import React, { Component } from 'react';
import { TreeView} from '@progress/kendo-react-treeview';
import {orderBy} from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import{parseDate, formatDate} from '@telerik/kendo-intl';
import LoadIcon from '../../Callback/loading.svg';
import axios from 'axios';
import EditPermissions from './EditPermissions';

class FileTree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openEdit:'',
            data:this.props.data,
            sort:[{field:'uploadDate', dir:'desc'}],
        }
    
        this.setOpen = this.setOpen.bind(this);

    }

    setOpen(popup){
        //close open folders because sub items appear on top of pop up
        for(let i=0; i<this.props.data.length; i++){
            this.props.data[i].expanded = false;
        }
        this.setState({openEdit:popup});
    }

    processData = () => {
       var sortedData = []
       var sort = []

       var data = orderBy(this.props.data, this.state.sort)
       var foldersOnTopData = []//this will have folders as the first elements. the sort on the previous line puts them at the bottom

       data.forEach(row => {
           if (row.items)
            foldersOnTopData.push(row)
            // if (typeof(row.items) == 'object') {
                if (row.expanded == null) row.expanded = false;
            // }
       })

       data.forEach(row => {
        if (!row.items)
         foldersOnTopData.push(row)
        })

        foldersOnTopData.forEach(row => {
        sort = []
        if (row.uploadDate){
            sort = this.state.sort
        }

        if (row.items){       
            sortedData.push({expanded: row.expanded, items: orderBy(row.items, this.state.sort), path: row.path, selected: row.selected, text: row.text.replace('/','')})    
        }
        else{
            sortedData.push({path: row.path, selected: row.selected, description: row.description, uploadDate: row.uploadDate, uploadedBy: row.uploadedBy,   makePrivate: row.makePrivate  , text: row.text.replace('/','')})
        }
        
       })
        return sortedData;
    }

    handleSortChange = (event) => {
        this.setState({
            sort: event.sort
        })
    }

    render() {
        return (           
            <div className="scrollTree">
                {!this.props.loading && (
                <TreeView
                    data={this.processData()}
                    sortable={true}
                    sort={this.state.sort}
                    onSortChange={this.handleSortChange}
                    expandIcons={true}
                    onExpandChange={this.props.onExpandChange}
                    //itemRender={props =>
                    //          [<span className={iconClassName(props.item)} key='0'></span>, props.item.text]
                    //        }

                    itemRender={props =>
                        [
                            <div className="fileItem" key={props.item.text}>
                                <span className={iconClassName(props.item)}></span>{docLink(props.item, this.props.idToken, this.props.user.company)}
                                {this.props.showDelete && props.item.items && this.props.type==='shared' && <><Button icon="gear" className="show" onClick={e=>this.setOpen(props.item.path)}/><EditPermissions idToken={this.props.idToken} open={this.state.openEdit===props.item.path} changeOpen={this.setOpen} type={this.props.type} user={this.props.user} folder={props.item}/></>}
                                {this.props.showDelete && <Button icon="close" className={setVisible(props.item)} onClick={this.props.onDelete}></Button>}
                                {props.item.makePrivate &&
                                <div className="description" >PRIVATE COMPANY DOCUMENT</div>
                                }
                            <div className="description">{props.item.description}</div>
                            <div className="uploadBy" >{uploadedByLine(props.item)}</div></div>
                        ]
                    }
                    onItemClick={this.props.onExpandChange}
                    aria-multiselectable={false}

                />)
                }
                {this.props.loading && (
                    <div>
                        <img src={LoadIcon} alt="loading"/>
                    </div>
                )}
            </div>
        )
    };




}

function onLinkClick(event, file , idToken, company){
    const headers = { 'authorization': 'Bearer '.concat(idToken) };
        
            var payload = {
                company:company,
                path:file.path,
                name:file.text
                }

            //.get("api/download?company="+company+"&path="+file.path+"&name="+file.text, {headers})
            axios
            .post("api/download",payload, {headers})
            .then((response) => {
                console.log(response);
                window.open(response.data, '_blank');
            })
            .catch((e) => {
                console.error("ERROR");
                return false;
            });
        
        return false;
}

function docLink(file, idToken, company){
    if(file.text.toLowerCase().endsWith('pdf'))
        return (<button className="link-button" onClick={(event) => onLinkClick(event, file, idToken, company)}>{file.text}</button>);
    else
        return file.text;
}

function setVisible({ selected }) {
    if (selected === undefined || selected === false)
        return "hide";
    else
        return "show";
}

function uploadedByLine({ items, uploadedBy, uploadDate }) {
    if (items === undefined && uploadedBy !== undefined) {
        return "Uploaded by: " + uploadedBy + " on " +formatDate(parseDate(uploadDate), "d");
    }

    return null;
}

const is = (fileName, ext) => new RegExp(`.${ext}`).test(fileName.toLowerCase());

function iconClassName({ text, items, expanded }) {
    if (expanded === true) {
        return 'k-icon k-i-folder-open colored-folder'
    }
    if (items !== undefined) {
        return 'k-icon k-i-folder colored-folder';
    } else if (is(text, 'pdf')) {
        return 'k-icon k-i-file-pdf colored-pdf';
    } else if (is(text, 'html')) {
        return 'k-icon k-i-html';
    } else if (is(text, 'jpg|png')) {
        return 'k-icon k-i-image';
    }
    else if (is(text, 'txt')) {
        return 'k-icon k-i-file-txt';
    }
    else if (is(text, 'xlsx|xls|xlsm')) {
        return 'k-icon k-i-excel colored-excel';
    }
    else if (is(text, 'doc|docx')) {
        return 'k-icon k-i-word colored-word';
    } 
    else if (is(text, 'csv')){
        return 'k-icon k-i-csv';
    }
    else if (is(text, 'ppt|pptx')){
        return 'k-icon k-i-ppt colored-ppt';
    }
    else if (is(text, 'ini|inf')){
        return 'k-icon k-i-config';
    }
    else if(is(text, 'zip')){
        return 'k-icon k-i-zip';
    }
    else {
        return 'k-icon k-i-file';
    }
}


export default FileTree;