import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import logo from '../Logos/blankhomepagelogo.png';
class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state={

    }

  }
  




  render() {
    return(
        <div className="homepage">
            <div className="logo">
                <img src={logo} alt="ERROR COULD NOT LOAD"/>
            </div>
            <div className="welcometext">Welcome to the Portal!</div>
            <Button className="homelogin"
                onClick={this.props.onLogin}>
                Log In
            </Button>
        </div>
    );
  }

}

export default HomePage;