import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class DisclaimersPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
        DisclaimerCode:'',
        initialLoad: true,
        DisclaimerTextWithNewline:''
      }

    this.uploadDisclaimer = this.uploadDisclaimer.bind(this);
    this.clearState = this.clearState.bind(this);
    this.getEditField = this.getEditField.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  onFieldChange(e, field){
    this.setState({[field]:e.target.value});
  }


  getEditField(field){
    if(this.props.disclaimerEdit && this.state.initialLoad){
      this.setState({[field]: this.props.disclaimerEdit[field], initialLoad:false});
      return this.props.disclaimerEdit[field];
    }
    else{
      return '';
    }
  }

  uploadDisclaimer(callback){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload={
        DisclaimerCode:this.state.DisclaimerCode,
        company:this.props.user.company,
        DisclaimerText:this.state.DisclaimerTextWithNewline
    }
    //add id field if editing template
    if(this.props.disclaimerEdit){
      payload.disclaimerEdit = true;
      payload.originalDisclaimerCode = this.props.disclaimerEdit.DisclaimerCode
    }

    axios.post("api/ssrsUploadDisclaimer", payload, {headers}).then(response=>{
      if(response.data.code===301){
        alert("Error: A disclaimer with the same name already exists!");
      }
      else if(response.data.code===302){
        alert("Error: Unable to use this disclaimer name.");
      }
      else{
        if(response.data.code===200){
          alert("Disclaimer created successfully.");
        }
        else if(response.data.code===201){
          alert("Disclaimer updated successfully. Any references to the disclaimer have been updated.");
        }
        else{
          alert("An error occured with the disclaimer.");
        }
        this.props.uploadMethod();
        this.clearState();
        callback();
      }         
    }).catch(err=>{alert("An error occured with the template."); console.log(err); this.props.uploadMethod(); this.clearState(); callback();});
  }

  clearState(){
    this.setState({
      DisclaimerCode:'',
      DisclaimerTextWithNewline:'',
      initialLoad:true
    })
  }


  render() {
      return (
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{close();}} />


              <div className="header" align="left">
                {this.props.disclaimerEdit ? 'Edit Disclaimer' : 'New Disclaimer'}
              </div>

              <div className="content">
              <label>Disclaimer Name: </label><input type="text" value={this.state.DisclaimerCode || this.getEditField('DisclaimerCode')} onChange={(e)=>this.onFieldChange(e, 'DisclaimerCode')} placeholder={""}/><br /><br />
              {//<textarea className='textbox' value={this.state.body || this.getEditField('body')} onChange={(e)=>this.onFieldChange(e, 'body')} placeholder='Enter body text' />
              }
              {
                <div>
                  <label>Disclaimer Text: </label><br/>
                  <textarea id="disclaimerText" name="disclaimerText" rows="5" style={{width:'95%'}} value={this.state.DisclaimerTextWithNewline || this.getEditField('DisclaimerTextWithNewline')} onChange={(e)=>this.onFieldChange(e, 'DisclaimerTextWithNewline')} placeholder={""} ></textarea>
                </div>
              }
              </div>

              <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Cancel
                </Button>

                <Button 
                  className="upload"  
                  onClick={(event)=>{this.uploadDisclaimer(close)}}>
                      {this.props.disclaimerEdit ? 'Update' : 'Create'}
                </Button>
              </div>

            </div>
          )}
        </Popup>

      );
  };
}

export default DisclaimersPopup;