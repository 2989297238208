import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import securityMasterFields from './securityMasterFields.json';

class SecurityMasterMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:{TypeCode:'',
            Symbol:'',
            CUSIP:'',
            Description:'',
            RiskCountry:'',
            IssueCountry:'',
            NextPaymentDate:'',
            IntDivRate:'',
            PaymentFrequency:'',
            SPRating:'',
            MoodyRating:'',
            FitchRating:'',
            State:'',
            TradesWithAI:'',
            PaysOnMonthEnd:'',
            ZeroCoupon:'',
            IssuePrice:'',
            IssueDate:'',
            FirstCoupon:'',
            LastCoupon:'',
            MaturityDate:'',
            BondStatus:'',
            RevenueSource:'',
            BondInsurer:'',
            CallDate1:'',
            CallPrice1:'',
            CallDate2:'',
            CallPrice2:'',
            CallDate3:'',
            CallPrice3:'',
            PutDate:'',
            PutPrice:'',
            RecordDate:'',
            PoolNumber:'',
            UserDef1:'',
            UserDef2:'',
            UserDef3:'',
            UserDef4:'',
            UserDef5:'',
            UserDef6:'',
            UserDef7:'',
            UserDef8:'',
            UserDef9:'',
            UserDef10:'',
            ExtendedString1:'',
            ExtendedString2:'',
            ExtendedString3:'',
            ExtendedString4:'',
            ExtendedString5:'',
            ExtendedDate1:'',
            ExtendedDate2:'',
            ExtendedDate3:'',
            ExtendedDate4:'',
            ExtendedDate5:'',
            ExtendedNumber1:'',
            ExtendedNumber2:'',
            ExtendedNumber3:'',
            ExtendedNumber4:'',
            ExtendedNumber5:'',
            isUnsupervised:''
                }
    }

    this.getMappings = this.getMappings.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount(){
      this.getMappings();
  }

  onInputChange(e, attr){
    let mappings = {...this.state.mappings};
    mappings[attr] = e.target.value.toUpperCase();
    this.setState({mappings});
  }

  uploadMappings = (e)=>{

    if(this.state.mappings.TypeCode===null || this.state.mappings.TypeCode==='')
        alert("TypeCode is required.");
    else if(this.state.mappings.Symbol===null || this.state.mappings.Symbol==='')
        alert("Symbol is required.");
    else{
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload ={
            company:this.props.user.company,
            mappings:this.state.mappings
        }
        axios.post('api/securityMasterMappings', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Security Master Mappings have been updated.");
            this.getMappings();
        }
        else
            alert("An error occured when trying to update mappings");
        }).catch(err=>{
        alert("An error occured when trying to update mappings");
        this.getMappings();
        });
    }
  }

  getMappings(){
    var emptyMappings = {
        TypeCode:'',
        Symbol:'',
        CUSIP:'',
        Description:'',
        RiskCountry:'',
        IssueCountry:'',
        NextPaymentDate:'',
        IntDivRate:'',
        PaymentFrequency:'',
        SPRating:'',
        MoodyRating:'',
        FitchRating:'',
        State:'',
        TradesWithAI:'',
        PaysOnMonthEnd:'',
        ZeroCoupon:'',
        IssuePrice:'',
        IssueDate:'',
        FirstCoupon:'',
        LastCoupon:'',
        MaturityDate:'',
        BondStatus:'',
        RevenueSource:'',
        BondInsurer:'',
        CallDate1:'',
        CallPrice1:'',
        CallDate2:'',
        CallPrice2:'',
        CallDate3:'',
        CallPrice3:'',
        PutDate:'',
        PutPrice:'',
        RecordDate:'',
        PoolNumber:'',
        UserDef1:'',
        UserDef2:'',
        UserDef3:'',
        UserDef4:'',
        UserDef5:'',
        UserDef6:'',
        UserDef7:'',
        UserDef8:'',
        UserDef9:'',
        UserDef10:'',
        ExtendedString1:'',
        ExtendedString2:'',
        ExtendedString3:'',
        ExtendedString4:'',
        ExtendedString5:'',
        ExtendedDate1:'',
        ExtendedDate2:'',
        ExtendedDate3:'',
        ExtendedDate4:'',
        ExtendedDate5:'',
        ExtendedNumber1:'',
        ExtendedNumber2:'',
        ExtendedNumber3:'',
        ExtendedNumber4:'',
        ExtendedNumber5:'',
        isUnsupervised:''
    }
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/securityMasterMappings?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.mappings.length===0)
          this.setState({mappings:emptyMappings});
      else
        this.setState({mappings:response.data.mappings[0]});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:emptyMappings})
    });
  }


  render() {
    var data = securityMasterFields;
    return(
        <div>
            <table className='posMapTable'>
                <tbody>
                    <tr>
                        <td className='heading'>Field Name <hr/></td>
                        <td align='right' className='heading'>Column Letter in Excel <hr/></td>
                    </tr>
                    {data.map(field => (
                    <tr key={field.id}>
                        <td><h4>{field.name}</h4><div>{field.desc}</div></td>
                        <td align='right'><input type='text' value={this.state.mappings[field.dbField]} onChange={e=>this.onInputChange(e,field.dbField)}/></td>                  
                    </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td align='right'><Button onClick={(e)=>this.uploadMappings(e)}>Apply Changes</Button></td>
                    </tr> 
                </tbody>
            </table>
        </div>
    );
  }

}

export default SecurityMasterMapping;