import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PositionUpload from './components/PositionUpload';
import PerfUpload from './components/PerfUpload';
import TranUpload from './components/TranUpload';
import BenchUpload from "./components/BenchUpload";
import IrrUpload from "./components/IrrUpload";
import RGainUpload from "./components/RGainUpload";

class DataUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabSelected:0
    }
  }

  handleSelect = (e) => {
    this.setState({ tabSelected: e.selected });
  }

  render() {
    return(
        <div className='dataUpload'>
            <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                <TabStripTab title="Upload Positions">
                  <PositionUpload user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Upload Performance">
                  <PerfUpload user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Upload IRRs">
                  <IrrUpload user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Upload Transactions">
                  <TranUpload user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Upload Benchmarks">
                  <BenchUpload user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Upload Realized Gains">
                  <RGainUpload user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
            </TabStrip>
        </div>
    );
  }

}

export default DataUpload;