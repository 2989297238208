import React, { Component } from "react";

class LogDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {

        const dataItem = this.props.dataItem;

        return (
            <section>
                <div className='includeWhiteSpace'><strong>Details: </strong>{dataItem.detail}</div>
                <div className='includeWhiteSpace'><strong>Item: </strong>{dataItem.item}</div>
            </section>
        );
  }
}

export default LogDetail;