import React from "react";
import {Grid, GridColumn, GridDetailRow, GridToolbar } from '@progress/kendo-react-grid';
import AddClients from './AddClients';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';

class ClientGrid extends GridDetailRow {

  constructor(props) {
    super(props);
    this.state = {
        clientsSelected:'',
        isClientSelected:false
    }
  }

  onDelete = (e)=>{
    var headers = { 'authorization': 'Bearer '.concat(this.props.dataItem.idToken) };

    var payload = {
      advisor: this.props.dataItem.email,
      company: this.props.dataItem.loggedInUser.company,
      clients: this.state.clientsSelected
    }
    axios.post("api/removeClients", payload, {headers}).then(response => {
      if(response.data.code===200){
        alert('Clients successfully removed from advisor');
        this.props.dataItem.refresh();
      }
      else{
        alert(response.data.errMsg);
        this.props.dataItem.refresh();
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err);
        this.props.dataItem.refresh();
      });  
  }

  selectionChange(event, clients){
    event.dataItem.selected = !event.dataItem.selected;
    var selectedClients = [];
    for(let i=0; i<clients.length; i++){
        if(clients[i].selected){
            selectedClients.push(clients[i]);
        }
    }
    this.setState({clientsSelected:selectedClients});
    if(selectedClients.length>0){
        this.setState({isClientSelected: true});
    }
    else{
        this.setState({isClientSelected: false});
    }

  }

  headerSelectionChange(event, clients){
    const checked = event.syntheticEvent.target.checked;
    clients.forEach(item => item.selected = checked);
    var selectedClients = [];
    for(let i=0; i<clients.length; i++){
        if(clients[i].selected){
            selectedClients.push(clients[i]);
        }
    }
    this.setState({clientsSelected:selectedClients});
    if(selectedClients.length>0){
        this.setState({isClientSelected: true});
    }
    else{
        this.setState({isClientSelected: false});
    }
  }

  render() {

    const data = this.props.dataItem.clients;

    return(
        <div>
            <Grid data={data}
            selectedField='selected'
            onRowClick={(e) => this.selectionChange(e, data)}
            onSelectionChange={(e) => this.selectionChange(e, data)}
            onHeaderSelectionChange={(e) => this.headerSelectionChange(e, data)}>
                <GridToolbar>
                    <AddClients user={this.props.dataItem.loggedInUser} clients={data} advisor={this.props.dataItem.email} idToken={this.props.dataItem.idToken} refresh={this.props.dataItem.refresh} button={<Button icon='plus'>Add Client(s)</Button>} />
                    <Button icon='delete' disabled={!this.state.isClientSelected} onClick={this.onDelete}>Remove</Button>              
                </GridToolbar>
                <GridColumn field="selected" />
                <GridColumn field='client' title='Clients'/>
            </Grid>
        </div>
    );
  }

}
export default ClientGrid;

