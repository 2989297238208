import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import GridLoading from '../../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';

class AdvisorAccountsAUM extends Component {

  constructor(props) {
    super(props);

    var classTypes = [
      {text:'Asset Class', value:'assetAllocation'},
      {text:'Security Type', value:'securityType'},
      {text:'Sector', value:'sector'},
      {text:'Industry', value:'industry'},
      {text:'Household', value:'household'},
      {text:props.compSettings.userDef1, value:'userDef1'},
      {text:props.compSettings.userDef2, value:'userDef2'},
      {text:props.compSettings.userDef3, value:'userDef3'},
      {text:props.compSettings.userDef4, value:'userDef4'},
      {text:props.compSettings.userDef5, value:'userDef5'}
    ]

    this.state = {
      data:[],
      fields:[],
      result:[],
      dataState:{},
      aggregates:[],
      portfolio:this.props.portfolio,
      type:props.compSettings.aumGrp || 'assetAllocation',
      classTypes:classTypes,
      dates:[],
      selectedDate:'',
      loading:false
    }

    this.getData = this.getData.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.getPosDates = this.getPosDates.bind(this);
  }

  componentDidMount(){
    this.getPosDates();
    this.getData();
  }

  getPosDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      company:this.props.user.company,
      advisor:this.props.user.user
    }    

    axios.post('api/posDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getData(){
    this.setState({loading:true});

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/advisorAUM?company='+this.props.user.company+'&date='+this.state.selectedDate+'&type='+this.state.type, {headers}).then(response=>{
      if(response.data.code===200 && !(response.data.fields.length===1 && response.data.fields[0]===null)){
        var aggs = [];
        for(let i=0; i<response.data.fields.length; i++){
          aggs.push({field:response.data.fields[i], aggregate:'sum'});
          response.data.data[i]["Household"] = response.data.data[i].household;
        }
        aggs.push({field:'total', aggregate:'sum'});
        var dState = {group:[{field:"Household", aggregates:aggs}]};
        var result = process(response.data.data, dState);
        this.setState({data:response.data.data, aggregates:aggs, dataState:dState, fields:response.data.fields, result:result, loading:false});
      }
      else{
        alert("No data available for selected classification type.");
        this.setState({loading:false, result:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  cellRender(tdElement, cellProps) {
    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field!=='value' && cellProps.field!=='displayName') { 
        var val = '';
        if(cellProps.dataItem.aggregates[cellProps.field])
          val = cellProps.dataItem.aggregates[cellProps.field].sum
        
        val = this.formatCurrency(val);

        return (
            <td style={{textAlign: 'right'}}>
                <strong>{val}</strong>
            </td>
        );
      }
      else if(cellProps.field==='displayName'){
        return (
          <td>
              <strong>{cellProps.dataItem.value} Totals:</strong>
          </td>
        );
      }
    }
    if(tdElement!==null){
      var style = {};
      if(cellProps.field!=='value' && cellProps.field!=='displayName')
        style = {textAlign: 'right'};

      return(
        <td {...tdElement.props} style={style}/>
      );
    }
    else{
      return tdElement;
    }
  }

  headerCellRender(thElement, cellProps) {
    if(thElement!==null){
      var style = {};
      if(cellProps.field!=='value' && cellProps.field!=='displayName')
        style = {textAlign: 'right'};

      return(
        <td {...thElement.props} style={style}/>
      );
    }
    else{
      return thElement;
    }
  }

  _export;
  export = () =>{
    this._export.save();
  }

  render() {
    return(
        <div className='advAccountAUM'>
          <ExcelExport data={this.state.data} fileName={'AdvisorAUM_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
            <ExcelExportColumn field='Household' title='Household'/>          
            <ExcelExportColumn field='displayName' title='Account'/>
            {this.state.fields.map((f, i)=>(
              <ExcelExportColumn field={f} key={i} title={f}/>
            ))}
            <ExcelExportColumn field='total' title='Total'/>
          </ExcelExport>
          <Grid data={this.state.result}
            groupable={{footer:'always'}}       
            className='noGroupGridLines'         
            resizable={true}
            reorderable={true}
            filterable={false}
            sortable={true}
            onDataStateChange={this.changeDataState}
            onExpandChange={this.expandChange}
            expandField="expanded"
            cellRender={this.cellRender}
            headerCellRender={this.headerCellRender}
            ref={(grid) => { this._grid = grid; }}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              <label>Type: </label><select value={this.state.type} onChange={(e)=>{this.setState({type:e.target.value}, ()=>this.getData())}}>
                {this.state.classTypes.map((t, i)=>(
                <option key={i} value={t.value}>{t.text}</option>
                ))}
              </select>
              <label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value}, ()=>this.getData())}>
              {this.state.dates.map((date, i)=>(
              <option value={date.fDate}>{date.fDate}</option>
              ))}
              </select>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
            </GridToolbar>
            <GridColumn headerClassName='gridHeader' field='displayName' title='Account'/>
            {this.state.fields.map((f, i)=>(
              <GridColumn headerClassName='gridHeader' field={f} key={i} title={f} format='{0:c}'/>
            ))}
            <GridColumn headerClassName='gridHeader' field='total' title='Total' format='{0:c}'/>
          </Grid>
          {this.state.loading && <GridLoading />}
        </div>
    );
  }

  changeDataState = (e)=>{
    const groups = e.data.group;
    if(groups){
      groups.map(group=>group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.data, e.data);
    this.setState({dataState:e.data, result:newData});
  }

  formatCurrency(val){
    if(isNaN(val))
      val = 0;

    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);  
    return formatted;
  }

  formatPercent(val){
      return (val*100).toFixed(2)+"%";
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

}

export default AdvisorAccountsAUM;