import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class ColorButton extends React.Component {
  
  constructor(props) {
    super(props);
    var defaultColor = props.defaultColor

    this.state = {
      displayColorPicker: false,
      changed: false,
      color: defaultColor,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.update){
      return({color:nextProps.defaultColor});
    }
    else{
      return null;
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({displayColorPicker: false })
  };

  setDefault(){
      if(!this.state.changed){
        if(this.props.defaultColor)
          return this.props.defaultColor;
        else
          return '#FFFFFF';
      }
      else{
        return this.state.color;
      }
  }

  handleChange = (color) => {
    
    this.setState({changed:true, color: color.rgb });
    this.props.onChange(color);
  };

  getHtmlColor(){
    var color = this.state.color
    var htmlColor = 'rgb(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')'
    return htmlColor
  }

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${this.getHtmlColor()}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <span>{this.props.text}
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker disableAlpha={true} color={this.state.color} presetColors={['#C00000', '#FF0000', '#FFC000', '#FFFF00', '#92D050', '#00B050', '#00B0F0', '#0070C0', '#002060', '#7030A0']} onChangeComplete={ this.handleChange } />
        </div> : null }

      </span>
    )
  }
}

export default ColorButton