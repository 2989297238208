import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import EditPermission from "./EditPermission";
import RolesList from "./RolesList";
import axios from 'axios';
import * as utils from '../../utilities';

class DetailRow extends Component {

    removePermission = (e) => {
        var permission = this.props.dataItem;
        const confirm = window.confirm("Are you sure you want to remove " + permission.permission + "?");

        if (confirm === true) {
            const headers = { 'authorization': 'Bearer '.concat(permission.idToken) };

            axios.get('api/deletePermission?guid=' + permission.guid + '&company=' + permission.company, { headers }).then(response => {
                if (response.data.code !== 200) {
                    alert("Error: Permission could not be removed.");
                }
                else {
                    this.props.dataItem.refreshList();
                }
            });
        }
    }

    changeActivate = (e) => {
        var permission = this.props.dataItem;
        var msg = "activate";

        if (permission.active)
            msg = "deactivate";

        const confirm = window.confirm("Are you sure you want to " + msg + " this permission?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(permission.idToken) };
            var payload = {
                company: permission.company,
                permissionguid: permission.guid,
                permission: permission.permission,
                description: permission.description,
                api: permission.api,
                method: permission.method,
                order: permission.order,
                active: !permission.active,
                roles: permission.roles != null ? permission.roles : [],
                isGlobal: permission.isGlobal,
                isOverride: (permission.isGlobal && permission.company !== 'cssi') ? true : false,
                ignoreRoles: true
            }

            axios.post('api/updatePermission', payload, { headers }).then(response => {
                if (response.data.code !== 200) {
                    alert("Error: Permission's active status could not be changed.");
                }
                else {
                    permission.changeActivate(permission.guid, !permission.active);
                    permission.refreshList();
                    permission.refreshUser('permissions',permission.company);
                }
            });
        }
    }

    render() {
        return (
            <section className='userActions'>
                <strong>Actions: </strong>
                {!this.props.dataItem.loggedInAsAdvisor && this.props.dataItem.active /*&& (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi'))*/ && <Button onClick={event => this.changeActivate(event)}>Deactivate</Button>} 
                {!this.props.dataItem.loggedInAsAdvisor && !this.props.dataItem.active /* && (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi'))*/ && <Button onClick={event => this.changeActivate(event)}>Activate</Button>} 
                {!this.props.dataItem.loggedInAsAdvisor && (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi') || this.props.dataItem.isOverride) && <Button onClick={event => this.removePermission(event)}>{this.props.dataItem.isOverride && this.props.dataItem.company !== 'cssi' ? 'Remove Override' : 'Remove'}</Button>} 
                {!this.props.dataItem.loggedInAsAdvisor /*&& (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi'))*/ && <EditPermission permission={this.props.dataItem} button={<Button>Edit</Button>} />} 
                {utils.checkPermission({permissions: this.props.dataItem.permissionsArray},'Roles') && <RolesList permission={this.props.dataItem} button={<Button>Roles</Button>} />}
            </section>
        );
    }
}

export default DetailRow;