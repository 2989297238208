
import React from 'react';
import ReactDOM from 'react-dom';
//import kendo from '@progress/kendo-ui';
import './index.css';
//import App from './App';
//deprecated replaced by two lines below
//import "babel-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import * as serviceWorker from './serviceWorker';
import Routes from './routes';

ReactDOM.render(
    <>
          <Routes />
    </>,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
