import React, { Component } from 'react';

class Contact extends Component {
    
    constructor(props) {
        super(props);
        this.state = {

        }       
    }


    render() {
        var data = this.props.compSettings.locations;

        return(
            <div>
                {data.map(loc => (
                    <div key={loc.id}>
                        <h2>{loc.name}:</h2>
                        
                        {loc.address && <div><strong>Address: </strong>{loc.address+" "+loc.city+", "+loc.state+" " +loc.zip}</div>}
                        {loc.email && <div><strong>Email: </strong><a href={'mailto:'+loc.email} target="_blank" rel="noopener noreferrer">{loc.email}</a></div>}
                        {loc.website && <div><strong>Website: </strong><a href={'http://'+loc.website} target="_blank" rel="noopener noreferrer">{loc.website}</a></div>}
                        {loc.phone && <div><strong>Phone: </strong>{loc.phone}</div>}
                    </div>
                    ))}               
            </div>
        )
        
    };


}

export default Contact;