import React, { Component } from "react";
import PerformanceHistory from '../components/PerformanceHistory';
import TwrChart from '../components/TwrChart';
import axios from 'axios';

class PerformanceOverview extends Component {

  constructor(props) {
    super(props);

    var returnType = "twr";

    if(this.props.styleSettings.perfHistIrr)
      returnType = "irr";
    
    // if(!this.props.styleSettings.enablePerfReturnSelect)
    //   returnType = "";

    this.state = {
      dates:[],
      selectedDate:'',
      perfType:'net',
      returnType:returnType,
      portfolio:this.props.portfolio,
      styleSettings: {
        chartShowAsOf: null,
        chartEnableFeeSelect: null,
        chartDefaultFees: null
      }
    }

  }

  componentDidMount(){
    this.getStyleSettings(() => {
      this.getPerfDates();
    });
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio){
      this.setState({portfolio:this.props.portfolio}, ()=>{this.getPerfDates()});
    }
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'chartShowAsOf',
      'chartEnableFeeSelect',
      'chartDefaultFees'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings, perfType: (response.data.styleSettings.chartDefaultFees === 'Gross' ? 'gross' : 'net') }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  getPerfDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      email:this.props.user.user,
      port:this.props.portfolio.account,
      household:this.props.user.defaultAcct.household,
      perfType:this.state.perfType
    }
    axios.post('api/perfDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0){
            var selectedDate = this.state.selectedDate ? this.state.selectedDate : response.data.data[0].fDate
            this.setState({dates:response.data.data, selectedDate: selectedDate});
          }
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  firstUpper(s){
    return s.charAt(0).toUpperCase()+s.slice(1);
  }


  render() {
    return(
        <div className='performanceOverview'>
          {this.props.styleSettings.enablePerfReturnSelect && <><label>Returns: </label><select onChange={(e)=>this.setState({returnType:e.target.value})} value={this.state.returnType} style={{marginRight:'10px'}}>
              <option value='irr'>IRR</option>
              <option value='twr'>TWR</option>
            </select></>}
          {this.state.styleSettings.chartEnableFeeSelect && <><label>Type: </label><select onChange={(e)=>this.setState({perfType:e.target.value})} value={this.state.perfType} style={{marginRight:'10px'}}>
              <option value='net'>Net</option>
              <option value='gross'>Gross</option>
            </select></>}
          {!this.state.styleSettings.chartEnableFeeSelect && <label style={{marginRight:'10px'}}>Type: {this.firstUpper(this.state.perfType)}</label>}
          {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value})}>
            {this.state.dates.map((date, i)=>(
              <option key={i} value={date.fDate}>{date.fDate}</option>
            ))}
            </select>
          <br/></>}
          {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
          <PerformanceHistory user={this.props.user} date={this.state.selectedDate} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} perfType={this.state.perfType} returnType={this.state.returnType} portfolio={this.props.portfolio} idToken={this.props.idToken}/>
          <TwrChart user={this.props.user} date={this.state.selectedDate} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} perfType={this.state.perfType} returnType={this.state.returnType} portfolio={this.props.portfolio} idToken={this.props.idToken}/>
        </div>
    );
  }

}

export default PerformanceOverview;