import React from "react";
import {Grid, GridColumn, GridDetailRow, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import AddUsers from './AddUsers';
import axios from 'axios';

class UserGroups extends GridDetailRow {

  constructor(props) {
    super(props);
    this.state = {
      usersSelected:'',
      isUserSelected:false
    }
  }

  onDelete = (e)=>{
    var headers = { 'authorization': 'Bearer '.concat(this.props.dataItem.idToken) };

    var payload = {
      users:this.state.usersSelected,
      action:'remove',
      group:this.props.dataItem.id,
      company:this.props.dataItem.loggedInUser.company
    }
    axios.post("api/usergroup", payload, {headers}).then(response => {
      if(response.data.code===200){
        alert('Users successfully removed from group');
        this.props.dataItem.refresh();
      }
      else{
        alert(response.data.errMsg);
        this.props.dataItem.refresh();
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err);
        this.props.dataItem.refresh();
      });  
  }

  selectionChange(event, users){
    event.dataItem.selected = !event.dataItem.selected;
    var selectedUsers = [];
    for(let i=0; i<users.length; i++){
        if(users[i].selected){
          selectedUsers.push(users[i]);
        }
    }
    this.setState({usersSelected:selectedUsers});
    if(selectedUsers.length>0){
        this.setState({isUserSelected: true});
    }
    else{
        this.setState({isUserSelected: false});
    }

  }

  headerSelectionChange(event, users){
    const checked = event.syntheticEvent.target.checked;
    users.forEach(item => item.selected = checked);
    var selectedUsers = [];
    for(let i=0; i<users.length; i++){
        if(users[i].selected){
          selectedUsers.push(users[i]);
        }
    }
    this.setState({usersSelected:selectedUsers});
    if(selectedUsers.length>0){
        this.setState({isUserSelected: true});
    }
    else{
        this.setState({isUserSelected: false});
    }
  }

  render() {

    const data = this.props.dataItem.details;

    return(
        <div>
            <Grid data={data}
            selectedField='selected'
            className='userGroupList'
            onRowClick={(e) => this.selectionChange(e, data)}
            onSelectionChange={(e) => this.selectionChange(e, data)}
            onHeaderSelectionChange={(e) => this.headerSelectionChange(e, data)}>
                <GridToolbar>
                  <AddUsers user={this.props.dataItem.loggedInUser} group={data} idToken={this.props.dataItem.idToken} refresh={this.props.dataItem.refresh} groupId={this.props.dataItem.id} button={<Button icon='plus'>Add User(s)</Button>} />
                  <Button icon='delete' disabled={!this.state.isUserSelected} onClick={this.onDelete}>Remove</Button>
                </GridToolbar>
                <GridColumn field="selected" />
                <GridColumn field='nickname' title='User'/>
                <GridColumn field='email' title='Email'/>
            </Grid>
        </div>
    );
  }

}
export default UserGroups;

