import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import axios from 'axios';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class AssetClassAUM extends Component {

  constructor(props) {
    super(props);

    this.minGridWidth = 0;

    var classTypes = [
      { text: 'Asset Class', value: 'assetAllocation' },
      { text: 'Security Type', value: 'securityType' },
      { text: 'Sector', value: 'sector' },
      { text: 'Industry', value: 'industry' },
      { text: props.compSettings.userDef1, value: 'userDef1' },
      { text: props.compSettings.userDef2, value: 'userDef2' },
      { text: props.compSettings.userDef3, value: 'userDef3' },
      { text: props.compSettings.userDef4, value: 'userDef4' },
      { text: props.compSettings.userDef5, value: 'userDef5' }
    ]

    var grpSplit = [];

    if (props.compSettings.aumDropGrps !== null) {
      grpSplit = props.compSettings.aumDropGrps.split(',');
    }

    for (let i = classTypes.length - 1; i >= 0; i--) {
      if (grpSplit.indexOf(classTypes[i].value) < 0)
        classTypes.splice(i, 1);
    }

    this.state = {
      aum: [],
      columns: [],
      result: [],
      dataState: {},
      aggregates: [],
      portfolio: this.props.portfolio,
      type: props.compSettings.aumGrp || 'assetAllocation',
      classTypes: classTypes,
      dates: [],
      selectedDate: '',
      loading: true,
      setMinWidth: false,
      gridCurrent: 0,
      styleSettings: {
        chartShowAsOf: null
      }
    }

    this.getAccountAUM = this.getAccountAUM.bind(this);
    this.getHoldDates = this.getHoldDates.bind(this);
    this.changeDataState = this.changeDataState.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.formatPercent = this.formatPercent.bind(this);
    this.getDate = this.getDate.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
  }



  componentDidMount() {
    this.getStyleSettings(() => {
    this.getAccountAUM('', () => {
      this.getHoldDates();
      this.setMinGridWidth();
    })
    });
  }

  componentDidUpdate() {
    if (this.props.portfolio !== this.state.portfolio) {
      this.setState({ portfolio: this.props.portfolio }, () => {
        this.getAccountAUM('', () => {
          this.setMinGridWidth()
        })
      });
    }
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      "chartShowAsOf"
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  setMinGridWidth = () => {
    this.grid = document.querySelector('.k-grid');
    window.addEventListener('resize', this.handleResize);
    this.minGridWidth = 0;
    this.state.columns.map(item => this.minGridWidth += item.minWidth);
    if (this.state.dataState.group)
      this.minGridWidth += 32 * this.state.dataState.group.length;
    this.setState({
      gridCurrent: this.grid.offsetWidth,
      setMinWidth: this.grid.offsetWidth < this.minGridWidth
    });
  }

  handleResize = () => {
    if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
      this.setState({
        setMinWidth: true
      });
    } else if (this.grid.offsetWidth > this.minGridWidth) {
      this.setState({
        gridCurrent: this.grid.offsetWidth,
        setMinWidth: false
      });
    }
  }

  setWidth = (minWidth) => {
    let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
    if (width >= COLUMN_MIN)
      width = width - ADJUST_PADDING;
    return width;
  }

  getAccountAUM(date, cb) {
    this.setState({ loading: true });
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var unmapped = '';
    if (this.props.user.unmapped)
      unmapped = 'true';

    var payload = {
      company: this.props.user.company,
      user: this.props.user.user,
      port: this.state.portfolio.account,
      type: this.state.type,
      household: this.props.user.defaultAcct.household,
      date: date,
      unmapped: unmapped
    }

    axios.post('api/accountAum', payload, { headers }).then(response => {
      if (response.data.code === 200 && !(response.data.fields.length === 1 && response.data.fields[0] === null)) {
        var aggs = [];
        for (let i = 0; i < response.data.fields.length; i++) {
          aggs.push({ field: response.data.fields[i], aggregate: 'sum' });
        }
        aggs.push({ field: 'total', aggregate: 'sum' });
        var dState = { group: [{ field: "displayName", aggregates: aggs }] };
        if (this.state.portfolio.consolidated)
          dState = { group: [{ field: "Account", aggregates: aggs }] };
        var result = process(response.data.data, dState);
        var cols = response.data.fields.map((f, i) => {
          return { field: f, title: f, headerClass: 'gridHeader', format: '{0:c2}', minWidth: 150 };
        });
        cols.unshift({ field: 'displayName', title: 'Portfolio', headerClass: 'gridHeader', minWidth: 150 });
        cols.push({ field: 'total', title: 'Total', headerClass: 'gridHeader', format: '{0:c2}', minWidth: 150 });
        this.setState({ aum: response.data.data, aggregates: aggs, dataState: dState, columns: cols, result: result, loading: false }, () => cb());
      }
      else {
        alert("No data available for selected classification type.");
        this.setState({ loading: false, result: [] }, () => cb());
      }
    }).catch(err => {
      console.log(err);
    });
  }

  getDate(sqlDate, isUTC) {
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if (d) {
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if (isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getHoldDates() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      port: this.props.portfolio.account,
      user: this.props.user.user
    }
    axios.post('api/posDates', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        if (response.data.data.length > 0)
          this.setState({ dates: response.data.data, selectedDate: response.data.data[0].fDate });
        else
          this.setState({ dates: [] });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  cellRender(tdElement, cellProps) {
    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field !== 'value' && cellProps.field !== 'displayName') {
        var val = '';
        if (cellProps.dataItem.aggregates[cellProps.field])
          val = cellProps.dataItem.aggregates[cellProps.field].sum

        val = this.formatCurrency(val);

        return (
          <td style={{ textAlign: 'right' }}>
            <strong>{val}</strong>
          </td>
        );
      }
      else if (cellProps.field === 'displayName') {
        return (
          <td>
            <strong>{cellProps.dataItem.value} Totals:</strong>
          </td>
        );
      }
    }
    if (tdElement !== null) {
      var style = {};
      if (cellProps.field !== 'value' && cellProps.field !== 'displayName')
        style = { textAlign: 'right' };

      return (
        <td {...tdElement.props} style={style} />
      );
    }
    else {
      return tdElement;
    }
  }

  headerCellRender(thElement, cellProps) {
    if (thElement !== null) {
      var style = {};
      if (cellProps.field !== 'value' && cellProps.field !== 'displayName')
        style = { textAlign: 'right' };

      return (
        <div {...thElement.props} style={style} />
      );
    }
    else {
      return thElement;
    }
  }

  _export;
  export = () => {
    this._export.save();
  }

  render() {
    return (
      <div className='accountAUM'>
        <ExcelExport data={this.state.aum} fileName={'AssetClassAUM.xlsx'} ref={(exporter) => { this._export = exporter; }}>
          <Grid data={this.state.result}
            groupable={{ footer: 'always' }}
            className='noGroupGridLines'
            resizable={true}
            reorderable={true}
            filterable={false}
            sortable={true}
            onDataStateChange={this.changeDataState}
            onExpandChange={this.expandChange}
            expandField="expanded"
            cellRender={this.cellRender}
            headerCellRender={this.headerCellRender}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              <label>Type: </label><select value={this.state.type} onChange={(e) => { this.setState({ type: e.target.value }, () => this.getAccountAUM(this.state.selectedDate, () => { this.setMinGridWidth() })) }}>
                {this.state.classTypes.map((t, i) => (
                  <option key={i} value={t.value}>{t.text}</option>
                ))}
              </select>
              {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e) => { this.setState({ selectedDate: e.target.value }); this.getAccountAUM(e.target.value, () => { this.setMinGridWidth() }) }}>
                {this.state.dates.map((date, i) => (
                  <option key={i} value={date.fDate}>{date.fDate}</option>
                ))}
              </select></>}
              {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
            </GridToolbar>
            {this.state.columns.map((c, i) => (
              <GridColumn headerClassName={c.headerClass} field={c.field} key={i} title={c.title} format={c.format} width={this.setWidth(c.minWidth)} />
            ))}
          </Grid>
        </ExcelExport>
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

  changeDataState = (e) => {
    const groups = e.data.group;
    if (groups) {
      groups.map(group => group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.aum, e.data);
    this.setState({ dataState: e.data, result: newData }, () => this.setMinGridWidth());
  }

  formatCurrency(val) {
    if (isNaN(val))
      val = 0;

    var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
    return formatted;
  }

  formatPercent(val) {
    return (val * 100).toFixed(2) + "%";
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

}

export default AssetClassAUM;