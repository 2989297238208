import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class EditPermission extends Component {

  constructor(props) {
    super(props);

    this.state = {
        permission: this.props.permission.permission,
        description: this.props.permission.description,
        api: this.props.permission.api,
        method: this.props.permission.method,
        isGlobal: this.props.permission.isGlobal
      }
  }

  clearState = ()=>{
    this.setState({
        permission: this.props.permission.permission, 
        description: this.props.permission.description, 
        api: this.props.permission.api,
        method: this.props.permission.method,
        isGlobal: this.props.permission.isGlobal
       });
  }

  onPermissionChange = (e) =>{
    this.setState({permission:e.target.value});
  }

  onDescriptionChange = (e) =>{
    this.setState({description:e.target.value});
  }

  onAPIChange = (e) =>{
    this.setState({api:e.target.value});
  }

  onMethodChange = (e) =>{
    this.setState({method:e.target.value});
  }

  onIsGlobalChange = (e) =>{
    this.setState({isGlobal:!this.state.isGlobal});
  }

  updatePermission = (e) => {
    var permission = this.props.permission;
    const headers = { 'authorization': 'Bearer '.concat(permission.idToken) };

    var payload = {
        company: permission.company,
        permissionguid: permission.guid,
        permission:this.state.permission,
        description:this.state.description,
        api:this.state.api,
        method:this.state.method,
        active: permission.active,
        order: permission.order,
        roles: permission.roles != null ? permission.roles : [],
        isGlobal: this.state.isGlobal,
        isOverride: (this.state.isGlobal && permission.company !== 'cssi') ? true : false,
        ignoreRoles: true
    }

    axios.post('api/updatePermission', payload, {headers}).then(response=>{
        if(response.data.code===200){
          permission.refreshList();
        }
        else{
          alert("Error: Permission could not be updated.");
        }
    });
  }

  render() {
      return (
        <Popup trigger={this.props.button} onClose={() => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">
              <Button className="close" icon="close" onClick={()=>{close();}} />
              <div className="header" align="left">
                Edit Permission
              </div>
              <div className="content">
                <div>
                    <label>Permission: </label>
                    <input defaultValue={this.props.permission.permission} onChange={this.onPermissionChange}/>
                </div>
                <br />
                <div>
                    <label>Description: </label>
                    <input defaultValue={this.props.permission.description} onChange={this.onDescriptionChange}/>
                </div>
                <br />
                <div>
                    <label>API: </label>
                    <input defaultValue={this.props.permission.api} onChange={this.onAPIChange}/>
                </div>
                <br />
                <div>
                    <label>Method: </label>
                    <input defaultValue={this.props.permission.method} onChange={this.onMethodChange}/>
                </div>
                {this.props.permission.company === 'cssi' && <div style={{marginTop: '10px'}}>
                    <label>Global: </label>
                    <input type="checkbox" name="isGlobal" onChange={this.onIsGlobalChange} checked={this.state.isGlobal} />
                </div>}
              </div>
              <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Cancel
                </Button>
                <Button 
                  className="upload"  
                  onClick={(event)=>{this.updatePermission(event); close();}}>
                      Update
                </Button>
              </div>
            </div>
          )}
        </Popup>
      );
  };
}

export default EditPermission;