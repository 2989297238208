
import React from 'react';
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy, process } from '@progress/kendo-data-query';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from '@progress/kendo-react-inputs';
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../Loaders/GridLoading';
import createFilterDropDownCell from './components/FilterDropDownCell';
import * as utils from '../utilities'

const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class Reporting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            data: [],
            editField: undefined,
            reportSelected: false,
            reportSelect: '',
            changes: false,
            reportsLoading: true,
            globalFeeMethod: {value: "0", label: "Gross"},
            globalDisclaimer: "No Disclaimer",
            globalLayout: {value: "l", label: "Landscape"},
            globalFeeMethodChecked: false,
            globalDisclaimerChecked: false,
            globalLayoutChecked: false,

            disclaimersList: [],
            assetClassList: [],

            gridFilter: undefined,
            filterable: false,
            reportStyles: ['Standard', 'Custom'],
            sort: [{ field: 'name', dir: 'asc' }],

            //
            runReportButtonFlag: false,

            layout: null,
            reports: [],
            report: { name: "Select Report", key: null, adjustableLayout: null },
            entities: [],
            loading: false,
            account: { account: "", displayName: "" },
            numPages: null,
            pageNumber: 1,
            pdfFile: null,
            pdfFlag: false,
            pdfScale: 1.25,
            status: "",
            loaderSpinnerFlag: false,
            checkPdfBlobTimerId: null,//used in componentWillUnmount cleanup
            generateExcelButtonFlag: true,
            generateCsvButtonFlag: true,
            reportParameters: [],
            reportParametersFlag: false,
            parameterList: [],
            reportStyles: [],
            defaultList: [],
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
            data:[],
            dataState:{skip:0, take:10000, /*sort:[{ field: 'style', dir: 'asc' }]*/},
            reportGoTo: null,
            runReportDisabled: false,
        };

        //this.renderers = new Renderers(this.enterEdit, this.exitEdit, 'inEdit');
        this.getReportList = this.getReportList.bind(this);
        this.getParameterDefaults = this.getParameterDefaults.bind(this);
        this.getReportStyles = this.getReportStyles.bind(this);
        this.headerCellRender = this.headerCellRender.bind(this);
        this.createCell = this.createCell.bind(this);
    }

    componentDidMount() {
        localStorage.removeItem('reportGoTo')
        localStorage.removeItem('reportArray')
        this.getParameterDefaults();
        this.useEffect();
    }

    useEffect(){
      window.addEventListener('storage', () => this.setState({reportGoTo: localStorage.getItem('reportGoTo')}));
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => item.selected = false);
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0] });
        if (selectedFiles.length > 0) {
            this.setState({ reportSelected: true });
        }
        else {
            this.setState({ reportSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        // const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = false);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0], reportSelected: false });

        this.forceUpdate();
    }



    getParameterDefaults() {
      this.setState({loading: true})
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var parameterList = []
        //parameterList = parameterList.concat(this.state.parameterList)
        //var originalParameterList = JSON.parse(JSON.stringify(parameterList)) 
        axios(`api/ssrsGetParameterDefaults`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers
        })
          .then(response => {
    
            if (response.data.code === 200) {
                parameterList = response.data.data
                this.setState({ parameterList: parameterList })
                this.getReportStyles(parameterList);

            }
            else {
              console.log("Error getting report defaults")
            }
          })
          .catch(error => {
            console.error(error);
          });
      }

      getReportStyles(parameterList) {
        this.setState({loading: true})
          const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
          var reportStyles = []
          var reportStylesText = []
          //parameterList = parameterList.concat(this.state.parameterList)
          //var originalParameterList = JSON.parse(JSON.stringify(parameterList)) 
          axios(`api/ssrsGetReportStyles`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
          })
            .then(response => {
      
              if (response.data.code === 200) {
                  reportStyles = response.data.data
                  reportStyles.forEach(r => {
                    if (reportStylesText.indexOf(r.style) === -1) {
                      reportStylesText.push(r.style)
                    }
                  })
                  reportStylesText.push('Custom')

                  this.setState({ reportStyles: reportStyles, reportStylesText: reportStylesText })
                  this.getReportList(parameterList, reportStyles);
  
              }
              else {
                console.log("Error getting report styles")
              }
            })
            .catch(error => {
              console.error(error);
            });
        }



    getReportList(parameterList, reportStyles) {
        var reports = [];
        var reportNames = [];
        var splitName;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetReportsWithTitle`, {
          method: 'GET',
          params: { 
            company: this.props.user.company, 
            includeStandard: utils.checkPermission(this.props.user, "Standard Reporting"), 
            includeCustom: utils.checkPermission(this.props.user, "Custom Reporting")
          },
          headers: headers,
        })
          .then(response => {
            var folder
            response.data.data.forEach(reportInResponse => {
              folder = reportInResponse.path.split('/')[1]
    
              if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
                splitName = reportInResponse.name.split('_')
                if (!reportNames.includes(splitName[0])) {
                  reportNames.push(splitName[0]);
                  reports.push({ name: splitName[0], key: reportInResponse.key, type: reportInResponse.reportType, title: reportInResponse.reportTitle })
                }
              }
              else {
                reports.push({ name: reportInResponse.name, key: reportInResponse.key, type: reportInResponse.reportType, title: reportInResponse.reportTitle })
              }
    
    
            })

            //add style and title properties to reports object items
            reports.forEach(o1 => o1.style = reportStyles.filter(o2 => o2.report === o1.name)[0]?.style || 'Custom'); 
            reports.forEach(o1 => o1.title = parameterList.filter(o2 => (o2.reportName === o1.name && o2.paramName === 'ReportTitle'))[0]?.value || (o1.title || o1.name)); 

            var customReports = []
            var standardReports = []

            reports.forEach(r => {if (r.style === 'Custom') customReports.push(r)})
            reports.forEach(r => {if (r.style !== 'Custom') standardReports.push(r)})

            customReports.sort(function (a, b) {
              if (a.name > b.name) return 1;
              if (a.name < b.name) return -1;
            });

            standardReports.sort(function (a, b) {
              if (a.name > b.name) return 1;
              if (a.name < b.name) return -1;
            });

            reports = customReports.concat(standardReports)
            

            var data = process(reports, this.state.dataState)
            this.setState({ reports: reports, data: data, loading: false })
          })
          .catch(err => {
            console.error(err);
          })
        //return reports;
    
      }

      getDefaultParamString(report, parameterList){
        var s = ""
        parameterList.forEach(p => {
            if (p.reportName === report){
                if (s != ""){ s += "; " }
                s += (p.label + ": " + (p.valueText || p.value))
            }
        })
        return s
      }


    saveChanges = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    openPopup = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    cancelChanges = () => {
        this.setState({
            data: this.state.reports.map(cloneProduct),
            changes: false
        });
    }

    itemChange = (event) => {
        event.dataItem[event.field] = event.value;
        this.setState({
            changes: true
        });
    }

    onFilterChange = (e) => {
        this.setState({ gridFilter: e.filter });
    }

    headerCellRender(thElement, cellProps) {
        if (thElement !== null) {
          if (cellProps.field === 'selected')
          return (
            <div  />
          );
          else{
              return thElement
          }
        }
        else {
          return thElement;
        }
      }

      createDisabledTimer(){
        var runReportDisabledTimer = setInterval(
          function () {
            clearInterval(runReportDisabledTimer)
            this.setState({ runReportDisabled: false })
          }
            .bind(this),
          3000
        )

        return runReportDisabledTimer
      }

      openReportTab(name){
        localStorage.setItem('reportGoTo', true)
        this.setState({reportGoTo: true, runReportDisabledTimerId: this.createDisabledTimer(), runReportDisabled: true})

        if (!localStorage.getItem('reportArray')){
          localStorage.setItem('reportArray', name)
        }
        else{
          const reportArray = localStorage.getItem('reportArray').split(',')
          reportArray.push(name)
          localStorage.setItem('reportArray', reportArray.toString())
        }
        

        window.open("reporting", "_blank")
      }

      createCell = (cell) => {
        return (
          <td>
            {/* <ReportDefaultPopup idToken={this.props.idToken} user={this.props.user} refresh={this.getParameterDefaults} report={{name: cell.dataItem.name, key: cell.dataItem.key, folder: cell.dataItem.folder, adjustableLayout:cell.dataItem.adjustableLayout}} button={<Button on>Edit Default Values</Button>} /> */}
            <Button onClick={() => this.openReportTab(cell.dataItem.name) } disabled={this.state.reportGoTo && this.state.runReportDisabled}>Run Report</Button>
          </td>
        );
      }

      handleGlobalChange(e, switchName){
        var name = e.target.name || switchName //can't get the "name" property to work for switch object. using this workaround
        var value = e.target.value
        var newValue = ""

        if (value.label){//if dropdown with label/value properties
            newValue = value.value
        }
        else{
            newValue = value
        }

        this.setState({
            ["global".concat(name)]: value
        })

        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        // var params = {
        //     company: this.props.user.company,
        //     parameters: paramsToSave,
        //     reportName: this.props.report.name
        // }

        var splitName = name.split('Checked')[0]
    
        var payload = {
          company: this.props.user.company,
          paramName: splitName
        };

        if (name.includes("Checked")){
            payload.active = newValue
            payload.value = (this.state["global".concat(splitName)].label ? this.state["global".concat(splitName)].value : this.state["global".concat(splitName)])
        }
        else{
            payload.active = this.state["global".concat(name).concat("Checked")] || false
            payload.value = newValue
        }

        axios.post('api/ssrsSaveGlobalParameterDefaults', payload, { headers }).then(response => {
          if (response.data.code === 200) {
            //nothing
          }
          else {
            alert("Unable to save global settings")
          }
        })
          .catch(err => {
            console.error(err);
            alert("Unable to save global settings")
          })


      }

      changeDataState = (e)=>{
        var newData = process(this.state.reports, e.data);
            this.setState({dataState:e.data, data:newData});

        
      }


    render() {
        const feeMethodData = [{label: "Gross", value: "0"}, {label: "Net", value: "1"}]
        const layoutData = [{label: "Landscape", value: "l"}, {label: "Portrait", value: "p"}]


        return (
            <div className="reportList">
                <Grid
                    //style={{ height: '420px' }}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.reports)}
                    onRowClick={(e) => this.selectionChange(e, this.state.reports)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.reports)}
                    onDataStateChange={this.changeDataState}
                    data={this.state.data}
                    headerCellRender={this.headerCellRender}
                    rowHeight={50}
                    //onItemChange={this.itemChange}
                    filterable={true}
                    resizable
                    sortable={{
                        mode: 'multiple'
                    }}
                    //cellRender={this.renderers.cellRender}
                    //rowRender={this.renderers.rowRender}

                    editField="inEdit"
                    {...this.state.dataState}
                >
                  <GridNoRecords>
                                    {this.state.loading && ''}
                                    {!this.state.loading && 'No records available'}
                                </GridNoRecords>                    
                    <GridToolbar>
                    </GridToolbar>
                    {/* <GridColumn filterable={false} width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={false} /> */}
                    <GridColumn field="style" title="Report Style" width="225px" filterable={true} filterCell={createFilterDropDownCell(this.state.reportStylesText, '')} />{/*  */}
                    <GridColumn field="name" title="Report Name" width="500px" filterable={true} />
                    <GridColumn field="title" title="Report Title" width="500px" filterable={true} />
                    <GridColumn headerClassName='gridHeader' field="runReport" title=" " width="120px" filterCell={(props) => { return (<></>) }} cell={(props) => this.createCell(props)} />
                </Grid>
                {this.state.loading && <GridLoading gridClass='packageReview' />}
            </div>
        );
    }

}

//ReactDOM.render(
//    <App />,
//    document.querySelector('my-app')
//);

export default Reporting