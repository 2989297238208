import React, { Component } from "react";
import HoldingsSummary from '../components/HoldingsSummary';

class HoldingsSummaryDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }


  render() {
    return(
        <div className='holdingsDash'>
            <HoldingsSummary user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} portfolio={this.props.portfolio} household={this.props.household} idToken={this.props.idToken} />
        </div>
    );
  }

}

export default HoldingsSummaryDashboard;