import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
//import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { parseDate } from '@telerik/kendo-intl';
import { Grid, Row, Col } from "react-flexbox-grid";
import { LoopCircleLoading } from 'react-loadingg';
import InputTextField from './InputTextField';
import InputDate from './InputDate';
import InputBoolean from './InputBoolean';
import InputDropdown from './InputDropdown';
import InputPortfolioDropdown from './InputPortfolioDropdown';
import ReactLoading from 'react-loading';

class ReportDefaultPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      households: this.props.households,
      result: this.props.households,
      selectedHousehold: '',
      display: '',
      checkTimerId: null,
      reportParametersFlag: false,
      unsavedChanges: false
    }

    //this.saveDefaultParameters = this.saveDefaultParameters.bind(this)
    //this.clearState = this.clearState.bind(this)
    // this.getParameterDefaults = this.getParameterDefaults.bind(this)
    // this.getReportParameters = this.getReportParameters.bind(this)
    // this.checkParamTracking = this.checkParamTracking.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    clearInterval(this.state.checkTimerId)
  }

  getGenericDates() {
    var dates = []

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetAllGenericDates`, {
      method: 'GET',
      params: { database: this.props.user.company },
      headers: headers,
    })
      .then(response => {
        response.data.data.forEach(dateInResponse => {
          dates.push({ label: dateInResponse.label, value: dateInResponse.value })
        })
        this.setState({ genericDates: dates }, () => console.log(this.state.genericDates))
      })
      .catch(err => {
        console.error(err);
      })

    const genericDates = [
      { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' },
      { value: 'bblq', label: 'bblq - Beginning Business Day Last Quarter' },
      { value: 'bbly', label: 'bbly - Beginning Business Day Last Year' },
      { value: 'bbpm', label: 'bbpm - Beginning Business Day Previous Month' },
      { value: 'bbpq', label: 'bbpq - Beginning Business Day Previous Quarter' },
      { value: 'bbpy', label: 'bbpy - Beginning Business Day Previous Year' },
      { value: 'bbtm', label: 'bbtm - Beginning Business Day This Month' },
      { value: 'bbtq', label: 'bbtq - Beginning Business Day This Quarter' },
      { value: 'bbty', label: 'bbty - Beginning Business Day This Year' },
      { value: 'bbnm', label: 'bbnm - Beginning Business Day Next Month' },
      { value: 'bbnq', label: 'bbnq - Beginning Business Day Next Quarter' },
      { value: 'bbny', label: 'bbny - Beginning Business Day Next Year' },
      { value: 'bdlm', label: 'bdlm - Beginning Day Last Month' },
      { value: 'bdlq', label: 'bdlq - Beginning Day Last Quarter' },
      { value: 'bdly', label: 'bdly - Beginning Day Last Year' },
      { value: 'bdpm', label: 'bdpm - Beginning Day Previous Month' },
      { value: 'bdpq', label: 'bdpq - Beginning Day Previous Quarter' },
      { value: 'bdpy', label: 'bdpy - Beginning Day Previous Year' },
      { value: 'bdtm', label: 'bdtm - Beginning Day This Month' },
      { value: 'bdtq', label: 'bdtq - Beginning Day This Quarter' },
      { value: 'bdty', label: 'bdty - Beginning Day This Year' },
      { value: 'bdnm', label: 'bdnm - Beginning Day Next Month' },
      { value: 'bdnq', label: 'bdnq - Beginning Day Next Quarter' },
      { value: 'bdny', label: 'bdny - Beginning Day Next Year' },
      { value: 'eblm', label: 'eblm - Ending Business Day Last Month' },
      { value: 'eblq', label: 'eblq - Ending Business Day Last Quarter' },
      { value: 'ebly', label: 'ebly - Ending Business Day Last Year' },
      { value: 'ebpm', label: 'ebpm - Ending Business Day Previous Month' },
      { value: 'ebpq', label: 'ebpq - Ending Business Day Previous Quarter' },
      { value: 'ebpy', label: 'ebpy - Ending Business Day Previous Year' },
      { value: 'ebtm', label: 'ebtm - Ending Business Day This Month' },
      { value: 'ebtq', label: 'ebtq - Ending Business Day This Quarter' },
      { value: 'ebty', label: 'ebty - Ending Business Day This Year' },
      { value: 'ebnm', label: 'ebnm - Ending Business Day Next Month' },
      { value: 'ebnq', label: 'ebnq - Ending Business Day Next Quarter' },
      { value: 'ebny', label: 'ebny - Ending Business Day Next Year' },
      { value: 'edlm', label: 'edlm - Ending Day Last Month' },
      { value: 'edlq', label: 'edlq - Ending Day Last Quarter' },
      { value: 'edly', label: 'edly - Ending Day Last Year' },
      { value: 'edpm', label: 'edpm - Ending Day Previous Month' },
      { value: 'edpq', label: 'edpq - Ending Day Previous Quarter' },
      { value: 'edpy', label: 'edpy - Ending Day Previous Year' },
      { value: 'edtm', label: 'edtm - Ending Day This Month' },
      { value: 'edtq', label: 'edtq - Ending Day This Quarter' },
      { value: 'edty', label: 'edty - Ending Day This Year' },
      { value: 'ednm', label: 'ednm - Ending Day Next Month' },
      { value: 'ednq', label: 'ednq - Ending Day Next Quarter' },
      { value: 'edny', label: 'edny - Ending Day Next Year' },
      { value: 'yest', label: 'yest - Yesterday' },
      { value: 'last', label: 'last - Last Business Day' },
      { value: 'prev', label: 'prev - Next To Last Business Day' }
    ]

    this.setState({
      genericDates_singleDate_Data: genericDates,
      genericDates_fromDate_Data: genericDates,
      genericDates_toDate_Data: genericDates,
      genericDates_singleDate_DataOriginal: genericDates,
      genericDates_fromDate_DataOriginal: genericDates,
      genericDates_toDate_DataOriginal: genericDates,
    })


  }

  mapAcct() {
    var mapping = {
      household: this.state.selectedHousehold.household
      , account: this.props.account
    }
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      company: this.props.company,
      mapping: mapping
    }


    axios.post('api/mapAccountHousehold', payload, { headers }).then(response => {

    }).catch(err => {
      console.log(err);
    });


  }

  getReportParameters() {
    this.checkParamTracking();
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var report = this.props.report.name;
    var parameters = [];
    //var adjustedDefaultValue = null

    if (this.props.report.adjustableLayout === true) {
      report = report + "_Landscape"
    }

    //axios
    //  .post('api/ssrsGetReportParameters', payload, { headers })
    axios(`api/ssrsGetReportParameters?company=${this.props.user.company}&report=` + report, {
      method: 'GET',
      headers: headers
    })
      .then(response => {

        this.setState({ paramTrackingCount: 0, reportParameters: response.data.data })
        //begin loop through response
        //response.data.data.map((individualParameter) => {
        response.data.data.forEach(individualParameter => {
          if (individualParameter.ParamName !== "database") {
            const t = "SSRSParam_".concat([individualParameter.ParamName])

            var adjustedDefaultValue = null//this.getThisDefaultValue(this.props.report.name, individualParameter.ParamName)
            console.log(individualParameter.ParamName)
            console.log(adjustedDefaultValue)

            if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
              //   parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: this.state.entities[0], values: this.state.entities, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
              //   parameters.sort(this.compareParamOrder)
              //   this.setState({
              //     [t]: [], [t.concat("_Data")]: this.state.entities, [t.concat("_DataOriginal")]: this.state.entities, [t.concat("_Validate")]: true
              //   })
            }
            else {
              if (individualParameter.ParamDataType === "String" && individualParameter.ValidValues !== "") {
                var values = []
                var XMLParser = require('react-xml-parser');
                var xml = new XMLParser().parseFromString(individualParameter.ValidValues);
                xml.getElementsByTagName('ValidValue').forEach(value => {
                  values.push({ value: value.children[0].value, label: value.children[1].value })
                })

                if (individualParameter.DefaultValues !== null) {
                  const defaultValue = values.find(item => item.value === individualParameter.DefaultValues);
                  adjustedDefaultValue = values.find(item => item.value === adjustedDefaultValue);
                  console.log(individualParameter.DefaultValues)
                  console.log(adjustedDefaultValue)
                  console.log(defaultValue)

                  //if (defaultValue){adjustedDefaultValue = {value: adjustedDefaultValue, label: defaultValue.label}}

                  parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                  parameters.sort(this.compareParamOrder)
                  this.setState({
                    [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                  })
                }
                else {
                  parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || values[0], values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                  parameters.sort(this.compareParamOrder)
                  this.setState({
                    [t]: adjustedDefaultValue || values[0], [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                  })
                }
              }
              else if (individualParameter.DynamicValidValues === "True") {
                this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                this.getValidValues(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                  if (callback !== false) {
                    var lowerValues = []
                    callback.forEach(obj => {
                      var key, keys = Object.keys(obj);
                      var n = keys.length;
                      var newobj = {}
                      while (n--) {
                        key = keys[n];
                        newobj[key.toLowerCase()] = obj[key];
                      }
                      lowerValues.push(newobj)
                    })

                    if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                      var splitLine
                      var code
                      var name
                      var type
                      var formattedLine
                      var newLowerValues = []
                      lowerValues.forEach(line => {
                        splitLine = line.label.split('|')
                        code = this.getSpacedLine(splitLine[0], 12)
                        name = this.getSpacedLine(splitLine[1], 32)
                        type = this.getSpacedLine(splitLine[2], 8)

                        formattedLine = code + " | " + name + " | " + type
                        newLowerValues.push({ value: line.value, label: formattedLine })
                      })
                      lowerValues = newLowerValues

                    }


                    if (individualParameter.DefaultValues !== null || individualParameter.ParamName === "equityAssetClass") {
                      const defaultValue = lowerValues.find(item => item.value === individualParameter.DefaultValues);
                      adjustedDefaultValue = lowerValues.find(item => item.value === adjustedDefaultValue);
                      parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                      parameters.sort(this.compareParamOrder)
                      this.setState({
                        [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                      })
                    }
                    else if (individualParameter.DefaultValues === null && individualParameter.DynamicDefaultValue === "True") {
                      console.log(individualParameter.ParamName)
                      this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                      this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                        if (callback !== "false") {
                          const lowerDefaultValue = []
                          callback.forEach(obj => {
                            var key, keys = Object.keys(obj);
                            var n = keys.length;
                            var newobj = {}
                            while (n--) {
                              key = keys[n];
                              newobj[key.toLowerCase()] = obj[key];
                            }
                            lowerDefaultValue.push(newobj)
                          })
                          console.log(lowerValues)
                          const defaultValue = lowerValues.find(item => item.value === lowerDefaultValue[0].value);
                          console.log(adjustedDefaultValue)
                          adjustedDefaultValue = null//this.getThisDefaultValue(this.props.report.name, individualParameter.ParamName)
                          adjustedDefaultValue = lowerValues.find(item => item.label === adjustedDefaultValue);
                          console.log(adjustedDefaultValue)

                          if (individualParameter.ParamName === 'Disclaimer') {
                            lowerValues.forEach(v => {
                              v.value = v.label
                            })
                            if (adjustedDefaultValue)
                              adjustedDefaultValue.value = adjustedDefaultValue.label
                          }
                          parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                          parameters.sort(this.compareParamOrder)
                          this.setState({
                            [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                          })
                        }
                        this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                      })

                    }
                    else {
                      parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: lowerValues[0], values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                      parameters.sort(this.compareParamOrder)
                      if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                        this.setState({
                          [t]: [], [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                        })
                      }
                      else {
                        this.setState({
                          [t]: '', [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                        })
                      }
                    }

                  }
                  this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                })

                //parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                //this.setState({
                //  [t]: individualParameter.DefaultValues
                //})

              }
              else if (individualParameter.DynamicDefaultValue === "True" && individualParameter.ParamDataType === "DateTime") {
                this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                  if (callback !== false) {
                    const lowerDefaultValue = []
                    callback.forEach(obj => {
                      var key, keys = Object.keys(obj);
                      var n = keys.length;
                      var newobj = {}
                      while (n--) {
                        key = keys[n];
                        newobj[key.toLowerCase()] = obj[key];
                      }
                      lowerDefaultValue.push(newobj)
                    })
                    var defaultDate = this.getDate(lowerDefaultValue[0].value, true)
                    this.setState({ [individualParameter.ParamName.concat('_OriginalDefault')]: defaultDate })//asdf
                    parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: defaultDate, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                    parameters.sort(this.compareParamOrder)
                    this.setState({
                      [t]: defaultDate, [t.concat("_Validate")]: true
                    })


                  }
                  this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                })

                //parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                //this.setState({
                //  [t]: individualParameter.DefaultValues
                //})
              }
              else {
                parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || individualParameter.DefaultValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                parameters.sort(this.compareParamOrder)
                if (individualParameter.DefaultValues !== null) {
                  this.setState({
                    [t]: adjustedDefaultValue || individualParameter.DefaultValues, [t.concat("_Validate")]: true
                  })
                }
                else {
                  this.setState({
                    [t]: '', [t.concat("_Validate")]: true
                  })
                }
              }
            }

          }

        })
        //end loop through response
        this.setState({ parameterList: parameters }, () => {
          //this.getParameterDefaults()
          //this.setState({ reportParametersFlag: true, runReportButtonFlag: true })

        });
      })
      .catch(err => {
        console.error(err);
      })
    //return reports;
    console.log(parameters)

    if (this.props.report.adjustableLayout === true) {
      //this.setState({ reportParametersFlag: true })
    }

  }

  getParameterDefaults() {
    clearInterval(this.state.checkTimerId)
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var report = this.props.report.name;
    var parameterList = []
    parameterList = parameterList.concat(this.state.parameterList)
    var originalParameterList = JSON.parse(JSON.stringify(parameterList))
    axios(`api/ssrsGetParameterDefaults`, {
      method: 'GET',
      params: { report: report, company: this.props.user.company },
      headers: headers
    })
      .then(response => {

        if (response.data.code === 200) {
          parameterList.forEach(param => {
            response.data.data.forEach(defaultParam => {
              if (param.name === defaultParam.paramName) {
                if (param.type === "Dropdown") {
                  const newDefault = param.values.find(item => item.value === defaultParam.value)
                  param.defaultValue = newDefault
                  this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                }
                else if (param.type === "Boolean") {
                  const newDefault = (defaultParam.value === "True" ? true : (defaultParam.value === "False" ? false : defaultParam.value))
                  param.defaultValue = newDefault
                  this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                }
                else if (param.type === "DateTime") {
                  //this.state.genericDates_singleDate_Data
                  if (defaultParam.value.length === 4) {//if generic date
                    const newDefault = this.state["genericDates_" + defaultParam.paramName + "_Data"].find(item => item.value === defaultParam.value)
                    param.defaultValue = newDefault
                    this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault, [defaultParam.paramName + "GenericDateToggle"]: true })
                  }
                  else {
                    const newDefault = this.getDate(defaultParam.value, true)
                    param.defaultValue = newDefault
                    this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                  }
                }
                else {
                  param.defaultValue = defaultParam.value
                  this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: defaultParam.value })
                }
                param.defaultSet = true
              }
            })
          })
          console.log(parameterList)
          originalParameterList.forEach(originalParam => {
            parameterList.forEach(param => {
              if (originalParam.name === param.name && param.defaultSet){
                originalParam.defaultSet = param.defaultSet
              }
            })
          })
          this.setState({ parameterList: parameterList, originalParameterList: originalParameterList, reportParametersFlag: true, runReportButtonFlag: true })
        }
        else {
          alert("Error getting report defaults")
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getDefaultValue = (report, parameter, callback) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetParameterDefaultValueQuery`, {
      method: 'GET',
      params: { report: report, parameter: parameter, company: this.props.user.company },
      //responseType: 'blob', //Force to receive data in a Blob Format
      headers: headers
    })
      .then(response => {

        if (response.data === "no data") {
          callback(false)
        }
        else {
          callback(response.data)
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  compareParamOrder(a, b) {
    if (parseInt(a.sortOrder) < parseInt(b.sortOrder)) {
      return -1;
    }
    if (parseInt(a.sortOrder) > parseInt(b.sortOrder)) {
      return 1;
    }
    return 0;
  }

  getDate(sqlDate, isUTC) {
    var d;
    if (parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")) {
      d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")
    }
    else {
      d = parseDate(sqlDate, "MM/dd/yyyy HH:mm:ss")
    }

    if (isUTC) {
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      return utc;
    }

    else
      return d;
  }

  getValidValues = (report, parameter, callback) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetParameterValidValuesQuery`, {
      method: 'GET',
      params: { report: report, parameter: parameter, company: this.props.user.company },
      //responseType: 'blob', //Force to receive data in a Blob Format
      headers: headers
    })
      .then(response => {

        if (response.data === "no data") {
          callback(false)
        }
        else {
          callback(response.data)
        }
      })
      .catch(error => {
        console.error(error);
      });

  }

  resetDefault(parameter) {
    console.log(parameter)
    var parameterList = JSON.parse(JSON.stringify(this.state.parameterList))
    //this.setState({parameterList: []})
    console.log(this.state.originalParameterList)
    var originalParameter = this.state.originalParameterList.find(item => item.name === parameter.name)
    console.log(this.state.originalParameterList)
    var newParameterList = []

    // const newDefault = param.values.find(item => item.value === defaultParam.value)
    //               param.defaultValue = newDefault
    //               this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })

    parameterList.forEach(p => {
      if (p.name === originalParameter.name) {
        console.log(originalParameter)
        originalParameter.defaultSet = false
        newParameterList.push(originalParameter)
        if (p.name = 'singleDate' || 'fromDate' || 'toDate') {
          this.setState({ [p.name + "GenericDateToggle"]: false })
        }
        //this.setState({ ["SSRSParam_".concat([parameter.name])]: originalParameter.defaultValue })
        //this.forceUpdate();
      }
      else {
        newParameterList.push(p)
      }
    })

    console.log(newParameterList);
    this.setState({ parameterList: newParameterList, ["SSRSParam_".concat([parameter.name])]: originalParameter.defaultValue })
  }

  _handleChange = e => {
    const t = "SSRSParam_".concat([e.target.props.name])
    this.validate(t)
    this.state.parameterList.forEach(p => {
      if (p.name === e.target.props.name) {
        p.defaultSet = true
      }
    })

    this.state.originalParameterList.forEach(p => {
      if (p.name === e.target.props.name) {
        p.defaultSet = true
      }
    })

    this.setState({
      [t]: e.target.value, unsavedChanges: true
    })
  }

  _filterChange = (event) => {
    console.log(event)
    const dataStateToBeFiltered = "SSRSParam_" + event.target.name + "_Data"
    const dataStateContainingOriginalValues = this.state["SSRSParam_" + event.target.name + "_DataOriginal"]
    if (event.target.name === "PortfolioList") {
      event.filter.field = "label"
    }

    this.setState({
      [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
    });
  }

  _filterChangeGenericDate = (event) => {
    console.log(event)
    event.filter.field = "value"
    const dataStateToBeFiltered = "genericDates_" + event.target.name + "_Data"
    const dataStateContainingOriginalValues = this.state["genericDates_" + event.target.name + "_DataOriginal"]//asdf

    this.setState({
      [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
    });
  }

  filterData(filter, originalData) {
    //const data = allData.slice();
    return filterBy(originalData, filter);
  }

  validate(input) {
    var flag = true;
    var isBlank = false;
    var isIncorrectDate = false;

    const { parameterList } = this.state
    if (input === undefined) {//check every parameter, e.g. for when user clicks Run Report
      parameterList.map(form => {
        if (form.allowBlank.toLowerCase() === "false" && (this.state["SSRSParam_" + form.name] === "" || this.state["SSRSParam_" + form.name] === null || this.state["SSRSParam_" + form.name].length === 0)) {

          this.setState({ ["SSRSParam_" + form.name + "_Validate"]: false })
          flag = false
          isBlank = true

        }
        if (form.name.toLowerCase() === "fromdate" && (!this.state.singleDateGenericDateToggle && !this.state.fromDateGenericDateToggle && !this.state.toDateGenericDateToggle)) {
          if (this.getDate(this.state.SSRSParam_fromDate, false).getTime() > this.getDate(this.state.SSRSParam_toDate, false).getTime()) {
            this.setState({ SSRSParam_fromDate_Validate: false, SSRSParam_toDate_Validate: false })
            flag = false
            isIncorrectDate = true
          }


        }
      })

    }
    else {
      if (this.state[input + "_Validate"] === false) {
        this.setState({ [input + "_Validate"]: true })

        if (input.toLowerCase().includes("date")) {
          const { ...inputFields } = this.state
          Object.entries(inputFields).map(([key, value]) => {
            if (key.toLowerCase().includes("date_") && key.includes("_Validate") && value !== null) {
              this.setState({ [key]: true })
            }
          })
        }

      }
    }

    if (flag === false) {
      var errorString = ''
      if (isBlank) {
        errorString = errorString.concat('Highlighted fields must not be blank.\r\n')
      }
      if (isIncorrectDate) {
        errorString = errorString.concat('Begin Date must precede End Date.\r\n')
      }
      alert(errorString);
    }

    return flag;

  }

  onGenericToggleChangeHandler = (value, type) => {
    console.log(value)
    this.setState({ [type]: value })

    if (type === 'singleDateGenericDateToggle') {
      if (value === true) {
        this.setState({ SSRSParam_singleDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
      }
      else {
        this.setState({ SSRSParam_singleDate: this.state.singleDate_OriginalDefault })
        console.log(this.state.singleDate_OriginalDefault)
      }
    }
    else if (type === 'fromDateGenericDateToggle') {
      if (value === true) {
        this.setState({ SSRSParam_fromDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
      }
      else {
        this.setState({ SSRSParam_fromDate: this.state.fromDate_OriginalDefault })
        console.log(this.state.fromDate_OriginalDefault)
      }
    }
    else if (type === 'toDateGenericDateToggle') {
      if (value === true) {
        this.setState({ SSRSParam_toDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
      }
      else {
        this.setState({ SSRSParam_toDate: this.state.toDate_OriginalDefault })
        console.log(this.state.toDate_OriginalDefault)
      }
    }
  }



  onUserChange = (e) => {
    this.setState({ selectedHousehold: e.target.value });
  }

  checkParamTracking() {
    var checkTimer = setInterval(
      function () {
        if (this.state.paramTrackingCount === 0) {
          clearInterval(checkTimer)
          this.getParameterDefaults()
        }

      }
        .bind(this),
      2000
    )

    this.setState({ checkTimerId: checkTimer })
  }

  clearState = (e) => {
    clearInterval(this.state.checkTimerId)

    this.setState({
      parameterList: [],
      originalParameterList: [],
      layout: null, layoutFlag: false,
      singleDateGenericDateToggle: false,
      fromDateGenericDateToggle: false,
      toDateGenericDateToggle: false,
      paramTrackingCount: null,
      checkTimerId: null,
      reportParametersFlag: false,
      unsavedChanges: false,
    })

    const { ...stateObject } = this.state
    Object.entries(stateObject).map(([key, value]) => {
      if (key.includes("SSRSParam_")) {
        this.setState({ [key]: null })
      }
    })



    this.props.refresh();
  }

  formatDate(date) {
    var d;
    var ds;
    if (parseDate(date, "yyyy-MM-ddTHH:mm:ss.SSSXXX")) {
        d = parseDate(date, "yyyy-MM-ddTHH:mm:ss.SSSXXX")
        ds = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear()
        console.log(d.getMonth() + 1 + " " + d.getDate() + " " + d.getFullYear())
    }
    else {
        d = parseDate(date, "MM/dd/yyyy HH:mm:ss")
        ds = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear()
        console.log(d.getMonth() + 1 + " " + d.getDate() + " " + d.getFullYear())
    }
    return ds;
}

  saveDefaultParameters(cb) {
    var paramsToSave = []
    const { ...inputFields } = this.state
    this.state.originalParameterList.forEach(param => {
      Object.entries(inputFields).map(([key, value]) => {
        if (key.includes("SSRSParam_") && value !== null && !key.includes("_Data") && !key.includes("_DataOriginal") && !key.includes("_Validate")) {
          if (param.type === "Boolean") {
            if (key.split('_')[1] === param.name && (value !== param.defaultValue || param.defaultSet)) {
              console.log(key, value, param.defaultValue, param.type)
              paramsToSave.push({ paramName: key.split('_')[1], paramValue: (value === true ? "True" : "False"), paramLabel: param.label, valueText: (value === true ? "True" : "False") })
            }
          }
          else if (param.type === "DateTime" && value && !value.label) {//the abscence of value.label means that this is not a generic date
            if (key.split('_')[1] === param.name && (this.getDate(value, true).getTime() !== this.getDate(param.defaultValue, true).getTime() || param.defaultSet)) {
              paramsToSave.push({ paramName: key.split('_')[1], paramValue: value, paramLabel: param.label, valueText: this.formatDate(value) })
            }
          }
          else {
            if (value && value.label) {
              if (key.split('_')[1] === param.name && ((param.defaultValue && value.value !== param.defaultValue.value) || param.defaultSet)) {
                console.log(key, value, param.defaultValue, param.type)
                paramsToSave.push({ paramName: key.split('_')[1], paramValue: value.value, paramLabel: param.label, valueText: value.label })
              }
            }
            else {
              if (key.split('_')[1] === param.name && (value !== param.defaultValue || param.defaultSet)) {
                console.log(key, value, param.defaultValue, param.type)
                paramsToSave.push({ paramName: key.split('_')[1], paramValue: value, paramLabel: param.label, valueText: value })
              }
            }
          }
        }
      })
    })
    console.log(paramsToSave)
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    // var params = {
    //     company: this.props.user.company,
    //     parameters: paramsToSave,
    //     reportName: this.props.report.name
    // }

    var payload = {
      company: this.props.user.company,
      parameters: paramsToSave,
      reportName: this.props.report.name
    };
    axios.post('api/ssrsSaveParameterDefaults', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        alert("Settings successfully saved")
        cb(true)

      }
      else {
        console.log(1);
        alert("Unable to save settings")
        cb()
      }
    })
      .catch(err => {
        console.log(err);
        //alert("Unable to save settings")
        cb()
      })
  }

  checkUnsaved(cb){
    if (this.state.unsavedChanges){
      var answer = window.confirm("You have unsaved changes. Close anyway?");
      if (answer) {
          cb(true)
      }
      else {
        cb(false)
      }      
    }
    else{
      cb(true)
    }
  }


  render() {
    var textFieldWidth = '200px'
    const paramWidth = 225
    return (
      <Popup closeOnDocumentClick={false} width='50%' trigger={this.props.button} onOpen={(e) => { this.getReportParameters(); this.getGenericDates(); }} onClose={this.clearState} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">
            <Button className="close" icon="close" onClick={(e) => { this.checkUnsaved((cb) => {if (cb) {close()}})  }} />


            <div className="header" align="left">
              Edit Default Values: {this.props.report.name}
            </div>

            <div className="content">
              {this.state.reportParametersFlag && this.state.paramTrackingCount === 0 &&
                <table align='left'>
                  <tbody>
                    {this.state.parameterList.map(form => {
                      const t = "SSRSParam_".concat([form.name])
                      if (form.type === "String") {
                        if (form.name === "ReportTitle") {
                          textFieldWidth = '220px'
                        }
                        else {
                          textFieldWidth = '200px'
                        }
                        return (
                          <tr>
                            <td width={paramWidth}>

                              <InputTextField
                                name={form.name}
                                input_type={form.type}
                                label={form.label}
                                key={form.label}
                                defaultValue={form.defaultValue}
                                textFieldWidth={textFieldWidth}
                                valid={this.state[t.concat("_Validate")]}
                                defaultSet={form.defaultSet}
                                value={this.state[t]}
                                _handleChange={this._handleChange}
                              />
                            </td>
                            <td><Button onClick={(e) => this.resetDefault(form)} disabled={!form.defaultSet}>Reset</Button></td>
                          </tr>
                        )
                      }
                      if (form.type === "DateTime" && form.name === 'singleDate') {
                        return (
                          <tr>
                            <td width={paramWidth}>

                              {!this.state.singleDateGenericDateToggle &&
                                <div>
                                  <InputDate
                                    name={form.name}
                                    label={form.label}
                                    key={form.label}
                                    defaultValue={form.defaultValue}
                                    valid={this.state[t.concat("_Validate")]}
                                    value={this.state[t]}
                                    defaultSet={form.defaultSet}
                                    _handleChange={this._handleChange}
                                  />
                                  <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'singleDateGenericDateToggle')} icon="list-unordered"></Button>
                                </div>
                              }
                              {this.state.singleDateGenericDateToggle &&
                                <div>
                                  <InputDropdown
                                    name={form.name}
                                    label={form.label}
                                    data={this.state.genericDates_singleDate_Data}
                                    value={this.state[t]}
                                    key={form.label}
                                    valid={this.state[t.concat("_Validate")]}
                                    defaultSet={form.defaultSet}
                                    _filterChange={this._filterChangeGenericDate}
                                    _handleChange={this._handleChange}
                                  />
                                  <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'singleDateGenericDateToggle')} icon="calendar"></Button>
                                </div>
                              }

                            </td>
                            <td><Button onClick={(e) => this.resetDefault(form)} disabled={!form.defaultSet}>Reset</Button></td>
                          </tr>

                        )
                      }
                      if (form.type === "DateTime" && form.name === 'fromDate') {
                        return (
                          <tr>
                            <td width={paramWidth}>

                              {!this.state.fromDateGenericDateToggle &&
                                <div>
                                  <InputDate
                                    name={form.name}
                                    label={form.label}
                                    key={form.label}
                                    value={this.state[t]}
                                    defaultValue={form.defaultValue}
                                    valid={this.state[t.concat("_Validate")]}
                                    defaultSet={form.defaultSet}
                                    _handleChange={this._handleChange}
                                  />
                                  <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'fromDateGenericDateToggle')} icon="list-unordered"></Button>
                                </div>
                              }
                              {this.state.fromDateGenericDateToggle &&
                                <div>
                                  <InputDropdown
                                    name={form.name}
                                    label={form.label}
                                    data={this.state.genericDates_fromDate_Data}
                                    value={this.state[t]}
                                    key={form.label}
                                    valid={this.state[t.concat("_Validate")]}
                                    defaultSet={form.defaultSet}
                                    _filterChange={this._filterChangeGenericDate}
                                    _handleChange={this._handleChange}
                                  />
                                  <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'fromDateGenericDateToggle')} icon="calendar"></Button>
                                </div>
                              }

                            </td>
                            <td><Button onClick={(e) => this.resetDefault(form)} disabled={!form.defaultSet}>Reset</Button></td>
                          </tr>
                        )
                      }
                      if (form.type === "DateTime" && form.name === 'toDate') {
                        return (
                          <tr>
                            <td width={paramWidth}>

                              {!this.state.toDateGenericDateToggle &&
                                <div>
                                  <InputDate
                                    name={form.name}
                                    label={form.label}
                                    key={form.label}
                                    value={this.state[t]}
                                    defaultValue={form.defaultValue}
                                    valid={this.state[t.concat("_Validate")]}
                                    defaultSet={form.defaultSet}
                                    _handleChange={this._handleChange}
                                  />
                                  <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'toDateGenericDateToggle')} icon="list-unordered"></Button>
                                </div>
                              }
                              {this.state.toDateGenericDateToggle &&
                                <div>
                                  <InputDropdown
                                    name={form.name}
                                    label={form.label}
                                    data={this.state.genericDates_toDate_Data}
                                    value={this.state[t]}
                                    key={form.label}
                                    valid={this.state[t.concat("_Validate")]}
                                    defaultSet={form.defaultSet}
                                    _filterChange={this._filterChangeGenericDate}
                                    _handleChange={this._handleChange}
                                  />
                                  <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'toDateGenericDateToggle')} icon="calendar"></Button>
                                </div>
                              }

                            </td>
                            <td><Button onClick={(e) => this.resetDefault(form)} disabled={!form.defaultSet}>Reset</Button></td>
                          </tr>
                        )
                      }
                      if (form.type === "Boolean") {
                        var defaultChecked = ''
                        var checked = false
                        // if (form.defaultValue.toLowerCase() === 'true') {
                        //   defaultChecked = true
                        // }
                        // else if (form.defaultValue.toLowerCase() === 'false') {
                        //   defaultChecked = false
                        // }
                        // if (this.state[t].toLowerCase() === 'true') {
                        //   checked = true
                        // }
                        // else if (this.state[t].toLowerCase() === 'false') {
                        //   checked = false
                        // }
                        return (
                          <tr>
                            <td width={paramWidth}>

                              <InputBoolean
                                name={form.name}
                                label={form.label}
                                key={form.label}
                                value={this.state[t]}
                                defaultChecked={this.state[t]}
                                valid={this.state[t.concat("_Validate")]}
                                defaultSet={form.defaultSet}
                                _handleChange={this._handleChange}
                              />
                            </td>
                            <td><Button onClick={(e) => this.resetDefault(form)} disabled={!form.defaultSet}>Reset</Button></td>
                          </tr>
                        )
                      }
                      if (form.type === "Dropdown" && (form.name === "PortfolioTest" || form.name === "PortfolioList")) {
                        return (
                          <tr>
                            <td width={paramWidth}>

                              <InputPortfolioDropdown
                                name={form.name}
                                label={form.label}
                                data={this.state[t.concat("_Data")]}
                                value={this.state[t]}
                                key={form.label}
                                valid={this.state[t.concat("_Validate")]}
                                _filterChange={this._filterChange}
                                _handleChangePortfolio={this._handleChangePortfolio}
                                _itemRender={this._itemRender}
                              />
                              <Button onClick={(e) => this.consolidateGroups()} >Consolidate</Button>
                            </td>
                            <td><Button onClick={(e) => this.resetDefault(form)} disabled={!form.defaultSet}>Reset</Button></td>
                          </tr>
                        )
                      }
                      else if (form.type === "Dropdown") {
                        return (
                          <tr>
                            <td width={paramWidth}>

                              <InputDropdown
                                name={form.name}
                                label={form.label}
                                data={this.state[t.concat("_Data")]}
                                value={this.state[t]}
                                key={form.label}
                                valid={this.state[t.concat("_Validate")]}
                                defaultSet={form.defaultSet}
                                _filterChange={this._filterChange}
                                _handleChange={this._handleChange}
                              />
                            </td>
                            <td><Button onClick={(e) => this.resetDefault(form)} disabled={!form.defaultSet}>Reset</Button></td>
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </table>
              }


              {(!this.state.reportParametersFlag || this.state.paramTrackingCount !== 0) &&
                <div align='center'><ReactLoading type='spin' color='grey' height={100} width={100} /></div>
              }
            </div>

            {this.state.reportParametersFlag && this.state.paramTrackingCount === 0 &&
              <div className="actions">

                <Button className="button" onClick={() => { this.checkUnsaved((cb) => {if (cb) {close()}})  }}>
                  Close
                </Button>

                <Button className="upload"
                  //onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })}>
                  onClick={(e) => {
                    this.saveDefaultParameters((cb) => {
                      //this.getMenuItems();
                      //this.clearState();
                      if (cb) {
                        close();
                      }
                    })
                  }}>


                  Save Changes
                </Button>

              </div>
            }


          </div>
        )}
      </Popup>

    );
  };

}

export default ReportDefaultPopup;