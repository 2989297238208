import React, { Component } from "react";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import chBlank from './components/images/chBlank.png'
import homeDocs from './components/images/homeDocs.png'
import homeAccessDocs from './components/images/homeAccessDocs.png'
import homeWeb from './components/images/homeWeb.png'
import homeMkt from './components/images/homeMkt.png'
import homeContact from './components/images/homeContact.png'
import chPlaceholder from './components/images/chPlaceholder.png'
import chbg from './components/images/chbg.jpg'
import ReactLoading from 'react-loading';




class Template1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            associatedHousehold: this.props.CHassociatedHousehold != null ? this.props.CHassociatedHousehold : null,
            csTeamLoaded: false,
            members: [],
            test: [],
            loading: true,
            imageLoading: true,
            styleSettings: {
                themePrimaryColor: null
            },
            docAccess: null,
            settings: {
                headerText: null,
                headerFontStyle: null,
                headerFontSize: null,
                headerFontColor: null,
                backgroundImage: null,
                leftSubheaderText: null,
                leftSubheaderColor: null,
                documentsLinkText: null,
                documentsLinkColor: null,
                accountsLinkText: null,
                accountsLinkColor: null,
                rightSubheaderText: null,
                rightSubheaderColor: null,
                websiteLinkText: null,
                websiteLinkUrl: null,
                websiteLinkColor: null,
                additionalLinkText: null,
                additionalLinkUrl: null,
                additionalLinkColor: null,
                bottomImage: null,
                bottomImageHeaderText: null,
                bottomImageHeaderColor: null, 
                showAdditionalLink: null,
                showWebsiteLink: null,
                backgroundImageFade: null,
            }
        }

        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);

    }


    componentDidMount() {
        //this.getCsTeamDedicated();  
        this.getDocAccessStatus(); 
        this.getStyleSettings();     
        this.getTemplate1Images(() => {
            this.getTemplate1Settings();
        });
    }

    getDocAccessStatus() {
        var accessArray = this.props.user.access;

        var docAccess = accessArray.find(a =>a.includes("doc"));
        
        this.setState({docAccess: docAccess}) 
    }

    getStyleSettings() {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
        var styleNames = ['themePrimaryColor', 'RIAFirmName']
    
        axios(`api/getStyleSettings`, {
          method: 'GET',
          params: { company: this.props.user.company, styleNames: styleNames },
          headers: headers
        })
        .then(response => {
          this.setState({styleSettings: response.data.styleSettings})
        })
        .catch(err => {
            console.error(err);
        })
      }

    getTemplate1Settings() {

        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        var members = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios(`api/getHomePageSettings`, {
            method: 'GET',
            params: { company: this.props.user.company, template: 'template1' },
            headers: headers,
        })
        .then(response => {
            this.setState({settings: response.data.settings, loading: false})
        })
        .catch(err => {
            this.setState({loading: false})
            console.error(err);
            //this.setState({ refreshText: '', csTeamLoaded: true });
        })
        
    }

    getTemplate1Images(cb) {

        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        var members = [];
        var backgroundImage = null
        var bottomImage = null
        var test = []
        var tt = {}
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios
            .get("api/download?company=" + this.props.user.company + "&path=" + 'customlanding/template1/backgroundImage.png&name=backgroundImage.png', { headers })
            .then((response) => {
                if (response.data) {
                    backgroundImage = response.data
                }
                axios
                .get("api/download?company=" + this.props.user.company + "&path=" + 'customlanding/template1/bottomImage.png&name=bottomImage.png', { headers })
                .then((response) => {
                    if (response.data) {
                        bottomImage = response.data
                    } 
                    this.setState({backgroundImage: backgroundImage, bottomImage: bottomImage, imageLoading: false}, () =>{
                        cb()
                    })
                })
                .catch((e) => {
                    console.error(e);
                    cb()
                });
            })
            .catch((e) => {
                console.error(e);
                cb()
            });
    }

    getCsTeamDedicated() {
        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        var members = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios(`api/CsTeamHousehold`, {
            method: 'GET',
            params: { company: this.props.user.company, user: this.props.user.user },
            headers: headers,
        })
            .then(response => {
                if (1 === 1) {

                    if (response.data.data != null && response.data.data.length === 1 && this.props.user.role === 'user') {
                        this.setState({ associatedHousehold: {
                            household: response.data.data[0].household, 
                            account: response.data.data[0].account,
                            displayName: response.data.data[0].displayName,
                            accountAnnotation: response.data.data[0].accountAnnotation
                        } })
                        this.props.setCHassociatedHousehold({
                            household: response.data.data[0].household, 
                            account: response.data.data[0].account,
                            displayName: response.data.data[0].displayName,
                            accountAnnotation: response.data.data[0].accountAnnotation
                        });
                    }

                    axios(`api/CsTeamDedicated`, {
                        method: 'GET',
                        params: { company: this.props.user.company, household: (response.data.data != null && response.data.data.length > 0 && response.data.data[0].household != null) ? response.data.data[0].household : '3Sisters85Inc-011_marysue83' },
                        headers: headers,
                    })
                        .then(response => {
                            if (response.data.data[0]) {

                                members.push(this.createTeamMemberObject(response.data.data[0]))
                            }
                            if (response.data.data[1]) {

                                members.push(this.createTeamMemberObject(response.data.data[1]))
                            }
                            if (response.data.data[2]) {

                                members.push(this.createTeamMemberObject(response.data.data[2]))
                            }

                            this.setState({ members: members, csTeamLoaded: true })


                            //var sort=[{ field: 'type', dir: 'asc' }, { field: 'reportName', dir: 'asc' }]
                            //this.setState({ data: members.map(cloneProduct), members: members, refreshText: '' })

                        })
                        .catch(err => {
                            console.error(err);
                            this.setState({ refreshText: '', csTeamLoaded: true });
                        })
                }
                else {
                    this.setState({ refreshText: '', csTeamLoaded: true });
                }

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '', csTeamLoaded: true });
            })


    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }

    createTeamMemberObject(memberData) {
        var fileURL = null

        if (memberData.data) {
            var arrayBufferView = new Uint8Array(memberData.data.data);

            const file = new Blob(
                [arrayBufferView],
                { type: 'image/png' }
            );
            fileURL = URL.createObjectURL(file);//Build a URL from the file
        }


        var member = {
            HouseholdID: memberData.HouseholdID,
            Relation: memberData.Relation,
            FullName: memberData.FullName,
            Title: memberData.Title,
            Phone: memberData.Phone,
            Email: memberData.Email,
            PictureName: memberData.PictureName,
            Image: fileURL
        }

        return member
    }

    

    fadeColor(rgb) {
        if (!rgb){
            rgb = "rgb(255,255,255,0)"
        }
        const [red, green, blue, alpha] = rgb.match(/\d+/g).map(Number);
        
        return "rgb(" + red + ", " + green + ", " + blue + ", " + "0" + ")";
    }

    createImageObject(imageData) {
        var arrayBufferView = new Uint8Array(imageData.data);

        const file = new Blob(
            [arrayBufferView],
            { type: 'image/png' }
        );

        var url = URL.createObjectURL(file);//Build a URL from the file

        console.log(url)
        return url
    }

    getHeaderVariables(text){
        if (!text){
            text = ''
        }

        text = text.replace(/{{name}}/g, this.props.user.name)
        text = text.replace(/{{riafirmname}}/g, this.state.styleSettings.RIAFirmName)
        text = text.replace(/{{email}}/g, this.props.user.email)

        return text
    }

    getFontWeight(){
        var fontAttributes = {}
        let fontStyle = this.state.settings.headerFontStyle || ''
        switch(fontStyle.toLowerCase().includes('bold')){
            case true:
                fontAttributes.fontWeight = 'bold'
                break
            default:
                fontAttributes.fontWeight = 'normal'
                break
        }  

        switch(fontStyle.toLowerCase().includes('underline')){
            case true:
                fontAttributes.textDecoration = 'underline'
                break
            default:
                fontAttributes.textDecoration = ''
                break
        }  

        switch(fontStyle.toLowerCase().includes('italic')){
            case true:
                fontAttributes.fontStyle = 'italic'
                break
            default:
                fontAttributes.fontStyle = ''
                break
        } 


        return fontAttributes
    }



    render() {
        var bgColor = this.state.styleSettings.themePrimaryColor
        return (
            <>
            {(!this.state.loading && !this.state.imageLoading) && <div
                style={{
                    backgroundImage: (this.state.settings.backgroundImageFade ? 
                        ("linear-gradient(to left, " + this.fadeColor(bgColor).replace("rgb(","rgba(") + ", " + bgColor.replace("rgb(","rgba(") +"),url(" + this.state.backgroundImage + ")")
                        :
                        ("url(" + this.state.backgroundImage + ")")
                    )
                    
                    , 
                    //backgroundPosition: 'right',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '92vh',
                    padding: 0,
                    margin: 0, 
                    
                }}>

                <div style={{ paddingTop: '3.2vw' }}></div>
                <label style={{
                    fontSize: (this.state.settings.headerFontSize || '5') + 'vw', paddingLeft: 60, float: 'left', fontFamily: 'Muli', ...this.getFontWeight(), color: this.state.settings.headerFontColor
                }}>{this.getHeaderVariables(this.state.settings.headerText)}</label>
                {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
                <br />
                <div style={{ paddingTop: '7vw' }}></div>
                <div className='customCHButtonGrid'>
                    <table cellPadding="0" cellSpacing="0" style={{ fontFamily: 'Muli', paddingLeft: '6.4vw', float: 'left' }}>
                        <tbody>
                            <tr>
                                <th style={{ padding: 4, color: this.state.settings.leftSubheaderColor }}><label style={{}}>{this.state.settings.leftSubheaderText}</label></th>
                            </tr>
                            <tr>
                                {this.state.docAccess && <td style={{ padding: '0.8vw', paddingRight: '3vw', fontSize: '0.8v' }}><div style={{ cursor: 'pointer' }} onClick={this.props.onCHDocClick}><img style={{ verticalAlign: 'middle' }} src={homeDocs} width="50" height="50" /> <a style={{color: this.state.settings.documentsLinkColor}}>{this.state.settings.documentsLinkText}</a></div></td>}
                                {!this.state.docAccess && this.props.user.access.indexOf('portfolio') >= 0 && <td style={{ padding: '0.8vw', paddingRight: '3vw', fontSize: '0.8v' }}>
                                    <div style={{ cursor: 'pointer' }} onClick={(e) => this.props.onCHAccountClick(e, this.state.associatedHousehold)} ><img style={{ verticalAlign: 'middle' }} src={homeAccessDocs} width="50" height="50" /> <a style={{color: this.state.settings.accountsLinkColor}}>{this.state.settings.accountsLinkText}</a></div></td>}
                                {!this.state.docAccess && this.props.user.access.indexOf('portfolio') < 0 && <td style={{ padding: '0.8vw', paddingRight: '3vw' }}><img style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></td>}
                            </tr>
                            <tr>
                                {this.state.docAccess && this.props.user.access.indexOf('portfolio') >= 0 && <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingRight: '3vw', fontSize: '0.8v' }}>
                                    <div style={{ cursor: 'pointer' }} onClick={(e) => this.props.onCHAccountClick(e, this.state.associatedHousehold)} ><img style={{ verticalAlign: 'middle' }} src={homeAccessDocs} width="50" height="50" /> <a style={{color: this.state.settings.accountsLinkColor}}>{this.state.settings.accountsLinkText}</a></div></td>}
                                {this.state.docAccess && this.props.user.access.indexOf('portfolio') < 0 && <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingRight: '3vw' }}><img style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></td>}
                                {!this.state.docAccess && <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingRight: '3vw' }}><img style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></td>}
                            </tr>
                            {/* <tr>
                                <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingRight: '3.2vw' }}><img style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></td>
                            </tr> */}
                        </tbody>
                    </table>
                    <table className='customCH' cellPadding="0" cellSpacing="0" style={{ float: 'left' }}>
                        <tbody>
                            <tr>
                                {(this.state.settings.showWebsiteLink || this.state.settings.showAdditionalLink) &&
                                    <th style={{ padding: 4, paddingLeft: '2.4vw', color: this.state.settings.rightSubheaderColor }}><label style={{}}>{this.state.settings.rightSubheaderText}</label></th>
                                }
                                {(!this.state.settings.showWebsiteLink && !this.state.settings.showAdditionalLink) &&
                                    <th style={{ padding: 4, visibility: 'hidden', paddingLeft: '2.4vw', color: this.state.settings.rightSubheaderColor }}>{"t"}</th>
                                }
                            </tr>
                            
                            <tr>
                            {this.state.settings.showWebsiteLink &&
                                <td style={{ padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: this.state.settings.websiteLinkColor }} href={(this.state.settings.websiteLinkUrl && !this.state.settings.websiteLinkUrl.includes('https://')) ? ('https://' + this.state.settings.websiteLinkUrl) : this.state.settings.websiteLinkUrl}><img style={{ verticalAlign: 'middle' }} src={homeWeb} width="50" height="50" /> {this.state.settings.websiteLinkText}</a></td>
                            }
                            {(!this.state.settings.showWebsiteLink && !this.state.settings.showAdditionalLink) &&
                                <td style={{ padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: 'black' }} ><img style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></a></td>
                            }
                            {(!this.state.settings.showWebsiteLink && this.state.settings.showAdditionalLink) &&
                                <td style={{ padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: this.state.settings.additionalLinkColor }} href= {(this.state.settings.additionalLinkUrl && !this.state.settings.additionalLinkUrl.includes('https://')) ? ('https://' + this.state.settings.additionalLinkUrl) : this.state.settings.additionalLinkUrl}><img style={{ verticalAlign: 'middle' }} src={homeWeb} width="50" height="50" /> {this.state.settings.additionalLinkText}</a></td>
                            }
                            </tr>
                            <tr>
                            {(this.state.settings.showAdditionalLink && this.state.settings.showWebsiteLink) &&
                                <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: this.state.settings.additionalLinkColor }} href={(this.state.settings.additionalLinkUrl && !this.state.settings.additionalLinkUrl.includes('https://')) ? ('https://' + this.state.settings.additionalLinkUrl) : this.state.settings.additionalLinkUrl}><img style={{ verticalAlign: 'middle' }} src={homeMkt} width="50" height="50" /> {this.state.settings.additionalLinkText}</a></td>
                            }
                            {((!this.state.settings.showAdditionalLink && this.state.settings.showWebsiteLink) || (this.state.settings.showAdditionalLink && !this.state.settings.showWebsiteLink)) &&
                                <td style={{ borderLeft: 'solid 2px rgb(160, 160, 160)', borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: 'black' }} ><img style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></a></td>
                            }
                            {(!this.state.settings.showAdditionalLink && !this.state.settings.showWebsiteLink) &&
                                <td style={{ borderLeft: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: 'black' }} ><img style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></a></td>
                            }
                            </tr>
                            {/* <tr>
                                <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8vw' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: 'black' }} href="https://www.churchillmanagement.com/contact/"><img style={{ verticalAlign: 'middle' }} src={chContact} width="50" height="50" /> CONTACT US</a></td>
                            </tr> */}
                        </tbody>
                    </table>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    <div>
                            <label style={{ color: this.state.settings.bottomImageHeaderColor, paddingLeft: '6.4vw', float: 'left' }}>{this.state.settings.bottomImageHeaderText}</label>
                            <br />
                            <br />
                            <table style={{ float: 'left' }}>
                                <tbody>
                                    <tr>
                                        {(this.state.settings.showBottomImage && this.state.settings.bottomImage) && <td style={{ paddingLeft: '6.4vw' }}><img style={{maxHeight: '35.0vh'}} src= { this.state.bottomImage ? this.state.bottomImage : chPlaceholder} /></td>}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    



                </div>




            </div>
                }
            {this.state.loading && <div></div>}
            </>
        );
    }

}

export default Template1;