import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import EmailForm from './components/EmailForm';
import axios from 'axios';

class PushEmail extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
      groups:[]
    }

    this.sendEmail = this.sendEmail.bind(this);
    this.combineUsersGroups = this.combineUsersGroups.bind(this);
    this.getGroups = this.getGroups.bind(this);
  }

  componentDidMount(){
    this.getGroups();
  }

  getGroups(){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/usergroups?company="+this.props.company, {headers}).then(response => {
      if(response.data.code===200){
        this.setState({groups:response.data.groups});
      }
      else{
        alert("An error occured while fetching the user groups.");
      }
    }).catch(error => console.log(error));
  }

  combineUsersGroups(){
    var combined = this.props.users.slice();
    var newGroup;

    for(let i=0; i<this.state.groups.length; i++){
      newGroup = this.state.groups[i];
      newGroup.emailDisplay = newGroup.name+" (GROUP)";
      combined.push(newGroup);
    }
    
    return combined;
  }

  sendEmail(users, cc, bcc, subject, body, callback){
    if(users===undefined || users===null || users.length===0){
      alert("Please select at least one user to send to.");
    }
    else if(subject===undefined || subject===null || subject===''){
      alert("Please enter subject text.");
    }
    else{
      var emails = [];
      for(let i=0; i<users.length; i++){
        emails.push(users[i].email);
      }

      var ccEmails = [];
      for(let i=0; i<cc.length; i++){
        ccEmails.push(cc[i].email);
      }

      var bccEmails = [];
      for(let i=0; i<bcc.length; i++){
        bccEmails.push(bcc[i].email);
      }

      var headers = {
          'Authorization': 'Bearer '.concat(this.props.idToken),
          };
      var payload = {
          to:emails,
          cc:ccEmails,
          bcc:bccEmails,
          company:this.props.company,
          subject:subject,
          body:body
      };

      axios.post('api/pushemail', payload, {headers}).then(response=>{
          if(response.data.code===200){
            alert("Email(s) were sent successfully.");
            callback();
          }
          else{
            alert("An error occured when sending the emails, some may have not been delivered.");
          }
        }).catch(err =>{alert("An error occured when sending the emails, some may have not been delivered."); console.log(err)});
    }
  }

  render() {
      if(this.props.role==='user'){
          return null;
      }
      return (
          <span>
          <EmailForm 
              sendEmail={this.sendEmail}
              company={this.props.company}
              idToken={this.props.idToken}
              data={this.combineUsersGroups()}
              groups={this.state.groups}
              button={<Button icon="email"> Send Email</Button>} />
          </span>
      )
  };


}

export default PushEmail;