import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class BannerLaunchModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bannerLogo: '',
            bannerWelcomeText: ''
        }

    }

    

    componentDidMount = () => {
        this.getBannerList();
    }

    getBannerList = () => {
        var banners = [];
        console.log(this.props)

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/getBannerList`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                response.data.data.forEach(bannerInResponse => {
                    bannerInResponse.BannerTextWithNewline = bannerInResponse.BannerText.replace(/\\n/g, '\n')
                    banners.push(bannerInResponse)
                })
                this.setState({
                    bannerLogo: banners.length > 0 ? banners[0].BannerCode : '',
                    bannerWelcomeText: banners.length > 0 ? banners[0].BannerTextWithNewline : ''
                },() => {
                    axios.get('api/logo?company='+this.props.user.company+'&path=' + this.state.bannerLogo, { headers }).then(response => {
                        console.log('response',response)
                      }).catch(err => console.log(err));
                })
            })
            .catch(err => {
                console.error(err);
            })
    }

    render() {
        return (
            <div className='shadowBox'>
                <div className='banner'>
                    <br />
                    {this.state.BannerLogo !== '' && <img className="modalLogo" src={"Logos/" + this.state.bannerLogo} alt="Banner Logo" />}
                    <br />
                    <br />
                    {this.state.bannerWelcomeText}
                    <br />
                    <br />
                    <div>{this.props.banner && <button className='link-button' style={{ /*color: '#656565'*/ }} onClick={(event) => this.props.onLinkClick(event, this.props.banner, this.props.idToken)}>{this.props.banner.description}</button>}</div>
                    <br />
                    <br />
                    <div>
                        <Button className='select' disabled={!this.props.bannerAcceptEnabled} onClick={this.props.onBannerAccept}>Accept</Button>
                        <Button className='select' onClick={this.props.onBannerDecline}>Decline</Button>
                    </div>
                </div>
            </div>

        );
    };
}

export default BannerLaunchModal;