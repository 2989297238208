import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';

class AddWidgets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            configuration: '{"widths":[4,6,12],"defaultWidth": 4}',
            types: '',
            module: '',
            order: 0,
            active: true
        }
    }

    onChangeName = (e) => {
        this.setState({ name: e.target.value });
    }

    onChangeDescription = (e) => {
        this.setState({ description: e.target.value });
    }

    onChangeConfiguration = (e) => {
        this.setState({ configuration: e.target.value });
    }

    onChangeTypes = (e) => {
        this.setState({ types: e.target.value });
    }

    onChangeModule = (e) => {
        this.setState({ module: e.target.value });
    }

    onChangeOrder = (e) => {
        this.setState({ order: parseInt(e.target.value) });
    }

    onChangeActive = (e) => {
        this.setState({ active: !this.state.active });
    }

    createWidget = (callback) => {
        var headers = {
            'Authorization': 'Bearer '.concat(this.props.idToken),
        };
        var payload = {
            name: this.state.name,
            description: this.state.description,
            configuration: this.state.configuration,
            types: this.state.types,
            module: this.state.module,
            order: this.state.order,
            active: this.state.active,
            company: this.props.company,
        };

        axios.post('api/createWidget', payload, { headers }).then(response => {
            if (response.data.code === 201) {
                callback();
            }
            else {
                alert("Unable to create widget.");
                callback("ERROR");
            }
        }).catch(err => {
            alert("An error occured when creating the widget.");
            console.log(err)
            callback("ERROR");
        });
    }

    clearState = () => {
        this.setState({
            name: '',
            description: '',
            configuration: '{"widths":[4,6,12],"defaultWidth": 4}',
            types: '',
            module: '',
            order: 0,
            active: true
        });
    }

    render() {
        return (
            <Popup trigger={this.props.button} onClose={(e) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={close} />
                        <div className="header" align="left">
                            Create New Widget
                        </div>
                        <div className="content">
                            <label>Widget Name: </label><Input onChange={this.onChangeName} defaultValue={this.state.name} />
                        </div>
                        <div className="content">
                            <label>Description: </label><Input name="description" onChange={this.onChangeDescription} defaultValue={this.state.description} />
                        </div>
                        <div className="content">
                            <label>Configuration (JSON string): </label><Input name="configuration" onChange={this.onChangeConfiguration} defaultValue={this.state.configuration} />
                        </div>
                        {/* <div className="content">
                            <label>Types: </label><Input name="types" onChange={this.onChangeTypes} value={this.state.types} />
                        </div> */}
                        <div className="content">
                            <label>Module: </label><Input name="module" onChange={this.onChangeModule} defaultValue={this.state.module} />
                        </div>
                        <div className="content">
                            <label>Order: </label><Input type="numeric" name="order" onChange={this.onChangeOrder} defaultValue={this.state.order} />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <label>Active: </label><input type="checkbox" name="active" onChange={this.onChangeActive} checked={this.state.active} />
                        </div>
                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => {
                                    close();
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                className="upload"
                                onClick={(e) => {
                                    this.createWidget((err) => {
                                        if (!err) {
                                            this.props.refreshWidgets();
                                            close();
                                        }
                                    });
                                }}
                            >
                                Create Widget
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>
        );
    };

}

export default AddWidgets;