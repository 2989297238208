import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import AdvisorAccountsAUM from './components/AdvisorAccountsAUM';
import AdvisorPerformance from './components/AdvisorPerformance';
import AdvisorPerfHist from './components/AdvisorPerfHist';
import AdvisorHoldingsSummary from "./components/AdvisorHoldingsSummary";

class Advisor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tabSelected:0,
    }
  }

  handleSelect = (e) => {
    this.setState({ tabSelected: e.selected });
  }

  render() {
    return(
        <div className='advisor'>
          <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
              <TabStripTab title='Accounts AUM'>
                <AdvisorAccountsAUM user={this.props.user} compSettings={this.props.compSettings} idToken={this.props.idToken}/>
              </TabStripTab>
              <TabStripTab title='Performance'>
                <AdvisorPerformance user={this.props.user} compSettings={this.props.compSettings} idToken={this.props.idToken}/>
              </TabStripTab>
              <TabStripTab title='Performance History'>
                <AdvisorPerfHist user={this.props.user} compSettings={this.props.compSettings} idToken={this.props.idToken}/>
              </TabStripTab>
              <TabStripTab title='Holdings Summary'>
                <AdvisorHoldingsSummary user={this.props.user} compSettings={this.props.compSettings} idToken={this.props.idToken}/>
              </TabStripTab>
              
          </TabStrip>
        </div>
    );
  }

}

export default Advisor;