import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import axios from 'axios';

class AddUsers extends Component {

  constructor(props) {
    super(props);
      this.state = {
        users:[],
        usersToAdd:[],
        adding:''
    }

    this.clearState = this.clearState.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  componentDidMount(){
    this.getUsers();
  }

  getUsers(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken)};
    axios.get("api/users?company="+this.props.user.company+"&userlevel="+this.props.user.userLevel, {headers}).then(response => {
        if(response.data.code===200){
          this.setState({users:response.data.users});
        }
        else{
          alert("An error occured while fetching the user list.");
        }
      }).catch(error => console.log(error));
  }

  addUsersToGroup(callback){
    
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      users:this.state.usersToAdd,
      action:'add',
      group:this.props.groupId,
      company:this.props.user.company
    }

    this.setState({adding:true});

    axios.post("api/usergroup", payload, {headers}).then(response => {

      this.setState({adding:false});

      if(response.data.code===200){
        alert('Users successfully added to group');
        callback()
      }
      else{
        alert(response.data.errMsg);
        callback("ERROR");
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err);
        callback("ERROR");
      });  
  }

  onUsersChange = (e)=>{
    this.setState({usersToAdd:e.target.value});
  }

  clearState(){
      this.setState({
        usersToAdd:[]
      });
  }

  render() {
      return (
        <Popup trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={close} />


              <div className="header" align="left">
                Add Users to Group
              </div>

              <div className="content">
                <label>List of Users</label><br />
                <MultiSelect
                  data={this.state.users}
                  textField="email"
                  onChange={this.onUsersChange}
                />
              </div>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </Button>

                <Button 
                    className="upload"
                    disabled={this.state.adding}
                    onClick={(e) => {this.addUsersToGroup(err=>{
                      if(!err){
                        this.props.refresh();
                        close();
                      }
                    });}}
                >
                  {!this.state.adding && <span>Add</span>}
                  {this.state.adding && <span>Adding...</span>}
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };

}

export default AddUsers;