import React, { Component } from "react";
import axios from 'axios';
import GridLoading from '../../Loaders/GridLoading';

class AllAccountSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
        accountSummary:[],
        totals:{},
        date:this.props.date,
        perfType:this.props.perfType,
        period:this.props.period,
        loading:false,
        previousSort: ''
    }

    this.getAccountSummary = this.getAccountSummary.bind(this);
    this.getConsolidatedAccountSummaryTotals = this.getConsolidatedAccountSummaryTotals.bind(this);
    this.handleLabelClick = this.handleLabelClick.bind(this);
  }

  componentDidMount(){
    this.getAccountSummary();
  }

  componentDidUpdate(){
    if(this.props.date!==this.state.date || this.props.perfType!==this.state.perfType || this.props.period!==this.state.period){
      this.setState({date:this.props.date, perfType:this.props.perfType, period:this.props.period}, ()=>{this.getAccountSummary()});
    }
  }

  getAccountSummary(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    this.setState({loading:true});

    var payload = {
      company:this.props.user.company,
      date:this.state.date,
      user:this.props.user.user,
      perfType:this.state.perfType,
      household:this.props.user.defaultAcct.household
    }

    axios.post('api/accountSummary', payload , {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0){
            var periodData=[];
            var totals = {
              beginMV:0,
              beginAI:0,
              additions:0,
              transfersIn:0,
              withdrawals:0,
              transfersOut:0,
              netCW:0,
              interest:0,
              dividends:0,
              deltaAI:0,
              income:0,
              realizedGains:0,
              unrealizedGains:0,
              investGL:0,
              endMV:0,
              endAI:0,
              benefit:0,
              manfees:0,
              manFeesClient:0,
              portFees:0,
              portFeesClient:0
            };
            var periods=[];
            var port = '';
            var display = false;

            for(let i=0; i<response.data.data.length; i++){
              if(response.data.data[i].periodCode===this.state.period){
                //add accrued interests to market values
                response.data.data[i].beginMV = response.data.data[i].beginMV + response.data.data[i].beginAI;
                response.data.data[i].endMV = response.data.data[i].endMV + response.data.data[i].endAI;

                periodData.push(response.data.data[i]);
                totals.beginMV = totals.beginMV + response.data.data[i].beginMV;
                totals.beginAI = totals.beginAI + response.data.data[i].beginAI;
                totals.additions = totals.additions + response.data.data[i].additions;
                totals.transfersIn = totals.transfersIn + response.data.data[i].transfersIn;
                totals.withdrawals = totals.withdrawals + response.data.data[i].withdrawals;
                totals.transfersOut = totals.transfersOut + response.data.data[i].transfersOut;
                totals.netCW = totals.netCW + response.data.data[i].netCW;
                totals.interest = totals.interest + response.data.data[i].interest;
                totals.dividends = totals.dividends + response.data.data[i].dividends;
                totals.deltaAI = totals.deltaAI + response.data.data[i].deltaAI;
                totals.income = totals.income + response.data.data[i].income;
                totals.realizedGains = totals.realizedGains + response.data.data[i].realizedGains;
                totals.unrealizedGains = totals.unrealizedGains + response.data.data[i].unrealizedGains;
                totals.investGL = totals.investGL + response.data.data[i].investGL;
                totals.endMV = totals.endMV + response.data.data[i].endMV;
                totals.endAI = totals.endAI + response.data.data[i].endAI;
                totals.benefit = totals.endMV - totals.beginMV;
                totals.manfees = totals.manfees + response.data.data[i].manfees;
                totals.manFeesClient = totals.manFeesClient + response.data.data[i].manFeesClient;
                totals.portFees = totals.portFees + response.data.data[i].portFees;
                totals.portFeesClient = totals.portFeesClient + response.data.data[i].portFeesClient;
              }

              //used to sort order of periods in drop down correctly
              if(response.data.data[i].portCode!==port){
                port = response.data.data[i].portCode;
                display = true;
              }
              // else if(!response.data.data[i].display){
              //   display = false;
              // }

              if(display){
                var index = periods.findIndex(p=>{
                  return p.value===response.data.data[i].periodCode;
                });

                if(index<0 && this.props.styleSettings.acctDropPeriods.indexOf(response.data.data[i].periodCode)>=0){
                  periods.push({name:response.data.data[i].periodDesc, value:response.data.data[i].periodCode})
                }
              }
            }

            //check for itd at end
            var incIndex = periods.findIndex(p=>{
              return p.value==='itd';
            });
            if(incIndex<0 && this.props.styleSettings.acctDropPeriods.indexOf('itd')>=0)
              periods.push({name:'Inception to Date', value:'itd'});

            this.props.setPeriods(periods);

            periodData.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1)

            if (this.props.styleSettings.acctsummDisplayConsolidatedPerformance){
              this.setState({accountSummary:periodData, totals:totals}, () =>{
                if (this.props.user.defaultAcct.household){
                  this.getConsolidatedAccountSummaryTotals();
                }
                else{
                  this.setState({loading:false})
                }
              });
            }
            else{
              this.setState({accountSummary:periodData, totals:totals,loading:false});
            }
            
          }
          else{
            this.props.setPeriods([]);
            this.setState({accountSummary:[] ,loading:false   });
          }
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getConsolidatedAccountSummaryTotals(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      date:this.state.date,
      user:this.props.user.user,
      perfType:this.state.perfType,
      household:this.props.user.defaultAcct.household,
      consolidated: true
    }

    axios.post('api/accountSummary', payload , {headers}).then(response=>{
      if(response.data.code===200){
        console.log(response.data.data)
          if(response.data.data.length>0){
            var totals = this.state.totals
            for(let i=0; i<response.data.data.length; i++){
              if(response.data.data[i].periodCode===this.state.period){
                totals.irr = response.data.data[i].irr;
                totals.twr = response.data.data[i].twr;
              }
            }
            this.setState({totals:totals, loading: false});
          }
          else{
            this.setState({loading:false});
          }
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  formatCurrency(val){
    if(isNaN(val))
      return '-';

    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    return formatted;
  }

  formatPercent(val){
    if(val)
      return val.toFixed(2)+"%";
    else
      return '-';
  }

  getColor(val){
    if(!val ||  isNaN(val))
      return 'black';
    else if(val>0)
      return '#0BD318';
    else
      return '#FF3B30';
  }

  handleLabelClick(field) {
    var accountSummary = this.state.accountSummary
    
    if (field === 'benefit'){
      if (field !== this.state.previousSort || ((accountSummary[0].endMV - accountSummary[0].beginMV) < (accountSummary[accountSummary.length - 1].endMV - accountSummary[accountSummary.length - 1].beginMV))){
        accountSummary.sort((a, b) => ((a.endMV - a.beginMV) < (b.endMV - b.beginMV)) ? 1 : -1)  
      }
      else{
        accountSummary.sort((a, b) => ((a.endMV - a.beginMV) > (b.endMV - b.beginMV)) ? 1 : -1)
      }
    }
    else if (field === 'irr' || field === 'twr'){
      if (field !== this.state.previousSort ||  ((accountSummary[0][field] ? accountSummary[0][field] : 0)  < (accountSummary[accountSummary.length - 1][field] ? accountSummary[accountSummary.length - 1][field] : 0))){
        accountSummary.sort((a, b) => ((a[field] ? a[field] : 0)  < (b[field] ? b[field] : 0)) ? 1 : -1)
      }
      else{
        accountSummary.sort((a, b) => ((a[field] ? a[field] : 0)  > (b[field] ? b[field] : 0)) ? 1 : -1)
      }      
    }
    else if (field === 'displayName'){
      if (this.state.previousSort !== '' && (field !== this.state.previousSort || (accountSummary[0][field] > accountSummary[accountSummary.length - 1][field]))){
        accountSummary.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
      }
      else{
        accountSummary.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
      }
    }
    else if (field === 'withdrawals' || field === 'transfersOut' ){
      if (field !== this.state.previousSort || (accountSummary[0][field] > accountSummary[accountSummary.length - 1][field])){
        accountSummary.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
      }
      else{
        accountSummary.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
      }
    }
    else{
      if (field !== this.state.previousSort || (accountSummary[0][field] < accountSummary[accountSummary.length - 1][field])){
        accountSummary.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
      }
      else{
        accountSummary.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
      }
    }

    this.setState({accountSummary: accountSummary, previousSort: field})


  }
  render() {

    var tableData = this.state.accountSummary.slice();

    var minWidth = 1400
    if (!this.props.styleSettings.accountSummaryshowNetCW && !this.props.styleSettings.accountSummaryShowTransfers)
      minWidth -= 100
    if (!this.props.styleSettings.accountSummaryShowBenefit)
      minWidth -= 100
    var minWidthString = minWidth + 'px'

    return(
        <div className='allAcctSummary'>
          <table style={{minWidth: minWidthString}} className='bottomBorders fixedHeaders'>
            <thead>
              <tr>
                <th style={{paddingLeft:'5px', verticalAlign:'bottom'}} onClick={() => this.handleLabelClick('displayName')}>Account Name</th>
                <th className='rightAlignHeader' onClick={() => this.handleLabelClick('beginMV')}><label>Beginning Value<br/>(on {this.state.accountSummary.length>0 && this.state.accountSummary[0].beginDate})</label></th>
                {!this.props.styleSettings.accountSummaryshowNetCW && <>
                  <th className='rightAlignHeader' onClick={() => this.handleLabelClick('additions')}>Additions</th>
                  {this.state.accountSummary.filter(x=>(x.transfersIn!==undefined && x.transfersIn!==0)).length>0 && this.props.styleSettings.accountSummaryShowTransfers && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('transfersIn')}>Transfers In</th>}                   
                  <th className='rightAlignHeader' onClick={() => this.handleLabelClick('withdrawals')}>Withdrawals</th>
                  {this.state.accountSummary.filter(x=>(x.transfersOut!==undefined && x.transfersOut!==0)).length>0 && this.props.styleSettings.accountSummaryShowTransfers && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('transfersOut')}>Transfers Out</th>}
                </>}
                {this.props.styleSettings.accountSummaryshowNetCW && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('netCW')}><div>Net</div><div>Additions/Withdrawals</div></th>}
                {!this.props.styleSettings.showIncome && <>
                  <th className='rightAlignHeader' onClick={() => this.handleLabelClick('interest')}>Interest</th>
                  <th className='rightAlignHeader' onClick={() => this.handleLabelClick('dividends')}>Dividends</th>
                  {this.state.accountSummary.filter(x=>(x.deltaAI!==undefined && x.deltaAI!==0)).length>0 && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('deltaAI')}>Accrued<br/>Interest</th>}
                </>}
                {this.props.styleSettings.showIncome && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('income')}>Income</th>}
                {!this.props.styleSettings.showInvstGL && <>
                  <th className='rightAlignHeader' onClick={() => this.handleLabelClick('realizedGains')}>Realized<br/>Gains</th>
                  <th className='rightAlignHeader' onClick={() => this.handleLabelClick('unrealizedGains')}>Unrealized<br/>Gains</th>
                </>}
                {this.props.styleSettings.showInvstGL && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('investGL')}><div>Investment</div><div>Gain/Loss</div></th>}
                {this.state.accountSummary.filter(x=>(x.manfees!==undefined && x.manfees!==0)).length>0 && this.state.perfType!=='gross' && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('manfees')}>Management Fees</th>}
                {this.state.accountSummary.filter(x=>(x.portFees!==undefined && x.portFees!==0)).length>0 && this.state.perfType!=='gross' && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('portFees')}>Portfolio Fees</th>}
                <th className='rightAlignHeader' onClick={() => this.handleLabelClick('endMV')}>Current Value<br/>(as of {this.state.accountSummary.length>0 && this.state.accountSummary[0].endDate})</th>                    
                {this.props.styleSettings.showFeesPaid && this.state.perfType!=='gross' && <>
                  {this.state.accountSummary.filter(x=>(x.manFeesClient!==undefined && x.manFeesClient!==0)).length>0 &&<th className='rightAlignHeader' onClick={() => this.handleLabelClick('manFeesClient')}>Man. Fees Paid</th>}
                  {this.state.accountSummary.filter(x=>(x.portFeesClient!==undefined && x.portFeesClient!==0)).length>0 &&<th className='rightAlignHeader' onClick={() => this.handleLabelClick('portFeesClient')}>Port. Fees Paid</th>}
                </>}
                {this.props.styleSettings.accountSummaryShowBenefit && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('benefit')}>Benefit for<br/>the Period</th>}
                {this.props.styleSettings.showAcctSummIrr && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('irr')}>IRR</th>}
                {this.props.styleSettings.showAcctSummTwr && <th className='rightAlignHeader' onClick={() => this.handleLabelClick('twr')}>TWR</th>}
              </tr>
            </thead>
            <tbody>
              {tableData.map((point, i)=>(
                    <tr key={i}>
                      <td>{point.displayName}</td>
                      <td style={{textAlign:'right'}}><strong>{this.formatCurrency(point.beginMV)}</strong></td>
                      {!this.props.styleSettings.accountSummaryshowNetCW && <>
                        <td style={{textAlign:'right'}}>{this.formatCurrency(point.additions)}</td>
                        {this.state.accountSummary.filter(x=>(x.transfersIn!==undefined && x.transfersIn!==0)).length>0 && this.props.styleSettings.accountSummaryShowTransfers && <td style={{textAlign:'right'}}>{this.formatCurrency(point.transfersIn)}</td>}
                        <td style={{textAlign:'right'}}>{this.formatCurrency(point.withdrawals)}</td>
                        {this.state.accountSummary.filter(x=>(x.transfersOut!==undefined && x.transfersOut!==0)).length>0 && this.props.styleSettings.accountSummaryShowTransfers && <td style={{textAlign:'right'}}>{this.formatCurrency(point.transfersOut)}</td>}
                      </>}
                      {this.props.styleSettings.accountSummaryshowNetCW && <td style={{textAlign:'right'}}>{this.formatCurrency(point.netCW)}</td>}
                      {!this.props.styleSettings.showIncome && <>
                      <td style={{textAlign:'right'}}>{this.formatCurrency(point.interest)}</td>
                      <td style={{textAlign:'right'}}>{this.formatCurrency(point.dividends)}</td>
                      {this.state.accountSummary.filter(x=>(x.deltaAI!==undefined && x.deltaAI!==0)).length>0 &&<td style={{textAlign:'right'}}>{this.formatCurrency(point.deltaAI)}</td>}
                      </>}
                      {this.props.styleSettings.showIncome && <td style={{textAlign:'right'}}>{this.formatCurrency(point.income)}</td>}
                      {!this.props.styleSettings.showInvstGL && <>
                      <td style={{textAlign:'right'}}>{this.formatCurrency(point.realizedGains)}</td>
                      <td style={{textAlign:'right'}}>{this.formatCurrency(point.unrealizedGains)}</td>
                      </>}
                      {this.props.styleSettings.showInvstGL && <td style={{textAlign:'right'}}>{this.formatCurrency(point.investGL)}</td>}
                      {this.state.accountSummary.filter(x=>(x.manfees!==undefined && x.manfees!==0)).length>0 && this.state.perfType!=='gross' && <td style={{textAlign:'right'}}>{this.formatCurrency(point.manfees)}</td>}
                      {this.state.accountSummary.filter(x=>(x.portFees!==undefined && x.portFees!==0)).length>0 && this.state.perfType!=='gross' && <td style={{textAlign:'right'}}>{this.formatCurrency(point.portFees)}</td>}                           
                      <td style={{textAlign:'right'}}><strong>{this.formatCurrency(point.endMV)}</strong></td>
                      {this.props.styleSettings.showFeesPaid && this.state.perfType!=='gross' && <>
                        {this.state.accountSummary.filter(x=>(x.manFeesClient!==undefined && x.manFeesClient!==0)).length>0 && <td style={{textAlign:'right'}}>{this.formatCurrency(point.manFeesClient)}</td>}
                        {this.state.accountSummary.filter(x=>(x.portFeesClient!==undefined && x.portFeesClient!==0)).length>0 && <td style={{textAlign:'right'}}>{this.formatCurrency(point.portFeesClient)}</td>}
                      </>}
                      {this.props.styleSettings.accountSummaryShowBenefit && <td style={{textAlign:'right'}}><span style={{color:this.getColor(point.endMV - point.beginMV)}}>{this.formatCurrency(point.endMV - point.beginMV)}</span></td>}
                      {this.props.styleSettings.showAcctSummIrr && <td style={{textAlign:'right'}}><span style={{color:this.getColor(point.irr)}}>{this.formatPercent(point.irr)}</span></td>}
                      {this.props.styleSettings.showAcctSummTwr && <td style={{textAlign:'right'}}><span style={{color:this.getColor(point.twr)}}>{this.formatPercent(point.twr)}</span></td>}                
                    </tr>
                  ))}
              <tr>
                <td style={{textAlign:'right'}}><strong>Totals:</strong></td>
                <td style={{textAlign:'right'}}><strong>{this.formatCurrency(this.state.totals.beginMV)}</strong></td>        
                {!this.props.styleSettings.accountSummaryshowNetCW && <>
                  <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.additions)}</td>
                  {this.state.accountSummary.filter(x=>(x.transfersIn!==undefined && x.transfersIn!==0)).length>0 && this.props.styleSettings.accountSummaryShowTransfers && <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.transfersIn)}</td>}
                  <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.withdrawals)}</td>
                  {this.state.accountSummary.filter(x=>(x.transfersOut!==undefined && x.transfersOut!==0)).length>0 && this.props.styleSettings.accountSummaryShowTransfers && <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.transfersOut)}</td>}
                </>}
                {this.props.styleSettings.accountSummaryshowNetCW && <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.netCW)}</td>}
                {!this.props.styleSettings.showIncome && <>
                <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.interest)}</td>
                <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.dividends)}</td>
                {this.state.accountSummary.filter(x=>(x.deltaAI!==undefined && x.deltaAI!==0)).length>0 && <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.deltaAI)}</td>}
                </>}
                {this.props.styleSettings.showIncome && <td className='rightAlign'>{this.formatCurrency(this.state.totals.income)}</td>}
                {!this.props.styleSettings.showInvstGL && <>
                  <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.realizedGains)}</td>
                  <td style={{textAlign:'right'}}>{this.formatCurrency(this.state.totals.unrealizedGains)}</td>
                </>}
                {this.props.styleSettings.showInvstGL && <td className='rightAlign'>{this.formatCurrency(this.state.totals.investGL)}</td>}
                {this.state.accountSummary.filter(x=>(x.manfees!==undefined && x.manfees!==0)).length>0 && this.state.perfType!=='gross' && <td className='rightAlign'>{this.formatCurrency(this.state.totals.manfees)}</td>}
                {this.state.accountSummary.filter(x=>(x.portFees!==undefined && x.portFees!==0)).length>0 && this.state.perfType!=='gross' && <td className='rightAlign'>{this.formatCurrency(this.state.totals.portFees)}</td>}                     
                <td style={{textAlign:'right'}}><strong>{this.formatCurrency(this.state.totals.endMV)}</strong></td>
                {this.props.styleSettings.showFeesPaid && this.state.perfType!=='gross' && <>
                  {this.state.accountSummary.filter(x=>(x.manFeesClient!==undefined && x.manFeesClient!==0)).length>0 && <td className='rightAlign'>{this.formatCurrency(this.state.totals.manFeesClient)}</td>}
                  {this.state.accountSummary.filter(x=>(x.portFeesClient!==undefined && x.portFeesClient!==0)).length>0 && <td className='rightAlign'>{this.formatCurrency(this.state.totals.portFeesClient)}</td>}
                </>}
                {this.props.styleSettings.accountSummaryShowBenefit && <td style={{textAlign:'right'}}><span style={{color:this.getColor(this.state.totals.benefit)}}>{this.formatCurrency(this.state.totals.benefit)}</span></td>}
                {this.props.styleSettings.showAcctSummIrr && <td style={{textAlign:'right'}}><span style={{color:this.getColor(this.state.totals.irr)}}>{this.formatPercent(this.state.totals.irr)}</span></td>}
                {this.props.styleSettings.showAcctSummTwr && <td style={{textAlign:'right'}}><span style={{color:this.getColor(this.state.totals.twr)}}>{this.formatPercent(this.state.totals.twr)}</span></td>}
              </tr>
            </tbody>
          </table>
          {this.state.loading && <GridLoading gridClass='assetAllGrid'/>}
        </div>
    );
  }


}

export default AllAccountSummary;