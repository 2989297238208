import React from 'react';
import axios from 'axios';
import loading from '../../Callback/loading.svg';

class FixedIncomeHoldings extends React.Component {

  constructor(props) {
    super(props);

    const valObj = this.getValues(this.props.width);

    this.state = {
      data: [],
      labels: [],
      loading: true,
      col1: valObj.col1,
      col2: valObj.col2,
      graphHeight: valObj.graphHeight,
      styleSettings: null,
      initialDate:"",
    };

  }

  getValues = (width) => {
    let col1 = 0, col2 = 0, graphHeight = 0;
    switch (width) {
      case 4:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 6:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 12:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      default:
    }
    return { col1: col1, col2: col2, graphHeight: graphHeight }
  }

  componentDidMount() {
    this.getStyleSettings(() =>{
      this.props.callback((width) => {
        const CBvalObj = this.getValues(width);
        this.setState({
          graphHeight: CBvalObj.graphHeight,
          col1: CBvalObj.col1,
          col2: CBvalObj.col2
        })
      });
      if (this.props.onWidgetLoad) {
        this.props.onWidgetLoad();
        this.props.onAddCallback((result, err) => {
          this.setState({ loading: true }, () => {
            this.getHoldDates(() => {
              this.onSubmit();
            })
          })
        })
      }
    })
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'fiHoldingsIncludeCash',
      'fiHoldingsIncludeAI'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      this.setState({styleSettings: response.data.styleSettings}, () => {
        cb()
      })
    })
    .catch(err => {
        console.error(err);
    })
  }

  getHoldDates(cb) {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
        company: this.props.user.company,
        port: '',
        household: this.props.selectedHousehold.household
    }
    axios.post('api/posDates', payload, { headers }).then(response => {
        if (response.data.code === 200) {
            if (response.data.data.length > 0)
                this.setState({ initialDate: response.data.data[0].fDate }, () => {
                  cb()
                });
            else
                cb();
        }
    }).catch(err => {
        console.log(err);
    });
}

  onSubmit = () => {
    this.getFixedIncomeHoldings();
  }


  getFixedIncomeHoldings(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var household = this.props.selectedHousehold.household

    var date = new Date(this.state.initialDate)

    var includeAI = this.state.styleSettings.fiHoldingsIncludeAI

    var includeCash = this.state.styleSettings.fiHoldingsIncludeCash

    axios.get(`api/fixedIncomeHoldingsSnapshot`, {
      method: 'GET',
      params: {
        company: this.props.user.company,
        household: household,
        date: date,
        includeAI: includeAI,
        includeCash: includeCash
      },
      headers: headers,
    })
    .then(response=>{
      if(response.data.code===200){

        var total = 0

        for(let i=0; i<response.data.data.length; i++){
            total = total + response.data.data[i].marketValue
        }
        console.log(response.data.data)
        
        this.setState({data:response.data.data, total: total, loading:false, date: date});
      }
    }).catch(err=>{
      this.setState({data:[], loading:false});
      console.log(err);
    });
  }

  numberWithCommas(x) {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return (<div>
      <div className="widget">
        <div className={'widgetWidth'+this.state.col2}>
          {this.state.loading && <img className="loadingIcon" src={loading} alt="loading" />}
          {!this.state.loading ?
            <>
              <div className='holdingsSnapshotHeader' ><label>{`${this.state.date.toLocaleDateString("en-US")}`}</label></div>
              {this.state.data.length > 0 ?
                <div style={{ height: this.state.graphHeight + 'px' }}>
                  <table className='holdingsSnapshot'>
                    <tbody>
                      {this.state.data && this.state.data.map((r,i) => {
                        if (i >= 5){
                          return (<></>)
                        }
                        else{
                          return (
                            <tr>
                              <td className='holdingsLabel'>
                                <label style={{fontWeight:'bold'}}>{r.assetAllocation}</label>
                              </td>
                              <td className='holdingsValue'>
                                <label style={{fontWeight:'bold'}}>{this.numberWithCommas((Math.round(r.marketValue * 100) / 100).toFixed(2))}</label>
                              </td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                  <br/>
                  <table className='holdingsSnapshot'>
                    <tbody>
                      <tr>
                        <td className='totalHoldingsLabel'>
                          <label style={{ fontWeight: 'bold' }}>Total</label>
                        </td>
                        <td className='totalHoldingsValue'>
                          <label style={{ fontWeight: 'bold' }}>{this.numberWithCommas((Math.round(this.state.total * 100) / 100).toFixed(2))}</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                : <div className='widgetNoDataWithDate'>No holdings during the period</div>
              }
            </>
            : <div className='widgetNoData'></div>}
        </div>
      </div>
    </div>
    );
  }
}

export default FixedIncomeHoldings;