import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import { Upload } from '@progress/kendo-react-upload';
import axios from 'axios';

class AddGroup extends Component {

  constructor(props) {
    super(props);
      this.state = {
        groupName:'',
        files:[],
        householdsToAdd:[],
        householdsToAddFromFile:[]
      }

    this.clearState = this.clearState.bind(this);
    this.createGroup = this.createGroup.bind(this);
  }

  onChange = (e)=>{
    this.setState({groupName:e.target.value});
  }

  createGroup(callback){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var households = []
    if (this.state.householdsToAdd.length > 0) {
        for (let householdToAdd of this.state.householdsToAdd){
          households.push(householdToAdd.household)
        }
        //households = this.state.householdsToAdd
    }
    if (this.state.householdsToAddFromFile.length > 0) {
        households = this.state.householdsToAddFromFile
    }

    var payload = {
      households:households,
      action:'createnew',
      group:this.state.groupName,
      company:this.props.user.company
    }

    console.log(payload)

    this.setState({adding:true});
    axios.post("api/householdgroup", payload, {headers}).then(response => {
        
      
    
      if(response.data.code===200){
        alert('Household group created successfully');
        this.setState({adding:false});
        callback()
      }
      else{
        alert('Error creating households');
        this.setState({adding:false});
        callback();
      }
    }).catch(err=>{
      alert("Household group created successfully but there was an error adding households to the group"); 
      this.setState({adding:false});
      console.log(err);
      callback("ERROR");
    }); 

    // axios.post("api/createHouseholdGroup", payload, {headers}).then(response => {
    //   if(response.data.code===200){

    //     var households = []
    //     if (this.state.householdsToAdd.length > 0) {
    //       households = this.state.householdsToAdd
    //     }
    //     if (this.state.householdsToAddFromFile.length > 0) {
    //       households = this.state.householdsToAddFromFile
    //     }
    //     payload = {
    //       households:households,
    //       action:'add',
    //       group:response.data.newGroupId,
    //       company:this.props.user.company
    //     }
      
    //     this.setState({adding:true});
      
    //     axios.post("api/householdgroup", payload, {headers}).then(response2 => {
        
    //       this.setState({adding:false});
        
    //       if(response2.data.code===200){
    //         alert('Household group created successfully');
    //         callback()
    //       }
    //       else{
    //         alert('Household group created successfully but there was an error adding households to the group: ' + response2.data.errMsg);
    //         callback();
    //       }
    //     }).catch(err=>{
    //       alert("Household group created successfully but there was an error adding households to the group"); 
    //       console.log(err);
    //       callback("ERROR");
    //     }); 

    //   }
    //   else{
    //     alert(response.data.error);
    //     callback("ERROR");
    //   }
    // }).catch(err=>{
    //     alert("An error has occured"); 
    //     console.log(err); 
    //     callback("ERROR");});    
  }

  onHouseholdsChange = (e)=>{
    this.setState({householdsToAdd:e.target.value});
  }

  onAdd = (e)=>{
    this.setState({files:e.newState});
  }

  onRemove = (e)=>{
    this.setState({files:e.newState});
  }

  onStatusChange = (e)=>{
    this.setState({files:e.newState, householdsToAddFromFile:e.response.response.households});
  }

  onBeforeUpload = (e)=>{
    //e.additionalData.company=this.props.company;
  }

  renderFile = (props)=>{
    const { files } = props;

      return (
      <ul>
          {
              files.map(file =>{
                return file.error ?
                <>
                <div style={{color:'red'}}key={file.name}>
                  <div>{file.name}</div>
                  <div>Error on line: {file.errorLine}</div>
                  <div>Error: {file.error}</div>
                </div>
                </>
                :
                <div>
                  <div style={{color:'green'}}key={file.name}>
                    <div>{file.name}</div>
                    <div>{this.state.householdsToAddFromFile.length} households found</div>
                  </div>
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.clearFileState("file")}>Cancel</Button>
                </div>

              })          
          }
      </ul>);
  }
  clearState(){
      this.setState({
        groupName:'',
        files:[],
        householdsToAdd:[],
        householdsToAddFromFile:[]
      });
  }

  clearFileState(){
    this.setState({
      files:[], householdsToAddFromFile:[]
    });
}

  render() {
      var manualLabelColor = ''
      var fileLabelColor = ''
      if (this.state.householdsToAddFromFile.length > 0) {
         manualLabelColor = '#d7d7d7'
      }
      if (this.state.householdsToAdd.length > 0) {
        fileLabelColor = '#d7d7d7'
      }

      return (
        <Popup trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={close} />


              <div className="header" align="left">
                Create New Group
              </div>

              <div className="content">
                <label>Group Name: </label><Input onChange={this.onChange}/>
                <br/><br/>
                <div className="header" align="left">
                  <label style={{ color: manualLabelColor }}>Add Households to Group</label>
                </div>

                <div className="content">
                  <MultiSelect
                    data={this.props.households}
                    disabled={this.state.files.length > 0}
                    textField="household"
                    onChange={this.onHouseholdsChange}
                  />
                </div>
              </div>

              <h3><label style={{ color: fileLabelColor }}>Bulk:</label></h3>
                <div><label style={{ color: fileLabelColor }}>Upload a list of households in *.txt format</label></div>
                <Upload autoUpload={true} 
                  multiple={false} 
                  files={this.state.files}
                  disabled={this.state.householdsToAdd.length > 0}
                  listItemUI={this.renderFile}
                  restrictions={{
                    allowedExtensions: [ '.txt', ]
                  }}
                  onAdd={this.onAdd}
                  onRemove={this.onRemove}
                  onStatusChange={this.onStatusChange}
                  //onBeforeUpload={e=>this.onBeforeUpload(e,true)}
                  saveUrl={'api/parseHouseholdGroupFile?company='+this.props.user.company}
                  saveHeaders={{'authorization': 'Bearer '.concat(this.props.idToken)}}
                />
                <br/>
                <br/>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </Button>

                <Button 
                    className="upload"  
                    onClick={(e) => {this.createGroup((err)=>{
                      if(!err){
                        this.props.refreshGroups();
                        close();
                      }
                    });
                    }}
                >
                  Create Group
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };

} 

export default AddGroup;