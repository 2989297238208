import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import HTMLEditor from '../../PushEmail/components/JoditEditor'

class StagedEmailRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
          username: this.props.user,
          bulkKey:this.props.bulkKey,
          DistinctEmailAddrs:this.props.DistinctEmailAddrs,
          
          EmailSubject:'',
          EmailBody:'',
          htmlModified:false,
          templates:[],
          templatesResult: [],
          selectedTemplate:'',
          editTemp:true,
          
            
            
          
        }
        this.clearState = this.clearState.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.getMailTemplates = this.getMailTemplates.bind(this);
        this.onHTMLChange = this.onHTMLChange.bind(this);
        this.getHTMLTemplate = this.getHTMLTemplate.bind(this);

    }

  
    
    componentDidMount(){
      this.getMailTemplates();
    }


    static getDerivedStateFromProps(nextProps, prevState)
    {
        let update = {};
        if(nextProps.DistinctEmailAddrs!==prevState.DistinctEmailAddrs)
        {
            update.DistinctEmailAddrs = nextProps.DistinctEmailAddrs;
        }
        return Object.keys(update).length ? update : null;        
    }

    clearState = (e) =>{
        this.setState({
            username:"",
            bulkKey:"",
            DistinctEmailAddrs:0,
            EmailSubject:'',
            EmailBody:'',
            htmlModified:false,
            editTemp:true,

            //templates:[],
            //templatesResult: [],
          selectedTemplate:'',


        });
      }

      getMailTemplates(){
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios.get("api/mailtemplate?company="+this.props.company, {headers}).then(response=>{          
          this.setState({templates:response.data, templatesResult:response.data});
        }).catch(err=>console.log(err));
        this.setState({tempsSelected:''});
      }


      onFieldChange(e, field){
        
      }

      isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

      onTemplateChange = (e) => {
        if (!this.isEmpty(e.target.value)) {
            this.setState({ selectedTemplate: e.target.value    }, () => {
                this.setState({editTemp:false,EmailSubject:e.target.value.subject,EmailBody:e.target.value.body } , () => {
                  this.setState({editTemp:true});
                } );
            });
        }
        

    }



      
    sendRequest(e, callback){
        var conf
        conf =  window.confirm("Are you sure you want to send email notifications?");
        
        if(conf){
          var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
          var payload ={
            company: this.props.company,
            user: this.props.user,
            bulkKey: this.props.bulkKey,
            EmailSubject: this.state.EmailSubject,
            EmailBody: this.state.EmailBody,
            
          }
    
          axios.post("api/createStagedEmailRequest", payload, {headers}).then(response=>{
            if(response.data.code===200){
                alert("Your request has been submitted. You will receive an email when it is completed.");
                if(callback){
                    callback();
                }  
            }
            else{
              alert("An error has occurred. Please try again.");
            }
          }).catch(err=>console.log(err));
        }
      }
    

      onHTMLChange(html){
        this.setState({EmailBody:html, htmlModified:true});
      }

      getHTMLTemplate(){
          return this.state.EmailBody;
      }

      getEditField(field){
        
          //this.setState({[field]: this.props.tempEdit[field], initialLoad:false});
          return this.state.EmailBody;
        
      }


    render() {
        return(
            <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
            {close =>  (
              <div className="modal">
                <Button className="close" icon="close" onClick={(e) => {close(); this.clearState();}} />
  
  
                <div className="header" align="left">
                  Request Email Notifications for: {this.props.bulkKey}
                  </div>
  
                <div className="content">


                <h3>THERE ARE {this.state.DistinctEmailAddrs} DISTINCT USERS TO BE NOTIFIED.</h3> 
                
                <table className="styleTable">
            <tbody>
              <tr>
              <td><strong>Select Email Template:</strong></td>
                <td>


                <ComboBox
                    style={{ width: '30vw' }}
                    data={this.state.templatesResult}
                    textField='title'
                    onChange={this.onTemplateChange}
                    value={this.state.selectedTemplate}
                    filterable={true}
                    allowCustom={false}
                    onFilterChange={(e) => {
                        this.setState({ templatesResult: filterBy(this.state.templates, e.filter) });
                    }}
                />

                </td>
                </tr>

                <tr><td><strong>Email Subject:</strong></td><td><input type='text' value={this.state.EmailSubject} onChange={(e)=>{this.setState({EmailSubject:e.target.value})}}/></td></tr>

                <tr><td colSpan="2">
                <div className='htmlEditContainer'>
                  <HTMLEditor onHTMLChange={this.onHTMLChange} template={this.state.EmailBody} editTemp={this.state.editTemp} />
                </div>
                  </td></tr>

                </tbody>
                </table>

                  
                             
                </div>
                <div className='actions'>
                  
                  <Button className='upload' onClick={(e) => {this.sendRequest(e, close);}}>Request Sending of Email Notifications</Button>
                </div> 
              </div>
            )}
          </Popup>

        );



    }



}

export default StagedEmailRequest;