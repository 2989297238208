import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class AddClients extends Component {

  constructor(props) {
    super(props);
      this.state = {
        newAccount:''
      }

    this.clearState = this.clearState.bind(this);
  }

  componentDidMount(){

  }



  clearState(){
      this.setState({
        newAccount:''
      });
  }

  onChange = (e)=>{
      this.setState({newAccount:e.target.value});
  }

  renameAccount(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
        company: this.props.user.company,
        oldPort: this.props.oldPort,
        newPort: this.state.newAccount
    }

    axios.post('api/renameAccount', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Account name has been successfully updated.");
            this.props.refresh();
        }
        else if(response.data.code===301){
            alert("Error: The account you are trying to change this to already exists.");
            this.props.refresh();
        }
        else{
            alert('An error occured when trying to rename this account.');
            this.props.refresh();
        }
    }).catch(err=>{
      console.log(err);
      alert('An error occured when trying to rename this account.');
      this.props.refresh();
      this.setState({newAccount:''});
    });
  }

  render() {
      return (
        <Popup trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={close} />


              <div className="header" align="left">
                Rename Account
              </div>

              <div className="content">
                <div><strong>Renaming this account will impact all the data previously uploaded. But will not impact any previously uploaded files.</strong></div>
                <br/>
                <div><label>Old account name: </label>{this.props.oldPort}</div>
                <label>New account name: </label><input type='text' value={this.state.newAccount} onChange={this.onChange}/>
              </div>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </Button>

                <Button 
                    className="upload"
                    onClick={(e) => {
                        var confirm = window.confirm("Are you sure you want to rename this account?");
                        if(confirm){
                            this.renameAccount();
                            close();
                        }
                        }
                    }
                >
                  Confirm
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };

}

export default AddClients;