import React from 'react';
import ReactDOM from 'react-dom';

class GridLoading extends React.Component {
    render() {
        const loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>
        );

        var query = '.k-grid-content';
        if(this.props.gridClass){
            query = 'div.'+this.props.gridClass+' .k-grid-content';
        }
        const gridContent = document && document.querySelector(query);
        return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
    }
}

export default GridLoading;