import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import axios from 'axios';
import styles from './styles.module.css';



class StagedActionRequest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: this.props.user,
      description: '',
      categorySelected: "",
      useHouseholdFolders: this.props.useHouseholdFolders,
      selectedBulkKey: this.props.selectedBulkKey,
      actionMode: "",
      UnmappedDocuments: this.props.UnmappedDocuments,
      makePrivateCheckbox: false

    }
    this.clearState = this.clearState.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }


  static getDerivedStateFromProps(nextProps, prevState) {

    let update = {};

    if (nextProps.useHouseholdFolders !== prevState.useHouseholdFolders) {
      update.useHouseholdFolders = nextProps.useHouseholdFolders;
    }
    if (nextProps.UnmappedDocuments !== prevState.UnmappedDocuments) {
      update.UnmappedDocuments = nextProps.UnmappedDocuments;
    }





    return Object.keys(update).length ? update : null;

  }

  handleChange = (color) => {
    this.setState({ makePrivateCheckbox: !this.state.makePrivateCheckbox });
  };


  clearState = (e) => {
    this.setState({
      username: "",
      description: "",
      categorySelected: "",
      useHouseholdFolders: false,
      selectedBulkKey: [],
      actionMode: "",
      UnmappedDocuments: 0,
      makePrivateCheckbox: false
    });
  }


  onFieldChange(e, field) {

    if (field === 'category') {
      this.setState({ categorySelected: e.target.value });
    }
    else {
      this.setState({ description: e.target.value });
    }

  }

  onClick_UseHH = () => {
    this.setState({ useHouseholdFolders: !this.state.useHouseholdFolders });
  };



  onInputChange(e) {
    var val = e.target.value;
    this.setState({ actionMode: val });
  }




  sendRequest(e, callback) {
    var conf = "";

    if (this.state.actionMode === 'Deploy') {
      conf = window.confirm("Are you sure you want deploy the files to the vault?");
    }
    if (this.state.actionMode === 'Remove') {
      conf = window.confirm("Are you sure you want remove the files from the vault?");
    }


    if (conf) {
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload = {
        company: this.props.company,
        user: this.props.user,
        bulkKey: this.props.selectedBulkKey.bulkKey,
        description: this.state.description,
        folder: this.state.categorySelected,
        actionMode: this.state.actionMode,
        useHouseholdFolders: this.state.useHouseholdFolders,
        makePrivateCheckbox: this.state.makePrivateCheckbox
      }

      axios.post("api/createStagedFileRequest", payload, { headers }).then(response => {
        if (response.data.code === 200) {
          alert("Your request has been submitted. You will receive an email when it is completed.");
          if (callback) {
            callback();
          }
        }
        else {
          alert("An error has occurred. Please try again.");
        }
      }).catch(err => console.log(err));
    }
  }





  render() {


    const stylePrivateToolTipCells = {
      paddingTop: "0px",
      paddingBottom: "0px",
      fontSize: "10px",
    };
    const stylePrivateCheckHead = {
      paddingBottom: "1px",
    };

    

    

    return (


      <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">
            <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


            <div className="header" align="left">
              Request Document Action for: {this.props.selectedBulkKey.bulkKey}
            </div>

            <div className="content">


              {this.state.UnmappedDocuments !== 0 && <h3 style={{ color: 'red' }}  >THERE ARE {this.state.UnmappedDocuments} UNMAPPED DOCUMENTS.</h3>}
              {this.state.UnmappedDocuments === 0 && <h3 >ALL DOCUMENTS ARE MAPPED.</h3>}


              <h3>Enter the action settings below:</h3>


              {this.state.useHouseholdFolders && <tr><td><strong>Importing into Household Folders.</strong></td></tr>}
              {!this.state.useHouseholdFolders && <tr><td><strong>Importing into User Folders.</strong></td></tr>}


              <table className={styles.table}>


                <colgroup>
                  <col span="1" style={{ width: '30%' }} />
                  <col span="1" style={{ width: '70%' }} />
                </colgroup>

                <tbody  >

                  <tr >
                    <td><strong>Select Action:</strong></td>
                    <td><input type="radio" name="actionMode" value="Deploy" onChange={e => this.onInputChange(e)} />Deploy Files to Vault</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td><input type="radio" name="actionMode" value='Remove' onChange={e => this.onInputChange(e)} />Remove Files from Vault</td>
                  </tr>

                  {this.state.actionMode === 'Deploy' &&
                    <>
                      <tr>
                        <td style={stylePrivateCheckHead}><strong>Mark Documents as Private:</strong></td>
                        <td style={stylePrivateCheckHead}>
                          <input type="checkbox" checked={this.state.makePrivateCheckbox} onChange={this.handleChange} label={'Yes'} />
                        </td>
                      </tr>
                      <tr  >
                        <td style={stylePrivateToolTipCells} >(Users will NOT see these documents;</td>
                        <td style={stylePrivateToolTipCells} ></td>
                      </tr>
                      <tr  >
                        <td style={stylePrivateToolTipCells}>only visible to Admins and Advisors)</td>
                        <td style={stylePrivateToolTipCells}></td>
                      </tr>
                    </>




                  }


                  <tr>
                  <td><strong>Vault Folder:</strong></td>
                    <td>
                      <ComboBox
                        style={{ width: '400px' }}
                        data={this.state.vaultFolderListResult || this.props.vaultFolderList}//uses filtered list if exists, otherwise uses original props
                        onChange={(e) => { this.setState({ categorySelected: e.target.value }) }}
                        value={this.state.categorySelected}
                        filterable={true}
                        allowCustom={true}
                        onFilterChange={(e) => {
                          this.setState({ vaultFolderListResult: filterBy(this.props.vaultFolderList, e.filter) });
                        }}
                      />
                    </td>
                  </tr>

                  {this.state.actionMode === 'Deploy' &&
                    <tr>
                      <td>
                        <strong>File Description [Optional]:</strong>
                      </td>
                      <td>
                        <ComboBox
                          style={{ width: '400px' }}
                          data={this.state.descriptionsResult || this.props.descriptions}//uses filtered list if exists, otherwise uses original props
                          onChange={(e) => { this.setState({ description: e.target.value }) }}
                          value={this.state.description}
                          filterable={true}
                          allowCustom={true}
                          onFilterChange={(e) => {
                            this.setState({ descriptionsResult: filterBy(this.props.descriptions, e.filter) });
                          }}
                        />
                      </td>
                    </tr>
                  }


                </tbody>
              </table>


            </div>
            <div className='actions'>


              {this.state.actionMode !== "" && <Button className='upload' onClick={(e) => { this.sendRequest(e, close); }}>Request Action</Button>}
            </div>
          </div>
        )}
      </Popup>



    );

  }



}


export default StagedActionRequest;