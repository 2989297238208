import React, { Component } from "react";
import {Chart, ChartTooltip, ChartSeriesItemTooltip, ChartSeries, 
    ChartSeriesItem, ChartTitle, ChartCategoryAxis, 
    ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartLegend} from '@progress/kendo-react-charts';
import axios from 'axios';
import {parseDate} from '@telerik/kendo-intl';
import 'hammerjs';
import GridLoading from '../../Loaders/GridLoading';

class GrowthHundred extends Component {

  constructor(props) {
    super(props);

    var typeText = "TWR";

    if(props.compSettings.growthIrr)
      typeText = "IRR";

    this.state = {
        data:[],
        perfType:this.props.perfType,
        chartType:this.props.chartType,
        portfolio:this.props.portfolio,
        fromDate:this.props.fromDate,
        toDate:this.props.toDate,
        title:typeText,
        loading:false,
    }

    this.getGrowth = this.getGrowth.bind(this);
    this.getDate = this.getDate.bind(this);
  }

  componentDidMount(){
    this.getGrowth();
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio || this.props.perfType!==this.state.perfType || this.props.chartType!==this.state.chartType
      || this.props.fromDate!==this.state.fromDate || this.props.toDate!==this.state.toDate){
      this.setState({portfolio:this.props.portfolio, perfType:this.props.perfType, chartType:this.props.chartType, fromDate:this.props.fromDate, toDate:this.props.toDate}, ()=>{this.getGrowth()});
    }
  }

  getGrowth(){
    this.setState({loading:true});

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var unmapped = '';
    if(this.props.user.unmapped)
      unmapped = 'true'

    var irr = '';
    if(this.props.compSettings.growthIrr)
      irr = 'true';

    var payload = {
      company:this.props.user.company,
      port:this.state.portfolio.account,
      household:this.props.user.defaultAcct.household,
      perfType:this.state.perfType,
      from:this.state.fromDate,
      to:this.state.toDate,
      user:this.props.user.user,
      unmapped:unmapped,
      irr:irr
    }

    axios.post('api/growthHundred', payload, {headers}).then(response=>{
      if(response.data.code===200){
        var fDate = new Date();

        if(response.data.data.length>0)
          fDate = this.getDate(response.data.data[0][0].date, true);

        if(this.state.fromDate===null)
          this.props.setFromDate(fDate);

        this.setState({data:response.data.data});
        this.setState({loading:false});
      }
    }).catch(err=>{
      this.setState({loading:false});
      console.log(err);
    });
  }

  getDate(sqlDate, isUTC){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if(d){
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  renderTooltip = ({point})=>{
    if(point!==undefined){
        return (
            <>
              <div><strong>{point.category.toLocaleDateString("en-US", {month:'long', year:'numeric'})}</strong></div>
              <div>Growth: {point.value.toFixed(2)}%</div>
            </>);
    }
    else{
      return(<div></div>);
    }
  }

  render() {
    
    var graphData = this.state.data.slice();

    return(
        <div className='growthHundred'>
        <Chart>
          <ChartTitle text={"Performance Growth "+this.state.title}/>
          <ChartLegend position="bottom" orientation='vertical'/>
          <ChartTooltip />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem title={{text:"Date"}} type='date' maxDivisions={20} baseUnit='months'/>
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem title={{ text: "Growth" }}/>
        </ChartValueAxis>
            <ChartSeries>
              {graphData.map((point, i)=>(
                <ChartSeriesItem key={i} name={point[0].displayName} markers={{visible:false}} type={this.state.chartType} field='growth' categoryField='date' color={point[0].color} data={point}>
                  <ChartSeriesItemTooltip render={this.renderTooltip} />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
        </Chart>
        {this.state.loading && <GridLoading />}
        </div>
    );
  }
}

export default GrowthHundred;