import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import InviteForm from './components/InviteForm';
import axios from 'axios';
import * as utils from '../utilities';

class InviteUser extends Component {
    
    constructor(props) {
        super(props);
        this.state = {

        }
        this.sendInvite = this.sendInvite.bind(this);
    }

   sendInvite(users, template, EnableMFA , callback){
    var headers = {
        'Authorization': 'Bearer '.concat(this.props.idToken),
        };
    var payload = {
        users:users,
        company:this.props.company,
        RIAFirmName: this.props.RIAFirmName,
        template:template,
        bulkUnmapped: this.props.unmappedUsers,
        EnableMFA:EnableMFA
    };

    axios.post('api/inviteUser', payload, {headers}).then(response=>{
        if(response.data.code===200){
            callback();
            alert("User(s) have been successfully invited.");
        }
        else if(response.data.code===201){
            callback("ERROR");
            alert("User(s) have been successfully invited but there was an error removing users from the Unmapped list.");
        }
        else{
            callback("ERROR");
            alert(response.data.errMsg+" \nOne or more invites were unsuccessful.");
        }
        this.props.refreshUsers();
      }).catch(err =>{this.props.refreshUsers(); console.log(err); callback(err)});
   }

    render() {
        if(!utils.isAdmin(this.props.user)){
            return null;
        }
        return (
            <span>
            <InviteForm 
                unmappedUsers={this.props.unmappedUsers}
                unmappedUserList={this.props.unmappedUserList}
                unmappedChangeMFA={this.props.unmappedChangeMFA}
                inviteUser={this.sendInvite}
                compSettings={this.props.compSettings}
                company={this.props.company}
                idToken={this.props.idToken}
                role={this.props.role}
                roles={this.props.roles}
                user={this.props.user}
                button={<Button 
                    icon={this.props.icon != null ? this.props.icon : "plus"}
                    disabled={this.props.unmappedUsers && this.props.unmappedUserList && this.props.unmappedUserList.length === 0 }
                    > 
                        {this.props.unmappedUsers ? 'Invite Unmapped User(s)' : 'Invite User'}
                    </Button>} />
            </span>
        )
    };


}

export default InviteUser;