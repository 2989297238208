import React from 'react';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import DisclaimersPopup from './components/DisclaimersPopup.js';

const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class Disclaimers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            data: [],
            disclaimers: [],
            contentSelected: false,
            reportSelect: '',
            changes: false,
            reportsLoading: true,
            gridFilter: undefined,
            filterable: false,
            reportStyles: [],
            sort: [{ field: 'SortOrder', dir: 'asc' }],
            fileDownload:[],
        };

        this.headerCellRender = this.headerCellRender.bind(this);
        this.getDisclaimerList = this.getDisclaimerList.bind(this);
        this.refreshDisclaimers = this.refreshDisclaimers.bind(this)
    }

    componentDidMount() {
        this.getDisclaimerList();
    }

    getDisclaimerList() {
        this.setState({ refreshText: 'Loading...', reportSelected: false, reportSelect: '', });
        var disclaimers = [];


        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetDisclaimerList`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {


                response.data.data.forEach(disclaimerInResponse => {

                    if (disclaimerInResponse.updated){
                        disclaimerInResponse.updated = this.getDate(disclaimerInResponse.updated);
                    }

                    disclaimerInResponse.DisclaimerTextWithNewline = disclaimerInResponse.DisclaimerText.replace(/\\n/g, '\n')
                    disclaimerInResponse.DisclaimerTextWithNewlineAsSpace = disclaimerInResponse.DisclaimerText.replace(/\\n/g, ' ')
                    
                    disclaimerInResponse.selected = false

                    disclaimers.push(disclaimerInResponse)

                })
                //var sort=[{ field: 'type', dir: 'asc' }, { field: 'reportName', dir: 'asc' }]
                this.setState({ data: disclaimers.map(cloneProduct), disclaimers: disclaimers, refreshText: '' })

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item =>{ 
            if (item.DisclaimerCode !== event.dataItem.DisclaimerCode){
                item.selected = false
            }
        });
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles }, () => {this.forceUpdate();});
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    refreshDisclaimers() {
        this.setState({ fileDownload: [] });
        this.setState({ fileSelected: false });
        this.getDisclaimerList();
    }


    getDate(sqlDate, isUTC) {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        if (isUTC)
            return utc;
        else
            return d;
    }

    onLinkClick = (event, rep, idToken) => {
        const headers = { 'authorization': 'Bearer '.concat(idToken) };
        axios
            .get("api/download?company=" + this.props.user.company + "&path=" + rep.contentPath + "&name=" + rep.contentName, { headers })
            .then((response) => {
                window.open(response.data, '_blank');
            })
            .catch((e) => {
                console.error("ERROR");
                return false;
            });

        return false;
    }

    onDelete = (e) => {

        var confirm = window.confirm("Are you sure you want to delete this disclaimer?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                disclaimers: this.state.fileDownload,
                company: this.props.user.company,
            };
            axios.post('api/ssrsDeleteDisclaimers', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getDisclaimerList();
                }
                else {
                    alert("Error: Disclaimer could not be deleted.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getDisclaimerList();
                }
            }).catch(e => console.log(e));
        }
    }

    headerCellRender(thElement, cellProps) {
        if (thElement !== null) {
          if (cellProps.field === 'selected')
          return (
            <div  />
          );
          else{
              return thElement
          }
        }
        else {
          return thElement;
        }
      }

    render() {
        return (
            <div className='disclaimers'>

                <StatefulGrid data={this.state.disclaimers}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.disclaimers)}
                    onRowClick={(e) => this.selectionChange(e, this.state.disclaimers)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.disclaimers)}
                    headerCellRender={this.headerCellRender}
                    filterable={this.state.filterable}
                    resizable
                >
                    <GridToolbar>
                        <Button icon='refresh' title='Refresh' onClick={this.refreshDisclaimers}>{this.state.refreshText}</Button>
                        <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                        <DisclaimersPopup idToken={this.props.idToken} uploadMethod={this.refreshDisclaimers} files={this.state.disclaimers} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} type='reportStaticContent' button={<Button className="docButton" icon="upload">New</Button>} />
                        {this.props.uploadUser.role==='admin' && (<DisclaimersPopup idToken={this.props.idToken} disclaimerEdit={this.state.fileDownload[0]} uploadMethod={this.refreshDisclaimers} files={this.state.disclaimers} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} button={<Button className="docButton" disabled={this.state.fileDownload.length!==1} icon='edit'>Edit</Button>} />)}           
                        {this.props.uploadUser.role === 'admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete</Button>)}
                    </GridToolbar>
                    <GridColumn width='50px' field="selected" filterable={false} headerClassName='gridHeader' headerSelectionValue={this.state.disclaimers.findIndex(dataItem => dataItem.selected === false) === -1 && this.state.disclaimers.length > 0} />
                    <GridColumn
                        width='180px'
                        field="DisclaimerCode"
                        title="Disclaimer Name"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.DisclaimerCode}
                            </td>
                        }
                    />
                    <GridColumn field="DisclaimerTextWithNewlineAsSpace" headerClassName='gridHeader' title="Text" />
                    <GridColumn width='160px' field="updated" headerClassName='gridHeader' filter="date" format="{0:g}" title="Last Updated:" />
                    {/* <GridColumn
                        width='180px'
                        field="title"
                        title="Report"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.samplePath && <button className='link-button' style={{ color: '#656565' }} onClick={(event) => this.onLinkClick(event, props.dataItem, this.props.idToken)}>{props.dataItem.title}</button>}
                                {!props.dataItem.samplePath && props.dataItem.title}
                            </td>
                        } />
                    <GridColumn width='80px' field="platform" headerClassName='gridHeader' title="Platform" filterCell={createFilterDropDownCell(this.state.platforms, '')} />
                    <GridColumn width='125px' field="category" headerClassName='gridHeader' title="Category" filterCell={createFilterDropDownCell(this.state.categories, '')} />
                    <GridColumn width='400px' field="description" headerClassName='gridHeader' title="Description" />
                    <GridColumn width='120px' field="reportName" headerClassName='gridHeader' title="File Name" />
                    <GridColumn width='90px' field="expiration" headerClassName='gridHeader' filter="date" format="{0:d}" title="Expiration" />
                    <GridColumn width='145px' field="uploadDate" headerClassName='gridHeader' filter="date" format="{0:g}" title="Last Updated:" /> */}
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='webRep' />}
            </div>
        )
    }

}

export default Disclaimers