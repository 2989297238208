import React, { Component } from "react";
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import ChartColors from './ChartColors';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { SketchPicker } from 'react-color'
import { Sortable } from '@progress/kendo-react-sortable';
import LogoUpload from "./LogoUpload";
import reactCSS from 'reactcss'


class Style extends Component {

    constructor(props) {
        super(props);

        var rgba = null
        var htmlColor = null
        var sortData = []
        var chartColors = []
        var colorListLabels = false

        if (this.props.style.dataType === 'color') {
            var color = this.props.style.value.replace('rgb(', '')

            color = color.replace(')', '')

            var splitColor = color.split(',')

            rgba = {
                r: splitColor[0],
                g: splitColor[1],
                b: splitColor[2],
                a: splitColor[3]
            }

            htmlColor = this.props.style.value
        }
        else if (this.props.style.dataType === 'colorList' || this.props.style.dataType === 'colorListLabels') {
            if (this.props.style.dataType === 'colorListLabels'){
                colorListLabels = true
            }
            var colorString = this.props.style.value
            if (colorString){
                colorString.split('|').forEach((colorLabelPair, i) => {
                    var c = colorLabelPair.split('<')
                        var color = c[0].replace('rgb(', '')
    
                        color = color.replace(')', '')
    
                        var splitColor = color.split(',')
    
                        rgba = {
                            r: splitColor[0],
                            g: splitColor[1],
                            b: splitColor[2],
                            a: splitColor[3]
                        }
    
                        chartColors.push({id: i, color: rgba, type: this.props.style.dataType === 'colorList' ? null : 'labels', mapping: c[1]})
                });
            }
        }

        else if (this.props.style.dataType === 'sortable'){
            const values = this.props.style.value.split('|')
            const valueLabels = this.props.style.availableValues.split('|')

            for (let i = 0; i < values.length; i++) {
                sortData.push({ id: values[i], text: valueLabels[i] })
            }
        }


        this.state = {
            company: this.props.company,
            color: rgba,
            htmlColor: htmlColor,
            sortData: sortData,
            chartColors: chartColors,
            colorListLabels: colorListLabels,
            noReturn: false
        }

        this.clearState = this.clearState.bind(this);
        this.createStyleObject = this.createStyleObject.bind(this);
    }

    componentDidMount() {
    }

    handleChangeComplete = (color) => {

        var htmlColor = 'rgb(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')'
        this.props._handleColorChange(color, this.props.style)
        this.setState({ color: color.rgb, htmlColor: htmlColor })
    };

    onChartColorsChange = (color) => {
        this.props._handleChartColorChange(color, this.props.style)
        this.setState({chartColors: color})
    }

    handleClick = () => {
        this.setState({ showColorPicker: !this.state.showColorPicker })
    };

    handleClose = () => {
        this.setState({ showColorPicker: false })
    };

    handleSortChange = (e) => {

        this.setState({sortData: e.newState})
        this.props._handleChange(e)
        //this.setState({ color: color.rgb, htmlColor: htmlColor })
    };

    sortItemUI = (props) => {
        const { isActive, isDragCue, style, attributes, dataItem, forwardRef } = props;
        return (
            <div ref={forwardRef}
                {...attributes}
                style={{
                    ...this.getBaseItemStyle(isActive, isDragCue),
                    ...style
                }}
            >
                {dataItem.text}
            </div>
        )
    }

    onChangeLogo = (event) => {
        this.setState({ cornerPreview: URL.createObjectURL(event.target.files[0]) })
        this.props._handleLogoChange(event)
    }

    getBaseItemStyle = (isActive, isDragCue) => ({
        fontSize: '16px',
        textAlign: 'center',
        outline: 'none',
        border: '1px solid',
        cursor: 'move',
        borderColor: isActive ? '#27aceb' : 'black',
        background: 'white',
        marginBottom: '5px',
        display: isDragCue ? 'none' : 'block'//hide item when dragging
    });

    createStyleObject(style, colorButtonstyles) {
        //put dataType check here for types that are not text boxes, such as dropdowns or checkboxes. not needed with initial creation
        if (style.dataType === 'boolean' && style.name !== 'defaultViewWebReports') {
            return (
                <Switch
                    dataType={style.dataType}
                    name={style.name}
                    defaultChecked={style.value}
                    description={style.description}
                    disabled={style.protected}
                    onChange={this.props._handleChange}
                />
            );
        }
        else if (style.dataType === 'boolean'  && style.name === 'defaultViewWebReports' && this.props.company === 'cssi') {
            return (
                <Switch
                    dataType={style.dataType}
                    name={style.name}
                    defaultChecked={style.value}
                    description={style.description}
                    disabled={style.protected}
                    onChange={this.props._handleChange}
                />
            );
        }
        else if (style.dataType === 'boolean' && style.name === 'defaultViewWebReports' && this.props.company !== 'cssi') {
            this.setState({noReturn: true})
            return (
                <></>
            );
        }
        else if (style.dataType === 'dropdown') {
            const availableValues = style.availableValues.split('|')
            return (
                <DropDownList
                    data={availableValues}
                    dataType={style.dataType}
                    name={style.name}
                    onChange={this.props._handleChange}
                    defaultValue={style.value}
                    description={style.description}
                    disabled={style.protected}
                />
            );
        }
        else if (style.dataType === 'multiselect') {
            const availableValues = style.availableValues.split('|')
            return (
                <MultiSelect 
                    data={availableValues}
                    dataType={style.dataType}
                    name={style.name}
                    autoClose={false}
                    //textField="text"
                    //value={this.getValue()}
                    onChange={this.props._handleChange}
                    defaultValue={style.value.split('|') || []}
                    description={style.description}
                    disabled={style.protected}
                    popupSettings={{className: "top"}}
                    //placeholder={this.props.placeholder}
                />
            );
        }
        else if (style.dataType === 'sortable') {
            return (
                <Sortable
                    style={{position:'relative'}}
                    data={this.state.sortData}
                    name={style.name}
                    dataType={style.dataType}
                    description={style.description}
                    idField={'id'}
                    onDragOver={this.handleSortChange}
                    onNavigate={this.handleSortChange}
                    itemUI={this.sortItemUI}
                    disabled={style.protected}
                />
            );
        }
        else if (style.dataType === 'color') {
            return (
                <div style={{position:'relative'}}>
                    <div style={colorButtonstyles.swatch} onClick={this.handleClick} >
                        <div style={colorButtonstyles.color} />
                    </div>
                    {this.state.showColorPicker ? <div style={colorButtonstyles.popover}>
                        <div style={colorButtonstyles.cover} onClick={this.handleClose} />
                        <SketchPicker
                            color={this.state.color}
                            onChangeComplete={this.handleChangeComplete}
                        />
                    </div>
                        :
                        null
                    }

                </div>
            );
        }
        else if (style.dataType === 'colorList' || style.dataType === 'colorListLabels') {
            return (
                <>
                    <ChartColors colors={this.state.chartColors} colorListLabels={this.state.colorListLabels} onChange={this.onChartColorsChange} />
                </>
            );
        }
        else if (style.dataType === 'number') {
            return (
                <Input
                    style={{ width: '75px' }}
                    type="number"
                    dataType={style.dataType}
                    name={style.name}
                    defaultValue={style.value}
                    description={style.description}
                    disabled={style.protected}
                    onChange={this.props._handleChange}
                />
            );
        }
        else if (style.dataType === 'decimal') {
            return (
                <Input
                    style={{ width: '75px' }}
                    type="number"
                    min="0"
                    step="0.1"
                    dataType={style.dataType}
                    name={style.name}
                    defaultValue={style.value}
                    description={style.description}
                    disabled={style.protected}
                    onChange={this.props._handleChange}
                />
            );
        }
        else if (style.dataType === 'cornerLogo') {
            return (
                <LogoUpload 
                    previewImage={this.state.cornerPreview} 
                    //onChangeLogo={(event) => this.onCornerLogoChange(event)} 
                    onChangeLogo={(event) => this.onChangeLogo(event)} 
                />
            );
        }
        else {
            return (
                <Input
                    style={{ width: '450px' }}
                    type="text"
                    dataType={style.dataType}
                    name={style.name}
                    defaultValue={style.value}
                    description={style.description}
                    disabled={style.protected}
                    onChange={this.props._handleChange}
                />
            );
        }
    }


    clearState() {

    }

    render() {
        const colorButtonstyles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `${this.state.htmlColor}`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '1',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });


        return (
            !this.state.noReturn &&
            <tr>
                <td className='settingLabel'>
                    <strong>{this.props.style.description}</strong>
                    {this.props.style.dataType === 'cornerLogo' &&
                        <img style={{ paddingLeft: '10px'}} className="cornerUpload" src={this.state.cornerPreview || "Logos/" + this.props.compSettings.cornerLogo} alt="Corner Logo" />
                    }
                    {this.props.style.tooltip &&
                        <div className='settingDescription'>{this.props.style.tooltip}</div>
                    }
                </td>
                <td>
                    {this.createStyleObject(this.props.style, colorButtonstyles)}
                </td>
            </tr>
        );
    }

}



export default Style;