import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import DatabaseListPopUpExclude from './components/DatabaseListPopUpExclude';
import DatabaseListPopUpInclude from './components/DatabaseListPopUpInclude';
import axios from 'axios';

class SqlEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputsql: 'SELECT * FROM [users]',
      includeDB: [],
      excludeDB: [],
      showDB: false,
      union: false,
      preview: true,
      databases: [],
      data: [],
      previewResults: false
    }

  }

  onInputChange = (e) => {
    if (e.target.props != null && e.target.props.type != null) {
      switch (e.target.props.type) {
        case "checkbox":
          this.setState({ [e.target.name]: !this.state[e.target.name] });
          break;
        default:
          this.setState({ [e.target.name]: e.target.value });
          break;
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  sendRequest = () => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      inputsql: this.state.inputsql,
      includeDB: this.state.includeDB.join(','),
      excludeDB: this.state.excludeDB.join(','),
      showDB: this.state.showDB,
      union: this.state.union,
      preview: this.state.preview
    }
    axios
      .post("api/executeAllDatabases", payload, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({
            data: response.data.data,
            previewResults: response.data.preview
          });
        }
        else {
          alert('An error has occured while sending your request.');
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  updateExcludeDatabases = (databaseArr) => {
    this.setState({
      excludeDB: databaseArr
    })
  }

  updateIncludeDatabases = (databaseArr) => {
    this.setState({
      includeDB: databaseArr
    })
  }

  copyContent = async () => {
    let text = document.getElementById('sqlText').innerHTML;
    text = text.replace(/<br>/g,"\r\n");
    try {
      await navigator.clipboard.writeText(text);
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  render() {
    return (
      <div>
        <div className="header">
          SQL editor
        </div>
        <div className="content">
          <textarea
            style={{ width: '95%' }}
            rows="30"
            name="inputsql"
            id="inputsql"
            value={this.state.inputsql}
            onChange={(e) => this.onInputChange(e)}
            placeholder="SQL to be executed across multiple databases"
          />
        </div>
        <div className="content">
          <DatabaseListPopUpInclude
            updateIncludeDatabases={this.updateIncludeDatabases}
            includeDB={this.state.includeDB}
            idToken={this.props.idToken}
            company={this.props.user.company}
            button={<Button className='docButton'>Include Databases ({this.state.includeDB.length})</Button>}
          />
          <DatabaseListPopUpExclude
            updateExcludeDatabases={this.updateExcludeDatabases}
            excludeDB={this.state.excludeDB}
            idToken={this.props.idToken}
            company={this.props.user.company}
            button={<Button className='docButton indent'>Exclude Databases ({this.state.excludeDB.length})</Button>}
          />
          {/* <Input
            type="checkbox"
            name="showDB"
            className="k-checkbox"
            id="showDB"
            onChange={(e) => this.onInputChange(e)}
            defaultChecked={this.state.showDB}
            disabled={this.state.preview}
          />
          <label className="k-checkbox-label indent" for="showDB">Show Database Name in Output</label>
          <Input
            type="checkbox"
            name="union"
            className="k-checkbox"
            id="union"
            onChange={(e) => this.onInputChange(e)}
            defaultChecked={this.state.union}
          />
          <label className="k-checkbox-label indent" for="union">Union All Rows</label> */}
          {/* <Input
            type="checkbox"
            name="preview"
            className="k-checkbox"
            id="preview"
            onChange={(e) => this.onInputChange(e)}
            defaultChecked={this.state.preview}
            disabled={this.state.showDB}
          />
          <label className="k-checkbox-label indent" for="preview">Preview SQL</label> */}
        {/* </div>
        <div className="content"> */}
          <Button className='docButton indent' onClick={this.sendRequest}>Submit</Button>
        </div>
        <div className="content" style={{ marginTop: '20px' }}>
          {this.state.previewResults && <>
            <div className="content">
              <Button className='docButton' onClick={this.copyContent}>Copy SQL to Clipboard</Button>
            </div>
            <div id="sqlText" dangerouslySetInnerHTML={{ __html: String(this.state.data[0][0].preview).replace(/[\r]?\n/g, "<br />") }}
              style={{ width: '95%', fontFamily: 'monospace' }}
              rows="30"
            >
            </div></>}
          {/* {!this.state.previewResults && this.state.data.length > 0 &&
            <div style={{ overflowX: 'scroll' }}>
              {this.state.data.map(db => (
                <table className="styleTable bottomBorders" style={{ marginTop: '20px' }}>
                  <thead>
                    <tr>
                      {Object.keys(db[0]).map(col => (<th>{col}</th>))}
                    </tr>
                  </thead>
                  <tbody>
                    {db.map((row) => (
                      <tr>
                        {Object.keys(row).map(col => (
                          <td>{row[col]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          } */}
        </div>
      </div>
    );
  }
}

export default SqlEditor;