import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import ViewsList from './ViewsList'
import axios from 'axios';

class PermissionsList extends Component {

    constructor(props) {
        super(props);

        var rolePermissions = this.props.role.permissions != null ? this.props.role.permissions.slice() : [];

        this.state = {
            permissions: [],
            rolePermissions: rolePermissions,
        }
    }

    componentDidMount() {
        this.getPermission();
    }

    getPermission = (cb) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.role.idToken) };

        axios.get("api/permissions?company=" + this.props.role.company, { headers }).then(response => {
            if (response.data.code === 200) {
                var formattedPermission = response.data.permissions.filter(permission => permission.active).map(permission => { 
                    const isOverride = this.props.role.permissions != null ? this.props.role.permissions.findIndex(p2 => p2.permissionguid === permission.guid && p2.isOverride) > -1 : false;
                    const active = this.props.role.permissions != null ? this.props.role.permissions.findIndex(p2 => p2.permissionguid === permission.guid && p2.active) > -1 : false;
                    return { 
                        name: permission.permission, 
                        page: permission.guid, 
                        userLevel: permission.userLevel, 
                        isGlobal: permission.isGlobal, 
                        isOverride: isOverride,
                        active: active,
                        originalActive: (permission.isGlobal && isOverride) ? !active : active
                    } 
                });
                this.setState({
                    permissions: formattedPermission,
                    rolePermissions: formattedPermission.filter(item => this.state.rolePermissions.findIndex(permission => permission.permissionguid === item.page) > -1),//.map(item => item.page),
                    originalRolePermissions: formattedPermission.filter(item => this.state.rolePermissions.findIndex(permission => permission.permissionguid === item.page) > -1)//.map(item => item.page)
                }, () => {
                    if (cb)
                        cb();
                });
            }
            else {
                if (cb)
                    cb();
                this.setState({ refreshText: '' });
                alert("An error occured while fetching the permissions list.");

            }
        }).catch(error => console.log(error));
    }

    clearState = () => {
        this.setState({ rolePermissions: [...this.state.originalRolePermissions] });
    }
    
    onPermissionChange = (permission, e) => {
        var newRolePermission = this.state.rolePermissions.slice();
        var indPermission = newRolePermission.findIndex(p2 => p2.page === permission.page);
        if (indPermission === -1) {
            // if permission is not in the array, add it
            newRolePermission.push({...permission, active: e.value, isOverride: (permission.isGlobal && permission.originalActive !== e.value)});
        } else if (indPermission > -1 && (permission.isGlobal && this.props.role.company !== 'cssi') && ((e.value !== permission.originalActive) || (e.value && permission.isGlobal))) {
            // if permission is in the array, is inherited
            newRolePermission[indPermission] = {...permission, active: e.value, isOverride: (permission.isGlobal && permission.originalActive !== e.value)};
        } else {
            // if permission is in the array, is not inherited
            newRolePermission.splice(indPermission, 1);
        }
        this.setState({ rolePermissions: newRolePermission });
    }

    updatePermission = () => {
        var role = this.props.role;
        const headers = { 'authorization': 'Bearer '.concat(role.idToken) };

        var payload = {
            company: role.company,
            roleguid: role.guid,
            role: role.role,
            description: role.description,
            active: role.active,
            order: role.order,
            permissions: this.state.rolePermissions.filter(permission => 
                (role.company === 'cssi' ||
                    this.state.permissions.findIndex(p2 => p2.page === permission.page && 
                        (
                            !permission.isGlobal || 
                            permission.isOverride   
                            // || (p2.isGlobal && (permission.active !== permission.originalActive))
                        )
                    ) > -1
                )
            ),
            users: role.users,
            isGlobal: role.isGlobal,
            isOverride: (role.isGlobal && role.company !== 'cssi') ? true : false,
            ignoreRoleUpdate: true,
            ignoreUsers: true
        }

        axios.post('api/updateRole', payload, { headers }).then(response => {
            if (response.data.code === 200) {
                role.refreshList();
                role.refreshUser('roles',role.company);
            }
            else {
                alert("Error: Permission could not be updated.");
            }
        });
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={() => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={(e) => { close(); }} />
                        <div className="header" align="left">
                            Edit Permissions
                        </div>
                        <div className="content">
                            <div style={{ maxHeight: '25vh', overflow: 'auto' }}>
                                <ViewsList
                                    data={[...this.state.permissions].filter(view => view.name.indexOf('___') === -1)}
                                    views={this.state.rolePermissions}
                                    company={this.props.role.company}
                                    isGlobal={this.props.role.isGlobal}
                                    onChange={this.onPermissionChange} />
                            </div>
                        </div>
                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>
                            <Button
                                className="upload"
                                onClick={(event) => { this.updatePermission(event); close(); }}>
                                Update
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>

        );
    };
}

export default PermissionsList;