import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { Upload } from '@progress/kendo-react-upload';
import {DatePicker} from '@progress/kendo-react-dateinputs';
import {parseDate} from '@telerik/kendo-intl';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import GridLoading from '../../Loaders/GridLoading';
import { orderBy } from '@progress/kendo-data-query';

class RGainUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
        files:[],
        bulkFiles:[],
        rgains:0,
        bulkRGains:0,
        rgainFiles:[],
        date:'',
        fileDownload: { text: "", path: ""},
        fileSelected: false,
        uploading:false,
        refreshText:'',
        uploadText:'Confirm Upload',
        overwrite:false,
        logFiles:[],
        fileGrid:'rgain',
        gridButtonText:'View Log Files',
        gridText:'Uploaded Realized Gains Files'
    }

    this.getLogFiles = this.getLogFiles.bind(this);
    this.getRGainFiles = this.getRGainFiles.bind(this);
    this.onBeforeUpload = this.onBeforeUpload.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.uploadRGains = this.uploadRGains.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  componentDidMount(){
    this.getRGainFiles();
    this.getLogFiles();
  }

  onDateChange = (e)=>{
    this.setState({date:e.target.value});
  }

  onAdd(e, bulk){
    if(bulk)
      this.setState({bulkFiles:e.newState});
    else
      this.setState({files:e.newState});
  }

  onRemove(e, bulk){
    if(bulk)
      this.setState({bulkFiles:e.newState});
    else
      this.setState({files:e.newState});
  }

  onStatusChange(e, bulk){
    if(!bulk){
      if(e.response.response.code===200){
        e.newState[0].progress=100;
        e.newState[0].status=4;
      }
      else{
        e.newState[0].progress=0;
        e.newState[0].status=0;
        e.newState[0].errorLine = e.response.response.errorLine;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({files:e.newState, rgains:e.response.response.rgains, overwrite:false});
    }
    else{
      if(e.response.response.code===200){
        e.newState[0].progress=100;
        e.newState[0].status=4;
        e.newState[0].bulk=true;
      }
      else{
        e.newState[0].progress=0;
        e.newState[0].status=0;
        e.newState[0].errorFile = e.response.response.file;
        e.newState[0].errorLine = e.response.response.errorLine;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({bulkFiles:e.newState, bulkRGains:e.response.response.rgains, overwrite:false});
    }
  }

  uploadRGains(e, bulk){
    
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var formData = new FormData();

    if(!bulk){
      if(this.state.date===''){
        alert("Please select a date for this realized gain file.");
      }
      else{
        formData.append('company', this.props.user.company);
        formData.append('date', this.state.date);
        formData.append('rgainFile' , this.state.files[0].getRawFile());
        formData.append('overwritePort', this.state.overwrite);

        this.setState({uploading:true, uploadText:'Uploading...'});

        axios.post('api/rgainUpload', formData, {headers}).then(response=>{
          if(response.data.code===200){
            alert("Realized gains have been successfully uploaded.");
            this.getRGainFiles();
            this.getLogFiles();
            this.clearState('single');
          }
          else{
            alert("An error has occurred while uploading. Please try again.");
            this.getRGainFiles();
            this.getLogFiles();
            this.clearState('single');
          }
        }).catch(err=>{
          alert("An error has occurred while uploading. Please try again.");
          this.getRGainFiles();
          this.getLogFiles();
          this.clearState('single');
        });
      }
    }
    else{
      formData.append('bulk', true);
      formData.append('rgainFile' , this.state.bulkFiles[0].getRawFile());
      formData.append('company', this.props.user.company);
      formData.append('overwritePort', this.state.overwrite);

      this.setState({uploading:true, uploadText:'Uploading...'});
      
        axios.post('api/rgainUpload', formData, {headers}).then(response=>{
          if(response.data.code===200){
            alert("Realized gains from all files have been successfully uploaded.");
            this.getRGainFiles();
            this.getLogFiles();
            this.clearState('bulk');
          }
          else{
            alert("An error has occurred while bulk uploading. Please try again.");
            this.getRGainFiles();
            this.getLogFiles();
            this.clearState('bulk');
          }
        }).catch(err=>{
          alert("An error has occurred while bulk uploading. Please try again.");
          this.getRGainFiles();
          this.getLogFiles();
          this.clearState('bulk');
        });
    }
  }

  clearState(type){
    if(type==='bulk'){
      this.setState({
        bulkFiles:[],
        bulkRGains:0,
        uploading:false,
        refreshText:'',
        uploadText:'Confirm Upload',
        overwrite:false
      });
    }
    else{
      this.setState({
        files:[],
        rgains:0,
        date:'',
        uploading:false,
        refreshText:'',
        uploadText:'Confirm Upload',
        overwrite:false
      });
    }
  }

  getRGainFiles(){

    this.setState({refreshText:'Loading...'});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var folder = 'rgains/';
        axios
            .get("api/filetree?company="+this.props.user.company+"&folder="+folder,{headers})
            .then((response) => {
                if(response.code===undefined){
                  var files = response.data;

                  for(let i=0; i<files.length; i++){
                    files[i].parsedUploadDate = this.getDate(files[i].uploadDate);
                    files[i].parsedDate = new Date(files[i].description);
                    var utc = new Date(files[i].parsedDate.getUTCFullYear(), files[i].parsedDate.getUTCMonth(), files[i].parsedDate.getUTCDate());
                    files[i].parsedDate = utc;
                  }

                  this.setState({ rgainFiles: files, refreshText:''});     
                }
                else{
                    this.setState({refreshText:''});
                    alert("Could not pull files from cloud.");
                }
            })
            .catch((e) => {
              this.setState({refreshText:''});
              console.error(e);
            });
  }

  getLogFiles(){
    this.setState({refreshText:'Loading...'});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var folder = 'rgainlogs/';
        axios
            .get("api/filetree?company="+this.props.user.company+"&folder="+folder,{headers})
            .then((response) => {
                if(response.code===undefined){
                  var files = response.data;

                  for(let i=0; i<files.length; i++){
                    files[i].parsedUploadDate = this.getDate(files[i].uploadDate);
                  }

                  this.setState({ logFiles: files, refreshText:''});     
                }
                else{
                    this.setState({refreshText:''});
                    alert("Could not pull files from cloud.");
                }
            })
            .catch((e) => {
              this.setState({refreshText:''});
              console.error(e);
            });
  }

  getDate(sqlDate){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    return d;
  }

  onBeforeUpload(e, bulk){
    e.additionalData.company=this.props.user.company;
    e.additionalData.bulk=bulk
  }

  download() {  
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {"files":[], "company":this.props.user.company};

    if(Array.isArray(this.state.fileDownload)){
        if(this.state.fileDownload.length>1){
            for(let i=0; i<this.state.fileDownload.length; i++){
                payload.files.push({
                    "path": this.state.fileDownload[i].path,
                    "name":this.state.fileDownload[i].text
                });
            }
                axios
                    .post("api/downloadzip", payload, {headers})
                    .then((response) => {
                        window.open("api/downloadzip?company="+this.props.user.company+"&user="+this.props.user.user);
                    })
                    .catch((e) => {
                    });
        }
        else{
            axios
            .get("api/download?company="+this.props.user.company+"&path="+this.state.fileDownload[0].path+"&name="+this.state.fileDownload[0].text+"&type=download", {headers})
            .then((response) => {
                window.open(response.data);
            })
            .catch((e) => {
                return false;
            });
        }
    }
    else{
        axios
            .get("api/download?company="+this.props.user.company+"&path="+this.state.fileDownload.path+"&name="+this.state.fileDownload.text+"&type=download", {headers})
            .then((response) => {
                window.open(response.data);
            })
            .catch((e) => {
                return false;
            });
    }

  }

  deleteFiles() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                "files": this.state.fileDownload,
                "company":this.props.user.company,
                "isRGain":this.state.fileGrid==='rgain'
            }
            axios
                .post("api/deleteFile", payload, {headers})
                .then((response) => {
                    if (response.data.code === 400) {
                        alert("Error deleting file.");
                    }
                    else if(response.data.code===401){
                      alert("Error realized gain files were deleted but realized gains were not removed from database");
                    }
                    this.getRGainFiles();
                    this.getLogFiles();
                    this.setState({fileSelected:false});
                    this.setState({fileDownload:{text:"", path:""}})

                })
                .catch((e) => {
                    this.getRGainFiles();
                    this.getLogFiles();
                    alert("Error deleting file.");
                });
  }

  headerSelectionChange(event, gFiles){
    const checked = event.syntheticEvent.target.checked;
    gFiles.forEach(item => item.selected = checked);
    var selectedFiles = [];
    for(let i=0; i<gFiles.length; i++){
        if(gFiles[i].selected){
            selectedFiles.push(gFiles[i]);
        }
    }
    this.setState({fileDownload:selectedFiles});
    if(selectedFiles.length>0){
        this.setState({fileSelected: true});
    }
    else{
        this.setState({fileSelected: false});
    }
    this.forceUpdate();
  }

  selectionChange(event, gFiles){
    event.dataItem.selected = !event.dataItem.selected;
    var selectedFiles = [];
    for(let i=0; i<gFiles.length; i++){
        if(gFiles[i].path === event.dataItem.path){
            gFiles[i].selected=event.dataItem.selected;
        }
        if(gFiles[i].selected){
            selectedFiles.push(gFiles[i]);
        }
    }
    this.setState({fileDownload:selectedFiles});
    if(selectedFiles.length>0){
        this.setState({fileSelected: true});
    }
    else{
        this.setState({fileSelected: false});
    }
    this.forceUpdate();
  }

  renderFile = (props)=>{
    const { files } = props;

      return (
      <ul>
          {
              files.map(file =>{
                return file.error ?
                <div>
                  <div style={{color:'red'}}key={file.name}>
                    <div>{file.name}</div>
                    <div>Error on line: {file.errorLine}</div>
                    <div>Error: {file.error}</div>
                  </div>
                  <div style={{color:'green'}}>{this.state.rgains} realized gains found for upload.</div>
                  <div><label>Date:</label><DatePicker value={this.state.date} onChange={this.onDateChange} disabled={this.state.uploading}/></div>
                  <div><label>Update Existing Data?</label><input type="checkbox" name='overwrite' onChange={(e)=>this.setState({overwrite:!this.state.overwrite})} disabled={this.state.uploading} checked={this.state.overwrite}/></div>
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.uploadRGains()}>{this.state.uploadText}</Button>
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.clearState('single')}>Cancel</Button>
                </div>
                :
                <div>
                  <div style={{color:'green'}}key={file.name}>
                    <div>{file.name}</div>
                    <div>{this.state.rgains} realized gains found for upload.</div>
                  </div>
                  <div><label>Date:</label><DatePicker value={this.state.date} onChange={this.onDateChange} disabled={this.state.uploading}/></div>
                  <div><label>Update Existing Data?</label><input type="checkbox" name='overwrite' onChange={(e)=>this.setState({overwrite:!this.state.overwrite})} disabled={this.state.uploading} checked={this.state.overwrite}/></div>                  
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.uploadRGains()}>{this.state.uploadText}</Button>
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.clearState('single')}>Cancel</Button>
                </div>

              })          
          }
      </ul>);
  }

  renderBulkFile = (props)=>{
    const { files } = props;

      return (
      <ul>
          {
              files.map(file =>{
                return file.error ?
                <div>
                  <div style={{color:'red'}}key={file.name}>
                    <div>{file.name}</div>
                    {file.errorFile && <div>Error in {file.errorFile}</div>}
                    <div>First Error on line: {file.errorLine}</div>
                    <div>Error: {file.error}</div>
                  </div>
                  <div style={{color:'green'} }key={file.name}>{this.state.bulkRGains} realized gains found in all files</div>
                  <div><label>Update Existing Data?</label><input type="checkbox" name='overwrite' onChange={(e)=>this.setState({overwrite:!this.state.overwrite})} disabled={this.state.uploading} checked={this.state.overwrite}/></div>                  
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.uploadRGains(e, 'bulk')}>{this.state.uploadText}</Button>
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.clearState('bulk')}>Cancel</Button>      
                </div>
                :
                <div>
                  <div style={{color:'green'}}key={file.name}>
                    <div>{file.name}</div>
                    <div>{this.state.bulkRGains} realized gains found for upload.</div>
                  </div>
                  <div><label>Update Existing Data?</label><input type="checkbox" name='overwrite' onChange={(e)=>this.setState({overwrite:!this.state.overwrite})} disabled={this.state.uploading} checked={this.state.overwrite}/></div>                  
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.uploadRGains(e, 'bulk')}>{this.state.uploadText}</Button>
                  <Button style={{textAlign:'right', marginLeft:'10px'}} disabled={this.state.uploading} onClick={e=>this.clearState('bulk')}>Cancel</Button>
                </div>

              })          
          }
      </ul>);
  }

  switchFileGrid = (e)=>{
    if(this.state.fileGrid==='log'){
      this.setState({fileGrid:'rgain', gridButtonText:'View Log Files', gridText:'Uploaded Realized Gains Files'});
    }
    else{
      this.setState({fileGrid:'log', gridButtonText:'View Realized Gains Files', gridText:'Log Files'});
    }
  }

  render() {
    return(
        <div>
            
            <span className='posList'>
              {this.state.fileGrid==='rgain' && <Grid style={{height:'600px'}} data={orderBy(this.state.rgainFiles, [{field:"parsedDate", dir:"desc"}])}
                    selectedField="selected"
                    onSelectionChange={(e) => this.selectionChange(e, this.state.rgainFiles)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.rgainFiles)}>
                <GridToolbar>
                  <div>Uploaded Realized Gains Files</div>                  
                  <Button className='docButton' icon='download' disabled={!this.state.fileSelected} onClick={e=>this.download()}>Download</Button>
                  <Button className='docButton' icon='delete' disabled={!this.state.fileSelected} onClick={e=>this.deleteFiles()}>Delete</Button>
                  <Button className='docButton' onClick={this.switchFileGrid}>{this.state.gridButtonText}</Button>
                </GridToolbar>
                <GridColumn headerClassName='gridHeader' field="selected"/>
                <GridColumn headerClassName='gridHeader' field="parsedDate" title="Date" format="{0:MM/dd/yyyy}"/>
                <GridColumn 
                    field="text" 
                    title="File Name"     
                />
                <GridColumn headerClassName='gridHeader' field="uploadedBy" title="Uploaded By:"/>
                <GridColumn headerClassName='gridHeader' field="parsedUploadDate" filter="date" format="{0:g}" title="Upload Date"/>
              </Grid>}
              {this.state.fileGrid==='log' && <Grid style={{height:'600px'}} data={orderBy(this.state.logFiles, [{field:"parsedUploadDate", dir:"desc"}])}
                    selectedField="selected"
                    onSelectionChange={(e) => this.selectionChange(e, this.state.logFiles)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.logFiles)}>
                <GridToolbar>
                  <div>{this.state.gridText}</div>                  
                  <Button className='docButton' icon='download' disabled={!this.state.fileSelected} onClick={e=>this.download()}>Download</Button>
                  <Button className='docButton' icon='delete' disabled={!this.state.fileSelected} onClick={e=>this.deleteFiles()}>Delete</Button>
                  <Button className='docButton' onClick={this.switchFileGrid}>{this.state.gridButtonText}</Button>
                </GridToolbar>
                <GridColumn headerClassName='gridHeader' field="selected"/>
                <GridColumn headerClassName='gridHeader' field="text" title="File Name"/>
                <GridColumn headerClassName='gridHeader' field="uploadedBy" title="Uploaded By:"/>
                <GridColumn headerClassName='gridHeader' field="parsedUploadDate" filter="date" format="{0:g}" title="Upload Date"/>
              </Grid>}
          </span>
          {this.state.refreshText && <GridLoading/>}
        </div>
    );
  }

  render_old() {
    return(
        <div>
            <table className='fullWidth'>
                <tbody>
                    <tr>
                        <td>
                          <h3>Upload Single Tab-Delimited Realized Gains File:</h3>
                          <div>Select a file to upload and select the date of the realized gains in the file. <br/> If realized gains already exist for a date they will be overwritten.</div>
                          <div>If "Update" is checked, data will be updated only for the accounts in the uploaded file. This will not impact any previously <br/> uploaded files, just the data that is displayed.</div>                         
                          <div><strong>Note: </strong> Upload will attempt to parse values with quotes for number fields <br/> and ?'s will be treated as null.  If you upload a month-end file it will overwrite all data for that month.</div>
                        </td>
                        <td><Upload autoUpload={true} 
                                    multiple={false} 
                                    files={this.state.files}
                                    listItemUI={this.renderFile}
                                    onAdd={e=>this.onAdd(e,false)}
                                    onRemove={e=>this.onRemove(e,false)}
                                    onStatusChange={e=>this.onStatusChange(e,false)}
                                    onBeforeUpload={e=>this.onBeforeUpload(e,false)}
                                    saveUrl={'api/parseRGainFile?company='+this.props.user.company}
                                    saveHeaders={{'authorization': 'Bearer '.concat(this.props.idToken)}}/></td>
                    </tr>
                    <tr>
                      <td>
                        <h3>Bulk-Upload Zip of Tab-Delimited Realized Gains Files:</h3>
                        <div>Select a zip-file with individual tab-delimited files (yyyymmdd.txt) for each date. <br/>If realized gains already exist for a specific date they will be overwritten</div>
                        <div>If "Update" is checked, data will be updated only for the accounts in the uploaded file. This will not impact any previously <br/> uploaded files, just the data that is displayed.</div>                          
                        <div><strong>Note: </strong> Upload will attempt to parse values with quotes for number fields <br/> and ?'s will be treated as null.  If you upload a month-end file it will overwrite all data for that month.</div>
                      </td>
                      <td>
                        <Upload autoUpload={true} 
                                multiple={false} 
                                files={this.state.bulkFiles}
                                listItemUI={this.renderBulkFile}
                                onAdd={e=>this.onAdd(e,true)}
                                onRemove={e=>this.onRemove(e,true)}
                                onStatusChange={e=>this.onStatusChange(e,true)}
                                onBeforeUpload={e=>this.onBeforeUpload(e,true)}
                                saveUrl={'api/parseRGainFile?company='+this.props.user.company}
                                saveHeaders={{'authorization': 'Bearer '.concat(this.props.idToken)}}/>
                      </td>
                    </tr>
                </tbody>
            </table>
            <span className='posList'>
              {this.state.fileGrid==='rgain' && <Grid data={orderBy(this.state.rgainFiles, [{field:"parsedDate", dir:"desc"}])}
                    selectedField="selected"
                    onSelectionChange={(e) => this.selectionChange(e, this.state.rgainFiles)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.rgainFiles)}>
                <GridToolbar>
                  <div>Uploaded Realized Gains Files</div>                  
                  <Button className='docButton' icon='download' disabled={!this.state.fileSelected} onClick={e=>this.download()}>Download</Button>
                  <Button className='docButton' icon='delete' disabled={!this.state.fileSelected} onClick={e=>this.deleteFiles()}>Delete</Button>
                  <Button className='docButton' onClick={this.switchFileGrid}>{this.state.gridButtonText}</Button>
                </GridToolbar>
                <GridColumn headerClassName='gridHeader' field="selected"/>
                <GridColumn headerClassName='gridHeader' field="parsedDate" title="Date" format="{0:MM/dd/yyyy}"/>
                <GridColumn 
                    field="text" 
                    title="File Name"     
                />
                <GridColumn headerClassName='gridHeader' field="uploadedBy" title="Uploaded By:"/>
                <GridColumn headerClassName='gridHeader' field="parsedUploadDate" filter="date" format="{0:g}" title="Upload Date"/>
              </Grid>}
              {this.state.fileGrid==='log' && <Grid data={orderBy(this.state.logFiles, [{field:"parsedUploadDate", dir:"desc"}])}
                    selectedField="selected"
                    onSelectionChange={(e) => this.selectionChange(e, this.state.logFiles)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.logFiles)}>
                <GridToolbar>
                  <div>{this.state.gridText}</div>                  
                  <Button className='docButton' icon='download' disabled={!this.state.fileSelected} onClick={e=>this.download()}>Download</Button>
                  <Button className='docButton' icon='delete' disabled={!this.state.fileSelected} onClick={e=>this.deleteFiles()}>Delete</Button>
                  <Button className='docButton' onClick={this.switchFileGrid}>{this.state.gridButtonText}</Button>
                </GridToolbar>
                <GridColumn headerClassName='gridHeader' field="selected"/>
                <GridColumn headerClassName='gridHeader' field="text" title="File Name"/>
                <GridColumn headerClassName='gridHeader' field="uploadedBy" title="Uploaded By:"/>
                <GridColumn headerClassName='gridHeader' field="parsedUploadDate" filter="date" format="{0:g}" title="Upload Date"/>
              </Grid>}
          </span>
          {this.state.refreshText && <GridLoading/>}
        </div>
    );
  }

}

export default RGainUpload;