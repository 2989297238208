import React from "react";
import { Switch } from '@progress/kendo-react-inputs';

const InputBoolean = ({ name, label, defaultChecked, valid, _handleChange }) =>

    (
        <div>
            <label>{label}: </label>
            <br></br>
            <Switch
                name={name}
                defaultChecked={defaultChecked}
                valid={valid}
                onChange={_handleChange}
            />
            <br></br>
        </div>
    );
export default InputBoolean;