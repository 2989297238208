import React, { Component } from "react";
import MarketValueGraph from '../components/MarketValueGraph';
import axios from 'axios';

class MarketValueDashbard extends Component {

  constructor(props) {
    super(props);

    this.state = {
        graph:this.props.compSettings.mvChart || 'column',
        dates:[],
        selectedDate:'',
        styleSettings: {
          chartShowAsOf: null,
        }
    }

  }

  componentDidMount(){
    this.getStyleSettings();
    this.getHoldDates();
  }

  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'chartShowAsOf'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      this.setState({styleSettings: response.data.styleSettings})
    })
    .catch(err => {
        console.error(err);
    })
  }

  getHoldDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      email:this.props.user.user,
      port:this.props.portfolio.account,
      household:this.props.user.defaultAcct.household
    }    
    axios.post('api/posDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }


  render() {
    return(
        <div className='marketValueDashboard'>
            <label>Graph: </label><select value={this.state.graph} onChange={(e)=>this.setState({graph:e.target.value})} style={{marginRight:'10px'}}>
              <option value='column'>Column</option><option value='line'>Line</option>
              <option value='area'>Area</option></select>             
            {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value})} style={{marginRight:'10px'}}>
            {this.state.dates.map((date, i)=>(
              <option key={i} value={date.fDate}>{date.fDate}</option>
            ))}
            </select></>}
            {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
            <MarketValueGraph user={this.props.user} compSettings={this.props.compSettings} date={this.state.selectedDate} graph={this.state.graph} stack='a' portfolio={this.props.portfolio} idToken={this.props.idToken}/>
        </div>
    );
  }

}

export default MarketValueDashbard;