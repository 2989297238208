import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
//const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExcelExport extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ExcelFile filename={'ExcelExport'} hideElement={true}>
                <ExcelSheet  dataSet={this.props.excelDataSet} name="Organization" />
            </ExcelFile>
        );
    }
}

export default ExcelExport