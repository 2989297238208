import React, { Component } from "react";
import {Chart, ChartLegend, ChartTooltip, ChartSeries, ChartSeriesItem, ChartSeriesLabels} from '@progress/kendo-react-charts';
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import { process, aggregateBy } from '@progress/kendo-data-query';
import axios from 'axios';
import {formatNumber} from '@telerik/kendo-intl';
import 'hammerjs';
import GridLoading from '../../Loaders/GridLoading';

class AssetAllocationPie extends Component {

  constructor(props) {
    super(props);
    
    this.groupingsStr = props.styleSettings.acctOverviewClassificationGrouping != null && props.styleSettings.acctOverviewClassificationGrouping !== '' ? props.styleSettings.acctOverviewClassificationGrouping : 'assetAllocation,sector,securityName';
    this.groupingsArray = this.groupingsStr.split(',');

    this.state = {
      data:[],
      type: null, //this.nextGrouping(),
      assetAllocation:'',
      sector:'',
      labelAssetAllocation:this.props.labelAssetAllocation,
      labelSector:this.props.labelSector,
      initialLoad:true,
      portfolio:this.props.portfolio,
      household:this.props.household,
      animation:false,
      loading:true,
      chartData: [],
      date:this.props.date,
      //dataState: {  sort:[{field:'securityName',dir:'asc'}]/*, sort:[{field:'Name1',dir:'asc'},{field:'Name2',dir:'asc'},{field:'Name3',dir:'asc'}]*/ },
      dataState:{ sort:[{field:'assetAllocation',dir:'asc'}] },
      //dataState:{group:[{field:'assetAllocation', aggregates:[{field:'marketValue', aggregate:'sum'},]}]},
    }


  }

  componentDidMount = () => {
    // this.getStyleSettings(() => {
    //   this.getAssetAllocationData();
    // })
    this.getAssetAllocationData(null, null, this.nextGrouping());
  }

  componentDidUpdate = () => {
      if((this.props.portfolio!==this.state.portfolio || this.props.date!==this.state.date) && !this.state.initialLoad){
      this.setState({portfolio:this.props.portfolio, date: this.props.date}, ()=>{this.getAssetAllocationData(null,null,this.nextGrouping())});
    }
  }

  nextGrouping = (grouping) => {
    const index = this.groupingsArray.findIndex(item => item === grouping);
    if (index === -1) {
        return this.groupingsArray[0];
    } else if (index === this.groupingsArray.length - 1) {
        return this.groupingsArray[0];
    } else {
        return this.groupingsArray[index + 1];
    }
  }

  getAssetAllocationData = (assetAllocation, sector, nextType) => {
    var currentType = null
    if (assetAllocation){
      currentType = 'assetAllocation'
    }
    else if (sector){
      currentType = 'sector'
    }
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    if(!assetAllocation)
      assetAllocation = ((this.groupingsStr === 'sector' || (this.groupingsStr === 'sector,securityName' && sector == null) || this.groupingsStr === 'securityName') 
        && (nextType === 'sector' || nextType === 'securityName')) ? 'All' : this.state.assetAllocation;
    if(!sector)
    sector = ((this.groupingsStr === 'assetAllocation,securityName' || this.groupingsStr === 'securityName') && nextType === 'securityName') ? 'All' : this.state.sector;
    var port = this.props.portfolio.account
    if (this.props.portfolio.consolidated) {
        port = this.props.household
    }
    if (nextType === 'assetAllocation') {
        assetAllocation = '';
        sector = '';
    }
    
    axios.get('api/assetAllocation',
    {
      method: 'GET',
      params: {
        company: this.props.user.company,
        date: this.props.date,
        assetAllocation: assetAllocation,//.replace("&","%26"),
        sector: sector,//.replace("&", "%26"),
        port: port,
        consolidated: this.props.portfolio.consolidated,
        includeAccruedInterest: true//this.state.styleSettings ? this.state.styleSettings.includeAccruedInterest : false
      },
      headers: headers
    }).then(response=>{
      if(response.data.code===200){
        var filteredData = response.data.data.filter(item => { return (this.groupingsStr !== 'sector,securityName') || (this.groupingsStr === 'sector,securityName' && sector === '') || (this.groupingsStr === 'sector,securityName' && sector !== '' && item.sector === sector)});
        var resData = [...filteredData];
        var dataStateCopy = this.state.dataState
        dataStateCopy.sort = [{field:nextType,dir:'asc'}]
        this.setState({dataState: dataStateCopy})
        var chartData = []
        var origColorOrder = []
        resData.forEach(d => {
          origColorOrder.push(d.color)
        })
        if (resData.length > 0){
          resData = process(resData, dataStateCopy)
          resData.data.forEach((d, i) => {
            d.color = origColorOrder[i]
          })
          chartData = [...resData.data]
          //totalMV = response.data.data[0].totalMV
          var totals = aggregateBy(resData.data, [{field:'marketValue', aggregate:'sum'}, {field:'percentage', aggregate:'sum'}]);
          resData.data.push({
            assetAllocation:'TOTALS',
            sector: "TOTALS",
            securityName: "TOTALS",
            securitySymbol: "TOTALS",
            marketValue:totals.marketValue.sum, //percentage:totals.percentage.sum
          });
        }

        if(this.props.onAssetSecChange){
          this.props.onAssetSecChange(assetAllocation, sector);
          this.setState({labelAssetAllocation: assetAllocation, labelSector: sector, currentType: currentType})
        }
          

        if (this.groupingsArray.findIndex(item => item === 'sector') === this.groupingsArray.length - 1 && nextType === 'sector') {
            assetAllocation = '';
            sector = '';
        }
        
        if (this.groupingsArray.findIndex(item => item === 'securityName') === this.groupingsArray.length - 1 && nextType === 'securityName') {
            assetAllocation = '';
            sector = '';
        }
       

        if(this.state.initialLoad){
          this.setState({data:filteredData, result: resData, chartData: chartData, loading:false, initialLoad:false, animation:true, origColorOrder: origColorOrder });
        }
          if(nextType==='assetAllocation'/* && assetAllocation*/){
            this.setState({data:filteredData, result: resData, chartData: chartData, loading:false, animation:true, origColorOrder: origColorOrder, 
                sector:sector, assetAllocation:assetAllocation, type: nextType != null ? nextType : this.state.nextType });
          }
          else if(nextType==='sector'){
            this.setState({data:filteredData, result: resData, chartData: chartData, loading:false, animation:true, origColorOrder: origColorOrder, 
                 assetAllocation:assetAllocation, sector:sector, type: nextType != null ? nextType : this.state.nextType });
          }
          else{
            this.setState({data:filteredData, result: resData, chartData: chartData, loading:false, animation:true, origColorOrder: origColorOrder, 
                 assetAllocation:assetAllocation, sector: sector, type: nextType != null ? nextType : this.state.nextType });
          }
  
      }
      else{
        this.setState({loading:false, type: nextType});
        alert('Could not retrieve asset class data.');
      }
    }).catch(err=>{
      this.setState({loading:false, type: nextType});
      console.log(err);
    });
  }

  getStyleSettings = () => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = ['includeAccruedInterest']

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      this.setState({styleSettings: response.data.styleSettings})
    })
    .catch(err => {
        console.error(err);
    })
  }


  changeDataState = (e)=>{

    var newData = this.state.data
    if (newData[newData.length - 1].assetAllocation === "TOTALS" ||
      newData[newData.length - 1].sector === "TOTALS" ||
      newData[newData.length - 1].securityName === "TOTALS" ||
      newData[newData.length - 1].securitySymbol === "TOTALS"
    ){
      newData.pop()
    }
    newData = process(this.state.data, e.data);
    if (newData.data.length === this.state.origColorOrder.length){
      newData.data.forEach((d, i) => {
        d.color = this.state.origColorOrder[i]
      })
    }
    var chartData = newData.data
    var totals = aggregateBy(newData.data, [{field:'marketValue', aggregate:'sum'}, {field:'percentage', aggregate:'sum'}]);
    newData.data.push({
      assetAllocation:'TOTALS',
      sector: "TOTALS",
      securityName: "TOTALS",
      securitySymbol: "TOTALS",
      marketValue:totals.marketValue.sum, //percentage:totals.percentage.sum
    });

    this.setState({dataState:e.data, result:newData, chartData: chartData})
  }

  renderTooltip = ({point})=>{
    if(point!==undefined){
      return (
          <>
            <div><strong>{point.category}</strong></div>
            <div>Market Value: {formatNumber(point.dataItem.marketValue, "c", "en")}</div>
            <div>Percentage: {formatNumber(point.dataItem.percentage, "p2")}</div>
          </>);
    }
    else{
      return(<div></div>);
    }
  }

  onSeriesClick = (e)=>{
    if (this.groupingsArray.length === 1) return;
    if(this.state.type==='assetAllocation'){
        this.getAssetAllocationData(e.dataItem.assetAllocation,null,this.nextGrouping('assetAllocation'))
        // this.setState({ 
        //     animation: false,
        //     type: this.nextGrouping('assetAllocation')
        // }, () => this.getAssetAllocationData(e.dataItem.assetAllocation,null));
      }
    else if(this.state.type==='sector'){
        this.getAssetAllocationData(null, e.dataItem.sector,this.nextGrouping('sector'))
        // this.setState({
        //     animation: false,
        //     type:
        // }, () =>
        // );
        }
    else{
        this.getAssetAllocationData(null,null,this.nextGrouping('securityName'))
        // this.setState({
        //     ,
        //     assetAllocation: '',
        //     sector: '',
        //     animation: false,
        // }, () =>;
    }
  }

  onRowClick = (e)=>{
    if (this.groupingsArray.length === 1) return;
    if(this.state.type==='assetAllocation'){
        // this.setState({ 
        //     type: 
        // }, () => 
        // );
        this.getAssetAllocationData(e.dataItem.assetAllocation,null,this.nextGrouping('assetAllocation'))
      }
    else if(this.state.type==='sector'){
        this.getAssetAllocationData(null, e.dataItem.sector, this.nextGrouping('sector'))
        // this.setState({
        //     type
        // }, () => );
        }
    else{
        this.getAssetAllocationData(null,null,this.nextGrouping('securityName'))
        // this.setState({
        //     type:,
        //     assetAllocation: '',
        //     sector: ''
        // }, () => );
    }
  }

  formatCurrency = (val) => {
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    
    if(val<0)
        formatted = '('+formatted+')';

    return formatted;
  }

  formatPercent = (val) => {
      return (val*100).toFixed(2)+"%";
  }

  getLabel = (e)=>{
    var label = e.category+' '+Math.round(e.dataItem.percentage*100)+'%';
    return label;
  }

  getLabelPadding = () => {
    if(this.props.labels)
      return 50;
    else
      return 0;
  }

  setExplode = (dataItem) => {
    var arr = this.state.data.slice();
    var match = false;

    for(let i=0; i<arr.length; i++){

      match=true;

      for(var property in dataItem){
        if(dataItem[property]!==arr[i][property])
          match=false;
      }

      if(match)
        arr[i].explode=true;
      else
        arr[i].explode=false;
    }

    this.setState({data:arr, animation:false});
  }

  clearExplode = () => {
    var arr = this.state.data.slice();

    for(let i=0; i<arr.length; i++){
      arr[i].explode=false;
    }

    this.setState({data:arr, animation:false});
  }

  cellRender = (td, props) => {
    var style = {}
    if(td !==null && (props.field === 'marketValue' || props.field==='percentage') && props.dataItem[this.state.type] !=='TOTALS'){
      return <td {...td.props} style={{textAlign:'right'}}/>;
    }
    else if(props.dataItem[this.state.type] ==='TOTALS'){
      style = {fontWeight:'bold'};
      if(props.field===this.state.type){
        return(
          <td {...td.props} style={style}>Totals:</td>
        );
      }
      else if(props.field==='marketValue'){
        style = {fontWeight:'bold',textAlign:'right'};
        return(
          <td {...td.props} style={style}>{this.formatCurrency(props.dataItem.marketValue)}</td>
        );
      }
      else if(props.field==='percentage'){
        style = {fontWeight:'bold',textAlign:'right'};
        return(
          <td {...td.props} style={style}>{formatNumber(1,'p2')}</td>
        );
      }
      else{
        style = {fontWeight:'bold', textAlign:'right'};
      }
    }
    else
      return td;
  }

  headerCellRender = (th, props)=>{
    if(th !=null && (props.field === 'marketValue' || props.field==='percentage'))
      return <div {...th.props} style={{textAlign:'right'}}/>;
    else
      return th;
  }

  getTitle = () => {
    if(this.state.type==='assetAllocation')
      return "Asset Class";
    else if(this.state.type==='sector')
      return "Sector";
    else 
      return "Security Name";
  }

  render() {

    var tableData = this.state.data;

    return(
        <div className='assetAllocationPie'>
          <table className='fullWidth'>
            <tbody>
              <tr className='fullWidth'>
                <td>
                  <div style={{height:'30vh'}}>
                  <Chart style={{width:'100%', height:'100%'}} onLegendItemClick={e=>{}} onSeriesClick={this.onSeriesClick} transitions={this.state.animation}>
                  <ChartLegend visible={this.props.legend} position={this.props.legendPosition}/>
                  <ChartTooltip render={this.renderTooltip}/>
                      <ChartSeries>
                          <ChartSeriesItem padding={this.getLabelPadding()} colorField='color' explodeField='explode'  border={{color:this.props.borderColor, width:this.props.borderWidth}} type="pie" overlay={{gradient:this.props.gradient}} data={this.state.chartData} field="percentage" categoryField={this.state.type}>
                            <ChartSeriesLabels content={this.getLabel} visible={this.props.labels}/>
                          </ChartSeriesItem>
                      </ChartSeries>
                  </Chart>
                  </div>
                </td>
              </tr>
              {this.props.table &&
                <tr>
                  <td>
                    <table>
                      <tbody className='alternateRows'>
                        <tr>
                          <td className='heading'>Color</td>
                          <td className='heading'>{this.state.type}</td>
                          <td className='heading'>Market Value</td>
                          <td className='heading'>% Equity</td>
                        </tr>
                        {tableData.map((point, i)=>(
                          <tr key={i}>
                            <td style={{backgroundColor:point.color, margin:'5px'}}></td>
                            <td>{point[this.state.type]}</td>
                            <td>{this.formatCurrency(point.marketValue)}</td>
                            <td>{this.formatPercent(point.percentage)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr> 
              }
              {this.props.grid &&
              <tr>
                <td colSpan='3'>
                  <Grid data={this.state.result} 
                        sortable={true}
                        resizable={true}
                        style={{maxHeight:'46vh'}} 
                        cellRender={this.cellRender}
                        headerCellRender={this.headerCellRender}
                        onRowClick={this.onRowClick}
                        onDataStateChange={this.changeDataState}
                        className='assetAllGrid'
                        {...this.state.dataState}
                    >
                    <GridToolbar>
                      {this.state.currentType === 'assetAllocation' && this.state.labelAssetAllocation && this.state.labelAssetAllocation!=='' && <div style={{textAlign:'center'}}><strong>{this.state.labelAssetAllocation}</strong></div>}
                      {this.state.currentType === 'sector' && this.state.labelSector && this.state.labelSector!=='' && <div style={{textAlign:'center'}}><strong>{this.state.labelSector}</strong></div>}
                      {((this.state.currentType !== 'sector' && this.state.currentType !== 'assetAllocation') || (this.state.currentType === 'sector' && !this.state.labelSector) || (this.state.currentType === 'assetAllocation' && !this.state.labelAssetAllocation) || (this.state.currentType === 'sector' && this.state.labelSector==='') || (this.state.currentType === 'assetAllocation' && this.state.labelAssetAllocation==='')) && <div style={{textAlign:'center'}}><strong>Total Portfolio</strong></div>}
                    </GridToolbar>
                    <GridNoRecords>
                      {this.state.loading && 'Loading...'}
                      {!this.state.loading && 'No records available'}
                    </GridNoRecords>
                    <GridColumn headerClassName='gridHeader' width={40} 
                      cell={(props) => 
                            <td style={{backgroundColor:props.dataItem.color, border:'2px solid rgba(255, 255, 255, .5)'}}
                            onMouseEnter={(e)=>{this.setExplode(props.dataItem);}}
                            onMouseLeave={(e)=>{this.clearExplode()}}></td>}/>
                    <GridColumn headerClassName='gridHeader' width={275} field={this.state.type} title={this.getTitle()}/>
                    <GridColumn headerClassName='gridHeader' width={150} format='{0:c}' field='marketValue' title='Market Value'/>
                    <GridColumn headerClassName='gridHeader' width={108} format='{0:p2}' field='percentage' title='% of Assets'/>
                  </Grid>
                </td>
              </tr>               
              }              
            </tbody>
          </table>
          {this.state.loading && <GridLoading gridClass='assetAllGrid'/>}
        </div>
    );
  }

}

export default AssetAllocationPie;