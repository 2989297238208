import React, { Component } from "react";
import GroupAppraisal from '../components/GroupAppraisal';

class GroupAppraisalDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }


  render() {
    return(
        <div className='groupAppraisalDashboard'>
            <GroupAppraisal user={this.props.user} compSettings={this.props.compSettings} portfolios={this.props.portfolios} portfolio={this.props.portfolio} idToken={this.props.idToken}/>
        </div>
    );
  }

}

export default GroupAppraisalDashboard;