import React, { Component } from "react";
import axios from 'axios';
import {parseDate} from '@telerik/kendo-intl';
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import GridLoading from '../../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class PerformanceHistory extends Component {

  constructor(props) {
    super(props);

    var filterBench = {filter:{logic:"and", filters:[{field:"indexName", operator:"eq", value:null}]}};

    if(props.styleSettings.showPerfBench)
      filterBench = {};

    this.state = {
        data:[],
        result:[],
        dataState:filterBench,
        portfolio:this.props.portfolio,
        perfType:this.props.perfType,
        returnType:this.props.returnType,
        loading:false,
        columns:[],
        date:this.props.date,
        uniqueAccounts: []
    }

    this.getDate = this.getDate.bind(this);
    this.getPerfHistory = this.getPerfHistory.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
  }

  componentDidMount(){
    this.getPerfHistory(()=>{
      this.setMinGridWidth();
    });
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio || this.props.date!==this.state.date || this.props.perfType!==this.state.perfType || this.props.returnType!==this.state.returnType){
      this.setState({portfolio:this.props.portfolio, date:this.props.date, perfType:this.props.perfType, returnType:this.props.returnType}, ()=>{this.getPerfHistory(()=>{
        this.setMinGridWidth();
      })});
    }
  }

  setMinGridWidth = ()=>{
    this.grid = document.querySelector('.k-grid');
          window.addEventListener('resize', this.handleResize);
          this.minGridWidth = 0;
          this.state.columns.map(item => this.minGridWidth += item.minWidth);
          if(this.state.dataState.group)
            this.minGridWidth += 32*this.state.dataState.group.length;
          this.setState({
              gridCurrent: this.grid.offsetWidth,
              setMinWidth: this.grid.offsetWidth < this.minGridWidth
      });
  }

  handleResize = () => {
    if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
        this.setState({
            setMinWidth: true
        });
    } else if (this.grid.offsetWidth > this.minGridWidth) {
        this.setState({
            gridCurrent: this.grid.offsetWidth,
            setMinWidth: false
        });
    }
  }

  setWidth = (minWidth) => {
    let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
    if(width >= COLUMN_MIN)
      width = width-ADJUST_PADDING;
    return width;
  }

  getDate(sqlDate, isUTC){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if(d){
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getPerfHistory(cb){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var port = this.state.portfolio.account;
    if(this.state.portfolio.consolidated)
      port = '';

    var unmapped='';
    if(this.props.user.unmapped)
      unmapped = 'true';
    
    var irr = false;
    if(this.state.returnType==='irr')
      irr = true;
    
    var payload = {
      company:this.props.user.company,
      date:this.state.date,
      port:port,
      household:this.props.user.defaultAcct.household,
      user:this.props.user.user,
      perfType:this.state.perfType,
      irr:irr,
      unmapped:unmapped
    }
    axios.post('api/perfHistory', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0){
            for(let i=0; i<response.data.data.length; i++){
              response.data.data[i].perfStDate = this.getDate(response.data.data[i].perfStDate, true);
            }
            var columns = this.getGridColumns(response.data.data);
            var uniqueAccounts = []
            response.data.data.forEach(i => {
              if(uniqueAccounts.indexOf(i.accountPortfolio) === -1) {
                if (i.returns3Month ||
                  i.returns3Year ||
                  i.returns5Year ||
                  i.returns6Month ||
                  i.returns7Year ||
                  i.returns7Year ||
                  i.returns10year ||
                  i.returns15year ||
                  i.returns20year ||
                  i.returns25year ||
                  i.returnsPrevQtr ||
                  i.returnsPrevYear ||
                  i.returnsQTD ||
                  i.returnsYear ||
                  i.twrITD ||
                  i.twrMTD ||
                  i.twrYTD){
                    uniqueAccounts.push(i.accountPortfolio);
                  }
              }
            })
            this.setState({data:response.data.data, loading:false, columns:columns, result:process(response.data.data, this.state.dataState), uniqueAccounts: uniqueAccounts},()=>{
              if(cb)
                cb();
            });
          }
      }
      else
      {this.setState({loading:false});}
    }).catch(err=>{
      this.setState({data:[], result:[], columns:[], loading:false}, ()=>{
        if(cb)
          cb();
      });
      console.log(err);
    });
  }

  changeDataState = (e)=>{
    var newData = process(this.state.data, e.data);
    this.setState({dataState:e.data, result:newData});
  }

  getGridColumns = (data)=>{
    var columns = [{field:'accountPortfolio' , title: 'Account', headerClass:'gridHeader', minWidth:200}];

    if(data.filter(x=>(x.perfStDate!==undefined && x.perfStDate!==null && x.indexName===null)).length>0 && this.props.styleSettings.showPerfStart)
      columns.push({field:'perfStDate' , title: 'Start Date', format:"{0:d}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.twrMTD!==undefined && x.twrMTD!==null && x.twrMTD!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerfMTD)
      columns.push({field:'twrMTD' , title: 'MTD', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returnsQTD!==undefined && x.returnsQTD!==null && x.returnsQTD!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerfQTD)
      columns.push({field:'returnsQTD' , title: 'QTD', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returnsPrevQtr!==undefined && x.returnsPrevQtr!==null && x.returnsPrevQtr!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerfPrevQtr)
      columns.push({field:'returnsPrevQtr' , title: 'Prev Qtr', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns3Month!==undefined && x.returns3Month!==null && x.returns3Month!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf3Month)
      columns.push({field:'returns3Month' , title: '3 Month', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns6Month!==undefined && x.returns6Month!==null && x.returns6Month!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf6Month)
      columns.push({field:'returns6Month' , title: '6 Month', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.twrYTD!==undefined && x.twrYTD!==null && x.twrYTD!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerfYTD)
      columns.push({field:'twrYTD' , title: 'YTD', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returnsPrevYear!==undefined && x.returnsPrevYear!==null && x.returnsPrevYear!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerfPrevYear)
      columns.push({field:'returnsPrevYear' , title: 'Prev Year', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returnsYear!==undefined && x.returnsYear!==null && x.returnsYear!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerfYear)
      columns.push({field:'returnsYear' , title: '1 Year', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns3Year!==undefined && x.returns3Year!==null && x.returns3Year!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf3Year)
      columns.push({field:'returns3Year' , title: '3 Years', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns5Year!==undefined && x.returns5Year!==null && x.returns5Year!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf5Year)
      columns.push({field:'returns5Year' , title: '5 Years', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns7Year!==undefined && x.returns7Year!==null && x.returns7Year!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf7Year)
      columns.push({field:'returns7Year' , title: '7 Years', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns10Year!==undefined && x.returns10Year!==null && x.returns10Year!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf10Year)
      columns.push({field:'returns10Year' , title: '10 Years', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns15Year!==undefined && x.returns15Year!==null && x.returns15Year!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf15Year)
      columns.push({field:'returns15Year' , title: '15 Years', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns20Year!==undefined && x.returns20Year!==null && x.returns20Year!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf20Year)
      columns.push({field:'returns20Year' , title: '20 Years', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.returns25Year!==undefined && x.returns25Year!==null && x.returns25Year!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerf25Year)
      columns.push({field:'returns25Year' , title: '25 Years', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    if(data.filter(x=>(x.twrITD!==undefined && x.twrITD!==null && x.twrITD!==0 && x.indexName===null)).length>0 && this.props.styleSettings.showPerfITD)
      columns.push({field:'twrITD' , title: 'ITD', format:"{0:0.00 '%'}", headerClass:'gridHeader', minWidth:100});
    
    return columns;
  }

  cellRender(tdElement, cellProps) {
    if(cellProps.rowType==='data' && cellProps.field!=='value' && (cellProps.dataItem[cellProps.field]===undefined || cellProps.dataItem[cellProps.field]===null || cellProps.dataItem[cellProps.field]===0)){
        return(
            <td style={{textAlign:'right'}}>-</td>
        );
    }
    else if(tdElement!==null){
      var style={};
      if(cellProps.field!=='value' && cellProps.field!=='accountPortfolio' && cellProps.field!=='perfStDate'){
        style = {textAlign:'right'};
        return(
          <td {...tdElement.props} style={style}/>
        );
      }
      else if(cellProps.field==='accountPortfolio' && cellProps.dataItem.indexName){
        style = {textAlign:'right', fontStyle:'italic', minWidth:'100px'};
        return(
          <td {...tdElement.props} style={style}>{cellProps.dataItem.indexName}</td>
        );
      }
      else if(cellProps.field==='accountPortfolio' && !cellProps.dataItem.indexName){
        return(
          <td {...tdElement.props} style={style}><strong>{cellProps.dataItem.accountPortfolio}</strong></td>
        );
      }

    }

    return tdElement;
  }

  headerCellRender(thElement, cellProps) {
    if(thElement!==null){
      var style={};
      if(cellProps.field!=='value' && cellProps.field!=='accountPortfolio' && cellProps.field!=='perfStDate')
        style = {textAlign:'right'};

      return(
        <div {...thElement.props} style={style}/>
      );
    }

    return thElement;
  }

  render() {
    var className = 'perfHistory'
    // if (this.state.uniqueAccounts.length >= 10){
    //   className = 'perfHistoryFullLength'
    // }

    return(
        <div className={className}>
          <Grid data={this.state.result}
                className='noGroupGridLines'
                cellRender = {this.cellRender}
                headerCellRender = {this.headerCellRender}
                resizable={true}
                reorderable={true}
                sortable={true}
                onDataStateChange={this.changeDataState}
                {...this.state.dataState}>
                <GridNoRecords>
                  {this.state.loading && 'Loading...'}
                  {!this.state.loading && 'No records available'}
                </GridNoRecords>
                {
                this.state.columns.map((column, key)=>{
                  return <GridColumn field={column.field} title={column.title} key={key} format={column.format} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
                })
                }
              </Grid>
          {this.state.loading && <GridLoading />}
        </div>
    );
  }


}

export default PerformanceHistory;