import React, { Component } from "react";
import {Chart, ChartTooltip, ChartSeries, 
  ChartSeriesItem, ChartTitle, ChartCategoryAxis, ChartAxisDefaults,
  ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartLegend} from '@progress/kendo-react-charts';
import { formatNumber } from '@telerik/kendo-intl';
import axios from 'axios';
import 'hammerjs';
import GridLoading from '../../Loaders/GridLoading';

class TwrChart extends Component {

  constructor(props) {
    super(props);

    var returnText = '';
    if (props.returnType === 'twr'){
      returnText = 'Time Weighted Return';
    }
    else if (props.returnType === 'irr'){
      returnText = 'IRR';
    }      

    this.state = {
      data:[],
      colors:[""],
      minValue:0,
      portfolio:this.props.portfolio,
      perfType:this.props.perfType,
      returnType:this.props.returnType,
      date:this.props.date,
      returnText: returnText,
      loading:false
    }

    this.getTWRs = this.getTWRs.bind(this);
    this.getChartColors = this.getChartColors.bind(this);
  }

  componentDidMount(){
    this.getTWRs();
    //this.getChartColors();
    this.getStyleSettings();
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio || this.props.date!==this.state.date || this.props.perfType!==this.state.perfType || this.props.returnType!==this.state.returnType){
      
      var returnText = "Time Weighted Return";
      if(this.props.returnType==='irr')
        returnText = "IRR";
      this.setState({portfolio:this.props.portfolio, date:this.props.date, perfType:this.props.perfType, returnType:this.props.returnType, returnText:returnText}, ()=>{
        this.getTWRs()
      });
    }
  }

  getTWRs(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var unmapped='';

    if(this.props.user.unmapped)
      unmapped = 'true';

    var irr=false
    if(this.state.returnType==="irr")
      irr = true;


      var payload = {
        company:this.props.user.company,
        port:this.state.portfolio.account,
        household:this.props.user.defaultAcct.household,
        date:this.state.date,
        user:this.props.user.user,
        perfType:this.state.perfType,
        irr:irr,
        unmapped:unmapped
      }

    var periodsWithData = []

    axios.post('api/perfTWR', payload, {headers}).then(response=>{
      if(response.data.code===200){
        var perfData = response.data.data;
        var min = 0;

        for(let i=0; i<perfData.length; i++){
          var acctData = perfData[i];

          if(!this.props.styleSettings.showTwrMTD)
            acctData = acctData.filter(x=>(x.category!=='MTD'));
          if(!this.props.styleSettings.showTwrQTD)
            acctData = acctData.filter(x=>(x.category!=='QTD'));
          if(!this.props.styleSettings.showTwrPrevQtr)
            acctData = acctData.filter(x=>(x.category!=='Prev Qtr'));
          if(!this.props.styleSettings.showTwr3Month)
            acctData = acctData.filter(x=>(x.category!=='3 Month'));
          if(!this.props.styleSettings.showTwr6Month)
            acctData = acctData.filter(x=>(x.category!=='6 Month'));
          if(!this.props.styleSettings.showTwrYTD)
            acctData = acctData.filter(x=>(x.category!=='YTD'));
          if(!this.props.styleSettings.showTwrPrevYear)
            acctData = acctData.filter(x=>(x.category!=='Prev Year'));
          if(!this.props.styleSettings.showTwrYear)
            acctData = acctData.filter(x=>(x.category!=='1 Year'));
          if(!this.props.styleSettings.showTwr3Year)
            acctData = acctData.filter(x=>(x.category!=='3 Years'));
          if(!this.props.styleSettings.showTwr5Year)
            acctData = acctData.filter(x=>(x.category!=='5 Years'));
          if(!this.props.styleSettings.showTwr7Year)
            acctData = acctData.filter(x=>(x.category!=='7 Years'));
          if(!this.props.styleSettings.showTwr10Year)
            acctData = acctData.filter(x=>(x.category!=='10 Years'));
          if(!this.props.styleSettings.showTwr15Year)
            acctData = acctData.filter(x=>(x.category!=='15 Years'));
          if(!this.props.styleSettings.showTwr20Year)
            acctData = acctData.filter(x=>(x.category!=='20 Years'));
          if(!this.props.styleSettings.showTwr25Year)
            acctData = acctData.filter(x=>(x.category!=='25 Years'));
          if(!this.props.styleSettings.showTwrITD)
            acctData = acctData.filter(x=>(x.category!=='ITD'));

          // acctData = acctData.filter(x=>(x.value!==null && x.value!==undefined && x.value!==0));

          //filter out any periods in benchmarks that aren't in account
          if(acctData.length>0 && acctData[0].indexName!==null && acctData[0].indexName!==undefined){
            acctData = acctData.filter(
              function(element){
                var test = this.findIndex(a=>{
                  return a.category===element.category;
                });
                return test>=0;
            },perfData[0]);
          }

          for(let k=0; k<acctData.length; k++){
            if(acctData[k].value<min)
              min = acctData[k].value;
          }

          perfData[i]=acctData;
        }

        //get list of periods with data across all accounts (preserves period order for consolidated view)
        for(let i=0; i<perfData.length; i++){
          for(let j=0; j<perfData[i].length; j++){
            if (perfData[i][j].value!==null && perfData[i][j].value!==undefined && perfData[i][j].value!==0){
              periodsWithData.push(perfData[i][j].category)
            }
          }
        }

        //filter out periods that don't have data
        for(let i=0; i<perfData.length; i++){
          perfData[i] = perfData[i].filter(x=>(periodsWithData.includes(x.category)));
        }

        //remove any benchmarks that no longer have periods
        for(let i=perfData.length-1; i>=0; i--){
          if(perfData[i].length===0)
            perfData.splice(i,1);
        }

        this.setState({data:perfData, loading:false, min:min});
      }
      else{
        this.setState({loading:false});
        alert('Could not retrieve performance data - Chart. ' );
      }
    }).catch(err=>{
      this.setState({loading:false});
      console.log(err);
    });
  }

  getChartColors(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/colors?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.code===200 && response.data.colors.length>0){
        this.setState({colors:response.data.colors});
        console.log(response.data.colors)
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
        'chartColors',
        'pieChartColors'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
        var CompLayoutText = "Grid Layout";
        if(response.data.styleSettings.docGrid)
        CompLayoutText = "Folder Layout";

        var rgba = null
        var chartColors = []

        
        if (response.data.styleSettings.chartColors){
          response.data.styleSettings.chartColors.split('|').forEach((colorLabelPair, i) => {
            var c = colorLabelPair.split('<')
            chartColors.push({color: c[0], type: null, mapping: null})
          });
        }
        

        if (response.data.styleSettings.pieChartColors){
          response.data.styleSettings.pieChartColors.split('|').forEach((colorLabelPair, i) => {
            var c = colorLabelPair.split('<')
            chartColors.push({color: c[0], type: 'pie', mapping: c[1]})
          });
        }
        

        


        this.setState({styleSettings: response.data.styleSettings, colors: chartColors})
    })
    .catch(err => {
        console.error(err);
    })
}

  renderTooltip = ({point})=>{
    if(point!==undefined){
      return (
            <div><strong>{point.dataItem.accountPortfolio}</strong><div>{formatNumber(point.value, "0.00 '%'")}</div></div>
          );
    }
    else{
      return(<div></div>);
    }
  }

  render() {
    var series = this.state.data;
    //var categories = ['mtd','qtd','ytd','itd']
    var categories = ['123','456','789','101']
    
    if (this.state.data.length >= 10 && this.state.portfolio.consolidated){
      return(<></>)      
    }
    else{
      return(
        <div className='twrChart'>
        <Chart style={{width:'100%'}}>
        <ChartLegend position="bottom" orientation="vertical" height={90} labels={{margin:{right:30}, padding:2}}/> 
          <ChartTitle text={this.state.returnText}/>
          <ChartTooltip render={this.renderTooltip}/>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem name='labels' line={{visible:false}}/>
            <ChartCategoryAxisItem name='series' line={{visible:false}}/>
            {/* <ChartCategoryAxisItem categories={categories} line={{visible:false}}/> */}
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem title={{ text: this.state.returnText, rotation:270}} axisCrossingValue={this.state.min}/>
          </ChartValueAxis>
            {!this.props.styleSettings.showTwrBench && <ChartSeries>
                {series.map((item, key)=>(
                  !item[0].indexName && <ChartSeriesItem key={key} name={item[0].accountPortfolio} categoryAxis='series' categoryField='category' type='column' field='value' color={(this.state.colors.length>key && this.state.colors[key].color)||''} data={item}/>
                ))}
            </ChartSeries>}
            {this.props.styleSettings.showTwrBench && <ChartSeries>
                {series.map((item, key)=>(
                  <ChartSeriesItem key={key} name={item[0].accountPortfolio} categoryAxis='series' categoryField='category' type='column' field='value' color={(this.state.colors.length>key && this.state.colors[key].color)||''} data={item}/>
                ))}
            </ChartSeries>}
        </Chart>
        {this.state.loading && <GridLoading />}
        </div>
      );
    }
    
  }

}

export default TwrChart;