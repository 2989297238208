import React, { Component } from "react";
class Login extends Component {

  constructor(props) {
    super(props);
    this.state={

    }
    this.props.auth.login();
  }
  




  render() {
    return(
        <div>
        </div>
    );
  }

}

export default Login;