import React, { Component } from "react";
import Popup from "reactjs-popup";
import axios from 'axios';
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { Grid, Row, Col } from "react-flexbox-grid";
import { Input, Switch } from '@progress/kendo-react-inputs';


class ReportListPopup extends Component {

    constructor(props) {
        super(props);


        this.state = {
            username: this.props.user,
            report: this.props.report,
            reportName: this.props.report.reportName,
            reportTitle: this.props.report.reportTitle,
            layout: this.props.report.layout,
            disclaimer: this.props.report.disclaimer,
            feeMethod: this.props.report.feeMethod,
            period1: this.props.report.period1,
            period2: this.props.report.period2,
            reportTitle1: this.props.report.reportTitle1,
            reportTitle2: this.props.report.reportTitle2,
            reportTitle3: this.props.report.reportTitle3,
            reportTitle4: this.props.report.reportTitle4,
            equityAssetClass: this.props.report.equityAssetClass,
            securityClassification: this.props.report.securityClassification,
            includeAI: this.props.report.includeAI,
            companyName: this.props.report.companyName,
            includePortfolioCode: this.props.report.includePortfolioCode,
            bondDescription: this.props.report.bondDescription,
            includeSP: this.props.report.includeSP,
            includeMoody: this.props.report.includeMoody,
            includeFitch: this.props.report.includeFitch,
            showCash: this.props.report.showCash,
            AccountNumber: this.props.report.AccountNumber,
            ShowAuthorization: this.props.report.ShowAuthorization,
            Rounding: this.props.report.Rounding,
            BillFor: this.props.report.BillFor,
        }

        this.saveDefaults = this.saveDefaults.bind(this);
    }

    componentDidMount() {
        
    }

    clearState = (e) => {
        this.setState({
            username: null,
            report: null,
            reportName: null,
            reportTitle: null,
            layout: null,
            disclaimer: null,
            feeMethod: null,
            period1: null,
            period2: null,
            reportTitle1: null,
            reportTitle2: null,
            reportTitle3: null,
            reportTitle4: null,
            equityAssetClass: null,
            securityClassification: null,
            includeAI: null,
            companyName: null,
            includePortfolioCode: null,
            bondDescription: null,
            includeSP: null,
            includeMoody: null,
            includeFitch: null,
            showCash: null,
            ShowAuthorization: null,
            AccountNumber: null,
            Rounding: null,
            BillFor: null,
        })


        this.props.closeRefresh()
    }

    setNewState = (e) => {
        this.setState({
            username: this.props.user,
            report: this.props.report,
            reportName: this.props.report.reportName,
            reportTitle: this.props.report.reportTitle,
            layout: this.props.report.layout,
            disclaimer: this.props.report.disclaimer,
            feeMethod: this.props.report.feeMethod,
            period1: this.props.report.period1,
            period2: this.props.report.period2,
            reportTitle1: this.props.report.reportTitle1,
            reportTitle2: this.props.report.reportTitle2,
            reportTitle3: this.props.report.reportTitle3,
            reportTitle4: this.props.report.reportTitle4,
            equityAssetClass: this.props.report.equityAssetClass,
            securityClassification: this.props.report.securityClassification,
            includeAI: this.props.report.includeAI,
            companyName: this.props.report.companyName,
            includePortfolioCode: this.props.report.includePortfolioCode,
            bondDescription: this.props.report.bondDescription,
            includeSP: this.props.report.includeSP,
            includeMoody: this.props.report.includeMoody,
            includeFitch: this.props.report.includeFitch,
            showCash: this.props.report.showCash,
            AccountNumber: this.props.report.AccountNumber,
            ShowAuthorization: this.props.report.ShowAuthorization,
            Rounding: this.props.report.Rounding,
            BillFor: this.props.report.BillFor,
        })
    }

    _handleChange = e => {
        this.setState({
            [e.target.props.name]: e.target.value
        })
    }



    saveDefaults = (callback) => {

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload;
        var reportTitle
        var layout
        var disclaimer
        var feeMethod
        var period1
        var period2
        var reportTitle1
        var reportTitle2
        var reportTitle3
        var reportTitle4
        var equityAssetClass
        var securityClassification
        var includeAI
        var companyName
        var includePortfolioCode
        var bondDescription
        var includeSP
        var includeMoody
        var includeFitch
        var showCash
        var ShowAuthorization
        var AccountNumber
        var Rounding
        var BillFor

        console.log(this.state)
        console.log(this.props.report)


        if (!this.state.reportTitle) {
            reportTitle = this.props.report.reportTitle
        }
        else {
            reportTitle = this.state.reportTitle
        }

        if (!this.state.layout) {
            layout = this.props.report.layout
        }
        else {
            layout = this.state.layout
        }

        if (!this.state.disclaimer) {
            disclaimer = this.props.report.disclaimer
        }
        else {
            disclaimer = this.state.disclaimer
        }

        if (!this.state.feeMethod) {
            feeMethod = this.props.report.feeMethod
        }
        else {
            feeMethod = this.state.feeMethod
        }

        if (!this.state.period1) {
            period1 = this.props.report.period1
        }
        else {
            period1 = this.state.period1
        }

        if (!this.state.period2) {
            period2 = this.props.report.period2
        }
        else {
            period2 = this.state.period2
        }

        if (this.state.reportTitle1 === undefined) {
            reportTitle1 = this.props.report.reportTitle1
        }
        else {
            reportTitle1 = this.state.reportTitle1
        }

        if (this.state.reportTitle2 === undefined) {
            reportTitle2 = this.props.report.reportTitle2
        }
        else {
            reportTitle2 = this.state.reportTitle2
        }

        if (this.state.reportTitle3 === undefined) {
            reportTitle3 = this.props.report.reportTitle3
        }
        else {
            reportTitle3 = this.state.reportTitle3
        }

        if (this.state.reportTitle4 === undefined) {
            reportTitle4 = this.props.report.reportTitle4
        }
        else {
            reportTitle4 = this.state.reportTitle4
        }

        if (!this.state.equityAssetClass) {
            equityAssetClass = this.props.report.equityAssetClass
        }
        else {
            equityAssetClass = this.state.equityAssetClass
        }

        if (!this.state.securityClassification) {
            securityClassification = this.props.report.securityClassification
        }
        else {
            securityClassification = this.state.securityClassification
        }

        if (this.state.includeAI === undefined || this.state.includeAI === null || this.state.includeAI === "") {
            includeAI = this.props.report.includeAI
        }
        else {
            if (this.state.includeAI === true || this.state.includeAI === "True")
                includeAI = "True"
            else
                includeAI = "False"
        }

        if (!this.state.companyName) {
            companyName = this.props.report.companyName
        }
        else {
            companyName = this.state.companyName
        }

        if (this.state.includePortfolioCode === undefined || this.state.includePortfolioCode === null || this.state.includePortfolioCode === "") {
            includePortfolioCode = this.props.report.includePortfolioCode
        }
        else {
            if (this.state.includePortfolioCode === true || this.state.includePortfolioCode === "True")
                includePortfolioCode = "True"
            else
                includePortfolioCode = "False"
        }

        if (this.state.bondDescription === undefined || this.state.bondDescription === null|| this.state.bondDescription === "") {
            bondDescription = this.props.report.bondDescription
        }
        else {
            if (this.state.bondDescription === true || this.state.bondDescription === "True")
                bondDescription = "True"
            else
                bondDescription = "False"
        }

        if (this.state.includeSP === undefined || this.state.includeSP === null || this.state.includeSP === "") {
            includeSP = this.props.report.includeSP
        }
        else {
            if (this.state.includeSP === true || this.state.includeSP === "True")
                includeSP = "True"
            else
                includeSP = "False"
        }

        if (this.state.includeMoody === undefined || this.state.includeMoody === null || this.state.includeMoody === "") {
            includeMoody = this.props.report.includeMoody
        }
        else {
            if (this.state.includeMoody === true || this.state.includeMoody === "True")
                includeMoody = "True"
            else
                includeMoody = "False"
        }

        if (this.state.includeFitch === undefined || this.state.includeFitch === null || this.state.includeFitch === "") {
            includeFitch = this.props.report.includeFitch
        }
        else {
            if (this.state.includeFitch === true || this.state.includeFitch === "True")
                includeFitch = "True"
            else
                includeFitch = "False"
        }

        if (this.state.showCash === undefined || this.state.showCash === null || this.state.showCash === "") {
            showCash = this.props.report.showCash
        }
        else {
            if (this.state.showCash === true || this.state.showCash === "True")
                showCash = "True"
            else
                showCash = "False"
        }

        if (this.state.AccountNumber === undefined || this.state.AccountNumber === null || this.state.AccountNumber === "") {
            AccountNumber = this.props.report.AccountNumber
        }
        else {
            if (this.state.AccountNumber === true || this.state.AccountNumber === "True")
                AccountNumber = "True"
            else
                AccountNumber = "False"
        }

        if (this.state.ShowAuthorization === undefined || this.state.ShowAuthorization === null || this.state.ShowAuthorization === "") {
            ShowAuthorization = this.props.report.ShowAuthorization
        }
        else {
            if (this.state.ShowAuthorization === true || this.state.ShowAuthorization === "True")
                ShowAuthorization = "True"
            else
                ShowAuthorization = "False"
        }

        if (!this.state.Rounding) {
            Rounding = this.props.report.Rounding
        }
        else {
            Rounding = this.state.Rounding
        }

        if (!this.state.BillFor) {
            BillFor = this.props.report.BillFor
        }
        else {
            BillFor = this.state.BillFor
        }




        payload = {
            company: this.props.user.company,
            reportName: this.props.report.reportName,
            type: this.props.report.type,
            reportTitle: reportTitle,
            layout: layout,
            disclaimer: disclaimer,
            feeMethod: feeMethod,
            period1: period1,
            period2: period2,
            reportTitle1: reportTitle1,
            reportTitle2: reportTitle2,
            reportTitle3: reportTitle3,
            reportTitle4: reportTitle4,
            equityAssetClass: equityAssetClass,
            securityClassification: securityClassification,
            includeAI: includeAI,
            companyName: companyName,
            includePortfolioCode: includePortfolioCode,
            bondDescription: bondDescription,
            includeSP: includeSP,
            includeMoody: includeMoody,
            includeFitch: includeFitch,
            showCash: showCash,
            AccountNumber: AccountNumber,
            ShowAuthorization: ShowAuthorization,
            Rounding: Rounding,
            BillFor: BillFor,
        }


        axios
            .post("api/ssrsSaveDefaults", payload, { headers })
            .then((response) => {
                if (response.data.code === 200) {

                    alert("Report Defaults have been saved.")

                    callback(true)
                }
                else {
                    alert('An error has occured while saving Report Defaults.');
                    callback(false)
                }
            })
            .catch((e) => {
                console.error(e);
                alert('An error has occured while saving Report Defaults');
                callback(false)
            });




    }

    render() {

        const layout = ["Landscape", "Portrait"]
        const feeMethod = ["Gross", "Net"]
        const period1 = ["Month to Date", "Quarter to Date", "Year to Date", "Inception to Date"]
        const period2 = ["Month to Date", "Quarter to Date", "Year to Date", "Inception to Date"]
        const securityClassification = ["Industry Group", "Sector", "Custom Security User Def1", "Custom Security User Def2", "Custom Security User Def3"]
        const roundingList = ['None', 'Round to Nearest Dollar', 'Round Down to Nearest Dollar']
        const billForList = ['None', 'Past Pay Period', 'Future Pay Period']

        return (
            <Popup closeOnDocumentClick={false} onClose={this.clearState} onOpen={this.setNewState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />
                        <div className="header" align="left">
                            Edit Report Defaults
                            </div>
                        <br />

                        <Grid fluid>
                            <Row>
                                {this.props.report.reportTitle && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>{"Report Title"}: </label>
                                        <br></br>
                                        <Input
                                            type="text"
                                            name={"reportTitle"}
                                            defaultValue={this.props.report.reportTitle}
                                            autoComplete="off"
                                            onChange={this._handleChange}
                                            style={{ width: "220px" }}
                                        />
                                        <br></br>
                                    </Col>
                                </>}
                                {this.props.report.layout && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Layout"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"layout"}
                                                defaultValue={this.props.report.layout}
                                                data={layout}
                                                value={this.state.layout}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.disclaimer && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Disclaimer"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"disclaimer"}
                                                defaultValue={this.props.report.disclaimer}
                                                data={this.props.disclaimers}
                                                value={this.state.disclaimer}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.feeMethod && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Fee Method"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"feeMethod"}
                                                defaultValue={this.props.report.feeMethod}
                                                data={feeMethod}
                                                value={this.state.feeMethod}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.period1 && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Period 1"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"period1"}
                                                defaultValue={this.props.report.period1}
                                                data={period1}
                                                value={this.state.period1}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.period2 && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Period 2"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"period2"}
                                                defaultValue={this.props.report.period2}
                                                data={period2}
                                                value={this.state.period2}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {(this.props.report.reportTitle1 || this.props.report.reportName === "Cover Page") && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>{"Report Title 1"}: </label>
                                        <br></br>
                                        <Input
                                            type="text"
                                            name={"reportTitle1"}
                                            defaultValue={this.props.report.reportTitle1}
                                            autoComplete="off"
                                            onChange={this._handleChange}
                                            style={{ width: "220px" }}
                                        />
                                        <br></br>
                                    </Col>
                                </>}
                                {(this.props.report.reportTitle2 || this.props.report.reportName === "Cover Page") && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>{"Report Title 2"}: </label>
                                        <br></br>
                                        <Input
                                            type="text"
                                            name={"reportTitle2"}
                                            defaultValue={this.props.report.reportTitle2}
                                            autoComplete="off"
                                            onChange={this._handleChange}
                                            style={{ width: "220px" }}
                                        />
                                        <br></br>
                                    </Col>
                                </>}
                                {(this.props.report.reportTitle3 || this.props.report.reportName === "Cover Page") && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>{"Report Title 3"}: </label>
                                        <br></br>
                                        <Input
                                            type="text"
                                            name={"reportTitle3"}
                                            defaultValue={this.props.report.reportTitle3}
                                            autoComplete="off"
                                            onChange={this._handleChange}
                                            style={{ width: "220px" }}
                                        />
                                        <br></br>
                                    </Col>
                                </>}
                                {(this.props.report.reportTitle4 || this.props.report.reportName === "Cover Page") && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>{"Report Title 4"}: </label>
                                        <br></br>
                                        <Input
                                            type="text"
                                            name={"reportTitle4"}
                                            defaultValue={this.props.report.reportTitle4}
                                            autoComplete="off"
                                            onChange={this._handleChange}
                                            style={{ width: "220px" }}
                                        />
                                        <br></br>
                                    </Col>
                                </>}
                                {this.props.report.equityAssetClass && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Equity Asset Class"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"equityAssetClass"}
                                                defaultValue={this.props.report.equityAssetClass}
                                                data={this.props.equityAssetClass}
                                                value={this.state.equityAssetClass}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.securityClassification && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Security Classification"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"securityClassification"}
                                                defaultValue={this.props.report.securityClassification}
                                                data={securityClassification}
                                                value={this.state.securityClassification}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.Rounding && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Rounding"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"Rounding"}
                                                defaultValue={this.props.report.Rounding}
                                                data={roundingList}
                                                value={this.state.Rounding}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.BillFor && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Bill For"}: </label>
                                            <br></br>
                                            <DropDownList
                                                //style={{ marginLeft: "14px" }}
                                                name={"BillFor"}
                                                defaultValue={this.props.report.BillFor}
                                                data={billForList}
                                                value={this.state.BillFor}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.includeAI && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Include Accrued Interest"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"includeAI"}
                                                defaultChecked={this.props.report.includeAI === "True"}
                                                value={this.state.includeAI}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.companyName && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>{"Company Name"}: </label>
                                        <br></br>
                                        <Input
                                            type="text"
                                            name={"companyName"}
                                            defaultValue={this.props.report.companyName}
                                            autoComplete="off"
                                            onChange={this._handleChange}
                                            style={{ width: "220px" }}
                                        />
                                        <br></br>
                                    </Col>
                                </>}
                                {this.props.report.includePortfolioCode && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Include Portfolio Code"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"includePortfolioCode"}
                                                defaultChecked={this.props.report.includePortfolioCode === "True"}
                                                value={this.state.includePortfolioCode}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.bondDescription && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Include Bond Description"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"bondDescription"}
                                                defaultChecked={this.props.report.bondDescription === "True"}
                                                value={this.state.bondDescription}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.includeSP && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Include S&P"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"includeSP"}
                                                defaultChecked={this.props.report.includeSP === "True"}
                                                value={this.state.includeSP}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.includeMoody && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Include Moody"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"includeMoody"}
                                                defaultChecked={this.props.report.includeMoody === "True"}
                                                value={this.state.includeMoody}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.includeFitch && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Include Fitch"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"includeFitch"}
                                                defaultChecked={this.props.report.includeFitch === "True"}
                                                value={this.state.includeFitch}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.showCash && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Include Cash"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"showCash"}
                                                defaultChecked={this.props.report.showCash === "True"}
                                                value={this.state.showCash}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.AccountNumber && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Show Account Number"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"AccountNumber"}
                                                defaultChecked={this.props.report.AccountNumber === "True"}
                                                value={this.state.AccountNumber}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                                {this.props.report.ShowAuthorization && <>
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <div>
                                            <label>{"Show Authorization"}: </label>
                                            <br></br>
                                            <Switch
                                                name={"ShowAuthorization"}
                                                defaultChecked={this.props.report.ShowAuthorization === "True"}
                                                value={this.state.ShowAuthorization}
                                                onChange={this._handleChange}
                                            />
                                            <br></br>
                                        </div>
                                    </Col>
                                </>}
                            </Row>
                        </Grid>

                        <div style={{ display: "flex" }}>
                            <Button onClick={(e) => this.saveDefaults((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Changes</Button>
                        </div>
                    </div>

                )}
            </Popup>
        )
    }
}


export default ReportListPopup;