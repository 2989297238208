import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

class WebRepSignUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      companyName:'',
      address:'',
      city:'',
      state:'',
      zip:'',
      country:'',
      website:'',
      firstName:'',
      lastName:'',
      title:'',
      phone:'',
      email:'',
      pms:'',
      otherPMS:'',
      otherPMSVersion:'',
      axysVersion:'',
      apxVersion:'',
      portCenterVersion:'',
      tms:'',
      otherTMS:'',
      bloombergVersion:'',
      cRiverVersion:'',
      instinetVersion:'',
      moxyVersion:'',
      otherTMSVersion:'',
      notifyUpdates:'',
      pmsService:'',
      otherServices:'',
      heardAbout:'',
      otherHeard:'',
      captcha:''
    }


  }

  clearState(){
      this.setState({
        companyName:'',
        address:'',
        city:'',
        state:'',
        zip:'',
        country:'',
        website:'',
        firstName:'',
        lastName:'',
        title:'',
        phone:'',
        email:'',
        pms:'',
        otherPMS:'',
        otherPMSVersion:'',
        axysVersion:'',
        apxVersion:'',
        portCenterVersion:'',
        tms:'',
        otherTMS:'',
        bloombergVersion:'',
        cRiverVersion:'',
        instinetVersion:'',
        moxyVersion:'',
        otherTMSVersion:'',
        notifyUpdates:'',
        pmsService:'',
        otherServices:'',
        heardAbout:'',
        otherHeard:'',
        captcha:''
      });
  }

  requiredFieldsValid(){
      if(this.state.companyName===''){
          alert("Company Name is required!");
          return false;
      }
      else if(this.state.address===''){
        alert("Address is required!");
        return false;
      }
      else if(this.state.city===''){
        alert("City is required!");
        return false;
      }
      else if(this.state.state==='' || this.state.state==='--'){
        alert("State is required!");
        return false;
      }
      else if(this.state.firstName===''){
        alert("First Name is required!");
        return false;
      }
      else if(this.state.lastName===''){
        alert("Last Name is required!");
        return false;
      }
      else if(this.state.title===''){
        alert("Title is required!");
        return false;
      }
      else if(this.state.phone===''){
        alert("Phone Number is required!");
        return false;
      }
      else if(this.state.email===''){
        alert("E-mail Address is required!");
        return false;
      }
      else if(!emailRegex.test(this.state.email)){
          alert("E-mail Address is in an invalid format.");
          return false;
      }
      else if(this.state.pms===''){
          alert("Please select a portfolio management system.");
          return false;
      }
      else if(this.state.axysVersion==='' && this.state.apxVersion===''
            && this.state.portCenterVersion==='' && this.state.otherPMSVersion===''){
        alert("Portfolio Management System Version is required!");
        return false;
      }
      else if(this.state.tms==='moxy' && this.state.moxyVersion===''){
        alert("Moxy Version is required!");
        return false;
      }
      else if(this.state.tms==='other' && this.state.otherTMSVersion===''){
        alert(this.state.otherTMS+" version is required!");
        return false;
      }
      else
        return true;
  }

  onInputChange(e, attr){

    var val = e.target.value;

    if(attr==='email')
        val = val.trim();

    if(attr==='notifyUpdates' || attr==='pmsService' || attr==='otherServices')
        this.setState({[attr]:!this.state[attr]});
    else
        this.setState({[attr]:val});

    if(e.target.name==='pms'){
        this.setState({pmsVersion:''});
    }
    else if(e.target.name==='managementsystem'){
        this.setState({tmsVersion:''});
    }
  }

  submit = (e)=>{
    var valid = this.requiredFieldsValid();
    if(valid){
        var pms = '';
        var pmsVersion = '';
        var tms = '';
        var tmsVersion = '';
        var heard = '';

        if(this.state.pms==='other'){
            pms = this.state.otherPMS;
            pmsVersion = this.state.otherPMSVersion;
        }
        else if(this.state.pms==='axys'){
            pms = 'axys';
            pmsVersion = this.state.axysVersion;
        }
        else if(this.state.pms==='apx'){
            pms = 'apx';
            pmsVersion = this.state.apxVersion;
        }
        else if(this.state.pms==='portfoliocenter'){
            pms = 'portfoliocenter';
            pmsVersion = this.state.portCenterVersion;
        }

        if(this.state.tms==='other'){
            tms = this.state.otherTMS;
            tmsVersion = this.state.otherTMSVersion;
        }
        else if(this.state.tms==='bloomberg'){
            tms = 'bloomberg';
            tmsVersion = this.state.bloombergVersion;
        }
        else if(this.state.tms==='charlesRiver'){
            tms = 'charlesRiver';
            tmsVersion = this.state.cRiverVersion;
        }
        else if(this.state.tms==='instinet'){
            tms = 'instinet';
            tmsVersion = this.state.instinetVersion;
        }
        else if(this.state.tms==='moxy'){
            tms = 'moxy';
            tmsVersion = this.state.moxyVersion;
        }

        if(this.state.heardAbout==='other'){
            heard = this.state.otherHeard;
        }
        else{
            heard = this.state.heardAbout;
        }
        var payload = {
            company:this.state.companyName,
            address:this.state.address,
            city:this.state.city,
            state:this.state.state,
            zip:this.state.zip,
            country:this.state.country,
            website:this.state.website,
            first:this.state.firstName,
            last:this.state.lastName,
            title:this.state.title,
            phone:this.state.phone,
            email:this.state.email,
            pms:pms,
            pmsVersion:pmsVersion,
            toms:tms,
            tomsVersion:tmsVersion,
            updates:this.state.notifyUpdates,
            pmsService:this.state.pmsService,
            nonPmsService:this.state.otherServices,
            heard:heard
        }

        axios.post('api/webrepsignup', payload).then(response=>{
            if(response.data.code===200){
                alert('You have been successfully signed up. You should receive an email shortly.');
                this.clearState();
            }
            else if(response.data.code===201){
                alert("The web reports tab has been added to your portal account. Please login to access web reports.");
                this.clearState();
            }
            else if(response.data.code===301 || response.data.code===302){
                alert("Error: There is already an account at CSSI associated with this email address.");
            }
            else{
                alert('An error occured during the sign up process, please try again later.');
            }
        }).catch(err=>{
            alert('An error occured during the sign up process, please try again later.');
            console.log(err);
        })
    }
  }

  onCaptcha = (e)=>{
    this.setState({captcha:e});
  }

  render() {
    return(
        <div>
          <div><a href='https://client-portal.cssi.org' style={{float:'right', paddingTop:'10px', paddingBottom:'10px', paddingRight:'15px'}}>Already registered on the portal?</a></div>
          <br/>
          <h4 style={{textAlign:'center'}}>Complete the following request form to download reports, scripts and macros: (* denotes required fields)</h4>
          <table align='center'>
              <tbody>
                  <tr>
                      <td className='heading' valign='TOP'>1.</td>
                      <td className='heading' colSpan='3'>Company Information:</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Company Name:</td>
                      <td><input type="text" value={this.state.companyName} onChange={(e)=>this.onInputChange(e,'companyName')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Address:</td>
                      <td><input type="text" value={this.state.address} onChange={(e)=>this.onInputChange(e,'address')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>City:</td>
                      <td><input type="text" value={this.state.city} onChange={(e)=>this.onInputChange(e,'city')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>State:</td>
                      <td><select size="1" value={this.state.state} onChange={(e)=>this.onInputChange(e,'state')}>
                            <option>--</option><option>AL</option><option>AK</option>
                            <option>AZ</option><option>AR</option><option>CA</option>
                            <option>CO</option><option>CT</option><option>DE</option>
                            <option>DC</option><option>FL</option><option>GA</option>
                            <option>HI</option><option>ID</option><option>IL</option>
                            <option>IN</option><option>IA</option><option>KS</option>
                            <option>KY</option><option>LA</option><option>ME</option>
                            <option>MD</option><option>MA</option><option>MI</option>
                            <option>MN</option><option>MS</option><option>MO</option>
                            <option>MT</option><option>NE</option><option>NV</option>
                            <option>NH</option><option>NJ</option><option>NM</option>
                            <option>NY</option><option>NC</option><option>ND</option>
                            <option>OH</option><option>OK</option><option>OR</option>
                            <option>PA</option><option>RI</option><option>SC</option>
                            <option>SD</option><option>TN</option><option>TX</option>
                            <option>UT</option><option>VT</option><option>VA</option>
                            <option>WA</option><option>WV</option><option>WI</option>
                            <option>WY</option>
                            <option>--</option>
                            <option>AB</option><option>BC</option><option>MB</option>
                            <option>NB</option><option>NF</option><option>NT</option>
                            <option>NS</option><option>NU</option><option>ON</option>
                            <option>PE</option><option>QC</option><option>SK</option>
                            <option>YT</option>
                        </select> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Zip:</td>
                      <td><input type="text" value={this.state.zip} onChange={(e)=>this.onInputChange(e,'zip')}/></td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Country:</td>
                      <td><input type="text" value={this.state.country} onChange={(e)=>this.onInputChange(e,'country')}/></td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Website:</td>
                      <td><input type="text" value={this.state.website} onChange={(e)=>this.onInputChange(e,'website')}/></td>
                  </tr>
                  <tr>
                      <td className='heading' valign='TOP'>2.</td>
                      <td className='heading' colSpan='3'>Contact Information:</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>First Name:</td>
                      <td><input type="text" value={this.state.firstName} onChange={(e)=>this.onInputChange(e,'firstName')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Last Name:</td>
                      <td><input type="text" value={this.state.lastName} onChange={(e)=>this.onInputChange(e,'lastName')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Title:</td>
                      <td><input type="text" value={this.state.title} onChange={(e)=>this.onInputChange(e,'title')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Phone Number:</td>
                      <td><input type="text" value={this.state.phone} onChange={(e)=>this.onInputChange(e,'phone')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>E-mail Address:</td>
                      <td><input type="text" value={this.state.email} onChange={(e)=>this.onInputChange(e,'email')}/> *</td>
                  </tr>
                  <tr>
                      <td className='heading' valign='TOP'>3.</td>
                      <td className='heading' colSpan='3'>Portfolio Management System Utilized:</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="pms" value='axys' onChange={e=>this.onInputChange(e,'pms')}/>SS&C Advent Axys (version #):</td>
                      <td><input type="text" disabled={this.state.pms!=='axys'} value={this.state.axysVersion} onChange={(e)=>this.onInputChange(e,'axysVersion')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="pms" value='apx' onChange={e=>this.onInputChange(e,'pms')}/>SS&C Advent APX (version #):</td>
                      <td><input type="text" disabled={this.state.pms!=='apx'} value={this.state.apxVersion} onChange={(e)=>this.onInputChange(e,'apxVersion')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="pms" value='portfoliocenter' onChange={e=>this.onInputChange(e,'pms')}/>Tamarac PortfolioCenter (version #):</td>
                      <td><input type="text" disabled={this.state.pms!=='portfoliocenter'} value={this.state.portCenterVersion} onChange={(e)=>this.onInputChange(e,'portCenterVersion')}/> *</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="pms" value='other' onChange={e=>this.onInputChange(e,'pms')}/><input type="text" placeholder="Other" onChange={e=>this.onInputChange(e,'otherPMS')}/> (version #):</td>
                      <td><input type="text" disabled={this.state.pms!=='other'} value={this.state.otherPMSVersion} onChange={(e)=>this.onInputChange(e,'otherPMSVersion')}/> * If Other, please specify name and version.</td>
                  </tr>
                  <tr>
                      <td className='heading' valign='TOP'>4.</td>
                      <td className='heading' colSpan='3'>Trade Order Management System Utilized:</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="managementsystem" value="none" onChange={e=>this.onInputChange(e,'tms')}/>None</td>
                      <td></td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="managementsystem" value='bloomberg' onChange={e=>this.onInputChange(e,'tms')}/>Bloomberg (version #):</td>
                      <td><input type="text" disabled={this.state.tms!=='bloomberg'} value={this.state.bloombergVersion} onChange={(e)=>this.onInputChange(e,'bloombergVersion')}/></td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="managementsystem" value='charlesRiver' onChange={e=>this.onInputChange(e,'tms')}/>Charles River (version #):</td>
                      <td><input type="text" disabled={this.state.tms!=='charlesRiver'} value={this.state.cRiverVersion} onChange={(e)=>this.onInputChange(e,'cRiverVersion')}/></td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="managementsystem" value="instinet" onChange={e=>this.onInputChange(e,'tms')}/>Instinet (version #):</td>
                      <td><input type="text" disabled={this.state.tms!=='instinet'} value={this.state.instinetVersion} onChange={(e)=>this.onInputChange(e,'instinetVersion')}/></td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="managementsystem" value="moxy" onChange={e=>this.onInputChange(e,'tms')}/>SS&C Advent Moxy (version #):</td>
                      <td><input type="text" disabled={this.state.tms!=='moxy'} value={this.state.moxyVersion} onChange={(e)=>this.onInputChange(e,'moxyVersion')}/> * If SS&C Advent Moxy, please specify version.</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td><input type="radio" name="managementsystem" value='other' onChange={e=>this.onInputChange(e,'tms')}/><input type="text" placeholder="Other" value={this.state.otherTMS} onChange={e=>this.onInputChange(e,'otherTMS')}/> (version #):</td>
                      <td><input type="text" disabled={this.state.tms!=='other'} value={this.state.otherTMSVersion} onChange={(e)=>this.onInputChange(e,'otherTMSVersion')}/> * If Other, please specify name and version.</td>
                  </tr>
                  <tr>
                      <td className='heading' valign='TOP'>5.</td>
                      <td className='heading'>Sign Up for Updates</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="checkbox" checked={this.state.notifyUpdates} onChange={e=>this.onInputChange(e,'notifyUpdates')}/>I would like to be notified when new/updated reports are placed in the "Free Downloads" section.</td>   
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="checkbox" checked={this.state.pmsService} onChange={e=>this.onInputChange(e,'pmsService')}/>I would like to be contacted for services related to our Portfolio Management System.</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="checkbox" checked={this.state.otherServices} onChange={e=>this.onInputChange(e,'otherServices')}/>I am interested in non-Portfolio Management System related services.</td>
                  </tr>
                  <tr>
                      <td className='heading' valign='TOP'>6.</td>
                      <td className='heading' colSpan='3'>How did you hear about CSSI?</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="radio" name="heard" value='conference' onChange={e=>this.onInputChange(e,'heardAbout')}/>SS&C Deliver Conference</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="radio" name="heard" value='usergroup' onChange={e=>this.onInputChange(e,'heardAbout')}/>TEN - The Exchange Network (formerly AUG - Advent Users Group)</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="radio" name="heard" value='recommendation' onChange={e=>this.onInputChange(e,'heardAbout')}/>Industry Colleague Recommendation</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="radio" name="heard" value='vendor' onChange={e=>this.onInputChange(e,'heardAbout')}/>Vendor Referral</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="radio" name="heard" value='linkedin' onChange={e=>this.onInputChange(e,'heardAbout')}/>LinkedIn</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td colSpan='3'><input type="radio" name="heard" value='search' onChange={e=>this.onInputChange(e,'heardAbout')}/>Search engine (Yahoo, Google, etc.)</td>
                  </tr>
                  {/* <tr>
                      <td></td>
                      <td><input type="radio" name="heard" value='other' onChange={e=>this.onInputChange(e,'otherHeard')}/><input type="text" placeholder="Other" onChange={(e)=>this.onInputChange(e,'heardAbout')}/></td>
                  </tr> */}
                  <tr><br/></tr>
                  
              </tbody>
          </table>
          <table align='center'>
              <tbody>
                  <tr valign='top'>
                      {this.state.captcha && <td><Button className='submitButton' onClick={this.submit} disabled={!this.state.captcha}>Submit</Button></td>}
                  </tr>
                  <tr valign='top'>
                      {!this.state.captcha && <td><ReCAPTCHA sitekey='6LcrPaIUAAAAALefYRAsDjrAICJoxkSTO0iJpNPC' onChange={this.onCaptcha}/></td>}                                       
                  </tr>
              </tbody>
          </table>
        </div>
    );
  }

}

// eslint-disable-next-line
const emailRegex= RegExp( process.env.REACT_APP_EMAIL_REGEX );



export default WebRepSignUp;