import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

class DatabaseListPopUpExclude extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDatabases: this.props.excludeDB,
      display: '',
      databases: [],
      result: []
    }

  }

  componentDidMount = () => {
    this.getAllDatabases();
  }

  getAllDatabases = () => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios
      .get("api/getAllDatabases?company=" + this.props.company, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({
            databases: response.data.data,
            result: response.data.data
          });
        }
        else {
          alert('An error has occured while sending your request.');
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  onUserChange = (e) => {
    this.setState({ selectedDatabases: e.target.value });
  }

  cancel = () => {
    this.setState({ selectedDatabases: this.props.excludeDB });
  }

  render() {
    return (
      <Popup trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">

            <Button className="close" icon="close" onClick={() => { this.cancel(); close()}} />

            <div className="header" align="left">
              Databases to Exclude
            </div>

            <div className="content">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Click to select databases:</strong>
                    </td>
                    <td width="400">
                      <MultiSelect
                        style={{ width: '100%' }}
                        data={this.state.result}
                        onChange={this.onUserChange}
                        filterable={true}
                        allowCustom={false}
                        defaultValue={this.state.selectedDatabases}
                        onBlur={(e) => console.log(e)}
                        onFilterChange={(e) => {
                          this.setState({ result: filterBy(this.state.databases, e.filter) });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="actions">

              <Button className="button" onClick={() => { this.cancel(); close() }}>
                Close
              </Button>

              <Button className="upload" onClick={() => {
                this.props.updateExcludeDatabases(this.state.selectedDatabases);
                close();
              }}>
                Confirm
              </Button>

            </div>

          </div>
        )}
      </Popup>

    );
  };

}

export default DatabaseListPopUpExclude;