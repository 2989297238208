import React from 'react';
import axios from 'axios';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { Doughnut } from 'react-chartjs-2';
import { Container, Row, Col } from 'reactstrap';
import loading from '../../Callback/loading.svg';
import '../Snapshot.css';

class AccountOverview extends React.Component {

  constructor(props) {
    super(props);

    const valObj = this.getValues(this.props.width);

    this.state = {
      data: [],
      selectedDate: false,
      labels: [],
      col1: valObj.col1,
      col2: valObj.col2,
      graphHeight: valObj.graphHeight,
      assetAllocation: '',
      sector: '',
      classification: 'securityName',
      dataState: { sort: [{ field: 'sector', dir: 'asc' }] },
      initialLoad: true,
      loading: true,
      plugins: [],
      dates: []
    };

    this.options = {
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return " " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentValue);

          },
          title: function(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    }

  }

  getValues = (width) => {
    let col1 = 0, col2 = 0, graphHeight = 0;
    switch (width) {
      case 4:
        col1 = 6; col2 = 6; graphHeight = 350;
        break;
      case 6:
        col1 = 6; col2 = 6; graphHeight = 350;
        break;
      case 12:
        col1 = 3; col2 = 3; graphHeight = 300;
        break;
      default:
    }
    return { col1: col1, col2: col2, graphHeight: graphHeight }
  }

  componentDidMount() {
    this.getStyleSettings(() => {
      this.props.callback((width) => {
        const CBvalObj = this.getValues(width);
        this.setState({
          graphHeight: CBvalObj.graphHeight,
          col1: CBvalObj.col1,
          col2: CBvalObj.col2
        })
      });
      if (this.props.onWidgetLoad) {
        this.props.onWidgetLoad();
        this.props.onAddCallback((result, err) => {
          this.setState({ loading: true }, () => {
            this.getHoldDates(() => {
              this.onSubmit();
            })
          })
        });
      }
    })
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'acctOverviewClassificationGrouping'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        var classificationGrouping = this.getClassificationGroupingString(response.data.styleSettings.acctOverviewClassificationGrouping)
        var groupingsArray = classificationGrouping.split(',');
        var classification = groupingsArray[0]
        this.setState({ classification: classification, styleSettings: response.data.styleSettings }, () => {
          cb()
        })
      })
      .catch(err => {
        console.error(err);
      })
  }

  getClassificationGroupingString(stringIn) {
    stringIn = stringIn.replace(/Asset Class/g, 'assetAllocation')
    stringIn = stringIn.replace(/Sector/g, 'sector')
    stringIn = stringIn.replace(/Securities/g, 'securityName')
    stringIn = stringIn.replace(/ > /g, ',')

    return stringIn
  }

  getHoldDates(cb) {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      port: '',
      household: this.props.selectedHousehold.household
    }
    axios.post('api/posDates', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        this.setState({ selectedDate: response.data.data[0].fDate, selectedDateObject: new Date(response.data.data[0].fDate) }, () => {
          cb()
        });
      }
      else {
        cb()
      }
    }).catch(err => {
      console.log(err);
    });
  }

  onSubmit = () => {
    // logic to get data from APIs
    this.getAssetAllocationData();
  }


  getAssetAllocationData = () => {
    var currentType = null
    var sector = ''
    var assetAllocation = ''
    var nextType = null

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var port = this.props.selectedHousehold.household

    axios.get('api/assetAllocationWidget',
      {
        method: 'GET',
        params: {
          company: this.props.user.company,
          date: this.state.selectedDate,
          classification: this.state.classification,
          household: port,
          includeAccruedInterest: true
        },
        headers: headers
      }).then(response => {
        if (response.data.code === 200) {
          var filteredData = response.data.data.filter(item => { return (true) });
          var origColorOrder = []
          filteredData.forEach(d => {
            origColorOrder.push(d.color)
          })
          filteredData.sort(this.compareMarketVal);
          var resData = [...filteredData];
          var dataStateCopy = this.state.dataState
          dataStateCopy.sort = [{ field: 'marketValue', dir: 'asc' }]
          this.setState({ dataState: dataStateCopy })
          var chartData = []
          var totalMarketValue = ''
          if (resData.length > 0) {
            resData = process(resData, dataStateCopy)
            resData.data.forEach((d, i) => {
              d.color = origColorOrder[i]
            })
            chartData = [...resData.data]
            //totalMV = response.data.data[0].totalMV
            var totals = aggregateBy(resData.data, [{ field: 'marketValue', aggregate: 'sum' }, { field: 'percentage', aggregate: 'sum' }]);
            resData.data.push({
              assetAllocation: 'TOTALS',
              sector: "TOTALS",
              securityName: "TOTALS",
              securitySymbol: "TOTALS",
              marketValue: totals.marketValue.sum, //percentage:totals.percentage.sum
            });
            totalMarketValue = totals.marketValue.sum
          }

          // if(this.props.onAssetSecChange){
          //   this.props.onAssetSecChange(assetAllocation, sector);
          //   this.setState({labelAssetAllocation: assetAllocation, labelSector: sector, currentType: currentType})
          // }



          filteredData = this.getDataStructure(filteredData);
          this.setTotalMarketValue(totalMarketValue);

          if (this.state.initialLoad) {
            this.setState({ data: filteredData, result: resData, chartData: chartData, loading: false, initialLoad: false, animation: true, origColorOrder: origColorOrder });
          }
          if (nextType === 'assetAllocation'/* && assetAllocation*/) {
            this.setState({
              data: filteredData, result: resData, chartData: chartData, loading: false, animation: true, origColorOrder: origColorOrder,
              sector: sector, assetAllocation: assetAllocation, type: nextType != null ? nextType : this.state.nextType
            });
          }
          else if (nextType === 'sector') {
            this.setState({
              data: filteredData, result: resData, chartData: chartData, loading: false, animation: true, origColorOrder: origColorOrder,
              assetAllocation: assetAllocation, sector: sector, type: nextType != null ? nextType : this.state.nextType
            });
          }
          else {
            this.setState({
              data: filteredData, result: resData, chartData: chartData, loading: false, animation: true, origColorOrder: origColorOrder,
              assetAllocation: assetAllocation, sector: sector, type: nextType != null ? nextType : this.state.nextType
            });
          }

        }
        else {
          this.setState({ loading: false, type: nextType });
          alert('Could not retrieve asset class data.');
        }
      }).catch(err => {
        console.log(err);
        this.setState({ loading: false, type: nextType });
      });
  }

  compareMarketVal(a, b) {
    return b.marketValue - a.marketValue;
  }
  
  setTotalMarketValue = (num) => {
    var formattedMarketValueTotal = parseFloat(num) ? this.formatCash(parseFloat(num)) : 'N/A'

    this.setState({
      plugins: [{
        id: 'centerText',
        beforeDraw: function (chart) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (((width / 160) + (height / 200)) / 2).toFixed(2);
          ctx.font = "bold " + fontSize + "em sans-serif";
          ctx.textBaseline = "middle";
          var text = formattedMarketValueTotal,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]
    })
  }

  formatCash = (num) => {
    if (num < 1e3){
      return num.toFixed(2);
    }
    if (num >= 1e3 && num < 1e6){
      if (((num / 1e3) < 10))
        return +(Math.floor((num * 100) / 1e3) / 100).toFixed(2) + " K";
      if ((num / 1e3) >= 10 && (num / 1e3) < 100)
        return +(Math.floor((num * 10) / 1e3) / 10).toFixed(1) + " K";
      if (((num / 1e3) >= 100))
        return +(Math.floor((num) / 1e3)).toFixed(0) + " K";
    }
    if (num >= 1e6 && num < 1e9){
      if (((num / 1e6) < 10))
        return +(Math.floor((num * 100) / 1e6) / 100).toFixed(2) + " M";
      if ((num / 1e6) >= 10 && (num / 1e6) < 100)
        return +(Math.floor((num * 10) / 1e6) / 10).toFixed(1) + " M";
      if (((num / 1e6) >= 100))
        return +(Math.floor((num) / 1e6)).toFixed(0) + " M";
    }
    if (num >= 1e9 && num < 1e12){
      if (((num / 1e9) < 10))
        return +(Math.floor((num * 100) / 1e9) / 100).toFixed(2) + " B";
      if ((num / 1e9) >= 10 && (num / 1e9) < 100)
        return +(Math.floor((num * 10) / 1e9) / 10).toFixed(1) + " B";
      if (((num / 1e9) >= 100))
        return +(Math.floor((num) / 1e9)).toFixed(0) + " B";
    } 
    if (num >= 1e12){
      if (((num / 1e12) < 10))
        return +(Math.floor((num * 100) / 1e12) / 100).toFixed(2) + " T";
      if ((num / 1e12) >= 10 && (num / 1e12) < 100)
        return +(Math.floor((num * 10) / 1e12) / 10).toFixed(1) + " T";
      if (((num / 1e12) >= 100))
        return +(Math.floor((num) / 1e12)).toFixed(0) + " T";
    }
  };

  getDataStructure(data) {
    var labels = []
    var percentages = []
    var values = []
    var colors = []
    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].label);
      percentages.push(data[i].percentage)
      values.push(data[i].marketValue);
      colors.push(data[i].color.replace('rgb', 'rgba'));
    }

    return {
      labels: labels,
      datasets: [
        {
          label: 'Market Value',
          data: values,
          backgroundColor: colors,
          percentage: percentages
        }
      ]
    }
  }

  render() {
    return (<Container fluid={true}>
      {this.state.loading && <img className="loadingIcon" src={loading} alt="loading" />}
      {!this.state.loading &&
      <Row className="mb-4">
        <Col md={this.state.col1}>
          {(!this.state.loading && this.state.data.labels.length !== 0) ?
            <div style={{ height: this.state.graphHeight + 'px' }}>
              <Doughnut
                data={this.state.data}
                options={this.options}
                legend={{ display: false }}
                plugins={this.state.plugins}
              />
            </div>
            : <></>}
        </Col>
        <Col md={this.state.col2}>
          <div className='acctOverviewTopHoldings'>
            {(!this.state.loading && this.state.data.labels.length !== 0) &&
              <div className='acctOverviewSnapshotHeader' ><label>{`Top Holdings as of ${this.state.selectedDateObject.toLocaleDateString("en-US", { timeZone: "UTC" })}`}</label></div>
            }
            {(!this.state.loading && this.state.data.labels.length !== 0) && 
            <table className='acctOverviewSnapshot'>
              <tbody>
                {(!this.state.loading && this.state.data.labels.length !== 0) && this.state.data.labels.map((r, i) => {
                  if (i < 5) {
                    return (
                      <>
                        <tr>
                          <td className='acctOverviewColor' style={{ backgroundColor: this.state.data.datasets[0].backgroundColor[i] }} onMouseEnter={(e) => { }} onMouseLeave={(e) => { }}>
                          </td>
                          <td className='acctOverviewLabel'>
                            <label>{r}</label>
                          </td>
                          <td className='acctOverviewPercent'>
                            <label>{(Math.round(this.state.data.datasets[0].percentage[i] * 10000) / 100).toFixed(2)}%</label>
                          </td>
                        </tr>
                        <tr className='acctOverviewValueRow'>
                          <td />
                          <td />
                          <td className='acctOverviewValue'>
                            <label>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.data.datasets[0].data[i])}</label>
                          </td>
                        </tr>
                        <tr style={{ height: '8px' }}></tr>
                      </>
                    )
                  }
                  else {
                    return (<></>)
                  }
                })}
              </tbody>
            </table>}
          </div>
        </Col>
      </Row>}
      {!this.state.loading && this.state.data.labels.length === 0 && 
        <div className='widgetNoData'>No data available</div>
      }
    </Container>
    )
  }
}

export default AccountOverview;