import React, { Component } from "react";
import axios from 'axios';
import GridLoading from '../../Loaders/GridLoading';


class AccountSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
        accountSummary:{
          mtd:{},
          qtd:{},
          pqtr:{},
          "3mo":{},
          "6mo":{},
          ytd:{},
          pyr:{},
          l1y:{},
          l3y:{},
          l5y:{},
          l7y:{},
          l10y:{},
          l15y:{},
          l20y:{},
          l25y:{},
          itd:{}
        },
        periods:[],
        periodOne:this.props.styleSettings.acctPeriod1,
        periodTwo:this.props.styleSettings.acctPeriod2,
        periodThree:this.props.styleSettings.acctPeriod3,
        portfolio:this.props.portfolio,
        date:this.props.date,
        perfType:this.props.perfType,
        loading:false
    }

    this.getAccountSummary = this.getAccountSummary.bind(this);
  }

  componentDidMount(){
    this.getAccountSummary();
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio || this.props.date!==this.state.date || this.props.perfType!==this.state.perfType){
      this.setState({portfolio:this.props.portfolio, date:this.props.date, perfType:this.props.perfType}, ()=>{this.getAccountSummary()});
    }
  }

  getAccountSummary(){
    this.setState({loading:true});

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var unmapped = '';

    if(this.props.user.unmapped)
      unmapped = 'true';

    var payload = {
      company:this.props.user.company,
      port:this.state.portfolio.account,
      date:this.state.date,
      household:this.props.user.defaultAcct.household,
      user:this.props.user.user,
      perfType:this.state.perfType,
      unmapped:unmapped
    }
    axios.post('api/accountSummary', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0){
            var formatted = {};
            var periods = [];

            for(let i=0; i<response.data.data.length; i++){
              response.data.data[i].periodCode = response.data.data[i].periodCode.toLowerCase()
              formatted[response.data.data[i].periodCode]=response.data.data[i];

              if(this.props.styleSettings.acctDropPeriods.indexOf(response.data.data[i].periodCode)>=0)
                periods.push({name:response.data.data[i].periodDesc, value:response.data.data[i].periodCode});
            }
            this.setState({accountSummary:formatted, loading:false , periods:periods});
          }
          else
            this.setState({periods:[], loading:false , accountSummary:{mtd:{},qtd:{},pqtr:{},"3mo":{},"6mo":{},ytd:{},pyr:{},l1y:{},l3y:{},l5y:{},l7y:{},l10y:{},l15y:{},l20y:{},l25y:{},itd:{}}});
      }
    }).catch(err=>{
      this.setState({loading:false});
      console.log(err);
    });
  }

  formatCurrency(val){
    if(isNaN(val))
      return '-';

    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    return formatted;
  }

  formatPercent(val){
    if(val)
      return val.toFixed(2)+"%";
    else
      return '-';
  }

  getColor(val){
    if(!val || isNaN(val))
      return 'black';
    else if(val>0)
      return '#0BD318';
    else
      return '#FF3B30';
  }

  render() {
    return(



        <div className='acctSummary' style={{paddingRight:this.props.padding, paddingLeft:this.props.padding}}>
        <table className='bottomBorders'>
                <tbody>
                    <tr>
                        <td className='heading'>Account Summary</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><select onChange={(e)=>this.setState({periodOne:e.target.value})} value={this.state.periodOne}>
                              {this.state.periods.map((per, i)=>(
                                <option value={per.value} key={i}>{per.name}</option>
                              ))}
                            </select>
                        </td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><select onChange={(e)=>this.setState({periodTwo:e.target.value})} value={this.state.periodTwo}>
                              {this.state.periods.map((per, i)=>(
                                <option value={per.value} key={i}>{per.name}</option>
                              ))}
                            </select>
                        </td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><select onChange={(e)=>this.setState({periodThree:e.target.value})} value={this.state.periodThree}>
                              {this.state.periods.map((per, i)=>(
                                <option value={per.value} key={i}>{per.name}</option>
                              ))}
                            </select>
                        </td> : null}
                    </tr>
                    <tr>
                        <td>Period</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].beginDate : "N/A"} - {this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].endDate : this.state.date}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].beginDate : "N/A"} - {this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].endDate : this.state.date}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].beginDate : "N/A"} - {this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].endDate : this.state.date}</span></td> : null}
                    </tr>
                    <tr>
                        <td><span className='indent'>Name</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.state.portfolio.displayName.split(' -')[0] || this.state.portfolio.displayName || this.state.portfolio.account}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.state.portfolio.displayName.split(' -')[0] || this.state.portfolio.displayName || this.state.portfolio.account}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.state.portfolio.displayName.split(' -')[0] || this.state.portfolio.displayName || this.state.portfolio.account}</span></td> : null}
                    </tr>
                    <tr>
                        <td>Beginning Value</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].beginMV : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].beginMV : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].beginMV : 0)}</strong></td> : null}
                    </tr>
                    <tr>
                        <td>Beginning Accrued Interest</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].beginAI : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].beginAI : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].beginAI : 0)}</strong></td> : null}
                    </tr>
                    {!this.props.styleSettings.accountSummaryshowNetCW && <><tr>
                        <td><span className='indent'>Additions</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].additions : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].additions : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].additions : 0)}</span></td> : null}
                    </tr>
                    {this.props.styleSettings.accountSummaryShowTransfers && <tr>
                        <td><span className='indent'>Transfers In</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].transfersIn : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].transfersIn : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].transfersIn : 0)}</span></td> : null}
                    </tr>}
                    <tr>
                        <td><span className='indent'>Withdrawals</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].withdrawals : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].withdrawals : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].withdrawals : 0)}</span></td> : null}
                    </tr>
                    {this.props.styleSettings.accountSummaryShowTransfers && <tr>
                        <td><span className='indent'>Transfers Out</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].transfersOut : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].transfersOut : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].transfersOut : 0)}</span></td> : null}
                    </tr>}
                    </>}
                    {this.props.styleSettings.accountSummaryshowNetCW && <tr>
                        <td><span className='indent'>Net Contributions/Withdrawals</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].netCW : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].netCW : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].netCW : 0)}</span></td> : null}
                    </tr>}
                    {!this.props.styleSettings.showIncome && <><tr>
                        <td><span className='indent'>Interest</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].interest : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].interest : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].interest : 0)}</span></td> : null}
                    </tr>
                    <tr>
                        <td><span className='indent'>Dividends</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].dividends : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].dividends : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].dividends : 0)}</span></td> : null}
                    </tr>
                    <tr>
                        <td><span className='indent'>Accrued Interest</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].deltaAI : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].deltaAI : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].deltaAI : 0)}</span></td> : null}
                    </tr>
                    </>}
                    {this.props.styleSettings.showIncome && <tr>
                        <td><span className='indent'>Income</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].income : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].income : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].income : 0)}</span></td> : null}
                    </tr>}
                    {!this.props.styleSettings.showInvstGL && <><tr>
                        <td><span className='indent'>Realized Gains</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].realizedGains : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].realizedGains : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].realizedGains : 0)}</span></td> : null}
                    </tr>
                    <tr>
                        <td><span className='indent'>Unrealized Gains</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].unrealizedGains : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].unrealizedGains : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].unrealizedGains : 0)}</span></td> : null}
                    </tr>
                    </>}
                    {this.props.styleSettings.showInvstGL && <tr>
                        <td><span className='indent'>Investment Gain/Loss</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].investGL : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].investGL : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].investGL : 0)}</span></td> : null}
                    </tr>}
                    {this.state.perfType!=='gross' && <>
                    <tr>
                        <td><span className='indent'>Management Fees</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].manfees : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].manfees : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].manfees : 0)}</td> : null}
                    </tr>
                    <tr>
                        <td><span className='indent'>Portfolio Fees</span></td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].portFees : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].portFees : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].portFees : 0)}</td> : null}
                    </tr></>}
                    <tr>
                        <td>End Value</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].endMV : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].endMV : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].endMV : 0)}</strong></td> : null}
                    </tr>
                    <tr>
                        <td>End Accrued Interest</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].endAI : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].endAI : 0)}</strong></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><strong>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].endAI : 0)}</strong></td> : null}
                    </tr>
                    {this.props.styleSettings.showFeesPaid && this.state.perfType!=='gross' && <><tr>
                        <td>Management Fees Paid</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].manFeesClient : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].manFeesClient : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].manFeesClient : 0)}</td> : null}
                    </tr>
                    <tr>
                        <td>Portfolio Fees Paid</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].portFeesClient : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].portFeesClient : 0)}</td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].portFeesClient : 0)}</td> : null}
                    </tr>
                    </>}
                    {this.props.styleSettings.accountSummaryShowBenefit && <tr>
                        <td>Benefit for the period</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodOne] ? (this.state.accountSummary[this.state.periodOne].endMV-this.state.accountSummary[this.state.periodOne].beginMV) : 0)}}>{this.formatCurrency(this.state.accountSummary[this.state.periodOne] ? (this.state.accountSummary[this.state.periodOne].endMV-this.state.accountSummary[this.state.periodOne].beginMV) : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodTwo] ? (this.state.accountSummary[this.state.periodTwo].endMV-this.state.accountSummary[this.state.periodTwo].beginMV) : 0)}}>{this.formatCurrency(this.state.accountSummary[this.state.periodTwo] ? (this.state.accountSummary[this.state.periodTwo].endMV-this.state.accountSummary[this.state.periodTwo].beginMV) : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodThree] ? (this.state.accountSummary[this.state.periodThree].endMV-this.state.accountSummary[this.state.periodThree].beginMV) : 0)}}>{this.formatCurrency(this.state.accountSummary[this.state.periodThree] ? (this.state.accountSummary[this.state.periodThree].endMV-this.state.accountSummary[this.state.periodThree].beginMV) : 0)}</span></td> : null}
                    </tr>}
                    {this.props.styleSettings.showAcctSummIrr && <tr>
                        <td>IRR</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].irr : 0)}}>{this.formatPercent(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].irr : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].irr : 0)}}>{this.formatPercent(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].irr : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].irr : 0)}}>{this.formatPercent(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].irr : 0)}</span></td> : null}
                    </tr>}
                    {this.props.styleSettings.showAcctSummTwr && <tr>
                        <td>Time-Weighted Return</td>
                        {this.props.styleSettings.acctPeriod1 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].twr : 0)}}>{this.formatPercent(this.state.accountSummary[this.state.periodOne] ? this.state.accountSummary[this.state.periodOne].twr : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod2 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].twr : 0)}}>{this.formatPercent(this.state.accountSummary[this.state.periodTwo] ? this.state.accountSummary[this.state.periodTwo].twr : 0)}</span></td> : null}
                        {this.props.styleSettings.acctPeriod3 ? <td align='right'><span style={{color:this.getColor(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].twr : 0)}}>{this.formatPercent(this.state.accountSummary[this.state.periodThree] ? this.state.accountSummary[this.state.periodThree].twr : 0)}</span></td> : null}
                    </tr>}
                </tbody>
            </table>
            {this.state.loading && <GridLoading gridClass='assetAllGrid'/>}
        </div>
    );
  }


}

export default AccountSummary;