import React, { Component } from 'react';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react";



class JoditHTMLEditor extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            content:'',
            currTemplate:this.props.template
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        //clear content if template changes
        if(nextProps.template!==prevState.currTemplate && !nextProps.editTemp){
            return({content:''});
        }
        else{
          return null;
        }
    }

    updateContent = (value) => {
        this.setState({content:value});
        this.props.onHTMLChange(value);
    }
    /**
     * @property Jodit jodit instance of native Jodit
     */
	jodit;
    setRef = jodit => this.jodit = jodit;
	
	config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        buttons: ['source','|','bold','strikethrough','underline','italic','|','superscript','subscript',
            '|','ul','ol','|','outdent','indent','|','font','fontsize','brush','paragraph','|','image','file',
            'video','table','link','|','align','undo','redo','\n','cut','hr','eraser','copyformat',
            '|','symbol','fullsize','selectall','print'],
        height: '100%',
        // enableDragAndDropFileToEditor: true,
        // uploader: {
        //     url: 'api/test',
        //     format: 'json',
        //     pathVariableName: 'path',
        //     filesVariableName: 'images',
        //     prepareData: function (data) {
        //         //append form data
        //         //data.append('test','test');
        //         return data;
        //     },
        //     isSuccess: function (resp) {
        //         return !resp.error;
        //     },
        //     getMsg: function (resp) {
        //         return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
        //     },
        //     process: function (resp) {
        //         console.log(resp);
        //         return {
        //             files: resp[this.options.filesVariableName] || [],
        //             path: resp.path,
        //             baseurl: resp.baseurl,
        //             error: resp.error,
        //             msg: resp.msg
        //         };
        //     },
        //     error: function (e) {
        //         alert(e);
        //         //this.events.fire('errorPopap', [e.getMessage(), 'error', 4000]);
        //     },
        //     defaultHandlerSuccess: function (data, resp) {
        //         var i, field = this.options.filesVariableName;
        //         if (data[field] && data[field].length) {
        //             for (i = 0; i < data[field].length; i += 1) {
        //                 //this.selection.insertImage(data.baseurl + data[field][i]);
        //                 this.selection.insertImage('http://localhost:3000/Logos/cssia34654b0-6a89-11e9-bb82-41cb7562998d.png');
        //             }
        //         }
        //     },
        //     defaultHandlerError: function (resp) {
        //         alert(this.options.uploader.getMsg(resp));
        //         //this.events.fire('errorPopap', [this.options.uploader.getMsg(resp)]);
        //     }
        // }
    }
    
    render() {
        return (
            <JoditEditor
            	editorRef={this.setRef}
                value={this.state.content || this.props.template}
                config={this.config}
                onChange={this.updateContent}
            />
        );
    }

}

export default JoditHTMLEditor;