import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Upload } from '@progress/kendo-react-upload';
import axios from 'axios';

class ReportLogosPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            filePath: '',
            displayName: '',
            description: '',
            initialLoad: true,
            files: [],
            company: ''
        }
    }

    onFieldChange = (e, field) => {
        this.setState({ [field]: e.target.value });
    }


    getEditField = (field) => {
        if (this.props.reportLogoEdit && this.state.initialLoad) {
            this.setState({
                filePath: this.props.reportLogoEdit.filePath,
                company: this.props.user.company,
                displayName: this.props.reportLogoEdit.displayName,
                description: this.props.reportLogoEdit.description,
                initialLoad: false
            });
            return this.props.reportLogoEdit[field];
        }
        else {
            return '';
        }
    }

    uploadReportLogo = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            filePath: this.state.filePath,
            company: this.state.company,
            displayName: this.state.displayName,
            description: this.state.description,
            uploadedBy: this.props.uploadUser.user
        }
        //add id field if editing template
        if (this.props.reportLogoEdit) {
            payload.reportLogoEdit = true;
            payload.id = this.props.reportLogoEdit.id
        }

        axios.post("api/uploadReportLogo", payload, { headers }).then(response => {
            if (response.data.code === 301) {
                alert("Error: A logo with the same filename already exists!");
            }
            else if (response.data.code === 302) {
                alert("Error: Unable to use this logo name.");
            }
            else {
                if (response.data.code === 200) {
                    alert("Logo created successfully.");
                }
                else if (response.data.code === 201) {
                    alert("Logo updated successfully.");
                }
                else {
                    alert("An error occured with the logo.");
                }
                this.props.uploadMethod();
                this.clearState();
                callback();
            }
        }).catch(err => { alert("An error occured with the template."); console.log(err); this.props.uploadMethod(); this.clearState(); callback(); });
    }

    clearState = () => {
        this.setState({
            id: 0,
            filePath: '',
            displayName: '',
            description: '',
            company: '',
            files: [],
            initialLoad: true
        })
    }

    onAdd(event) {
        this.setState({
            files: event.newState,
            displayName: event.newState[0].name,
        });
    }

    onRemove(event) {
        this.setState({
            files: event.newState,
            displayName: '',
        });
    }

    onBeforeUpload = (event) => {
        event.additionalData.filePath = event.files[0].name;
        event.additionalData.username = this.props.user;
        event.additionalData.company = 'logos';
        event.additionalData.type = 'logo';
        event.additionalData.logoCompany = this.props.user.company;
        event.additionalData.displayName = this.state.displayName;
        event.additionalData.description = this.state.description;
        event.additionalData.uploadedBy = this.props.uploadUser.user;
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">

                        <Button className="close" icon="close" onClick={(e) => { close(); }} />


                        <div className="header" align="left">
                            {this.props.reportLogoEdit ? 'Edit Logo' : 'New Logo'}
                        </div>

                        <div className="content">
                            {/* <div>
                                <label>Description: </label><br />
                                <textarea id="description" name="description" rows="5" style={{ width: '95%' }} value={this.state.description || this.getEditField('description')} onChange={(e) => this.onFieldChange(e, 'description')} placeholder={""} ></textarea>
                            </div> */}
                            <div>
                                <label>Please select a .jpg or .png to upload. High Quality images render best.</label><br />
                                <Upload batch={true}
                                    autoUpload={false}
                                    multiple={false}
                                    files={this.state.files}
                                    restrictions={{
                                        allowedExtensions: [".jpg", ".png", ".jpeg"],
                                    }}
                                    onAdd={(e) => this.onAdd(e)}
                                    onRemove={(e) => this.onRemove(e)}
                                    onBeforeUpload={this.onBeforeUpload}
                                    onStatusChange={(event) => {
                                        var msg = true;
                                        if (event.response !== undefined && parseInt(event.response.response.code) === 200) {
                                            this.setState({
                                                files: [],
                                                company: this.props.user.company,
                                                filePath: event.response.response.newlogo,
                                                initialLoad: true
                                            }, () => this.uploadReportLogo(close));
                                            if (!msg) { alert("File(s) were successfully uploaded!"); msg = true; };
                                        }
                                        else if (event.response !== undefined) {
                                            alert("Files could not be uploaded successfully. Please try again.");
                                        }
                                    }}
                                    saveUrl={"api/reportLogoUpload?company="+this.props.user.company}
                                    saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                />
                            </div>
                        </div>

                        {/* <div className="actions">
                            {this.props.reportLogoEdit && this.state.files.length === 0 && <Button
                                className="upload"
                                onClick={(event) => { this.uploadReportLogo(close) }}>
                                {this.props.reportLogoEdit ? 'Update' : 'Create'}
                            </Button>}
                        </div> */}

                    </div>
                )}
            </Popup>

        );
    };
}

export default ReportLogosPopup;