import React from 'react';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import GridLoading from '../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import { DropDownFilterCell } from '../UserList/components/DropDownFilterCell';
import { process } from '@progress/kendo-data-query';

const yesNoFilterCell = (props) => (
    <DropDownFilterCell {...props} fieldType="yesNo" data={["(All)", "Yes", "No"]} />
);

class UserAcceptance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            banners: [],
            result: [],
            contentSelected: false,
            reportSelect: '',
            changes: false,
            reportsLoading: true,
            gridFilter: undefined,
            filterable: props.styleSettings.showFilters,
            reportStyles: [],
            dataState: { skip: 0, take: 25, sort: [{ field: 'termsAccepted', dir: 'desc' }] },
            fileDownload: [],
            fileSelected: false
        };

    }

    componentDidMount = () => {
        this.getBannerList();
    }

    getBannerList = () => {
        this.setState({ refreshText: 'Loading...', reportSelected: false, reportSelect: '', });
        var banners = [];


        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/getTermsAcceptanceList`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(bannerInResponse => {
                    bannerInResponse.selected = false
                    banners.push(bannerInResponse)
                })

                this.setState((prevState) => {
                    let obj = { ...prevState }
                    obj.banners = banners;
                    obj.result = process(banners, this.state.dataState);
                    obj.refreshText = '';
                    return obj;
                })

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }

    selectionChange = (event, gFiles) => {
        console.log(event, gFiles)
        gFiles.forEach(item => {
            if (item.BannerCode !== event.dataItem.BannerCode) {
                item.selected = false
            }
        });
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles }, () => { this.forceUpdate(); });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    refreshBanners = () => {
        this.setState({ fileDownload: [] });
        this.setState({ fileSelected: false });
        this.getBannerList();
    }

    resetAcceptance = (allUsers) => {
        var message = ''

        if (allUsers === true) {
            message = 'This will reset the Terms and Conditions acceptance for ALL USERS. Are you sure?'
        }
        else {
            message = 'This will reset the Terms and Conditions acceptance for the selected users. Are you sure?'
        }

        var confirm = window.confirm(message);

        if (confirm) {
            // console.log(this.state.fileDownload)
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            var payload = {
                users: this.state.fileDownload,
                company: this.props.user.company,
            };

            if (allUsers === true) {
                payload.users = []
            }

            axios.post('api/resetAcceptance', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getBannerList();
                }
                else {
                    alert("Error: User(s) could not be updated.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getBannerList();
                }
            }).catch(e => console.log(e));
        }
    }


    getDate = (sqlDate, isUTC) => {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        if (isUTC)
            return utc;
        else
            return d;
    }

    onLinkClick = (event, rep, idToken) => {
        const headers = { 'authorization': 'Bearer '.concat(idToken) };
        axios
            .get("api/download?company=" + this.props.user.company + "&path=" + rep.contentPath + "&name=" + rep.contentName, { headers })
            .then((response) => {
                window.open(response.data, '_blank');
            })
            .catch((e) => {
                console.error("ERROR");
                return false;
            });

        return false;
    }

    onDelete = (e) => {

        var confirm = window.confirm("Are you sure you want to delete this terms and conditions banner?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                banners: this.state.fileDownload,
                company: this.props.user.company,
            };
            axios.post('api/deleteBanners', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getBannerList();
                }
                else {
                    alert("Error: Disclaimer could not be deleted.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getBannerList();
                }
            }).catch(e => console.log(e));
        }
    }

    onSetBanner = (e) => {

        var confirm = window.confirm("Are you sure? This will be the banner that users see upon logging in");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                banners: this.state.fileDownload,
                company: this.props.user.company,
            };
            axios.post('api/setBanner', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getBannerList();
                }
                else {
                    alert("Error: Disclaimer could not be deleted.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getBannerList();
                }
            }).catch(e => console.log(e));
        }
    }

    headerCellRender = (thElement, cellProps) => {
        if (thElement !== null) {
            if (cellProps.field === 'selected')
                return thElement
            else {
                return thElement
            }
        }
        else {
            return thElement;
        }
    }

    changeDataState = (e) => {
        var data = { ...e.data }
        if (data.filter != null && data.filter.filters != null) {
            data.filter.filters.forEach(item => {
                if (item.field === 'termsAccepted' && item.value === 'Yes') { item.value = true; }
                if (item.field === 'termsAccepted' && item.value === 'No') { item.value = false; }
            })
            data.filter.filters = data.filter.filters.filter(item => item.value !== '(All)');
        }
        var newData = process(this.state.banners, data);
        this.setState({ dataState: data, result: newData });
    }

    render() {
        console.log('render', this.state.banners.findIndex(dataItem => dataItem.selected === false), this.state.banners.length)
        return (
            <div className='termsandconditions'>

                <Grid data={this.state.result}
                    onSelectionChange={(e) => this.selectionChange(e, this.state.banners)}
                    onRowClick={(e) => this.selectionChange(e, this.state.banners)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.banners)}
                    resizable={true}
                    selectedField='selected'
                    sortable={true}
                    onDataStateChange={this.changeDataState}
                    filterable={this.state.filterable}
                    headerCellRender={this.headerCellRender}
                    {...this.state.dataState}
                >
                    <GridToolbar>
                        <Button icon='refresh' title='Refresh' onClick={this.refreshBanners}>{this.state.refreshText}</Button>
                        <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                        <Button title='Reset for Selected User(s)' onClick={(e) => this.resetAcceptance()}>Reset for Selected User(s)</Button>
                        <Button title='Reset for All Users' onClick={(e) => this.resetAcceptance(true)}>Reset for All Users</Button>
                    </GridToolbar>
                    <GridColumn
                        width='50px'
                        field="selected"
                        filterable={false}
                        headerClassName='gridHeader'
                        editor="boolean"
                        headerSelectionValue={this.state.banners.findIndex(dataItem => dataItem.selected === false) === -1 && this.state.banners.length > 0}
                    />
                    <GridColumn
                        // width='300px'
                        field="name"
                        title="User"
                        headerClassName='gridHeader'
                    />
                    <GridColumn
                        width='160px'
                        field="termsAccepted"
                        headerClassName='gridHeader'
                        title="Terms Accepted"
                        filterable={true}
                        filter="boolean"
                        filterCell={yesNoFilterCell}
                        cell={(props) =>
                            <td>
                                {props.dataItem.termsAccepted ? 'Yes' : 'No'}
                            </td>
                        }
                    />
                </Grid>
                {this.state.refreshText && <GridLoading gridClass='webRep' />}
            </div>
        )
    }

}

export default UserAcceptance