import React, { Component } from "react";
import { MultiSelect } from '@progress/kendo-react-dropdowns';

class MultiDropDown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      default: props.default,
      items:props.items,
      value:''
    }

    this.getValue = this.getValue.bind(this);
  }

  onChange = (e)=>{
    this.setState({value:e.target.value});
    var arr = e.target.value;
    var valString = '';
    for(let i=0; i<arr.length; i++){
        if(i!==arr.length-1)
            valString = valString+arr[i].value+',';
        else
            valString = valString+arr[i].value;
    }
    this.props.onChange(valString);
  }

  getValue(){
    var vals = [];
    if(this.props.value){
        var propVals = this.props.value.split(',');   
        for(let i=0; i<propVals.length; i++){
            var index = this.props.items.findIndex(x=>{
                return x.value===propVals[i];
            });
            if(index>=0)
                vals.push(this.props.items[index]);
        }
    }
    return vals;
  }

  render() {
    return (
      <>
        <MultiSelect data={this.props.items}
            autoClose={false}
            textField="text"
            value={this.getValue()}
            onChange={this.onChange}
            popupSettings={style}
            placeholder={this.props.placeholder}
        />
      </>
    );
  }
}


const style = {
  className: "top",
};
export default MultiDropDown;