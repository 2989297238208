import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import ClientGrid from './components/ClientGrid';
import axios from 'axios';

class AdvisorRelations extends Component {

  constructor(props) {
    super(props);
    this.state = {
        advisors:[]
    }

    this.getAdvisors = this.getAdvisors.bind(this);
  }

  componentDidMount(){
    this.getAdvisors();
  }

  getAdvisors(){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/advisors?company="+this.props.user.company, {headers}).then(response => {
      if(response.data.code===200){
        this.setState({advisors:response.data.advisors});
      }
      else{
        alert("An error occured while fetching the advisor list.");
      }
    }).catch(error => console.log(error));
  }

  expandChange = (e) =>{
    e.dataItem.expanded = e.value;
    if(e.value){
        e.dataItem.idToken = this.props.idToken;
        e.dataItem.loggedInUser = this.props.user;
        e.dataItem.refresh = this.getAdvisors;
      }
      else{
        e.dataItem.idToken=null;
        e.dataItem.loggedInUser=null;
        e.dataItem.refresh = null;
      }
    this.setState({ ...this.state });
  }

  render() {
    return(
        <div className='advisorRelations'>
        <Grid data={this.state.advisors}
                detail={ClientGrid}
                expandField='expanded'
                onExpandChange={this.expandChange}>
                <GridToolbar>
               </GridToolbar>
                <GridColumn field='email' title='Advisor'/>
        </Grid>
        </div>
    );
  }

}

export default AdvisorRelations;