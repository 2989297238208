import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import securityClassificationMembersFields from './securityClassificationMembersFields.json';

class SecurityClassificationMembersMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:{
                ClassificationCode:'',
                ClassificationDescription:'',
                ClassificationMemberCode:'',
                ClassificationMemberDescription:'',
                ClassificationMemberSortOrder:''
                }
    }

    this.getMappings = this.getMappings.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount(){
      this.getMappings();
  }

  onInputChange(e, attr){
    let mappings = {...this.state.mappings};
    mappings[attr] = e.target.value.toUpperCase();
    this.setState({mappings});
  }

  uploadMappings = (e)=>{

    if(this.state.mappings.ClassificationCode===null || this.state.mappings.ClassificationCode==='')
        alert("ClassificationCode is required.");
    else if(this.state.mappings.ClassificationDescription===null || this.state.mappings.ClassificationDescription==='')
        alert("ClassificationDescription is required.");
    else if(this.state.mappings.ClassificationMemberCode===null || this.state.mappings.ClassificationMemberCode==='')
        alert("ClassificationMemberCode is required.");
    else if(this.state.mappings.ClassificationMemberDescription===null || this.state.mappings.ClassificationMemberDescription==='')
        alert("ClassificationMemberDescription is required.");
    else{
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload ={
            company:this.props.user.company,
            mappings:this.state.mappings
        }
        axios.post('api/securityClassificationMembersMappings', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Security Classification Members Mappings have been updated.");
            this.getMappings();
        }
        else
            alert("An error occured when trying to update mappings");
        }).catch(err=>{
        alert("An error occured when trying to update mappings");
        this.getMappings();
        });
    }
  }

  getMappings(){
    var emptyMappings = {
        ClassificationCode:'',
        ClassificationDescription:'',
        ClassificationMemberCode:'',
        ClassificationMemberDescription:'',
        ClassificationMemberSortOrder:''
    }
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/securityClassificationMembersMappings?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.mappings.length===0)
          this.setState({mappings:emptyMappings});
      else
        this.setState({mappings:response.data.mappings[0]});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:emptyMappings})
    });
  }


  render() {
    var data = securityClassificationMembersFields;
    return(
        <div>
            <table className='posMapTable'>
                <tbody>
                    <tr>
                        <td className='heading'>Field Name <hr/></td>
                        <td align='right' className='heading'>Column Letter in Excel <hr/></td>
                    </tr>
                    {data.map(field => (
                    <tr key={field.id}>
                        <td><h4>{field.name}</h4><div>{field.desc}</div></td>
                        <td align='right'><input type='text' value={this.state.mappings[field.dbField]} onChange={e=>this.onInputChange(e,field.dbField)}/></td>                  
                    </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td align='right'><Button onClick={(e)=>this.uploadMappings(e)}>Apply Changes</Button></td>
                    </tr> 
                </tbody>
            </table>
        </div>
    );
  }

}

export default SecurityClassificationMembersMapping;