
import React from 'react';
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy, process } from '@progress/kendo-data-query';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from '@progress/kendo-react-inputs';
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../Loaders/GridLoading';
import ReportListPopup from './components/ReportListPopup.js';
import createFilterDropDownCell from './components/FilterDropDownCell';
import ReportDefaultPopup from './components/ReportDefaultPopup';

const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class ReportList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            data: [],
            editField: undefined,
            reportSelected: false,
            reportSelect: '',
            changes: false,
            reportsLoading: true,
            globalFeeMethod: {value: "0", label: "Gross"},
            globalDisclaimer: "No Disclaimer",
            globalLayout: {value: "l", label: "Landscape"},
            globalFeeMethodChecked: false,
            globalDisclaimerChecked: false,
            globalLayoutChecked: false,

            disclaimersList: [],
            assetClassList: [],

            gridFilter: undefined,
            filterable: false,
            reportStyles: ['Standard', 'Custom'],
            sort: [{ field: 'name', dir: 'asc' }],

            //
            runReportButtonFlag: false,

            layout: null,
            reports: [],
            report: { name: "Select Report", key: null, adjustableLayout: null },
            entities: [],
            loading: false,
            account: { account: "", displayName: "" },
            numPages: null,
            pageNumber: 1,
            pdfFile: null,
            pdfFlag: false,
            pdfScale: 1.25,
            status: "",
            loaderSpinnerFlag: false,
            checkPdfBlobTimerId: null,//used in componentWillUnmount cleanup
            generateExcelButtonFlag: true,
            generateCsvButtonFlag: true,
            reportParameters: [],
            reportParametersFlag: false,
            parameterList: [],
            defaultList: [],
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
            data:[],
            dataState:{skip:0, take:10000, sort:[{ field: 'type', dir: 'asc' }]},
        };

        //this.renderers = new Renderers(this.enterEdit, this.exitEdit, 'inEdit');
        this.getReportList = this.getReportList.bind(this);
        this.getParameterDefaults = this.getParameterDefaults.bind(this);
        this.headerCellRender = this.headerCellRender.bind(this);
        this.getDisclaimers = this.getDisclaimers.bind(this);
        this.createCell = this.createCell.bind(this);
        this.getAssetClassDefaults = this.getAssetClassDefaults.bind(this);
    }

    componentDidMount() {
        this.getGlobalParameterDefaults();
        this.getParameterDefaults();
        this.getDisclaimers()
        this.getAssetClassDefaults();

    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => item.selected = false);
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0] });
        if (selectedFiles.length > 0) {
            this.setState({ reportSelected: true });
        }
        else {
            this.setState({ reportSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        // const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = false);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0], reportSelected: false });

        this.forceUpdate();
    }



    getDisclaimers() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var disclaimers = []

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetDisclaimers`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(disclaimerInResponse => {
                    disclaimers.push(disclaimerInResponse.DisclaimerCode)
                })
                console.log(disclaimers)
                this.setState({ disclaimersList: disclaimers })
            })
            .catch(err => {
                console.error(err);
            })
    }

    resetAllParameters() {
        let confirm = prompt("Type \"RESET ALL\" to confirm", "");

        if (confirm && confirm.toLowerCase() === "reset all") {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            var payload = {
              company: this.props.user.company,
            }
        
        
            axios.post('api/ssrsDeleteParameterDefaults', payload, { headers }).then(response => {
                if (response.data.code === 200){
                    alert("Reset Successful")
                    this.getParameterDefaults();
                }
                else{
                    alert("Error deleting parameter defaults")
                }
            }).catch(err => {
              console.log(err);
              alert("Error deleting parameter defaults")
            });
        }
        else if (confirm === ""){
            alert("\"RESET ALL\" was not entered. Parameter defaults were not deleted")
        }
        else if (confirm === null){
            //nothing
        }
        else{
            alert("\"RESET ALL\" was not entered. Parameter defaults were not deleted")
        }

    }

    getParameterDefaults() {
      this.setState({loading: true})
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var parameterList = []
        //parameterList = parameterList.concat(this.state.parameterList)
        //var originalParameterList = JSON.parse(JSON.stringify(parameterList)) 
        axios(`api/ssrsGetParameterDefaults`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers
        })
          .then(response => {
    
            if (response.data.code === 200) {
                parameterList = response.data.data
                this.setState({ parameterList: parameterList })
                this.getReportList(parameterList);

            }
            else {
              console.log("Error getting report defaults")
            }
          })
          .catch(error => {
            console.error(error);
          });
      }

      getGlobalParameterDefaults() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var parameterList = []
        //parameterList = parameterList.concat(this.state.parameterList)
        //var originalParameterList = JSON.parse(JSON.stringify(parameterList)) 
        axios(`api/ssrsGetGlobalParameterDefaults`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers
        })
          .then(response => {
    
            if (response.data.code === 200) {
                response.data.data.forEach(globalDefault =>{
                    if (globalDefault.paramName && globalDefault.paramName.toLowerCase() === "feemethod"){
                        this.setState({
                            globalFeeMethod: (globalDefault.value === "0" ? {value: "0", label: "Gross"} : {value: "1", label: "Net"}), 
                            globalFeeMethodChecked: globalDefault.active
                        })
                    }
                    else if (globalDefault.paramName && globalDefault.paramName.toLowerCase() === "layout"){
                        this.setState({
                            globalLayout: (globalDefault.value === "l" ? {value: "l", label: "Landscape"} : {value: "p", label: "Portrait"}), 
                            globalLayoutChecked: globalDefault.active
                        })
                    }
                    else if (globalDefault.paramName && globalDefault.paramName.toLowerCase() === "disclaimer"){
                        this.setState({
                            globalDisclaimer: globalDefault.value, 
                            globalDisclaimerChecked: globalDefault.active
                        })
                    }
                })
                // parameterList = response.data.data
                // this.setState({ parameterList: parameterList })
                // this.getReportList(parameterList);

            }
            else {
              console.log("Error getting report defaults")
            }
          })
          .catch(error => {
            console.error(error);
          });
      }



    getAssetClassDefaults() {
        // var assetClassDefaults = []
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsAssetClassDefaults`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                var assetClasses = []

                response.data.data.forEach(assetClassInResponse => {
                    //assetClasses.push(assetClassInResponse.AssetClassCode)
                    assetClasses.push(assetClassInResponse.label)
                })
                //this.setState({ assetClassList: assetClasses})
                this.setState({ assetClassList: assetClasses })
            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getReportList(parameterList) {
        var reports = [];
        var reportNames = [];
        var splitName;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetReports`, {
          method: 'GET',
          params: { folder: 'ExampleSSRSProject', company: this.props.user.company, standardAndCustom: true },
          headers: headers,
        })
          .then(response => {
            var folder
            response.data.data.forEach(reportInResponse => {
              folder = reportInResponse.path.split('/')[1]
    
              if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
                splitName = reportInResponse.name.split('_')
                if (!reportNames.includes(splitName[0])) {
                  reportNames.push(splitName[0]);
                  reports.push({ name: splitName[0], key: reportInResponse.key, paramString: this.getDefaultParamString(splitName[0], parameterList), adjustableLayout: true, folder: folder, type: reportInResponse.reportType })
                }
              }
              else {
                reports.push({ name: reportInResponse.name, paramString: this.getDefaultParamString(reportInResponse.name, parameterList), key: reportInResponse.key, folder: folder, type: reportInResponse.reportType })
              }
    
    
            })
            var data = process(reports, this.state.dataState)
            this.setState({ reports: reports, data: data, loading: false })
    
          })
          .catch(err => {
            console.error(err);
          })
        //return reports;
    
      }

      getDefaultParamString(report, parameterList){
        var s = ""
        parameterList.forEach(p => {
            if (p.reportName === report){
                if (s != ""){ s += "; " }
                s += (p.label + ": " + (p.valueText || p.value))
            }
        })
        return s
      }

    // getReportList() {
    //     this.setState({ refreshText: 'Loading...', reportSelected: false, reportSelect: '', });
    //     var reports = [];
    //     var reportStyles = [];
    //     var reportStylesUnique = [];

    //     const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    //     //axios
    //     //.post('api/ssrsGetReports', payload, { headers })
    //     axios(`api/ssrsGetReportListWithDefaults`, {
    //         method: 'GET',
    //         params: { company: this.props.user.company },
    //         headers: headers,
    //     })
    //         .then(response => {


    //             response.data.data.forEach(reportInResponse => {
    //                 //if (reportInResponse.Path.includes('/ExampleSSRSProject')) {
    //                 //    standardOrCustom = "Standard Report"
    //                 //}
    //                 //else {
    //                 //    standardOrCustom = "Custom Report"
    //                 //}
    //                 //
    //                 //var name = reportInResponse.name
    //                 //if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
    //                 //    splitName = reportInResponse.name.split('_')
    //                 //    if (!reportNames.includes(splitName[0])) {
    //                 //        reportNames.push(splitName[0]);
    //                 //        name = splitName[0]
    //                 //        //reports.push({ name: splitName[0], key: reportInResponse.key, adjustableLayout: true, type: standardOrCustom })
    //                 //    }
    //                 //}
    //                 //else {
    //                 //    name = reportInResponse.name
    //                 //    //reports.push({ name: name, key: reportInResponse.key, type: standardOrCustom })
    //                 //}
    //                 //reports.push({ name: name, key: reportInResponse.key, type: standardOrCustom })

    //                 if(reportStylesUnique.indexOf(reportInResponse.type) === -1) {
    //                     reportStyles.push({text: reportInResponse.type});
    //                     reportStylesUnique.push(reportInResponse.type);
    //                 }

    //                 if (reportInResponse.reportName === 'Equity Overview'){
    //                     reports.push({
    //                         selected: false,
    //                         reportDefaults: "Equity Asset Class: d - Domestic Equity; Include Portfolio Code: On",
    //                         reportName: reportInResponse.reportName,
    //                         type: reportInResponse.type,
    //                         reportTitle: reportInResponse.reportTitle,
    //                         layout: reportInResponse.layout,
    //                         disclaimer: reportInResponse.disclaimer,
    //                         feeMethod: reportInResponse.feeMethod,
    //                         period1: reportInResponse.period1,
    //                         period2: reportInResponse.period2,
    //                         reportTitle1: reportInResponse.reportTitle1,
    //                         reportTitle2: reportInResponse.reportTitle2,
    //                         reportTitle3: reportInResponse.reportTitle3,
    //                         reportTitle4: reportInResponse.reportTitle4,
    //                         equityAssetClass: reportInResponse.equityAssetClass,
    //                         securityClassification: reportInResponse.securityClassification,
    //                         includeAI: reportInResponse.IncludeAI,
    //                         companyName: reportInResponse.CompanyName,
    //                         includePortfolioCode: reportInResponse.IncludePortfolioCode,
    //                         bondDescription: reportInResponse.bondDescription,
    //                         includeSP: reportInResponse.IncludeSP,
    //                         includeMoody: reportInResponse.IncludeMoody,
    //                         includeFitch: reportInResponse.IncludeFitch,
    //                         showCash: reportInResponse.showCash,
    //                         AccountNumber: reportInResponse.AccountNumber,
    //                         ShowAuthorization: reportInResponse.ShowAuthorization,
    //                         Rounding: reportInResponse.Rounding,
    //                         BillFor: reportInResponse.BillFor
    //                     })
    //                 }
    //                 else if (reportInResponse.reportName === 'Portfolio Appraisal'){
    //                     reports.push({
    //                         selected: false,
    //                         reportDefaults: "Date: edlq - Ending Day Last Quarter; Report Title: Appraisal; First Group Level: Sector",
    //                         reportName: reportInResponse.reportName,
    //                         type: reportInResponse.type,
    //                         reportTitle: reportInResponse.reportTitle,
    //                         layout: reportInResponse.layout,
    //                         disclaimer: reportInResponse.disclaimer,
    //                         feeMethod: reportInResponse.feeMethod,
    //                         period1: reportInResponse.period1,
    //                         period2: reportInResponse.period2,
    //                         reportTitle1: reportInResponse.reportTitle1,
    //                         reportTitle2: reportInResponse.reportTitle2,
    //                         reportTitle3: reportInResponse.reportTitle3,
    //                         reportTitle4: reportInResponse.reportTitle4,
    //                         equityAssetClass: reportInResponse.equityAssetClass,
    //                         securityClassification: reportInResponse.securityClassification,
    //                         includeAI: reportInResponse.IncludeAI,
    //                         companyName: reportInResponse.CompanyName,
    //                         includePortfolioCode: reportInResponse.IncludePortfolioCode,
    //                         bondDescription: reportInResponse.bondDescription,
    //                         includeSP: reportInResponse.IncludeSP,
    //                         includeMoody: reportInResponse.IncludeMoody,
    //                         includeFitch: reportInResponse.IncludeFitch,
    //                         showCash: reportInResponse.showCash,
    //                         AccountNumber: reportInResponse.AccountNumber,
    //                         ShowAuthorization: reportInResponse.ShowAuthorization,
    //                         Rounding: reportInResponse.Rounding,
    //                         BillFor: reportInResponse.BillFor
    //                     })
    //                 }
    //                 else{
    //                     reports.push({
    //                         selected: false,
    //                         reportName: reportInResponse.reportName,
    //                         type: reportInResponse.type,
    //                         reportTitle: reportInResponse.reportTitle,
    //                         layout: reportInResponse.layout,
    //                         disclaimer: reportInResponse.disclaimer,
    //                         feeMethod: reportInResponse.feeMethod,
    //                         period1: reportInResponse.period1,
    //                         period2: reportInResponse.period2,
    //                         reportTitle1: reportInResponse.reportTitle1,
    //                         reportTitle2: reportInResponse.reportTitle2,
    //                         reportTitle3: reportInResponse.reportTitle3,
    //                         reportTitle4: reportInResponse.reportTitle4,
    //                         equityAssetClass: reportInResponse.equityAssetClass,
    //                         securityClassification: reportInResponse.securityClassification,
    //                         includeAI: reportInResponse.IncludeAI,
    //                         companyName: reportInResponse.CompanyName,
    //                         includePortfolioCode: reportInResponse.IncludePortfolioCode,
    //                         bondDescription: reportInResponse.bondDescription,
    //                         includeSP: reportInResponse.IncludeSP,
    //                         includeMoody: reportInResponse.IncludeMoody,
    //                         includeFitch: reportInResponse.IncludeFitch,
    //                         showCash: reportInResponse.showCash,
    //                         AccountNumber: reportInResponse.AccountNumber,
    //                         ShowAuthorization: reportInResponse.ShowAuthorization,
    //                         Rounding: reportInResponse.Rounding,
    //                         BillFor: reportInResponse.BillFor
    //                     })
    //                 }

                    


    //             })
    //             //var sort=[{ field: 'type', dir: 'asc' }, { field: 'reportName', dir: 'asc' }]
    //             this.setState({ data: reports.map(cloneProduct), reports: reports, refreshText: '', reportStyles: reportStyles})

    //         })
    //         .catch(err => {
    //             console.error(err);
    //             this.setState({ refreshText: '' });
    //         })
    // }






    saveChanges = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    openPopup = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    cancelChanges = () => {
        this.setState({
            data: this.state.reports.map(cloneProduct),
            changes: false
        });
    }

    itemChange = (event) => {
        event.dataItem[event.field] = event.value;
        this.setState({
            changes: true
        });
    }

    onFilterChange = (e) => {
        this.setState({ gridFilter: e.filter });
    }

    headerCellRender(thElement, cellProps) {
        if (thElement !== null) {
          if (cellProps.field === 'selected')
          return (
            <div  />
          );
          else{
              return thElement
          }
        }
        else {
          return thElement;
        }
      }

      createCell = (cell) => {
        return (
          <td>
            <ReportDefaultPopup idToken={this.props.idToken} user={this.props.user} refresh={this.getParameterDefaults} report={{name: cell.dataItem.name, key: cell.dataItem.key, folder: cell.dataItem.folder, adjustableLayout:cell.dataItem.adjustableLayout}} button={<Button>Edit Default Values</Button>} />
          </td>
        );
      }

      handleGlobalChange(e, switchName){
        var name = e.target.name || switchName //can't get the "name" property to work for switch object. using this workaround
        var value = e.target.value
        var newValue = ""

        if (value.label){//if dropdown with label/value properties
            newValue = value.value
        }
        else{
            newValue = value
        }

        this.setState({
            ["global".concat(name)]: value
        })

        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        // var params = {
        //     company: this.props.user.company,
        //     parameters: paramsToSave,
        //     reportName: this.props.report.name
        // }

        var splitName = name.split('Checked')[0]
    
        var payload = {
          company: this.props.user.company,
          paramName: splitName
        };

        if (name.includes("Checked")){
            payload.active = newValue
            payload.value = (this.state["global".concat(splitName)].label ? this.state["global".concat(splitName)].value : this.state["global".concat(splitName)])
        }
        else{
            payload.active = this.state["global".concat(name).concat("Checked")] || false
            payload.value = newValue
        }

        axios.post('api/ssrsSaveGlobalParameterDefaults', payload, { headers }).then(response => {
          if (response.data.code === 200) {
            //nothing
          }
          else {
            alert("Unable to save global settings")
          }
        })
          .catch(err => {
            console.error(err);
            alert("Unable to save global settings")
          })


      }

      changeDataState = (e)=>{
        var newData = process(this.state.reports, e.data);
            this.setState({dataState:e.data, data:newData});

        
      }


    render() {
        const feeMethodData = [{label: "Gross", value: "0"}, {label: "Net", value: "1"}]
        const layoutData = [{label: "Landscape", value: "l"}, {label: "Portrait", value: "p"}]


        return (
            <div className="reportList">
                <strong>Global Report Defaults:</strong>
                <div>These will apply to every report if set to ON. Defaults set within the "Individual Report Defaults" section below will override global values</div>
                <strong>Fee Method:</strong> <ComboBox data={feeMethodData} name={"FeeMethod"} onChange={(e) => {this.handleGlobalChange(e)}} value={this.state.globalFeeMethod} textField="label" dataItemKey="value" defaultValue={{label: "Gross", value: "0"}} /> <Switch name={"FeeMethodChecked"} checked={this.state.globalFeeMethodChecked} value={this.state.globalFeeMethodChecked} onChange={(e) => {this.handleGlobalChange(e, "FeeMethodChecked")}}/>
                <br/>
                <strong>Disclaimer:</strong> <ComboBox data={this.state.disclaimersList} name={"Disclaimer"} onChange={(e) => {this.handleGlobalChange(e)}} value={this.state.globalDisclaimer} defaultValue='No Disclaimer' /> <Switch name={"DisclaimerChecked"} checked={this.state.globalDisclaimerChecked} value={this.state.globalDisclaimerChecked} onChange={(e) => {this.handleGlobalChange(e, "DisclaimerChecked")}}/>
                <br/>
                <strong>Layout:</strong> <ComboBox data={layoutData} name={"Layout"} onChange={(e) => {this.handleGlobalChange(e)}} value={this.state.globalLayout} textField="label" dataItemKey="value" defaultValue={{label: "Landscape", value: "l"}} /> <Switch name={"LayoutChecked"} checked={this.state.globalLayoutChecked} value={this.state.globalLayoutChecked} onChange={(e) => {this.handleGlobalChange(e, "LayoutChecked")}}/>
                <br/>
                <br/>
                <strong>Individual Report Defaults:</strong>
                <Grid
                    //style={{ height: '420px' }}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.reports)}
                    onRowClick={(e) => this.selectionChange(e, this.state.reports)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.reports)}
                    onDataStateChange={this.changeDataState}
                    data={this.state.data}
                    headerCellRender={this.headerCellRender}
                    rowHeight={50}
                    //onItemChange={this.itemChange}
                    filterable={true}
                    resizable
                    sortable={{
                        mode: 'multiple'
                    }}
                    //cellRender={this.renderers.cellRender}
                    //rowRender={this.renderers.rowRender}

                    editField="inEdit"
                    {...this.state.dataState}
                >
                  <GridNoRecords>
                                    {this.state.loading && ''}
                                    {!this.state.loading && 'No records available'}
                                </GridNoRecords>                    
                    <GridToolbar>
                    <Button onClick={(e) => this.resetAllParameters()}>Reset All Default Values</Button>
                    </GridToolbar>
                    {/* <GridColumn filterable={false} width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={false} /> */}
                    <GridColumn field="type" title="Report Style" width="165px" filterable={true} filterCell={createFilterDropDownCell(this.state.reportStyles, '')} />{/*  */}
                    <GridColumn field="name" title="Report Name" width="250px" filterable={true} />
                    <GridColumn field="paramString" title="Report Defaults" filterable={false} />
                    <GridColumn headerClassName='gridHeader' field="clearDefaults" title=" " width="250px" filterCell={(props) => { return (<></>) }} cell={(props) => this.createCell(props)} />
                    {/* <GridColumn field="layout" title="Layout" width="100px" filterable={false} />
                    <GridColumn field="disclaimer" title="Disclaimer" width="200px" filterable={false} />
                    <GridColumn field="feeMethod" title="Fee Method" width="100px" filterable={false} />
                    <GridColumn field="period1" title="Period 1" width="135px" filterable={false} />
                    <GridColumn field="period2" title="Period 2" width="135px" filterable={false} />
                    <GridColumn field="reportTitle1" title="Report Title 1" width="250px"filterable={false} />
                    <GridColumn field="reportTitle2" title="Report Title 2" width="200px"filterable={false} />
                    <GridColumn field="reportTitle3" title="Report Title 3" width="200px" filterable={false}/>
                    <GridColumn field="reportTitle4" title="Report Title 4" width="200px" filterable={false}/>
                    <GridColumn field="equityAssetClass" title="Equity Asset Class" width="200px"filterable={false} />
                    <GridColumn field="securityClassification" title="Security Classification" width="200px"filterable={false} />
                    <GridColumn field="includeAI" title="Include Accrued Interest" width="150px"filterable={false} />
                    <GridColumn field="companyName" title="Company Name" width="200px"filterable={false} />
                    <GridColumn field="includePortfolioCode" title="Include Portfolio Code" width="150px"filterable={false} />
                    <GridColumn field="bondDescription" title="Include Bond Description" width="150px"filterable={false} />
                    <GridColumn field="includeSP" title="Include S&P" width="150px"filterable={false} />
                    <GridColumn field="includeMoody" title="Include Moody" width="150px"filterable={false} />
                    <GridColumn field="includeFitch" title="Include Fitch" width="150px"filterable={false} />
                    <GridColumn field="showCash" title="Include Cash" width="150px"filterable={false} />
                    <GridColumn field="AccountNumber" title="Show Account Number" width="150px"filterable={false} />
                    <GridColumn field="ShowAuthorization" title="Show Authorization" width="150px"filterable={false} />
                    <GridColumn field="Rounding" title="Rounding" width="200px"filterable={false} />
                    <GridColumn field="BillFor" title="Bill For" width="200px"filterable={false} /> */}
                </Grid>
                {this.state.loading && <GridLoading gridClass='packageReview' />}
            </div>
        );
    }

}

//ReactDOM.render(
//    <App />,
//    document.querySelector('my-app')
//);

export default ReportList