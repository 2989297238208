import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import EditRole from "./EditRole";
import UsersList from "./UsersList";
import PermissionsList from "./PermissionsList";
import axios from 'axios';
import * as utils from '../../utilities';

class DetailRow extends Component {

    removeRole = (e) => {
        var role = this.props.dataItem;
        const confirm = window.confirm("Are you sure you want to remove " + role.role + "?");

        if (confirm === true) {
            const headers = { 'authorization': 'Bearer '.concat(role.idToken) };

            axios.get('api/deleteRole?guid=' + role.guid + '&company=' + role.company, { headers }).then(response => {
                if (response.data.code !== 200) {
                    alert("Error: Role could not be removed.");
                }
                else {
                    this.props.dataItem.refreshList();
                }
            });
        }
    }

    changeActivate = (e) => {
        var role = this.props.dataItem;
        var msg = "activate";

        if (role.active)
            msg = "deactivate";

        const confirm = window.confirm("Are you sure you want to " + msg + " this role?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(role.idToken) };
            var payload = {
                company: role.company,
                roleguid: role.guid,
                role: role.role,
                description: role.description,
                order: role.order,
                active: !role.active,
                permissions: role.permissions != null ? role.permissions : [],
                users: role.users != null ? role.users : [],
                isGlobal: role.isGlobal,
                isOverride: (role.isGlobal && role.company !== 'cssi') ? true : false,
                ignoreUsers: true,
                ignorePermissions: true
            }

            axios.post('api/updateRole', payload, { headers }).then(response => {
                if (response.data.code !== 200) {
                    alert("Error: Role's active status could not be changed.");
                }
                else {
                    role.changeActivate(role.guid, !role.active);
                    role.refreshList();
                    role.refreshUser('roles',role.company);
                }
            });
        }
    }

    render() {
        return (
            <section className='userActions'>
                <strong>Actions: </strong>
                {!this.props.dataItem.loggedInAsAdvisor && this.props.dataItem.active /*&& (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi'))*/ && <Button onClick={event => this.changeActivate(event)}>Deactivate</Button>}
                {!this.props.dataItem.loggedInAsAdvisor && !this.props.dataItem.active /*&& (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi'))*/ && <Button onClick={event => this.changeActivate(event)}>Activate</Button>}
                {!this.props.dataItem.loggedInAsAdvisor && (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi') || this.props.dataItem.isOverride) && <Button onClick={event => this.removeRole(event)}>{this.props.dataItem.isOverride && this.props.dataItem.company !== 'cssi' ? 'Remove Override' : 'Remove'}</Button>}
                {!this.props.dataItem.loggedInAsAdvisor /*&& (!this.props.dataItem.isGlobal || (this.props.dataItem.isGlobal && this.props.dataItem.company === 'cssi'))*/ && <EditRole role={this.props.dataItem} button={<Button>Edit</Button>} />}
                {utils.checkPermission({permissions: this.props.dataItem.permissionsArray},'Users') && <UsersList role={this.props.dataItem} userLevel={this.props.dataItem.userLevel} button={<Button>Users</Button>} />}
                {utils.checkPermission({permissions: this.props.dataItem.permissionsArray},'Permissions') && <PermissionsList role={this.props.dataItem} button={<Button>Permissions</Button>} />}
            </section>
        );
    }
}

export default DetailRow;