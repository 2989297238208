import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class ModelFeesPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ModelCode: '',
            ModelName: '',
            BeginDate: '',
            EndDate: '',
            Fee: 0,
            initialLoad: true,
        }

    }

    onFieldChange = (e, field) => {
        this.setState({ [field]: e.target.value });
    }

    getEditField = (field) => {
        if (this.props.modelFeeEdit && this.state.initialLoad) {
            this.setState({ [field]: this.props.modelFeeEdit[field], initialLoad: false });
            return this.props.modelFeeEdit[field];
        }
        else {
            return '';
        }
    }

    uploadModelFee = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            ModelCode: this.state.ModelCode,
            ModelName: this.state.ModelName,
            BeginDate: this.state.BeginDate,
            EndDate: this.state.EndDate,
            Fee: this.state.Fee,
            company: this.props.user.company,
        }
        //add id field if editing template
        if (this.props.modelFeeEdit) {
            payload.modelFeeEdit = true;
            payload.originalID = this.props.modelFeeEdit.ModelFeesID
        }

        axios.post("api/uploadModelFee", payload, { headers }).then(response => {
            if (response.data.code === 301) {
                alert("Error: A model fee with the same Model Code already exists!");
            }
            else if (response.data.code === 302) {
                alert("Error: Unable to use this Model Code.");
            }
            else {
                if (response.data.code === 200) {
                    alert("Model fee created successfully.");
                }
                else if (response.data.code === 201) {
                    alert("Model fee updated successfully.");
                }
                else {
                    alert("An error occured with the model fee.");
                }
                this.props.uploadMethod();
                this.clearState();
                callback();
            }
        }).catch(err => { alert("An error occured with the template."); console.log(err); this.props.uploadMethod(); this.clearState(); callback(); });
    }

    clearState = () => {
        this.setState({
            ModelCode: '',
            ModelName: '',
            BeginDate: '',
            EndDate: '',
            Fee: 0,
            initialLoad: true
        })
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">

                        <Button className="close" icon="close" onClick={(e) => { close(); }} />


                        <div className="header" align="left">
                            {this.props.modelFeeEdit ? 'Edit Model Fee' : 'New Model Fee'}
                        </div>

                        <div className="content">
                            <div>
                                <label>Model Code: </label>
                                <input type="text" value={this.state.ModelCode || this.getEditField('ModelCode')} onChange={(e) => this.onFieldChange(e, 'ModelCode')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Model Name: </label><br />
                                <input type="text" value={this.state.ModelName || this.getEditField('ModelName')} onChange={(e) => this.onFieldChange(e, 'ModelName')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Begin Date: </label>
                                <input type="date" value={(this.state.BeginDate === '' || typeof (this.state.BeginDate) !== 'object' ? this.state.BeginDate : new Date(this.state.BeginDate).toISOString().substring(0, 10)) || this.getEditField('BeginDate')} onChange={(e) => this.onFieldChange(e, 'BeginDate')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>End Date: </label>
                                <input type="date" value={(this.state.EndDate === '' || typeof (this.state.EndDate) !== 'object' ? this.state.EndDate : new Date(this.state.EndDate).toISOString().substring(0, 10)) || this.getEditField('EndDate')} onChange={(e) => this.onFieldChange(e, 'EndDate')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Fee (%): </label>
                                <input type="number" value={this.state.Fee || this.getEditField('Fee')} onChange={(e) => this.onFieldChange(e, 'Fee')} placeholder={""} />
                            </div>
                        </div>

                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>

                            <Button
                                className="upload"
                                onClick={(event) => { this.uploadModelFee(close) }}>
                                {this.props.modelFeeEdit ? 'Update' : 'Create'}
                            </Button>
                        </div>

                    </div>
                )}
            </Popup>

        );
    };
}

export default ModelFeesPopup;