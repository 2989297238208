
import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../../Loaders/GridLoading';
import { loadMessages } from '@progress/kendo-react-intl';
import bgMessages from './bg.json';
loadMessages(bgMessages, 'bg-BG');

const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class RelationshipAssociateMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataState: { skip: 0, take: 25 },
            files: [],
            associates: []
        };

    }

    componentDidMount() {
        this.getCsRelationshipAssociateMapping();
    }

    changeDataState = (e) => {
        var newData = process(this.state.data, e.data);
        this.setState({ dataState: e.data, data: newData });
    }

    getCsRelationshipAssociateMapping = () => {
        this.setState({ refreshText: 'Loading... '});
        var associates = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/CsRelationshipAssociateMapping`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(relationshipsAssociateMapping => {

                    associates.push({
                        HouseholdID: relationshipsAssociateMapping.HouseholdID,
                        InterestedVPName: relationshipsAssociateMapping.InterestedVPName,
                        InterestedVPEmail: relationshipsAssociateMapping.InterestedVPEmail,
                        RelationshipAssociateName: relationshipsAssociateMapping.RelationshipAssociateName,
                        RelationshipAssociateEmail: relationshipsAssociateMapping.RelationshipAssociateEmail,
                        ResponsibleName: relationshipsAssociateMapping.ResponsibleName,
                        ResponsibleEmail: relationshipsAssociateMapping.ResponsibleEmail,
                    })

                })
                this.setState({ data: associates.map(cloneProduct), associates: associates, refreshText: '' })

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }

    render() {
        return (
            <div className="customMapping">
                <StatefulGrid
                    selectedField='selected'
                    onDataStateChange={this.changeDataState}
                    data={this.state.data}
                    rowHeight={50}
                    filterable={true}
                    resizable
                    sortable={{
                        mode: 'multiple'
                    }}
                    sort={this.state.sort}
                    editField="inEdit"
                >
                    <GridColumn field="HouseholdID" title="Household" filterable={true} />
                    <GridColumn field="InterestedVPName" title="Interested VP" width='250px' filterable={true} />
                    <GridColumn field="RelationshipAssociateName" title="Relationship Associate" width='250px' filterable={true} />
                    <GridColumn field="ResponsibleName" title="Responsible" width='250px' filterable={true} />
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='packageReview' />}
            </div>
        );
    }

}


export default RelationshipAssociateMapping