import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import EditUser from "./EditUser";
import HouseholdList from "./HouseholdList";
import RolesList from "./RolesList";
import axios from 'axios';
import * as utils from '../../utilities';

class DetailRow extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.removeUser = this.removeUser.bind(this);
    this.changeActivate = this.changeActivate.bind(this);

    this.state = {
        userInfoLoaded: false
    }
  }

  setUserInfoLoaded = () => {
    this.setState({userInfoLoaded: true})
  }

  removeUser(e){
    var user = this.props.dataItem;
    const confirm = window.confirm("Are you sure you want to remove " + user.email +  "?");
    
    if(confirm===true){
        const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
        var payload = {
            email:user.email,
            company:user.company,
            companyToRemove:user.company
        }

        axios.post('api/removeuser', payload, {headers}).then(response=>{
            if(response.data.code!==201){
                alert("Error: User could not be removed from company.");
            }
            else{
                this.props.dataItem.refreshList();
            }
        });
    }
}



    changeActivate(e){
        var user = this.props.dataItem;
        var msg = "activate";

        if(user.active)
            msg = "deactivate";

        const confirm = window.confirm("Are you sure you want to "+msg+" this user?");
        
        if(confirm===true){
            const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
            var payload = {
                email:user.email,
                company:user.company,
                activate:!user.active
            }

            axios.post('api/activateUser', payload, {headers}).then(response=>{
                if(response.data.code!==201){
                    alert("Error: User's active status could not be changed.");
                }
                else{
                    user.changeActivate(user.email, !user.active);
                    user.refreshUser('users',user.company);
                }
            });
        }
  }

  render() {
        return (
            <section className='userActions'>
                <strong>Actions: </strong>
                {!this.props.dataItem.loggedInAsAdvisor && this.props.dataItem.active && <Button onClick={event=>this.changeActivate(event)}>Deactivate</Button>}
                {!this.props.dataItem.loggedInAsAdvisor && !this.props.dataItem.active && <Button onClick={event=>this.changeActivate(event)}>Activate</Button>}
                {!this.props.dataItem.loggedInAsAdvisor && <Button onClick={event=>this.removeUser(event)}>Remove</Button>}
                {!this.props.dataItem.loggedInAsAdvisor && <EditUser  user={this.props.dataItem} templates={this.props.dataItem.templates} tempDefault={this.props.dataItem.tempDefault} enableMFA={this.props.dataItem.enableMFA} idToken={this.props.dataItem.idToken} role={this.props.dataItem.role} company={this.props.dataItem.company} button={<Button>Edit</Button>} />}
                {!this.props.dataItem.loggedInAsAdvisor && <HouseholdList user={this.props.dataItem} button={<Button>Households</Button>} />}
                <Button onClick={e=>this.props.dataItem.onDocClick(this.props)}>View Documents</Button>
                {/*utils.checkPermission({permissions: this.props.dataItem.permissionsArray},'Roles') &&*/ utils.checkPermission({permissions: this.props.dataItem.permissionsArray},'Update User Roles') && <RolesList setUserInfoLoaded={this.setUserInfoLoaded} user={this.props.dataItem} button={<Button disabled={!this.state.userInfoLoaded}>Roles</Button>} />}
            </section>
        );
  }
}

export default DetailRow;