import React, { Component } from "react";

class ImageUpload extends Component {

    constructor(props){
        super(props)
        this.state = {

        }
      }

    render() {
    return (
        <div>
            <input type="file" acccept="image/*" name={this.props.name} onChange={this.props.onChangeImage}/>           
        </div>
    );
    }
    
}

export default ImageUpload;