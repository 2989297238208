import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class BenchmarkIndexesPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            IndexEntityCode: '',
            IndexDescription: '',
            IndexValue: '',
            AsOfDate: '',
            Blend: '',
            UserDef1: '',
            UserDef2: '',
            UserDef3: '',
            initialLoad: true,
        }

    }

    onFieldChange = (e, field) => {
        this.setState({ [field]: e.target.value });
    }

    getEditField = (field) => {
        if (this.props.benchmarkIndexEdit && this.state.initialLoad) {
            this.setState({ [field]: this.props.benchmarkIndexEdit[field], initialLoad: false });
            return this.props.benchmarkIndexEdit[field];
        }
        else {
            return '';
        }
    }

    uploadBenchmarkIndex = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            IndexEntityCode: this.state.IndexEntityCode,
            IndexDescription: this.state.IndexDescription,
            IndexValue: this.state.IndexValue,
            AsOfDate: this.state.AsOfDate,
            Blend: this.state.Blend,
            UserDef1: this.state.UserDef1,
            UserDef2: this.state.UserDef2,
            UserDef3: this.state.UserDef3,
            company: this.props.user.company,
        }
        //add id field if editing template
        if (this.props.benchmarkIndexEdit) {
            payload.benchmarkIndexEdit = true;
            payload.originalID = this.props.benchmarkIndexEdit.BenchmarkIndexID
        }

        axios.post("api/uploadBenchmarkIndex", payload, { headers }).then(response => {
            if (response.data.code === 200) {
                alert("Benchmark index created successfully.");
            }
            else if (response.data.code === 201) {
                alert("Benchmark index updated successfully.");
            }
            else {
                alert("An error occured with the benchmark index.");
            }
            this.props.uploadMethod();
            this.clearState();
            callback();
        }).catch(err => { alert("An error occured with the template."); console.log(err); this.props.uploadMethod(); this.clearState(); callback(); });
    }

    clearState = () => {
        this.setState({
            IndexEntityCode: '',
            IndexDescription: '',
            IndexValue: '',
            AsOfDate: '',
            Blend: '',
            UserDef1: '',
            UserDef2: '',
            UserDef3: '',
            initialLoad: true
        })
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">

                        <Button className="close" icon="close" onClick={(e) => { close(); }} />


                        <div className="header" align="left">
                            {this.props.benchmarkIndexEdit ? 'Edit Benchmark Index' : 'New Benchmark Index'}
                        </div>

                        <div className="content">
                            <div>
                                <label>Index Entity Code: </label>
                                <input type="text" value={this.state.IndexEntityCode || this.getEditField('IndexEntityCode')} onChange={(e) => this.onFieldChange(e, 'IndexEntityCode')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Index Entity Description: </label><br />
                                <textarea name="IndexDescription" rows="5" style={{ width: '95%' }} value={this.state.IndexDescription || this.getEditField('IndexDescription')} onChange={(e) => this.onFieldChange(e, 'IndexDescription')} placeholder={""} ></textarea>
                            </div>
                            <br /><br />
                            <div>
                                <label>Index Value: </label>
                                <input type="text" value={this.state.IndexValue || this.getEditField('IndexValue')} onChange={(e) => this.onFieldChange(e, 'IndexValue')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>As of Date: </label>
                                <input type="date" value={(this.state.AsOfDate === '' || typeof (this.state.AsOfDate) !== 'object' ? this.state.AsOfDate : new Date(this.state.AsOfDate).toISOString().substring(0, 10)) || this.getEditField('AsOfDate')} onChange={(e) => this.onFieldChange(e, 'AsOfDate')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Blend: </label>
                                <input type="text" value={this.state.Blend || this.getEditField('Blend')} onChange={(e) => this.onFieldChange(e, 'Blend')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>User Def 1: </label>
                                <input type="text" value={this.state.UserDef1 || this.getEditField('UserDef1')} onChange={(e) => this.onFieldChange(e, 'UserDef1')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>User Def 2: </label>
                                <input type="text" value={this.state.UserDef2 || this.getEditField('UserDef2')} onChange={(e) => this.onFieldChange(e, 'UserDef2')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>User Def 3: </label>
                                <input type="text" value={this.state.UserDef3 || this.getEditField('UserDef3')} onChange={(e) => this.onFieldChange(e, 'UserDef3')} placeholder={""} />
                            </div>
                            <br /><br />
                        </div>

                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>

                            <Button
                                className="upload"
                                onClick={(event) => { this.uploadBenchmarkIndex(close) }}>
                                {this.props.benchmarkIndexEdit ? 'Update' : 'Create'}
                            </Button>
                        </div>

                    </div>
                )}
            </Popup>

        );
    };
}

export default BenchmarkIndexesPopup;