import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import GridLoading from '../../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import {parseDate} from '@telerik/kendo-intl';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

class AdvisorHoldingsSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data:[],
      result:[],
      dataState:{group:[{field:"Asset Class", aggregates:[]}]},
      aggregates:[],
      perfType:'net',
      returnType:'irr',
      dates:[],
      selectedDate:'',
      loading:false
    }

    this.getData = this.getData.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.getDate = this.getDate.bind(this);
    this.getPosDates = this.getPosDates.bind(this);
  }

  componentDidMount(){
      //this.getData();
      this.getPosDates();
  }

  getPosDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      advisor:this.props.user.user,
      email:this.props.user.email
    }
    axios.post('api/posDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate}, () =>{
              this.getData();
            });
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getDate(sqlDate, isUTC){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if(d){
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getData(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };


    if (this.state.selectedDate==='')
    {
      this.getPosDates();
    }
    

    axios.get('api/advisorHoldingsSummary?company='+this.props.user.company+'&date='+this.state.selectedDate, {headers}).then(response=>{
      if(response.data.code===200){
        for(let i=0; i<response.data.data.length; i++){
          response.data.data[i]["Asset Class"] = response.data.data[i].assetAllocation;
        }
        this.setState({data:response.data.data, result:process(response.data.data, this.state.dataState), loading:false});
      }
      else{
        this.setState({loading:false});
        alert("Could not data for advisor's accounts.");
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

  changeDataState = (e)=>{
    const groups = e.data.group;
    if(groups){
      groups.map(group=>group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.data, e.data);
    this.setState({dataState:e.data, result:newData});
  }

  cellRender(tdElement, cellProps) {
    if(cellProps.rowType==='data' && cellProps.field!=='value' && (cellProps.dataItem[cellProps.field]===undefined || cellProps.dataItem[cellProps.field]===null)){
        return(
            <td style={{textAlign:'right'}}>-</td>
        );
    }
    else if(tdElement!==null){
      var style={};
        if(cellProps.field!=='Asset Class'){
            
            style = {textAlign:'right'};
            return(
                <td {...tdElement.props} style={style}/>
            );
        }
        else if(cellProps.field==='displayName'){
            if(cellProps.dataItem.indexName)
              style = {textAlign:'right', fontStyle:'italic'};
            else
              style = {fontWeight:'bold'}

            return(
                <td {...tdElement.props} style={style}/>
            );
        }
    
    }

    return tdElement;
  }

  headerCellRender(thElement, cellProps) {
    if(thElement!==null){
      var style={};
      if(cellProps.field!=='Asset Class')
        style = {textAlign:'right'};

      return(
        <th {...thElement.props} style={style}/>
      );
    }

    return thElement;

  }

  _export;

  export = () =>{
    this._export.save();
  }

  render() {
    return(
        <div className='advisorPerfHist'>
          <ExcelExport data={this.state.data} fileName={'AdvisorHoldingsSummary_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
            <ExcelExportColumn field="Asset Class" title="Asset Class"/>
            <ExcelExportColumn field="security" title="Holding | Symbol"/>
            <ExcelExportColumn field="totalMarketValue" title="Market Value"/>
            <ExcelExportColumn field="percentage" title="Total %"/>
          </ExcelExport>
          <Grid data={this.state.result}
            className='noGroupGridLines'
            cellRender = {this.cellRender}
            headerCellRender = {this.headerCellRender}
            onExpandChange={this.expandChange}
            expandField="expanded"
            groupable={true}
            resizable={true}
            reorderable={true}
            onDataStateChange={this.changeDataState}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              <label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value}, ()=>this.getData())}>
              {this.state.dates.map((date, i)=>(
              <option value={date.fDate}>{date.fDate}</option>
              ))}
              </select>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
            </GridToolbar>
            <GridColumn headerClassName='gridHeader' field="security" title="Holding | Symbol"/>
            <GridColumn headerClassName='gridHeader' field="totalMarketValue" title="Market Value" format="{0:c2}"/>
            <GridColumn headerClassName='gridHeader' field="percentage" title="Total %" format="{0:p2}"/>
          </Grid>
          {this.state.loading && <GridLoading />}
        </div>
    );
  }

}

export default AdvisorHoldingsSummary;