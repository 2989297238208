import React, { Component } from "react";
import RGains from '../components/RGains';

class RGainDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
        
    }

  }


  render() {
    return(
        <div className='rgainDash'>
            <RGains user={this.props.user} compSettings={this.props.compSettings} portfolio={this.props.portfolio} household={this.props.household} idToken={this.props.idToken}/>
        </div>
    );
  }

}

export default RGainDashboard;