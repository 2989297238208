import React, { Component } from "react";
import {Chart, ChartTooltip, ChartSeries, 
  ChartSeriesItem, ChartTitle, ChartCategoryAxis, 
  ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartLegend, ChartSeriesItemTooltip} from '@progress/kendo-react-charts';
import axios from 'axios';
import 'hammerjs';

class HistoricalMV extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data:[],
      portfolio:this.props.portfolio,
    }

    this.getMarketValues = this.getMarketValues.bind(this);
    this.getLineColor = this.getLineColor.bind(this);
    this.getAreaColor = this.getAreaColor.bind(this)

  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio || this.props.date!==this.state.date){
      var update = this.state.date!=='';
      this.setState({...this.props}, ()=>{if(update){this.getMarketValues()}});
    }
  }

  componentDidMount(){
    this.getMarketValues();
  }

  getMarketValues(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var port = this.state.portfolio.account;

    if(this.props.type==='multiple')
      port = undefined;

    axios.get('api/marketValuePerf?company='+this.props.user.company+'&port='+port+'&date='+this.state.date+'&perfType='+this.state.perfType, {headers}).then(response=>{
      if(response.data.code===200){
        for(let i=0; i<response.data.data.length; i++){
            response.data.data[i].realColor = response.data.data[i].color;
            response.data.data[i].color=null;
        }
        console.log(response.data.data);
        this.setState({data:response.data.data});
      }
      else{
        alert('Could not retrieve market value data.');
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  renderTooltip = ({point})=>{
    if(point!==undefined){
        return (
            <>
                <div><strong>{point.dataItem.displayName}</strong></div>
                <div><strong>{point.category.toLocaleDateString("en-US", {month:'long', year:'numeric'})}</strong></div>
                <div>Market Value: ${point.dataItem.marketValue.toFixed(2)}</div>
            </>);
    }
    else{
      return(<div></div>);
    }
  }

  renderTooltipCW = ({point})=>{
    if(point!==undefined){
        return (
            <>
                <div><strong>{point.dataItem.displayName}</strong></div>
                <div><strong>{point.category.toLocaleDateString("en-US", {month:'long', year:'numeric'})}</strong></div>
                <div>Market Value: ${point.dataItem.mvCW.toFixed(2)}</div>
                <div>Net CW: ${point.dataItem.netCW.toFixed(2)}</div>
            </>);
    }
    else{
        return(<div></div>);
    }
}

  getAreaColor(data){
    if(Array.isArray(data) && data.length>0){
      return data[0].realColor;
    }
    else{
      return data.realColor;
    }
  }

  getLineColor(data){
    if(Array.isArray(data) && data.length>1){
        return data[1].realColor;
      }
      else{
        return data.realColor;
    }
  }

  render() {

    return(
        <div className='marketValueGraph'>
        <Chart pannable={{ lock: 'y' }} zoomable={{ mousewheel: { lock: 'y' } }}>
          <ChartTitle text="Market Value"/>
          <ChartLegend position="bottom" orientation='veritcal'/>
          <ChartTooltip />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem title={{text:"Date"}} type='date' maxDivisions={20} baseUnit='months' axisCrossingValue={0} justified={true} />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem title={{ text: "Market Value" }} labels={{format:'c'}}/>
        </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem type='area' name={this.state.portfolio.displayName} color={this.getAreaColor(this.state.data)} field='marketValue' categoryField='date' data={this.state.data}>
                    <ChartSeriesItemTooltip render={this.renderTooltip} />
                </ChartSeriesItem>
                <ChartSeriesItem markers={{visible:false}} type='line' name='Net Contributions/Withdrawals' color={this.getLineColor(this.state.data)} field='mvCW' categoryField='date' data={this.state.data}>
                    <ChartSeriesItemTooltip render={this.renderTooltipCW} />
                </ChartSeriesItem>
            </ChartSeries>
        </Chart>
        </div>
    );
  }

}

export default HistoricalMV;