import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import GridLoading from "../Loaders/GridLoading";

class EditUserProfile extends Component {

  constructor(props) {
    super(props);

    var portTabs = [{ name: "Account Summary", page: "acctSumm" },
    { name: "Account Overview", page: "equityOver" },
    { name: "Performance Overview", page: "perfOver" },
    { name: "Holdings", page: "holds" },
    //{name:"Holdings Summary", page:"holdSumm"}, 
    { name: "Fixed Income Holdings", page: "fiHolds" },
    //{name:"Market Value", page:"markVal"},
    //{name:"Group Appraisal", page:"grpApp"},
    //{name:"Historical Market Value", page:"histMarkVal"},
    { name: "Transaction Summary", page: "tranSumm" },
    //{name:"Performance Growth", page:"growth"},
    //{name:"Account AUM", page:"aum"},
    { name: "Realized Gains", page: "rgain" }];

    var userViews = this.props.user.access.slice();
    var userTabs = this.props.user.access.slice();




    userTabs = userTabs.filter(x => {
      var ind = portTabs.findIndex(y => { return y.page === x });
      return ind >= 0;
    });


    var useCompDef = this.props.user.access.indexOf('defaultTabs') >= 0;


    this.state = {
      roles: [{ text: "admin" }, { text: "advisor" }, { text: "user" }],
      selectedRole: { text: this.props.user.role },
      companyViews: [],
      portTabs: portTabs,
      enabledTabs: userTabs,
      views: userViews,
      approved: this.props.user.approved,
      searchCompany: this.props.user.searchCompany,
      userUpload: this.props.user.upload,
      //userReceiveEmail:this.props.user.receiveEmail,
      nickname: this.props.user.nickname,
      sendApprovalEmail: false,
      editTabs: false,
      useCompDefTabs: useCompDef,
      //userEnableMFA: this.props.user.useMFA,
      editEmail: false,
      templates: this.props.templates || [],
      tempSelected: '',
      tempDefault: this.props.tempDefault || { title: 'Default Template' },
      userEmail: this.props.user.email,
      styleSettings: {
        userChangeName: null,
        userTurnOnMFA: null,
        userChangeMFAPhoneNumber: null,
        userChangeReceiveEmail: null,
      },
      nicknameColor: '',
      userInfo: null,
      loading: true,
    }

    this.updateRole = this.updateRole.bind(this);
    this.clearState = this.clearState.bind(this);
    this.getUploadRadioValue = this.getUploadRadioValue.bind(this);
    this.getReceiveEmailRadioValue = this.getReceiveEmailRadioValue.bind(this);
    this.onUploadChange = this.onUploadChange.bind(this);
    this.onReceiveEmailChange = this.onReceiveEmailChange.bind(this);
    this.resetMFAPhoneNumber = this.resetMFAPhoneNumber.bind(this);
  }



  componentDidMount() {
    this.getSuperAdminSettings()
    this.getStyleSettings(() => {
      this.getUserSettings()
    })
  }

  getSuperAdminSettings() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/superAdminSettings`, {
      method: 'GET',
      params: { company: this.props.company },
      headers: headers
    })
      .then(response => {
        response.data.data.forEach(setting => {
          if (setting.settingName === 'enableMFA') {
            this.setState({ enableMFA: setting.settingValue.toLowerCase() === 'true' ? true : false })
          }
        })

      })
      .catch(err => { console.log(err); });
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'userChangeName',
      'userTurnOnMFA',
      'userChangeMFAPhoneNumber',
      'userChangeReceiveEmail',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {

        this.setState({
          styleSettings: response.data.styleSettings,
        }, () => {
          cb()
        })
      })
      .catch(err => {
        console.error(err);
      })
  }

  getUserSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get('api/userInfo?company=' + this.props.user.company + '&email=' + this.props.user.email, { headers }).then((response) => {
      if (response.data.code === 200) {
        this.setState({
          userInfo: response.data.user,
          userReceiveEmail: response.data.user.receiveEmail,
          userEnableMFA: response.data.user.useMFA,
          loading: false,
        })
      }
      else {
        alert("User info could not be received.")
        if (response.data) {
          console.log(response.data.error)
        }
      }
    }).catch(error => console.log(error));
  }

  getRoles() {
    var arr = this.state.roles.slice();
    var roleInd = arr.findIndex(role => {
      return role.text === this.props.user.role;
    });
    if (roleInd !== -1) {
      arr.splice(roleInd, 1);
    }
    return arr;
  }

  clearState() {
    this.setState({ selectedRole: { text: this.props.user.role }, views: this.props.user.access, editEmail: false, tempSelected: '', userEnableMFA: (this.state.userInfo ? this.state.userInfo.useMFA : this.props.user.useMFA) });
  }

  onRoleChange = (e) => {
    this.setState({ selectedRole: e.target.value });
  }

  onRoleChange = (e) => {
    this.setState({ selectedRole: e.target.value });
  }



  onUploadChange(e, attr) {
    var val = 'default';

    if (attr === 'enabled')
      val = true;
    else if (attr === 'disabled')
      val = false;

    this.setState({ userUpload: val });
  }


  onReceiveEmailChange(e, attr) {
    var val = 'default';

    if (attr === 'enabled')
      val = true;
    else if (attr === 'disabled')
      val = false;

    this.setState({ userReceiveEmail: val });
  }

  onNameChange = (e) => {
    this.setState({ nickname: e.target.value, nicknameColor: '' });
  }

  onEmailChange = (e) => {
    this.setState({ userEmail: e.target.value });
  }

  onCompChange = (e) => {
    this.setState({ searchCompany: e.target.value });
  }

  onTabsChange(tab, e) {
    var newTabs = this.state.enabledTabs.slice();
    var indTab = newTabs.indexOf(tab.page);

    if (indTab === -1)
      newTabs.push(tab.page);
    else
      newTabs.splice(indTab, 1);

    this.setState({ enabledTabs: newTabs });
  }

  updateRole(e) {
    var user = this.props.user;
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    if (!this.state.nickname) {
      this.setState({ nicknameColor: 'red' })
    }
    else {
      var payload = {
        email: user.email,
        nickname: this.state.nickname,
        company: user.company,
        searchCompany: this.state.searchCompany,
        role: this.state.selectedRole.text,
        access: user.access,
        approved: this.state.approved,
        upload: this.state.userUpload,
        receiveEmail: this.state.userReceiveEmail,
        useMFA: this.state.userEnableMFA
      }


      axios.post('api/updateuser', payload, { headers }).then(response => {
        if (response.data.code === 201) {
          this.props.refreshUser(this.props.currentPage, user.company);
          alert("Profile has been updated.");
        }
        else {
          alert("Error: Profile could not be updated.");
        }
      });
    }
  }


  resetMFAPhoneNumber(e) {
    var conf = "";
    conf = window.confirm("Are you sure you want to reset your MFA phone number?");

    if (conf) {
      var user = this.props.user;
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload = {
        email: user.email,
        company: user.company,
        useMFA: this.state.userEnableMFA
      }

      axios.post('api/resetMFAPhoneNumber', payload, { headers }).then(response => {
        if (response.data.code === 200) {
          alert("MFA phone number has been reset.");
        }
        else if (response.data.code === 201) {
          alert("MFA phone number has not be setup yet.");
        }
        else {
          alert("Unable to reset MFA phone number.");
        }

      });
    }

  }




  getUploadRadioValue(field) {
    if (field === 'default') {
      return this.state.userUpload === null || this.state.userUpload === undefined;
    }
    else if (field === 'enabled') {
      return this.state.userUpload;
    }
    else if (field === 'disabled' && this.state.userUpload !== undefined) {
      return this.state.userUpload === false;
    }
  }

  getReceiveEmailRadioValue(field) {
    if (field === 'enabled') {
      return this.state.userReceiveEmail || this.state.userReceiveEmail === null || this.state.userReceiveEmail === undefined;;
    }
    else if (field === 'disabled') {
      return this.state.userReceiveEmail === false
    }
  }

  useDefault = (e) => {
    var checked = !this.state.useCompDefTabs;
    if (checked)
      this.setState({ useCompDefTabs: checked, editTabs: false });
    else
      this.setState({ useCompDefTabs: checked });
  }

  handleChange(e) {

    this.setState({ [e.target.props.name]: e.target.value })
    //this.forceUpdate();
  }

  /*
  onPhoneInput = (e) =>{
    if(phoneRegex.test(e.target.value)){
      this.setState({invitePhone:e.target.value});
    }
    else{
      this.setState({invitePhone:''});
    }
    
  }
  */



  render() {
    return (
      <Popup closeOnDocumentClick={false} open={this.props.open} onClose={(event) => { this.clearState(); this.props.close() }} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">

            <Button className="close" icon="close" onClick={(e) => { close(); }} />


            <div className="header" align="left">
              Edit My Profile
            </div>

            {this.state.userInfo && !this.state.loading && <>
              <div className="content">
                {this.state.styleSettings.userChangeName && <>
                  <div>
                    <label>Name: </label>
                    <input style={{ borderColor: this.state.nicknameColor, width: '230px' }} defaultValue={this.state.userInfo.nickname} onChange={this.onNameChange} />
                  </div>
                  <br />
                </>}
                <div>
                  <label>Email: </label>
                  <>
                    <input style={{ width: '230px' }} defaultValue={this.state.userInfo.email} disabled={true} readonly="readonly" />
                    <br />
                    <label style={{ fontSize: '70%', fontStyle: 'italic', paddingLeft: '10px' }}>Please contact your portal admin team to change your email address.</label>
                  </>

                </div>
                <br />
                {/* <div><label>Role: </label><RoleDropDown roles={this.getRoles()} onChange={this.onRoleChange} default={{text:this.props.user.role}}/></div>
                <br /> */}
                {this.state.styleSettings.userTurnOnMFA && this.state.enableMFA && <>
                  {this.state.enableMFA &&
                    <>
                      <label>Enable Multi-Factor Authentication</label><Switch
                        name='userEnableMFA'
                        checked={this.state.userEnableMFA}
                        value={this.state.userEnableMFA}
                        onChange={(e) => this.handleChange(e)}
                      />


                      {/*this.state.userEnableMFA &&
                    <>
                      <Input
                        className="phoneInput"
                        name="phoneNum"
                        style={{ width: "100%", padding:'0px'}}
                        label="Phone (optional)"
                        onBlur = {this.onPhoneInput} 
                        type="tel"
                        pattern={'^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'}
                        required={false}
                        validationMessage="Must enter a valid phone number."
                      />
                      <br/>
                      <br/>
                    </>
                  */}


                    </>
                  }

                  {this.state.enableMFA && this.state.userEnableMFA && this.state.styleSettings.userChangeMFAPhoneNumber &&
                    <>
                      <br />
                      <Button
                        className="button"
                        onClick={(event) => { this.resetMFAPhoneNumber(event); }}>
                        Reset MFA Phone Number
                      </Button>


                    </>
                  }
                  <br />
                  <br />
                </>}
                {this.state.styleSettings.userChangeReceiveEmail && <div><strong>Receive Portal Email Notifications? </strong>
                  <input type='radio' onChange={(e) => this.onReceiveEmailChange(e, 'enabled')} defaultChecked={this.getReceiveEmailRadioValue('enabled')} name='receiveEmail' />Enabled
                  <input type='radio' onChange={(e) => this.onReceiveEmailChange(e, 'disabled')} defaultChecked={this.getReceiveEmailRadioValue('disabled')} name='receiveEmail' />Disabled
                </div>}

              </div>


              <div className="actions">
                <Button
                  className="button"
                  onClick={() => { close(); }}>
                  Cancel
                </Button>

                <Button
                  className="upload"
                  onClick={(event) => { this.updateRole(event); }}>
                  Update
                </Button>
              </div>
            </>}
            {this.state.loading && <div style={{ padding: '50px' }}><GridLoading /></div>}
          </div>
        )}
      </Popup>

    );
  };
}

const style = {
  className: "top",
};

//const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export default EditUserProfile;