import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';

class MailVariables extends Component {

  constructor(props) {
    super(props);

    this.state = {

      }
  }

  clearState(){
    this.setState({

    })
  }


  render() {
      return (
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{close();}} />


              <div className="header" align="left">
                Email Variables
              </div>

              <div className="content">
                <div>
                    To use variables in your email templates insert them in the template as {"{{variablename}}"}.<br/>
                    If the variable doesn't exist it will be replaced with empty space.<br/>
                    Below is a list of available variables:
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td className='heading'>Variable <hr/></td>
                            <td className='heading'>Description<hr/></td>
                            <td align='right' className='heading'>Emails Used In:<hr/></td>
                        </tr>
                        <tr>
                            <td>{"{{host}}"}</td>
                            <td>URL that email was sent from (aka Portal URL)</td>
                            <td>Invite, Bulk Emails</td>
                        </tr>
                        <tr>
                            <td>{"{{name}}"}</td>
                            <td>Name of the user (if entered on invite form)</td>
                            <td>Invite, Bulk Emails, Notifications</td>
                        </tr>
                        <tr>
                            <td>{"{{email}}"}</td>
                            <td>Email address of the user sending the notification</td>
                            <td>"Document Notification" and "Advisor Document Notification" templates</td>
                        </tr>
                        <tr>
                            <td>{"{{role}}"}</td>
                            <td>Role of the user</td>
                            <td>Invite</td>
                        </tr>
                        <tr>
                            <td>{"{{resetlink}}"}</td>
                            <td>Link for new user to reset password</td>
                            <td>Invite(New user only)</td>
                        </tr>
                        <tr>
                            <td>{"{{documents}}"}</td>
                            <td>Names of files and descriptions</td>
                            <td>"Document Notification" and "Advisor Document Notification" templates</td>
                        </tr>
                        <tr>
                            <td>{"{{RIAFirmName}}"}</td>
                            <td>References RIA Firm Name under Admin Settings &gt; Style Settings &gt; General Settings</td>
                            <td>Invite, Bulk Emails, Notifications</td>
                        </tr>
                    </tbody>
                </table>
              </div>

              <div className="actions">              

              </div>

            </div>
          )}
        </Popup>

      );
  };
}

export default MailVariables;