import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import { filterBy } from '@progress/kendo-data-query';
import HTMLEditor from './JoditEditor'

class EmailForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        usersSelected:[],
        ccSelected:[],
        bccSelected:[],
        templates:[],
        tempSelected: '',
        tempDefault: {title:'Select a Template'},
        subjectText: '',
        bodyText: '',
        templateText:'',
        filter:null,
        ccFilter:null,
        bccFilter:null,
    }

    this.getMailTemplates=this.getMailTemplates.bind(this);
    this.onHTMLChange=this.onHTMLChange.bind(this);
    this.clearState=this.clearState.bind(this);
    this.onUsersChange = this.onUsersChange.bind(this);
  }

  componentDidMount(){
    this.getMailTemplates();
  }

  getMailTemplates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/mailtemplate?company="+this.props.company, {headers}).then(response=>{          
      this.setState({templates:response.data});
    }).catch(err=>console.log(err));
  }

  onTemplateChange = (e) => {
    if (e.target.value.title === 'Select a Template') {
      this.setState({ tempSelected: '', subjectText: '', bodyText: ''});
    }
    else {
      this.setState({ tempSelected: e.target.value.title, subjectText: e.target.value.subject, templateText: e.target.value.body});
    }

    this.forceUpdate();
  }

  onUsersChange(e, type){
    var arr = [];
    var foundIndex;

    if (Array.isArray(e.target.value)) {
      var temp = e.target.value;

      for(let i=0; i<temp.length; i++){

        if(temp[i].details!==undefined){
          for(let k=0; k<temp[i].details.length; k++){

            foundIndex = arr.findIndex(function(user){
              return user.email===temp[i].details[k].email;
            });

            if(foundIndex<0){
              arr.push(temp[i].details[k]);
            }

          }
        }
        else{
          foundIndex = arr.findIndex(function(user){
            return user.email===temp[i].email;
          });

          if(foundIndex<0){
            arr.push(temp[i]);
          }
        }
      }
    }
    else {
      if(temp.details!==undefined){
        for(let k=0; k<temp.details.length; k++){
            arr.push(temp.details[k]);
        }
      }
      else{
        arr.push(e.target.value);
      }
    }

    this.setState({ [type]: arr });
  }

  onSubjectChange = (e) => {
      this.setState({subjectText:e.target.value});
  }

  onBodyChange = (e) => {
      this.setState({bodyText:e.target.value});
  }

  onHTMLChange(html){
    this.setState({bodyText:html, templateText:''});
  }

  clearState(){
    this.setState({ usersSelected:[], tempSelected: '', subjectText: '', bodyText: ''});
  }

  render() {
      return (
       <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{close();}} />


              <div className="header" align="left">
                Send Push Email
              </div>

              <div className="content">
              <DropDownList 
                    data={this.state.templates}
                    textField='title'
                    defaultItem={this.state.tempDefault} 
                    onChange={this.onTemplateChange} 
                    popupSettings={style} /><br /><br />
              <label>Send To: </label>
              <MultiSelect data={filterBy(this.props.data, this.state.filter)} 
                textField="emailDisplay"
                onChange={(e)=>this.onUsersChange(e, 'usersSelected')}
                filterable={true}
                onFilterChange={e=>this.setState({filter:e.filter})}
              />
              <label>CC: </label>
              <MultiSelect data={filterBy(this.props.data, this.state.ccFilter)} 
                textField="emailDisplay"
                onChange={(e)=>this.onUsersChange(e, 'ccSelected')}
                filterable={true}
                onFilterChange={e=>this.setState({ccFilter:e.filter})}
              />
              <label>BCC: </label>
              <MultiSelect data={filterBy(this.props.data, this.state.bccFilter)} 
                textField="emailDisplay"
                onChange={(e)=>this.onUsersChange(e, 'bccSelected')}
                filterable={true}
                onFilterChange={e=>this.setState({bccFilter:e.filter})}
              />             
              <br/><br/>
              <label>Subject: </label><input type="text"  value={this.state.subjectText} onChange={this.onSubjectChange} placeholder="Subject"/><br /><br />
              
              <div className='htmlEditContainer'>
                <HTMLEditor onHTMLChange={this.onHTMLChange} template={this.state.templateText}/>
              </div>

              </div>

              <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Cancel
                </Button>

                <Button 
                  className="upload"  
                  onClick={(event)=>{this.props.sendEmail(this.state.usersSelected, this.state.ccSelected, this.state.bccSelected, this.state.subjectText, this.state.bodyText, ()=>{close(); this.clearState();});}}>
                      Send
                </Button>
              </div>

            </div>
          )}
        </Popup>
      );
  };

}
 

const style = {
    className: "top",
  };

export default EmailForm;