import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';

class EditWebRepProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      first:'',
      last:'',
      company:'',
      title:'',
      address:'',
      city:'',
      state:'',
      zip:'',
      country:'',
      website:'',
      phone:'',
      pms:'',
      pmsVersion:'',
      toms:'',
      tomsVersion:'',
      notifyUpdates:'',
      pmsServices:'',
      nonPmsServices:''
    }

    this.onInputChange = this.onInputChange.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.clearState = this.clearState.bind(this);
    this.editProfile = this.editProfile.bind(this);

  }

  componentDidMount(){
    this.getProfile();
  }

  clearState(){
    this.setState({
      first:'',
      last:'',
      company:'',
      title:'',
      address:'',
      city:'',
      state:'',
      zip:'',
      country:'',
      website:'',
      phone:'',
      pms:'',
      pmsVersion:'',
      toms:'',
      tomsVersion:'',
      notifyUpdates:'',
      pmsServices:'',
      nonPmsServices:''
    });
  }

  getProfile(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/webrepprofile", {headers}).then(response=>{
      if(response.data.code===200){
        this.setState({
          first:response.data.result.firstName,
          last:response.data.result.lastName,
          company:response.data.result.company,
          title:response.data.result.title,
          address:response.data.result.address,
          city:response.data.result.city,
          state:response.data.result.state,
          zip:response.data.result.zip,
          country:response.data.result.country,
          website:response.data.result.website,
          phone:response.data.result.phone,
          pms:response.data.result.pms,
          pmsVersion:response.data.result.pmsVersion,
          toms:response.data.result.toms,
          tomsVersion:response.data.result.tomsVersion,
          notifyUpdates:response.data.result.notifyUpdates,
          pmsServices:response.data.result.pmsServices,
          nonPmsServices:response.data.result.nonPmsServices
        });
      }
      else{
        alert('Error: could not retrieve profile info');
      }
    }).catch((err)=>console.log(err));
  }

  editProfile(e, callback){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      first:this.state.first,
      last:this.state.last,
      company:this.state.company,
      title:this.state.title,
      address:this.state.address,
      city:this.state.city,
      state:this.state.state,
      zip:this.state.zip,
      country:this.state.country,
      website:this.state.website,
      phone:this.state.phone,
      pms:this.state.pms,
      pmsVersion:this.state.pmsVersion,
      toms:this.state.toms,
      tomsVersion:this.state.tomsVersion,
      notifyUpdates:this.state.notifyUpdates,
      pmsServices:this.state.pmsServices,
      nonPmsServices:this.state.nonPmsServices
    }

    axios.post("api/webrepprofile?company="+this.state.company, payload, {headers}).then(response=>{
      if(response.data.code===200){
        if(callback)
          callback();
      }
      else{
        alert('Error: could not update profile info');
      }
    }).catch((err)=>console.log(err));
  }

  onInputChange(e, attr){
    if(attr==='notifyUpdates' || attr==='pmsServices' || attr==='nonPmsServices')
      this.setState({[attr]:!this.state[attr]});
    else
      this.setState({[attr]:e.target.value});
  }

  render() {
      return (
        <div className='editProfile'>
          <Popup closeOnDocumentClick={false} trigger={this.props.button} onOpen={(e)=>this.getProfile()} onClose={(event) => this.clearState()} overlayStyle={{ zIndex: 5 }}  modal>
            {close => (
              <div className="modal">

                <Button className="close" icon="close" onClick={(e)=>{close(); this.clearState();}} />


                <div className="header" align="left">
                  Edit Profile
                </div>

                <div className="content">
                  <table style={{width:'100%'}}>
                    <tbody>
                      <tr>
                        <td><strong>First Name: </strong></td>
                        <td className='right'><Input value={this.state.first} onChange={(e)=>this.onInputChange(e, 'first')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Last Name: </strong></td>
                        <td className='right'><Input value={this.state.last} onChange={(e)=>this.onInputChange(e, 'last')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Company: </strong></td>
                        <td className='right'><Input value={this.state.company} onChange={(e)=>this.onInputChange(e, 'company')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Title: </strong></td>
                        <td className='right'><Input value={this.state.title} onChange={(e)=>this.onInputChange(e, 'title')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Address: </strong></td>
                        <td className='right'><Input value={this.state.address} onChange={(e)=>this.onInputChange(e, 'address')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>City: </strong></td>
                        <td className='right'><Input value={this.state.city} onChange={(e)=>this.onInputChange(e, 'city')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>State: </strong></td>
                        <td className='right'><select size="1" value={this.state.state} onChange={(e)=>this.onInputChange(e,'state')}>
                              <option>--</option><option>AL</option><option>AK</option>
                              <option>AZ</option><option>AR</option><option>CA</option>
                              <option>CO</option><option>CT</option><option>DE</option>
                              <option>DC</option><option>FL</option><option>GA</option>
                              <option>HI</option><option>ID</option><option>IL</option>
                              <option>IN</option><option>IA</option><option>KS</option>
                              <option>KY</option><option>LA</option><option>ME</option>
                              <option>MD</option><option>MA</option><option>MI</option>
                              <option>MN</option><option>MS</option><option>MO</option>
                              <option>MT</option><option>NE</option><option>NV</option>
                              <option>NH</option><option>NJ</option><option>NM</option>
                              <option>NY</option><option>NC</option><option>ND</option>
                              <option>OH</option><option>OK</option><option>OR</option>
                              <option>PA</option><option>RI</option><option>SC</option>
                              <option>SD</option><option>TN</option><option>TX</option>
                              <option>UT</option><option>VT</option><option>VA</option>
                              <option>WA</option><option>WV</option><option>WI</option>
                              <option>WY</option>
                              <option>--</option>
                              <option>AB</option><option>BC</option><option>MB</option>
                              <option>NB</option><option>NF</option><option>NT</option>
                              <option>NS</option><option>NU</option><option>ON</option>
                              <option>PE</option><option>QC</option><option>SK</option>
                              <option>YT</option>
                          </select></td>
                      </tr>
                      <tr>
                        <td><strong>Zip: </strong></td>
                        <td className='right'><Input value={this.state.zip} onChange={(e)=>this.onInputChange(e, 'zip')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Country: </strong></td>
                        <td className='right'><Input value={this.state.country} onChange={(e)=>this.onInputChange(e, 'country')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Website: </strong></td>
                        <td className='right'><Input value={this.state.website} onChange={(e)=>this.onInputChange(e, 'website')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Phone Number: </strong></td>
                        <td className='right'><Input value={this.state.phone} onChange={(e)=>this.onInputChange(e, 'phone')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Portfolio Management System: </strong></td>
                        <td className='right'><Input value={this.state.pms} onChange={(e)=>this.onInputChange(e, 'pms')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong className='indent'>Verison: </strong></td>
                        <td className='right'><Input value={this.state.pmsVersion} onChange={(e)=>this.onInputChange(e, 'pmsVersion')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong>Trade Order Management System: </strong></td>
                        <td className='right'><Input value={this.state.toms} onChange={(e)=>this.onInputChange(e, 'toms')}></Input></td>
                      </tr>
                      <tr>
                        <td><strong  className='indent'>Verison: </strong></td>
                        <td className='right'><Input value={this.state.tomsVersion} onChange={(e)=>this.onInputChange(e, 'tomsVersion')}></Input></td>
                      </tr>
                      <tr>
                        <td colSpan='2'><input type="checkbox" checked={this.state.notifyUpdates} onChange={e=>this.onInputChange(e,'notifyUpdates')}/>Notify me when new/updated reports are added.</td>   
                      </tr>
                      <tr>
                        <td colSpan='2'><input type="checkbox" checked={this.state.pmsServices} onChange={e=>this.onInputChange(e,'pmsServices')}/>Interested in Portfolio Management System related services.</td>
                      </tr>
                      <tr>
                        <td colSpan='2'><input type="checkbox" checked={this.state.nonPmsServices} onChange={e=>this.onInputChange(e,'nonPmsServices')}/>Interested in non-Portfolio Management System related services.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="actions">

                  <Button
                    className="button"
                    onClick={() => {
                      close(); this.clearState();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button 
                    className="upload"  
                    onClick={(e)=>{this.editProfile(e,close);}}
                  >
                        Save Changes
                  </Button>

                </div>

              </div>
            )}
          </Popup>
        </div>
      );
  };
}

export default EditWebRepProfile;