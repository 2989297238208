import React, { Component } from "react";
import ColorButton from './ColorButton';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';

class ChartColors extends Component {

  constructor(props) {
    super(props);

    this.state = {
        update:false
    }

  }

  addColor = (e)=>{
      var arr = this.props.colors.slice();
      arr.push({ id: arr.length, color:{r:'0',g:'0',b:'0',a:'1'}, mapping: '', type: this.props.colorListLabels ? 'labels' : null });
      this.setState({update:false});
      this.props.onChange(arr);
  }

  onDelete = (e, index)=>{
    var arr = this.props.colors.slice();
    console.log(index)
    console.log(arr)
    arr.splice(index,1);
    console.log(arr)
    this.setState({update:true}, ()=>this.setState({update:false}));
    this.props.onChange(arr);
  }

  onChange = (e, index)=>{
    this.setState({update:false});
    var arr = this.props.colors.slice();
    arr[index].color=e.rgb;
    this.props.onChange(arr);
  }

  onMapChange = (e,index)=>{
    this.setState({update:false});
    var arr = this.props.colors.slice();
    arr[index].mapping=e.target.value;
    this.props.onChange(arr);
  }


  render() {

    var data = this.props.colors;
    
    return(
        <div className='chartColorList' style={{position:'relative'}}>
        {!this.props.colorListLabels && data.map((color, i) => (
            <div key={i}><ColorButton defaultColor={color.color} update={this.state.update} onChange={(e)=>this.onChange(e,i)}/><Button style={{verticalAlign:'top', marginLeft:'5px', height:'auto', width:'auto'}} onClick={(e)=>this.onDelete(e, i)}icon='close'></Button></div>
        ))}
        {this.props.colorListLabels && data.map((color, i) => (
            <div key={i}><ColorButton defaultColor={color.color} update={this.state.update} onChange={(e)=>this.onChange(e,i)}/><Input defaultValue={color.mapping} placeholder='Asset Class/Sector' onChange={(e)=>this.onMapChange(e,i)} style={{verticalAlign:'top', marginLeft:'5px', height:'auto', width:'auto'}}/><Button style={{verticalAlign:'top', marginLeft:'5px', height:'auto', width:'auto'}} onClick={(e)=>this.onDelete(e, i)}icon='close'></Button></div>
        ))}
        <Button icon='plus' onClick={this.addColor}>New Color</Button>
        </div>
    );
  }

}

export default ChartColors;