import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import demographicFields from './demographicsFields.json';

class DemographicMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:{RunDate:'',
                EntityCode:'',
                Name1:'',
                Name2:'',
                Name3:'',
                EntityType:'',
                EntityPurpose:'',
                AccountingReportingCurrency:'',
                AccountingReconciliationCloseDate:'',
                DomicileCountry:'',
                EntityStartDate:'',
                EntityCloseDate:'',
                Parent:'',
                EntryDate:'',
                ExitDate:'',
                Comment:'',
                CustodianAccountNumber:'',
                Custodian:'',
                AccountType:'',
                TaxStatus:'',
                Address1:'',
                Address2:'',
                City:'',
                State:'',
                Zip:'',
                Country:'',
                Phone:'',
                Footnote1:'',
                Footnote2:'',
                Footnote3:'',
                BillingType:'',
                BillingName1:'',
                BillingName2:'',
                BillingName3:'',
                BillingAddress1:'',
                BillingAddress2:'',
                BillingCity:'',
                BillingState:'',
                BillingZip:'',
                BillingCountry:'',
                Bfund:'',
                BillingMethod:'',
                FlatFee:'',
                PaymentPeriod:'',
                MinimumFee:'',
                PastDueAmount:'',
                TaxRate:'',
                ReconcileDate:'',
                CustomTextField01:'',
                CustomTextField02:'',
                CustomTextField03:'',
                CustomTextField04:'',
                CustomTextField05:'',
                CustomTextField06:'',
                CustomTextField07:'',
                CustomTextField08:'',
                CustomTextField09:'',
                CustomTextField10:'',
                CustomTextField11:'',
                CustomTextField12:'',
                CustomTextField13:'',
                CustomTextField14:'',
                CustomTextField15:'',
                CustomTextField16:'',
                CustomTextField17:'',
                CustomTextField18:'',
                CustomTextField19:'',
                CustomTextField20:'',
                CustomDateField01:'',
                CustomDateField02:'',
                CustomDateField03:'',
                CustomDateField04:'',
                CustomDateField05:'',
                CustomDateField06:'',
                CustomDateField07:'',
                CustomDateField08:'',
                CustomDateField09:'',
                CustomDateField10:'',
                CustomDateField11:'',
                CustomDateField12:'',
                CustomDateField13:'',
                CustomDateField14:'',
                CustomDateField15:'',
                CustomDateField16:'',
                CustomDateField17:'',
                CustomDateField18:'',
                CustomDateField19:'',
                CustomDateField20:'',
                CustomNumericField01:'',
                CustomNumericField02:'',
                CustomNumericField03:'',
                CustomNumericField04:'',
                CustomNumericField05:'',
                CustomNumericField06:'',
                CustomNumericField07:'',
                CustomNumericField08:'',
                CustomNumericField09:'',
                CustomNumericField10:'',
                CustomNumericField11:'',
                CustomNumericField12:'',
                CustomNumericField13:'',
                CustomNumericField14:'',
                CustomNumericField15:'',
                CustomNumericField16:'',
                CustomNumericField17:'',
                CustomNumericField18:'',
                CustomNumericField19:'',
                CustomNumericField20:'',
                ModelCode:'',
                AccountingMethod:'',
                FiscalYearEnd:'',
                BillingPhone:'',
                Title:'',
                FirstName:'',
                MiddleName:'',
                LastName:'',
                Suffix:'',
                Company:'',
                Salutation:'',
                CC:'',
                Paid:'',
                BillingNote:'',
                BillingNote2:''
                }
    }

    this.getMappings = this.getMappings.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount(){
      this.getMappings();
  }

  onInputChange(e, attr){
    let mappings = {...this.state.mappings};
    mappings[attr] = e.target.value.toUpperCase();
    this.setState({mappings});
  }

  uploadMappings = (e)=>{

    if(this.state.mappings.RunDate===null || this.state.mappings.RunDate==='')
        alert("RunDate is required.");
    else if(this.state.mappings.EntityCode===null || this.state.mappings.EntityCode==='')
        alert("EntityCode is required.");
    else{
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload ={
            company:this.props.user.company,
            mappings:this.state.mappings
        }
        axios.post('api/demographicsMappings', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Demographics Mappings have been updated.");
            this.getMappings();
        }
        else
            alert("An error occured when trying to update mappings");
        }).catch(err=>{
        alert("An error occured when trying to update mappings");
        this.getMappings();
        });
    }
  }

  getMappings(){
    var emptyMappings = {
        RunDate:'',
        EntityCode:'',
        Name1:'',
        Name2:'',
        Name3:'',
        EntityType:'',
        EntityPurpose:'',
        AccountingReportingCurrency:'',
        AccountingReconciliationCloseDate:'',
        DomicileCountry:'',
        EntityStartDate:'',
        EntityCloseDate:'',
        Parent:'',
        EntryDate:'',
        ExitDate:'',
        Comment:'',
        CustodianAccountNumber:'',
        Custodian:'',
        AccountType:'',
        TaxStatus:'',
        Address1:'',
        Address2:'',
        City:'',
        State:'',
        Zip:'',
        Country:'',
        Phone:'',
        Footnote1:'',
        Footnote2:'',
        Footnote3:'',
        BillingType:'',
        BillingName1:'',
        BillingName2:'',
        BillingName3:'',
        BillingAddress1:'',
        BillingAddress2:'',
        BillingCity:'',
        BillingState:'',
        BillingZip:'',
        BillingCountry:'',
        Bfund:'',
        BillingMethod:'',
        FlatFee:'',
        PaymentPeriod:'',
        MinimumFee:'',
        PastDueAmount:'',
        TaxRate:'',
        ReconcileDate:'',
        CustomTextField01:'',
        CustomTextField02:'',
        CustomTextField03:'',
        CustomTextField04:'',
        CustomTextField05:'',
        CustomTextField06:'',
        CustomTextField07:'',
        CustomTextField08:'',
        CustomTextField09:'',
        CustomTextField10:'',
        CustomTextField11:'',
        CustomTextField12:'',
        CustomTextField13:'',
        CustomTextField14:'',
        CustomTextField15:'',
        CustomTextField16:'',
        CustomTextField17:'',
        CustomTextField18:'',
        CustomTextField19:'',
        CustomTextField20:'',
        CustomDateField01:'',
        CustomDateField02:'',
        CustomDateField03:'',
        CustomDateField04:'',
        CustomDateField05:'',
        CustomDateField06:'',
        CustomDateField07:'',
        CustomDateField08:'',
        CustomDateField09:'',
        CustomDateField10:'',
        CustomDateField11:'',
        CustomDateField12:'',
        CustomDateField13:'',
        CustomDateField14:'',
        CustomDateField15:'',
        CustomDateField16:'',
        CustomDateField17:'',
        CustomDateField18:'',
        CustomDateField19:'',
        CustomDateField20:'',
        CustomNumericField01:'',
        CustomNumericField02:'',
        CustomNumericField03:'',
        CustomNumericField04:'',
        CustomNumericField05:'',
        CustomNumericField06:'',
        CustomNumericField07:'',
        CustomNumericField08:'',
        CustomNumericField09:'',
        CustomNumericField10:'',
        CustomNumericField11:'',
        CustomNumericField12:'',
        CustomNumericField13:'',
        CustomNumericField14:'',
        CustomNumericField15:'',
        CustomNumericField16:'',
        CustomNumericField17:'',
        CustomNumericField18:'',
        CustomNumericField19:'',
        CustomNumericField20:'',
        ModelCode:'',
        AccountingMethod:'',
        FiscalYearEnd:'',
        BillingPhone:'',
        Title:'',
        FirstName:'',
        MiddleName:'',
        LastName:'',
        Suffix:'',
        Company:'',
        Salutation:'',
        CC:'',
        Paid:'',
        BillingNote:'',
        BillingNote2:''
    }
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/demographicsMappings?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.mappings.length===0)
          this.setState({mappings:emptyMappings});
      else
        this.setState({mappings:response.data.mappings[0]});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:emptyMappings})
    });
  }


  render() {
    var data = demographicFields;
    return(
        <div>
            <table className='posMapTable'>
                <tbody>
                    <tr>
                        <td className='heading'>Field Name <hr/></td>
                        <td align='right' className='heading'>Column Letter in Excel <hr/></td>
                    </tr>
                    {data.map(field => (
                    <tr key={field.id}>
                        <td><h4>{field.name}</h4><div>{field.desc}</div></td>
                        <td align='right'><input type='text' value={this.state.mappings[field.dbField]} onChange={e=>this.onInputChange(e,field.dbField)}/></td>                  
                    </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td align='right'><Button onClick={(e)=>this.uploadMappings(e)}>Apply Changes</Button></td>
                    </tr> 
                </tbody>
            </table>
        </div>
    );
  }

}

export default DemographicMapping;