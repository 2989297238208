import React, { Component } from "react";
import {Grid, GridColumn, GridNoRecords, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import InviteUser from '../InviteUser/InviteUser';
import { Button } from "@progress/kendo-react-buttons";
import DetailRow from './components/DetailRow';
import PushEmail from '../PushEmail/PushEmail';
import GridLoading from '../Loaders/GridLoading';
import {process} from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import UserGroups from '../UserGroups/UserGroups';
import {parseDate} from '@telerik/kendo-intl';
import LogList from '../LogList/LogList';
import { DropDownFilterCell } from "./components/DropDownFilterCell";

//import UploadForm from '../DocCenter/components/UploadForm';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class UserList extends Component {

  constructor(props) {
    super(props);

    this.minGridWidth = 0;

    const activeFilterCell = (props) => (
        <DropDownFilterCell {...props} fieldType="activeInactive" data={["(All)","Active","Inactive"]}/>
    );

    var columns = [{field:'nickname' , title: 'User', headerClass:'gridHeader', minWidth: 150},
    {field:'email' , title: 'Email', headerClass:'gridHeader', minWidth: 150},
    {field:'userRoles' , title: 'Role', headerClass:'gridHeader', width:225},
    {field:'verified' , headerClass:'gridHeader', filterable:false, filter: 'boolean', title:'Email Verified?', cell:(p) => this.createCell(p, 'email'), width:175},
    {field:'active' , title: 'Active', filterable:true, filter: 'boolean', filterCell: activeFilterCell, cell:(p) => this.createCell(p, 'active'), headerClass:'gridHeader', width:125},
    {field:'lastLogin' , title: 'Last Login', filter:'date', format:'{0:g}', headerClass:'gridHeader', width:225},
    {field:'profileCreation' , title: 'Profile Created', filter:'date', format:'{0:g}', headerClass:'gridHeader', width:225, index: 2},
  ]
     
    if(this.props.company==='cssi')
      columns.splice(2,0, {field:'searchCompany' , title: 'Company', headerClass:'gridHeader', width:150});

    this.state = {
        users:[],
        roles: [],
        result:[],
        refreshText:'',
        filterable:false,
        // filterable:this.props.compSettings.showFilters,
        groups:false,
        logs:false,
        columns:columns,
        dataState:{skip:0, take:25, sort:[{field:'lastLogin', dir:'desc'}]},
        enableMFA: null,
        templates: [],
        tempDefault: {title:'Default Template'},
        RIAFirmName: '',
        // advAllUsers: false
    }
    
    this.createCell = this.createCell.bind(this);
    this.sendResetLink = this.sendResetLink.bind(this);
    this.expandChange = this.expandChange.bind(this);
    this.changeActivate = this.changeActivate.bind(this);
    this.changeUserData = this.changeUserData.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getDate = this.getDate.bind(this);
    this.getSuperAdminSettings = this.getSuperAdminSettings.bind(this);
    this.getMailTemplates = this.getMailTemplates.bind(this);

  }

  componentDidMount(){
    this.getSuperAdminSettings();
    this.getMailTemplates();
    this.getStyleSettings()
    this.getUsers()
  }

  componentDidUpdate(){
    
  }

  getStyleSettings = (cb) => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = ['requireTermsAndConditions,RIAFirmName,showFilters']

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
        const termsAcceptedFilterCell = (props) => (
            <DropDownFilterCell {...props} fieldType="yesNo" data={["(All)","Yes","No"]}/>
            // <DropDownFilterCell {...props} data={[{operator: '', text: "(All)"},{operator: true, text: "Active"},{operator: false, text: "Inactive"}]}/>
        );
        this.setState((prevState) => {
            let obj = {
              ...prevState, 
              requireTermsAndConditions: response.data.styleSettings.requireTermsAndConditions, 
              filterable:response.data.styleSettings.showFilters, 
              /*advAllUsers: response.data.styleSettings.advAllUsers,*/ 
              RIAFirmName: response.data.styleSettings.RIAFirmName
            };
            if (response.data.styleSettings.requireTermsAndConditions) {
                obj.columns.push(
                    {field:'termsAccepted' , title: 'Terms Accepted', filterCell: termsAcceptedFilterCell, filterable: true, filter: 'boolean', headerClass:'gridHeader', width:150, cell:(p) => this.createCell(p, 'termsAccepted'), index: 1}
                );
                obj.columns = obj.columns.sort((a,b) => (a.index != null ? a.index : 0) - (b.index != null ? b.index : 0))
            }
            return obj;
        },() => {if (cb) cb()})
    })
    .catch(err => {
        console.error(err);
        {if (cb) cb()}
    })
}

  getSuperAdminSettings() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/superAdminSettings`, {
        method: 'GET',
        params: { company: this.props.user.company },
        headers: headers
    })
        .then(response => {
            response.data.data.forEach(setting => {
                if (setting.settingName === 'enableMFA'){
                    this.setState({enableMFA: setting.settingValue.toLowerCase() === 'true' ? true : false})
                }
            })

        })
        .catch(err => console.log(err));
  }

  getMailTemplates(){
    var defaultTemplate = "Portal Invite";
    
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/mailtemplate?company="+this.props.company, {headers}).then(response=>{   
      var filtered = response.data.filter(
        function(e) {
          return e.title !== defaultTemplate;
        }
      );
      this.setState({templates:filtered});
      if (filtered.length !== response.data.length)
      {
        this.setState({tempDefault: {title: defaultTemplate}})
      }
      else
      {
        this.setState({tempDefault: {title: "Default Template"}})
      }
    }).catch(err=>console.log(err));
  
  }




  getUsers(cb){
    this.setState({refreshText:'Loading...'});

    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var isAdvisor = this.props.user.role==='advisor' /*&& !this.state.advAllUsers*/;
   
    // axios.get("api/test?company="+this.props.company,{headers});

    axios.get("api/users?company="+this.props.company+"&advisor="+isAdvisor+"&userlevel="+this.props.user.userLevel, {headers}).then(response => {
      if(response.data.code===200){
        var profileCreationExists = [...response.data.users].filter(user => user.hasOwnProperty('profileCreation')).length > 0;
        var formattedUsers = this.formatUserList(response.data.users);
        this.setState((prevState) => {
            let obj = {...prevState}
            obj.users = formattedUsers;
            obj.roles = response.data.roles
              .sort((a,b) => (a.userLevel - b.userLevel))
              .map(item => {return ({id: item.guid, text: item.role})})
            obj.result = process(formattedUsers, this.state.dataState);
            obj.refreshText = '';
            if (profileCreationExists && obj.columns.findIndex(column => column.field === 'profileCreation') === -1) {
                obj.columns.push({field:'profileCreation' , title: 'Profile Created', filter:'date', format:'{0:g}', headerClass:'gridHeader', width:225, index: 2})
            }
            obj.columns = obj.columns.sort((a,b) => (a.index != null ? a.index : 0) - (b.index != null ? b.index : 0))
            return obj;
        }, ()=>{
          if(cb)
            cb();
        });
      }
      else{
        if(cb)
          cb();
        this.setState({refreshText:''});
        alert("An error occured while fetching the user list.");
        
      }
    }).catch(error => console.log(error));
  }

  refreshProfileCreationDates(cb){
    this.setState({refreshText:'Loading...'});

    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var isAdvisor = this.props.user.role==='advisor' /*&& !this.state.advAllUsers*/;
   
    // axios.get("api/test?company="+this.props.company,{headers});

    axios.get("api/updateUserProfileCreationDates?company="+this.props.company+"&advisor="+isAdvisor+"&userlevel="+this.props.user.userLevel, {headers}).then(response => {
      if(response.data.code===200){
        this.getUsers(cb);
      }
      else{
        if(cb)
          cb();
        this.setState({refreshText:''});
        alert("An error occured while refreshing the user profile creation dates.");
        
      }
    }).catch(error => console.log(error));
  }

  formatUserList(userList){
    var listWithRoles;
    listWithRoles = userList.slice();

    for(let i=0; i<listWithRoles.length; i++){

      listWithRoles[i].company=this.props.company;
      listWithRoles[i].compSettings = this.props.compSettings;
      listWithRoles[i].idToken = this.props.idToken;
      listWithRoles[i].changeActivate = this.changeActivate;
      listWithRoles[i].changeUserData = this.changeUserData;
      listWithRoles[i].refreshList = this.getUsers;
      listWithRoles[i].refreshUser = this.props.refreshUser;
      listWithRoles[i].lastLogin = this.getDate(listWithRoles[i].lastLogin);
      listWithRoles[i].profileCreation = this.getDate(listWithRoles[i].profileCreation);
      listWithRoles[i].onDocClick = this.props.onDocClick;
      listWithRoles[i].loggedInAsAdvisor = this.props.user.role==='advisor';
      listWithRoles[i].userLevel = this.props.user.userLevel;
      listWithRoles[i].permissionsArray = this.props.user.permissions;
    }
    
    return listWithRoles;
  }

  getDate(sqlDate){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    var utc = null;

    if(d!==null)
      utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes());

    return utc;
}

  sendResetLink(userID, email, role,nickname){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      userId:userID,
      to:email,
      uploadUser:this.props.user,
      company:this.props.user.company,
      role:role,
      nickname:nickname,
      RIAFirmName: this.state.RIAFirmName
    }
    axios.post("api/verifyEmail", payload, {headers}).then(response => {
      if(response.status===200)
        alert("Verification email has been sent.");
      else{
        alert("There was an error sending the verification email. Please try again.");
      }
    }).catch(error => console.log(error));     
  }

  createCell(cell, type){
    if(type==='email'){
      if(cell.dataItem.verified===true){
        return (<td>Verified</td>);
      }
      else{
        return (<td><Button icon="email" onClick = {(event) => this.sendResetLink(cell.dataItem.id, cell.dataItem.email, cell.dataItem.role, cell.dataItem.nickname)}>Send Verify Email</Button></td>);
      }
    }
    else if(type==='active'){
      if(cell.dataItem.active===true){
        return (<td>Active</td>);
      }
      else{
        return (<td>Inactive</td>);
      }
    }
    else if(type==='termsAccepted'){
      if(cell.dataItem.termsAccepted===true){
        return (<td>Yes</td>);
      }
      else{
        return (<td>No</td>);
      }
    }
  }

  changeActivate(email, active){
    var arr = this.state.users.slice();

    var indexOfUser = arr.findIndex((item)=>{
      return item.email === email;
    });

    arr[indexOfUser].active = active;
    this.setState({users:arr});
  }

  changeUserData(email, newRole, newViews, approved){
    var arr = this.state.users.slice();

    var indexOfUser = arr.findIndex(function(item){
      return item.email===email
    });

    if(indexOfUser!==-1){
      arr[indexOfUser].role=newRole;
      arr[indexOfUser].access=newViews;
      arr[indexOfUser].approved=approved;
    }
    this.setState({users:arr});
  }

  expandChange = (event) => {
    event.dataItem.expanded = !event.dataItem.expanded;
    event.dataItem.enableMFA = this.state.enableMFA
    event.dataItem.templates = this.state.templates
    event.dataItem.tempDefault = this.state.tempDefault
    this.forceUpdate();
  }

  _export;

  export = () =>{
      console.log('save',this._export)
    this._export.save();
  }

  getFullFilteredList(){//for excel export, otherwise only the amount of users on a single page would be exported
    let noSkipDataState = {...this.state.dataState}

    noSkipDataState.skip = null
    noSkipDataState.take = null
    
    return process(this.state.users.map(item => { return {...item, termsAccepted: (item.termsAccepted != null ? item.termsAccepted : false)}}), noSkipDataState);
    //return process(this.state.users, noSkipDataState);
  }

  changeDataState = (e)=>{
    var data = {...e.data}
    if (data.filter != null && data.filter.filters != null) {
        data.filter.filters.forEach(item => {
            if (item.field === 'active' && item.value === 'Active') item.value = true;
            if (item.field === 'active' && item.value === 'Inactive') item.value = false;
            if (item.field === 'termsAccepted' && item.value === 'Yes') item.value = true;
            if (item.field === 'termsAccepted' && item.value === 'No') item.value = false;
        })
        data.filter.filters = data.filter.filters.filter(item => item.value !== '(All)');
    }
    var newData = process(this.state.users.map(item => { return {...item, termsAccepted: (item.termsAccepted != null ? item.termsAccepted : false)}}), data);
    this.setState({dataState:data, result:newData});
  }

  render() {
    return(       
        <div>
          {!this.state.groups && !this.state.logs && (
          <div className="userList">
            <ExcelExport data={this.state.result.data != null && this.state.result.data.length > 0 ? JSON.parse(JSON.stringify(this.getFullFilteredList().data)).map(item => {
                item.active = (item.active ? 'Active' : 'Inactive');
                item.verified = (item.verified ? 'Verified' : 'Unverified');
                item.termsAccepted = (item.termsAccepted ? 'Yes' : 'No');
                if (item.lastLogin != null && item.lastLogin !== '') item.lastLogin = new Date(item.lastLogin);
                if (item.profileCreation != null && item.profileCreation !== '') item.profileCreation = new Date(item.profileCreation);
                return item;
            }) : []} fileName='Users_Export.xlsx' ref={(exporter)=>{this._export = exporter;}}>
                <ExcelExportColumn field="nickname" title="User" />
                <ExcelExportColumn field="email" title="Email" />
                {(this.props.company==='cssi') && <ExcelExportColumn field="searchCompany" title="Company" width={280}/>}
                <ExcelExportColumn field="userRoles" title="Role" width={225}/>
                <ExcelExportColumn field="verified" title="Verified?" width={100} />
                <ExcelExportColumn field="active" title="Active" width={100} />
                <ExcelExportColumn field="lastLogin" title="Last Login" cellOptions={{format:'m/d/yyyy h:mm AM/PM'}} width={225}/>
                {this.state.requireTermsAndConditions ? <ExcelExportColumn field="termsAccepted" title="Terms Accepted" width={250} /> : null}
                <ExcelExportColumn field="profileCreation" title="Profile Created" cellOptions={{format:'m/d/yyyy h:mm AM/PM'}} width={225} />
            </ExcelExport>
            <Grid data={this.state.result}
                  onRowClick={this.expandChange}
                  detail={DetailRow}
                  resizable={true}
                  reorderable={true}
                  pageable={{pageSizes:[10,25,50,100,250,500]}}
                  sortable={true}
                  onDataStateChange={this.changeDataState}
                  expandField="expanded"
                  onExpandChange={this.expandChange}
                  filterable={this.state.filterable}
                  {...this.state.dataState}>
                <GridNoRecords>
                  {this.state.refreshText && ''}
                  {!this.state.refreshText && 'No records available'}
                </GridNoRecords>
                <GridToolbar>
                  <Button icon='refresh' title='Refresh' onClick={(e)=>this.getUsers()}>{this.state.refreshText}</Button>
                  <Button icon='filter' title='Filter' onClick={(e)=>this.setState({filterable:!this.state.filterable})}>Filter</Button>
                  <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
                  {/* <Button icon="email" title='Export to Excel ' onClick={this.export}>Export</Button> */}
                  {!this.state.logs && <Button title='View Logs' onClick={(e)=>this.setState({logs:true, groups:false})}>View Logs</Button>}     
                  {this.props.user.role==='admin' && <Button onClick={(e)=>this.setState({groups:!this.state.groups})}>Manage Groups</Button>}
                  {this.props.user.role==='admin' &&  <Button disabled={this.state.users.filter(user => user.profileCreation == null).length === 0} onClick={(e)=>this.refreshProfileCreationDates(()=>{})}>Refresh Profile Creation</Button>}
                  {/*this.props.user.role==='admin' && <UploadForm idToken={this.props.idToken} company={this.props.user.company} user={this.props.user.user} uploadUser={this.props.user} type='bulk'  button={<Button className="docButton" icon="upload"> Bulk Upload Client Files</Button>} />*/}         
                  <PushEmail company={this.props.company} idToken={this.props.idToken} users={this.state.users}/>               
                  <InviteUser compSettings={this.props.compSettings} company={this.props.company} roles={this.state.roles} role={this.props.role} user={this.props.user} idToken={this.props.idToken} refreshUsers={this.getUsers} RIAFirmName={this.state.RIAFirmName}/>
                </GridToolbar>
                {
                this.state.columns.map((column, key)=>{
                  return <GridColumn field={column.field} title={column.title} key={key} format={column.format} cell={column.cell} filterable={column.filterable} filterCell={column.filterCell} filter={column.filter} headerClassName={column.headerClass} width={column.width} />
                })
                }
              </Grid>
            {this.state.refreshText && <GridLoading gridClass='userList'/>}
          </div>)
          }

          {this.state.logs && 
            <LogList idToken={this.props.idToken} company={this.props.user.company} filterable={this.state.filterable} compSettings={this.props.compSettings} showBack={true} onBack={(e)=>this.setState({logs:false, groups:false},()=>{})}/>
          }

          {this.state.groups && (
            <UserGroups back={(e)=>this.setState({groups:false})} user={this.props.user} idToken={this.props.idToken} size='Full'/>
          )}
        </div>
    );
  }

}

export default UserList;