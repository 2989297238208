import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import GridPopup from './GridPopup';
//import { Upload } from '@progress/kendo-react-upload';
import { process } from '@progress/kendo-data-query';
//import { Input } from '@progress/kendo-react-inputs';
import UnmappedAccounts from './UnmappedAccounts';

class AccountMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        files:[],
        previewMappings:[],
        mappings:[],
        unmapped:[],
        edit:false,
        result:[],
        filter:'',
        showMapped:false,
        buttonText:'View Mapped Accounts',
        dataState:{group:[{field:"email"}], filter:[]},
    }

    this.getAcctMappings=this.getAcctMappings.bind(this);
    this.clearState=this.clearState.bind(this);
    this.newRender = this.newRender.bind(this);
    this.onGroupExpandClick = this.onGroupExpandClick.bind(this);
    this.addMappingEmail = this.addMappingEmail.bind(this);
  }

  componentDidMount(){
    this.getAcctMappings();
  }

  clearState(){
    this.setState({
        files:[],
        previewMappings:[],
        mappings:[],
        edit:false
    });
  };

  onFilterChange = (e)=>{
    var newDataState = {group:[{field:"email"}], filter:{logic: 'or',
        filters:[
        {field: 'email', operator: 'contains', value:e.value},
        {field: 'account', operator: 'contains', value:e.value},
        {field: 'displayName', operator: 'contains', value:e.value}
      ]}
    };

    var newResult = process(this.state.mappings, newDataState);

    //expand when searching
    for(let i=0; i<newResult.data.length; i++){  
        newResult.data[i].expanded = true;
    }

    this.setState({filter:e.value, dataState:newDataState, result:newResult});
  }

  onAdd = (e)=>{
      this.setState({files:e.newState});
  }

  onRemove = (e)=>{
    this.setState({files:e.newState});
  }

  onStatusChange = (e)=>{
      if(e.response.response.code===200){
        e.newState[0].progress=100;
        e.newState[0].status=4;
        this.setState({previewMappings:e.response.response.mapping});
      }
      else{
        e.newState[0].progress=0;
        e.newState[0].status=0;
        e.newState[0].errorLine = e.response.response.errorLine;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({files:e.newState});
  }

  onBeforeUpload = (e)=>{
    e.additionalData.company=this.props.user.company;
  }

  uploadAcctMappings(e, maps){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      mappings:maps
    }
    axios.post('api/accountMappings', payload, {headers}).then(response=>{
      if(response.data.code===200){
        alert("Account mapppings have been successfully uploaded.");
        this.clearState();
        this.getAcctMappings();
      }
      else if(response.data.code===304){
        alert(response.data.error);
      }
      else{
        alert("An error has occurred while uploading. Please try again.");
        this.clearState();
        this.getAcctMappings();
      }
    }).catch(err=>{
      alert("An error has occurred while uploading. Please try again.");
      this.clearState();
      this.getAcctMappings();
      console.log(err);
    });
  }

  getAcctMappings(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    this.setState({loading:true});

    axios.get('api/unmappedAccts?company='+this.props.user.company, {headers}).then(response=>{
      this.setState({unmapped:response.data.unmapped, loading:false});
    }).catch(err=>{
      console.log(err);
      this.setState({unmapped:[], loading:false});
    });
  }

  gridItemChange = (e) => {

    e.dataItem[e.field] = e.value;

    var originalIndex = this.state.result.data.findIndex(item=>{
      return item.value === e.dataItem.email;
    });

    var mappings = this.state.mappings.slice();

    var mapIndex = mappings.findIndex(mapping=>{
      return mapping.id === e.dataItem.id;
    });

    if(mapIndex>=0)
      mappings[mapIndex][e.field] = e.value;

    var result = process(mappings, this.state.dataState);

    var newIndex = result.data.findIndex(item=>{
      return item.value === e.dataItem.email;
    });

    var currMapping;

    if(originalIndex === -1 || (mapIndex>=0 && mappings[mapIndex].new===true)){
      currMapping = result.data.splice(newIndex, 1);
      result.data.splice(0,0,currMapping[0]);
    }
    else if(mapIndex>=0 && mappings[mapIndex].newEmailMap===true){
        currMapping = result.data.splice(newIndex, 1);
        result.data.splice(originalIndex,0,currMapping[0]);
    }

    //keep collapsed/expanded from previous state
    for(let i=0; i<result.data.length; i++){  
      var ind = this.state.result.data.findIndex(item=>{
        return item.value===result.data[i].value;
      });
      
      if(ind>=0)
        result.data[i].expanded = this.state.result.data[ind].expanded;
      else
        result.data[i].expanded = true;
    }

    this.setState({
        mappings: mappings, result:result
    });
  }

  editGrid = (e) =>{
    var isEdit = !this.state.edit;
    var editMaps = this.state.mappings.slice();
    for(let i=0; i<editMaps.length; i++){
      editMaps[i].inEdit = isEdit;
      
      if(isEdit===false){
        editMaps[i].new = false;
        editMaps[i].newEmailMap = false;
      }
    }
    this.setState({mappings:editMaps, edit:isEdit});
  }

  addMapping = (e) =>{

    var resetDataState = {group:[{field:"email"}], filter:[]};
    var maps = this.state.mappings.slice();
    var id = 1;
    if(maps.length>0){
      id = maps[maps.length-1].id+1
    }
    for(let i=0; i<maps.length; i++){
      maps[i].new=false;
    }
    maps.push({id:id, email:"", account:"", inEdit:true, new:true});

    var newResult = process(maps, resetDataState);
    //keep collapsed/expanded from previous state
    for(let i=0; i<newResult.data.length; i++){

      var ind = this.state.result.data.findIndex(item=>{
        return item.value===newResult.data[i].value;
      });

      if(ind>=0)
        newResult.data[i].expanded = this.state.result.data[ind].expanded;
      else
        newResult.data[i].expanded = true;
    }

    this.setState({mappings:maps, result:newResult, dataState:resetDataState, filter:''});
  }

  addMappingEmail(email){
    var maps = this.state.mappings.slice();
    var id = 1;
    if(maps.length>0){
      id = maps[maps.length-1].id+1
    }

    var newResult = this.state.result;

    var index = newResult.data.findIndex(x=>{
      return x.value === email;
    });

    newResult.data[index].items.push({id:id, email:email, account:"", inEdit:true, newEmailMap:true});
    newResult.data[index].expanded = true;

    maps.push({id:id, email:email, account:"", inEdit:true, newEmailMap:true});

    this.setState({mappings:maps, result:newResult});
  }

  deleteMapping(e, cell){
    var maps = this.state.mappings.slice();
    var index = maps.findIndex(x=>{
      return (x.id===cell.dataItem.id);
    });
    if(index>=0){
      maps.splice(index, 1);
      var result = process(maps, this.state.dataState);

      //keep collapsed/expanded from previous state
      for(let i=0; i<result.data.length; i++){

        var ind = this.state.result.data.findIndex(item=>{
          return item.value===result.data[i].value;
        });

        result.data[i].expanded = this.state.result.data[ind].expanded;
      }

      this.setState({mappings:maps, result:result});
    }
  }


  createCell(cell){
    if(cell.rowType==='data'){
      if(this.state.edit){
        return (
          <td>
            <Button icon="trash" onClick = {(event) => this.deleteMapping(event, cell)}>Delete?</Button>
          </td>
        );
      }
      else{
        return (
          <td>
          </td>
        );
      }
    }
    else{
      return (
        <></>
      );
    }
  }

  onGroupExpandClick(e){
    e.dataItem.expanded = !e.dataItem.expanded;
    this.forceUpdate();
  }

  newRender(tdElement, cell){
    if(cell.rowType==='groupHeader'){
      if(cell.field==='value'){
        return(
          <td colSpan="4" style={{position: 'sticky', left: '0px', right: '0px', zIndex: '1', background: 'rgb(246, 246, 246)'}}>
            {// eslint-disable-next-line
            <p className="k-reset"><a tabIndex="-1" className={cell.dataItem.expanded ? "k-i-collapse k-icon" : "k-i-expand k-icon"} onClick={(e)=>this.onGroupExpandClick(cell)}></a>{cell.dataItem.value}</p>
            }
          </td>
        );
      }
      if(cell.field==='email'){
        if(!this.state.edit){
          return(
            <td colSpan="1" style={{background: 'rgb(246, 246, 246)'}}>
            </td>
          );
        }
        else{
          return(
            <td colSpan="1" style={{background: 'rgb(246, 246, 246)'}}>
              <Button icon="add" onClick={(e)=>this.addMappingEmail(cell.dataItem.items[0].email)}>Add Mapping</Button>
            </td>
          );
        }
      }
    }
    else if(cell.rowType==='data'){
      if(cell.field==='email' && cell.dataItem.new!==true){
        return(
          <td>
            {cell.dataItem.email}
          </td>
        );
      }
    }

    return tdElement;
  }

  renderFile = (props)=>{
    const { files } = props;

      return (
      <ul>
          {
              files.map(file =>{
                return file.error ?
                <>
                <div style={{color:'red'}}key={file.name}>
                  <div>{file.name}</div>
                  <div>Error on line: {file.errorLine}</div>
                  <div>Error: {file.error}</div>
                </div>
                </>
                :
                <div style={{color:'green'}}key={file.name}>
                  <div>{file.name}</div>
                  <GridPopup button={<Button style={{textAlign:'left', marginRight:'10px'}}>Preview?</Button>}
                             grid={<Grid style={{height:'40vh'}} data={this.state.previewMappings}>
                                    <GridToolbar>
                                      <div>Preview Mappings</div>
                                    </GridToolbar>
                                        <GridColumn field='email' title='Email' />
                                        <GridColumn field='account' title='Account/Portfolio' />
                                        <GridColumn field='displayName' title='Display Name' />
                                    </Grid>}/>
                  <Button style={{textAlign:'right', marginLeft:'10px'}} onClick={e=>this.uploadAcctMappings(e, this.state.previewMappings)}>Confirm Upload</Button>
                  </div>
              })          
          }
      </ul>);
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

  onShowMapClick = (e)=>{
    if(this.state.showMapped){
      this.setState({showMapped:false, buttonText:'View Mapped Accounts'});
    }
    else{
      this.getAcctMappings();
      this.setState({showMapped:true, buttonText:'View Unmapped Accounts'});
    }
  }

  render() {
    return(
        <div>
            
            {/*  
            <br/>
           {!this.state.loading && this.state.unmapped.length>0 && <strong style={{color:'red', marginLeft:'5px'}}>{this.state.unmapped.length} Unmapped Accounts (Most Recent Position File)</strong>}
            <br/>
            */}
            
            {!this.state.showMapped && <UnmappedAccounts user={this.props.user} idToken={this.props.idToken} previewUnmapped={this.props.previewUnmapped}/>}
        </div>
    );
  }

}

export default AccountMapping;