import React, { Component } from "react";
import {Switch} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import RolePermissionsPopUp from "./RolePermissionsPopUp";

class ViewsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  isDefaultChecked(view){
    if(view.page==='docTab6' && this.props.approved===false){
      return false;
    }
    if(this.props.views.indexOf(view.page)!==-1){
      return true;
    }
    if(this.props.views.indexOf(view.name)!==-1){
      return true;
    }
    else{
      return false;
    }
  }

  render() {
    var data = this.props.data;
      
    return (
        <table className="viewTable">
            <tbody>
                {data.map((view, i)=> (
                <tr key={i} style={{background:'none'}}>
                    <td style={{background:'none'}}><label key={view.name}>{view.name}</label></td>
                    <td style={{background:'none'}}><Switch onChange={(e)=>this.props.onChange(view, e)} defaultChecked={this.isDefaultChecked(view)}/></td>
                    {this.props.hidePermissions == null && <td style={{background:'none'}}><RolePermissionsPopUp permissions={view.permissions} title={view.name} button={<Button className='docButton'>View Permissions ({view.permissions != null ? view.permissions.length : 0})</Button>}/></td>}
                </tr>
                ))}
            </tbody>
        </table>
 
    );
  }
}

export default ViewsList;