import React, { Component } from "react";

class LogoUpload extends Component {

    constructor(props){
        super(props)
        this.state = {

        }
      }

    render() {
    return (
        <div>
            <input type="file" acccept="image/*" onChange={this.props.onChangeLogo}/>           
        </div>
    );
    }
    
}

export default LogoUpload;