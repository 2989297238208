import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import HTMLEditor from '../../PushEmail/components/JoditEditor'

class CreateTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
        title:'',
        subject:'',
        body:'',
        initialLoad: true,
        htmlModified: false
      }

    this.uploadTemplate = this.uploadTemplate.bind(this);
    this.clearState = this.clearState.bind(this);
    this.getEditField = this.getEditField.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onHTMLChange = this.onHTMLChange.bind(this);
    this.getHTMLTemplate = this.getHTMLTemplate.bind(this);
  }

  onFieldChange(e, field){
    this.setState({[field]:e.target.value});
  }

  onHTMLChange(html){
    this.setState({body:html, htmlModified:true});
  }

  getHTMLTemplate(){
    if(this.props.tempEdit && !this.state.htmlModified){
      return this.props.tempEdit.body;
    }
    else{
      return '';
    }
  }

  getEditField(field){
    if(this.props.tempEdit && this.state.initialLoad){
      this.setState({[field]: this.props.tempEdit[field], initialLoad:false});
      return this.props.tempEdit[field];
    }
    else{
      return '';
    }
  }

  uploadTemplate(callback){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload={
        title:this.state.title,
        company:this.props.user.company,
        subject:this.state.subject,
        body:this.state.body
    }
    //add id field if editing template
    if(this.props.tempEdit){
      payload.id = this.props.tempEdit.id;
    }

    axios.post("api/mailtemplate", payload, {headers}).then(response=>{
      console.log(response);
      if(response.data.code===301){
        alert("Error: A template with the same name already exists!");
      }
      else{
        if(response.data.code===200){
          alert("Template created successfully.");
        }
        else if(response.data.code===201){
          alert("Template updated successfully.");
        }
        else{
          alert("An error occured with the template.");
        }
        this.props.uploadMethod();
        this.clearState();
        callback();
      }         
    }).catch(err=>{alert("An error occured with the template."); console.log(err); this.props.uploadMethod(); this.clearState(); callback();});
  }

  clearState(){
    this.setState({
      title:'',
      subject:'',
      body:'',
      htmlModified:false,
      initialLoad:true
    })
  }


  render() {

      return (
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{close();}} />


              <div className="header" align="left">
                New Mail Template
              </div>

              <div className="content">
              <label>Template Name: </label><input type="text" value={this.state.title || this.getEditField('title')} onChange={(e)=>this.onFieldChange(e, 'title')} placeholder={"Template Name"}/><br /><br />
              <label>Subject: </label><input type="text"  value={this.state.subject || this.getEditField('subject')} onChange={(e)=>this.onFieldChange(e, 'subject')} placeholder="Subject"/><br /><br />
              {//<textarea className='textbox' value={this.state.body || this.getEditField('body')} onChange={(e)=>this.onFieldChange(e, 'body')} placeholder='Enter body text' />
              }
              {
                <div className='htmlEditContainer'>
                  <HTMLEditor onHTMLChange={this.onHTMLChange} template={this.getHTMLTemplate()} editTemp={true} test={this.getEditField('body')}/>
                </div>
              }
              </div>

              <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Cancel
                </Button>

                <Button 
                  className="upload"  
                  onClick={(event)=>{this.uploadTemplate(close)}}>
                      Create
                </Button>
              </div>

            </div>
          )}
        </Popup>

      );
  };
}

export default CreateTemplate;