import React, { Component } from "react";
import AccountSummary from '../components/AccountSummary';
import AllAccountSummary from '../components/AllAccountSummary';
import axios from 'axios';

class AccountSummaryDashboard extends Component {

  constructor(props) {
    super(props);

    var count = 0;
    if(this.props.styleSettings.acctPeriod1)
      count++;
    if(this.props.styleSettings.acctPeriod2)
      count++
    if(this.props.styleSettings.acctPeriod3)
      count++;

    var padding = '10%';

    if(count>1)
      padding = '7%';
    if(count>2)
      padding = '5%';
    
    this.state = {
      dates:[],
      selectedDate:'',
      portfolio:this.props.portfolio,
      perfType:'net',
      periods:[],
      period:this.props.styleSettings.acctPeriodMult || 'mtd',
      padding:padding,
      styleSettings: {
        accountSummaryshowNetCW: null, 
        accountSummaryShowBenefit: null, 
        accountSummaryShowTransfers: null,
        chartShowAsOf: null,
        chartEnableFeeSelect: null,
        chartDefaultFees: null,
        showConsolidated: null,
      }
    }

    this.getPerfDates = this.getPerfDates.bind(this);
    this.setPeriods = this.setPeriods.bind(this);
    this.firstUpper = this.firstUpper.bind(this);
    //this.getStyleSettings=this.getStyleSettings.bind(this);
  }

  componentDidMount(){
    this.getStyleSettings(() => {
      this.getPerfDates();
    });
    
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio){
      this.setState({portfolio:this.props.portfolio}, ()=>{this.getPerfDates()});
    }
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'accountSummaryshowNetCW',
      'accountSummaryShowBenefit', 
      'accountSummaryShowTransfers',
      'chartShowAsOf',
      'chartEnableFeeSelect',
      'chartDefaultFees',
      'showConsolidated'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings, perfType: (response.data.styleSettings.chartDefaultFees === 'Gross' ? 'gross' : 'net') }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }




  getPerfDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      port:this.state.portfolio.account,
      perfType:this.state.perfType,
      email:this.props.user.user,
      household:this.props.user.defaultAcct.household
    }
    axios.post('api/perfIRRDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }


  setPeriods(periods){
    this.setState({periods:periods});
  }

  setSelectedPeriod(p){
    this.setState({period:p});
  }

  firstUpper(s){
    return s.charAt(0).toUpperCase()+s.slice(1);
  }

  render() {
    
    return(
        <div className='accountSummaryOverview' style={{overflowY:'auto', overflowX:'auto'}}  >
          <span style={{float:'left', paddingLeft:this.state.padding}}>
            {this.state.styleSettings.chartEnableFeeSelect && <><label>Type: </label><select onChange={(e)=>this.setState({perfType:e.target.value})} value={this.state.perfType} style={{marginRight:'10px'}}>
              <option value='net'>Net</option>
              <option value='gross'>Gross</option>
            </select></>}
            {!this.state.styleSettings.chartEnableFeeSelect && <label style={{marginRight:'10px'}}>Type: {this.firstUpper(this.state.perfType)}</label>}
            {((this.props.portfolio.default && this.state.styleSettings.showConsolidated) || (!this.props.portfolio.default && this.props.portfolio.consolidated)) && <>
              <label>Period: </label><select onChange={(e)=>this.setState({period:e.target.value})} value={this.state.period}>
                    {this.state.periods.map((per, i)=>(
                      <option value={per.value} key={i}>{per.name}</option>
                    ))}
              </select>
            </>}
          </span>
          {this.state.styleSettings.chartShowAsOf && <span style={{float:'right', paddingRight:this.state.padding}}>
            <label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value})}>
              {this.state.dates.map((date, i)=>(
                <option key={i} value={date.fDate}>{date.fDate}</option>
              ))}
            </select>
          </span>}
          {!this.state.styleSettings.chartShowAsOf && <span style={{float:'right', paddingRight:this.state.padding}}>
            <label>As Of: {this.state.selectedDate}</label>
            </span>}
          <br/>
          <br/>
          {((this.props.portfolio.default && !this.state.styleSettings.showConsolidated) || (!this.props.portfolio.default && !this.props.portfolio.consolidated)) && <AccountSummary user={this.props.user} padding={this.state.padding} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} perfType={this.state.perfType} portfolio={this.props.portfolio} date={this.state.selectedDate} idToken={this.props.idToken} />}
          {((this.props.portfolio.default && this.state.styleSettings.showConsolidated) || (!this.props.portfolio.default && this.props.portfolio.consolidated)) && <AllAccountSummary user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} perfType={this.state.perfType} period={this.state.period} date={this.state.selectedDate} idToken={this.props.idToken} setPeriods={this.setPeriods} />}
        </div>
    );
  }

}

export default AccountSummaryDashboard;