import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class UserSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notify:this.props.userSettings.notify
    }

    this.onNotifyChange = this.onNotifyChange.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
  }

  onNotifyChange = (e) =>{
    this.setState({notify:e.target.checked});
  }

  applyChanges = (e) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
        company: this.props.user.company,
        notify:this.state.notify
    };
    axios
        .post("api/notify", payload, {headers})
        .then((response) => {
            console.log(response);
            alert("Notification Settings have been updated.");
        })
        .catch((e) => {
          console.error(e);
        });
  }

  render() {
    return(
        <div>
          <div>
            <input type="checkbox" id="notify" className="k-checkbox" onChange={this.onNotifyChange} defaultChecked={this.props.userSettings.notify}/>
            <label className="k-checkbox-label" for="notify">Receive Email Notifications</label>
          </div>
          <Button onClick={this.applyChanges}>Apply Changes</Button>
        </div>
    );
  }

}

export default UserSettings;