import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';

class AddGroup extends Component {

  constructor(props) {
    super(props);
      this.state = {
        groupName:''
      }

    this.clearState = this.clearState.bind(this);
    this.createGroup = this.createGroup.bind(this);
  }

  onChange = (e)=>{
    this.setState({groupName:e.target.value});
  }

  createGroup(callback){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      company:this.props.user.company,
      group:this.state.groupName
    }
    axios.post("api/createUserGroup", payload, {headers}).then(response => {
      if(response.data.code===200){
        alert('User group created successfully');
        callback();
      }
      else{
        alert(response.data.error);
        callback("ERROR");
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err); 
        callback("ERROR");});    
  }

  clearState(){
      this.setState({
        groupName:''
      });
  }

  render() {
      return (
        <Popup trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={close} />


              <div className="header" align="left">
                Create New Group
              </div>

              <div className="content">
                <label>Group Name: </label><Input onChange={this.onChange}/>
              </div>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </Button>

                <Button 
                    className="upload"  
                    onClick={(e) => {this.createGroup((err)=>{
                      if(!err){
                        this.props.refreshGroups();
                        close();
                      }
                    });
                    }}
                >
                  Create Group
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };

} 

export default AddGroup;