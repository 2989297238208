import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class EditRole extends Component {

  constructor(props) {
    super(props);

    this.state = {
        role: this.props.role.role,
        description: this.props.role.description,
        isGlobal: this.props.role.isGlobal
      }
  }

  clearState = ()=>{
    this.setState({
      role: this.props.role.role, 
      description: this.props.role.description, 
      isGlobal: this.props.role.isGlobal 
    });
  }

  onRoleChange = (e) =>{
    this.setState({role:e.target.value});
  }

  onDescriptionChange = (e) =>{
    this.setState({description:e.target.value});
  }

  onIsGlobalChange = (e) =>{
    this.setState({isGlobal:!this.state.isGlobal});
  }

  updateRole = (e) => {
    var role = this.props.role;
    const headers = { 'authorization': 'Bearer '.concat(role.idToken) };

    var payload = {
        company: role.company,
        roleguid: role.guid,
        role:this.state.role,
        description:this.state.description,
        active: role.active,
        order: role.order,
        permissions: role.permissions != null ? role.permissions : [],
        users: role.users != null ? role.users : [],
        isGlobal: this.state.isGlobal,
        isOverride: (this.state.isGlobal && role.company !== 'cssi') ? true : false,
        ignoreUsers: true,
        ignorePermissions: true
    }

    axios.post('api/updateRole', payload, {headers}).then(response=>{
        if(response.data.code===200){
          role.refreshList();
        }
        else{
          alert("Error: Role could not be updated.");
        }
    });
  }

  render() {
      return (
        <Popup trigger={this.props.button} onClose={() => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">
              <Button className="close" icon="close" onClick={()=>{close();}} />
              <div className="header" align="left">
                Edit Role
              </div>
              <div className="content">
                <div>
                    <label>Role: </label>
                    <input defaultValue={this.props.role.role} onChange={this.onRoleChange}/>
                </div>
                <br />
                <div>
                    <label>Description: </label>
                    <input defaultValue={this.props.role.description} onChange={this.onDescriptionChange}/>
                </div>
                {this.props.role.company === 'cssi' && <div style={{marginTop: '10px'}}>
                    <label>Global: </label>
                    <input type="checkbox" name="isGlobal" onChange={this.onIsGlobalChange} checked={this.state.isGlobal} />
                </div>}
              </div>
              <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Cancel
                </Button>
                <Button 
                  className="upload"  
                  onClick={(event)=>{this.updateRole(event); close();}}>
                      Update
                </Button>
              </div>
            </div>
          )}
        </Popup>
      );
  };
}

export default EditRole;