import React, { Component } from "react";
import AssetClassAUM from '../components/AssetClassAUM';

class AUMDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }


  render() {
    return(
        <div className='aumDashboard'>
            <AssetClassAUM user={this.props.user} compSettings={this.props.compSettings} portfolio={this.props.portfolio} idToken={this.props.idToken} />
        </div>
    );
  }

}

export default AUMDashboard;