import React from "react";
import {Grid, GridColumn, GridDetailRow, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import { orderBy } from '@progress/kendo-data-query';
import AddHouseholds from './AddHouseholds';
import axios from 'axios';

class Households extends GridDetailRow {

  constructor(props) {
    super(props);
    this.state = {
      householdsSelected:'',
      isHouseholdSelected:false,
      sort:[{field: 'household', dir:'asc'}],
    }
  }

  onDelete = (e)=>{
    var headers = { 'authorization': 'Bearer '.concat(this.props.dataItem.idToken) };

    var payload = {
      households:this.state.householdsSelected,
      action:'remove',
      group:this.props.dataItem.name,
      company:this.props.dataItem.loggedInUser.company
    }

    console.log(payload)
    axios.post("api/householdgroup", payload, {headers}).then(response => {
      if(response.data.code===200){
        alert('Households successfully removed from group');
        this.props.dataItem.refresh();
      }
      else{
        alert(response.data.errMsg);
        this.props.dataItem.refresh();
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err);
        this.props.dataItem.refresh();
      });  
  }

  selectionChange(event, households){
    event.dataItem.selected = !event.dataItem.selected;
    var selectedHouseholds = [];
    for(let i=0; i<households.length; i++){
        if(households[i].selected){
          selectedHouseholds.push(households[i]);
        }
    }
    this.setState({householdsSelected:selectedHouseholds});
    if(selectedHouseholds.length>0){
        this.setState({isHouseholdSelected: true});
    }
    else{
        this.setState({isHouseholdSelected: false});
    }

  }

  headerSelectionChange(event, households){
    const checked = event.syntheticEvent.target.checked;
    households.forEach(item => item.selected = checked);
    var selectedHouseholds = [];
    for(let i=0; i<households.length; i++){
        if(households[i].selected){
          selectedHouseholds.push(households[i]);
        }
    }
    this.setState({householdsSelected:selectedHouseholds});
    if(selectedHouseholds.length>0){
        this.setState({isHouseholdSelected: true});
    }
    else{
        this.setState({isHouseholdSelected: false});
    }
  }

  render() {
    const data = this.props.dataItem.members;
    console.log(data)
    return(
        <div>
            <Grid data={orderBy(data, this.state.sort)}
            selectedField='selected'
            className='householdGroupList'
            onRowClick={(e) => this.selectionChange(e, data)}
            onSelectionChange={(e) => this.selectionChange(e, data)}
            onHeaderSelectionChange={(e) => this.headerSelectionChange(e, data)}>
                <GridToolbar>
                  <AddHouseholds user={this.props.dataItem.loggedInUser} /*group={data}*/ idToken={this.props.dataItem.idToken} refresh={this.props.dataItem.refresh} group={this.props.dataItem.name} button={<Button icon='plus'>Add Household(s)</Button>} />
                  <Button icon='delete' disabled={!this.state.isHouseholdSelected} onClick={this.onDelete}>Remove</Button>
                  <label style={{position: 'absolute', right: 10, top: 13}}>Households: {data.length}</label>
                </GridToolbar>
                <GridColumn field="selected" />
                <GridColumn field='household' title='Household'/>
            </Grid>
        </div>
    );
  }

}
export default Households;

