import history from '../history';
import auth0 from 'auth0-js';

export default class Auth {
  accessToken;
  idToken;
  expiresAt;
  profile;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin+"/callback",
    responseType: 'token id_token',
    scope: 'openid',
    
  issuer: process.env.AUTH0_DOMAIN
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  getProfile() {
    return this.profile;
  }


  handleAuthentication() {

    //check if no expire date
    if (!this.expiresAt) {
      this.accessToken = null;
      this.idToken = null;
      this.expiresAt = 0;
      // Remove isLoggedIn flag from localStorage
      localStorage.removeItem('isLoggedIn');
    }
    


    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        if(authResult.idTokenPayload['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies'] && authResult.idTokenPayload['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies'].length>0){
          this.setSession(authResult);
        }
        else{
          alert('Error: Account has been deactivated.');
          this.logout();
        }
      } else if (err) {
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
        history.replace('/login');
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  setSession(authResult, renew) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');


    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload
    this.expiresAt = expiresAt;

    if(!renew){
    // navigate to the home route
    history.replace('/home');
    }

  }

  renewSession(route,cb) {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true);
        if (cb != null) cb();
      } else if (err) {
        this.logout();
        console.log(err);
        if (cb != null) cb();
        //alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }



  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('appGoTo');
    localStorage.removeItem('appCompany');

    //logout and return to login screen
    this.auth0.logout({returnTo: window.location.origin+"/login"});
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }


}
