import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import axios from 'axios';
import { Button } from "@progress/kendo-react-buttons";
import DetailRow from './components/DetailRow';
import AddWidgets from './components/AddWidgets';
import GridLoading from '../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class Widgets extends Component {

  constructor(props) {
    super(props);

    this.minGridWidth = 0;

    var columns = [
      { field: 'name', title: 'Widget', headerClass: 'gridHeader', minWidth: 150 },
      { field: 'description', title: 'Description', headerClass: 'gridHeader', minWidth: 150 },
      { field: 'module', title: 'Module', headerClass: 'gridHeader', minWidth: 125 },
      { field: 'active', title: 'Active', filterable: false, cell: (p) => this.createCell(p, 'active'), headerClass: 'gridHeader', minWidth: 50 },
    ];

    this.state = {
      widgets: [],
      result: [],
      refreshText: '',
      filterable: this.props.compSettings.showFilters,
      columns: columns,
      dataState: { skip: 0, take: 25, sort: [{ field: 'order', dir: 'asc' }] },
    };
  }

  componentDidMount() {
    this.getWidgets(() => {
      this.setMinGridWidth();
    });
  }

  setMinGridWidth = () => {
    this.grid = document.querySelector('.k-grid');
    window.addEventListener('resize', this.handleResize);
    this.minGridWidth = 0;
    this.state.columns.map(item => this.minGridWidth += item.minWidth);
    if (this.state.dataState.group)
      this.minGridWidth += 32 * this.state.dataState.group.length;
    this.setState({
      gridCurrent: this.grid.offsetWidth,
      setMinWidth: this.grid.offsetWidth < this.minGridWidth
    });
  }

  handleResize = () => {
    if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
      this.setState({
        setMinWidth: true
      });
    } else if (this.grid.offsetWidth > this.minGridWidth) {
      this.setState({
        gridCurrent: this.grid.offsetWidth,
        setMinWidth: false
      });
    }
  }

  setWidth = (minWidth) => {
    let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
    if (width >= COLUMN_MIN)
      width = width - ADJUST_PADDING;
    return width;
  }

  getWidgets = (cb) => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/widgets?company=" + this.props.user.company, { headers }).then(response => {
      if (response.data.code === 200) {
        var formattedWidgets = this.formatWidgetList(response.data.data);
        this.setState({
          widgets: formattedWidgets, result: process(formattedWidgets, this.state.dataState), refreshText: ''
        }, () => {
          if (cb)
            cb();
        });
      } else {
        if (cb)
          cb();
        this.setState({ refreshText: '' });
        alert("An error occured while fetching the widgets.");
      }
    }).catch(error => console.log(error));
  }

  formatWidgetList = (widgetList) => {
    var listWithWidgets;
    listWithWidgets = widgetList.slice();

    for (let i = 0; i < listWithWidgets.length; i++) {
      listWithWidgets[i].company = this.props.user.company;
      listWithWidgets[i].idToken = this.props.idToken;
      listWithWidgets[i].changeActivate = this.changeActivate;
      listWithWidgets[i].refreshList = this.getWidgets;
    }

    return listWithWidgets;
  }

  createCell = (cell, type) => {
    if (type === 'active') {
      if (cell.dataItem.active === true) {
        return (<td>Active</td>);
      }
      else {
        return (<td>Inactive</td>);
      }
    }
  }

  changeActivate = (widgetId, active) => {
    var arr = this.state.widgets.slice();

    var indexOfWidget = arr.findIndex((item) => {
      return item.widgetId === widgetId;
    });

    if (indexOfWidget > -1) {
      arr[indexOfWidget].active = active;
    }
    this.setState({ widgets: arr });
  }

  expandChange = (event) => {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  }

  _export;

  export = () => {
    this._export.save();
  }

  changeDataState = (e) => {
    var newData = process(this.state.widgets, e.data);
    this.setState({ dataState: e.data, result: newData });
  }

  render() {
    return (
      <div>
          <div className="widgetList">
            <Grid data={this.state.result}
              onRowClick={this.expandChange}
              detail={DetailRow}
              resizable={true}
              reorderable={true}
              pageable={{ pageSizes: [10, 25, 50, 100, 250, 500] }}
              sortable={true}
              onDataStateChange={this.changeDataState}
              expandField="expanded"
              onExpandChange={this.expandChange}
              filterable={this.state.filterable}
              {...this.state.dataState}>
              <GridToolbar>
                <Button icon='refresh' title='Refresh' onClick={(e) => this.getWidgets(() => this.setMinGridWidth())}>{this.state.refreshText}</Button>
                <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                <AddWidgets compSettings={this.props.compSettings} company={this.props.user.company} idToken={this.props.idToken} refreshWidgets={this.getWidgets}
                  button={<Button icon="plus"> Create Widget</Button>} newOrder={(1 + this.state.widgets.length) * 10} />
              </GridToolbar>
              {
                this.state.columns.map((column, key) => {
                  return <GridColumn field={column.field} title={column.title} key={key} format={column.format} cell={column.cell} filterable={column.filterable} filter={column.filter} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
                })
              }
            </Grid>
            {this.state.refreshText && <GridLoading gridClass='widgetList' />}
          </div>
      </div>
    );
  }

}

export default Widgets;