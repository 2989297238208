import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import GridLoading from '../../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';

class AdvisorPerformance extends Component {

  constructor(props) {
    super(props);

    var defaultAggs = [{field:'beginMV', aggregate:'sum'},
    {field:'additions', aggregate:'sum'},
    {field:'transfersIn', aggregate:'sum'},
    {field:'withdrawals', aggregate:'sum'},
    {field:'transfersOut', aggregate:'sum'},
    {field:'netCW', aggregate:'sum'},
    {field:'interest', aggregate:'sum'},
    {field:'dividends', aggregate:'sum'},
    {field:'income', aggregate:'sum'},
    {field:'realizedGain', aggregate:'sum'},
    {field:'unrealizedGain', aggregate:'sum'},
    {field:'investGL', aggregate:'sum'},
    {field:'manFees', aggregate:'sum'},
    {field:'portFees', aggregate:'sum'},
    {field:'endMV', aggregate:'sum'},
    {field:'manFeesClient', aggregate:'sum'},
    {field:'portFeesClient', aggregate:'sum'},
    {field:'benefit', aggregate:'sum'}];

    this.state = {
        data:[],
        result:{data:[]},
        filter:{logic:'and', filters:[{field:"periodCode", operator:"eq", value:'mtd'}]},
        dataState:{},
        aggregates:defaultAggs,
        returnType:'irr',
        perfType:'net',
        period:'',
        periods:[],
        dates:[],
        selectedDate:'',
        loading:true
    }

    this.getAccountSummary = this.getAccountSummary.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.getPerfDates = this.getPerfDates.bind(this);
  }

  componentDidMount(){
    this.getAccountSummary();
    this.getPerfDates();
  }

  getPerfDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      advisor:this.props.user.user,
      perfType:this.state.perfType
    }
    axios.post('api/perfDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getAccountSummary(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    this.setState({loading:true});

    if(this.state.selectedDate==='')
    {
      this.getPerfDates();
    }

    axios.get('api/advisorPerf?company='+this.props.user.company+'&date='+this.state.selectedDate+'&perfType='+this.state.perfType, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0){
            var periods = [];
            var port = '';
            var display = false;
            for(let i=0 ;i<response.data.data.length; i++){
              //used to sort order of periods in drop down correctly
              if(response.data.data[i].portCode!==port){
                port = response.data.data[i].portCode;
                display = true;
              }
              // else if(!response.data.data[i].display){
              //   display = false;
              // }

              if(display){
                var index = periods.findIndex(p=>{
                  return p.value===response.data.data[i].periodCode;
                });

                if(index<0 && this.props.compSettings.acctDropPeriods.indexOf(response.data.data[i].periodCode)>=0){
                  periods.push({name:response.data.data[i].periodDesc, value:response.data.data[i].periodCode})
                }
              }
            }
            

            var dataState = {group:[{field:"client", aggregates:this.state.aggregates}], filter:this.state.filter};
            this.setState({period:this.state.filter.filters[0].value , data:response.data.data, result:process(response.data.data, dataState), periods:periods, dataState:dataState, loading:false});
          }
          else
          {
            this.setState({data:[], loading:false});
          }
            
      }
    }).catch(err=>{
      console.log(err);
      this.setState({data:[], loading:false});
    });
  }

  formatCurrency(val){
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);

    return formatted;
  }

  formatPercent(val){
    if(val)
      return val.toFixed(2)+"%";
    else
      return val;
  }

  getColor(val){
    if(val>0)
        return '#0BD318';
    else
        return '#FF3B30';
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

  changeDataState = (e)=>{
    const groups = e.data.group;
    if(groups){
      groups.map(group=>group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.data, e.data);
    this.setState({dataState:e.data, result:newData});
  }

  headerCellRender(thElement, cellProps) {
    if(thElement!==null){
      var style={};
      var date = '';
      if(cellProps.field!=='client' && cellProps.field!=='displayName')
        style = {textAlign:'right'};

      if(cellProps.field==='beginMV'){
        if(this.state.result.data.length>0){
          date = this.state.result.data[0].beginDate;
          if(this.state.result.data[0].items && this.state.result.data[0].items.length>0)
            date = this.state.result.data[0].items[0].beginDate;
        }
        return(
          <th {...thElement.props} style={style}><div>Beginning Value</div><div> on {date}</div></th>
        );
      }
      else if (cellProps.field==='endMV'){
        if(this.state.result.data.length>0){
          date = this.state.result.data[0].endDate;
          if(this.state.result.data[0].items && this.state.result.data[0].items.length>0)
            date = this.state.result.data[0].items[0].endDate;
        }
        return(
          <th {...thElement.props} style={style}><div>Ending Value</div><div> on {date}</div></th>
        );
      }
      else if(cellProps.field==='netCW'){
        return(
          <th {...thElement.props} style={style}><div>Net</div><div>Additions/Withdrawals</div></th>
        );
      }
      else if(cellProps.field==='unrealizedGains'){
        return(
          <th {...thElement.props} style={style}><div>Unrealized</div><div>Gains</div></th>
        );
      }
      else if(cellProps.field==='realizedGains'){
        return(
          <th {...thElement.props} style={style}><div>Realized</div><div>Gains</div></th>
        );
      }
      else if(cellProps.field==='benefit'){
        return(
          <th {...thElement.props} style={style}><div>Total</div><div>Benefit</div></th>
        );
      }
      return(
        <th {...thElement.props} style={style}/>
      );
    }

    return thElement;
  }

  cellRender(tdElement, cellProps) {
    var val = '';
    if (cellProps.rowType === 'groupFooter') {
      if(cellProps.field!=='value' && cellProps.field!=='displayName' && cellProps.field!=='twr' 
        && cellProps.field!=='irr'){

        if(cellProps.dataItem.aggregates[cellProps.field])
          val = cellProps.dataItem.aggregates[cellProps.field].sum
          
        val = this.formatCurrency(val);

        return (
            <td style={{textAlign: 'right'}}>
                <strong>{val}</strong>
            </td>
        );
      }
      else if(cellProps.field==='displayName'){
        return (
          <td>
              <strong>Totals:</strong>
          </td>
        );
      }
    }
    if(tdElement!==null){
      var style={};
        if(cellProps.field==='irr' || cellProps.field==='twr'){
          val = cellProps.dataItem[cellProps.field];
          val = this.formatPercent(val);
          style = {textAlign:'right'};
          return(
            <td {...tdElement.props} style={style}>{val}</td>
        );
        }
        if(cellProps.field!=='client' && cellProps.field!=='displayName'){ 
            style = {textAlign:'right'};
            return(
                <td {...tdElement.props} style={style}/>
            );
        } 
    }
    return tdElement;
  }

  changePeriod = (e)=>{
    var period = e.target.value;
    var filter = this.state.filter;
    filter.filters[0].value = period;
    var dataState = this.state.dataState;
    dataState.filter = filter;
    this.setState({filter:filter, period:period, dataState:dataState, result:process(this.state.data, dataState)});
  }

  _export;

  export = () =>{
    this._export.save();
  }


  render() {

    //var exportData = this.state.result.data;
    //if(this.state.result.data && this.state.result.data.length>0 && this.state.result.data[0].items)
      //exportData = this.state.result.data[0].items;

    return(
      <div className='advisorPerf'>
      <ExcelExport data={this.state.data} fileName={'AdvisorPerf_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
                <ExcelExportColumn field="client" title="Client" />
                <ExcelExportColumn field="displayName" title="Account" />
                <ExcelExportColumn field="periodDesc" title="Period" />
                <ExcelExportColumn field="beginDate" title="Beginning Date" />
                <ExcelExportColumn field="endDate" title="End Date" />
                <ExcelExportColumn field="beginMV" title="Beginning Value" />
                <ExcelExportColumn field="additions" title="Additions" />
                <ExcelExportColumn field="transfersIn" title="Transfers In" />
                <ExcelExportColumn field="withdrawals" title="Withdrawals" />
                <ExcelExportColumn field="transfersOut" title="Transfers Out" />
                <ExcelExportColumn field="netCW" title="Net Additions/Withdrawals" />
                <ExcelExportColumn field="interest" title="Interest" />
                <ExcelExportColumn field="dividends" title="Dividends" />
                <ExcelExportColumn field="income" title="Income" />
                <ExcelExportColumn field="realizedGains" title="unrealizedGains" />
                <ExcelExportColumn field="investGL" title="Investment Gain/Loss" />
                <ExcelExportColumn field="manFees" title="Management Fees" />
                <ExcelExportColumn field="portFees" title="Portfolio Fees" />
                <ExcelExportColumn field="endMV" title="Ending Value" />
                <ExcelExportColumn field="manFeesClient" title="Management Fees Paid" />
                <ExcelExportColumn field="portFeesClient" title="Portfolio Fees Paid" />
                <ExcelExportColumn field="benefit" title="Total Benefit" />
                <ExcelExportColumn field="irr" title="IRR" />
                <ExcelExportColumn field="twr" title="TWR" />
      </ExcelExport>
      <Grid data={this.state.result}
            className='noGroupGridLines'
            cellRender = {this.cellRender}
            headerCellRender = {this.headerCellRender}
            onExpandChange={this.expandChange}
            expandField="expanded"
            groupable={{footer:'always'}}
            resizable={true}
            reorderable={true}
            onDataStateChange={this.changeDataState}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              <label>Type: </label><select onChange={(e)=>this.setState({perfType:e.target.value}, ()=>this.getAccountSummary())} value={this.state.perfType} style={{marginRight:'10px'}}>
                <option value='net'>Net</option>
                <option value='gross'>Gross</option>
              </select>
              <label>Returns: </label><select onChange={(e)=>this.setState({returnType:e.target.value})} value={this.state.returnType} style={{marginRight:'10px'}}>
                <option value='irr'>IRR</option>
                <option value='twr'>TWR</option>
              </select>
              <label>Period: </label><select onChange={this.changePeriod} value={this.state.period} style={{marginRight:'10px'}}>
                {this.state.periods.map((per, i)=>(
                        <option value={per.value} key={i}>{per.name}</option>
                      ))}
              </select>
              <label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value}, ()=>this.getAccountSummary())}>
              {this.state.dates.map((date, i)=>(
              <option value={date.fDate}>{date.fDate}</option>
              ))}
              </select>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
            </GridToolbar>
            <GridColumn headerClassName='gridHeader' field="client" title="Client"/>
            <GridColumn headerClassName='gridHeader' field="displayName" title="Account" format="{0:n2}"/>
            <GridColumn headerClassName='gridHeader' field="beginMV" title="Beginning Value" format="{0:c2}"/>
            {!this.props.compSettings.showNetCW && <GridColumn headerClassName='gridHeader' field="additions" title="Additions" format="{0:c2}"/>}
            {!this.props.compSettings.showNetCW && this.state.result.data.filter(x=>(x.transfersIn!==undefined && x.transfersIn!==0)).length>0 && <GridColumn headerClassName='gridHeader' field="transfersIn" title="Transfers In" format="{0:c2}"/>}
            {!this.props.compSettings.showNetCW && <GridColumn headerClassName='gridHeader' field="withdrawals" title="Withdrawals" format="{0:c2}"/>}
            {!this.props.compSettings.showNetCW && this.state.result.data.filter(x=>(x.transfersOut!==undefined && x.transfersOut!==0)).length>0 && <GridColumn headerClassName='gridHeader' field="transfersOut" title="Transfers Out" format="{0:c2}"/>}
            {this.props.compSettings.showNetCW && <GridColumn headerClassName='gridHeader' field="netCW" title="Net Additions/Withdrawals" format="{0:c2}"/>}
            {!this.props.compSettings.showIncome && <GridColumn headerClassName='gridHeader' field="interest" title="Interest" format="{0:c2}"/>}
            {!this.props.compSettings.showIncome && <GridColumn headerClassName='gridHeader' field="dividends" title="Dividends" format="{0:c2}"/>}
            {this.props.compSettings.showIncome && <GridColumn headerClassName='gridHeader' field="income" title="Income" format="{0:c2}"/>}
            {!this.props.compSettings.showInvestGL && <GridColumn headerClassName='gridHeader' field="realizedGains" title="Realized Gains" format="{0:c2}"/>}
            {!this.props.compSettings.showInvestGL && <GridColumn headerClassName='gridHeader' field="unrealizedGains" title="Unrealized Gains" format="{0:c2}"/>}
            {this.props.compSettings.showInvestGL && <GridColumn headerClassName='gridHeader' field="investGL" title="Investment Gain/Loss" format="{0:c2}"/>}
            {this.state.result.data.filter(x=>(x.manFees!==undefined && x.manFees!==0)).length>0 && <GridColumn headerClassName='gridHeader' field="manFees" title="Management Fees" format="{0:c2}"/>}
            {this.state.result.data.filter(x=>(x.portFees!==undefined && x.portFees!==0)).length>0 && <GridColumn headerClassName='gridHeader' field="portFees" title="Portfolio Fees" format="{0:c2}"/>}
            <GridColumn headerClassName='gridHeader' field="endMV" title="Current Value" format="{0:c2}"/>
            {this.props.compSettings.showFeesPaid && <GridColumn headerClassName='gridHeader' field="manFeesClient" title="Management Fees Paid" format="{0:c2}"/>}
            {this.props.compSettings.showFeesPaid && <GridColumn headerClassName='gridHeader' field="portFeesClient" title="Portfolio Fees Paid" format="{0:c2}"/>}
            <GridColumn headerClassName='gridHeader' field="benefit" title="Total Benefit" format="{0:c2}"/>
            {this.state.returnType==='irr' && <GridColumn headerClassName='gridHeader' field="irr" title="IRR" format="{0:p2}"/>}
            {this.state.returnType==='twr' && <GridColumn headerClassName='gridHeader' field="twr" title="TWR" format="{0:p2}"/>}
            </Grid>
            {this.state.loading && <GridLoading />}
      </div>
    );
  }


}

export default AdvisorPerformance;