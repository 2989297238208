import React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import LogoUpload from '../StyleCenter/components/LogoUpload';

class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerLogo: this.props.bannerLogo,
            bannerLogoPreview: '',
            logoRemoved: false
        };

    }

    onLogoChange = (e) => {
        this.setState({
            bannerLogo: e.target.files[0],
            bannerLogoPreview: URL.createObjectURL(e.target.files[0])
        });
    }

    removeLogo = () => {
        this.setState({
            bannerLogo: '',
            bannerLogoPreview: '',
            logoRemoved: true
        });
    }

    callback = () => {
        this.setState({
            bannerLogoPreview: '',
            bannerLogo: this.props.bannerLogo
        })
    }

    applyChanges = () => {
        this.props.saveBannerLogo(this.state.bannerLogo,this.callback)
        this.setState({
            logoRemoved: false,
            bannerLogo: ''
        });
    }

    render() {
        return (
            <div className='bannerLogo'>
                <table className="styleTable">
                    <tbody>
                        <tr>
                            <td className='settingLabel'>
                                <strong>Banner Logo:</strong>
                                {this.state.bannerLogoPreview !== '' && <img className="cornerUpload" src={this.state.bannerLogoPreview} alt="Banner Logo" />}
                                {this.state.bannerLogo !== '' && this.state.bannerLogoPreview === '' && <img className="cornerUpload" src={"Logos/" + this.state.bannerLogo} alt="Banner Logo" />}
                            </td>
                            <td>
                                <LogoUpload previewImage={this.state.bannerLogo} onChangeLogo={(event) => this.onLogoChange(event)} />
                            </td>
                        </tr>
                        <tr>
                            <td className='settingLabel'></td>
                            <td>
                                <Button disabled={this.state.bannerLogoPreview === '' && !this.state.logoRemoved} onClick={this.applyChanges}>Apply Changes</Button>
                                <Button style={{ marginLeft: '4px' }} disabled={this.state.bannerLogoPreview === '' && this.state.bannerLogo === ''} onClick={this.removeLogo}>Remove Logo</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default Logo