import React from 'react';
import { Button } from "@progress/kendo-react-buttons";

class WelcomeText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerWelcomeText: this.props.bannerWelcomeText,
        };

    }

    onInputChange = (e) => {
        this.setState({ bannerWelcomeText: e.target.value });
    }

    applyChanges = () => {
        this.props.saveBannerWelcomeText(this.state.bannerWelcomeText);
    }

    render() {
        return (
            <div className='bannerLogo'>
                <table className="styleTable">
                    <tbody>
                        <tr>
                            <td >
                                <strong>Banner Welcome Text:</strong>
                                {/* <img className="cornerUpload" src={this.state.cornerPreview} alt="Corner Logo" /> */}
                            </td>
                            <td>
                                <textarea style={{width: '95%'}} rows="8" value={this.state.bannerWelcomeText} onChange={(e) => this.onInputChange(e)} />
                            </td>
                        </tr>
                        <tr>
                            <td ></td>
                            <td>
                                <Button onClick={this.applyChanges}>Apply Changes</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default WelcomeText