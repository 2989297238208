import React, { Component } from 'react';
import axios from 'axios';
import FileTree from './components/FileTree';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
//import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { withState } from './components/with-state';
import UploadForm from './components/UploadForm';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import {filterBy,orderBy,process} from '@progress/kendo-data-query';
import {parseDate, formatDate} from '@telerik/kendo-intl';
import AddFolder from './components/AddFolder';
import GridLoading from '../Loaders/GridLoading';


const StatefulGrid = withState(Grid);

class HouseholdDocCenter extends Component {

    constructor(props) {
        super(props);


        // var CompLayoutText = "Grid Layout";
        // if(props.compSettings.docGrid)
        //     CompLayoutText = "Folder Layout";

        this.state = {
            tabSelected: 0,
            
            householdFolders:[],
            
            householdFiles:[],
            fileDownload: { text: "", path: ""},
            fileSelected: false,
            update: '',
            newCategory: '',
            gridLayout:false,
            gridHouseholdFiles:[],
            gridFilter: undefined,
            layoutText:'',
            filterable:props.compSettings.showFilters,
            filesLoading:true,
            householdLoading:false,
            refreshText:'',
            portalUser:this.props.user.user,
            households:[],
            dataState:{sort:[{field:'household', dir:'asc'}]},
            sort:[{field:'uploadDate', dir:'desc'}],
            result:[],
            dropdownHHResult:[],
            selectedHousehold:"",
            vaultFolderList: [],
            descriptions: [],
            showHouseholdName: null,
            styleSettings:{
                enableUserUploadDocument: null,
                documentFolderOrder: null,
                docGrid: null
            }
        }

        this.onUserDeleteHousehold = this.onUserDeleteHousehold.bind(this);

        this.getHouseholdFiles = this.getHouseholdFiles.bind(this);
        this.getHouseholdMappings=this.getHouseholdMappings.bind(this);

        this.onUploadHouseholdDoc = this.onUploadHouseholdDoc.bind(this);
        this.createNewFolder = this.createNewFolder.bind(this);
        this.clickTimeout = null;
        //this.isEmpty = this.isEmpty.bind(this);
        this.getStyleSettings=this.getStyleSettings.bind(this);
        

    }

    
    //check if an object is empty
    isEmpty = (obj) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    getStyleSettings() {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
        var styleNames = ['enableUserUploadDocument', 'documentFolderOrder', 'docGrid', 'docTab7', 'showFilters']
    
        axios(`api/getStyleSettings`, {
          method: 'GET',
          params: { company: this.props.user.company, styleNames: styleNames },
          headers: headers
        })
        .then(response => {
            var CompLayoutText = "Grid Layout";
            if(response.data.styleSettings.docGrid)
            CompLayoutText = "Folder Layout";



          this.setState({styleSettings: response.data.styleSettings, filterable:response.data.styleSettings.showFilters, layoutText:CompLayoutText, gridLayout: response.data.styleSettings.docGrid})
        })
        .catch(err => {
            console.error(err);
        })
    }


    handleSelect = (e) => {        
        this.setState({ tabSelected: e.selected });

        if(this.props.user.access.indexOf('docTab7')!==-1 && e.selected===0)
            this.getHouseholdFiles();

        var copyHousehold = this.state.householdFiles.slice();
        var copyGridHousehold= this.state.gridHouseholdFiles.slice();

        this.setState({fileDownload:{ text: "", path: ""}});
        this.setState({fileSelected:false});
        this.setState({gridFilter:undefined});
        this.setState({filterable:this.state.styleSettings.showFilters});
        
        this.clearSelected(copyHousehold);
        this.clearSelected(copyGridHousehold);
    }

    getHouseholdFiles() {
        this.setState({refreshText:'Loading...'});

        this.setState({householdFiles:[] });
        this.setState({householdFolders:[] });
        this.setState({gridHouseholdFiles:[]});
        this.setState({householdLoading:false});
        this.setState({refreshText:''});

        var household = this.state.selectedHousehold.household;
        
        if (household !=="")
        {
            household = household.toLowerCase().replace('/','');


            this.setState({refreshText:'Loading...'});

            //var folder = "households/andrew cooper" +'/';
            var folder = "households/" + household + '/';
    
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                "username": this.props.user.user,
                "folder": folder,
                "company": this.props.user.company,
                "loggedInUser": this.props.uploadUser.user
            }
            var apiBaseUrl = "/api/";
    
            axios
                //.get("api/filetree?company="+this.props.user.company+"&folder="+folder,{headers})
                .post(apiBaseUrl + "/filetree_post", payload, {headers})
                .then((response) => {
                    var orderedFiles = this.sortFolders(response.data);
                    this.setState({ householdFiles: orderedFiles });
                    var folds = this.getFolders(orderedFiles);
                    this.setState({ householdFolders: folds });
                    this.setState({gridHouseholdFiles:this.convertFilesToGrid(this.state.householdFiles)});
                    this.setState({householdLoading:false});
                    this.setState({refreshText:''});
                    return response.data;
                })
                .catch((e) => {
                    //console.error("ERROR");
                    this.setState({refreshText:''});
                });
        }


    }

    getShowHouseholdNameSetting() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/superAdminSettings`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
        })
            .then(response => {
                response.data.data.forEach(setting => {
                    if (setting.settingName === 'showHouseholdName'){
                        this.setState({showHouseholdName: setting.settingValue.toLowerCase() === 'true' ? true : false})
                    }
                })
    
            })
            .catch(err => console.log(err));
    }

        
    getHouseholdMappings(initialLoad){
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        
        var payload ={
        company:this.props.user.company,
        email:this.props.user.user,
        listmode:  this.props.user.role
        }

        
        axios.post('api/userGetHouseholds' , payload , {headers}).then(response=>{
            var result = process(response.data.households, this.state.dataState);
            this.setState({households:response.data.households,result:result, dropdownHHResult:response.data.households});

            //check if only 1 household
            var i = 1;
            if (!initialLoad){
                for (var household of response.data.households) {
                    if (i ===1)//set the first one
                    {
                        this.setState({selectedHousehold: household});
                        i = 2;
                        this.getHouseholdFiles();
                    }
                }    
            }
            
        }).catch(err=>{
            console.log(err);
            this.setState({households:[], selectedHousehold:'', dropdownHHResult:[]});
        });
    }


    download() {  
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {"files":[], "company":this.props.user.company};

        if(Array.isArray(this.state.fileDownload)){
            if(this.state.fileDownload.length>1){
                for(let i=0; i<this.state.fileDownload.length; i++){
                    payload.files.push({
                        "path": this.state.fileDownload[i].path,
                        "name":this.state.fileDownload[i].text
                    });
                }
                    axios
                        .post("api/downloadzip", payload, {headers})
                        .then((response) => {
                            window.open("api/downloadzip?company="+this.props.user.company+"&user="+this.props.uploadUser.user);
                        })
                        .catch((e) => {
                            //console.error("ERROR");
                        });
            }
            else{
                var payload2 = {
                    company:this.props.user.company,
                    path:this.state.fileDownload[0].path,
                    name:this.state.fileDownload[0].text,
                    type:'download'
                    }
                //.get("api/download?company="+this.props.user.company+"&path="+this.state.fileDownload[0].path+"&name="+this.state.fileDownload[0].text+"&type=download", {headers})
                axios
                .post("api/download",payload2, {headers})
                .then((response) => {
                    window.open(response.data);
                })
                .catch((e) => {
                    return false;
                });
            }
        }
        else if(!this.state.fileDownload.path.endsWith('/')){
                var payload3 = {
                    company:this.props.user.company,
                    path:this.state.fileDownload.path,
                    name:this.state.fileDownload.text,
                    type:'download'
                    }
                //.get("api/download?company="+this.props.user.company+"&path="+this.state.fileDownload.path+"&name="+this.state.fileDownload.text+"&type=download", {headers})
                axios
                .post("api/download",payload3, {headers})
                .then((response) => {
                    window.open(response.data);
                })
                .catch((e) => {
                    return false;
                });
        }

    }

    getFolders(fileTree) {
        var folders = [];

        for (var i = 0; i < fileTree.length; i++) {
            if (fileTree[i].items !== undefined) {
                folders.push(fileTree[i]);
            }
        }

        return folders;
    }


    onUploadHouseholdDoc(e, files, category, description) {
        this.getHouseholdFiles();
    }


    createNewFolder(e, directory, addFolder, groups) {
        if(addFolder!=="" && addFolder!==undefined)
        {
            var folds;
            var files;
            
            folds = this.state.householdFolders.slice();
            files = this.state.householdFiles.slice();
            directory = directory.toLowerCase();
            
            

            var newFolder = { text: addFolder + "/", path: directory + "/" + addFolder + "/", items: [] };
            
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            var payload = {
                "folder": newFolder.path,
                "company": this.props.user.company,
                "groups":groups
            };

            axios
                .post("api/createFolder", payload, {headers})
                .then((response) => {
                    if (response.data.code === 200) {
                        folds.push(newFolder);
                        files.push(newFolder);
                        
                        this.setState({ householdFolders: folds });
                        this.setState({ householdFiles: files });
                        this.setState({newCategory:''});
                        
                    }
                    else if (response.data.code === 400) {
                        alert("Folder already exists.");
                    }
                    else if (response.data.code === 300){
                        alert("Folder was created but an error occured when setting permissions");
                        folds.push(newFolder);
                        files.push(newFolder);
                        
                        this.setState({ householdFolders: folds });
                        this.setState({ householdFiles: files });
                        this.setState({newCategory:''});
                        
                    }
                })
                .catch((e) => {
                    //console.error("ERROR");
                });
        }
    }

    onGridLayoutClick = (e) => {
        var clearedHousehold;

        if(this.state.gridLayout===false){
            clearedHousehold = this.state.householdFiles.slice();
            this.clearSelected(clearedHousehold);
            this.setState({householdFiles:clearedHousehold});

            this.setState({gridLayout:true});
            this.setState({fileDownload:{ text: "", path: ""}});
            this.setState({fileSelected: false});
            this.setState({layoutText:'Folder Layout'})
        }
        else{
            clearedHousehold = this.state.gridHouseholdFiles.slice();
            this.clearSelected(clearedHousehold);
            this.setState({gridHouseholdFiles:clearedHousehold});

            this.setState({gridLayout:false});
            this.setState({gridFilter:undefined});
            this.setState({fileDownload:{ text: "", path: ""}});
            this.setState({fileSelected: false});
            this.setState({layoutText:'Grid Layout'});
        }
    }

    headerSelectionChange(event, gFiles){
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for(let i=0; i<gFiles.length; i++){
            if(gFiles[i].selected){
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({fileDownload:selectedFiles});
        if(selectedFiles.length>0){
            this.setState({fileSelected: true});
        }
        else{
            this.setState({fileSelected: false});
        }
        this.forceUpdate();
    }

    selectionChange(event, gFiles){
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for(let i=0; i<gFiles.length; i++){
            if(gFiles[i].path === event.dataItem.path){
                gFiles[i].selected=event.dataItem.selected;
            }
            if(gFiles[i].selected){
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({fileDownload:selectedFiles});
        if(selectedFiles.length>0){
            this.setState({fileSelected: true});
        }
        else{
            this.setState({fileSelected: false});
        }
        this.forceUpdate();
    }


    convertFilesToGrid(files){
        var grid = [];
        var folder = "";
        for(let i=0; i<files.length; i++){
            if(files[i].items===undefined || files[i].items===null){
                grid.push({category:"",text:files[i].text, path:files[i].path, description:files[i].description, uploadedBy:files[i].uploadedBy, uploadDate:this.getDate(files[i].uploadDate),makePrivate:files[i].makePrivate,   selected:files[i].selected});
            }
            else{
                folder = files[i].text;
                for(let j=0; j<files[i].items.length; j++){
                    grid.push({category:folder,text:files[i].items[j].text, path:files[i].items[j].path, description:files[i].items[j].description, uploadedBy:files[i].items[j].uploadedBy, uploadDate:this.getDate(files[i].items[j].uploadDate), makePrivate:files[i].items[j].makePrivate , selected:files[i].items[j].selected})
                }
            }
        }
        return grid;
    }

    getDate(mysqlDate){
        var d = parseDate(mysqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        return d;
    }


    processData = () => {
        const { gridHouseholdFiles, sort, gridFilter } = this.state;

        //filterBy(orderBy(this.state.gridHouseholdFiles, this.state.sort), this.state.gridFilter)
        var trimmedData = []
        gridHouseholdFiles.forEach(row => {
         row.category = row.category.replace('/','')
         trimmedData.push(row)
        })
         return filterBy(orderBy(trimmedData, sort), gridFilter);
    }

    FileCell = props => (
        <>
            <td className="fileItem">
              <span className={this.iconClassName(props.dataItem.text)}></span>
              {props.dataItem.text.toLowerCase().endsWith('pdf') && <button className='link-button' onClick={(event) => this.onLinkClick(event, props.dataItem, this.props.idToken)}>{props.dataItem.text}</button>}
              {!props.dataItem.text.toLowerCase().endsWith('pdf') && props.dataItem.text}
              <br/>
              {props.dataItem.makePrivate &&
            <div className="description" >PRIVATE COMPANY DOCUMENT</div>
            }
            <div className="description" >{props.dataItem.description}</div>
            <div className="uploadBy"  >{this.uploadedByLine(props.dataItem.uploadedBy, props.dataItem.uploadDate)}</div>
            </td>
            
          </>
        
    );

    uploadedByLine = (uploadedBy, uploadDate ) => {
        var returnString = null

        if (uploadedBy !== undefined) {
            returnString = "Uploaded by: " + uploadedBy;
            if (uploadDate) {
                returnString = returnString.concat(" on " +formatDate(parseDate(uploadDate), "d"))
            }
        }
        
    
        return returnString;
    }

    is = (fileName, ext) => new RegExp(`.${ext}`).test(fileName.toLowerCase());

    iconClassName = (text) => {
        
        if (this.is(text, 'pdf')) {
            return 'k-icon k-i-file-pdf colored-pdf';
        } else if (this.is(text, 'html')) {
            return 'k-icon k-i-html';
        } else if (this.is(text, 'jpg|png')) {
            return 'k-icon k-i-image';
        }
        else if (this.is(text, 'txt')) {
            return 'k-icon k-i-file-txt';
        }
        else if (this.is(text, 'xlsx|xls|xlsm')) {
            return 'k-icon k-i-excel colored-excel';
        }
        else if (this.is(text, 'doc|docx')) {
            return 'k-icon k-i-word colored-word';
        } 
        else if (this.is(text, 'csv')){
            return 'k-icon k-i-csv';
        }
        else if (this.is(text, 'ppt|pptx')){
            return 'k-icon k-i-ppt colored-ppt';
        }
        else if (this.is(text, 'ini|inf')){
            return 'k-icon k-i-config';
        }
        else if(this.is(text, 'zip')){
            return 'k-icon k-i-zip';
        }
        else {
            return 'k-icon k-i-file';
        }
    }

    getVaultFolderList() {
      var vaultFolderList = []

      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var payload = {
        company: this.props.user.company
      }
      axios.post('api/commonVaultFolderListGet', payload, { headers }).then(response => {
        if (response.data.code === 200) {
          console.log(response.data)
          if (response.data.vaultFolders.length > 0){
            for (let dataItem of response.data.vaultFolders){
              vaultFolderList = vaultFolderList.concat(dataItem.folder)
            }
            this.setState({ vaultFolderList: vaultFolderList});
          }
          else
            this.setState({ vaultFolderList: vaultFolderList});
        }
      }).catch(err => {
        console.log(err);
      });
    }

    getCommonFileDescriptions() {
      var descriptions = []

      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var payload = {
        company: this.props.user.company
      }
      axios.post('api/commonFileDescriptionsGet', payload, { headers }).then(response => {
        if (response.data.code === 200) {
          console.log(response.data)
          if (response.data.descriptions.length > 0){
            for (let dataItem of response.data.descriptions){
              descriptions = descriptions.concat(dataItem.description)
            }
            this.setState({ descriptions: descriptions});
          }
          else
            this.setState({ descriptions: descriptions});
        }
      }).catch(err => {
        console.log(err);
      });
    }

    render() {

        return (
            <div>
                {this.props.user.user!==this.props.uploadUser.user && <strong style={{textAlign:'center'}}>Viewing {this.props.user.user}'s documents</strong>}
                <div>
                    <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                        
                        
                        {this.props.user.access.indexOf('docTab7')!==-1 && (<TabStripTab title={this.state.styleSettings.docTab7}>


                            { !this.isEmpty(this.state.selectedHousehold) &&    this.showUploadAdmin('user') && <UploadForm idToken={this.props.idToken} company={this.props.user.company}  user={this.props.user.user} household={this.state.selectedHousehold.household} uploadUser={this.props.uploadUser} uploadMethod={this.onUploadHouseholdDoc} type='household' files={this.state.householdFiles} folders={this.state.householdFolders} vaultFolderList={this.state.vaultFolderList} descriptions={this.state.descriptions} button={<Button className="docButton" icon="upload"> Upload Document</Button>} />}   
                            


                            <Button className="docButton" disabled={!this.state.fileSelected} icon="download" onClick={(event) => this.onClick(event)}>Download {this.getDownButtonText(this.state.fileDownload)}</Button>      
                            
                
                            {this.showUploadAdmin('user') && <Button className="docButton" disabled={this.state.fileDownload.path===""} onClick={this.onUserDeleteHousehold} icon='delete'>Delete</Button>}           

                            {(this.props.uploadUser.role === 'admin' || this.props.uploadUser.user === this.props.user.user) && <span>
                                <AddFolder addFolder={this.createNewFolder} idToken={this.props.idToken} user={this.props.uploadUser} directory={'households/' + this.state.selectedHousehold.household} button={<Button className="docButton" icon="folder-add">Add Folder</Button>} />
                            </span>
                            }

                            <Button className="docButton" onClick={this.onGridLayoutClick}>{this.state.layoutText}</Button>
                            
                            <label className='bgText'>  Household: </label>
                            

                            <ComboBox
                                style={{width:'30vw'}}
                                data={this.state.dropdownHHResult}
                                textField={(this.state.showHouseholdName && this.props.user.role === 'user') ? 'householdName' : 'household'}
                                onChange={this.onHouseholdChange}
                                value={this.state.selectedHousehold} 
                                filterable={true}
                                
                                onFilterChange={(e)=>{
                                    this.setState({dropdownHHResult:filterBy(this.state.households, e.filter)});
                                }}
                            />


                            {this.state.gridLayout && <span className="fileList">
                                <StatefulGrid 
                                //data={filterBy(orderBy(this.state.gridHouseholdFiles, this.state.sort), this.state.gridFilter)} 
                                data={this.processData()}
                                selectedField="selected"
                                onRowClick={(event) => this.onItemClick(event, this.state.householdFiles, this.state.householdFolders, this.state.gridHouseholdFiles)}
                                onSelectionChange={(e) => this.selectionChange(e, this.state.gridHouseholdFiles)}
                                onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.gridHouseholdFiles)}
                                filterable={this.state.filterable}
                                filter={this.state.gridFilter}
                                sortable={true}
                                sort={this.state.sort}
                                onSortChange={(e) => {
                                    this.setState({
                                        sort: e.sort
                                    });
                                }}
                                onFilterChange={(e)=>{this.setState({gridFilter:e.filter})}}
                                >
                                <GridToolbar>
                                <Button icon='refresh' title='Refresh' onClick={this.getHouseholdFiles}>{this.state.refreshText}</Button>
                                <Button icon='filter' title='Filter' onClick={(e)=>this.setState({filterable:!this.state.filterable})}>Filter</Button>
                                </GridToolbar>
                                <GridColumn field="selected" headerClassName='gridHeader' filterable={false} headerSelectionValue={this.state.gridHouseholdFiles.findIndex(dataItem => dataItem.selected === false) === -1} />
                                <GridColumn field="category" width='200px' headerClassName='gridHeader' title="Folder" />
                                <GridColumn title="Document Details" field="text" width='600px'  headerClassName='gridHeader'cell={this.FileCell} />
                            </StatefulGrid></span>}
                            
                            {!this.state.gridLayout && this.props.user.role !== 'user' && <FileTree className="scrollTree" showDelete={true}  loading={this.state.householdLoading} data={this.state.householdFiles} user={this.props.uploadUser} idToken={this.props.idToken} onItemClick={(event) => this.onItemClick(event, this.state.householdFiles, this.state.householdFolders)} onDelete={(event) => this.onUserDeleteHousehold(event)}/>}
                            {!this.state.gridLayout && this.props.user.role === 'user' && <FileTree className="scrollTree" showDelete={false}  loading={this.state.householdLoading} data={this.state.householdFiles} user={this.props.uploadUser} idToken={this.props.idToken} onItemClick={(event) => this.onItemClick(event, this.state.householdFiles, this.state.householdFolders)} onDelete={(event) => this.onUserDeleteHousehold(event)}/>}

                        </TabStripTab>)}

                    </TabStrip>
                    {this.state.refreshText && this.state.gridLayout && <GridLoading/>}
                </div>              
            </div>
        )
    };

    getDownButtonText(files){
        if(Array.isArray(files) && files.length>1){
            return files.length+' files';
        }
        else if(Array.isArray(files) && files.length===1){
            if(!files[0].text.endsWith('/'))
                return files[0].text;
            else 
                return "";
        }
        else if(Array.isArray(files) && files.length===0){
            return "";
        }
        else if (files!==undefined && files!==null){
            if(!files.text.endsWith('/'))
                return files.text;
            else 
                return "";
        }
        else{
            return "";
        }
    }

    showUpload(role) {        
        if (this.props.uploadUser.role === 'admin') {
            return true;
        }
        else if((role==='advisor' || role==='user') && this.props.uploadUser.role==='advisor'){
            return true;
        }
        else if(((role==='user' && this.props.uploadUser.role === 'user') && this.props.uploadUser.upload===true) || 
                ((this.props.uploadUser.upload!==false) && this.state.styleSettings.enableUserUploadDocument===true)){
                //((this.props.uploadUser.upload===undefined || this.props.uploadUser.upload==="default") && this.props.compSettings.userUpload===true)){
            return true;
        }
        return false;
    }


    showUploadAdmin(role) {
        if (this.props.uploadUser.role === 'admin') {
            return true;
        }
        //not sure about this one
        else if((role==='advisor' || role==='user') && this.props.uploadUser.role==='advisor'){
            return true;
        }
        return false;
      }


    onClick = (event) => {
        this.download();
    }

    onLinkClick = (event, file, idToken) => {      
        const headers = { 'authorization': 'Bearer '.concat(idToken) };
        var payload = {
            company:this.props.user.company,
            path:file.path,
            name:file.text
            }
        
            //.get("api/download?company="+this.props.user.company+"&path="+file.path+"&name="+file.text, {headers})
            axios
            .post("api/download",payload, {headers})
            .then((response) => {
                window.open(response.data, '_blank');
            })
            .catch((e) => {
                return false;
            });
        return false;
    }

    onDoubleItemClick = (event, itemArray, gridArray) =>{
        var copyFiles = itemArray.slice();

        if(event.dataItem===undefined){
            if (event.item.selected === undefined || event.item.selected === false) {
                this.clearSelected(copyFiles);
            }
            this.setState(copyFiles);

            if (!Array.isArray(event.item.items) && event.item.items === undefined) {
                this.setState({ fileDownload: event.item });
                if (event.item.selected === true) {
                    event.item.selected = false;
                    this.setState({ fileDownload: { text: "", path: "" } });
                    this.setState({fileSelected: false});

                }
                else {
                    event.item.selected = true;
                    this.setState({fileSelected: true});
                }

            }
        }
        else{
            if (event.dataItem.selected === undefined || event.dataItem.selected === false) {
                this.clearSelected(copyFiles);
            }
            this.setState(copyFiles);

            if (!Array.isArray(event.dataItem.items) && event.dataItem.items === undefined) {
                this.setState({ fileDownload: event.dataItem });
                if (event.dataItem.selected === true) {
                    event.dataItem.selected = false;
                    this.setState({ fileDownload: { text: "", path: "" } });
                    this.setState({fileSelected: false});
                }
                else {
                    event.dataItem.selected = true;
                    this.setState({fileSelected: true});
                }

            }
        }
        this.download();
        this.clearSelected(copyFiles);
        this.setState(copyFiles);
        this.setState({ fileDownload: { text: "", path: "" } });
        this.setState({fileSelected: false});
    }

    onItemClick = (event, itemArray, folderArray, gridArray) =>{
        if (this.clickTimeout !== null) {
            this.onDoubleItemClick(event, itemArray);
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
          } else {
            this.onSingleItemClick(event, itemArray, folderArray, gridArray);  
            this.clickTimeout = setTimeout(()=>{
            clearTimeout(this.clickTimeout)
              this.clickTimeout = null
            }, 200)
          }
    }

    onSingleItemClick = (event, itemArray, folderArray, gridArray) => {
        //console.log(event);
        var copyFiles = itemArray.slice();
        var copyFolders = [];

        if(folderArray!==null){
        copyFolders = folderArray.slice();
        }

        if(event.dataItem===undefined){
            if (event.item.selected === undefined || event.item.selected === false) {
                this.clearSelected(copyFiles);
                this.clearSelected(copyFolders);
            }
            this.setState(copyFiles);

            //not a folder
            if (event.item.items === undefined) {
                this.setState({ fileDownload: event.item });
                if (event.item.selected === true) {
                    event.item.selected = false;
                    this.setState({ fileDownload: { text: "", path: "" } })
                    this.setState({fileSelected: false});
                }
                else {
                    event.item.selected = true;
                    this.setState({ fileDownload: event.item });
                    this.setState({fileSelected: true});
                }
            }
            //folder without any contents
            else if(event.item.items.length===0){
                if (event.item.selected === true) {
                    event.item.selected = false;
                    this.setState({ fileDownload: { text: "", path: "" } });
                    this.setState({fileSelected: false});
                }
                else {
                    event.item.selected = true;
                    this.setState({ fileDownload: event.item});
                    this.setState({fileSelected: false});
                }
            }
        }
        else{
            if (event.dataItem.selected === undefined || event.dataItem.selected === false) {
                this.clearSelected(copyFiles);
                this.clearSelected(copyFolders);
                this.clearSelected(gridArray);
            }
            this.setState(copyFiles);

            //not a folder
            if (event.dataItem.items === undefined) {
                this.setState({ fileDownload: event.dataItem });
                if (event.dataItem.selected === true) {
                    event.dataItem.selected = false;
                    this.setState({ fileDownload: { text: "", path: "" } });
                    this.setState({fileSelected: false});
                }
                else {
                    event.dataItem.selected = true;
                    this.setState({ fileDownload: event.dataItem });
                    this.setState({fileSelected: true});
                }
            }
            //folder without any contents
            else if(event.dataItem.items.length===0){
                if (event.dataItem.selected === true) {
                    event.dataItem.selected = false;
                    this.setState({ fileDownload: { text: "", path: "" } });
                    this.setState({fileSelected: false});
                }
                else {
                    event.dataItem.selected = true;
                    this.setState({ fileDownload: event.dataItem});
                    this.setState({fileSelected: false});
                }
            }
        }
    }

    onUserDeleteHousehold = (e) => {
        this.deleteUserOrHHFile("docTab7");
    }

    deleteUserOrHHFile(docTab)
    {
        var confirm;
        var pathToDelete;
        if(!Array.isArray(this.state.fileDownload)){
            pathToDelete = this.state.fileDownload.path;
            if(pathToDelete.endsWith("/")){
                confirm = window.confirm("Are you sure you want to delete this folder?");
            }
            else{
                confirm = window.confirm("Are you sure you want to delete this file?");
            }
        }
        else if(this.state.fileDownload.length===1){
            pathToDelete = this.state.fileDownload[0].path;
            if(pathToDelete.endsWith("/")){
                confirm = window.confirm("Are you sure you want to delete this folder?")
            }
            else{
                confirm = window.confirm("Are you sure you want to delete this file?");
            }
        }
        else{
            confirm = window.confirm("Are you sure you want to delete the selected files?");
        }

        if (confirm === true) {
            var apiBaseUrl = "/api/";
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                "files": this.state.fileDownload,
                "company":this.props.user.company,
                "user": ""
            }
            axios
                .post(apiBaseUrl + "/deleteFile", payload, {headers})
                .then((response) => {
                    if (response.data.code === 400) {
                        alert("Error deleting file.");
                    }
                    this.setState({fileSelected:false});
                    this.setState({fileDownload:{text:"", path:""}})
                    if (docTab === "docTab1")
                        this.getFileTree();
                    else if (docTab === "docTab7")
                        this.getHouseholdFiles();

                    this.forceUpdate();
                })
                .catch((e) => {
                    alert("Error deleting file.");
                });

        }
    }



    clearSelected(arr) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].items !== undefined) {
                for (var j = 0; j < arr[i].items.length; j++) {
                    arr[i].items[j].selected = false;
                }
                arr[i].selected = false;                       
            }
            else {
                arr[i].selected = false;
            }
        }
    }

    sortFolders(arr){
        var order;

        if(this.state.styleSettings.documentFolderOrder!==null && this.state.styleSettings.documentFolderOrder!==undefined && this.state.styleSettings.documentFolderOrder!==''){
            order=this.state.styleSettings.documentFolderOrder.split(',');
        }

        var sorted = arr;
        if(order!==undefined && order!==null){
            sorted = arr.sort(function(a,b){
                var inA = order.indexOf(a.text.substring(0,a.text.length-1));
                var inB = order.indexOf(b.text.substring(0,b.text.length-1));
        
                if(inA === -1)
                return 1;
                
                return inA-inB;
            });
        }

        return sorted;
    }

    componentDidMount() {
        this.getHouseholdMappings(true);
        this.getVaultFolderList();
        this.getCommonFileDescriptions();
        this.getShowHouseholdNameSetting();
        this.getStyleSettings();
    }

    //handles if viewing another users documents and then clicking doc tab
    componentDidUpdate(){
        if(this.state.portalUser!==this.props.user.user){
            this.setState({portalUser:this.props.user.user});
            this.getHouseholdMappings();
        }
    }


    onHouseholdChange = (e) =>{
        
        if (!this.isEmpty(e.target.value))
        {
            this.setState({selectedHousehold:e.target.value}, () => {
                this.getHouseholdFiles();
            });        
        }
        else
        {
            this.setState({householdFiles:[] });
            this.setState({householdFolders:[] });
            this.setState({gridHouseholdFiles:[]});
            this.setState({householdLoading:false});
            this.setState({refreshText:''});
            this.setState({selectedHousehold:""})
        }
      
    }
    

}

export default HouseholdDocCenter;