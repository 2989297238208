import React, { Component } from "react";
import { DropDownList } from '@progress/kendo-react-dropdowns';


class CategoryDropDown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      default: { text: "-Select a folder-" }
    }
  }

  render() {
    return (
      <div>
        <DropDownList
            data={this.props.folders}
            textField="text"
            defaultItem={this.props.default || this.state.default}
            onChange={this.props.onChange}
            popupSettings={style}
          />
      </div>     
    );
  }
}


const style = {
  className: "top",
};
export default CategoryDropDown;