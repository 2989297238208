import React, { Component } from "react";
import Popup from "reactjs-popup";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { Button } from "@progress/kendo-react-buttons";


class AddHouseholdUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
        users:[],
        result:[],
        loading:true,
        dataState:{skip:0, take:10},

        household:this.props.household,
        company:this.props.company,
        mapUser:""
    }
    
    this.clearState = this.clearState.bind(this);
    this.getUsers=this.getUsers.bind(this);
    this.createCell=this.createCell.bind(this);
    this.addHouseholdUser=this.addHouseholdUser.bind(this);
  }

  componentDidMount(){
    this.getUsers();
  }

  getUsers(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };  

    var payload ={
      company:this.props.company,
      household:this.props.household
    }

    axios.post('api/userHHList', payload , {headers}).then(response=>{
      var result = process(response.data.users, this.state.dataState);
      this.setState({users:response.data.users, result:result, loading:false});
    }).catch(err=>{
      console.log(err);
      this.setState({users:[], loading:false});
    });
  }

  changeDataState = (e)=>{
    var newData = process(this.state.users, e.data);
    this.setState({dataState:e.data, result:newData});
  }


  
  addHouseholdUser(e, cell, callback){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      company:this.props.company,
      household:this.state.household,
      email: cell.dataItem.email
    }
    axios.post("api/createHouseholdUserMapping", payload, {headers}).then(response => {
      if(response.data.code===200){
        alert('Household/User Mapping created successfully');
        //callback();
      }
      else{
        alert(response.data.error);
        //callback("ERROR");
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err); 
        //callback("ERROR");
      });    
  }





  createCell(cell){
    if(cell.rowType==='data'){
      return (
          <div>          
          <Button className='docButton' onClick = {(event) => this.addHouseholdUser(event, cell)}>Add User Mapping</Button>
          </div>
      );
    }
    else{
      return (
        <></>
      );
    }
  }




  clearState(){
    this.setState({
      account:''
    });
}

  render() {
    return(
      <Popup size="lg" trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
        {close => (

      <div className='modal'>
              <Button className="close" 
                  icon="close" 
                  onClick={() => {
                    this.props.refresh();
                    close();
                  } }
                  />

              <div className="header" align="left">
                Select User to Map
              </div>


        <Grid data={this.state.result}
              filterable={true}
              sortable={true}
              pageable={{pageSizes:[10,25,50,100,250,500]}}
              onDataStateChange={this.changeDataState}
              {...this.state.dataState}>
            <GridColumn width='200' headerClassName='gridHeader' field='nickname' title='User' />
            <GridColumn width='300' headerClassName='gridHeader' field='email' title='Email' />
            <GridColumn filterable={false}  headerClassName='gridHeader' width='200'  editable={false} cell={(props) => this.createCell(props)}/>
        </Grid>
        {this.state.loading && <GridLoading />}

                <div className="actions">

        <Button
          className="button"
          onClick={() => {
            this.props.refresh();
            close();
          }}
        >
          Close
        </Button>

        

        </div>



      </div>
                )}
                </Popup>
        
    );
  }

}

export default AddHouseholdUser;