import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';

class GridPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {

      }



  }


  render() {
      return (
        <Popup trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={close} />


              <div className="header" align="left">
                Account Mappings
              </div>

              <div className="content">
                {this.props.grid}
              </div>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    close()
                  }}
                >
                  Close
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };

}


export default GridPopup;