import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class EditWidget extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.widget.name,
      description: this.props.widget.description,
      configuration: this.props.widget.configuration,
      types: this.props.widget.types,
      module: this.props.widget.module,
      order: this.props.widget.order,
      active: this.props.widget.active
    }
  }

  clearState = () => {
    this.setState({
      name: this.props.widget.name,
      description: this.props.widget.description,
      configuration: this.props.widget.configuration,
      types: this.props.widget.types,
      module: this.props.widget.module,
      order: this.props.widget.order,
      active: this.props.widget.active
    });
  }

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  }

  onConfigurationChange = (e) => {
    this.setState({ configuration: e.target.value });
  }

  onTypesChange = (e) => {
    this.setState({ types: e.target.value });
  }

  onModuleChange = (e) => {
    this.setState({ module: e.target.value });
  }

  onOrderChange = (e) => {
    this.setState({ order: parseInt(e.target.value) });
  }

  onActiveChange = (e) => {
    this.setState({ active: !this.state.active });
  }

  updateWidget = (e) => {
    var widget = this.props.widget;

    var headers = { 'Authorization': 'Bearer '.concat(widget.idToken) };

    var payload = {
      widgetId: widget.widgetId,
      name: this.state.name,
      description: this.state.description,
      configuration: this.state.configuration,
      types: this.state.types,
      module: this.state.module,
      order: this.state.order,
      active: this.state.active,
      deleted: false,
      company: widget.company,
    };

    axios.post('api/updateWidget', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        widget.refreshList();
      }
      else {
        alert("Unable to update widget.");
      }
    }).catch(err => { alert("An error occured when updating the widget."); console.log(err) });
  }

  render() {
    return (
      <Popup trigger={this.props.button} onClose={() => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">
            <Button className="close" icon="close" onClick={() => { close(); }} />
            <div className="header" align="left">
              Edit Widget
            </div>
            <div className="content">
              <div>
                <label>Widget Name: </label>
                <input defaultValue={this.props.widget.name} onChange={this.onNameChange} />
              </div>
              <br />
              <div>
                <label>Description: </label>
                <input defaultValue={this.props.widget.description} onChange={this.onDescriptionChange} />
              </div>
              <br />
              <div>
                <label>Configuration: </label>
                <input defaultValue={this.props.widget.configuration} onChange={this.onConfigurationChange} />
              </div>
              <br />
              <div>
                <label>Types: </label>
                <input defaultValue={this.props.widget.types} onChange={this.onTypesChange} />
              </div>
              <br />
              <div>
                <label>Module: </label>
                <input defaultValue={this.props.widget.module} onChange={this.onModuleChange} />
              </div>
              <br />
              <div>
                <label>Order: </label>
                <input type="numeric" defaultValue={this.props.widget.order} onChange={this.onOrderChange} />
              </div>
              <br />
              <div style={{ marginTop: '10px' }}>
                <label>Active: </label>
                <input type="checkbox" name="active" onChange={this.onActiveChange} checked={this.state.active} />
              </div>
            </div>
            <div className="actions">
              <Button
                className="button"
                onClick={() => { close(); }}>
                Cancel
              </Button>
              <Button
                className="upload"
                onClick={(event) => { this.updateWidget(event); close(); }}>
                Update
              </Button>
            </div>
          </div>
        )}
      </Popup>
    );
  };
}

export default EditWidget;