import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import ViewsList from './ViewsList'
import axios from 'axios';

class RolesList extends Component {

    constructor(props) {
        super(props);

        var permissionRoles = this.props.permission.roles != null ? this.props.permission.roles.slice() : [];

        this.state = {
            roles: [],
            permissionRoles: permissionRoles,
        }
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles = (cb) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.permission.idToken) };

        axios.get("api/roles?company=" + this.props.permission.company, { headers }).then(response => {
            if (response.data.code === 200) {
                var formattedRoles = response.data.roles.filter(role => role.active).map(role => {
                    const isOverride = this.props.permission.roles != null ? this.props.permission.roles.findIndex(r2 => r2.roleguid === role.guid && r2.isOverride) > -1 : false;
                    const active = this.props.permission.roles != null ? this.props.permission.roles.findIndex(r2 => r2.roleguid === role.guid && r2.active) > -1 : false;
                    return {
                        name: role.role,
                        page: role.guid,
                        userLevel: role.userLevel,
                        isGlobal: role.isGlobal,
                        isOverride: isOverride,
                        active: active,
                        originalActive: (role.isGlobal && isOverride) ? !active : active,
                        permissions: role.permissionList, 
                    }
                });
                this.setState({
                    roles: formattedRoles,
                    permissionRoles: formattedRoles.filter(item => this.state.permissionRoles.findIndex(role => role.roleguid === item.page) > -1),//.map(item => item.page)
                    originalPermissionRoles: formattedRoles.filter(item => this.state.permissionRoles.findIndex(role => role.roleguid === item.page) > -1)//.map(item => item.page)
                }, () => {
                    if (cb)
                        cb();
                });
            }
            else {
                if (cb)
                    cb();
                this.setState({ refreshText: '' });
                alert("An error occured while fetching the roles list.");

            }
        }).catch(error => console.log(error));
    }

    clearState = () => {
        this.setState({ permissionRoles: [...this.state.originalPermissionRoles] });
    }

    onRolesChange = (role, e) => {
        var newPermissionRoles = this.state.permissionRoles.slice();
        var indRole = newPermissionRoles.findIndex(r2 => r2.page === role.page);

        if (indRole === -1) {
            // if role is not in the array, add it
            newPermissionRoles.push({ ...role, active: e.value, isOverride: (role.isGlobal && role.originalActive !== e.value) });
        } else if (indRole > -1 && (role.isGlobal && this.props.permission.company !== 'cssi') && ((e.value !== role.originalActive) || (e.value && role.isGlobal))) {
            // if permission is in the array, is inherited
            newPermissionRoles[indRole] = { ...role, active: e.value, isOverride: (role.isGlobal && role.originalActive !== e.value) };
        } else {
            // if permission is in the array, is not inherited
            newPermissionRoles.splice(indRole, 1);
        }

        this.setState({ permissionRoles: newPermissionRoles });
    }

    updateRole = (e) => {
        var permission = this.props.permission;
        const headers = { 'authorization': 'Bearer '.concat(permission.idToken) };

        var payload = {
            company: permission.company,
            permissionguid: permission.guid,
            permission: permission.permission,
            description: permission.description,
            api: permission.api,
            method: permission.method,
            active: permission.active,
            order: permission.order,
            roles: this.state.permissionRoles.filter(role =>
            (role.company === 'cssi' ||
                this.state.roles.findIndex(r2 => r2.page === role.page &&
                    (
                        !role.isGlobal ||
                        role.isOverride
                    )
                ) > -1
            )
            ),
            isGlobal: permission.isGlobal,
            isOverride: (permission.isGlobal && permission.company !== 'cssi') ? true : false,
            ignoreRoleUpdate: true,
        }

        axios.post('api/updatePermission', payload, { headers }).then(response => {
            if (response.data.code === 200) {
                permission.refreshList();
                permission.refreshUser('permissions', permission.company);
            }
            else {
                alert("Error: Permission could not be updated.");
            }
        });
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={(e) => { close(); }} />
                        <div className="header" align="left">
                            Edit Roles
                        </div>
                        <div className="content">
                            <div style={{ maxHeight: '25vh', overflow: 'auto' }}>
                                <ViewsList
                                    data={[...this.state.roles].filter(role => role.userLevel >= this.props.permission.userLevel || role.userLevel == null)}
                                    // disabledPages={[...this.state.roles].filter(role => role.userLevel === this.props.permission.userLevel).map(role => role.page)}
                                    views={this.state.permissionRoles}
                                    company={this.props.permission.company}
                                    isGlobal={this.props.permission.isGlobal}
                                    onChange={this.onRolesChange}
                                />
                            </div>
                        </div>
                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>
                            <Button
                                className="upload"
                                onClick={(event) => { this.updateRole(event); close(); }}>
                                Update
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>

        );
    };
}

export default RolesList;