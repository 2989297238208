import React, { Component } from "react";
//import {parseDate} from '@telerik/kendo-intl';
import { DatePicker, DateTimePicker } from '@progress/kendo-react-dateinputs';
import { MultiSelect, DropDownList } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { parseDate } from '@telerik/kendo-intl';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';


class CreateStagedBulkEmailSet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() , '00', '00', '00'),
      //fromDate: new Date(),
      //toDate: new Date(),
      toDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() , '23', '59', '59'),
      maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() , '23', '59', '59'),
      folderLocation: { name: "Both (HH/User)", value: "Both" },//1 = both, 2 = hh, 3 = user
      folderLocationOptions: [],
      adminUsers: [],
      adminUsersSelected: [],

      fileList: [],
      result: [],
      dataState: { skip: 0, take: 25, sort: [{ field: 'fileLocation', dir: 'asc' }, { field: 'displayName', dir: 'asc' }] },
      loading: false,
      TotalDocuments: 0,
      UnmappedDocuments: 0,
      DistinctEmailAddrs: 0,
      vaultFolderList: ['<All Folders>'],
      vaultFolder: ['<All Folders>']
    }

    this.loadDocumentList = this.loadDocumentList.bind(this);
    this.getDate = this.getDate.bind(this)


  }

  componentDidMount() {

    //define list of main document locations
    this.state.folderLocationOptions.push({ name: "Both (HH/User)", value: "Both" });
    this.state.folderLocationOptions.push({ name: "Household Only", value: "HHOnly" });
    this.state.folderLocationOptions.push({ name: "User Only", value: "UserOnly" });

    //get the list of users that have uploaded documents
    this.getUploadUserList();
    this.getVaultFolderList();

  }

  getDate(sqlDate, isUTC){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if(d){
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }


  getUploadUserList() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company
    }
    axios.post('api/uploadUserListGet', payload, { headers }).then(response => {
      if (response.data.code === 200) {

        if (response.data.uploadUserList.length > 0)
          this.setState({ adminUsers: response.data.uploadUserList, adminUsersSelected: [{ name: '<All Users>', uploadedby: '<All>' }] });
        else
          this.setState({ adminUsers: [], adminUsersSelected: [] });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  getVaultFolderList() {
    var vaultFolderList = ['<All Folders>']

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company
    }
    axios.post('api/vaultFolderListGet', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        console.log(response.data)
        if (response.data.vaultFolders.length > 0){
          for (let dataItem of response.data.vaultFolders){
            vaultFolderList = vaultFolderList.concat(dataItem.folder)
          }
          this.setState({ vaultFolderList: vaultFolderList});
        }
        else
          this.setState({ vaultFolderList: vaultFolderList});
      }
    }).catch(err => {
      console.log(err);
    });
  }


  onFieldChange(e, field) {
    this.setState({ [field]: e.target.value });
  }


  onAdminUserChange = (event) => {
    this.setState({
      adminUsersSelected: [...event.target.value]
    });

  }


  createEmailSet() {

    //make sure user wants to create the emailStaging
    var conf = "";
    conf = window.confirm("Are you sure you want to create the Email Set?");

    if (conf) {



      //create string of "folders"
      var locationString = this.state.folderLocation;
      //get list of users (emails)
      var usersString = ";"
      if (this.state.adminUsersSelected.length > 0) {
        usersString = this.state.adminUsersSelected.map(e => e.uploadedby);
      }

      var foldersString = ";"
      if (this.state.vaultFolder.length > 0) {
        foldersString = this.state.vaultFolder.map(e => e);
      }

      //create the bulk key here
      var bulkKey = '';
      var l_date = new Date();

      var year = l_date.getFullYear();
      var month = (1 + l_date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      var day = l_date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      var time = l_date.toLocaleTimeString();
      bulkKey = month + '' + day + '' + year + '_' + time;

      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var payload = {
        company: this.props.user.company,
        bulkKey: bulkKey,
        locationString: locationString.value,
        usersString: usersString,
        foldersString: foldersString,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate
      }
      
      axios.post('api/createEmailStaging', payload, { headers }).then(response => {
        if (response.data.code === 200) {
          //response.data.bulkEmailKey
          //notify user of new bulkKey and where they can see it
          window.alert("New Email Set Created, Key=" + response.data.bulkEmailKey);
        }
      }).catch(err => {
        console.log(err);
      });
      

    }

  }

  loadDocumentList() {

    //create string of "folders"
    var locationString = this.state.folderLocation;
    //get list of users (emails)

    var usersString = ";"
    if (this.state.adminUsersSelected.length > 0) {
      usersString = this.state.adminUsersSelected.map(e => e.uploadedby);
    }

    var foldersString = ";"
    if (this.state.vaultFolder.length > 0) {
      foldersString = this.state.vaultFolder.map(e => e);
    }

    

    //call the sql to load the list
    this.setState({ loading: true, fileList: [], result: [] });

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      locationString: locationString.value,
      usersString: usersString,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      foldersString: foldersString
    }
    axios.post('api/fileUploadedListGet', payload, { headers }).then(response => {
      if (response.data.code === 200) {


        var fileList = []
        for (let row of response.data.fileList){
          row.uploadDateObject = this.getDate(row.uploadDate)
          fileList.push(row)
        }

        var result = process(fileList, this.state.dataState);

        this.setState({
          fileList: fileList
          , result: result
          , loading: false
          , TotalDocuments: response.data.TotalDocuments
          , UnmappedDocuments: response.data.UnmappedDocuments
          , DistinctEmailAddrs: response.data.DistinctEmailAddrs
        });
      }
    }).catch(err => {
      console.log(err);
    });


  }


  changeDataState = (e) => {
    var newData = process(this.state.fileList, e.data);
    this.setState({ dataState: e.data, result: newData });
  }




  render() {
    return (
      <>



        <div>
          <table className="styleTable">
            <tbody>
              <tr>
                <td>
                  <label style={{ paddingLeft: '10px' }}><strong>Upload From: </strong></label><DateTimePicker value={this.state.fromDate} format={"M/d/yyyy hh:mm a"} max={this.state.maxDate} onChange={(e) => this.onFieldChange(e, 'fromDate')} />
                </td>
                <td>
                  <label style={{ paddingLeft: '5px' }}><strong>Upload To: </strong></label><DateTimePicker value={this.state.toDate} max={this.state.maxDate} format={"M/d/yyyy hh:mm a"} onChange={(e) => this.onFieldChange(e, 'toDate')} />
                </td>
                <td>
                  <label style={{ paddingLeft: '5px' }}><strong> File Locations </strong></label>
                  <DropDownList
                    data={this.state.folderLocationOptions}
                    textField="name"
                    dataItemKey="value"
                    onChange={(e) => this.onFieldChange(e, 'folderLocation')}
                    value={this.state.folderLocation}
                  />


                </td>
                <td>
                  <label style={{ paddingLeft: '5px' }}><strong> Vault Folder </strong></label>
                  <MultiSelect
                    className="scrollMulti"
                    data={this.state.vaultFolderList}
                    autoClose={false}
                    style={{width:'250px'}}
                    onChange={(e) => this.onFieldChange(e, 'vaultFolder')}
                    value={this.state.vaultFolder}
                    popupSettings={style}
                    placeholder="Select vault folders."
                  />


                </td>
                <td>
                  <label style={{ paddingLeft: '5px' }}><strong> Upload Users: </strong></label>
                  <MultiSelect
                    className="scrollMulti"
                    autoClose={false}
                    style={{width:'250px'}}
                    data={this.state.adminUsers}
                    textField="name"
                    dataItemKey="uploadedby"
                    onChange={this.onAdminUserChange}
                    value={this.state.adminUsersSelected}
                    popupSettings={style}
                    placeholder="Select upload users."
                  />

                </td>
                

              </tr>
            </tbody>
          </table>
          <br/>
          <Button className='docButton' icon='refresh' onClick={(e) => this.loadDocumentList()} >Review Document List</Button>
        </div>


        <div>
          <ExcelExport data={this.state.fileList} fileName={'BulkDocumentEmailList_Export.xlsx'} ref={(exporter) => { this._export = exporter; }}>
            <ExcelExportColumn field='fileLocation' title='Document Vault Location' />
            <ExcelExportColumn field='displayName' title='File Name' />
            <ExcelExportColumn field='EndUserName' title='End User' />
            <ExcelExportColumn field='EndUserEmail' title='End User Email' />
            <ExcelExportColumn field='uploadedBy' title='Uploaded By' />
            <ExcelExportColumn field='uploadDate' title='Uploaded Date' />
          </ExcelExport>


          <div className='houseMappingList'>
            <Grid data={this.state.result}
              sortable={true}
              filterable={true}
              pageable={true}
              style={{ height: '500px' }}
              onDataStateChange={this.changeDataState}
              {...this.state.dataState}
            >


              <GridToolbar>

                <div>

                  <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>

                  {this.state.DistinctEmailAddrs !== 0 &&
                    <Button className='docButton' icon='upload' onClick={(e) => this.createEmailSet()} >Create Email Set</Button>
                  }


                  <strong>Total Documents:{this.state.TotalDocuments}</strong>
                  {this.state.UnmappedDocuments !== 0 && <strong style={{ color: 'red' }} >  Unmapped Documents:{this.state.UnmappedDocuments}</strong>}
                  <strong>  Distinct Email Addrs:{this.state.DistinctEmailAddrs}</strong>
                </div>



              </GridToolbar>
              <GridColumn field='fileLocation' title='File Location' />
              <GridColumn field='displayName' title='File Name' />
              <GridColumn field='EndUserName' title='End User to Notify' />
              <GridColumn field='uploadedBy' title='Uploaded By' />
              <GridColumn field='uploadDateObject' title='Uploaded Date' format="{0:g}" />


            </Grid>
            {this.state.loading && <GridLoading />}
          </div>
        </div>



      </>
    );
  }


  _export;
  export = () => {
    this._export.save();
  }

}

const style = {
  className: "top",
};



export default CreateStagedBulkEmailSet;