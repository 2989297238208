import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import BlendedIndex from './components/BlendedIndex';
import SecurityClassificationSplit from './components/SecurityClassificationSplit';
import axios from 'axios';
import ModelFees from "./components/ModelFees";
import BenchmarkIndexes from "./components/BenchmarkIndexes";


class DataManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabSelected:0,
      styleSettings:{},
    }
  }

  handleSelect = (e) => {
    this.setState({ tabSelected: e.selected });
  }

  componentDidMount(){
    this.getStyleSettings()
  }

  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
        'showFilters'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {


        this.setState({styleSettings: response.data.styleSettings, filterable:response.data.styleSettings.showFilters})
    })
    .catch(err => {
        console.error(err);
    })
  }

  render() {
    return(
      <>
        {this.props.styleSettings && <div>
            <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                <TabStripTab title='Blended Index'>
                    <BlendedIndex user={this.props.user} idToken={this.props.idToken} filterable={this.state.filterable} styleSettings={this.props.styleSettings} previewUnmapped={this.props.previewUnmapped} uploadUser={this.props.uploadUser}/>
                </TabStripTab>
                <TabStripTab title='Security Classification Split'>
                    <SecurityClassificationSplit user={this.props.user} idToken={this.props.idToken} styleSettings={this.props.styleSettings} previewUnmapped={this.props.previewUnmapped} uploadUser={this.props.uploadUser}/>
                </TabStripTab>
                <TabStripTab title='Model Fees'>
                    <ModelFees user={this.props.user} idToken={this.props.idToken} styleSettings={this.props.styleSettings} previewUnmapped={this.props.previewUnmapped} uploadUser={this.props.uploadUser}/>
                </TabStripTab>
                <TabStripTab title='Benchmark Indexes'>
                    <BenchmarkIndexes user={this.props.user} idToken={this.props.idToken} styleSettings={this.props.styleSettings} previewUnmapped={this.props.previewUnmapped} uploadUser={this.props.uploadUser}/>
                </TabStripTab>
            </TabStrip>
        </div>}
      </>
    );
  }

}

export default DataManagement;