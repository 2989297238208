import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import CreateLocation from './components/CreateLocation';
import axios from 'axios';

class LocationList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locations:[],
      locsSelected:'',
      isLocSelected:false
    }

    this.getLocations = this.getLocations.bind(this);
    this.onLocDelete = this.onLocDelete.bind(this);
  }

  componentDidMount(){
    this.getLocations();
  }

  getLocations(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/location?company="+this.props.user.company, {headers}).then(response=>{          
      this.setState({locations:response.data});
      this.props.updateCompSettings('locations', response.data);
    }).catch(err=>console.log(err));
    this.setState({locsSelected:''});
    this.setState({isLocSelected:false});
  }

  onLocDelete(){
    var payload = {
      company:this.props.user.company,
      names:''
    }

    var locs = this.state.locsSelected.slice();

    for(let i=0; i<locs.length; i++){
      if(i!==locs.length-1){
        payload.names = payload.names+locs[i].name+',';
      }
      else{
        payload.names = payload.names+locs[i].name;
      }
    }

    const confirm = window.confirm("Are you sure you want to delete these location(s)?");
    if(confirm){
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      axios.post("api/deletelocation", payload, {headers}).then(response=>{          
        if(response.data.code===200){
          alert('Location(s) were deleted successfully.');
        }
        else{
          alert('An error occured while deleting location(s).');
        }
        this.getLocations();
      }).catch(err=>{alert('An error occured while deleting location(s).');this.getLocations();console.log(err);});
    }
  }

  selectionChange(event, gFiles){
    event.dataItem.selected = !event.dataItem.selected;
    var selectedFiles = [];
    for(let i=0; i<gFiles.length; i++){
        if(gFiles[i].selected){
            selectedFiles.push(gFiles[i]);
        }
    }
    this.setState({locsSelected:selectedFiles});
    if(selectedFiles.length>0){
        this.setState({isLocSelected: true});
    }
    else{
        this.setState({isLocSelected: false});
    }
    this.forceUpdate();
  }

  headerSelectionChange(event, gFiles){
    const checked = event.syntheticEvent.target.checked;
    gFiles.forEach(item => item.selected = checked);
    var selectedFiles = [];
    for(let i=0; i<gFiles.length; i++){
        if(gFiles[i].selected){
            selectedFiles.push(gFiles[i]);
        }
    }
    this.setState({locsSelected:selectedFiles});
    if(selectedFiles.length>0){
        this.setState({isLocSelected: true});
    }
    else{
        this.setState({isLocSelected: false});
    }
    this.forceUpdate();
  }


  render() {
    return(
        <div>
            <Grid data={this.state.locations}
                    selectedField='selected'
                    onRowClick={(e) => this.selectionChange(e, this.state.locations)}
                    onSelectionChange={(e) => this.selectionChange(e, this.state.locations)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.locations)}>
                <GridToolbar>
                    <CreateLocation idToken={this.props.idToken} uploadMethod={this.getLocations} user={this.props.user} button={<Button icon='plus'>Add Location</Button>}/>
                    <CreateLocation idToken={this.props.idToken} uploadMethod={this.getLocations} user={this.props.user} locEdit={this.state.locsSelected[0]} button={<Button icon='edit' disabled={this.state.locsSelected.length!==1}>Edit</Button>}/>
                    <Button icon='delete' disabled={!this.state.isLocSelected} onClick={this.onLocDelete}>Delete</Button>
                </GridToolbar>
                <GridColumn headerClassName='gridHeader' field="selected" />
                <GridColumn headerClassName='gridHeader' field="name" title="Name" />
                <GridColumn headerClassName='gridHeader' field="address" title="Address" />
                <GridColumn headerClassName='gridHeader' field="city" title="City" />
                <GridColumn headerClassName='gridHeader' field="state" title="State" />
                <GridColumn headerClassName='gridHeader' field="zip" title="Zip" />
                <GridColumn headerClassName='gridHeader' field="email" title="Email" />
                <GridColumn headerClassName='gridHeader' field="website" title="Web" />
                <GridColumn headerClassName='gridHeader' field="phone" title="Phone" />
            </Grid>
        </div>
    );
  }

}

export default LocationList;