import React from "react";
import { Switch } from '@progress/kendo-react-inputs';

const InputBoolean = ({ name, label, defaultChecked, valid, value, defaultSet, _handleChange }) =>
    (
        <div>
            {defaultSet && <strong>{label}: </strong>}
            {!defaultSet && <label>{label}: </label>}
            <br></br>
            <Switch
                name={name}
                checked={(value === "True" ? true : value === "False" ? false : value )}
                defaultChecked={defaultChecked}
                valid={valid}
                onChange={_handleChange}
            />
            <br></br>
        </div>
    );
export default InputBoolean;