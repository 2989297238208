import React from "react";
import { Input } from '@progress/kendo-react-inputs';

const InputTextField = ({ name, label, defaultValue, valid, _handleChange }) =>

    (
        <div>
            <label>{label}: </label>
            <br></br>
            <Input
                type="text"
                name={name}
                defaultValue={defaultValue}
                autoComplete="off"
                valid={valid}
                onChange={_handleChange}
            />
            <br></br>
        </div>
    );
export default InputTextField;