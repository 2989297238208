import React from 'react';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import UploadForm from '../DocCenter/components/UploadForm';
import { orderBy } from '@progress/kendo-data-query';

const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class PdfTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            data: [],
            content: [],
            contentSelected: false,
            reportSelect: '',
            changes: false,
            reportsLoading: true,
            gridFilter: undefined,
            filterable: props.styleSettings.showFilters,
            reportStyles: [],
            sort: [{ field: 'uploadDate', dir: 'desc' }],
            fileDownload: [],
        };

        this.getStaticContentList = this.getStaticContentList.bind(this);
        this.headerCellRender = this.headerCellRender.bind(this);
        this.refreshContent = this.refreshContent.bind(this)
    }

    componentDidMount() {
        this.getStaticContentList();
    }

    getStaticContentList() {
        this.setState({ refreshText: 'Loading...', reportSelected: false, reportSelect: '', });
        var content = [];


        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/getPdfTerms`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {


                response.data.data.forEach(contentInResponse => {

                    contentInResponse.uploadDate = this.getDate(contentInResponse.uploadDate);

                    contentInResponse.selected = false

                    content.push(contentInResponse)

                })
                // var sort=[{ field: 'updated', dir: 'desc' }]
                this.setState({ data: content.map(cloneProduct), content: content, refreshText: '' })

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => {
            if (item.title !== event.dataItem.title) {
                item.selected = false
            }
        });
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles }, () => { this.forceUpdate(); });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    refreshContent() {
        this.setState({ fileDownload: [] });
        this.setState({ fileSelected: false });
        this.getStaticContentList();
    }


    getDate(sqlDate, isUTC) {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        if (isUTC)
            return utc;
        else
            return d;
    }

    onLinkClick = (event, rep, idToken) => {
        const headers = { 'authorization': 'Bearer '.concat(idToken) };
        axios
            .get("api/download?company=" + this.props.user.company + "&path=" + rep.contentPath + "&name=" + rep.contentName, { headers })
            .then((response) => {
                window.open(response.data, '_blank');
            })
            .catch((e) => {
                console.error("ERROR");
                return false;
            });

        return false;
    }

    onDelete = (e) => {
        var files = [];
        var rep;
        var addFile = {};
        for (let i = 0; i < this.state.fileDownload.length; i++) {
            rep = this.state.fileDownload[i];
            addFile.path = rep.contentPath;
            addFile.text = rep.contentName;
            files.push(addFile);
            addFile = {};
        }

        var confirm = window.confirm("Are you sure you want to delete this file?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                files: files,
                company: this.props.user.company,
                isPdfTerms: true
            };
            axios.post('api/deleteFile', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getStaticContentList();
                }
                else {
                    alert("Error: File could not be deleted.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getStaticContentList();
                }
            }).catch(e => console.log(e));
        }
    }

    headerCellRender(thElement, cellProps) {
        if (thElement !== null) {
            if (cellProps.field === 'selected')
                return (
                    <div />
                );
            else {
                return thElement
            }
        }
        else {
            return thElement;
        }
    }

    onSetBanner = (e) => {

        var confirm = window.confirm("Are you sure? This will be the banner that users see upon logging in");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                banners: this.state.fileDownload,
                company: this.props.user.company,
            };
            axios.post('api/setBanner', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getStaticContentList();
                }
                else {
                    alert("Error: Disclaimer could not be deleted.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getStaticContentList();
                }
            }).catch(e => console.log(e));
        }
    }

    render() {
        return (
            <div className='staticContent'>

                <StatefulGrid data={orderBy(this.state.content,this.state.sort)}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.content)}
                    onRowClick={(e) => this.selectionChange(e, this.state.content)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.content)}
                    filterable={this.state.filterable}
                    headerCellRender={this.headerCellRender}
                    sortable={true}
                    sort={this.state.sort}
                    resizable
                >
                    <GridToolbar>
                        <Button icon='refresh' title='Refresh' onClick={this.refreshContent}>{this.state.refreshText}</Button>
                        <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                        <UploadForm idToken={this.props.idToken} uploadMethod={this.refreshContent} files={this.state.content} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} type='pdfTerms' button={<Button className="docButton" icon="upload">New PDF</Button>} />
                        {this.props.uploadUser.role === 'admin' && (<UploadForm idToken={this.props.idToken} repEdit={this.state.fileDownload[0]} uploadMethod={this.refreshContent} files={this.state.content} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} type='pdfTermsEdit' button={<Button className="docButton" disabled={this.state.fileDownload.length !== 1} icon='edit'>Edit</Button>} />)}
                        {this.props.uploadUser.role === 'admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete</Button>)}
                        {this.props.uploadUser.role === 'admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onSetBanner} >Set as Banner</Button>)}
                    </GridToolbar>
                    <GridColumn width='50px' filterable={false} field="selected" headerClassName='gridHeader' headerSelectionValue={this.state.content.findIndex(dataItem => dataItem.selected === false) === -1 && this.state.content.length > 0} />
                    <GridColumn
                        width='180px'
                        field="title"
                        title="Title"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.contentPath && <button className='link-button' style={{ color: '#656565' }} onClick={(event) => this.onLinkClick(event, props.dataItem, this.props.idToken)}>{props.dataItem.title}</button>}
                                {!props.dataItem.contentPath && props.dataItem.title}
                            </td>
                        }
                    />
                    <GridColumn field="description" headerClassName='gridHeader' title="Hyperlink Text" />
                    <GridColumn
                        width='100px'
                        field="bannerSelected"
                        headerClassName='gridHeader'
                        title="Selected"
                        filterable={false}
                        cell={(props) =>
                            <td>
                                {props.dataItem.bannerSelected ? 'Yes' : ''}
                            </td>
                        }
                    />
                    <GridColumn width='250px' field="uploadDate" headerClassName='gridHeader' filter="date" format="{0:g}" title="Last Updated:" />
                    {/* <GridColumn
                        width='180px'
                        field="title"
                        title="Report"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.samplePath && <button className='link-button' style={{ color: '#656565' }} onClick={(event) => this.onLinkClick(event, props.dataItem, this.props.idToken)}>{props.dataItem.title}</button>}
                                {!props.dataItem.samplePath && props.dataItem.title}
                            </td>
                        } />
                    <GridColumn width='80px' field="platform" headerClassName='gridHeader' title="Platform" filterCell={createFilterDropDownCell(this.state.platforms, '')} />
                    <GridColumn width='125px' field="category" headerClassName='gridHeader' title="Category" filterCell={createFilterDropDownCell(this.state.categories, '')} />
                    <GridColumn width='400px' field="description" headerClassName='gridHeader' title="Description" />
                    <GridColumn width='120px' field="reportName" headerClassName='gridHeader' title="File Name" />
                    <GridColumn width='90px' field="expiration" headerClassName='gridHeader' filter="date" format="{0:d}" title="Expiration" />
                    <GridColumn width='145px' field="uploadDate" headerClassName='gridHeader' filter="date" format="{0:g}" title="Last Updated:" /> */}
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='webRep' />}
            </div>
        )
    }

}

export default PdfTerms