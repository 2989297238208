import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import ViewsList from './ViewsList'
import axios from 'axios';

class UsersList extends Component {

    constructor(props) {
        super(props);

        var roleUsers = this.props.role.users.slice();

        this.state = {
            users: [],
            roleUsers: roleUsers,
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = (cb) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.role.idToken) };
        console.log('ul',this.props.userLevel)
        axios.get("api/users?company=" + this.props.role.company+"&userlevel="+this.props.userLevel, { headers }).then(response => {
            if (response.data.code === 200) {
                var formattedUsers = response.data.users.filter(user => user.active).map(user => { return {name: user.nickname,page: user.id}});
                this.setState({ users: formattedUsers,
                    roleUsers: formattedUsers.filter(item => this.state.roleUsers.findIndex(user => user === item.page) > -1 ).map(item => item.page)
                 }, () => {
                    if (cb)
                        cb();
                });
            }
            else {
                if (cb)
                    cb();
                this.setState({ refreshText: '' });
                alert("An error occured while fetching the users list.");

            }
        }).catch(error => console.log(error));
    }

    clearState = () => {
        this.setState({ roleUsers: this.props.role.users.slice() });
    }

    onUsersChange = (user) => {
        var newRoleUsers = this.state.roleUsers.slice();
        var indUser = newRoleUsers.indexOf(user.page);

        if (indUser === -1)
        newRoleUsers.push(user.page);
        else
        newRoleUsers.splice(indUser, 1);
        this.setState({ roleUsers: newRoleUsers });
    }

    updateUser = () => {
        var role = this.props.role;
        const headers = { 'authorization': 'Bearer '.concat(role.idToken) };

        var payload = {
            company: role.company,
            roleguid: role.guid,
            role: role.role,
            description: role.description,
            active: role.active,
            order: role.order,
            permissions: role.permissions != null ? role.permissions : [],
            users: this.state.roleUsers != null ? this.state.roleUsers : [],
            ignoreRoleUpdate: true,
            isGlobal: role.isGlobal,
            isOverride: (role.isGlobal && role.company !== 'cssi') ? true : false,
            ignorePermissions: true
        }

        axios.post('api/updateRole', payload, { headers }).then(response => {
            if (response.data.code === 200) {
                role.refreshList();
                role.refreshUser('roles',role.company);
            }
            else {
                alert("Error: User could not be updated.");
            }
        });
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={() => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={(e) => { close(); }} />
                        <div className="header" align="left">
                            Edit Users
                        </div>
                        <div className="content">
                            <div style={{ maxHeight: '25vh', overflow: 'auto' }}><ViewsList 
                                data={this.state.users} 
                                disabledPages={[...this.state.users].filter(user => user.page === this.props.role.guid).map(user => user.page)}
                                views={this.state.roleUsers} 
                                onChange={this.onUsersChange} 
                                /></div>
                        </div>
                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>
                            <Button
                                className="upload"
                                onClick={(event) => { this.updateUser(event); close(); }}>
                                Update
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>

        );
    };
}

export default UsersList;