import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import CreateTemplate from './components/CreateTemplate';
import MailVariables from './components/MailVariables';
import axios from 'axios';

class MailTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = {
        templates:[],
        tempsSelected:'',
        isTempSelected:false
    }
    
    this.getMailTemplates = this.getMailTemplates.bind(this);
    this.onTemplateDelete = this.onTemplateDelete.bind(this);

  }

  componentDidMount(){
    this.getMailTemplates();
  }

  getMailTemplates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/mailtemplate?company="+this.props.user.company, {headers}).then(response=>{          
      this.setState({templates:response.data});
    }).catch(err=>console.log(err));
    this.setState({tempsSelected:''});
    this.setState({isTempSelected:false});
  }

  onTemplateDelete(){
    var payload = {
      company:this.props.user.company,
      titles:''
    }

    var temps = this.state.tempsSelected.slice();

    for(let i=0; i<temps.length; i++){
      if(i!==temps.length-1){
        payload.titles = payload.titles+temps[i].title+',';
      }
      else{
        payload.titles = payload.titles+temps[i].title;
      }
    }

    const confirm = window.confirm("Are you sure you want to delete these template(s)?");
    if(confirm){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      axios.post("api/deletemailtemplate", payload, {headers}).then(response=>{          
        if(response.data.code===200){
          alert('Template(s) were deleted successfully.');
        }
        else{
          alert('An error occured while deleting template(s).');
        }
        this.getMailTemplates();
      }).catch(err=>{alert('An error occured while deleting template(s).');this.getMailTemplates();console.log(err);});
    }
  }

  selectionChange(event, gFiles){
    event.dataItem.selected = !event.dataItem.selected;
    var selectedFiles = [];
    for(let i=0; i<gFiles.length; i++){
      if(gFiles[i].selected){
        selectedFiles.push(gFiles[i]);
      }
    }
    this.setState({tempsSelected:selectedFiles});
    if(selectedFiles.length>0){
      this.setState({isTempSelected: true});
    }
    else{
      this.setState({isTempSelected: false});
    }
    this.forceUpdate();
  }

  headerSelectionChange(event, gFiles){
    const checked = event.syntheticEvent.target.checked;
    gFiles.forEach(item => item.selected = checked);
    var selectedFiles = [];
    for(let i=0; i<gFiles.length; i++){
      if(gFiles[i].selected){
        selectedFiles.push(gFiles[i]);
      }
    }
    this.setState({tempsSelected:selectedFiles});
    if(selectedFiles.length>0){
      this.setState({isTempSelected: true});
    }
    else{
      this.setState({isTempSelected: false});
    }
    this.forceUpdate();
  }

  render() {
      return(
          <div>
            <div className="mailtemplates">
              <Grid data={this.state.templates}
                    selectedField='selected'
                    onRowClick={(e) => this.selectionChange(e, this.state.templates)}
                    onSelectionChange={(e) => this.selectionChange(e, this.state.templates)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.templates)}
                    >
                  <GridToolbar>
                    <CreateTemplate idToken={this.props.idToken} uploadMethod={this.getMailTemplates} user={this.props.user} button={<Button>Create New Template</Button>}/>
                    <CreateTemplate idToken={this.props.idToken} uploadMethod={this.getMailTemplates} user={this.props.user} tempEdit={this.state.tempsSelected[0]} button={<Button disabled={this.state.tempsSelected.length!==1}>Edit</Button>}/>
                    <Button disabled={!this.state.isTempSelected} onClick={this.onTemplateDelete}>Delete</Button>
                    <MailVariables button={<Button icon='gear'>Variables</Button>}/>
                  </GridToolbar>
                  <GridColumn field="selected" />
                  <GridColumn headerClassName='gridHeader' field="title" title="Template" />
                  <GridColumn headerClassName='gridHeader' field="createdBy" title="Created By:" />
              </Grid>
            </div>
          </div>
      );
  }

}

export default MailTemplate;