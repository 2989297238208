import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';

class AddRoles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role: '',
            description: '',
            isGlobal: false
        }
    }

    onChangeRole = (e) => {
        this.setState({ role: e.target.value });
    }

    onChangeDescription = (e) => {
        this.setState({ description: e.target.value });
    }

    onChangeIsGlobal = (e) => {
        this.setState({ isGlobal: !this.state.isGlobal });
    }

    createRole = (callback) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload = {
            company: this.props.company,
            role: this.state.role,
            description: this.state.description,
            order: this.props.newOrder,
            active: true,
            isGlobal: this.state.isGlobal
        }
        axios.post("api/createRole", payload, { headers }).then(response => {
            if (response.data.code === 200) {
                // alert('Role created successfully');
                callback();
            }
            else {
                alert(response.data.error);
                callback("ERROR");
            }
        }).catch(err => {
            alert("An error has occured");
            console.log(err);
            callback("ERROR");
        });
    }

    clearState = () => {
        this.setState({
            role: '',
            description: '',
            isGlobal: false
        });
    }

    render() {
        return (
            <Popup trigger={this.props.button} onClose={(e) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={close} />
                        <div className="header" align="left">
                            Create New Role
                        </div>
                        <div className="content">
                            <label>Role: </label><Input onChange={this.onChangeRole} />
                        </div>
                        <div className="content">
                            <label>Description: </label><Input name="description" onChange={this.onChangeDescription} />
                        </div>
                        {this.props.company === 'cssi' && <div style={{marginTop: '10px'}}>
                            <label>Global: </label><input type="checkbox" name="isGlobal" onChange={this.onChangeIsGlobal} value={this.state.isGlobal} />
                        </div>}
                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => {
                                    close();
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                className="upload"
                                onClick={(e) => {
                                    this.createRole((err) => {
                                        if (!err) {
                                            this.props.refreshRoles();
                                            close();
                                        }
                                    });
                                }}
                            >
                                Create Role
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>
        );
    };

}

export default AddRoles;