import React from "react";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';


export default function createFilterDropDownCell(data, defaultItem) {
    return class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
              filterText:null,
              dropFilter:null
            }
        }
        hasValue(value) {
            return value && value !== defaultItem;
        }

        render() {
            return (
                <div className="k-filtercell">
                    <ComboBox
                        data={filterBy(data,this.state.dropFilter)}
                        textField='text'
                        onChange={(event) => {
                            const hasValue = this.hasValue(event.target.value);
                            this.setState({filterText:event.target.value});
                            this.props.onChange({
                                value: hasValue ? event.target.value.text : '',
                                operator: hasValue ? 'eq' : '',
                                syntheticEvent: event.syntheticEvent
                            });
                        }}
                        filterable={true}
                        onFilterChange={(e)=>{
                            this.setState({dropFilter:e.filter});
                        }}
                        allowCustom={true}
                        value={this.state.filterText || defaultItem}
                        defaultItem={defaultItem}
                    />
                    <button
                        className="k-button k-button-icon k-clear-button-visible"
                        title="Clear"
                        disabled={!this.hasValue(this.props.value)}
                        onClick={(event) => {
                            event.preventDefault();
                            this.props.onChange({
                                value: '',
                                operator: '',
                                syntheticEvent: event
                            });
                        }}
                    >
                        <span className="k-icon k-i-filter-clear" />
                    </button>
                </div>
            );
        }
    };
}