import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import rgainFields from './rgainFields.json';

class RGainMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:{portCode:'',
                openDate:'',
                closeDate:'',
                quantity:'',
                secSymbol:'',
                secType:'',
                secName:'',
                costBasis:'',
                proceeds:'',
                shortTerm:'',
                longTerm:'',
                realizedCap:'',
                lot:'',
                TranID:''
        }
    }

    this.getMappings = this.getMappings.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount(){
      this.getMappings();
  }

  onInputChange(e, attr){
    let mappings = {...this.state.mappings};
    mappings[attr] = e.target.value.toUpperCase();
    this.setState({mappings});
  }

  uploadMappings = (e)=>{
    if(this.state.mappings.portCode===null || this.state.mappings.portCode==='')
        alert("Portfolio Id is required.");
    else{
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload ={
            company:this.props.user.company,
            mappings:this.state.mappings
        }
        axios.post('api/rgainMappings', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Realized Gains Mappings have been updated.");
            this.getMappings();
        }
        else
            alert("An error occured when trying to update mappings");
        }).catch(err=>{
        alert("An error occured when trying to update mappings");
        this.getMappings();
        });
    }
  }

  getMappings(){
    var emptyMappings = {portCode:'',
                        openDate:'',
                        closeDate:'',
                        quantity:'',
                        secSymbol:'',
                        secType:'',
                        secName:'',
                        costBasis:'',
                        proceeds:'',
                        shortTerm:'',
                        longTerm:'',
                        realizedCap:'',
                        lot:'',
                        TranID:''
    }

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/rgainMappings?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.mappings.length===0)
          this.setState({mappings:emptyMappings});
      else
        this.setState({mappings:response.data.mappings[0]});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:emptyMappings})
    });
  }


  render() {
    var data = rgainFields;
    return(
        <div>
            <table className='posMapTable'>
                <tbody>
                    <tr>
                        <td className='heading'>Field Name <hr/></td>
                        <td align='right' className='heading'>Column Letter in Excel <hr/></td>
                    </tr>
                    {data.map(field => (
                    <tr key={field.id}>
                        <td><h4>{field.name}</h4><div>{field.desc}</div></td>
                        <td align='right'><input type='text' value={this.state.mappings[field.dbField]} onChange={e=>this.onInputChange(e,field.dbField)}/></td>                  
                    </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td align='right'><Button onClick={(e)=>this.uploadMappings(e)}>Apply Changes</Button></td>
                    </tr> 
                </tbody>
            </table>
        </div>
    );
  }

}

export default RGainMapping;