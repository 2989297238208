import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import axios from 'axios';
import UserAcceptance from "./UserAcceptance";
import Logo from "./Logo";
import WelcomeText from "./WelcomeText";
import PdfTerms from "./PdfTerms";


class BannerSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabSelected: 0,
            banners: [],
            bannerLogo: '',
            bannerLogoCurrent: '',
            bannerWelcomeText: ''
        }
    }

    componentDidMount = () => {
        this.getBannerList();
    }

    handleSelect = (e) => {
        this.setState({ tabSelected: e.selected });;
    }

    getBannerList = (callback) => {
        var banners = [];

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/getBannerList`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                response.data.data.forEach(bannerInResponse => {
                    bannerInResponse.BannerTextWithNewline = bannerInResponse.BannerText.replace(/\\n/g, '\n')
                    banners.push(bannerInResponse)
                })
                this.setState({
                    banners: banners,
                    bannerLogo: banners.length > 0 ? banners[0].BannerCode : '',
                    bannerLogoCurrent: banners.length > 0 ? banners[0].BannerCode : '',
                    bannerWelcomeText: banners.length > 0 ? banners[0].BannerTextWithNewline : ''
                },() => {
                    if (callback) {
                        callback();
                    }
                })
            })
            .catch(err => {
                console.error(err);
            })
    }

    deleteBanner = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            banners: this.state.banners,
            company: this.props.user.company,
        };
        axios.post('api/deleteBanners', payload, { headers }).then(response => {
            if (response.data.code === 200) {
                callback();
            }
            else {
                alert("Error: Banner info could not be deleted.");
                callback();
            }
        }).catch(e => console.log(e));
    }

    uploadBanner = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            BannerCode: typeof(this.state.bannerLogo) === 'object' ? this.state.bannerLogo.name : this.state.bannerLogo,
            company: this.props.user.company,
            BannerText: this.state.bannerWelcomeText
        }

        axios.post("api/uploadBanner", payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    alert("Banner updated successfully.");
                }
                else if (response.data.code === 201) {
                    alert("Banner updated successfully.");
                }
                else {
                    alert("An error occured with the banner.");
                }
                callback();
        }).catch(err => { alert("An error occured with the template."); console.log(err); callback(); });
    }

    uploadLogo = (callback) => {

        if (!this.state.bannerLogo){//if removing dont do post
            callback()
        }
        else{
            var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            const formData = new FormData();
            formData.append('company', 'logos');
            formData.append('bannerCompany', this.props.user.company);
            formData.append('type', 'bannerLogo');
            formData.append('prevLogo', this.state.bannerLogoCurrent);
            if (this.state.bannerLogo.name != null) formData.append(this.props.user.company + this.state.bannerLogo.name.substring(this.state.bannerLogo.name.indexOf('.')), this.state.bannerLogo);
    
            axios.post('api/logo?company='+this.props.user.company, formData, { headers }).then(response => {
                if (response.data.code === 200) {
                    // alert("Logo has been updated.");
                }
                callback();
            }).catch(err => console.log(err));
        }     
    }

    saveBannerLogo = (file,callback) => {
        this.setState({
            bannerLogo: file
        }, () => {
            this.deleteBanner(
                () => this.uploadBanner(() => this.uploadLogo(() => this.getBannerList(() => callback())))
            );
        });
    }

    saveBannerWelcomeText = (text) => {
        this.setState({ bannerWelcomeText: text }, () => {
            this.deleteBanner(
                () => this.uploadBanner(() => this.getBannerList())
            );
        });
    }

    refreshBanners = () => {
        this.setState({ bannerLogo: '', bannerWelcomeText: '' }, () => {
            this.getBannerList();
        });
    }

    render() {
        return (
            <>
                {this.props.styleSettings && <div className="reportManagement">
                    <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                        <TabStripTab title={'Terms and Conditions'}>
                            <PdfTerms idToken={this.props.idToken} user={this.props.user} styleSettings={this.props.styleSettings} uploadUser={this.props.uploadUser} />
                        </TabStripTab>
                        <TabStripTab title={'Logo'}>
                            <Logo
                                idToken={this.props.idToken}
                                user={this.props.user}
                                uploadUser={this.props.uploadUser}
                                bannerLogo={this.state.bannerLogo}
                                refreshBanners={this.refreshBanners}
                                saveBannerLogo={this.saveBannerLogo}
                            />
                        </TabStripTab>
                        <TabStripTab title={'Welcome Text'}>
                            <WelcomeText
                                idToken={this.props.idToken}
                                user={this.props.user}
                                uploadUser={this.props.uploadUser}
                                bannerWelcomeText={this.state.bannerWelcomeText}
                                refreshBanners={this.refreshBanners}
                                saveBannerWelcomeText={this.saveBannerWelcomeText}
                            />
                        </TabStripTab>
                        {/* <TabStripTab title={'Terms and Conditions'}>
                            <TermsAndConditions idToken={this.props.idToken} user={this.props.user} uploadUser={this.props.uploadUser} />
                        </TabStripTab> */}
                        <TabStripTab title={'User Acceptance'}>
                            <UserAcceptance idToken={this.props.idToken} user={this.props.user} styleSettings={this.props.styleSettings} uploadUser={this.props.uploadUser} />
                        </TabStripTab>
                    </TabStrip>
                </div>}
            </>

        )
    }

}

export default BannerSettings;