import React, { Component } from "react";
import AssetAllocationPie from '../components/AssetAllocationPie';
import TopTenHoldings from '../components/TopTenHoldings';
import axios from 'axios';

class EquityOverview extends Component {

  pdfExportComponent;

  constructor(props) {
    super(props);

    this.state = {
      legend:false,
      grid:true,
      labels:false,
      table:false,
      hTable:true,
      hGrid:false,
      gradient:'none',
      borderWidth:0,
      borderColor:'#FFFFFF',
      assetAllocation:'',
      sector:'',
      dates:[],
      selectedDate:'',
      styleSettings: {
        chartShowAsOf: null
      }
    }
    
    this.onAssetSecChange = this.onAssetSecChange.bind(this);
  }

  componentDidMount(){
    this.getStyleSettings();
    this.getHoldDates();
  }

  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'chartShowAsOf'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      this.setState({styleSettings: response.data.styleSettings})
    })
    .catch(err => {
        console.error(err);
    })
  }

  getHoldDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      port:this.props.portfolio.account,
      household: this.props.household
    }
    axios.post('api/posDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }


  onAssetSecChange(asset, sec){
    this.setState({assetAllocation:asset, sector:sec});
  }

  render() {
    return(
        <div className='equityOverview'>
        {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value})}>
            {this.state.dates.map((date, i)=>(
              <option key={i} value={date.fDate}>{date.fDate}</option>
            ))}
          </select></>}
        {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
         {this.state.selectedDate !== '' && <table style={{width:'100%', height:'100%'}}>
            <tbody>
              <tr>
                <td style={{width:'30%', verticalAlign:'top'}}>
                  <AssetAllocationPie user={this.props.user} date={this.state.selectedDate} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} portfolio={this.props.portfolio} household={this.props.household} labelAssetAllocation={this.state.assetAllocation} labelSector={this.state.sector} onAssetSecChange={this.onAssetSecChange} idToken={this.props.idToken} borderWidth={this.props.styleSettings.acctOverviewPieBorderWidth} borderColor={this.props.styleSettings.acctOverviewPieBorderColor} gradient={this.props.styleSettings.acctOverviewPieGradient} legend={this.state.legend} labels={this.state.labels} table={this.state.table} grid={this.state.grid}/>
                </td>
                <td style={{width:'10px', verticalAlign:'top'}}/>
                <td style={{width:'70%', verticalAlign:'top'}}>
                  <TopTenHoldings user={this.props.user} date={this.state.selectedDate} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} portfolio={this.props.portfolio} household={this.props.household} assetAllocation={this.state.assetAllocation} sector={this.state.sector} idToken={this.props.idToken} table={false} grid={true}/>
                </td>
              </tr>
            </tbody>
          </table>}
        </div>
    );
  }

}

export default EquityOverview;