import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import AddHouseholdAccount from "./AddHouseholdAccount";
import AddHouseholdAccountNoPositions from "./AddHouseholdAccountNoPositions";

class HouseholdAccounts extends Component {

    constructor(props) {
    super(props);

    this.state = {
        household:this.props.household,
        company:this.props.company,
        mappings:[],
        result:[],
        loading:false,
        dataState:{sort:[{field:'account', dir:'asc'}]},
        refreshText:'',
        addAccountMappingLoading: false,
        addAccountMappingText: "Add Account Mapping"
      }
      
      this.getHouseholdMappings=this.getHouseholdMappings.bind(this);
      this.clearState=this.clearState.bind(this); 
      this.onCloseModal=this.onCloseModal.bind(this); 
      this.refresh = this.refresh.bind(this);
  }


  componentDidMount(){
    this.getHouseholdMappings();
    this.checkPositions();
  }

  
  componentWillUnmount() {
    this.clearState();
  }
  
  refresh(){
    this.getHouseholdMappings();
  }

  getHouseholdMappings(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
    this.setState({loading:true, refreshText:'Loading...'});

    var payload ={
      company:this.props.company,
      household:this.props.household
    }

    axios.post('api/householdMappingsGet' , payload , {headers}).then(response=>{
      var result = process(response.data.mappings, this.state.dataState);
      this.setState({mappings:response.data.mappings,result:result,  loading:false ,refreshText:''});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:[], loading:false, refreshText:''});
    });
  }

  checkPositions(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
    this.setState({addAccountMappingLoading:true, addAccountMappingText:'Loading...'});

    var payload ={
      company:this.props.company,
    }

    axios.post('api/checkForPositions' , payload , {headers}).then(response=>{
      var hasPositions = false
      if (response.data.result){
        hasPositions = true
      }
      this.setState({hasPositions: hasPositions, addAccountMappingLoading:false, addAccountMappingText:'Add Account Mapping'})
      
    }).catch(err=>{
      console.log(err);
      this.setState({hasPositions: false, addAccountMappingLoading:false, addAccountMappingText:'Add Account Mapping'});
    });
  }


  clearState(){
    //this.setState({selectedRole:{text:this.props.user.role}, views:this.props.user.access});
    //this.setState({mappings:[]});
  }


  createCell(cell){
    if(cell.rowType==='data'){
      return (
          <div>
          
          <Button icon="trash" onClick = {(event) => this.deleteHouseholdMapping(event, cell)}>Delete?</Button>
          </div>

      );
    }
    else{
      return (
        <></>
      );
    }
  }



  deleteHouseholdMapping(e, cell){
    var mappings = this.state.mappings.slice();
    var index = mappings.findIndex(x=>{
      return (x.id===cell.dataItem.id);
    });

    //verify deletion
    const confirm = window.confirm("Are you sure you want to delete this Mapping? (" + cell.dataItem.account + ")" );

    if(confirm===true){
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var payload = {
          company:this.props.company,
          HHid:cell.dataItem.id,
          houeshold:cell.dataItem.household,
          account:cell.dataItem.account
        }

      axios.post('api/deleteHouseholdMapping', payload, {headers}).then(response=>{
          if(response.data.code!==201){
              alert("Error: Mapping could not be deleted.");
          }
          else{      
            if(index>=0){
                alert('Household/Account Mapping deleted successfully');
                this.getHouseholdMappings();
            }

          }
      });
    }
  }

  onCloseModal = () => {
    this.clearState();
  }


  changeDataState = (e)=>{
    var newData = process(this.state.mappings, e.data);
    this.setState({dataState:e.data, result:newData});
  }


  render() {
    return(
    
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState() }} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">
            <Button className="close" icon="close" onClick={(e)=>{close();}} />


            <div className="header" align="left">
                Edit Household Accounts
              </div>

            <div className='houseMappingList'>
                <Grid data={this.state.result}
                    sortable={true}
                    onDataStateChange={this.changeDataState}
                    {...this.state.dataState}>

                <GridToolbar>
                <strong>Current Accounts Mapped</strong>
                
                <Button icon='refresh' title='Refresh' onClick={(e)=>this.getHouseholdMappings()}>{this.state.refreshText}</Button>
                {/* {this.state.addAccountMappingLoading &&
                  <Button className='docButton' icon='plus' disabled={true}>Loading...</Button>
                } */}
                {!this.state.addAccountMappingLoading &&
                  <>
                    {this.state.hasPositions &&
                      <AddHouseholdAccount refresh={this.refresh}  household={this.props.household} user={this.props.loggedInUser} idToken={this.props.idToken} company={this.props.company} disabled={this.state.addAccountMappingLoading}  button={<Button className='docButton' icon='plus' onClick={(e)=>this.getHouseholdMappings()   }  >Add Account Mapping</Button>} />
                    }
                    {!this.state.hasPositions &&
                      <AddHouseholdAccountNoPositions refresh={this.refresh}  household={this.props.household} user={this.props.loggedInUser} idToken={this.props.idToken} company={this.props.company} disabled={this.state.addAccountMappingLoading}  button={<Button className='docButton' icon='plus' onClick={(e)=>this.getHouseholdMappings()   }  >Add Account Mapping</Button>} />
                    }
                  </>
                }
                
                

                </GridToolbar>

                    <GridColumn headerClassName='gridHeader' width='200' field='household' title='Household'/>
                    <GridColumn headerClassName='gridHeader' width='200' field='account' title='Account'/>
                    <GridColumn headerClassName='gridHeader' width='200' field='displayName' title='Account Name'/>
                    <GridColumn headerClassName='gridHeader' width='300'  editable={false} cell={(props) => this.createCell(props)}/>
                </Grid>
            </div>

            
            <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Close
                </Button>

              </div>
        
        
              </div>
          )}
        </Popup>

    );
  }

}


export default HouseholdAccounts;