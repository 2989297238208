import React, { Component } from "react";
//import axios from 'axios';
import { Upload } from '@progress/kendo-react-upload';
//import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';

class Reports extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files:[]
    }

    this.runReport = this.runReport.bind(this);
  }

  onAdd = (e)=>{
    this.setState({files: e.newState});
  }

  onRemove = (e)=>{
    this.setState({files: e.newState});
  }

  onProgress = (e)=>{
    this.setState({files: e.newState});
  }

  onStatusChange = (e)=>{
    if(e.response!==undefined && e.response.response.code===200){
      this.setState({files: []});
      alert("File(s) were successfully uploaded!");
      this.runReport();
    }
    else if(e.response!==undefined){
      alert("Files could not be uploaded successfully. Please try again.")
    }
  }

  onBeforeUpload = (e) => {
    e.additionalData.company=this.props.user.company;
  }

  runReport(){

    var payload = {
      ssrsOptions:{
        company:this.props.user.company,
        storedProc:'GetPositions',
        rdl:'Report1',
        folder:'ssrs',
      }
    };

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
    axios.post('api/runSSRS', payload, {headers}).then(response=>{
      console.log(response);
    }).catch(err=>console.log(err));

  }

  render() {
    return(
        <div>
          <Upload batch={true}
                autoUpload={false}
                multiple={false}
                restrictions={{allowedExtensions:['.zip']}}
                files={this.state.files}
                onAdd={this.onAdd}
                onRemove={this.onRemove}
                onProgress={this.onProgress}
                onStatusChange={this.onStatusChange}
                onBeforeUpload={this.onBeforeUpload}
                saveUrl={"api/reports?company="+this.props.user.company}
                saveHeaders={{'authorization': 'Bearer '.concat(this.props.idToken)}}
          />
        </div>
    );
  }

}

export default Reports;