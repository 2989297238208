import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import benchFields from './benchFields.json';

class BenchMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:{portCode:'',
            accountName:'',
            indexOrder:'',
            indexName:'',
            indexSymbol:'',
            returnsMTD:'',
            returnsQTD:'',
            returnsQtrr:'',
            returns3Month:'',
            returns6Month:'',
            returnsYTD:'',
            returnsPrevYear:'',
            returnsYear:'',
            returns3Year:'',
            returns5Year:'',
            returns7Year:'',
            returns10Year:'',
            returns15Year:'',
            returns20Year:'',
            returns25Year:'',
            returnsITD:'',
            perfStDate:''
        }
    }

    this.getMappings = this.getMappings.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount(){
      this.getMappings();
  }

  onInputChange(e, attr){
    let mappings = {...this.state.mappings};
    mappings[attr] = e.target.value.toUpperCase();
    this.setState({mappings});
  }

  uploadMappings = (e)=>{

    if(this.state.mappings.portCode===null || this.state.mappings.portCode==='')
        alert("Portfolio Code is required.");
    else if(this.state.mappings.indexSymbol===null || this.state.mappings.indexSymbol==='')
        alert("Index Symbol is required.");
    else{
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload ={
            company:this.props.user.company,
            mappings:this.state.mappings
        }
        axios.post('api/benchMappings', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Benchmark Mappings have been updated.");
            this.getMappings();
        }
        else
            alert("An error occured when trying to update mappings");
        }).catch(err=>{
        alert("An error occured when trying to update mappings");
        this.getMappings();
        });
    }
  }

  getMappings(){
    var emptyMappings = {portCode:'',
    accountName:'',
    indexOrder:'',
    indexName:'',
    indexSymbol:'',
    returnsMTD:'',
    returnsQTD:'',
    returnsQtr:'',
    returns3Month:'',
    returns6Month:'',
    returnsYTD:'',
    returnsPrevYear:'',
    returnsYear:'',
    returns3Year:'',
    returns5Year:'',
    returns7Year:'',
    returns10Year:'',
    returns15Year:'',
    returns20Year:'',
    returns25Year:'',
    returnsITD:'',
    perfStDate:''
}
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/benchMappings?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.mappings.length===0)
          this.setState({mappings:emptyMappings});
      else
        this.setState({mappings:response.data.mappings[0]});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:emptyMappings})
    });
  }


  render() {
    var data = benchFields;
    return(
        <div>
            <table className='posMapTable'>
                <tbody>
                    <tr>
                        <td className='heading'>Field Name <hr/></td>
                        <td align='right' className='heading'>Column Letter in Excel <hr/></td>
                    </tr>
                    {data.map(field => (
                    <tr key={field.id}>
                        <td><h4>{field.name}</h4><div>{field.desc}</div></td>
                        <td align='right'><input type='text' value={this.state.mappings[field.dbField]} onChange={e=>this.onInputChange(e,field.dbField)}/></td>                  
                    </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td align='right'><Button onClick={(e)=>this.uploadMappings(e)}>Apply Changes</Button></td>
                    </tr> 
                </tbody>
            </table>
        </div>
    );
  }

}

export default BenchMapping;