import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import * as utils from '../utilities';
import PropTypes from 'prop-types';

class PermissionWrapper extends Component {

    render() {
        return (<> 
            {utils.checkPermission(this.props.user,this.props.allowedPermission) ?
                <>
                    {this.props.children}
                </>
                :
                (("hidden" in this.props && this.props.hidden) ? <></> : <Alert color="danger">You do not have permission to access this resource.</Alert>)
                }
        </>
        )
    }
};

// PermissionWrapper.propTypes = {
//     user: PropTypes.object,
//     allowedPermission: PropTypes.string,
//     hidden: PropTypes.bool
//   };

export default PermissionWrapper;