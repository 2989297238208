import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ReportList from './ReportList';
import StaticContent from './StaticContent';
import Disclaimers from "./Disclaimers";
import Logo from "./Logo";

class ReportManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabSelected: 0
        }
    }

    handleSelect = (e) => {
        this.setState({ tabSelected: e.selected });;
    }

    render() {
        return (
            <div className="reportManagement">
                <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                    <TabStripTab title={'Report Parameters'}>
                        <ReportList idToken={this.props.idToken} user={this.props.user} />
                    </TabStripTab>
                    <TabStripTab title={'Report Disclaimers'}>
                        <Disclaimers idToken={this.props.idToken} user={this.props.user} uploadUser={this.props.uploadUser} />
                    </TabStripTab>
                    <TabStripTab title={'Report Package Static Content'}>
                        <StaticContent idToken={this.props.idToken} user={this.props.user} uploadUser={this.props.uploadUser} />
                    </TabStripTab>
                    <TabStripTab title={'Report Logo'}>
                        <Logo idToken={this.props.idToken} user={this.props.user} uploadUser={this.props.uploadUser} saveReportLogo={this.saveReportLogo} />
                    </TabStripTab>

                </TabStrip>

            </div>

        )
    }

}

export default ReportManagement;