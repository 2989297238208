import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import ViewsList from './ViewsList'
import axios from 'axios';

class RolesList extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            roles: [],
            userRoles: [],
            prevUserRoles: []
        }
    }

    componentDidMount() {
        this.getRoles(this.getUserRoles);
    }

    getUserRoles = (cb) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.user.idToken) };
        axios.get('api/userInfo?company=' + this.props.user.company + '&email=' + this.props.user.email, { headers }).then((response) => {
            if (response.data.code === 200) {
                this.setState({
                    userRoles: response.data.user.roles.map(role => this.state.roles.filter(item => item.name === role)[0].page),
                    prevUserRoles: response.data.user.roles.map(role => this.state.roles.filter(item => item.name === role)[0].page)
                }, () => {
                    if (this.props.setUserInfoLoaded != null) this.props.setUserInfoLoaded();
                    if (cb)
                        cb();
                });
            }
            else {
                if (cb)
                    cb();
                this.setState({ refreshText: '' });
                alert("An error occured while fetching the user roles list.");

            }
        }).catch(error => console.log(error));
    }

    getRoles = (cb) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.user.idToken) };
        axios.get("api/roles?company=" + this.props.user.company, { headers }).then(response => {
            if (response.data.code === 200) {
                var formattedRoles = response.data.roles.filter(role => role.active).map(role => { return { name: role.role, page: role.guid, userLevel: role.userLevel, permissions: role.permissionList } });
                this.setState({
                    roles: formattedRoles,
                    userRoles: formattedRoles.filter(item => this.state.userRoles.findIndex(role => role === item.page) > -1).map(item => item.page)
                }, () => {
                    if (cb)
                        cb();
                });
            }
            else {
                if (cb)
                    cb();
                this.setState({ refreshText: '' });
                alert("An error occured while fetching the roles list.");

            }
        }).catch(error => console.log(error));
    }

    clearState = () => {
        this.setState({ userRoles: [...this.state.prevUserRoles] });
    }

    onRolesChange = (role) => {
        var newUserRoles = this.state.userRoles.slice();
        var indRole = newUserRoles.indexOf(role.page);

        if (indRole === -1)
            newUserRoles.push(role.page);
        else
            newUserRoles.splice(indRole, 1);

        this.setState({ userRoles: newUserRoles });
    }

    updateRole = (e) => {
        var user = this.props.user;
        const headers = { 'authorization': 'Bearer '.concat(user.idToken) };

        var payload = {
            company: user.company,
            auth0: user.id,
            roles: this.state.userRoles
        }

        axios.post('api/updateUserRoles', payload, { headers }).then(response => {
            if (response.data.code === 200) {
                this.setState({
                    prevUserRoles: [...this.state.userRoles]
                },() => {
                    user.refreshList();
                    user.refreshUser('users',user.company);
                })
            }
            else {
                alert("Error: User could not be updated.");
            }
        });
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={(e) => { close(); }} />
                        <div className="header" align="left">
                            Edit Roles
                        </div>
                        <div className="content">
                            <div style={{ maxHeight: '25vh', overflow: 'auto' }}><ViewsList
                                // disabledPages={[...this.state.roles].filter(role => role.userLevel === this.props.user.userLevel).map(role => role.page)}
                                data={[...this.state.roles].filter(role => role.userLevel >= this.props.user.userLevel || role.userLevel == null)}
                                views={this.state.userRoles}
                                onChange={this.onRolesChange} /></div>
                        </div>
                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>
                            <Button
                                className="upload"
                                onClick={(event) => { this.updateRole(event); close(); }}>
                                Update
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>

        );
    };
}

export default RolesList;