import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';

class RolePermissionsPopUp extends Component {

  render() {
    return (
      <Popup trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">

            <Button className="close" icon="close" onClick={close} />


            <div className="header" align="left">
              {this.props.title} Permissions Enabled:
            </div>

            <div className="content">
              <table>
                <tbody>
                  {this.props.permissions.map(permission => (
                    <tr>
                      <td><strong>{permission.permission}</strong></td>
                      <td>{permission.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="actions">

              <Button className="button" onClick={() => { close() }}>
                Close
              </Button>

            </div>

          </div>
        )}
      </Popup>

    );
  };

}

export default RolePermissionsPopUp;