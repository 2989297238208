import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { format } from 'date-fns'
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import PackagerForm from './components/PackagerForm';
import ExcelExport from './components/ExcelExport';
import axios from 'axios';
import Progress from 'react-progressbar';
import GridLoading from '../Loaders/GridLoading';
import Popup from "reactjs-popup";
import SsrsPackagerDetail from './SsrsPackagerDetail';
import SsrsPackagerTemplateDetail from './SsrsPackagerTemplateDetail';
import SsrsPackagerTemplatePackageDetail from './SsrsPackagerTemplatePackageDetail';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { Switch } from '@progress/kendo-react-inputs';
import { parseDate } from '@telerik/kendo-intl';
import { filterBy, orderBy, process } from '@progress/kendo-data-query';
import { withState } from './with-state';
import createFilterDropDownCell from './components/FilterDropDownCell';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Grid as Grid2, Row, Col } from "react-flexbox-grid";

const StatefulGrid = withState(Grid);

class SsrsPackager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            runReportButtonFlag: false,
            portfolio: '',
            portfolioFlag: false,
            singleDate: null,
            singleDateFlag: false,
            fromDate: null,
            fromDateFlag: false,
            toDate: null,
            toDateFlag: false,
            showLots: null,
            showLotsFlag: false,
            filterBySecurityType: null,
            filterBySecurityTypeFlag: false,
            filterBySymbol: null,
            filterBySymbolFlag: false,
            reportComments: null,
            reportCommentsFlag: false,
            turnOffCloseDateProcessing: null,
            turnOffCloseDateProcessingFlag: false,
            includeUnsupervised: null,
            includeUnsupervisedFlag: false,
            capitalGains: null,
            capitalGainsFlag: false,
            separate5YearGains: null,
            separate5YearGainsFlag: false,
            sortBySecurity: null,
            sortBySecurityFlag: false,
            showCapitalAdjReturnsPaydowns: null,
            showCapitalAdjReturnsPaydownsFlag: false,
            showPurchasedAndSoldAccruedInterest: null,
            showPurchasedAndSoldAccruedInterestFlag: false,
            lumpClosingTransactions: null,
            lumpClosingTransactionsFlag: false,
            showSplitsWithTransactions: null,
            showSplitsWithTransactionsFlag: false,
            calcPerfNetOrGross: null,
            calcPerfNetOrGrossFlag: false,
            graph: null,
            graphFlag: false,
            reports: [],
            report: { name: "Select Report", key: null, folder: null },
            accounts: [],
            account: { account: "", displayName: "" },
            numPages: null,
            pageNumber: 1,
            pdfFile: null,
            pdfFlag: false,
            pdfScale: 1.25,
            status: "",
            loaderSpinnerFlag: false,
            selectedPackage: [],
            selectedPackageId: null,
            selectedPackageReport: null,
            selectedPackageName: { name: "<Create New>", key: "createnew" },
            packageList: [],
            templateList: [],
            templatePackageList: [],
            indivPackageList: [],
            groupPackageList: [],
            packageId: null,
            packageName: '',
            reportOrder: 1,
            currentPackageReports: null,
            packages: [{ Key: "Package 1", Description: "Standard Client Report Package that consists of a portfolio overview, portfolio appraisal and transaction summary report", Entity: "@smithfamily", Frequency: "Quarterly", expanded: false, sortOrder: 1 },
            { Key: "Package 2", Description: "Custom Client Report Package that consists of a portfolio overview and portfolio appraisal report", Entity: "@josephsmith", Frequency: "Monthly", expanded: false, sortOrder: 1 }],
            packageRunList: [],
            packageRunId: null,
            editScreenLoading: false,
            editReportList: [],
            openProgressModal: false,
            openRunWithInput: false,
            progress: 1,
            progressTimerId: null,//used in componentWillUnmount cleanup
            progressText: "You can close this window and your Package(s) will continue to run.",
            progressErrorString: [],
            progressWarningText: [],
            genericDates: [],
            gridFilter: undefined,
            frequencyDropdown: [{ text: "Ad-Hoc" }, { text: "Daily" }, { text: "Monthly" }, { text: "Quarterly" }, { text: "Annually" }],
            sort: [{ field: 'Frequency', dir: 'asc' }, { field: 'Key', dir: 'asc' }],
            packageRequestLoading: false,
            runPackageText: "Run Selected Package(s)",
            tabSelected: 0,
            dataState:{skip:0, take:10000, group:undefined, filter:undefined, sort:[{ field: 'Frequency', dir: 'asc' }, { field: 'Key', dir: 'asc' }]},
            indivResult:[],
            groupResult: [],
            templateResult:[],
            templatePackageResult: [],

            accountRunWithInput: null,
            groupRunWithInput: null,
            singleFileRunWithInput: false,
            filenameRunWithInput: null,
            fromDateGenericDateToggle: false,
            fromDateRunWithInputEnable: false,
            fromDateRunWithInput: null,
            fromDateRunWithInputGeneric: null,
            toDateGenericDateToggle: false,
            toDateRunWithInputEnable: false,
            toDateRunWithInput: null,
            toDateRunWithInputGeneric: null,
            genericDates_fromDate_Data: null,
            genericDates_toDate_Data: null,
            genericDates_fromDate_DataOriginal: null,
            genericDates_toDate_DataOriginal: null,
            progressBarColor: "rgb(11, 211, 24)",
            progressTextColor: "rgb(0, 0, 0)"
        }

        this.sendRequest = this.sendRequest.bind(this);
        this.getReportList = this.getReportList.bind(this);
        this.getAccountList = this.getAccountList.bind(this);
        this.getReportParameters = this.getReportParameters.bind(this);
        this.getPackageList = this.getPackageList.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.getPackageReports = this.getPackageReports.bind(this);
        this.getTemplateReports = this.getTemplateReports.bind(this);
        this.getTemplatePackageTemplates = this.getTemplatePackageTemplates.bind(this);
        this.getFileTree = this.getFileTree.bind(this);
        this.onReportListChange = this.onReportListChange.bind(this);
        this.openProgress = this.openProgress.bind(this);
        this.closeProgress = this.closeProgress.bind(this);
        this.closeRunWithInput = this.closeRunWithInput.bind(this);
        this.itemRender = this.itemRender.bind(this);
        this.getSpacedLine = this.getSpacedLine.bind(this);
        this.runWithInput = this.runWithInput.bind(this);
        this.processFileNameDateLabels = this.processFileNameDateLabels.bind(this);
        this.openFileBrowser = this.openFileBrowser.bind(this);
        this.getTemplateList = this.getTemplateList.bind(this);
        this.getTemplatePackageList = this.getTemplatePackageList.bind(this);

        this.fileInput = React.createRef();
        this.fileInput2 = React.createRef();
        this.fileInput3 = React.createRef();
        this.fileInput4 = React.createRef();
    }

    componentDidMount() {
        //this.getReportList();
        this.getAccountList();
        this.getPackageList();
        this.getTemplateList();
        this.getTemplatePackageList();
        this.getGenericDates();
        //this.props.style["--theme-primary-color"]
        //this.getFileTree();
        //console.log(this.state.selectedPackageName)
    }

    componentWillUnmount() {
        clearTimeout(this.state.progressTimerId)
    }

    onChangeHandler = (e, type) => {
        this.setState({ [type]: e.target.value });
        //console.log(e.target.value)
    }

    onFilterChange = (e) => {
        this.setState({ gridFilter: e.filter });


    }

    getFileTree = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/ssrsGetS3FileTree?company=${this.props.user.company}`, {
            method: 'GET',
            headers: headers,
        })
            .then(response => {

            })
            .catch(err => {
                console.error(err);
            })
    }


    formatDates = (date) => {

        var formattedDate = null

        return formattedDate
    }

    onReportChangeHandler = (e) => {
        this.setState({
            portfolioFlag: false, portfolio: null,
            fromDateFlag: false, fromDate: null,
            toDateFlag: false, toDate: null,
            singleDateFlag: false, singleDate: null,
            showLots: null, showLotsFlag: false,
            filterBySecurityType: null, filterBySecurityTypeFlag: false,
            filterBySymbol: null, filterBySymbolFlag: false,
            reportComments: null, reportCommentsFlag: false,
            turnOffCloseDateProcessing: null, turnOffCloseDateProcessingFlag: false,
            includeUnsupervised: null, includeUnsupervisedFlag: false,
            capitalGains: null, capitalGainsFlag: false,
            separate5YearGains: null, separate5YearGainsFlag: false,
            sortBySecurity: null, sortBySecurityFlag: false,
            showCapitalAdjReturnsPaydowns: null, showCapitalAdjReturnsPaydownsFlag: false,
            showPurchasedAndSoldAccruedInterest: null, showPurchasedAndSoldAccruedInterestFlag: false,
            lumpClosingTransactions: null, lumpClosingTransactionsFlag: false,
            showSplitsWithTransactions: null, showSplitsWithTransactionsFlag: false,
            calcPerfNetOrGross: null, calcPerfNetOrGrossFlag: false,
            graph: null, graphFlag: false,
            pdfFile: null, pdfFlag: false, pdfScale: 1.25,
        })
        //this.setState({ fromDateFlag: false })
        //this.setState({ toDateFlag: false })
        //this.setState({ singleDateFlag: false })
        //this.setState({ pdfFile: "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"})

        //wait for setState to finish before calling getReportParameters
        if (e.target === undefined) {
            this.setState({ report: e, runReportButtonFlag: true }, () => {
                this.getReportParameters(e.name)
            });
        }
        else {
            this.setState({ report: e.target.value, runReportButtonFlag: true }, () => {
                this.getReportParameters(e.target.value.name)
            });
        }

    }


    onPackageReportChangeHandler = (e) => {
        this.setState({
            portfolioFlag: false, portfolio: null,
            fromDateFlag: false, fromDate: null,
            toDateFlag: false, toDate: null,
            singleDateFlag: false, singleDate: null,
            showLots: null, showLotsFlag: false,
            filterBySecurityType: null, filterBySecurityTypeFlag: false,
            filterBySymbol: null, filterBySymbolFlag: false,
            reportComments: null, reportCommentsFlag: false,
            turnOffCloseDateProcessing: null, turnOffCloseDateProcessingFlag: false,
            includeUnsupervised: null, includeUnsupervisedFlag: false,
            capitalGains: null, capitalGainsFlag: false,
            separate5YearGains: null, separate5YearGainsFlag: false,
            sortBySecurity: null, sortBySecurityFlag: false,
            showCapitalAdjReturnsPaydowns: null, showCapitalAdjReturnsPaydownsFlag: false,
            showPurchasedAndSoldAccruedInterest: null, showPurchasedAndSoldAccruedInterestFlag: false,
            lumpClosingTransactions: null, lumpClosingTransactionsFlag: false,
            showSplitsWithTransactions: null, showSplitsWithTransactionsFlag: false,
            calcPerfNetOrGross: null, calcPerfNetOrGrossFlag: false,
            graph: null, graphFlag: false,
            pdfFile: null, pdfFlag: false, pdfScale: 1.25,
        })
        this.setState({ selectedPackageReport: e.target.value, report: { name: e.target.value.report, key: e.target.value.report } })

        //var reportToAdd = {
        //    singleDate: singleDateFormatted,
        //    from: fromDateFormatted,
        //    to: toDateFormatted,
        //    graph: this.state.graph,
        //    report: this.state.report.name,
        //    database: this.props.user.company,
        //    packageId: packageId,
        //}


        this.getReportParameters(e.target.value.report)
        //this.setState({toDate:  format(e.target.value.to, "MM-dd-yyyy")})


    }


    getReportList() {
        var reports = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetReports?company=${this.props.user.company}`, {
            method: 'GET',
            headers: headers,
        })
            .then(response => {
                reports = response.data.data

                this.setState({ reports: reports })
            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    // getAccountList() {
    //     var accounts = [];
    //     const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    //     axios(`api/ssrsGetAccounts?database=` + this.props.user.company, {
    //         method: 'GET',
    //         headers: headers
    //     })
    //         .then(response => {
    //             accounts = response.data.data

    //             this.setState({ accounts: accounts })
    //         })
    //         .catch(err => {
    //             console.error(err);
    //         })
    //     //return reports;

    // }

    getSpacedLine(line, maxLength) {
        var newLine = line
        if (line.length > maxLength) {
            newLine = line.substring(0, maxLength - 3) + '...'
        }
        else if (line.length < maxLength) {
            for (var i = line.length; i < maxLength; i++) {
                newLine = newLine + " "
            }
        }
        return newLine
    }

    getAccountList() {
        var entities = [];
        var groupEntities = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetEntities`, {
            method: 'GET',
            params: { database: this.props.user.company },
            headers: headers
        })
            .then(response => {
                entities = response.data.data
                var splitLine
                var code
                var name
                var type
                var formattedLine
                var newEntities = []
                entities.forEach(line => {
                    splitLine = line.label.split('|')
                    code = this.getSpacedLine(splitLine[0], 12)
                    name = this.getSpacedLine(splitLine[1], 32)
                    type = this.getSpacedLine(splitLine[2], 8)

                    formattedLine = code + " | " + name + " | " + type
                    newEntities.push({ value: line.value, label: formattedLine })
                })
                entities = newEntities
                this.setState({ accountsWithInput: entities, accountsWithInputForFilter: entities })


                axios(`api/ssrsGetEntities`, {
                    method: 'GET',
                    params: { database: this.props.user.company, newGroups: true },
                    headers: headers
                })
                    .then(response2 => {
                        groupEntities = response2.data.data
                        var splitLine2
                        var code2
                        var name2
                        var type2
                        var formattedLine2
                        var newEntities2 = []
                        groupEntities.forEach(line => {
                            splitLine2 = line.label.split('|')
                            code2 = this.getSpacedLine(splitLine2[0], 12)
                            name2 = this.getSpacedLine(splitLine2[1], 32)
                            type2 = this.getSpacedLine(splitLine2[2], 8)

                            formattedLine2 = code2 + " | " + name2 + " | " + type2
                            newEntities2.push({ value: line.value, label: formattedLine2 })
                        })
                        groupEntities = newEntities2
                        this.setState({ groupsWithInput: groupEntities, groupsWithInputForFilter: groupEntities })
                    })
                    .catch(err => {
                        console.error(err);
                    })
            })
            .catch(err => {
                console.error(err);
            })


    }

    // getAccountList() {
    //     var entities = [];
    //     var groupEntities = [];
    //     const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    //     axios(`api/ssrsGetEntities`, {
    //         method: 'GET',
    //         params: { database: this.props.user.company, newGroups: true },
    //         headers: headers
    //     })
    //         .then(response => {
    //             groupEntities = response.data.data
    //             var splitLine2
    //             var code2
    //             var name2
    //             var type2
    //             var formattedLine2
    //             var newEntities2 = []
    //             groupEntities.forEach(line => {
    //                 splitLine2 = line.label.split('|')
    //                 code2 = this.getSpacedLine(splitLine2[0], 12)
    //                 name2 = this.getSpacedLine(splitLine2[1], 32)
    //                 type2 = this.getSpacedLine(splitLine2[2], 8)

    //                 formattedLine2 = code2 + " | " + name2 + " | " + type2
    //                 newEntities2.push({ value: line.value, label: formattedLine2 })
    //             })
    //             groupEntities = newEntities2
    //             this.setState({ accountsWithInput: groupEntities, accountsWithInputForFilter: groupEntities })
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })


    // }

    getPackageList() {
        var packages = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        this.setState({ loading: true, editPackage: false, packageSelected: false })

        this.setState({ packageList: [], indivPackageList: [], groupPackageList: [] }, () => {
            axios(`api/ssrsGetPackages?database=` + this.props.user.company, {
                method: 'GET',
                headers: headers
            })
                .then(response => {
                    packages = response.data.data
                    var packageList = []
                    var indivPackageList = []
                    var groupPackageList = []
                    packages.forEach(packageInResponse => {
                        if (packageInResponse.type === "i") {
                            indivPackageList = indivPackageList.concat({ Id: packageInResponse.packageId, Key: packageInResponse.packageName, Description: packageInResponse.packageDescription, Entity: packageInResponse.reportingGroup, Frequency: packageInResponse.frequency, SaveAsSinglePdf: packageInResponse.saveAsSinglePdf, FileName: packageInResponse.fileName, sortOrder: packageInResponse.sortOrder, advancedMode: packageInResponse.advancedMode, expanded: false, database: this.props.user.company, idToken: this.props.idToken })
                        }
                        else {
                            groupPackageList = groupPackageList.concat({ Id: packageInResponse.packageId, Key: packageInResponse.packageName, Description: packageInResponse.packageDescription, Entity: packageInResponse.reportingGroup, Frequency: packageInResponse.frequency, SaveAsSinglePdf: packageInResponse.saveAsSinglePdf, FileName: packageInResponse.fileName, sortOrder: packageInResponse.sortOrder, advancedMode: packageInResponse.advancedMode, expanded: false, database: this.props.user.company, idToken: this.props.idToken })
                        }
                        //packageList = packageList.concat({ Id: packageInResponse.packageId, Key: packageInResponse.packageName, Description: packageInResponse.packageDescription, Entity: packageInResponse.reportingGroup, Frequency: packageInResponse.frequency, SaveAsSinglePdf: packageInResponse.saveAsSinglePdf, FileName: packageInResponse.fileName, sortOrder: packageInResponse.sortOrder, advancedMode: packageInResponse.advancedMode, expanded: false, database: this.props.user.company, idToken: this.props.idToken })
                    })
                    //this.setState({ accounts: packages })
                    var indivResult = process(indivPackageList, this.state.dataState)
                    var groupResult = process(groupPackageList, this.state.dataState)
                    this.setState({ packageList: packageList, indivPackageList: indivPackageList, groupPackageList: groupPackageList, loading: false, indivResult: indivResult, groupResult: groupResult })
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false })
                })
            //return reports;
        })
    }


    getTemplateList() {
        var templates = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        this.setState({ loading: true, editPackage: false, packageSelected: false })

        this.setState({ packageList: [], indivPackageList: [], groupPackageList: [] }, () => {
            axios(`api/ssrsGetTemplates?database=` + this.props.user.company, {
                method: 'GET',
                headers: headers
            })
                .then(response => {
                    templates = response.data.data
                    var templateList = []
                    var indivPackageList = []
                    var groupPackageList = []
                    templates.forEach(packageInResponse => {
                        templateList = templateList.concat({ Id: packageInResponse.packageId, Key: packageInResponse.templateName, Description: packageInResponse.templateDescription, Entity: packageInResponse.reportingGroup, expanded: false, database: this.props.user.company, idToken: this.props.idToken })
                        //packageList = packageList.concat({ Id: packageInResponse.packageId, Key: packageInResponse.packageName, Description: packageInResponse.packageDescription, Entity: packageInResponse.reportingGroup, Frequency: packageInResponse.frequency, SaveAsSinglePdf: packageInResponse.saveAsSinglePdf, FileName: packageInResponse.fileName, sortOrder: packageInResponse.sortOrder, advancedMode: packageInResponse.advancedMode, expanded: false, database: this.props.user.company, idToken: this.props.idToken })
                    })
                    //this.setState({ accounts: packages })
                    var templateResult = process(templateList, this.state.dataState)
                    this.setState({ templateList: templateList, templateResult: templateResult, loading: false })
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false })
                })
            //return reports;
        })
    }

    getTemplatePackageList() {
        var templatePackages = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        this.setState({ loading: true, editPackage: false, packageSelected: false })

        this.setState({ packageList: [], indivPackageList: [], groupPackageList: [] }, () => {
            axios(`api/ssrsGetTemplatePackages?database=` + this.props.user.company, {
                method: 'GET',
                headers: headers
            })
                .then(response => {
                    templatePackages = response.data.data
                    var templatePackageList = []
                    templatePackages.forEach(packageInResponse => {
                        templatePackageList = templatePackageList.concat({ Id: packageInResponse.packageId, Key: packageInResponse.packageName, Description: packageInResponse.packageDescription, Entity: packageInResponse.reportingGroup, Frequency: packageInResponse.frequency, SaveAsSinglePdf: packageInResponse.saveAsSinglePdf, FileName: packageInResponse.fileName, sortOrder: packageInResponse.sortOrder, expanded: false, database: this.props.user.company, idToken: this.props.idToken, templates: packageInResponse.templates })
                        //packageList = packageList.concat({ Id: packageInResponse.packageId, Key: packageInResponse.packageName, Description: packageInResponse.packageDescription, Entity: packageInResponse.reportingGroup, Frequency: packageInResponse.frequency, SaveAsSinglePdf: packageInResponse.saveAsSinglePdf, FileName: packageInResponse.fileName, sortOrder: packageInResponse.sortOrder, advancedMode: packageInResponse.advancedMode, expanded: false, database: this.props.user.company, idToken: this.props.idToken })
                    })
                    //this.setState({ accounts: packages })
                    var templatePackageResult = process(templatePackageList, this.state.dataState)
                    this.setState({ templatePackageList: templatePackageList, templatePackageResult: templatePackageResult, loading: false })
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false })
                })
            //return reports;
        })
    }




    getPackageReports() {
        this.setState({ editScreenLoading: true });
        var reports = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        this.setState({ editReportList: [] }, () => {
            axios(`api/ssrsGetPackageReports`, {
                method: 'GET',
                params: { database: this.props.user.company, packageName: this.state.packageRunList[0].Key },
                headers: headers
            })
                .then(response => {
                    reports = response.data.data
                    reports.forEach(reportInResponse => {
                        this.setState({
                            editReportList: this.state.editReportList.concat({ text: reportInResponse.reportName, contentType: reportInResponse.contentType, value: reportInResponse.reportOrder, reportingGroup: reportInResponse.reportingGroup, reportDate: reportInResponse.reportDate, orientation: reportInResponse.orientation, paramString: reportInResponse.paramString, hierarchy: reportInResponse.hierarchy, })
                        })
                    })
                    //this.setState({ accounts: packages })
                    this.setState({ editScreenLoading: false });
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ editScreenLoading: false });
                })
            //return reports;
        })
    }

    getTemplateReports() {
        this.setState({ editScreenLoading: true });
        var reports = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        this.setState({ editReportList: [] }, () => {
            axios(`api/ssrsGetTemplateReports`, {
                method: 'GET',
                params: { database: this.props.user.company, packageName: this.state.packageRunList[0].Key },
                headers: headers
            })
                .then(response => {
                    reports = response.data.data
                    reports.forEach(reportInResponse => {
                        this.setState({
                            editReportList: this.state.editReportList.concat({ text: reportInResponse.reportName, contentType: reportInResponse.contentType, value: reportInResponse.reportOrder, reportingGroup: reportInResponse.reportingGroup, reportDate: reportInResponse.reportDate, orientation: reportInResponse.orientation, paramString: reportInResponse.paramString, hierarchy: reportInResponse.hierarchy, })
                        })
                    })
                    //this.setState({ accounts: packages })
                    this.setState({ editScreenLoading: false });
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ editScreenLoading: false });
                })
            //return reports;
        })
    }

    getTemplatePackageTemplates() {
        this.setState({ editScreenLoading: true });
        var reports = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        this.setState({ editReportList: [] }, () => {
            axios(`api/ssrsgetTemplatePackageTemplates`, {
                method: 'GET',
                params: { database: this.props.user.company, packageName: this.state.packageRunList[0].Key },
                headers: headers
            })
                .then(response => {
                    reports = response.data.data
                    reports.forEach((reportInResponse,i) => {
                        this.setState({
                            editReportList: this.state.editReportList.concat({ text: reportInResponse.name, contentType: reportInResponse.contentType, value: i + 1, reportingGroup: reportInResponse.reportingGroup, reportDate: reportInResponse.reportDate, orientation: reportInResponse.orientation, paramString: reportInResponse.paramString, hierarchy: reportInResponse.hierarchy, })
                        })
                    })
                    //this.setState({ accounts: packages })
                    this.setState({ editScreenLoading: false });
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ editScreenLoading: false });
                })
            //return reports;
        })
    }


    getGenericDates() {
        var dates = []

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetAllGenericDates`, {
            method: 'GET',
            params: { database: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                response.data.data.forEach(dateInResponse => {
                    dates.push({ label: dateInResponse.label, value: dateInResponse.value })
                })
                this.setState({ genericDates: dates })
            })
            .catch(err => {
                console.error(err);
            })

        const genericDates = [
            { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' },
            { value: 'bblq', label: 'bblq - Beginning Business Day Last Quarter' },
            { value: 'bbly', label: 'bbly - Beginning Business Day Last Year' },
            { value: 'bbpm', label: 'bbpm - Beginning Business Day Previous Month' },
            { value: 'bbpq', label: 'bbpq - Beginning Business Day Previous Quarter' },
            { value: 'bbpy', label: 'bbpy - Beginning Business Day Previous Year' },
            { value: 'bbtm', label: 'bbtm - Beginning Business Day This Month' },
            { value: 'bbtq', label: 'bbtq - Beginning Business Day This Quarter' },
            { value: 'bbty', label: 'bbty - Beginning Business Day This Year' },
            { value: 'bbnm', label: 'bbnm - Beginning Business Day Next Month' },
            { value: 'bbnq', label: 'bbnq - Beginning Business Day Next Quarter' },
            { value: 'bbny', label: 'bbny - Beginning Business Day Next Year' },
            { value: 'bdlm', label: 'bdlm - Beginning Day Last Month' },
            { value: 'bdlq', label: 'bdlq - Beginning Day Last Quarter' },
            { value: 'bdly', label: 'bdly - Beginning Day Last Year' },
            { value: 'bdpm', label: 'bdpm - Beginning Day Previous Month' },
            { value: 'bdpq', label: 'bdpq - Beginning Day Previous Quarter' },
            { value: 'bdpy', label: 'bdpy - Beginning Day Previous Year' },
            { value: 'bdtm', label: 'bdtm - Beginning Day This Month' },
            { value: 'bdtq', label: 'bdtq - Beginning Day This Quarter' },
            { value: 'bdty', label: 'bdty - Beginning Day This Year' },
            { value: 'bdnm', label: 'bdnm - Beginning Day Next Month' },
            { value: 'bdnq', label: 'bdnq - Beginning Day Next Quarter' },
            { value: 'bdny', label: 'bdny - Beginning Day Next Year' },
            { value: 'eblm', label: 'eblm - Ending Business Day Last Month' },
            { value: 'eblq', label: 'eblq - Ending Business Day Last Quarter' },
            { value: 'ebly', label: 'ebly - Ending Business Day Last Year' },
            { value: 'ebpm', label: 'ebpm - Ending Business Day Previous Month' },
            { value: 'ebpq', label: 'ebpq - Ending Business Day Previous Quarter' },
            { value: 'ebpy', label: 'ebpy - Ending Business Day Previous Year' },
            { value: 'ebtm', label: 'ebtm - Ending Business Day This Month' },
            { value: 'ebtq', label: 'ebtq - Ending Business Day This Quarter' },
            { value: 'ebty', label: 'ebty - Ending Business Day This Year' },
            { value: 'ebnm', label: 'ebnm - Ending Business Day Next Month' },
            { value: 'ebnq', label: 'ebnq - Ending Business Day Next Quarter' },
            { value: 'ebny', label: 'ebny - Ending Business Day Next Year' },
            { value: 'edlm', label: 'edlm - Ending Day Last Month' },
            { value: 'edlq', label: 'edlq - Ending Day Last Quarter' },
            { value: 'edly', label: 'edly - Ending Day Last Year' },
            { value: 'edpm', label: 'edpm - Ending Day Previous Month' },
            { value: 'edpq', label: 'edpq - Ending Day Previous Quarter' },
            { value: 'edpy', label: 'edpy - Ending Day Previous Year' },
            { value: 'edtm', label: 'edtm - Ending Day This Month' },
            { value: 'edtq', label: 'edtq - Ending Day This Quarter' },
            { value: 'edty', label: 'edty - Ending Day This Year' },
            { value: 'ednm', label: 'ednm - Ending Day Next Month' },
            { value: 'ednq', label: 'ednq - Ending Day Next Quarter' },
            { value: 'edny', label: 'edny - Ending Day Next Year' },
            { value: 'yest', label: 'yest - Yesterday' },
            { value: 'last', label: 'last - Last Business Day' },
            { value: 'prev', label: 'prev - Next To Last Business Day' }
        ]

        this.setState({
            genericDates_singleDate_Data: genericDates,
            genericDates_fromDate_Data: genericDates,
            genericDates_toDate_Data: genericDates,
            genericDates_singleDate_DataOriginal: genericDates,
            genericDates_fromDate_DataOriginal: genericDates,
            genericDates_toDate_DataOriginal: genericDates,
        })

    }



    getReportParameters = (report) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        //var report = this.state.report.name;

        //axios
        //  .post('api/ssrsGetReportParameters', payload, { headers })
        axios(`api/ssrsGetReportParameters?company=${this.props.user.company}&report=` + report, {
            method: 'GET',
            headers: headers
        })
            .then(response => {
                //begin loop through response
                //response.data.data.map((individualParameter) => {
                response.data.data.forEach(individualParameter => {

                    switch (individualParameter.ParamName) {
                        case 'portfolio':
                            this.setState({ portfolioFlag: true })
                            break
                        case 'fromDate':
                            this.setState({ fromDateFlag: true, fromDate: new Date('3/31/2018') })
                            break
                        case 'singleDate':
                            this.setState({ singleDateFlag: true, singleDate: new Date('11/12/2019') })
                            break
                        case 'toDate':
                            this.setState({ toDateFlag: true, toDate: new Date('3/31/2019') })
                            break
                        case 'showLots':
                            this.setState({ showLotsFlag: true })
                            break
                        case 'filterBySecurityType':
                            this.setState({ filterBySecurityTypeFlag: true })
                            break
                        case 'filterBySymbol':
                            this.setState({ filterBySymbolFlag: true })
                            break
                        case 'reportComments':
                            this.setState({ reportCommentsFlag: true })
                            break
                        case 'turnOffCloseDateProcessing':
                            this.setState({ turnOffCloseDateProcessingFlag: true })
                            break
                        case 'includeUnsupervised':
                            this.setState({ includeUnsupervisedFlag: true })
                            break
                        case 'capitalGains':
                            this.setState({ capitalGainsFlag: true })
                            break
                        case 'separate5YearGains':
                            this.setState({ separate5YearGainsFlag: true })
                            break
                        case 'sortBySecurity':
                            this.setState({ sortBySecurityFlag: true })
                            break
                        case 'showCapitalAdjReturnsPaydowns':
                            this.setState({ showCapitalAdjReturnsPaydownsFlag: true })
                            break
                        case 'showPurchasedAndSoldAccruedInterest':
                            this.setState({ showPurchasedAndSoldAccruedInterestFlag: true })
                            break
                        case 'lumpClosingTransactions':
                            this.setState({ lumpClosingTransactionsFlag: true })
                            break
                        case 'showSplitsWithTransactions':
                            this.setState({ showSplitsWithTransactionsFlag: true })
                            break
                        case 'calcPerfNetOrGross':
                            this.setState({ calcPerfNetOrGrossFlag: true })
                            break
                        case 'graph':
                            this.setState({ graphFlag: true })
                            break
                        default:
                            break
                    }
                })
                //end loop through response
            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    runWithInput = () => {
        var accountOverride = null
        var groupOverride = null
        var fromDateOverride = null
        var toDateOverride = null
        var singleFileOverride = null
        var filenameOverride = null

        if (this.state.accountRunWithInput) {
            accountOverride = this.state.accountRunWithInput.value
        }

        if (this.state.groupRunWithInput) {
            accountOverride = this.state.groupRunWithInput.value
        }

        if (!this.state.fromDateGenericDateToggle && this.state.fromDateRunWithInputEnable) {
            fromDateOverride = format(this.getDate(this.state.fromDateRunWithInput, true), "MM-dd-yyyy")

        }

        if (this.state.fromDateGenericDateToggle && this.state.fromDateRunWithInputGeneric) {
            fromDateOverride = this.state.fromDateRunWithInputGeneric.value
        }

        if (!this.state.toDateGenericDateToggle && this.state.toDateRunWithInputEnable) {
            toDateOverride = format(this.getDate(this.state.toDateRunWithInput, true), "MM-dd-yyyy")
        }

        if (this.state.toDateGenericDateToggle && this.state.toDateRunWithInputGeneric) {
            toDateOverride = this.state.toDateRunWithInputGeneric.value
        }

        if (this.state.singleFileRunWithInput) {
            singleFileOverride = this.state.singleFileRunWithInput
        }

        if (this.state.filenameRunWithInput) {
            filenameOverride = this.state.filenameRunWithInput
        }

        var overrides = { entity: accountOverride, fromDate: fromDateOverride, toDate: toDateOverride, singleFile: singleFileOverride, filename: filenameOverride }

        this.closeRunWithInput()
        this.sendPackageRequest(overrides)
    }


    sendRequest = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload = { reports: this.state.selectedPackage, packageName: this.state.packageName, company: this.props.user.company }

        axios
            .post("api/ssrsSavePackage", payload, { headers })
            .then((response) => {
                if (response.data.code === 200) {
                    console.log("Package saved")
                }
                else {
                    console.log("Package not saved")
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    deletePackage = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload = { packageName: this.state.packageName, company: this.props.user.company }

        axios
            .post("api/ssrsDeletePackage", payload, { headers })
            .then((response) => {
                if (response.data.code === 200) {
                    console.log("Package deleted")
                    this.setState({
                        packageList: [], indivPackageList: [], groupPackageList: [], selectedPackageName: { name: "<Create New>", key: "createnew" }, selectedPackage: [], currentPackageReports: null, packageName: ''
                    })
                    this.getPackageList();
                }
                else {
                    console.log("Package not deleted")
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    // getGenericDates() {
    //     var dates = []

    //     const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    //     axios(`api/ssrsGetAllGenericDates`, {
    //         method: 'GET',
    //         params: { database: this.props.user.company },
    //         headers: headers,
    //     })
    //         .then(response => {
    //             response.data.data.forEach(dateInResponse => {
    //                 dates.push({ label: dateInResponse.label, value: dateInResponse.value })
    //             })
    //             this.setState({ genericDates: dates }, () => console.log(this.state.genericDates))
    //         })
    //         .catch(err => {
    //             console.error(err);
    //             //paramString += splitKey[1] + "," + response.data.data
    //         })

    //     // const genericDates = [
    //     //     { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' }, { value: 'bblq', label: 'bblq - Beginning Business Day Last Quarter' },
    //     //     { value: 'bbly', label: 'bbly - Beginning Business Day Last Year' }, { value: 'bbpm', label: 'bbpm - Beginning Business Day Previous Month' },
    //     //     { value: 'bbpq', label: 'bbpq - Beginning Business Day Previous Quarter' }, { value: 'bbpy', label: 'bbpy - Beginning Business Day Previous Year' },
    //     //     { value: 'bbnm', label: 'bbnm - Beginning Business Day Next Month' }, { value: 'bbnq', label: 'bbnq - Beginning Business Day Next Quarter' },
    //     //     { value: 'bbny', label: 'bbny - Beginning Business Day Next Year' }, { value: 'bdlm', label: 'bdlm - Beginning Day Last Month' },
    //     //     { value: 'bdlq', label: 'bdlq - Beginning Day Last Quarter' }, { value: 'bdly', label: 'bdly - Beginning Day Last Year' },
    //     //     { value: 'bdpm', label: 'bdpm - Beginning Day Previous Month' }, { value: 'bdpq', label: 'bdpq - Beginning Day Previous Quarter' },
    //     //     { value: 'bdpy', label: 'bdpy - Beginning Day Previous Year' }, { value: 'bdnm', label: 'bdnm - Beginning Day Next Month' },
    //     //     { value: 'bdnq', label: 'bdnq - Beginning Day Next Quarter' }, { value: 'bdny', label: 'bdny - Beginning Day Next Year' },
    //     //     { value: 'eblm', label: 'eblm - Ending Business Day Last Month' }, { value: 'eblq', label: 'eblq - Ending Business Day Last Quarter' },
    //     //     { value: 'ebly', label: 'ebly - Ending Business Day Last Year' }, { value: 'ebpm', label: 'ebpm - Ending Business Day Previous Month' },
    //     //     { value: 'ebpq', label: 'ebpq - Ending Business Day Last Quarter' }, { value: 'ebpy', label: 'ebpy - Ending Business Day Last Year' },
    //     //     { value: 'ebnm', label: 'ebnm - Ending Business Day Next Month' }, { value: 'ebnq', label: 'ebnq - Ending Business Day Next Quarter' },
    //     //     { value: 'ebny', label: 'ebny - Ending Business Day Next Year' }, { value: 'edlm', label: 'edlm - Ending Day Last Month' },
    //     //     { value: 'edlq', label: 'edlq - Ending Day Last Quarter' }, { value: 'edly', label: 'edly - Ending Day Last Year' },
    //     //     { value: 'edpm', label: 'edpm - Ending Day Previous Month' }, { value: 'edpq', label: 'edpq - Ending Day Previous Quarter' },
    //     //     { value: 'edpy', label: 'edpy - Ending Day Previous Year' }, { value: 'ednm', label: 'ednm - Ending Day Next Month' },
    //     //     { value: 'ednq', label: 'ednq - Ending Day Next Quarter' }, { value: 'edny', label: 'edny - Ending Day Next Year' }, { value: 'yest', label: 'yest - Yesterday' },
    //     //     { value: 'last', label: 'last - Last Business Day' }, { value: 'prev', label: 'prev - Next To Last Business Day' }
    //     // ]

    //     // this.setState({
    //     //     genericDates_singleDate_Data: genericDates,
    //     //     genericDates_fromDate_Data: genericDates,
    //     //     genericDates_toDate_Data: genericDates,
    //     //     genericDates_singleDate_DataOriginal: genericDates,
    //     //     genericDates_fromDate_DataOriginal: genericDates,
    //     //     genericDates_toDate_DataOriginal: genericDates,
    //     // })

    // }

    getDate(sqlDate, isUTC) {
        var d;
        if (parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")) {
            d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")
        }
        else {
            d = parseDate(sqlDate, "MM/dd/yyyy HH:mm:ss")
        }

        if (isUTC) {
            var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
            return utc;
        }

        else
            return d;
    }

    processFileNameDateLabels(filename) {
        this.state.genericDates.forEach(genericDate => {
            if (filename.includes("{" + genericDate.label + "}")) {
                filename = filename.replace("{" + genericDate.label + "}", "{" + format(this.getDate(genericDate.value, true), "MMddyy") + "}")
            }
        })

        return filename
    }

    sendTemplatePackageRequest = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var packageNames = '';
        var packageType = (this.state.tabSelected === 0 ? 'individual' : 'group')
        var today = new Date();
        var date = '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getFullYear();
        var time = '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        var packageRunId = '' + date + '' + time;


        today = new Date();
        date = '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getFullYear();
        time = '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        var currentDateTime = '' + date + '' + time;
        var id = '' + this.props.user.company + "_" + this.props.user.user + "_" + currentDateTime

        this.setState({ packageRequestLoading: true, packageRunId: id, runPackageText: "Loading..." })

        //var packageRunList = filterBy(this.state.packageRunList, this.state.gridFilter)
        var packageRunList = filterBy(this.state.packageRunList, this.state.dataState.filter) ;


        var requestTime = '' + (today.getUTCMonth() + 1) + '' + today.getUTCDate() + '' + today.getUTCFullYear() + '' + today.getUTCHours() + '' + today.getUTCMinutes() + '' + today.getUTCSeconds();

        var packageList = []
        if (Array.isArray(packageRunList)) {
            var addComma = false;
            packageRunList.forEach(p => {
                packageList.push(p)
            })
            
            console.log(packageList)
            axios(`api/ssrsGetTemplatePackageCommands`, {
                method: 'GET',
                params: { packageList: packageList, packageType: packageType, company: this.props.user.company },
                headers: headers,
            })
                .then(response => {
                    var splitParamString
                    var paramValueLabelPair
                    var tempParamString
                    var packageCommandList = []
                    response.data.data.forEach(row => {
                        if (row.fileName) {
                            row.fileName = this.processFileNameDateLabels(row.fileName)
                        }

                        tempParamString = ''
                        splitParamString = row.paramString.split('|')
                        splitParamString.forEach(param => {
                            paramValueLabelPair = param.split('`')
                            if ((paramValueLabelPair[0] === 'fromDate' || paramValueLabelPair[0] === 'toDate' || paramValueLabelPair[0] === 'singleDate') && paramValueLabelPair[1].length === 4) {
                                this.state.genericDates.forEach(genericDate => {
                                    if (genericDate.label === paramValueLabelPair[1]) {
                                        tempParamString += paramValueLabelPair[0] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy") + '|'
                                    }
                                })
                            }
                            else {
                                tempParamString += param + '|'
                            }
                        })

                        row.paramString = tempParamString.slice(0, -1)
                        packageCommandList.push(row)

                    })
                    console.log(packageCommandList)
                    axios
                        .post("api/ssrsSendPackageCommand", { id: id, data: packageCommandList, company: this.props.user.company, packageRunId: packageRunId, userName: this.props.user.name, requestTime: requestTime }, { headers })
                        .then((response) => {
                            if (response.data.code === 200) {
                                console.log("command sent")
                                //this.setState({openProgressModal: true});
                                this.openProgress();
                                this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
                            }
                            else {
                                console.log("command not sent")
                                this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                            this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
                        });
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
                    return false;
                })
        }
    }


    sendPackageRequest = (overrides) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var packageNames = '';
        var packageType = (this.state.tabSelected === 0 ? 'individual' : 'group')

        var packageType = ''
        if (this.state.tabSelected === 0){
            packageType = 'indiv'
        }
        else if (this.state.tabSelected === 1){
            packageType = 'group'
            if (overrides && overrides.entity){
                overrides.entity = overrides.entity.replace('+','').replace('@','')
            }
        }
        else if (this.state.tabSelected === 2 || this.state.tabSelected === 3){
            packageType = 'template'
        }
        var today = new Date();
        var date = '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getFullYear();
        var time = '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        var packageRunId = '' + date + '' + time;


        today = new Date();
        date = '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getFullYear();
        time = '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        var currentDateTime = '' + date + '' + time;
        var id = '' + this.props.user.company + "_" + this.props.user.user + "_" + currentDateTime

        this.setState({ packageRequestLoading: true, packageRunId: id, runPackageText: "Loading..." })

        //var packageRunList = filterBy(this.state.packageRunList, this.state.gridFilter)
        var packageRunList = filterBy(this.state.packageRunList, this.state.dataState.filter) ;

        var requestTime = '' + (today.getUTCMonth() + 1) + '' + today.getUTCDate() + '' + today.getUTCFullYear() + '' + today.getUTCHours() + '' + today.getUTCMinutes() + '' + today.getUTCSeconds();

        if (1===1){
            packageNames = [];
                var addComma = false;
                for (let i = 0; i < packageRunList.length; i++) {
                    packageNames.push(packageRunList[i].Key);
                }
                axios
                            .post("api/ssrsSendPackage", { id: id, data: packageRunList, type: packageType, company: this.props.user.company, packageRunId: packageRunId, userName: this.props.user.name, requestTime: id, overrides: overrides }, { headers })
                            .then((response) => {
                                if (response.data.code === 200) {
                                    console.log("command sent")
                                    //this.setState({openProgressModal: true});
                                    this.openProgress();
                                    this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
                                }
                                else {
                                    console.log("command not sent")
                                    this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
                                }
                            })
                            .catch((e) => {
                                console.error(e);
                                this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
                            });
        }

        // if (Array.isArray(packageRunList) && packageRunList.length > 1) {
        //     var addComma = false;
        //     for (let i = 0; i < packageRunList.length; i++) {
        //         if (addComma) {
        //             packageNames = packageNames.concat('|');
        //         }
        //         packageNames = packageNames.concat(packageRunList[i].Key);
        //         addComma = true;
        //     }
        //     axios(`api/ssrsGetPackageCommands`, {
        //         method: 'GET',
        //         params: { packageName: packageNames, packageType: packageType, company: this.props.user.company },
        //         headers: headers,
        //     })
        //         .then(response => {
        //             var splitParamString
        //             var paramValueLabelPair
        //             var tempParamString
        //             var packageCommandList = []
        //             response.data.data.forEach(row => {
        //                 if (row.fileName) {
        //                     row.fileName = this.processFileNameDateLabels(row.fileName)
        //                 }

        //                 tempParamString = ''
        //                 splitParamString = row.paramString.split('|')
        //                 splitParamString.forEach(param => {
        //                     paramValueLabelPair = param.split('`')
        //                     if ((paramValueLabelPair[0] === 'fromDate' || paramValueLabelPair[0] === 'toDate' || paramValueLabelPair[0] === 'singleDate') && paramValueLabelPair[1].length === 4) {
        //                         this.state.genericDates.forEach(genericDate => {
        //                             if (genericDate.label === paramValueLabelPair[1]) {
        //                                 tempParamString += paramValueLabelPair[0] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy") + '|'
        //                             }
        //                         })
        //                     }
        //                     else {
        //                         tempParamString += param + '|'
        //                     }
        //                 })

        //                 row.paramString = tempParamString.slice(0, -1)
        //                 packageCommandList.push(row)

        //             })
        //             console.log(packageCommandList)
        //             axios
        //                 .post("api/ssrsSendPackageCommand", { id: id, data: packageCommandList, company: this.props.user.company, packageRunId: packageRunId, userName: this.props.user.name, requestTime: requestTime }, { headers })
        //                 .then((response) => {
        //                     if (response.data.code === 200) {
        //                         console.log("command sent")
        //                         //this.setState({openProgressModal: true});
        //                         this.openProgress();
        //                         this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //                     }
        //                     else {
        //                         console.log("command not sent")
        //                         this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //                     }
        //                 })
        //                 .catch((e) => {
        //                     console.error(e);
        //                     this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //                 });
        //         })
        //         .catch(err => {
        //             console.error(err);
        //             this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //             return false;
        //         })
        // }
        // else if (Array.isArray(packageRunList)) {
        //     axios(`api/ssrsGetPackageCommands`, {
        //         method: 'GET',
        //         params: { packageName: packageRunList[0].Key, packageType: packageType, company: this.props.user.company },
        //         headers: headers,
        //     })
        //         .then(response => {
        //             var splitParamString
        //             var paramValueLabelPair
        //             var tempParamString
        //             var packageCommandList = []

        //             response.data.data.forEach(row => {

        //                 if (overrides) {//reports and pdfs
        //                     // if (packageType === 'group'){
        //                     //     row.account = overrides.entity || row.account
        //                     // }
        //                     row.account = overrides.entity || row.account
        //                     row.saveAsSinglePdf = overrides.singleFile
        //                     row.fileName = overrides.filename || row.fileName
        //                 }

        //                 if (overrides && row.contentType.toString().toLowerCase().includes('report')) {//just reports

        //                     var overrideParamString = ''

        //                     row.paramString.split('|').forEach(param => {
        //                         var splitParam = param.split('`')
        //                         if (overrides.entity && splitParam[0] == 'PortfolioList' && packageType === 'individual') {
        //                             var consolidatedPrefix = ""
        //                             if (splitParam[1].includes("+") && overrides.entity.includes("@"))
        //                                 consolidatedPrefix = "+"
        //                             overrideParamString = overrideParamString.concat('PortfolioList`' + consolidatedPrefix + overrides.entity + '|')
        //                         }
        //                         else if (overrides.fromDate && splitParam[0] == 'fromDate') {
        //                             overrideParamString = overrideParamString.concat('fromDate`' + overrides.fromDate + '|')
        //                         }
        //                         else if (overrides.toDate && splitParam[0] == 'toDate') {
        //                             overrideParamString = overrideParamString.concat('toDate`' + overrides.toDate + '|')
        //                         }
        //                         else if (overrides.toDate && splitParam[0] == 'singleDate') {
        //                             overrideParamString = overrideParamString.concat('singleDate`' + overrides.toDate + '|')
        //                         }
        //                         else {
        //                             overrideParamString = overrideParamString.concat(splitParam[0] + '`' + splitParam[1] + '|')
        //                         }
        //                     })

        //                     overrideParamString = overrideParamString.slice(0, -1);
        //                     row.paramString = overrideParamString
        //                 }

        //                 if (row.fileName) {
        //                     row.fileName = this.processFileNameDateLabels(row.fileName)
        //                 }


        //                 tempParamString = ''
        //                 splitParamString = row.paramString.split('|')
        //                 splitParamString.forEach(param => {
        //                     paramValueLabelPair = param.split('`')
        //                     if ((paramValueLabelPair[0] === 'fromDate' || paramValueLabelPair[0] === 'toDate' || paramValueLabelPair[0] === 'singleDate') && paramValueLabelPair[1].length === 4) {
        //                         this.state.genericDates.forEach(genericDate => {
        //                             if (genericDate.label === paramValueLabelPair[1]) {
        //                                 tempParamString += paramValueLabelPair[0] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy") + '|'
        //                             }
        //                         })
        //                     }
        //                     else {
        //                         tempParamString += param + '|'
        //                     }
        //                 })

        //                 row.paramString = tempParamString.slice(0, -1)
        //                 packageCommandList.push(row)

        //             })
        //             console.log(packageCommandList)
        //             axios
        //                 .post("api/ssrsSendPackageCommand", { data: packageCommandList, id: id, company: this.props.user.company, packageRunId: packageRunId, userName: this.props.user.name, requestTime: requestTime }, { headers })
        //                 .then((response) => {
        //                     if (response.data.code === 200) {
        //                         console.log("command sent")
        //                         this.openProgress();
        //                     }
        //                     else {
        //                         console.log("command not sent")
        //                     }
        //                     this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //                 })
        //                 .catch((e) => {
        //                     console.error(e);
        //                     this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //                 });
        //         })
        //         .catch(err => {
        //             console.error(err);
        //             this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //             return false;
        //         })
        // }
        // else {
        //     axios(`api/ssrsGetPackageCommands`, {
        //         method: 'GET',
        //         params: { packageName: packageRunList.Key, packageType: packageType, company: this.props.user.company },
        //         headers: headers,
        //     })
        //         .then(response => {
        //             var splitParamString
        //             var paramValueLabelPair
        //             var tempParamString
        //             var packageCommandList = []
        //             response.data.data.forEach(row => {
        //                 if (row.fileName) {
        //                     row.fileName = this.processFileNameDateLabels(row.fileName)
        //                 }

        //                 tempParamString = ''
        //                 splitParamString = row.paramString.split('|')
        //                 splitParamString.forEach(param => {
        //                     paramValueLabelPair = param.split('`')
        //                     if ((paramValueLabelPair[0] === 'fromDate' || paramValueLabelPair[0] === 'toDate' || paramValueLabelPair[0] === 'singleDate') && paramValueLabelPair[1].length === 4) {
        //                         this.state.genericDates.forEach(genericDate => {
        //                             if (genericDate.label === paramValueLabelPair[1]) {
        //                                 tempParamString += paramValueLabelPair[0] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy") + '|'
        //                             }
        //                         })
        //                     }
        //                     else {
        //                         tempParamString += param + '|'
        //                     }
        //                 })

        //                 row.paramString = tempParamString.slice(0, -1)
        //                 packageCommandList.push(row)
        //             })
        //             axios
        //                 .post("api/ssrsSendPackageCommand", { id: id, data: packageCommandList, company: this.props.user.company, packageRunId: packageRunId, userName: this.props.user.name, requestTime: requestTime }, { headers })
        //                 .then((response) => {
        //                     if (response.data.code === 200) {
        //                         console.log("command sent")
        //                         //alert("Package request has been sent");
        //                         this.openProgress();
        //                     }
        //                     else {
        //                         console.log("command not sent")
        //                     }
        //                     this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //                 })
        //                 .catch((e) => {
        //                     console.error(e);
        //                     this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //                 });
        //         })
        //         .catch(err => {
        //             console.error(err);
        //             this.setState({ packageRequestLoading: false, runPackageText: "Run Selected Package(s)" })
        //             return false;
        //         })
        // }

    }

    itemRender = (li, itemProps) => {
        const itemChildren = <span>{itemProps.dataItem.label}</span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    parseParamString(paramStringIn) {
        var splitParam = ''
        var returnList = []
        var paramName = ''
        var value = ''
        //const data = allData.slice();
        paramStringIn.split('|').forEach(i => {
            splitParam = i.split('`')

            paramName = splitParam[0]
            value = splitParam[1]

            if (paramName === 'PortfolioList' || paramName === 'PortfolioTest' || paramName === 'Family' || paramName === 'family') {
                paramName = 'Portfolio'
                if (value.charAt(0) === '@') {
                    value = "'" + value//add formula-blocking character to excel spreadsheet cell
                }
            }

            returnList.push(['', '', paramName, value])

        })

        returnList.forEach(function (item, i) {
            if (item[2] === "Portfolio") {
                returnList.splice(i, 1);
                returnList.unshift(item);
            }
        });
        return returnList;
    }

    exportToExcel = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var packageRunList = filterBy(this.state.packageRunList, this.state.dataState.filter) ;
        //var packageRunList = filterBy(this.state.packageRunList, this.state.gridFilter)

        var exportPackageList = ''
        packageRunList.forEach(packageInList => {
            exportPackageList = exportPackageList.concat("'" + packageInList.Key + "',")
        })

        exportPackageList = exportPackageList.slice(0, -1);


        var excelDataSet = []
        var packageName = ''

        var template = false
        if (this.state.tabSelected === 3){
            template = true
        }

        axios(`api/ssrsGetExcelExportFormat`, {
            method: 'GET',
            params: { exportPackageList: exportPackageList, company: this.props.user.company, template: template },
            headers: headers,
        })
            .then(response => {
                var antiFormulaChar = ''
                var dataIn = []
                response.data.data.forEach(reportIn => {

                    var antiFormulaChar = ''
                    if (reportIn.reportingGroup.charAt(0) === '@') {
                        antiFormulaChar = "'"
                    }

                    if (this.state.tabSelected === 3){
                        if (packageName !== reportIn.templateName) {
                            dataIn.push([reportIn.templateName, reportIn.templateDescription, '', antiFormulaChar + reportIn.reportingGroup, '', 'Template Mode'])
                        }
                    }
                    else{
                        if (packageName !== reportIn.packageName) {
                            dataIn.push([reportIn.packageName, reportIn.packageDescription, reportIn.fileName, antiFormulaChar + reportIn.reportingGroup, reportIn.frequency, reportIn.groupBehavior ? 'Group Mode' : '', reportIn.groupBehavior ? (reportIn.saveAsSinglePdf ? 'Output as Single File On' : 'Output as Single File Off') : ''])
                        }
                    }

                    

                    if (reportIn.reportName.toString().toLowerCase().includes('.pdf')) {
                        dataIn.push(['', reportIn.reportName, '', reportIn.groupBehavior ? reportIn.groupBehavior : '', (reportIn.hierarchy && reportIn.hierarchy !== 'null') ? reportIn.hierarchy : ''])
                    }
                    else {
                        dataIn.push(['', reportIn.reportName, '', '', (reportIn.hierarchy && reportIn.hierarchy !== 'null') ? reportIn.hierarchy : ''])
                    }


                    if (!reportIn.reportName.toString().toLowerCase().includes('.pdf')) {
                        this.parseParamString(reportIn.paramString).forEach(i => {
                            dataIn.push(i)
                        })
                    }


                    
                    if (this.state.tabSelected === 3){
                        packageName = reportIn.templateName
                    }
                    else{
                        packageName = reportIn.packageName
                    }
                })

                excelDataSet.push(
                    {
                        columns: [],
                        data: dataIn
                    })

                this.setState({ excelDataSet: excelDataSet }, () => {
                    this.setState({ downloadExcel: true }, () => {
                        this.setState({ downloadExcel: false })
                    })
                })

            })
            .catch(err => {
                console.error(err);
                return false;
            })


    }

    exportTemplatePackageToExcel = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var packageRunList = filterBy(this.state.packageRunList, this.state.dataState.filter) ;
        //var packageRunList = filterBy(this.state.packageRunList, this.state.gridFilter)

        var exportPackageList = ''
        packageRunList.forEach(packageInList => {
            exportPackageList = exportPackageList.concat("'" + packageInList.Key + "',")
        })

        exportPackageList = exportPackageList.slice(0, -1);


        var excelDataSet = []
        var packageName = ''


        axios(`api/ssrsGetExcelExportFormat`, {
            method: 'GET',
            params: { exportPackageList: exportPackageList, company: this.props.user.company, templatePackage: true },
            headers: headers,
        })
            .then(response => {
                var antiFormulaChar = ''
                var dataIn = []
                response.data.data.forEach(reportIn => {

                    var antiFormulaChar = ''
                    if (reportIn.reportingGroup.charAt(0) === '@') {
                        antiFormulaChar = "'"
                    }


                    if (packageName !== reportIn.packageName) {
                        dataIn.push([reportIn.packageName, reportIn.packageDescription, reportIn.fileName, antiFormulaChar + reportIn.reportingGroup, reportIn.frequency, 'Template Package Mode', reportIn.saveAsSinglePdf ? 'Output as Single File On' : 'Output as Single File Off'])
                    }

                    reportIn.templates.split(',').forEach(template =>{
                        var tempTemplate = template.split('|')
                        dataIn.push(['', '', '', '', '', tempTemplate[0], tempTemplate[1]])
                    })

                })

                excelDataSet.push(
                    {
                        columns: [],
                        data: dataIn
                    })

                this.setState({ excelDataSet: excelDataSet }, () => {
                    this.setState({ downloadExcel: true }, () => {
                        this.setState({ downloadExcel: false })
                    })
                })

            })
            .catch(err => {
                console.error(err);
                return false;
            })


    }

    deletePackageRequest = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var packageNames = '';

        var packageRunList = filterBy(this.state.packageRunList, this.state.dataState.filter) ;
        //var packageRunList = filterBy(this.state.packageRunList, this.state.gridFilter)



        var template = false
        var templatePackage = false
        var messageText = 'Package'
        if (this.state.tabSelected === 3) {
            template = true
            messageText = 'Template'

            var confirmString
            if (Array.isArray(packageRunList) && packageRunList.length > 1) {
                confirmString = 'Delete ' + packageRunList.length + ' templates?'
            }
            else {
                confirmString = 'Delete 1 template?'
            }
        }
        else if (this.state.tabSelected === 2) {
            templatePackage = true
            messageText = 'Template Package'

            var confirmString
            if (Array.isArray(packageRunList) && packageRunList.length > 1) {
                confirmString = 'Delete ' + packageRunList.length + ' template packages?'
            }
            else {
                confirmString = 'Delete 1 template packages?'
            }
        }
        else {
            var confirmString
            if (Array.isArray(packageRunList) && packageRunList.length > 1) {
                confirmString = 'Delete ' + packageRunList.length + ' packages?'
            }
            else {
                confirmString = 'Delete 1 package?'
            }
        }



        if (window.confirm(confirmString)) {
            if (Array.isArray(packageRunList) && packageRunList.length > 1) {
                var addComma = false;
                for (let i = 0; i < packageRunList.length; i++) {
                    if (addComma) {
                        packageNames = packageNames.concat('|');
                    }
                    packageNames = packageNames.concat(packageRunList[i].Key);
                    addComma = true;
                }
                axios
                    .post("api/ssrsDeletePackage", { packageName: packageNames, company: this.props.user.company, template: template, templatePackage: templatePackage }, { headers })
                    .then((response) => {
                        if (response.data.code === 200) {
                            alert(messageText + " has been deleted");
                            this.getPackageList();
                            this.getTemplateList();
                            this.getTemplatePackageList();
                        }
                        else {
                            console.log("command not sent")
                        }
                    })
                    .catch((e) => {
                        console.error(e);

                    });
            }
            else if (Array.isArray(packageRunList)) {
                axios
                    .post("api/ssrsDeletePackage", { packageName: packageRunList[0].Key, company: this.props.user.company, template: template, templatePackage: templatePackage }, { headers })
                    .then((response) => {
                        if (response.data.code === 200) {
                            alert(messageText + " has been deleted");
                            this.getPackageList();
                            this.getTemplateList();
                            this.getTemplatePackageList();
                        }
                        else {
                            console.log("command not sent")
                        }
                    })
                    .catch((e) => {
                        console.error(e);

                    });
            }
            else {
                axios
                    .post("api/ssrsDeletePackage", { packageName: packageRunList.Key, company: this.props.user.company, template: template, templatePackage: templatePackage }, { headers })
                    .then((response) => {
                        if (response.data.code === 200) {
                            alert(messageText + " has been deleted");
                            this.getPackageList();
                            this.getTemplateList();
                            this.getTemplatePackageList();
                        }
                        else {
                            console.log("command not sent")
                        }
                    })
                    .catch((e) => {
                        console.error(e);

                    });
            }
        }



    }

    deleteFromPackage = (reportToDelete) => {
        var selectedPackage = [];


        this.state.selectedPackage.forEach(reportInPackage => {//recreates package without deleted report
            if (reportInPackage.report !== reportToDelete) {
                selectedPackage = selectedPackage.concat(reportInPackage)
            }
        })

        this.setState({ selectedPackage: selectedPackage, selectedPackageReport: { report: '' } })
    }

    addToPackage = () => {
        var packageId = null
        if (this.state.packageId === null) {
            var today = new Date();
            var date = '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getFullYear();
            var time = '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
            var currentDateTime = '' + date + '' + time;
            packageId = this.props.user.company + "_" + currentDateTime
            this.setState({ packageId: packageId })
        }
        else {
            packageId = this.state.packageId
        }

        var singleDateFormatted = null
        var fromDateFormatted = null
        var toDateFormatted = null
        if (this.state.singleDate !== null) {
            singleDateFormatted = format(this.state.singleDate, "MM-dd-yyyy")
        }
        if (this.state.fromDate !== null) {
            fromDateFormatted = format(this.state.fromDate, "MM-dd-yyyy")
        }
        if (this.state.toDate !== null) {
            toDateFormatted = format(this.state.toDate, "MM-dd-yyyy")
        }


        var reportToAdd = {
            singleDate: singleDateFormatted,
            from: fromDateFormatted,
            to: toDateFormatted,
            graph: this.state.graph,
            report: this.state.report.name,
            database: this.props.user.company,
            packageId: packageId,
            packageName: this.state.packageName,
            reportOrder: this.state.reportOrder,
        }

        this.setState({
            selectedPackage: this.state.selectedPackage.concat(reportToAdd), reportOrder: this.state.reportOrder + 1, selectedPackageId: packageId,
        })
        this.onReportChangeHandler({ name: "Select Report", key: null })

    }

    expandChange = (event) => {
        event.dataItem[event.target.props.expandField] = event.value;
        this.forceUpdate();
    }

    selectionChange(event, gPackages) {
        event.dataItem.selected = !event.dataItem.selected;
        var selectedPackages = [];
        for (let i = 0; i < gPackages.length; i++) {
            if (gPackages[i].selected) {
                selectedPackages.push(gPackages[i]);
            }
        }
        //this.setState({ packageRunList: filterBy(selectedPackages, this.state.gridFilter) });
        this.setState({ packageRunList: filterBy(selectedPackages, this.state.dataState.filter) });
        if (selectedPackages.length > 0) {
            this.setState({ packageSelected: true });
            if (selectedPackages.length === 1) {
                this.setState({ editPackage: true });
                if (this.state.tabSelected === 2){
                    this.getTemplatePackageTemplates();
                }
                else if (this.state.tabSelected === 3){
                    this.getTemplateReports();
                }
                else {
                    this.getPackageReports();
                }
            }
            else {
                this.setState({ editPackage: false });
            }
        }
        else {
            this.setState({ packageSelected: false });
            this.setState({ editPackage: false });
        }
        this.forceUpdate();
    }

    unselectAll() {
        this.state.indivPackageList.forEach(item => item.selected = false)
        this.state.groupPackageList.forEach(item => item.selected = false)
        this.state.templatePackageList.forEach(item => item.selected = false)
        this.state.templateList.forEach(item => item.selected = false)
    }

    clearSelection() {
        this.setState({ packageRunList: [], editPackage: false, packageSelected: false })
    }

    headerSelectionChange(event, gPackages) {
        const checked = event.syntheticEvent.target.checked;
        gPackages.forEach(item => item.selected = checked);
        var selectedPackages = [];
        for (let i = 0; i < gPackages.length; i++) {
            if (gPackages[i].selected) {
                selectedPackages.push(gPackages[i]);
            }
        }

        this.setState({ packageRunList: filterBy(selectedPackages, this.state.dataState.filter) });
        if (selectedPackages.length > 0) {
            this.setState({ packageSelected: true });
        }
        else {
            this.setState({ packageSelected: false });
        }
        this.forceUpdate();
    }

    onReportListChange = (e) => {
        this.setState({ editReportList: e });
    }


    openProgress() {
        this.setState({ openProgressModal: true });
        this.setState({
            progressText: "You can close this window and your Package(s) will continue to run.",
            progressErrorString: [],
            progressWarningText: [],
            progress: 1,
        })

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var progressTimer = setInterval(
            function () {
                var progress;
                var errorList = []
                console.log(this.state.packageRunId)

                axios(`api/ssrsGetPackageRunProgress`, {
                    method: 'GET',
                    params: {
                        packageRunId: this.state.packageRunId,
                        company: this.props.user.company,
                    },
                    headers: headers
                })
                    .then(response => {
                        progress = response.data.data[0].progress
                        if (response.data.data[0].errorString) {
                            var details = response.data.data[0].errorString.split('|')
                            var progressText = details[0]

                            if (progressText.includes('was skipped due to an empty group')) {
                                var progressWarningText = this.state.progressWarningText
                                progressWarningText.push(progressText)
                                if (Number.isInteger(progress) && progress >= 100) {
                                    console.log(progress)
                                    this.setState({ progress: progress })
                                    if (progress >= 100) {
                                        clearInterval(progressTimer)//end timer loop
                                        this.clearPackageRun()
                                        this.setState({ progressText: "Package(s) complete. You can find them in the Review Packages section.", progressWarningText: progressWarningText})
                                    }
                                }
                                else{
                                    this.setState({progressWarningText: progressWarningText})
                                }
                            }
                            else {
                                clearInterval(progressTimer)//end timer loop
                                this.clearPackageRun()
                                //this.setState({ progressErrorString: errorList })
                                this.setState({progressBarColor: "rgb(160, 160, 160)", progressTextColor: "rgb(230, 0, 0)", progressText: progressText, progressPackage: details[1], progressReport: details[2]})
                            }
                            
                            

                        }
                        else{
                            if (Number.isInteger(progress)) {
                                console.log(progress)
                                this.setState({ progress: progress })
                                if (progress >= 100) {
                                    clearInterval(progressTimer)//end timer loop
                                    this.clearPackageRun()
                                    this.setState({ progressText: "Package(s) complete. You can find them in the Review Packages section." })
                                }
                            }
                        }
                        

                    })
                    .catch(err => {
                        console.error(err);
                    })


            }
                .bind(this),
            2000
        )

        this.setState({ progressTimerId: progressTimer })

    }

    closeProgress() {
        this.setState({
            openProgressModal: false,
            progress: 1,
            progressText: "You can close this window and your Package(s) will continue to run.",
            progressPackage: '',
            progressReport: '',
            progressBarColor: "rgb(11, 211, 24)",
            progressTextColor: "rgb(0, 0, 0)",
            progressErrorString: [],
            progressWarningText: []
        });
        clearInterval(this.state.progressTimerId)//end timer loop
        this.clearPackageRun()
    }

    closeRunWithInput() {
        this.setState({
            openRunWithInput: false,

            accountRunWithInput: null,
            groupRunWithInput: null,
            singleFileRunWithInput: false,
            filenameRunWithInput: null,
            fromDateGenericDateToggle: false,
            fromDateRunWithInputEnable: false,
            fromDateRunWithInput: null,
            fromDateRunWithInputGeneric: null,
            toDateGenericDateToggle: false,
            toDateRunWithInputEnable: false,
            toDateRunWithInput: null,
            toDateRunWithInputGeneric: null,
            // genericDates_fromDate_Data: null,
            // genericDates_toDate_Data: null,
            // genericDates_fromDate_DataOriginal: null,
            // genericDates_toDate_DataOriginal: null,
        });
    }

    clearPackageRun() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload = {
            packageRunId: this.state.packageRunId,
            company: this.props.user.company
        }

        axios
            .post("api/ssrsClearPackageRun", payload, { headers })
            .then((response) => {
                if (response.data.code === 200) {
                    console.log("Package run cleared")
                }
                else {
                    console.log("Package run not cleared")
                }
            })
            .catch((e) => {
                console.error(e);
            });

    }

    determineFileHandler = (event) => {
        let fileObj = event.target.files[0];
        var isTemplatePackage = false;
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                resp.rows.forEach(row => {
                    if (row[5] && row[5].toString().toLowerCase() === 'template package mode') {
                        isTemplatePackage = true
                    }
                })

                if (isTemplatePackage){
                    this.fileHandlerTemplatePackage(resp);
                }
                else {
                    this.fileHandler(resp);
                }
            }
        })
    }

    fileHandler = (resp) => {

        var newPackage = [];
        var newPackage2 = {};
        var detailRow = {};
        var label = ""
        var paramString = ""
        var detail = []
        var firstPackagePass = true
        var firstPass = true
        var dateString = " - "
        var reportOrder = 1
        var payload = {};
        var packagePayload = []
        var reportingGroup = {};

        this.setState({
            cols: resp.cols,
            rows: resp.rows
        }, () => {

            var reportIsAdvanced = false
            var isGroup = false
            var isTemplate = false;
            var skip = false
            var reportingGroupForFinalPDF = ''

            this.state.rows.forEach(row => {
                if (row.length === 0){
                    return
                }

                if (row[0] || row[1] || row[2] || row[3] || row[4] || row[5]) {
                    reportingGroupForFinalPDF = ''
                }

                if (row[5] && row[5].toString().toLowerCase() === 'group mode') {
                    isGroup = true
                }
                if (row[5] && row[5].toString().toLowerCase() === 'template mode') {
                    isTemplate = true
                }


                if (row[0] !== "" && row[0]) {
                    if (firstPackagePass === false) {
                        if (detailRow.reportName.toString().toLowerCase().includes('.pdf')) {
                            if (detailRow.reportingGroup) {
                                detailRow.contentType = "Advanced Group PDF"
                            }
                            else {
                                detailRow.contentType = "PDF"
                            }
                            //detailRow.contentType = "PDF"
                            //detailRow.orientation = 
                            detailRow.packageId = null
                            detailRow.packageName = newPackage[0].packageName
                            detailRow.paramString = 'null'
                            detailRow.reportDate = ''
                            detailRow.reportOrder = reportOrder
                            detailRow.reportingGroup = detailRow.reportingGroup || ''//groupb
                            detailRow.orientation = ''
                            //detailRow.hierarchy = ''
                        }
                        else {
                            if (isGroup) {
                                detailRow.contentType = "Advanced Group Report"
                            }
                            else {
                                detailRow.contentType = "SSRS Report"
                            }
                            //detailRow.contentType = "SSRS Report"
                            //detailRow.orientation = 
                            detailRow.packageId = null
                            detailRow.packageName = newPackage[0].packageName
                            detailRow.paramString = paramString.substring(0, paramString.length - 1)
                            detailRow.reportDate = dateString
                            detailRow.reportOrder = reportOrder
                            detailRow.reportingGroup = reportingGroup//groupb
                            if (!detailRow.orientation) {
                                detailRow.orientation = "Landscape"
                            }
                            //detailRow.hierarchy = ''
                        }


                        reportOrder += 1

                        detail = detail.concat(detailRow)

                        packagePayload = packagePayload.concat({ newPackage: newPackage[0], detail: detail, company: this.props.user.company, originalName: newPackage[0].packageName })

                        newPackage = [];
                        detailRow = {};
                        label = ""
                        paramString = ""
                        detail = []
                        dateString = " - "
                        reportOrder = 1
                        firstPass = true
                        isGroup = false
                        reportIsAdvanced = false
                        reportingGroup = '';

                        if (row[5] && row[5].toString().toLowerCase() === 'group mode') {
                            isGroup = true
                        }
                        if (row[5] && row[5].toString().toLowerCase() === 'template mode') {
                            isTemplate = true
                        }

                    }
                    else {
                        firstPackagePass = false
                    }

                    newPackage = newPackage.concat({
                        fileName: row[2],
                        frequency: row[4],
                        packageDescription: row[1],
                        packageName: row[0],
                        reportingGroup: row[3],
                        reportingGroupCode: row[3],
                        saveAsSinglePdf: ((row[5] && !isTemplate) ? (row[6].toString().toLowerCase() === 'output as single file on' ? true : false) : (row[2] ? true : false)),
                    })

                    //newPackage2 = newPackage2.concat(newpackage)

                    // newPackage.fileName = ""
                    // newPackage.frequency = row[4]
                    // newPackage.packageDescription = row[1]
                    // newPackage.packageName = row[0]
                    // newPackage.reportingGroup = row[3]
                    // newPackage.reportingGroupCode = row[3]
                    // newPackage.saveAsSinglePdf = false
                }

                if ((row[0] === "" || !row[0]) && (row[1] !== "" && row[1])) {//hierarchy here
                    if (firstPass === false) {
                        if (detailRow.reportName.toString().toLowerCase().includes('.pdf')) {
                            //if (detailRow.reportingGroup) {
                            if (isGroup) {
                                detailRow.contentType = "Advanced Group PDF"
                            }
                            else {
                                detailRow.contentType = "PDF"
                            }
                            //detailRow.contentType = row[3] ? "Advanced Group PDF" : "PDF"
                            //detailRow.orientation = 
                            detailRow.packageId = null
                            detailRow.packageName = newPackage[0].packageName
                            detailRow.paramString = 'null'
                            detailRow.reportDate = ''
                            detailRow.reportOrder = reportOrder
                            //detailRow.reportingGroup = row[3] || ''//groupb
                            detailRow.orientation = ''
                        }
                        else {
                            if (isGroup) {
                                detailRow.contentType = "Advanced Group Report"
                            }
                            else {
                                detailRow.contentType = "SSRS Report"
                            }

                            //detailRow.orientation = 
                            detailRow.packageId = null
                            detailRow.packageName = newPackage[0].packageName
                            detailRow.paramString = paramString.substring(0, paramString.length - 1)
                            detailRow.reportDate = dateString
                            detailRow.reportOrder = reportOrder
                            // detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? 'Multi Level' : reportingGroup
                            detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? reportingGroup : reportingGroup
                            //detailRow.reportingGroup = reportingGroup//groupb

                            if (!detailRow.orientation) {
                                detailRow.orientation = "Landscape"
                            }
                        }


                        reportOrder += 1

                        detail = detail.concat(detailRow)
                        paramString = ""
                        //isGroup = false
                        detailRow = {}
                        dateString = " - "

                    }
                    detailRow.reportName = row[1]
                    detailRow.hierarchy = row[4]
                    //detailRow.reportingGroup = row[5]
                    firstPass = false;
                    // if (detailRow.hierarchy) {
                    //     if (detailRow.hierarchy.toString().toLowerCase() === 'top level' || detailRow.hierarchy.toString().toLowerCase() === 'multi level' || detailRow.hierarchy.toString().toLowerCase() === 'bottom level') {
                    //         reportIsAdvanced = true
                    //         newPackage[0].advancedMode = true
                    //     }
                    // }
                    if (!isGroup) {
                        reportIsAdvanced = true
                        newPackage[0].advancedMode = true
                    }

                }

                if ((row[0] === "" || !row[0]) && (row[1] === "" || !row[1])) {
                    skip = false
                    label = row[2]

                    if (row[2] === "Portfolio") {
                        label = "PortfolioList"
                        reportingGroup = row[3]
                    }

                    if (row[2] === "GroupBehavior") {
                        skip = true
                        isGroup = true
                        reportingGroup = row[3] || ""
                        if (row[3] && row[3].toString().toLowerCase() === "run as is") {
                            reportingGroup = "Run as is"
                        }
                        else if (row[3] && row[3].toString().toLowerCase() === "run consolidated") {
                            reportingGroup = "Run Consolidated"
                        }
                        else if (row[3] && row[3].toString().toLowerCase() === "run unconsolidated") {
                            reportingGroup = "Run Unconsolidated"
                        }
                    }



                    if (row[2] === "Family") {
                        reportingGroup = row[3]
                    }

                    if (row[2] === "singleDate") {
                        dateString = row[3].replace(/-/g, "/").replace(/ 00:00:00/g, "").toString().toLowerCase()
                        row[3] = row[3].toString().toLowerCase()
                    }

                    if (row[2] === "fromDate") {
                        dateString = row[3].replace(/-/g, "/").replace(/ 00:00:00/g, "").toString().toLowerCase() + dateString
                        row[3] = row[3].toString().toLowerCase()
                    }

                    if (row[2] === "toDate") {
                        dateString = dateString + row[3].replace(/-/g, "/").replace(/ 00:00:00/g, "").toString().toLowerCase()
                        row[3] = row[3].toString().toLowerCase()
                    }



                    if (row[2] === "Layout") {
                        if (row[3] && row[3].toString().toLowerCase() === "l") {
                            detailRow.orientation = "Landscape"
                        }
                        else {
                            detailRow.orientation = "Portrait"
                        }
                    }
                    if (row[2] === "Disclaimer" && !row[3]) {//"No Disclaimer" needs to be a space character, not blank. Just how we did it early on.
                        row[3] = " "
                    }


                    if (!skip) {
                        if (row[3] && row[3].toString().toLowerCase() === "true") {
                            row[3] = "True"
                        }
                        if (row[3] && row[3].toString().toLowerCase() === "false") {
                            row[3] = "False"
                        }
                        paramString += (label + "`" + ((row[3] || row[3] === 0) ? row[3] : '') + "|")
                    }

                }

                //newPackage = newPackage
                if (row[1] && row[1].toString().toLowerCase().includes('.pdf') && row[3]) {
                    // if (row[3] && row[3].toString().toLowerCase() === "print once") {
                    //     detailRow.reportingGroup = "Print once"
                    //     reportingGroupForFinalPDF = "Print once"
                    // }
                    // else if (row[3] && row[3].toString().toLowerCase() === "print for each household") {
                    //     detailRow.reportingGroup = "Print for each household"
                    //     reportingGroupForFinalPDF = "Print for each household"
                    // }
                    // else {
                    //     detailRow.reportingGroup = row[3]
                    //     reportingGroupForFinalPDF = row[3]
                    // }

                    detailRow.reportingGroup = row[3]
                    reportingGroupForFinalPDF = row[3]


                }
            })
            if (detailRow.reportName.toString().toLowerCase().includes('.pdf')) {
                detailRow.contentType = isGroup ? "Advanced Group PDF" : "PDF"
                //detailRow.orientation = 
                detailRow.packageId = null
                detailRow.packageName = newPackage[0].packageName
                detailRow.paramString = 'null'
                detailRow.reportDate = ''
                detailRow.reportOrder = reportOrder
                detailRow.reportingGroup = reportingGroupForFinalPDF || ''
                detailRow.orientation = ''
            }
            else {
                if (isGroup) {
                    detailRow.contentType = "Advanced Group Report"
                }
                else {
                    detailRow.contentType = "SSRS Report"
                }
                //detailRow.orientation = 
                detailRow.packageId = null
                detailRow.packageName = newPackage[0].packageName
                detailRow.paramString = paramString.substring(0, paramString.length - 1)
                detailRow.reportDate = dateString
                detailRow.reportOrder = reportOrder
                // detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? 'Multi Level' : reportingGroup
                detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? reportingGroup : reportingGroup
                if (!detailRow.orientation) {
                    detailRow.orientation = "Landscape"
                }
            }


            reportOrder += 1

            detail = detail.concat(detailRow)



            if (isTemplate){
                packagePayload = packagePayload.concat({ newPackage: newPackage[0], detail: detail, company: this.props.user.company, originalName: newPackage[0].packageName, template: true })
            }
            else{
                packagePayload = packagePayload.concat({ newPackage: newPackage[0], detail: detail, company: this.props.user.company, originalName: newPackage[0].packageName })
            }                    

            //payload = { newPackage: newPackage[0], detail: detail, company: this.props.user.company, originalName: newPackage[0].packageName }
            payload = {packages: packagePayload, company: this.props.user.company}


            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            axios
                .post("api/ssrsSavePackage", payload, { headers })
                .then((response) => {
                    if (response.data.code === 200) {
                        alert("Import successful")
                        //callback(true)
                        this.getPackageList()
                        this.getTemplateList()
                        this.getTemplatePackageList();
                    }
                    else {
                        alert('An error has occured while importing.');
                        //callback(false)
                        this.getPackageList()
                        this.getTemplateList()
                        this.getTemplatePackageList();
                    }
                })
                .catch((e) => {
                    console.error(e);
                    alert('An error has occured while importing.');
                    //callback(false)
                    this.getPackageList()
                    this.getTemplateList()
                    this.getTemplatePackageList();
                });

        });

    }

    fileHandlerTemplatePackage = (resp) => {

        var newPackage = [];
        var newPackage2 = {};
        var detailRow = {};
        var label = ""
        var paramString = ""
        var detail = []
        var firstPackagePass = true
        var firstPass = true
        var dateString = " - "
        var reportOrder = 1
        var payload = {};
        var packagePayload = []
        var reportingGroup = {};

        this.setState({
            cols: resp.cols,
            rows: resp.rows
        }, () => {

            var reportIsAdvanced = false
            var isGroup = false
            var isTemplatePackage = false
            var skip = false
            var reportingGroupForFinalPDF = ''

            this.state.rows.forEach(row => {
                if (row.length === 0){
                    return
                }
                if (row[0] || row[1] || row[2] || row[3] || row[4] || row[5]) {
                    reportingGroupForFinalPDF = ''
                }

                if (row[5] && row[5].toString().toLowerCase() === 'group mode') {
                    isGroup = true
                }
                if (row[5] && row[5].toString().toLowerCase() === 'template package mode') {
                    isTemplatePackage = true
                }


                if (row[0] !== "" && row[0]) {
                    if (firstPackagePass === false) {
                        
                        if (1===1) {
                            //detailRow.contentType = "SSRS Report"
                            //detailRow.orientation = 
                            detailRow.packageId = null
                            detailRow.packageName = newPackage[0].packageName
                            detailRow.paramString = paramString.substring(0, paramString.length - 1)
                            detailRow.reportDate = dateString
                            detailRow.reportOrder = reportOrder
                            detailRow.reportingGroup = reportingGroup//groupb
                            if (!detailRow.orientation) {
                                detailRow.orientation = "Landscape"
                            }
                            //detailRow.hierarchy = ''
                        }


                        reportOrder += 1

                        detail = detail.concat(detailRow)

                        var templateString = ''
                        detail.forEach(d => {
                            templateString = templateString.concat(d.template + '|' + d.templateGroup + ',')
                        })
                    
                        templateString = templateString.slice(0, -1);//trims last character which is a comma

                        packagePayload = packagePayload.concat({ newPackage: newPackage[0], detail: detail, company: this.props.user.company, originalName: newPackage[0].packageName, templatePackage: true })

                        newPackage = [];
                        detailRow = {};
                        label = ""
                        paramString = ""
                        templateString = ''
                        detail = []
                        dateString = " - "
                        reportOrder = 1
                        firstPass = true
                        isGroup = false
                        reportIsAdvanced = false
                        reportingGroup = '';

                        if (row[5] && row[5].toString().toLowerCase() === 'group mode') {
                            isGroup = true
                        }
                        if (row[5] && row[5].toString().toLowerCase() === 'template package mode') {
                            isTemplatePackage = true
                        }

                    }
                    else {
                        firstPackagePass = false
                    }

                    newPackage = newPackage.concat({
                        fileName: row[2],
                        frequency: row[4],
                        packageDescription: row[1],
                        packageName: row[0],
                        reportingGroup: row[3],
                        reportingGroupCode: row[3],
                        saveAsSinglePdf: (row[5] ? (row[6].toString().toLowerCase() === 'output as single file on' ? true : false) : (row[2] ? true : false)),
                    })

                    //newPackage2 = newPackage2.concat(newpackage)

                    // newPackage.fileName = ""
                    // newPackage.frequency = row[4]
                    // newPackage.packageDescription = row[1]
                    // newPackage.packageName = row[0]
                    // newPackage.reportingGroup = row[3]
                    // newPackage.reportingGroupCode = row[3]
                    // newPackage.saveAsSinglePdf = false
                }

                if ((row[0] === "" || !row[0]) && (row[5] !== "" && row[5])) {//hierarchy here
                    if (firstPass === false) {
                        if (1===1) {
                            if (isGroup) {
                                detailRow.contentType = "Advanced Group Report"
                            }
                            else {
                                detailRow.contentType = "SSRS Report"
                            }

                            //detailRow.orientation = 
                            detailRow.packageId = null
                            detailRow.packageName = newPackage[0].packageName
                            detailRow.paramString = paramString.substring(0, paramString.length - 1)
                            detailRow.reportDate = dateString
                            detailRow.reportOrder = reportOrder
                            // detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? 'Multi Level' : reportingGroup
                            detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? reportingGroup : reportingGroup
                            //detailRow.reportingGroup = reportingGroup//groupb

                            if (!detailRow.orientation) {
                                detailRow.orientation = "Landscape"
                            }
                        }


                        reportOrder += 1

                        detail = detail.concat(detailRow)
                        paramString = ""
                        //isGroup = false
                        detailRow = {}
                        dateString = " - "

                    }
                    detailRow.template = row[5]
                    detailRow.templateGroup = row[6]
                    //detailRow.reportingGroup = row[5]
                    firstPass = false;
                    // if (detailRow.hierarchy) {
                    //     if (detailRow.hierarchy.toString().toLowerCase() === 'top level' || detailRow.hierarchy.toString().toLowerCase() === 'multi level' || detailRow.hierarchy.toString().toLowerCase() === 'bottom level') {
                    //         reportIsAdvanced = true
                    //         newPackage[0].advancedMode = true
                    //     }
                    // }
                    if (!isGroup) {
                        reportIsAdvanced = true
                        newPackage[0].advancedMode = true
                    }

                }


                
            })
            
            if (1===1) {
                if (isGroup) {
                    detailRow.contentType = "Advanced Group Report"
                }
                else {
                    detailRow.contentType = "SSRS Report"
                }
                //detailRow.orientation = 
                detailRow.packageId = null
                detailRow.packageName = newPackage[0].packageName
                detailRow.paramString = paramString.substring(0, paramString.length - 1)
                detailRow.reportDate = dateString
                detailRow.reportOrder = reportOrder
                // detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? 'Multi Level' : reportingGroup
                detailRow.reportingGroup = (detailRow.hierarchy === 'Multi Level' && isGroup) ? reportingGroup : reportingGroup
                if (!detailRow.orientation) {
                    detailRow.orientation = "Landscape"
                }
            }


            reportOrder += 1

            detail = detail.concat(detailRow)

            var templateString = ''
            detail.forEach(d => {
                templateString = templateString.concat(d.template + '|' + d.templateGroup + ',')
            })

            templateString = templateString.slice(0, -1);//trims last character which is a comma


            packagePayload = packagePayload.concat({ newPackage: newPackage[0], detail: detail, company: this.props.user.company, originalName: newPackage[0].packageName, templatePackage: true })
       

            //payload = { newPackage: newPackage[0], detail: detail, company: this.props.user.company, originalName: newPackage[0].packageName }
            payload = {packages: packagePayload, company: this.props.user.company}


            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            axios
                .post("api/ssrsSavePackage", payload, { headers })
                .then((response) => {
                    if (response.data.code === 200) {
                        alert("Import successful")
                        //callback(true)
                        this.getPackageList()
                        this.getTemplateList()
                        this.getTemplatePackageList();
                    }
                    else {
                        alert('An error has occured while importing.');
                        //callback(false)
                        this.getPackageList()
                        this.getTemplateList()
                        this.getTemplatePackageList();
                    }
                })
                .catch((e) => {
                    console.error(e);
                    alert('An error has occured while importing.');
                    //callback(false)
                    this.getPackageList()
                    this.getTemplateList()
                    this.getTemplatePackageList();
                });

        });

    }

    filterChangeGenericDate = (event) => {
        event.filter.field = "value"
        const dataStateToBeFiltered = "genericDates_" + event.target.name + "_Data"
        const dataStateContainingOriginalValues = this.state["genericDates_" + event.target.name + "_DataOriginal"]

        this.setState({
            [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
        });
    }

    filterAccountsWithInput = (event) => {
        event.filter.field = "value"
        const dataStateToBeFiltered = "accountsWithInput"
        const dataStateContainingOriginalValues = this.state.accountsWithInputForFilter

        this.setState({
            [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
        });
    }

    filterGroupsWithInput = (event) => {
        event.filter.field = "value"
        const dataStateToBeFiltered = "groupsWithInput"
        const dataStateContainingOriginalValues = this.state.groupsWithInputForFilter

        this.setState({
            [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
        });
    }

    filterData(filter, originalData) {
        //const data = allData.slice();
        return filterBy(originalData, filter);
    }


    openFileBrowser = () => {
        this.fileInput.current.click();
    }

    openFileBrowser2 = () => {
        this.fileInput2.current.click();
    }

    openFileBrowser3 = () => {
        this.fileInput3.current.click();
    }

    openFileBrowser4 = () => {
        this.fileInput4.current.click();
    }

    changeDataState = (e)=>{

        if (this.state.tabSelected === 0){
            var newData = process(this.state.indivPackageList, e.data);
            this.setState({dataState:e.data, indivResult:newData});
        }
        if (this.state.tabSelected === 1){
            var newData = process(this.state.groupPackageList, e.data);
            this.setState({dataState:e.data, groupResult:newData});
        }
        if (this.state.tabSelected === 2){
            var newData = process(this.state.templatePackageList, e.data);
            this.setState({dataState:e.data, templatePackageResult:newData});
        }
        if (this.state.tabSelected === 3){
            var newData = process(this.state.templateList, e.data);
            this.setState({dataState:e.data, templateResult:newData});
        }
        
      }


    render() {
        var packageType = [{ title: "Individual Packages", key: 0 }, { title: "Group Packages", key: 1 }]
        //console.log(this.state.packageRunList)
        return (
            <div className="packager">
                
                    <TabStrip selected={this.state.tabSelected} onSelect={(e) => { this.unselectAll(); this.setState({ tabSelected: e.selected, packageRunList: [], packageSelected: false }) }}>
                        {packageType.map((type, i) => {
                            return (
                                <TabStripTab title={type.title} name={type.title} key={type.key}>
                                    <>{this.state.downloadExcel && <ExcelExport excelDataSet={this.state.excelDataSet} />}</>


                                    {//this.props.uploadUser.approved !== false &&
                                        <div>
                                            <Grid data={type.key === 0 ? this.state.indivResult : this.state.groupResult}//asdf
                                                selectedField='selected'
                                                onSelectionChange={(e) => this.selectionChange(e, type.key === 0 ? this.state.indivPackageList : this.state.groupPackageList)}
                                                onRowClick={(e) => this.selectionChange(e, type.key === 0 ? this.state.indivPackageList : this.state.groupPackageList)}
                                                onHeaderSelectionChange={(e) => this.headerSelectionChange(e, type.key === 0 ? this.state.indivPackageList : this.state.groupPackageList)}
                                                onDataStateChange={this.changeDataState}
                                                pageable={true}
                                                filterable
                                                // filter={this.state.gridFilter}
                                                // onFilterChange={(e) => {
                                                //     this.onFilterChange(e);
                                                // }}
                                                expandField='expanded'
                                                onExpandChange={this.expandChange}
                                                detail={SsrsPackagerDetail}
                                                resizable
                                                sortable={{
                                                    mode: 'multiple'
                                                }}
                                                // sort={this.state.sort}
                                                // onSortChange={(e) => {
                                                //     this.setState({
                                                //         sort: e.sort
                                                //     });
                                                // }}
                                                {...this.state.dataState}
                                            >
                                                <GridNoRecords>
                                                    {this.state.loading && 'Loading...'}
                                                    {!this.state.loading && 'No records available'}
                                                </GridNoRecords>
                                                <GridToolbar>
                                                    <PackagerForm closeRefresh={this.getPackageList} idToken={this.props.idToken} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='create' indivOrGroup={type.key === 0 ? 'individual' : 'group'} package='' loading={false} reportList={[]} button={<Button className="docButton">Create New</Button>} />
                                                    <PackagerForm closeRefresh={this.getPackageList} idToken={this.props.idToken} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='edit' indivOrGroup={type.key === 0 ? 'individual' : 'group'} package={this.state.packageRunList} loading={this.state.editScreenLoading} reportList={this.state.editReportList} onReportListChange={this.onReportListChange} button={<Button icon='edit' disabled={!this.state.editPackage} className="docButton">Edit</Button>} />
                                                    {type.key === 0 && <><Button onClick={this.openFileBrowser.bind(this)}>Import Excel</Button><input type="file" hidden ref={this.fileInput} accept=".xlsx" onClick={(event) => { event.target.value = null }} onChange={this.determineFileHandler.bind(this)} style={{ "padding": "0px" }} /></>}
                                                    {type.key === 1 && <><Button onClick={this.openFileBrowser2.bind(this)}>Import Excel</Button><input type="file" hidden ref={this.fileInput2} accept=".xlsx" onClick={(event) => { event.target.value = null }} onChange={this.determineFileHandler.bind(this)} style={{ "padding": "0px" }} /></>}
                                                    <Button disabled={!this.state.packageSelected} onClick={this.exportToExcel}>Export to Excel</Button>
                                                    <Popup
                                                        open={this.state.openProgressModal}
                                                        closeOnDocumentClick
                                                        onClose={this.closeProgress}
                                                        overlayStyle={{ zIndex: 5 }}
                                                        modal
                                                    >
                                                        <div className="modal">
                                                            <Button className="close" icon="close" onClick={this.closeProgress} />
                                                            <div className="header" align="left">
                                                                Package Progress
                                                            </div>
                                                            <br />
                                                            <Progress color={this.state.progressBarColor} completed={this.state.progress} />
                                                            {<label style={{color: this.state.progressTextColor}}>{this.state.progressText}</label>}
                                                            <br />
                                                            {this.state.progressPackage && <><br/><label style={{color: 'rgb(0,0,0)'}}>Package: </label><label style={{color: this.state.progressTextColor}}>{this.state.progressPackage}</label></>}
                                                            {this.state.progressReport && <><br/><label style={{color: 'rgb(0,0,0)'}}>Report: </label><label style={{color: this.state.progressTextColor}}>{this.state.progressReport}</label></>}
                                                            <br />
                                                            <ul>
                                                                {this.state.progressErrorString.map(error => {
                                                                    return error
                                                                })}
                                                            </ul>
                                                            <ul>
                                                                {this.state.progressWarningText.map(warning => {
                                                                    return <><br /><label>{warning}</label></>
                                                                })}
                                                            </ul>

                                                        </div>
                                                    </Popup>
                                                    {(<Button className="docButton" disabled={!this.state.packageSelected} onClick={this.deletePackageRequest} icon='delete'>Delete</Button>)}
                                                    <Button className="docButton" disabled={!this.state.packageSelected || this.state.packageRequestLoading} onClick={(e) => this.sendPackageRequest(null)}>{this.state.runPackageText}</Button>
                                                    <Button className="docButton" disabled={!this.state.packageSelected || this.state.packageRequestLoading || this.state.packageRunList.length !== 1} onClick={(e) => this.setState({ openRunWithInput: true })}>Run Package with Input</Button>
                                                    <Popup
                                                        open={this.state.openRunWithInput}
                                                        closeOnDocumentClick
                                                        onClose={this.closeRunWithInput}
                                                        overlayStyle={{ zIndex: 5 }}
                                                        modal
                                                    >
                                                        <div className="modal">
                                                            <Button className="close" icon="close" onClick={this.closeRunWithInput} />
                                                            <div className="header" align="left">
                                                                Run Package With Input
                                                            </div>
                                                            <br />
                                                            <Grid2 fluid>
                                                                <Row>
                                                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                        <>
                                                                            <label>Reporting Entity:</label><br />
                                                                            <ComboBox
                                                                                name='accountWithInput'//asdf
                                                                                data={type.key === 0 ? this.state.accountsWithInput : this.state.groupsWithInput}
                                                                                textField="value"
                                                                                //textField={"entityCode     |Name of Account or Group truncated if it's too l...|Account"}
                                                                                dataItemKey="value"
                                                                                allowCustom={true}
                                                                                placeholder="As defined in package..."
                                                                                //defaultValue={defaultValue}
                                                                                filterable={true}
                                                                                header={<span>Code          |Name                              |Type    </span>}
                                                                                popupSettings={{ width: "450px", className: "ssrsportfolio" }}
                                                                                onFilterChange={type.key === 0 ? this.filterAccountsWithInput : this.filterGroupsWithInput}
                                                                                value={type.key === 0 ? this.state.accountRunWithInput : this.state.groupRunWithInput}
                                                                                //valid={valid}
                                                                                onChange={(e) => this.onChangeHandler(e, type.key === 0 ? 'accountRunWithInput' : 'groupRunWithInput')}
                                                                                itemRender={this.itemRender}
                                                                            />
                                                                        </>
                                                                    </Col>
                                                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} ><label style={{ paddingLeft: 20 }}></label></Col>
                                                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                        <>
                                                                            <label>From Date:</label><br />
                                                                            {!this.state.fromDateGenericDateToggle &&
                                                                                <div>
                                                                                    {!this.state.fromDateRunWithInputEnable &&
                                                                                        <Input
                                                                                            type="text"
                                                                                            name="fromDatePlaceholder"
                                                                                            style={{ color: '#B0B0B0' }}
                                                                                            defaultValue="As defined in package..."
                                                                                            onClick={(e) => this.setState({ fromDateRunWithInputEnable: true })}
                                                                                        />
                                                                                    }
                                                                                    {this.state.fromDateRunWithInputEnable &&
                                                                                        <DatePicker
                                                                                            //defaultValue={new Date()}
                                                                                            name='fromDate'
                                                                                            //valid={valid}
                                                                                            format={"MM/dd/yyyy"}
                                                                                            value={this.state.fromDateRunWithInput}
                                                                                            onChange={(e) => this.onChangeHandler(e, 'fromDateRunWithInput')}
                                                                                        />
                                                                                    }
                                                                                    <Button onClick={(e) => this.setState({ fromDateGenericDateToggle: true })} icon="list-unordered"></Button>
                                                                                </div>
                                                                            }
                                                                            {this.state.fromDateGenericDateToggle &&
                                                                                <div>
                                                                                    <ComboBox
                                                                                        name='fromDate'
                                                                                        data={this.state.genericDates_fromDate_Data}
                                                                                        textField="label"
                                                                                        dataItemKey="value"
                                                                                        placeholder="As defined in package..."
                                                                                        //defaultValue={defaultValue}
                                                                                        filterable={true}
                                                                                        onFilterChange={this.filterChangeGenericDate}
                                                                                        value={this.state.fromDateRunWithInputGeneric}
                                                                                        //valid={valid}
                                                                                        onChange={(e) => this.onChangeHandler(e, 'fromDateRunWithInputGeneric')}
                                                                                    />
                                                                                    <Button onClick={(e) => this.setState({ fromDateGenericDateToggle: false })} icon="calendar"></Button>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    </Col>
                                                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} ><label style={{ paddingLeft: 20 }}></label></Col>
                                                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                        <>
                                                                            <label>To Date**:</label><br />
                                                                            {!this.state.toDateGenericDateToggle &&
                                                                                <div>
                                                                                    {!this.state.toDateRunWithInputEnable &&
                                                                                        <Input
                                                                                            type="text"
                                                                                            name="toDatePlaceholder"
                                                                                            style={{ color: '#B0B0B0' }}
                                                                                            defaultValue="As defined in package..."
                                                                                            onClick={(e) => this.setState({ toDateRunWithInputEnable: true })}
                                                                                        />
                                                                                    }
                                                                                    {this.state.toDateRunWithInputEnable &&
                                                                                        <DatePicker
                                                                                            //defaultValue={new Date()}
                                                                                            name='toDate'
                                                                                            //valid={valid}
                                                                                            format={"MM/dd/yyyy"}
                                                                                            value={this.state.toDateRunWithInput}
                                                                                            onChange={(e) => this.onChangeHandler(e, 'toDateRunWithInput')}
                                                                                        />
                                                                                    }
                                                                                    <Button onClick={(e) => this.setState({ toDateGenericDateToggle: true })} icon="list-unordered"></Button>
                                                                                </div>
                                                                            }
                                                                            {this.state.toDateGenericDateToggle &&
                                                                                <div>
                                                                                    <ComboBox
                                                                                        name='toDate'
                                                                                        data={this.state.genericDates_toDate_Data}
                                                                                        textField="label"
                                                                                        dataItemKey="value"
                                                                                        placeholder="As defined in package..."
                                                                                        //defaultValue={defaultValue}
                                                                                        filterable={true}
                                                                                        onFilterChange={this.filterChangeGenericDate}
                                                                                        value={this.state.toDateRunWithInputGeneric}
                                                                                        //valid={valid}
                                                                                        onChange={(e) => this.onChangeHandler(e, 'toDateRunWithInputGeneric')}
                                                                                    />
                                                                                    <Button onClick={(e) => this.setState({ toDateGenericDateToggle: false })} icon="calendar"></Button>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    </Col>
                                                                    {this.type === 1 && <><Col xs={99} sm={99} md={99} lg={99} xl={99} ><label style={{ paddingLeft: 20 }}></label></Col>
                                                                        <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                            <>
                                                                                <label>Output as Single File:</label><br />
                                                                                <Switch
                                                                                    name='singleFileRunWithInput'//asdf
                                                                                    //data={this.state.accountsWithInput}
                                                                                    allowCustom={true}
                                                                                    placeholder="As defined in package..."
                                                                                    value={this.state.singleFileRunWithInput}
                                                                                    //valid={valid}
                                                                                    onChange={(e) => this.onChangeHandler(e, 'singleFileRunWithInput')}
                                                                                />
                                                                            </>
                                                                        </Col></>}
                                                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} ><label style={{ paddingLeft: 20 }}></label></Col>
                                                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                        <>
                                                                            <label>Name of File:</label><br />
                                                                            <Input
                                                                                name='filenameWithInput'//asdf
                                                                                placeholder="As defined in package..."
                                                                                value={this.state.filenameRunWithInput}
                                                                                //valid={valid}
                                                                                onChange={(e) => this.onChangeHandler(e, 'filenameRunWithInput')}
                                                                            />
                                                                        </>
                                                                    </Col>
                                                                </Row>
                                                            </Grid2>
                                                            {/* stuff goes here */}



                                                            <br />
                                                            <br />
                                                            <div style={{ display: "flex" }}>
                                                                <label style={{ marginRight: "auto", paddingLeft: 20 }}>** "To Date" includes Single Date reports</label>
                                                                {/* <Button onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Package</Button> */}
                                                                <Button onClick={(e) => this.runWithInput()} style={{ marginLeft: "auto" }}>Run Package</Button>
                                                            </div>
                                                        </div>
                                                    </Popup>
                                                </GridToolbar>
                                                <GridColumn filterable={false} width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={(type.key === 0 ? this.state.indivPackageList : this.state.groupPackageList).findIndex(dataItem => dataItem.selected === false) === -1} />
                                                <GridColumn filterable={true} filterCell={createFilterDropDownCell(this.state.frequencyDropdown, '')} width='140px' field="Frequency" headerClassName='gridHeader' title="Frequency" />
                                                <GridColumn
                                                    filterable={true}
                                                    width='350px'
                                                    field="Key"
                                                    title="Package Name"
                                                    headerClassName='gridHeader'
                                                />
                                                <GridColumn filterable={true} width='760px' field="Description" headerClassName='gridHeader' title="Package Description" />
                                                <GridColumn filterable={true} width='140px' field="Entity" headerClassName='gridHeader' title="Reporting Group" />
                                            </Grid>
                                            {this.state.loading && <GridLoading />}
                                        </div>
                                    }
                                    <br /><br />
                                </TabStripTab>
                            )

                        })}
                        <TabStripTab title={'Template Packages'} name={'Template Packages'} key={2}>
                            <>{this.state.downloadExcel && <ExcelExport excelDataSet={this.state.excelDataSet} />}</>
                            <Grid data={this.state.templatePackageResult}
                                selectedField='selected'
                                onSelectionChange={(e) => this.selectionChange(e, this.state.templatePackageList)}
                                onRowClick={(e) => this.selectionChange(e, this.state.templatePackageList)}
                                onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.templatePackageList)}
                                onDataStateChange={this.changeDataState}
                                pageable={true}
                                filterable
                                // filter={this.state.gridFilter}
                                // onFilterChange={(e) => {
                                //     this.onFilterChange(e);
                                // }}
                                expandField='expanded'
                                onExpandChange={this.expandChange}
                                detail={SsrsPackagerTemplatePackageDetail}
                                resizable
                                sortable={{
                                    mode: 'multiple'
                                }}
                                // sort={this.state.sort}
                                // onSortChange={(e) => {
                                //     this.setState({
                                //         sort: e.sort
                                //     });
                                // }}
                                {...this.state.dataState}
                            >
                                <GridNoRecords>
                                    {this.state.loading && 'Loading...'}
                                    {!this.state.loading && 'No records available'}
                                </GridNoRecords>
                                <GridToolbar>
                                    <PackagerForm closeRefresh={this.getTemplatePackageList} idToken={this.props.idToken} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='createtemplatepackage' indivOrGroup={'template'} package='' loading={false} reportList={[]} button={<Button className="docButton">Create New</Button>} />
                                    <PackagerForm closeRefresh={this.getTemplatePackageList} idToken={this.props.idToken} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='edittemplatepackage' package={this.state.packageRunList} loading={this.state.editScreenLoading} reportList={this.state.editReportList} onReportListChange={this.onReportListChange} button={<Button icon='edit' disabled={!this.state.editPackage} className="docButton">Edit</Button>} />
                                    <Button onClick={this.openFileBrowser4.bind(this)}>Import Excel</Button><input type="file" hidden ref={this.fileInput4} accept=".xlsx" onClick={(event) => { event.target.value = null }} onChange={this.determineFileHandler.bind(this)} style={{ "padding": "0px" }} />
                                    <Button disabled={!this.state.packageSelected} onClick={this.exportTemplatePackageToExcel}>Export to Excel</Button>
                                    <Popup
                                                        open={this.state.openProgressModal}
                                                        closeOnDocumentClick
                                                        onClose={this.closeProgress}
                                                        overlayStyle={{ zIndex: 5 }}
                                                        modal
                                                    >
                                                        <div className="modal">
                                                            <Button className="close" icon="close" onClick={this.closeProgress} />
                                                            <div className="header" align="left">
                                                                Package Progress
                                                            </div>
                                                            <br />
                                                            <Progress color={this.state.progressBarColor} completed={this.state.progress} />
                                                            {<label style={{color: this.state.progressTextColor}}>{this.state.progressText}</label>}
                                                            <br />
                                                            {this.state.progressPackage && <><br/><label style={{color: 'rgb(0,0,0)'}}>Package: </label><label style={{color: this.state.progressTextColor}}>{this.state.progressPackage}</label></>}
                                                            {this.state.progressReport && <><br/><label style={{color: 'rgb(0,0,0)'}}>Report: </label><label style={{color: this.state.progressTextColor}}>{this.state.progressReport}</label></>}
                                                            <br />
                                                            <ul>
                                                                {this.state.progressErrorString.map(error => {
                                                                    return error
                                                                })}
                                                            </ul>
                                                            <ul>
                                                                {this.state.progressWarningText.map(warning => {
                                                                    return <><br /><label>{warning}</label></>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </Popup>
                                    {(<Button className="docButton" disabled={!this.state.packageSelected} onClick={this.deletePackageRequest} icon='delete'>Delete</Button>)}
                                    <Button className="docButton" disabled={!this.state.packageSelected || this.state.packageRequestLoading} onClick={(e) => this.sendPackageRequest(null)}>{this.state.runPackageText}</Button>
                                    {/* <Button className="docButton" disabled={!this.state.packageSelected || this.state.packageRequestLoading} onClick={(e) => this.setState({ openRunWithInput: true })}>Run Package with Input</Button> */}
                                </GridToolbar>
                                <GridColumn filterable={false} width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={(this.state.groupPackageList).findIndex(dataItem => dataItem.selected === false) === -1} />
                                <GridColumn filterable={true} filterCell={createFilterDropDownCell(this.state.frequencyDropdown, '')} width='140px' field="Frequency" headerClassName='gridHeader' title="Frequency" />
                                <GridColumn
                                    filterable={true}
                                    width='350px'
                                    field="Key"
                                    title="Package Name"
                                    headerClassName='gridHeader'
                                />
                                <GridColumn filterable={true} width='760px' field="Description" headerClassName='gridHeader' title="Package Description" />
                                <GridColumn filterable={true} width='140px' field="Entity" headerClassName='gridHeader' title="Reporting Group" />
                            </Grid>
                        </TabStripTab>
                        <TabStripTab title={'Templates'} name={'Templates'} key={3}>
                            <>{this.state.downloadExcel && <ExcelExport excelDataSet={this.state.excelDataSet} />}</>
                            <Grid data={this.state.templateResult}
                                selectedField='selected'
                                onSelectionChange={(e) => this.selectionChange(e, this.state.templateList)}
                                onRowClick={(e) => this.selectionChange(e, this.state.templateList)}
                                onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.templateList)}
                                onDataStateChange={this.changeDataState}
                                pageable={true}
                                filterable
                                // filter={this.state.gridFilter}
                                // onFilterChange={(e) => {
                                //     this.onFilterChange(e);
                                // }}
                                expandField='expanded'
                                onExpandChange={this.expandChange}
                                detail={SsrsPackagerTemplateDetail}
                                resizable
                                sortable={{
                                    mode: 'multiple'
                                }}
                                // sort={this.state.sort}
                                // onSortChange={(e) => {
                                //     this.setState({
                                //         sort: e.sort
                                //     });
                                // }}
                                {...this.state.dataState}
                            >
                                <GridNoRecords>
                                    {this.state.loading && 'Loading...'}
                                    {!this.state.loading && 'No records available'}
                                </GridNoRecords>
                                <GridToolbar>
                                    <PackagerForm closeRefresh={this.getTemplateList} idToken={this.props.idToken} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='createtemplate' indivOrGroup={'group'} package='' loading={false} reportList={[]} button={<Button className="docButton">Create New</Button>} />
                                    <PackagerForm closeRefresh={this.getTemplateList} idToken={this.props.idToken} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='edittemplate' indivOrGroup={'group'} package={this.state.packageRunList} loading={this.state.editScreenLoading} reportList={this.state.editReportList} onReportListChange={this.onReportListChange} button={<Button icon='edit' disabled={!this.state.editPackage} className="docButton">Edit</Button>} />
                                    <Button onClick={this.openFileBrowser3.bind(this)}>Import Excel</Button><input type="file" hidden ref={this.fileInput3} accept=".xlsx" onClick={(event) => { event.target.value = null }} onChange={this.determineFileHandler.bind(this)} style={{ "padding": "0px" }} />
                                    <Button disabled={!this.state.packageSelected} onClick={this.exportToExcel}>Export to Excel</Button>
                                    {(<Button className="docButton" disabled={!this.state.packageSelected} onClick={this.deletePackageRequest} icon='delete'>Delete</Button>)}
                                </GridToolbar>
                                <GridColumn filterable={false} width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={(this.state.groupPackageList).findIndex(dataItem => dataItem.selected === false) === -1} />
                                {/* <GridColumn filterable={true} filterCell={createFilterDropDownCell(this.state.frequencyDropdown, '')} width='140px' field="Frequency" headerClassName='gridHeader' title="Frequency" /> */}
                                <GridColumn
                                    filterable={true}
                                    width='350px'
                                    field="Key"
                                    title="Template Name"
                                    headerClassName='gridHeader'
                                />
                                <GridColumn filterable={true} width='760px' field="Description" headerClassName='gridHeader' title="Template Description" />
                                {/* <GridColumn filterable={true} width='140px' field="Entity" headerClassName='gridHeader' title="Reporting Group" /> */}
                            </Grid>
                        </TabStripTab>
                    </TabStrip>
                
            </div>
        );
    }
}

export default SsrsPackager;