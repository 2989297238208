import React from 'react';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import {parseDate} from '@telerik/kendo-intl';
import loading from '../../Callback/loading.svg';

class TransactionSummary extends React.Component {

  constructor(props) {
    super(props);

    const valObj = this.getValues(this.props.width);

    this.state = {
      data: [],
      labels: [],
      transactions: [],
      loading: true,
      col1: valObj.col1,
      col2: valObj.col2,
      graphHeight: valObj.graphHeight,
      dataState:{
        group: [{field:'tranCode', aggregates:[{field: 'tradeAmount', aggregate:'sum'}, {field: 'tranName', aggregate:'max'}]}],
        sort:[{field:'tradeDate', dir:'desc'}]
      },
      styleSettings: null,
    };

  }

  getValues = (width) => {
    let col1 = 0, col2 = 0, graphHeight = 0;
    switch (width) {
      case 4:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 6:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 12:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      default:
    }
    return { col1: col1, col2: col2, graphHeight: graphHeight }
  }

  componentDidMount() {
    this.getStyleSettings(() =>{
      this.props.callback((width) => {
        const CBvalObj = this.getValues(width);
        this.setState({
          graphHeight: CBvalObj.graphHeight,
          col1: CBvalObj.col1,
          col2: CBvalObj.col2
        })
      });
      if (this.props.onWidgetLoad) {
        this.props.onWidgetLoad();
        this.props.onAddCallback((result, err) => {
          this.setState({ loading: true }, () => {
            this.onSubmit();
          })
        })
      }
    })
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'tranSummaryPeriod'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      this.setState({styleSettings: response.data.styleSettings}, () => {
        cb()
      })
    })
    .catch(err => {
        console.error(err);
    })
  }

  onSubmit = () => {
    this.getTransactions();
  }

  getDate(sqlDate, isUTC){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if(d){
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getPeriod(){
    var period = this.state.styleSettings.tranSummaryPeriod;
    var date = new Date();

    if(period==="Month to Date")
      date = new Date(date.getFullYear(), date.getMonth(), 1);
    else if(period==='Quarter to Date'){
      var quarter = Math.floor((date.getMonth() / 3));
      date = new Date(date.getFullYear(), quarter * 3, 1);
    }
    else if(period==='Year to Date')
      date = new Date(date.getFullYear(), 0, 1);
    else if(period==='6 Month')
      date = new Date(date.getFullYear(), date.getMonth()-6, date.getDate());
    else if(period==='12 Month')
      date = new Date(date.getFullYear()-1, date.getMonth(), date.getDate());
    else date = null;

    return date;
  }

  getTransactions(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var port = this.props.selectedHousehold.household

    var fromDate = this.getPeriod()
    var toDate = new Date()


    axios.get(`api/transactions`, {
      method: 'GET',
      params: {
        company: this.props.user.company,
        port: port,
        consolidated: true,
        from: fromDate,
        to: toDate,
      },
      headers: headers,
    })
    .then(response=>{
      if(response.data.code===200){
        var fDate = fromDate;
        var tDate = toDate;
        var getNewDate = false
        if (!fDate){
          getNewDate = true
        }

        for(let i=0; i<response.data.data.length; i++){
            response.data.data[i].tradeDate = this.getDate(response.data.data[i].tradeDate, true);
            response.data.data[i].settleDate = this.getDate(response.data.data[i].settleDate, true);
            if(!response.data.data[i].displayName)
              response.data.data[i].displayName = response.data.data[i].portfolio;

            //get earliest date
            if(getNewDate && (!fDate || new Date(fDate) > new Date(response.data.data[i].tradeDate)))
              fDate = response.data.data[i].tradeDate;
            
        }

        
        this.setState({transactions:response.data.data, loading:false, toDate:tDate, fromDate:fDate, result:process(response.data.data, this.state.dataState)});
      }
    }).catch(err=>{
      this.setState({transactions:[], loading:false});
      console.log(err);
    });
  }

  numberWithCommas(x) {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return (<div>
      <div className="widget">
        <div className={'widgetWidth'+this.state.col2}>
          {this.state.loading && <img className="loadingIcon" src={loading} alt="loading" />}
          {!this.state.loading ?
            <>
              <div className='tranSummSnapshotHeader' ><label>{`${this.state.fromDate.toLocaleDateString("en-US")} to ${this.state.toDate.toLocaleDateString("en-US")}`}</label></div>
              {this.state.transactions.length > 0 ?
                <div style={{ height: this.state.graphHeight + 'px' }}>
                  <table className='tranSummSnapshot'>
                    <tbody>
                      {this.state.result && this.state.result.data.map((r,i) => {
                        if (i >= 7){
                          return (<></>)
                        }
                        else{
                          return (
                            <tr>
                              <td className='tranSummLabel'>
                                <label style={{fontWeight:'bold'}}>{r.aggregates.tranName.max}</label> <label>({r.items.length})</label>
                              </td>
                              <td className='tranSummValue'>
                                <label style={{fontWeight:'bold'}}>{this.numberWithCommas((Math.round(r.aggregates.tradeAmount.sum * 100) / 100).toFixed(2))}</label>
                              </td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                </div>
                : <div className='widgetNoDataWithDate'>No transactions during the period</div>
              }
            </>
            : <div className='widgetNoData'></div>}
        </div>
      </div>
    </div>
    );
  }
}

export default TransactionSummary;