import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import axios from 'axios';

class AddHouseholds extends Component {

  constructor(props) {
    super(props);
      this.state = {
        households:[],
        householdsToAdd:[],
        adding:''
    }

    this.clearState = this.clearState.bind(this);
    this.getHouseholds = this.getHouseholds.bind(this);
  }

  componentDidMount(){
    this.getHouseholds();
  }

  getHouseholds(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken)};
    axios.get("api/households?company="+this.props.user.company, {headers}).then(response => {
        if(response.data.code===200){
          this.setState({households:response.data.households});
        }
        else{
          alert("An error occured while fetching the household list.");
        }
      }).catch(error => console.log(error));
  }

  addHouseholdsToGroup(callback){
    
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var households = []
    if (this.state.householdsToAdd.length > 0) {
        for (let householdToAdd of this.state.householdsToAdd){
          households.push(householdToAdd.household)
        }
        //households = this.state.householdsToAdd
    }

    var payload = {
      households:households,
      action:'add',
      group:this.props.group,
      company:this.props.user.company
    }

    this.setState({adding:true});

    axios.post("api/householdgroup", payload, {headers}).then(response => {

      this.setState({adding:false});

      if(response.data.code===200){
        alert('Households successfully added to group');
        callback()
      }
      else{
        alert(response.data.errMsg);
        callback("ERROR");
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err);
        callback("ERROR");
      });  
  }

  onHouseholdsChange = (e)=>{
    this.setState({householdsToAdd:e.target.value});
  }

  clearState(){
      this.setState({
        householdsToAdd:[]
      });
  }

  render() {
      return (
        <Popup trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={close} />


              <div className="header" align="left">
                Add Households to Group
              </div>

              <div className="content">
                <label>List of Households</label><br />
                <MultiSelect
                  data={this.state.households}
                  textField="household"
                  onChange={this.onHouseholdsChange}
                />
              </div>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </Button>

                <Button 
                    className="upload"
                    disabled={this.state.adding}
                    onClick={(e) => {this.addHouseholdsToGroup(err=>{
                      if(!err){
                        this.props.refresh();
                        close();
                      }
                    });}}
                >
                  {!this.state.adding && <span>Add</span>}
                  {this.state.adding && <span>Adding...</span>}
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };

}

export default AddHouseholds;