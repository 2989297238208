import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {DatePicker} from '@progress/kendo-react-dateinputs';
import axios from 'axios';

class Vacation extends Component {

  constructor(props) {
    super(props);
    this.state = {
        fromDate: null,
        toDate: null
    }

    this.sendRequest = this.sendRequest.bind(this);
  }

  onDateChange = (e, type)=>{
    this.setState({[type]:e.target.value});
  }

  sendRequest(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
        from: this.state.fromDate,
        to: this.state.toDate,
        company: 'cssi'
    }
    axios
      .post("api/vacationRequest", payload, {headers})
      .then((response) => {
        if(response.data.code===200){
            alert('Vacation request has been sent.');
            this.setState({fromDate:null, toDate:null});
        }
        else{
            alert('An error has occured while sending your request.');
        }
      })
      .catch((e) => {
        console.error(e);
    });
  }


  render() {
        return (
            <div>
                <div><label>From Date: </label><DatePicker value={this.state.fromDate} onChange={(e)=>this.onDateChange(e,'fromDate')}/></div>
                <div><label>To Date: </label><DatePicker value={this.state.toDate} onChange={(e)=>this.onDateChange(e,'toDate')}/></div>
                <Button onClick={(e)=>this.sendRequest()}>Send Request</Button>
            </div>
        );
  }
}

export default Vacation;