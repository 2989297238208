import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

class MapUserHHPopUp extends Component {

  constructor(props) {
    super(props);

    this.state = {
        households:this.props.households,
        result:this.props.households,
        selectedHousehold:'',
        display:'',
        email:this.props.email
    }

    this.clearState = this.clearState.bind(this);
    this.mapAcct = this.mapAcct.bind(this);
  }

  componentDidMount(){

  }

  mapAcct(){
    var user = this.props.user;
    const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
    
    var payload = {
        company:this.props.company,
        email:this.props.email,
        household:this.state.selectedHousehold
    }

    axios.post('api/createHouseholdUserMapping', payload, {headers}).then(response=>{

    }).catch(err=>{
        console.log(err);
    });


  }

  onUserChange = (e)=>{
    this.setState({selectedHousehold:e.target.value});
  }

  clearState(){
    this.setState({result:this.props.households, selectedHousehold:''});
  }

  render() {
      return (
        <Popup trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

                <Button className="close" icon="close" onClick={close} />


                <div className="header" align="left">
                Map Account User: <strong>{this.props.user.nickname}</strong>
                </div>

                <div className="content">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Map to Household:</strong>
                                </td>
                                <td>
                                    <MultiSelect
                                        style={{width:'100%'}}
                                        data={this.state.result}
                                        textField='household'
                                        onChange={this.onUserChange}
                                        filterable={true}
                                        allowCustom={false}
                                        onFilterChange={(e)=>{
                                            this.setState({result:filterBy(this.state.households, e.filter)});
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>            
                </div>

                <div className="actions">

                    <Button className="button" onClick={() => {close()}}>
                        Close
                    </Button>

                    <Button className="upload" onClick={(event) => {
                        this.mapAcct();
                        this.clearState();
                        close();
                        this.props.refresh();
                        }}>
                        Confirm
                    </Button>

                </div>

            </div>
          )}
        </Popup>

      );
  };

}

export default MapUserHHPopUp;