import React, { Component } from "react";
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../Loaders/GridLoading';

class Landing extends Component {

  constructor(props) {
    super(props);

    var aggs = [{field:'mktVal', aggregate:'sum'}, 
    {field:'realizedYTD', aggregate:'sum'}, 
    {field:'estAnnIncome', aggregate:'sum'}];
    
    var landCols = [];

    if(props.compSettings.landCols!==null){
      landCols = props.compSettings.landCols.split(',');
    }

    this.state = {
        data:[],
        result:[],
        loading:true,
        columns:landCols,
        dataState:{group:[{field:"email", aggregates:aggs}]},
        aggregates:aggs,
    }

    this.getPortfolios=this.getPortfolios.bind(this);
    this.formatCurrency=this.formatCurrency.bind(this);
    this.cellRender=this.cellRender.bind(this);
    this.headerCellRender=this.headerCellRender.bind(this);
  }

  componentDidMount(){
    this.getPortfolios();
  }

  getPortfolios(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get('api/landingData?company='+this.props.user.company+'&user='+this.props.user.user+'&perfType='+this.props.compSettings.defaultFees, {headers}).then(response=>{
      if(response.data.code===200){
        var data = response.data.data;
        var result = process(data, this.state.dataState);
        this.setState({data:data, result:result, loading:false});
      }
      else{
        alert("Could not get portfolios for account.");
        this.setState({data:[], result:[], loading:false});
      }
    }).catch(err=>{
      this.setState({data:[], result:[], loading:false});
      console.log(err);
    });
  }

  changeDataState = (e)=>{
    const groups = e.data.group;
    if(groups){
      groups.map(group=>group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.data, e.data);
    this.setState({dataState:e.data, result:newData});
  }

  formatCurrency(val){
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    
    if(val<0)
        formatted = '('+formatted+')';

    return formatted;
  }

  cellRender(tdElement, cellProps) {
    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field==='mktVal' || cellProps.field==='realizedYTD' || cellProps.field==='estAnnIncome') { 
        var val = '';
        if(cellProps.dataItem.aggregates[cellProps.field]){
          val = cellProps.dataItem.aggregates[cellProps.field].sum;
          val = this.formatCurrency(val);
        }
        return (
            <td style={{textAlign: 'right'}}>
                <strong>{val}</strong>
            </td>
        );
      }
      else if(cellProps.field==='displayName'){
        return (
          <td colSpan='1'>
              <strong>{cellProps.dataItem.value} Totals:</strong>
          </td>
        );
      }
    }
    if(tdElement!==null){
      var style = {};
      if(cellProps.field!=='displayName')
        style = {textAlign: 'right'};

      return(
        <td {...tdElement.props} style={style}/>
      );
    }
    else{
      return tdElement;
    }
  }

  headerCellRender(thElement, cellProps) {
    if(thElement!==null){
      var style = {};
      if(cellProps.field!=='displayName')
        style = {textAlign: 'right'};
  
      return(
        <div {...thElement.props} style={style}/>
      );
    }
    else{
      return thElement;
    }
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

  render() {
    return(
      <div className='portLanding'>
        <Grid data={this.state.result}
              filterable={true}
              sortable={true}
              groupable={{footer:'always'}}
              //pageable={true}
              onExpandChange={this.expandChange}
              expandField="expanded"
              onDataStateChange={this.changeDataState}
              onRowClick={this.props.onAccountClick}
              cellRender={this.cellRender}
              headerCellRender={this.headerCellRender}
              {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridColumn headerClassName='gridHeader' field='displayName' title='Account' />
            {this.state.columns.indexOf('mtdIrr')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='mtd' format='{0:n2}' title='MTD IRR' />}
            {this.state.columns.indexOf('mtd')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='twrMTD' format='{0:n2}' title='MTD' />}
            {this.state.columns.indexOf('qtd')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returnsQTD' format='{0:n2}' title='QTD' />}
            {this.state.columns.indexOf('qtdIrr')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='qtd' format='{0:n2}' title='QTD IRR' />}
            {this.state.columns.indexOf('pqtr')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returnsPrevQtr' format='{0:n2}' title='Prev QTR' />}
            {this.state.columns.indexOf('3mo')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns3Month' format='{0:n2}' title='3 Months' />}
            {this.state.columns.indexOf('6mo')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns6Month' format='{0:n2}' title='6 Months' />}
            {this.state.columns.indexOf('ytdIrr')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='ytd' format='{0:n2}' title='YTD IRR' />}
            {this.state.columns.indexOf('ytd')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='twrYTD' format='{0:n2}' title='YTD' />}
            {this.state.columns.indexOf('pyr')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returnsPrevYear' format='{0:n2}' title='Prev Year' />}
            {this.state.columns.indexOf('l1y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returnsYear' format='{0:n2}' title='1 Years' />}
            {this.state.columns.indexOf('l3y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns3Year' format='{0:n2}' title='3 Years' />}
            {this.state.columns.indexOf('l5y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns5Year' format='{0:n2}' title='5 Years' />}
            {this.state.columns.indexOf('l7y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns7Year' format='{0:n2}' title='7 Years' />}
            {this.state.columns.indexOf('l10y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns10Year' format='{0:n2}' title='10 Years' />}
            {this.state.columns.indexOf('l15y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns15Year' format='{0:n2}' title='15 Years' />}
            {this.state.columns.indexOf('l20y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns20Year' format='{0:n2}' title='20 Years' />}
            {this.state.columns.indexOf('l25y')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='returns25Year' format='{0:n2}' title='25 Years' />}
            {this.state.columns.indexOf('itd')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='twrITD' format='{0:n2}' title='ITD' />}
            {this.state.columns.indexOf('mv')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='mktVal' format='{0:c2}' title='Market Value' />}
            {this.state.columns.indexOf('rYTD')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='realizedYTD' format='{0:c2}' title='Realized YTD' />}
            {this.state.columns.indexOf('estAnn')>=0 && <GridColumn headerClassName='gridHeader' filterable={false} field='estAnnIncome' format='{0:c2}' title='Est. Ann. Income' />}
        </Grid>
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

}

export default Landing;