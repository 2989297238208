import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import tranFields from './tranFields.json';

class TranMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:{portfolio:'',
                tranName:'',
                security:'',
                tradeDate:'',
                settleDate:'',
                quantity:'',
                sdType:'',
                sdSymbol:'',
                closeMethod:'',
                tradeAmount:'',
                lot:'',
                tranCode:'',
                secType:'',
                secTypeText:'',
                secSymbol:'',
                //new fields (4/23/2020)
                EntityType:'',
                Exchange:'',
                ExchangeFee:'',
                Commission:'',
                Broker:'',
                ImpliedCommission:'',
                OtherFees:'',
                CommissionPurpose:'',
                Pledge:'',
                LotLocation:'',
                DestPledge:'',
                DestLotLocation:'',
                OriginalFace:'',
                YieldOnCost:'',
                DurationOnCost:'',
                TranUserDef1:'',
                TranUserDef2:'',
                TranUserDef3:'',
                TranID:'',
                PostDate:'',
                OriginalCost:'',
                OriginalCostDate:'',
                AccruedInterest:'',
                CUSIP:'',
                Comment:'',
                ChildPortfolio:'',
                UnitPrice:''



        }
    }

    this.getMappings = this.getMappings.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount(){
      this.getMappings();
  }

  onInputChange(e, attr){
    let mappings = {...this.state.mappings};
    mappings[attr] = e.target.value.toUpperCase();
    this.setState({mappings});
  }

  uploadMappings = (e)=>{
    if(this.state.mappings.portfolio===null || this.state.mappings.portfolio==='')
        alert("Account Name/ID is required.");
    else{
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload ={
            company:this.props.user.company,
            mappings:this.state.mappings
        }
        axios.post('api/tranMappings', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Transaction Mappings have been updated.");
            this.getMappings();
        }
        else
            alert("An error occured when trying to update mappings");
        }).catch(err=>{
        alert("An error occured when trying to update mappings");
        this.getMappings();
        });
    }
  }

  getMappings(){
    var emptyMappings = {portfolio:'',
                        tranName:'',
                        security:'',
                        tradeDate:'',
                        settleDate:'',
                        quantity:'',
                        sdType:'',
                        sdSymbol:'',
                        closeMethod:'',
                        tradeAmount:'',
                        lot:'',
                        tranCode:'',
                        secType:'',
                        secTypeText:'',
                        secSymbol:'',
                        //new fields (4/23/2020)
                        EntityType:'',
                        Exchange:'',
                        ExchangeFee:'',
                        Commission:'',
                        Broker:'',
                        ImpliedCommission:'',
                        OtherFees:'',
                        CommissionPurpose:'',
                        Pledge:'',
                        LotLocation:'',
                        DestPledge:'',
                        DestLotLocation:'',
                        OriginalFace:'',
                        YieldOnCost:'',
                        DurationOnCost:'',
                        TranUserDef1:'',
                        TranUserDef2:'',
                        TranUserDef3:'',
                        TranID:'',
                        PostDate:'',
                        OriginalCost:'',
                        OriginalCostDate:'',
                        AccruedInterest:'',
                        CUSIP:'',
                        Comment:'',
                        ChildPortfolio:'',
                        UnitPrice:''
    }

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/tranMappings?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.mappings.length===0)
          this.setState({mappings:emptyMappings});
      else
        this.setState({mappings:response.data.mappings[0]});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:emptyMappings})
    });
  }


  render() {
    var data = tranFields;
    return(
        <div>
            <table className='posMapTable'>
                <tbody>
                    <tr>
                        <td className='heading'>Field Name <hr/></td>
                        <td align='right' className='heading'>Column Letter in Excel <hr/></td>
                    </tr>
                    {data.map(field => (
                    <tr key={field.id}>
                        <td><h4>{field.name}</h4><div>{field.desc}</div></td>
                        <td align='right'><input type='text' value={this.state.mappings[field.dbField]} onChange={e=>this.onInputChange(e,field.dbField)}/></td>                  
                    </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td align='right'><Button onClick={(e)=>this.uploadMappings(e)}>Apply Changes</Button></td>
                    </tr> 
                </tbody>
            </table>
        </div>
    );
  }

}

export default TranMapping;