import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import DetailGrid from './components/DetailGrid';
import { Button } from "@progress/kendo-react-buttons";
import AddGroup from './components/AddGroup';
import {orderBy} from '@progress/kendo-data-query';
import GridLoading from '../Loaders/GridLoading';
import axios from 'axios';

class UserGroups extends Component {

  constructor(props) {
    super(props);
    this.state = {
        groups:[],
        sort:[{field: 'name', dir:'asc'}],
        loading:true
    }

    this.getGroups = this.getGroups.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
  }

  componentDidMount(){
    this.getGroups();
  }

  deleteGroup(id){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      group:id,
      company:this.props.user.company
    }
    axios.post("api/deleteUserGroup", payload, {headers}).then(response => {
      if(response.data.code===200){
        alert('Users group successfully deleted');
        this.getGroups();
      }
      else{
        alert(response.data.error);
        this.getGroups();
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err);
        this.getGroups();
      });  
  }

  getGroups(){
    this.setState({loading:true});
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/usergroups?company="+this.props.user.company, {headers}).then(response => {
      if(response.data.code===200){
        this.setState({groups:response.data.groups, loading:false});
      }
      else{
        alert("An error occured while fetching the user groups.");
        this.setState({loading:false});
      }
    }).catch(error => console.log(error));
  }

  expandChange = (e) =>{
    e.dataItem.expanded = e.value;
    if(e.value){
      e.dataItem.idToken = this.props.idToken;
      e.dataItem.loggedInUser = this.props.user;
      e.dataItem.refresh = this.getGroups;
    }
    else{
      e.dataItem.idToken=null;
      e.dataItem.loggedInUser=null;
      e.dataItem.refresh = null;
    }
    this.setState({ ...this.state });
  }

  render() {
    return(
        <div className={'userGroups'+this.props.size}>
            <Grid data={orderBy(this.state.groups, this.state.sort)}
                detail={DetailGrid}
                expandField='expanded'
                onExpandChange={this.expandChange}>
                <GridToolbar>
                    {this.props.back && <Button onClick={this.props.back}>Back to Users</Button>}
                    <AddGroup user={this.props.user} idToken={this.props.idToken} refreshGroups={this.getGroups} button={<Button icon='plus'>Create Group</Button>} />
                </GridToolbar>
                <GridColumn field='name' title='Group'/>
                <GridColumn width='100px' cell={(props)=><td><Button icon='delete' onClick={(e)=>this.deleteGroup(props.dataItem.id)}>Delete</Button></td>}/>
            </Grid>
            {this.state.loading && <GridLoading gridClass='userGroups'/>}
        </div>
    );
  }

}

export default UserGroups;