import React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import ReportLogosPopup from './components/ReportLogosPopup';

const StatefulGrid = withState(Grid);

class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            reportLogos: [],
            gridFilter: undefined,
            filterable: false,
            sort: [{ field: 'SortOrder', dir: 'asc' }],
            fileDownload: [],
            fileSelected: false
        };

    }

    componentDidMount() {
        this.getReportLogos();
    }

    refreshReportLogos = () => {
        this.setState({
            fileDownload: [],
            fileSelected: false
        }, () => this.getReportLogos());
    }

    onLogoChange = (e) => {
        this.setState({
            reportLogo: e.target.files[0],
            reportLogoPreview: URL.createObjectURL(e.target.files[0])
        });
    }

    removeLogo = () => {
        this.setState({
            reportLogo: '',
            reportLogoPreview: '',
            logoRemoved: true
        });
    }

    callback = () => {
        this.setState({
            reportLogoPreview: '',
            reportLogo: this.props.reportLogo
        })
    }

    applyChanges = () => {
        this.props.saveReportLogo(this.state.reportLogo, this.callback)
        this.setState({
            logoRemoved: false,
            reportLogo: ''
        });
    }

    getReportLogos = (callback) => {
        var reportLogos = [];

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/getReportLogos`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                response.data.data.forEach(reportLogoInResponse => {

                    if (reportLogoInResponse.uploadDate) {
                        reportLogoInResponse.uploadDate = this.getDate(reportLogoInResponse.uploadDate);
                    }
                    reportLogoInResponse.selected = false
                    reportLogos.push(reportLogoInResponse)
                })
                this.setState({
                    reportLogos: reportLogos,
                }, () => {
                    if (callback) {
                        callback();
                    }
                })
            })
            .catch(err => {
                console.error(err);
            })
    }

    uploadLogo = (callback) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        const formData = new FormData();
        formData.append('company', 'logos');
        formData.append('logoCompany', this.props.company);
        formData.append('type', 'reportLogo');
        formData.append('prevLogo', this.state.reportLogoCurrent);
        if (this.state.reportLogo.name != null) formData.append(this.props.company + this.state.reportLogo.name.substring(this.state.reportLogo.name.indexOf('.')), this.state.reportLogo);

        axios.post('api/logo?company='+this.props.company, formData, { headers }).then(response => {
            if (response.data.code === 200) {
                // alert("Logo has been updated.");
            }
            callback();
        }).catch(err => console.log(err));
    }

    saveReportLogo = (file, callback) => {
        this.setState({
            reportLogo: file
        }, () => {
            this.deleteReportLogo(
                () => this.uploadReportLogo(() => this.getReportList(() => callback()))
            );
        });
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => {
            if (item.id !== event.dataItem.id) {
                item.selected = false
            }
        });
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles }, () => { this.forceUpdate(); });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerCellRender(thElement, cellProps) {
        if (thElement !== null) {
            if (cellProps.field === 'selected')
                return (
                    <div />
                );
            else {
                return thElement
            }
        }
        else {
            return thElement;
        }
    }

    getDate(sqlDate, isUTC) {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        if (isUTC)
            return utc;
        else
            return d;
    }

    onLinkClick = (event, rep, idToken) => {
        const headers = { 'authorization': 'Bearer '.concat(idToken) };
        axios
            .get("api/download?company=" + this.props.user.company + "&path=" + rep.contentPath + "&name=" + rep.contentName, { headers })
            .then((response) => {
                window.open(response.data, '_blank');
            })
            .catch((e) => {
                console.error("ERROR");
                return false;
            });

        return false;
    }

    onDelete = (e) => {

        var confirm = window.confirm("Are you sure you want to delete this logo?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                reportLogos: this.state.fileDownload,
                company: this.props.user.company,
            };
            axios.post('api/deleteReportLogo', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getReportLogos();
                }
                else {
                    alert("Error: Logo could not be deleted.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getReportLogos();
                }
            }).catch(e => console.log(e));
        }
    }

    onSetReportLogo = (e) => {

        var confirm = window.confirm("Are you sure? This will be the logo that will appear on reports");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                id: this.state.fileDownload[0].id,
                company: this.props.user.company,
            };
            axios.post('api/setReportLogo', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getReportLogos();
                }
                else {
                    alert("Error: Disclaimer could not be set.");
                    this.setState({ fileDownload: [] });
                    this.setState({ fileSelected: false });
                    this.getReportLogos();
                }
            }).catch(e => console.log(e));
        }
    }

    render() {
        return (
            <div className='reportLogo'>
                <StatefulGrid data={this.state.reportLogos}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.reportLogos)}
                    onRowClick={(e) => this.selectionChange(e, this.state.reportLogos)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.reportLogos)}
                    headerCellRender={this.headerCellRender}
                    filterable={this.state.filterable}
                    resizable
                >
                    <GridToolbar>
                        <Button icon='refresh' title='Refresh' onClick={this.refreshReportLogos}>{this.state.refreshText}</Button>
                        <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                        <ReportLogosPopup idToken={this.props.idToken} uploadMethod={this.refreshReportLogos} files={this.state.reportLogos} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} type='reportStaticContent' button={<Button className="docButton" icon="upload">New</Button>} />
                        {this.props.uploadUser.role === 'admin' && (<ReportLogosPopup idToken={this.props.idToken} reportLogoEdit={this.state.fileDownload[0]} uploadMethod={this.refreshReportLogos} files={this.state.reportLogos} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} button={<Button className="docButton" disabled={this.state.fileDownload.length !== 1} icon='edit'>Edit</Button>} />)}
                        {this.props.uploadUser.role === 'admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete</Button>)}
                        {this.props.uploadUser.role === 'admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onSetReportLogo} >Set as Default Report Logo</Button>)}
                    </GridToolbar>
                    <GridColumn width='50px' field="selected" filterable={false} headerClassName='gridHeader' headerSelectionValue={this.state.reportLogos.findIndex(dataItem => dataItem.selected === false) === -1 && this.state.reportLogos.length > 0} />
                    <GridColumn field="displayName" headerClassName='gridHeader' title="File Name" />
                    <GridColumn
                        // width='180px'
                        field="filePath"
                        title="Preview"
                        filterable={false}
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                <img className="cornerUpload" src={"Logos/" + props.dataItem.filePath} alt="Report Logo" />
                            </td>
                        }
                    />
                    {/* <GridColumn field="description" headerClassName='gridHeader' title="Description" /> */}
                    <GridColumn
                        width='160px'
                        field="uploadDate"
                        headerClassName='gridHeader'
                        filter="date"
                        format="{0:g}"
                        title="Last Updated:"
                    />
                    <GridColumn
                        width='160px'
                        field="reportLogoSelected"
                        headerClassName='gridHeader'
                        title="Selected"
                        filterable={false}
                        cell={(props) =>
                            <td>
                                {props.dataItem.reportLogoSelected ? 'Yes' : ''}
                            </td>
                        }
                    />
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='webRep' />}
            </div>
        )
    }

}

export default Logo