import React, { Component } from "react";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
import { Switch, NumericTextBox } from '@progress/kendo-react-inputs';
import axios from 'axios';

class SuperAdminSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customLandingData: [],
            customLanding: null,
            showHouseholdName: null,
            householdListShowMV: null,
            householdListSearchCapacity: null,
            showAdvisorViews: null,
            enableMFA: null,
            copyHouseholds: null,
            householdCount: 0,
            parameters: []
        }
        this.defaultCapacity = 2500;
    }

    componentDidMount = () => {
        this.getSuperAdminSettings();
        this.getCustomLandingData();
    }

    getSuperAdminSettings = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/superAdminSettings`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
        })
            .then(response => {
                let parameters = [];
                response.data.data.forEach(setting => {
                    if (setting.settingValue.toLowerCase() === 'true' || setting.settingValue.toLowerCase() === 'false'){
                        this.setState({[setting.settingName]: setting.settingValue.toLowerCase() === 'true' ? true : false})
                    }
                    else {
                        this.setState({[setting.settingName]: setting.settingValue})
                    }
                    parameters.push(setting.settingName);
                })
                this.setState({householdCount: response.data.householdCount, parameters: parameters})
            })
            .catch(err => console.log(err));
    }

    getCustomLandingData = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var customLandingData = []
        axios(`api/customLandingList`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
        })
            .then(response => {

                response.data.data.forEach(entry => {
                    customLandingData.push(entry.landingPageCode)
                })

            })
            .catch(err => console.log(err));

        this.setState({ customLandingData: customLandingData });
    }

    handleChange = (e,isNumeric) => {
        this.setState({[e.target.props.name]: e.target.value != null ? e.target.value : (isNumeric ? 0 : '')})
    }

    saveSettings = () => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        let params = {
            company: this.props.user.company
        }
        this.state.parameters.forEach(key => {
            params[key] = this.state[key];
        });

        axios(`api/superAdminSettings`, {
            method: 'POST',
            params: params,
            headers: headers
          })
          .then(response => {
            if (response.data.code===200){
                alert("Settings successfully saved")
            }
            else{
                alert("Unable to save settings")
            }
          })
          .catch(err => {
              console.error(err);
              alert("Unable to save settings")
          })
    }


    render = () => {
        return (
            <div>
                <br/>
                <label>Custom Landing Page:</label><br/>
                <DropDownList
                    data={this.state.customLandingData}
                    name='customLanding'
                    onChange={(e) => this.handleChange(e)}
                    //defaultValue={'None'}
                    value={this.state.customLanding}
                /><br/>
                <br/>
                <label>Show Household Name Instead of Household ID for the User Role:</label><br/>
                <Switch
                    name='showHouseholdName'
                    checked={this.state.showHouseholdName}
                    value={this.state.showHouseholdName}
                    onChange={(e) => this.handleChange(e)}
                />
                <br/>
                <br/>
                <label>Show MV Column on the Household Landing Page:</label><br/>
                <Switch
                    name='householdListShowMV'
                    checked={this.state.householdListShowMV}
                    value={this.state.householdListShowMV}
                    onChange={(e) => this.handleChange(e)}
                />
                <br/>
                <br/>  
                {this.state.householdListSearchCapacity != null && <><label>Household List Search Capacity:</label><br/>
                <NumericTextBox
                    name='householdListSearchCapacity'
                    checked={this.state.householdListSearchCapacity}
                    value={parseInt(this.state.householdListSearchCapacity)}
                    onChange={(e) => this.handleChange(e,true)}
                    required={true}
                    step={100}
                    min={0}
                /> ({this.state.householdCount} records total)
                 {/* - {(Math.round(this.state.householdCount * .2) < this.state.householdListSearchCapacity) ? 
                    ' recommend keeping at '+this.state.householdListSearchCapacity : 
                    (' recommend setting around '+Math.max((Math.round(this.state.householdCount * .2)),this.defaultCapacity)+ ' to optimize performance')}) */}
                <br/>
                <br/></>}
                <label>Show Advisor Views:</label><br/>
                <Switch
                    name='showAdvisorViews'
                    checked={this.state.showAdvisorViews}
                    value={this.state.showAdvisorViews}
                    onChange={(e) => this.handleChange(e)}
                />
                <br/>
                <br/>
                <label>Enable Multi-Factor Authentication:</label><br/>
                <Switch
                    name='enableMFA'
                    checked={this.state.enableMFA}
                    value={this.state.enableMFA}
                    onChange={(e) => this.handleChange(e)}
                />
                <br/>
                <br/>
                <label>Copy Households to Group Tables:</label><br/>
                <Switch
                    name='copyHouseholds'
                    checked={this.state.copyHouseholds}
                    value={this.state.copyHouseholds}
                    onChange={(e) => this.handleChange(e)}
                />
                <br/>
                <hr/>
                <br/><br/>
                <Button onClick={this.saveSettings}>Save Settings</Button>
            </div>
        );
    }

}

export default SuperAdminSettings;