export function checkPermission(obj,permission,ignoreSuperAdmin) {
    var allowed = permission == null || permission === '' // ignore check if no permission was provided
    if (!allowed){
        if (Array.isArray(permission)){
            console.log(permission)
            for (let j = 0; j < permission.length; j++){
                allowed = (obj.permissions !== null && obj.permissions.findIndex(i => i.toUpperCase() === String(permission[j]).toUpperCase()) > -1) // check to see if the user has a matching permission
                || (obj.permissions !== null && !ignoreSuperAdmin && obj.permissions.findIndex(i => i === '___1_Super_Admin') > -1) // bypass to allow all super admins
                
                if (allowed){break;}
            }
        }
        else{
            allowed = (obj.permissions !== null && obj.permissions.findIndex(i => i.toUpperCase() === String(permission).toUpperCase()) > -1) // check to see if the user has a matching permission
            || (obj.permissions !== null && !ignoreSuperAdmin && obj.permissions.findIndex(i => i === '___1_Super_Admin') > -1) // bypass to allow all super admins
        }
    }

    return allowed;
}

export function getRole(obj) {
    // pass in an object that has a permissions array 
    if (checkPermission(obj,"___2_Admin")) {
        return "admin";
    } else 
    if (checkPermission(obj,"___3_Advisor")) {
        return "advisor";
    } else {
        return "user";
    }
}

export function isSuperAdmin(obj) {
    return (checkPermission(obj,"___1_Super_Admin",true))
}

export function isAdmin(obj) {
    return (checkPermission(obj,"___2_Admin",true))
}

export function isAdvisor(obj) {
    return (checkPermission(obj,"___3_Advisor",true))
}

export function isUser(obj) {
    return (checkPermission(obj,"___4_User",true))
}