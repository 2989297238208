import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import {filterBy, process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import StagedActionRequest from './StagedActionRequest';
import SelectHHPopUp from './SelectHHPopUp';

class StagedDocuments extends Component {

  constructor(props) {
    super(props);
    this.state = {
        files:[],
        previewMappings:[],
        mappings:[],
        result:[],
        dataState:{skip:0, take:500, sort:[{field:'displayName', dir:'asc'}]},
        loading:false,
        selectedBulkKey:"",
        bulkKeys:[],
        bulkKeysResult:[],
        useHouseholdFolders:true,
        unmappedOnly:true,
        excludeExcluded:true,
        TotalDocuments:0,
        UnmappedDocuments:0 ,
        ExcludedDocuments:0,
        householdsAll:[],
        refreshText:'',
        vaultFolderList: [],
        descriptions: []
    }

    this.getStagedDocuments=this.getStagedDocuments.bind(this);
    this.getBulkKeyList=this.getBulkKeyList.bind(this);
    this.clearState=this.clearState.bind(this);
    this.createMapCell = this.createMapCell.bind(this);
    this.excludeDocument = this.excludeDocument.bind(this);
    this.getHouseholdsAll = this.getHouseholdsAll.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount(){

    this.getBulkKeyList();
    this.getHouseholdsAll();
    this.getVaultFolderList();
    this.getCommonFileDescriptions();
    
  }

  getVaultFolderList() {
    var vaultFolderList = []

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company
    }
    axios.post('api/commonVaultFolderListGet', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        console.log(response.data)
        if (response.data.vaultFolders.length > 0){
          for (let dataItem of response.data.vaultFolders){
            vaultFolderList = vaultFolderList.concat(dataItem.folder)
          }
          this.setState({ vaultFolderList: vaultFolderList});
        }
        else
          this.setState({ vaultFolderList: vaultFolderList});
      }
    }).catch(err => {
      console.log(err);
    });
  }

  getCommonFileDescriptions() {
    var descriptions = []

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company
    }
    axios.post('api/commonFileDescriptionsGet', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        console.log(response.data)
        if (response.data.descriptions.length > 0){
          for (let dataItem of response.data.descriptions){
            descriptions = descriptions.concat(dataItem.description)
          }
          this.setState({ descriptions: descriptions});
        }
        else
          this.setState({ descriptions: descriptions});
      }
    }).catch(err => {
      console.log(err);
    });
  }

  clearState(){
    this.setState({
        files:[],
        previewMappings:[]
    });
  };

  getHouseholdsAll(){
    
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
    axios.get("api/households?company="+this.props.user.company, {headers}).then(response => {
      if(response.data.code===200){
        this.setState({householdsAll:response.data.households});
      }
      else{
        alert("An error occured while fetching the Household list.");
        this.setState({householdsAll:[]});
      }
    }).catch(error => console.log(error));

  }


  onAdd = (e)=>{
      this.setState({files:e.newState});
  }

  onRemove = (e)=>{
    this.setState({files:e.newState});
  }

  onStatusChange = (e)=>{
      if(e.response.response.code===200){
        e.newState[0].progress=100;
        e.newState[0].status=4;
        this.setState({previewMappings:e.response.response.mappings});
      }
      else{
        e.newState[0].progress=0;
        e.newState[0].status=0;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({files:e.newState});
  }

//get the bulk key list
getBulkKeyList(){

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company
    }
    axios.post('api/stagedBulkKeysGet', payload, {headers}).then(response=>{
      if(response.data.code===200){
        
          if(response.data.bulkKeys.length>0)
            this.setState({bulkKeys:response.data.bulkKeys, bulkKeysResult:response.data.bulkKeys, selectedBulkKey:[]});
          else
            this.setState({bulkKeys:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }




  refresh(){
    this.getStagedDocuments();
  }

  
  getStagedDocuments(){

    this.setState({refreshText:'Loading...'});

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    if (this.state.selectedBulkKey !==  undefined)
    {
        this.setState({loading:true, mappings:[], result:[]});
        var payload ={
          company:this.props.user.company,
          bulkKey:this.state.selectedBulkKey.bulkKey,
          useHousehold:this.state.useHouseholdFolders,
          unmappedOnly:this.state.unmappedOnly,
          excludeExcluded:this.state.excludeExcluded
        }
        axios.post('api/stagedDocumentsGet' , payload , {headers}).then(response=>{

          if (response.data.mappings.length>0)
          {
            response.data.mappings.splice(0,1);
          }
          var result = process(response.data.mappings, this.state.dataState);
          this.setState({
              mappings:response.data.mappings
              ,result:result
              ,loading:false
              ,TotalDocuments:response.data.TotalDocuments
              ,UnmappedDocuments:response.data.UnmappedDocuments 
              ,ExcludedDocuments:response.data.ExcludedDocuments
              ,refreshText:''
            });

          

        }).catch(err=>{
          console.log(err);
          this.setState({mappings:[], loading:false, refreshText:''});
        });
    }

  }

  
  

  _export;
  export = () =>{
    this._export.save();
  }

  

  changeDataState = (e)=>{
    var newData = process(this.state.mappings, e.data);
    this.setState({dataState:e.data, result:newData});
  }

  onBulkKeyChange = (e) =>{
    if (!this.isEmpty(e.target.value))
    {
        this.setState({selectedBulkKey:e.target.value}, () => {
            this.getStagedDocuments();
        });        
    }
  
}

//check if an object is empty
isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
};

onClick_UseHH = () => {
    this.setState({useHouseholdFolders:!this.state.useHouseholdFolders}, () => {
        this.getStagedDocuments();
    });        
  };

  onClick_unmappedOnly = () => {
    this.setState({unmappedOnly:!this.state.unmappedOnly}, () => {
        this.getStagedDocuments();
    });        
  };
  onClick_excludeExcluded = () => {
    this.setState({excludeExcluded:!this.state.excludeExcluded}, () => {
        this.getStagedDocuments();
    });        
  };

  

  createMapCell(cell){
    return(
      <td>
        <div>
        {cell.dataItem.ExcludeDocument===false   && <Button className='docButton' icon='delete' onClick={(e)=>this.excludeDocument(cell.dataItem.bulkKey, cell.dataItem.displayName, cell.dataItem.ExcludeDocument)}>Exclude</Button> }

        {cell.dataItem.ExcludeDocument===true   && <Button className='docButton' icon='delete' onClick={(e)=>this.excludeDocument(cell.dataItem.bulkKey, cell.dataItem.displayName, cell.dataItem.ExcludeDocument)}>Include</Button> }

        <SelectHHPopUp user={this.props.user} refresh={this.refresh} idToken={this.props.idToken} company={this.props.user.company} households={this.state.householdsAll} bulkKey={cell.dataItem.bulkKey} displayName={cell.dataItem.displayName} button={<Button className='docButton'>Change Mapping</Button>}/>
        </div>
      </td>
    );
  }
  

  excludeDocument(bulkKey,displayName,ExcludeDocument ){
    var conf="";
    
    if(ExcludeDocument===true)
    {
        conf =  window.confirm("Are you sure you want to include "+displayName + "?");
    }
    else
    {
        conf =  window.confirm("Are you sure you want to exclude "+displayName + "?");
    }


    if(conf){
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload ={
        company: this.props.user.company,
        bulkKey: bulkKey,
        displayName:displayName
      }

      axios.post("api/excludeStagedDocument", payload, {headers}).then(response=>{
        if(response.data.code===200){
          this.getStagedDocuments();
        }
        else{
          alert("An error has occurred. Please try again.");
        }
      }).catch(err=>console.log(err));
    }
  }


  render() {
    return(
        <div>
          <ExcelExport data={this.state.mappings} fileName={'BulkStagingMapping_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
            <ExcelExportColumn field='displayName' title='File Name'/>          
            <ExcelExportColumn field='MappingDisplay' title='Document Vault Location'/>
            <ExcelExportColumn field='mappingKey' title='Document Mapping Key'/>
            <ExcelExportColumn field='ExcludeDocument' title='Exclude Document Setting'/>
          </ExcelExport>

          


          <label className='bgText'>  BulkUpload: </label>
                            

                            <ComboBox
                                style={{width:'30vw'}}
                                data={this.state.bulkKeysResult}
                                textField='textField'
                                onChange={this.onBulkKeyChange}
                                value={this.state.selectedBulkKey} 
                                filterable={true}
                                allowCustom={false}
                                
                                onFilterChange={(e)=>{
                                    this.setState({bulkKeysResult:filterBy(this.state.bulkKeys, e.filter)});
                                }}
                            />
            
            <strong> Use Household Folders:</strong><input type="checkbox" checked={this.state.useHouseholdFolders} onChange={this.onClick_UseHH}/>

            <strong> Unmapped Only:</strong><input type="checkbox" checked={this.state.unmappedOnly} onChange={this.onClick_unmappedOnly}/>

            <strong> Hide Excluded:</strong><input type="checkbox" checked={this.state.excludeExcluded} onChange={this.onClick_excludeExcluded}/>
            
            <div className='houseMappingList'>
                <Grid data={this.state.result}
                 sortable={true}
                 filterable={true}
                 pageable={true}
                 style={{height:'600px'}}
                 onDataStateChange={this.changeDataState}
                   {...this.state.dataState}
                   >
                

                <GridToolbar>

              {this.state.selectedBulkKey.bulkKey !== undefined && 
              <div>

              <Button icon='refresh' title='Refresh' onClick={(e)=>this.getStagedDocuments()}>{this.state.refreshText}</Button>

              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>

              <StagedActionRequest vaultFolderList={this.state.vaultFolderList} descriptions={this.state.descriptions} UnmappedDocuments={this.state.UnmappedDocuments}  useHouseholdFolders={this.state.useHouseholdFolders} selectedBulkKey={this.state.selectedBulkKey}  idToken={this.props.idToken} company={this.props.user.company} user={this.props.user.user} button={<Button className="docButton" icon="upload"> Request Document Action</Button>} />

              <strong>Total Documents:{this.state.TotalDocuments}</strong>
              {this.state.UnmappedDocuments!==0 &&  <strong  style={{color:'red'}} >  Unmapped Documents:{this.state.UnmappedDocuments}</strong>}
              {this.state.ExcludedDocuments!==0 && <strong>  Excluded Documents:{this.state.ExcludedDocuments}</strong>}
              </div>
              }


            </GridToolbar>
                    <GridColumn field='displayName' title='File Name'/>
                    <GridColumn field='MappingDisplay' title='Document Vault Location'/>
                    <GridColumn field='mappingKey' title='Document Mapping Key'/>
                    <GridColumn filterCell={(props)=>{return(<></>)}} field='ExcludeDocument' title='Exclude'/>
                    <GridColumn headerClassName='gridHeader' filterCell={(props)=>{return(<></>)}} cell={(props) => this.createMapCell(props)} />
                </Grid>
                {this.state.loading && <GridLoading />}
            </div>
        </div>
    );
  }

}

export default StagedDocuments;