import React, { Component } from "react";
import GrowthHundred from '../components/GrowthHundred';
import {DatePicker} from '@progress/kendo-react-dateinputs';


class GrowthDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      perfType:this.props.compSettings.defaultFees || 'net',
      chartType:'line',
      fromDate: this.getPeriod(),
      toDate:new Date()
    }

    this.getPeriod = this.getPeriod.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidUpdate(){
    if(this.props.portfolio.consolidated && this.state.chartType==='column')
      this.setState({chartType:'line'});
  }

  getPeriod(){
    var period = this.props.compSettings.growthPeriod;
    var date = new Date();

    if(period==="mtd")
      date = new Date(date.getFullYear(), date.getMonth(), 1);
    else if(period==='qtd'){
      var quarter = Math.floor((date.getMonth() / 3));
      date = new Date(date.getFullYear(), quarter * 3, 1);
    }
    else if(period==='ytd')
      date = new Date(date.getFullYear()-1, 11, 31);
    else if(period==='6m')
      date = new Date(date.getFullYear(), date.getMonth()-6, date.getDate());
    else if(period==='12m')
      date = new Date(date.getFullYear()-1, date.getMonth(), date.getDate());
    else date = null;

    return date;
  }

  onFieldChange(e, field){
    this.setState({[field]:e.target.value});
  }

  setFromDate = (date)=>{
    this.setState({fromDate:date});
  }

  firstUpper(s){
    return s.charAt(0).toUpperCase()+s.slice(1);
  }

  render() {
    return(
        <div className='growthDashboard'>
          {this.props.compSettings.enableFeeSelect && <><label>Type: </label><select value={this.state.perfType} onChange={(e)=>this.setState({perfType:e.target.value})} style={{marginRight:'10px'}}>
            <option value='net'>Net</option>
            <option value='gross'>Gross</option>
          </select></>}
          {!this.props.compSettings.enableFeeSelect && <label style={{marginRight:'10px'}}>Type: {this.firstUpper(this.state.perfType)}</label>}
          <label>Chart: </label><select onChange={(e)=>this.setState({chartType:e.target.value})} value={this.state.chartType} disabled={this.props.portfolio.consolidated} style={{marginRight:'10px'}}>
            <option value='line'>Line</option>
            <option value='column'>Column</option>
          </select>
          <label style={{paddingLeft:'10px'}}><strong>From: </strong></label><DatePicker value={this.state.fromDate} max={new Date()} onChange={(e)=>this.onFieldChange(e, 'fromDate')}/>
          <label style={{paddingLeft:'10px'}}><strong>To: </strong></label><DatePicker value={this.state.toDate} max={new Date()} onChange={(e)=>this.onFieldChange(e, 'toDate')}/>
          <GrowthHundred idToken={this.props.idToken} perfType={this.state.perfType} fromDate={this.state.fromDate} toDate={this.state.toDate} setFromDate={this.setFromDate}chartType={this.state.chartType} user={this.props.user} portfolio={this.props.portfolio} compSettings={this.props.compSettings}/>
        </div>
    );
  }

}

export default GrowthDashboard;