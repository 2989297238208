import React from "react";
import { Input } from '@progress/kendo-react-inputs';

const InputTextField = ({ name, label, defaultValue, textFieldWidth, valid, defaultSet, value, _handleChange }) =>

    (
        <div>
            {defaultSet && <strong>{label}: </strong>}
            {!defaultSet && <label>{label}: </label>}
            <br></br>
            <Input
                type="text"
                name={name}
                defaultValue={defaultValue}
                autoComplete="off"
                valid={valid}
                value={value}
                onChange={_handleChange}
                style={{ width: textFieldWidth}}
            />
            <br></br>
        </div>
    );
export default InputTextField;