import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { Input } from '@progress/kendo-react-inputs';

class MapUserHHPopUp extends Component {

  constructor(props) {
    super(props);

    this.state = {
        households:[],
        result:[],// result is used for filtered data
        selectedHousehold:[],
        emailToMap: ''
    }

    this.clearState = this.clearState.bind(this);
    this.createMapping = this.createMapping.bind(this);
  }

  componentDidMount(){
    this.getHouseholdsAll();
  }

  getHouseholdsAll(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
    axios.get("api/households?company="+this.props.user.company, {headers}).then(response => {
      if(response.data.code===200){
        console.log(response.data.households)
        this.setState({households:response.data.households, result:response.data.households});
      }
      else{
        alert("An error occured while fetching the Household list.");
        this.setState({households:[], result: []});
      }
    }).catch(error => console.log(error));

  }

  createMapping(callback){

    var validityMessage = this.validityTest()

    if (validityMessage) {
      alert(validityMessage)
      callback(false)
    }
    else{
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var payload = {
          company:this.props.user.company,
          email:this.state.emailToMap,
          households:this.state.selectedHousehold
      }

      axios.post('api/addHouseholdUserMapping', payload, {headers}).then(response=>{
        if(response.data.code===200){
          alert("Household User mapping was added successfully.");
          callback(true)
        }
        else{
          alert("An error occured while attempting to add Household User mapping.");
          callback(true)
        }
      }).catch(err=>{
          console.log(err);
          alert("An error occured while attempting to add Household User mapping.");
          callback(true)
      });
    }
  }

  onHouseholdsChange = (e)=>{
    this.setState({selectedHousehold:e.target.value});
  }

  onEmailChange = (e)=>{
    this.setState({emailToMap:e.target.value});
  }

  clearState(){
    this.setState({result:this.state.households, selectedHousehold:[]});
  }

  validityTest() {
    var errorString = ''
    if (!emailRegex.test(this.state.emailToMap)) {
        errorString = errorString.concat('E-mail Address is in an invalid format.\n')
    }
    else if  (!this.state.emailToMap){
      errorString = errorString.concat('E-mail must not be blank.\n')
    }
    if (this.state.selectedHousehold.length < 1){
      errorString = errorString.concat('Select at least one household.\n')
    }

    return errorString

  }

  render() {
      return (
        <Popup closeOnDocumentClick={false} trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
          {close => (
            <div className="modal">

                <Button className="close" icon="close" onClick={close} />


                <div className="header" align="left">
                Add User Mapping
                </div>

                <div className="content">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Household:</strong>
                                </td>
                                <td>
                                    <MultiSelect
                                        style={{width:'100%'}}
                                        data={this.state.result}
                                        textField='household'
                                        onChange={this.onHouseholdsChange}
                                        filterable={true}
                                        allowCustom={false}
                                        onFilterChange={(e)=>{
                                            this.setState({result:filterBy(this.state.households, e.filter)});
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Email:</strong>
                                </td>
                                <td>
                                    <Input
                                        style={{ width: '100%' }}
                                        type="text"
                                        key='email'
                                        name='email'
                                        onChange={this.onEmailChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>            
                </div>

                <div className="actions">

                    <Button className="button" onClick={() => {close()}}>
                        Close
                    </Button>

                    <Button className="upload" onClick={(event) => {
                        this.createMapping((success) => {
                          if (success){
                            this.clearState();
                            this.props.refresh();
                            close();     
                          }
                        });                   
                        }}>
                        Confirm
                    </Button>

                </div>

            </div>
          )}
        </Popup>

      );
  };

}

const emailRegex= RegExp( process.env.REACT_APP_EMAIL_REGEX );

export default MapUserHHPopUp;