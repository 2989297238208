import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import EditWidget from "./EditWidget";
import axios from 'axios';

class DetailRow extends Component {

    removeWidget = (e) => {
        var widget = this.props.dataItem;
        const confirm = window.confirm("Are you sure you want to remove " + widget.name + "?");

        if (confirm === true) {
            var headers = { 'Authorization': 'Bearer '.concat(widget.idToken) };
            var payload = {
                widgetId: widget.widgetId,
                company: widget.company,
            };

            axios.post('api/deleteWidget', payload, { headers }).then(response => {
                if (response.data.code !== 200) {
                    alert("Error: Widget could not be removed.");
                }
                else {
                    this.props.dataItem.refreshList();
                }
            }).catch(err => { alert("An error occured when deleting the widget."); console.log(err) });
        }
    }

    changeActivate = (e) => {
        var widget = this.props.dataItem;
        var msg = "activate";

        if (widget.active)
            msg = "deactivate";

        const confirm = window.confirm("Are you sure you want to " + msg + " this widget?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(widget.idToken) };
            var payload = {
                widgetId: widget.widgetId,
                name: widget.name,
                description: widget.description,
                configuration: widget.configuration,
                types: widget.types,
                module: widget.module,
                order: widget.order,
                active: !widget.active,
                deleted: false,
                company: widget.company,
            };

            axios.post('api/updateWidget', payload, { headers }).then(response => {
                if (response.data.code !== 200) {
                    alert("Error: Widget's active status could not be changed.");
                }
                else {
                    widget.changeActivate(widget.widgetId, !widget.active);
                    widget.refreshList();
                }
            });
        }
    }

    render() {
        return (
            <section className='userActions'>
                <strong>Actions: </strong>
                {this.props.dataItem.active && <Button onClick={event => this.changeActivate(event)}>Deactivate</Button>}
                {!this.props.dataItem.active && <Button onClick={event => this.changeActivate(event)}>Activate</Button>}
                <Button onClick={event => this.removeWidget(event)}>Remove</Button>
                <EditWidget widget={this.props.dataItem} button={<Button>Edit</Button>} />
            </section>
        );
    }
}

export default DetailRow;