import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class BlendedIndexPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            BlendID: '',
            EntityDescription: '',
            DateCreated: '',
            IndexEntityCode: '',
            Weighting: 0,
            AsOfDate: '',
            initialLoad: true,
        }

    }

    onFieldChange = (e, field) => {
        this.setState({ [field]: e.target.value });
    }


    getEditField = (field) => {
        if (this.props.blendedIndexEdit && this.state.initialLoad) {
            this.setState({ [field]: this.props.blendedIndexEdit[field], initialLoad: false });
            return this.props.blendedIndexEdit[field];
        }
        else {
            return '';
        }
    }

    uploadBlendedIndex = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            BlendID: this.state.BlendID,
            EntityDescription: this.state.EntityDescription,
            DateCreated: this.state.DateCreated,
            IndexEntityCode: this.state.IndexEntityCode,
            Weighting: this.state.Weighting,
            AsOfDate: this.state.AsOfDate,
            company: this.props.user.company,
        }
        //add id field if editing template
        if (this.props.blendedIndexEdit) {
            payload.blendedIndexEdit = true;
            payload.originalBlendID = this.props.blendedIndexEdit.BlendID
            payload.originalIndexEntityCode = this.props.blendedIndexEdit.IndexEntityCode
        }

        axios.post("api/uploadBlendedIndex", payload, { headers }).then(response => {
            if (response.data.code === 301) {
                alert("Error: A blended index with the same Blend ID already exists!");
            }
            else if (response.data.code === 302) {
                alert("Error: Unable to use this Blend ID.");
            }
            else {
                if (response.data.code === 200) {
                    alert("Blended Index created successfully.");
                }
                else if (response.data.code === 201) {
                    alert("Blended Index updated successfully.");
                }
                else {
                    alert("An error occured with the blended index.");
                }
                this.props.uploadMethod();
                this.clearState();
                callback();
            }
        }).catch(err => { alert("An error occured with the template."); console.log(err); this.props.uploadMethod(); this.clearState(); callback(); });
    }

    clearState = () => {
        this.setState({
            BlendID: '',
            EntityDescription: '',
            DateCreated: '',
            IndexEntityCode: '',
            Weighting: 0,
            AsOfDate: '',
            initialLoad: true
        })
    }


    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">

                        <Button className="close" icon="close" onClick={(e) => { close(); }} />


                        <div className="header" align="left">
                            {this.props.blendedIndexEdit ? 'Edit Blended Index' : 'New Blended Index'}
                        </div>

                        <div className="content">
                            <div>
                                <label>Blend ID: </label>
                                <select value={this.state.BlendID || this.getEditField('BlendID')} onChange={(e) => {
                                    let index = this.props.blendedIndexes.findIndex(file => file.BlendID === e.target.value);
                                    if (index > -1) {
                                        let file = this.props.blendedIndexes[index];
                                        this.setState({
                                            BlendID: e.target.value,
                                            EntityDescription: file.EntityDescription,
                                            DateCreated: file.DateCreated
                                        });
                                    } else {
                                        this.setState({
                                            BlendID: '',
                                            EntityDescription: '',
                                            DateCreated: ''
                                        });
                                    }
                                }}>
                                    <option value="">Select Existing Blend ID</option>
                                    {this.props.blendedIndexes.map(file => (
                                        <option key={file.BlendID} value={file.BlendID}>{file.BlendID}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Or create new: </label>
                                <input type="text" value={this.state.BlendID || this.getEditField('BlendID')} onChange={(e) => this.onFieldChange(e, 'BlendID')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Description: </label><br />
                                <textarea name="EntityDescription" rows="5" style={{ width: '95%' }} value={this.state.EntityDescription || this.getEditField('EntityDescription')} onChange={(e) => this.onFieldChange(e, 'EntityDescription')} placeholder={""} ></textarea>
                            </div>
                            <br /><br />
                            <div>
                                <label>Date Created: </label>
                                <input type="date" value={(this.state.DateCreated === '' || typeof (this.state.DateCreated) !== 'object' ? this.state.DateCreated : new Date(this.state.DateCreated).toISOString().substring(0, 10)) || this.getEditField('DateCreated')} onChange={(e) => this.onFieldChange(e, 'DateCreated')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Index Entity Code: </label>
                                <input type="text" value={this.state.IndexEntityCode || this.getEditField('IndexEntityCode')} onChange={(e) => this.onFieldChange(e, 'IndexEntityCode')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Weighting: </label>
                                <input type="number" value={this.state.Weighting || this.getEditField('Weighting')} onChange={(e) => this.onFieldChange(e, 'Weighting')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>As of Date: </label>
                                <input type="date" value={(this.state.AsOfDate === '' || typeof (this.state.AsOfDate) !== 'object' ? this.state.AsOfDate : new Date(this.state.AsOfDate).toISOString().substring(0, 10)) || this.getEditField('AsOfDate')} onChange={(e) => this.onFieldChange(e, 'AsOfDate')} placeholder={""} />
                            </div>
                            <br /><br />
                        </div>

                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>

                            <Button
                                className="upload"
                                onClick={(event) => { this.uploadBlendedIndex(close) }}>
                                {this.props.blendedIndexEdit ? 'Update' : 'Create'}
                            </Button>
                        </div>

                    </div>
                )}
            </Popup>

        );
    };
}

export default BlendedIndexPopup;