import React, { Component } from "react";
import {Chart, ChartTooltip, ChartSeries, 
  ChartSeriesItem, ChartTitle, ChartCategoryAxis, 
  ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartLegend} from '@progress/kendo-react-charts';
import axios from 'axios';
import 'hammerjs';
import GridLoading from '../../Loaders/GridLoading';

class MarketValueGraph extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data:[],
      portfolio:this.props.portfolio,
      date:this.props.date,
      loading:false
    }

    this.getMarketValues = this.getMarketValues.bind(this);
    this.getLineColor = this.getLineColor.bind(this);

  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio || this.props.date!==this.state.date){
      var update = this.state.date!=='';
      this.setState({...this.props}, ()=>{if(update){this.getMarketValues()}});
    }
  }

  componentDidMount(){
    this.getStyleSettings(() => {
      this.getMarketValues();
    });
    
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'showConsolidated',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {

      this.setState({styleSettings: response.data.styleSettings}, () => {
        cb()
      })
    })
    .catch(err => {
        console.error(err);
    })
}

  getMarketValues(){
    this.setState({loading:true});

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var port = this.state.portfolio.account;

    if(this.props.portfolio.consolidated || (this.props.portfolio.default && this.state.styleSettings.showConsolidated))
      port = undefined;

    var unmapped = '';
    if(this.props.user.unmapped)
      unmapped = 'true'

    var payload = {
      company:this.props.user.company,
      port:port,
      household:this.props.user.defaultAcct.household,
      date:this.state.date,
      user:this.props.user.user,
      unmapped:unmapped
    }

    axios.post('api/marketValue' , payload, {headers}).then(response=>{
      if(response.data.code===200){
        this.setState({data:response.data.data, loading:false});
      }
      else{
        this.setState({loading:false});
        alert('Could not retrieve market value data.');
      }
    }).catch(err=>{
      this.setState({loading:false});
      console.log(err);
    });
  }

  renderTooltip = ({point})=>{
    if(point!==undefined){
      var mvVal = point.dataItem.marketValue;
      if(mvVal!==undefined)
        mvVal = mvVal.toFixed(2);
      return (
          <>
            <div><strong>{point.dataItem.displayName}</strong></div>
            <div><strong>{point.category.toLocaleDateString("en-US", {month:'long', year:'numeric'})}</strong></div>
            <div>Market Value: ${mvVal}</div>
          </>);
    }
    else{
      return(<div></div>);
    }
  }

  getLineColor(point){
    if(Array.isArray(point) && point.length>0){
      return point[0].color;
    }
    else{
      return point.color;
    }
  }

  render() {
    var graphData = this.state.data.slice();
    return(
        <div className='marketValueGraph'>
        <Chart>
          <ChartTitle text="Market Value"/>
          <ChartLegend position="bottom" orientation='vertical'/>
          <ChartTooltip render={this.renderTooltip}/>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem title={{text:"Date"}} type='date' maxDivisions={20} baseUnit='months' axisCrossingValue={0} justified={true} />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem title={{ text: "Market Value" }} labels={{format:'c'}}/>
        </ChartValueAxis>
            <ChartSeries>
              {this.props.graph==='column' ? 
              graphData.map((point, i)=>(
                <ChartSeriesItem key={i} type='column' stack={this.props.stack} name={point[0] && point[0].displayName} color={this.getLineColor(point)} field='marketValue' overlay={{gradient:'none'}} categoryField='date' data={point} />
              )) : null}
              {this.props.graph==='area' ? 
              graphData.map((point, i)=>(
                <ChartSeriesItem key={i} type='area' stack={true} name={point[0].displayName} color={this.getLineColor(point)} field='marketValue' categoryField='date' data={point}/>
              )) : null}
              {this.props.graph==='line' ? 
              graphData.map((point, i)=>(
                <ChartSeriesItem key={i} type='line' color={this.getLineColor(point)} field='marketValue' overlay={{gradient:'none'}} categoryField='date' data={point}/>
              )) : null}
            </ChartSeries>
        </Chart>
        {this.state.loading && <GridLoading />}
        </div>
    );
  }

}

export default MarketValueGraph;