import React, { Component } from "react";
import { DropDownList } from '@progress/kendo-react-dropdowns';


class FontDropDown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      default: { text: "-Select a font-" },
      fonts:[{ text: "serif" }, { text: "satisfy" }, { text: "sans-serif" }, { text: "fantasy" }, { text: "monospace" }, { text: "cursive" }],
    }

  }

  valueRender = (element, value) => {
    if (!value) {
        return element;
    }

    const children = [ <span style={{fontFamily:value.text}}>{element.props.children}</span>];

    return React.cloneElement(element, { ...element.props }, children);
  }

  itemRender = (li, itemProps) => {
    const itemChildren = <span style={{ fontFamily: itemProps.dataItem.text}}>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  render() {
    return (
      <div>
        <label>{this.props.label}</label>
        <DropDownList
          data={this.state.fonts}
          textField="text"
          defaultItem={{text:this.props.default}}
          itemRender={this.itemRender}
          valueRender={this.valueRender}
          onChange={this.props.onChange}
          popupSettings={style}
        />
      </div>
    );
  }
}


const style = {
  className: "top",
};
export default FontDropDown;