import React, { Component } from "react";
import { DropDownList } from '@progress/kendo-react-dropdowns';


class TabDropDown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      default: props.default,
      items:props.items,
      value:''
    }

    this.getValue = this.getValue.bind(this);
  }

  onChange = (e)=>{
    this.setState({value:e.target.value});
    this.props.onChange(e);
  }

  getValue(){
    var index = this.props.items.findIndex(x=>{
      if(this.props.value)
        return x.value===this.props.value || x.value===this.props.value.value;
      else
        return x.value===this.props.value;
    });

    if(index>=0)
      return this.props.items[index];
    else
      return this.props.default;
  }

  render() {
    return (
      <>
        <DropDownList
          data={this.state.items}
          textField="text"
          value={this.getValue()}
          defaultItem={this.state.default}
          onChange={this.onChange}
          popupSettings={style}
          style={{width: this.props.width}}
        />
      </>
    );
  }
}


const style = {
  className: "top",
};
export default TabDropDown;