import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class SecurityClassificationSplitPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            SecurityID: '',
            AssetClass: '',
            Sector: '',
            IndustryGroup: '',
            NewSecurityID: '',
            Percentage: 0,
            AsOfDate: '',
            initialLoad: true
        }

    }

    onFieldChange = (e, field) => {
        this.setState({ [field]: e.target.value });
    }

    getEditField = (field) => {
        if (this.props.securityClassificationSplitEdit && this.state.initialLoad) {
            this.setState({ [field]: this.props.securityClassificationSplitEdit[field], initialLoad: false });
            return this.props.securityClassificationSplitEdit[field];
        }
        else {
            return '';
        }
    }

    uploadSecurityClassificationSplit = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            SecurityID: this.state.SecurityID,
            AssetClass: this.state.AssetClass,
            Sector: this.state.Sector,
            IndustryGroup: this.state.IndustryGroup,
            NewSecurityID: this.state.NewSecurityID,
            Percentage: this.state.Percentage,
            AsOfDate: this.state.AsOfDate,
            company: this.props.user.company,
        }
        //add id field if editing template
        if (this.props.securityClassificationSplitEdit) {
            payload.securityClassificationSplitEdit = true;
            payload.originalID = this.props.securityClassificationSplitEdit.SecurityClassificationSplitID
        }

        axios.post("api/uploadSecurityClassificationSplit", payload, { headers }).then(response => {
            if (response.data.code === 200) {
                alert("Security classification split created successfully.");
            }
            else if (response.data.code === 201) {
                alert("Security classification split updated successfully.");
            }
            else {
                alert("An error occured with the security classification split.");
            }
            this.props.uploadMethod();
            this.clearState();
            callback();
        }).catch(err => { alert("An error occured with the template."); console.log(err); this.props.uploadMethod(); this.clearState(); callback(); });
    }

    clearState = () => {
        this.setState({
            SecurityID: '',
            AssetClass: '',
            Sector: '',
            IndustryGroup: '',
            NewSecurityID: '',
            Percentage: 0,
            AsOfDate: '',
            initialLoad: true
        })
    }

    render() {
        return (
            <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">

                        <Button className="close" icon="close" onClick={(e) => { close(); }} />


                        <div className="header" align="left">
                            {this.props.securityClassificationSplitEdit ? 'Edit Security Classification Split' : 'New Security Classification Split'}
                        </div>

                        <div className="content">
                            <div>
                                <label>Security ID: </label>
                                <input type="text" value={this.state.SecurityID || this.getEditField('SecurityID')} onChange={(e) => this.onFieldChange(e, 'SecurityID')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Asset Class: </label>
                                <input type="text" value={this.state.AssetClass || this.getEditField('AssetClass')} onChange={(e) => this.onFieldChange(e, 'AssetClass')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Sector: </label>
                                <input type="text" value={this.state.Sector || this.getEditField('Sector')} onChange={(e) => this.onFieldChange(e, 'Sector')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Industry Group: </label>
                                <input type="text" value={this.state.IndustryGroup || this.getEditField('IndustryGroup')} onChange={(e) => this.onFieldChange(e, 'IndustryGroup')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>New Security ID: </label>
                                <input type="text" value={this.state.NewSecurityID || this.getEditField('NewSecurityID')} onChange={(e) => this.onFieldChange(e, 'NewSecurityID')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>Percentage (%): </label>
                                <input type="number" value={this.state.Percentage || this.getEditField('Percentage')} onChange={(e) => this.onFieldChange(e, 'Percentage')} placeholder={""} />
                            </div>
                            <br /><br />
                            <div>
                                <label>As of Date: </label>
                                <input type="date" value={(this.state.AsOfDate === '' || typeof (this.state.AsOfDate) !== 'object' ? this.state.AsOfDate : new Date(this.state.AsOfDate).toISOString().substring(0, 10)) || this.getEditField('AsOfDate')} onChange={(e) => this.onFieldChange(e, 'AsOfDate')} placeholder={""} />
                            </div>
                        </div>

                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => { close(); }}>
                                Cancel
                            </Button>

                            <Button
                                className="upload"
                                onClick={(event) => { this.uploadSecurityClassificationSplit(close) }}>
                                {this.props.securityClassificationSplitEdit ? 'Update' : 'Create'}
                            </Button>
                        </div>

                    </div>
                )}
            </Popup>

        );
    };
}

export default SecurityClassificationSplitPopup;