import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';

class AddPermissions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            permission: '',
            description: '',
            api: '',
            method: '',
            isGlobal: false
        }
    }

    onChangePermission = (e) => {
        this.setState({ permission: e.target.value });
    }

    onChangeDescription = (e) => {
        this.setState({ description: e.target.value });
    }

    onChangeAPI = (e) => {
        this.setState({ api: e.target.value });
    }

    onChangeMethod = (e) => {
        this.setState({ method: e.target.value });
    }

    onChangeIsGlobal = (e) => {
        this.setState({ isGlobal: !this.state.isGlobal });
    }

    createPermission = (callback) => {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload = {
            company: this.props.company,
            permission: this.state.permission,
            description: this.state.description,
            api: this.state.api,
            method: this.state.method,
            order: this.props.newOrder,
            active: true,
            isGlobal: this.state.isGlobal
        }
        axios.post("api/createPermission", payload, { headers }).then(response => {
            if (response.data.code === 200) {
                // alert('Permission created successfully');
                callback();
            }
            else {
                alert(response.data.error);
                callback("ERROR");
            }
        }).catch(err => {
            alert("An error has occured");
            console.log(err);
            callback("ERROR");
        });
    }

    clearState = () => {
        this.setState({
            permission: '',
            description: '',
            api: '',
            method: '',
            isGlobal: false
        });
    }

    render() {
        return (
            <Popup trigger={this.props.button} onClose={(e) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
                {close => (
                    <div className="modal">
                        <Button className="close" icon="close" onClick={close} />
                        <div className="header" align="left">
                            Create New Permission
                        </div>
                        <div className="content">
                            <label>Permission: </label><Input onChange={this.onChangePermission} />
                        </div>
                        <div className="content">
                            <label>Description: </label><Input name="description" onChange={this.onChangeDescription} />
                        </div>
                        <div className="content">
                            <label>API: </label><Input name="api" onChange={this.onChangeAPI} />
                        </div>
                        <div className="content">
                            <label>Method: </label><Input name="method" onChange={this.onChangeMethod} />
                        </div>
                        {this.props.company === 'cssi' && <div style={{marginTop: '10px'}}>
                            <label>Global: </label><input type="checkbox" name="isGlobal" onChange={this.onChangeIsGlobal} value={this.state.isGlobal} />
                        </div>}
                        <br />
                        <div className="actions">
                            <Button
                                className="button"
                                onClick={() => {
                                    close();
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                className="upload"
                                onClick={(e) => {
                                    this.createPermission((err) => {
                                        if (!err) {
                                            this.props.refreshPermissions();
                                            close();
                                        }
                                    });
                                }}
                            >
                                Create Permission
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>
        );
    };

}

export default AddPermissions;