import React, { Component } from "react";
import Popup from "reactjs-popup";
import axios from 'axios';
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { format } from 'date-fns'
import { Grid, Row, Col } from "react-flexbox-grid";
import { Input, Switch } from '@progress/kendo-react-inputs';
import InputTextField from './InputTextField';
import InputDate from './InputDate';
import InputBoolean from './InputBoolean';
import InputDropdown from './InputDropdown';
import { parseDate } from '@telerik/kendo-intl';
import { RadioGroup, Radio } from 'react-radio-group'
import InputPortfolioDropdown from "../../Ssrs/components/InputPortfolioDropdown";


class PackagerForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.user,
            runReportButtonFlag: false,

            accountLoading: false,
            layout: null,
            reports: [],
            pdfList: [],
            report: { name: "Select Report", key: null, adjustableLayout: null, folder: null },
            accounts: [],
            account: { account: "", displayName: "" },
            groups: [],
            numPages: null,
            pageNumber: 1,
            pdfFile: null,
            pdfFlag: false,
            pdfScale: 1.25,
            status: "",
            loaderSpinnerFlag: false,
            checkPdfBlobTimerId: null,//used in componentWillUnmount cleanup
            generateExcelButtonFlag: true,
            reportParameters: [],
            reportParametersFlag: false,
            parameterList: [],
            packageName: null,
            packageDesc: '',
            frequency: "",
            showReport: false,
            showReportText: "Add Content",
            selectedAccount: { value: '', label: '' },
            reportsList: [],
            reportIdCount: 1,
            editPackageName: false,
            editPackageDesc: false,
            editEntity: false,
            editFrequency: false,
            editSingleFile: false,
            editAdvanced: false,
            selectedReport: null,
            contentType: "Report",
            selectedPdf: null,
            singleFileCheckbox: false,
            packageGroupCheckbox: false,
            packageGroupCheckboxEnable: false,
            singleFileName: '',
            householdList: [],
            householdsForFilter: [],
            defaultList: [],
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
            advancedMode: false,
            hierarchy: "Top Level",
            packageGroupPortfolio: "Run as is",
            packageGroupPdf: "",
            selectedMode: this.props.indivOrGroup || 'individual',
            mutliAdded: '',
            templates: [],
            template: {name:''},
        }

    }



    componentDidMount() {
        this.getReportList();
        //this.getGenericDates = this.getGenericDates.bind(this);
        this.getGenericDates();
        this.getPdfList();
        this.getAccountList();
        this.getHouseholdList();
        this.getReportDefaults();
        this.getTemplateList();
    }

    componentWillUnmount() {
        clearTimeout(this.state.checkPdfBlobTimerId)
    }

    onChangeHandler = (e, type) => {
        // if (type === 'selectedAccount') {
        //     if (e.target.value && e.target.value.value && e.target.value.value.charAt(0) !== '@') {
        //         this.setState({ packageGroupCheckbox: false, packageGroupCheckboxEnable: false });
        //     }
        //     if (e.target.value && e.target.value.value && e.target.value.value.charAt(0) === '@') {
        //         this.setState({ packageGroupCheckboxEnable: true });
        //     }
        // }
        this.setState({ [type]: e.target.value });
    }

    onChangeHandlerSingleFile = (e) => {
        //console.log(e)
        // if (e.target.value === false) {
        //     this.setState({ singleFileName: '' })
        // }
        this.setState({ singleFileCheckbox: e.target.value });
    }

    onChangeHandlerPackageGroup = (e) => {
        //console.log(e)
        this.setState({ packageGroupCheckbox: e.target.value });
    }

    onRadioChangeHandler = (e) => {
        this.setState({ selectedReport: e });
    }

    onGenericToggleChangeHandler = (value, type) => {
        console.log(value)
        this.setState({ [type]: value })

        if (type === 'singleDateGenericDateToggle') {
            if (value === true) {
                this.setState({ SSRSParam_singleDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
            }
            else {
                this.setState({ SSRSParam_singleDate: this.state.singleDate_OriginalDefault })
                console.log(this.state.singleDate_OriginalDefault)
            }
        }
        else if (type === 'fromDateGenericDateToggle') {
            if (value === true) {
                this.setState({ SSRSParam_fromDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
            }
            else {
                this.setState({ SSRSParam_fromDate: this.state.fromDate_OriginalDefault })
                console.log(this.state.fromDate_OriginalDefault)
            }
        }
        else if (type === 'toDateGenericDateToggle') {
            if (value === true) {
                this.setState({ SSRSParam_toDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
            }
            else {
                this.setState({ SSRSParam_toDate: this.state.toDate_OriginalDefault })
                console.log(this.state.toDate_OriginalDefault)
            }
        }
    }

    getHouseholdList() {
        var households = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        //this.setState({ loading: true, editPackage: false, packageSelected: false })

        this.setState({ householdList: [] }, () => {
            axios(`api/ssrsGetHouseholds?database=` + this.props.user.company, {
                method: 'GET',
                headers: headers
            })
                .then(response => {
                    households = response.data.data
                    var householdList = []
                    households.forEach(householdInResponse => {
                        householdList = householdList.concat(householdInResponse.household)
                    })
                    //this.setState({ accounts: packages })
                    this.setState({ householdList: householdList, householdListForFilter: householdList })
                })
                .catch(err => {
                    console.error(err);
                })
            //return reports;
        })
    }

    changeReportOrder = (direction) => {

        var currentOrder = this.state.selectedReport.value;

        var newOrder
        var tempReport;
        var newReportsList = [];

        if (this.props.type === 'create' || this.props.type === 'createtemplate' || this.props.type === 'createtemplatepackage') {
            if (!(currentOrder === 1 && direction === 'up') && !(currentOrder === this.state.reportsList.length && direction === 'down')) {
                newOrder = 0
                if (direction === 'up') {
                    newOrder = currentOrder - 1
                }
                else {
                    newOrder = currentOrder + 1
                }

                newReportsList = [];
                this.state.reportsList.forEach(report => {
                    tempReport = report
                    if (report.value === currentOrder) {
                        tempReport.value = newOrder;
                    }
                    else if (report.value === newOrder) {
                        tempReport.value = currentOrder;
                    }
                    newReportsList.push(tempReport)
                })
                newReportsList.sort(this.compareReportOrder)
                this.setState({ reportsList: newReportsList });
                //this.setState({ [type]: e.target.value });
            }
        }
        else if (this.props.type === 'edit' || this.props.type === 'edittemplate' || this.props.type === 'edittemplatepackage') {
            if (!(currentOrder === 1 && direction === 'up') && !(currentOrder === this.props.reportList.length && direction === 'down')) {
                newOrder = 0
                if (direction === 'up') {
                    newOrder = currentOrder - 1
                }
                else {
                    newOrder = currentOrder + 1
                }

                newReportsList = [];
                this.props.reportList.forEach(report => {
                    tempReport = report
                    if (report.value === currentOrder) {
                        tempReport.value = newOrder;
                    }
                    else if (report.value === newOrder) {
                        tempReport.value = currentOrder;
                    }
                    newReportsList.push(tempReport)
                })
                newReportsList.sort(this.compareReportOrder)
                this.props.onReportListChange(newReportsList)
            }
        }



    }

    deleteReportFromPackage = () => {
        console.log(this.state.selectedReport)

        var reportToDelete = this.state.selectedReport.value;

        var tempReport;
        var newReportsList = [];
        var newValue

        if (this.props.type === 'create' || this.props.type === 'createtemplate'  || this.props.type === 'createtemplatepackage') {

            console.log(this.state.reportsList)
            newReportsList = [];
            newValue = 1;
            this.state.reportsList.forEach(report => {
                tempReport = report
                if (report.value !== reportToDelete) {
                    tempReport.value = newValue;
                    newReportsList.push(tempReport)
                    newValue++;
                }
            })
            newReportsList.sort(this.compareReportOrder)
            this.setState({ reportsList: newReportsList });
            //this.setState({ [type]: e.target.value });

        }
        else if (this.props.type === 'edit' || this.props.type === 'edittemplate' || this.props.type === 'edittemplatepackage') {

            newReportsList = [];
            newValue = 1;
            this.props.reportList.forEach(report => {
                tempReport = report
                if (report.value !== reportToDelete) {
                    tempReport.value = newValue;
                    newReportsList.push(tempReport)
                    newValue++;
                }
            })
            console.log(newReportsList);
            newReportsList.sort(this.compareReportOrder)
            this.props.onReportListChange(newReportsList)
        }



    }

    consolidateGroups = () => {
        var newListWithConsolidated = []
        var unconsolidatedGroupCount = 0
        var consolidatedGroupCount = 0

        if (this.state.SSRSParam_PortfolioTest) {
            this.state.SSRSParam_PortfolioTest.forEach(entity => {
                if (entity.value.charAt(0) === '@') {
                    newListWithConsolidated = newListWithConsolidated.concat({ value: '+' + entity.value, label: entity.label })
                    unconsolidatedGroupCount++

                }
                else if (entity.value.includes('+@')) {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                    consolidatedGroupCount++
                }
                else {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                }

            })
            this.setState({ SSRSParam_PortfolioTest: newListWithConsolidated }, () => {
                if (consolidatedGroupCount > 0 && unconsolidatedGroupCount === 0) {
                    newListWithConsolidated = []
                    this.state.SSRSParam_PortfolioTest.forEach(entity => {
                        if (entity.value.charAt(0) === '+') {
                            newListWithConsolidated = newListWithConsolidated.concat({ value: entity.value.replace('+', ''), label: entity.label })
                        }
                        else {
                            newListWithConsolidated = newListWithConsolidated.concat(entity)
                        }
                    })
                    this.setState({ SSRSParam_PortfolioTest: newListWithConsolidated })
                }
            })


        }

        if (this.state.SSRSParam_PortfolioList) {
            this.state.SSRSParam_PortfolioList.forEach(entity => {
                if (entity.value.charAt(0) === '@') {
                    newListWithConsolidated = newListWithConsolidated.concat({ value: '+' + entity.value, label: entity.label })
                    unconsolidatedGroupCount++
                }
                else if (entity.value.includes('+@')) {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                    consolidatedGroupCount++
                }
                else {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                }
            })
            this.setState({ SSRSParam_PortfolioList: newListWithConsolidated }, () => {
                if (consolidatedGroupCount > 0 && unconsolidatedGroupCount === 0) {
                    newListWithConsolidated = []

                    this.state.SSRSParam_PortfolioList.forEach(entity => {
                        if (entity.value.charAt(0) === '+') {
                            newListWithConsolidated = newListWithConsolidated.concat({ value: entity.value.replace('+', ''), label: entity.label })
                        }
                        else {
                            newListWithConsolidated = newListWithConsolidated.concat(entity)
                        }
                    })
                    this.setState({ SSRSParam_PortfolioList: newListWithConsolidated })
                }
            })
        }

    }

    savePackage = (callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var reportsListWithOrder = [];
        var reportOrderCount = 1
        var newPackage;
        var payload;

        var topLevelReports = false
        var multiLevelReports = false
        var bottomLevelReports = false


        var doAxios = true
        if (this.props.type === 'create') {
            if (this.state.packageName === "") {
                alert("Package Name must not be blank")
                doAxios = false
            }
            else if (this.state.reportsList.length < 1) {
                alert("Package must contain one or more reports")
                doAxios = false
            }
            else {
                reportsListWithOrder = [];
                reportOrderCount = 1
                for (let report of this.state.reportsList) {
                    //this.state.reportsList.forEach(report => {
                    if (report.hierarchy) {
                        if (report.hierarchy.toLowerCase() === 'top level') { topLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'multi level') { multiLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'bottom level') { bottomLevelReports = true }

                        if (report.hierarchy.toLowerCase() === 'top level' && (multiLevelReports || bottomLevelReports)) {
                            alert("Top Level reports must precede Multi and Bottom Level reports")
                            doAxios = false
                            break
                        }

                        if (report.hierarchy.toLowerCase() === 'multi level' && bottomLevelReports) {
                            alert("Multi Level reports must precede Bottom Level reports")
                            doAxios = false
                            break
                        }
                    }

                    console.log(report)
                    reportsListWithOrder = reportsListWithOrder.concat({
                        packageId: null,
                        packageName: this.state.packageName,
                        reportName: report.text,
                        contentType: report.contentType,
                        reportOrder: reportOrderCount,
                        reportingGroup: report.reportingGroup,
                        reportDate: report.reportDate,
                        orientation: report.orientation,
                        paramString: report.paramString,
                        hierarchy: report.hierarchy,
                    })
                    reportOrderCount++;
                }//)

                var thisFileName = this.state.singleFileName
                // if (!this.state.singleFileCheckbox) {
                //     thisFileName = ''
                // }

                if (!thisFileName) {
                    thisFileName = ''
                }
                newPackage = {
                    packageName: this.state.packageName,
                    packageDescription: this.state.packageDesc,
                    reportingGroup: this.state.selectedAccount.value,
                    reportingGroupCode: this.state.selectedAccount.value,
                    frequency: this.state.frequency,
                    saveAsSinglePdf: this.state.singleFileCheckbox,
                    fileName: thisFileName,
                    advancedMode: this.state.selectedMode === 'individual',
                }

                payload = { newPackage: newPackage, detail: reportsListWithOrder, company: this.props.user.company, originalName: this.state.packageName }
            }


        }
        else if (this.props.type === 'edit') {
            if (this.state.packageName === "" && this.state.packageName) {
                alert("Package Name must not be blank")
                doAxios = false
            }
            else if (this.props.reportList.length < 1) {
                alert("Package must contain one or more reports")
                doAxios = false
            }
            else {
                reportsListWithOrder = [];
                reportOrderCount = 1

                var packageName
                if (this.state.editPackageName) {
                    packageName = this.state.packageName
                }
                else {
                    packageName = this.props.package[0].Key
                }

                var packageDesc
                if (this.state.editPackageDesc) {
                    packageDesc = this.state.packageDesc
                }
                else {
                    packageDesc = this.props.package[0].Description
                }

                var reportingGroup
                var reportingGroupCode
                if (this.state.editEntity) {
                    reportingGroup = this.state.selectedAccount.value
                    reportingGroupCode = this.state.selectedAccount.value
                }
                else {
                    reportingGroup = this.props.package[0].Entity
                    reportingGroupCode = this.props.package[0].Entity.replace('@', '')
                }

                console.log(reportsListWithOrder)

                var packageFreq
                if (this.state.editFrequency) {
                    packageFreq = this.state.frequency
                }
                else {
                    packageFreq = this.props.package[0].Frequency
                }

                var packageAdvanced
                if (this.state.editAdvanced) {
                    packageAdvanced = this.state.selectedMode === 'individual'
                }
                else {
                    packageAdvanced = this.props.package[0].advancedMode
                }

                var packageSaveAsSinglePdf
                if (this.state.editSingleFile) {
                    packageSaveAsSinglePdf = this.state.singleFileCheckbox
                }
                else {
                    packageSaveAsSinglePdf = this.props.package[0].SaveAsSinglePdf
                }

                var packageFileName
                if (this.state.editSingleFile) {
                    packageFileName = this.state.singleFileName
                }
                else {
                    packageFileName = this.props.package[0].FileName
                }

                console.log(reportsListWithOrder)
                console.log(this.state.reportsList.length)
                for (let report of this.props.reportList) {
                    //this.props.reportList.forEach(report => {
                    console.log(this.state.reportsList.length)
                    console.log(reportsListWithOrder)
                    // var hierarchy = null


                    // if (this.state.advancedMode){
                    //     hierarchy = report.hierarchy
                    // }

                    console.log(this.props.package[0].advancedMode)

                    // if (this.props.package[0].advancedMode === false){
                    //     report.hierarchy = ''
                    // }

                    console.log(report.hierarchy)

                    if (report.hierarchy) {
                        if (report.hierarchy.toLowerCase() === 'top level') { topLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'multi level') { multiLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'bottom level') { bottomLevelReports = true }

                        console.log(report.hierarchy.toLowerCase() + '  ' + multiLevelReports + '  ' + bottomLevelReports)
                        if (report.hierarchy.toLowerCase() === 'top level' && (multiLevelReports || bottomLevelReports)) {
                            alert("Top Level reports must precede Multi and Bottom Level reports")
                            doAxios = false
                            break
                        }

                        if (report.hierarchy.toLowerCase() === 'multi level' && bottomLevelReports) {
                            alert("Multi Level reports must precede Bottom Level reports")
                            doAxios = false
                            break
                        }
                    }


                    console.log(report)
                    reportsListWithOrder = reportsListWithOrder.concat({
                        packageId: null,
                        packageName: packageName,
                        reportName: report.text,
                        contentType: report.contentType,
                        reportOrder: reportOrderCount,
                        reportingGroup: report.reportingGroup,
                        reportDate: report.reportDate,
                        orientation: report.orientation,
                        paramString: report.paramString,
                        hierarchy: report.hierarchy,
                    })
                    reportOrderCount++;
                }//)

                // if (!packageSaveAsSinglePdf) {
                //     packageFileName = ''
                // }

                if (!packageFileName) {
                    packageFileName = ''
                }

                console.log(reportsListWithOrder)

                newPackage = {
                    packageName: packageName,
                    packageDescription: packageDesc,
                    reportingGroup: reportingGroup,
                    reportingGroupCode: reportingGroupCode,
                    frequency: packageFreq,
                    saveAsSinglePdf: packageSaveAsSinglePdf,
                    fileName: packageFileName,
                    advancedMode: packageAdvanced,
                }
                console.log(newPackage)


                console.log(reportsListWithOrder)
                payload = { newPackage: newPackage, detail: reportsListWithOrder, company: this.props.user.company, originalName: this.props.package[0].Key }
                console.log(payload)
            }

        }
        if (this.props.type === 'createtemplate') {
            if (this.state.packageName === "") {
                alert("Package Name must not be blank")
                doAxios = false
            }
            else if (this.state.reportsList.length < 1) {
                alert("Package must contain one or more reports")
                doAxios = false
            }
            else {
                reportsListWithOrder = [];
                reportOrderCount = 1
                for (let report of this.state.reportsList) {
                    //this.state.reportsList.forEach(report => {
                    if (report.hierarchy) {
                        if (report.hierarchy.toLowerCase() === 'top level') { topLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'multi level') { multiLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'bottom level') { bottomLevelReports = true }

                        if (report.hierarchy.toLowerCase() === 'top level' && (multiLevelReports || bottomLevelReports)) {
                            alert("Top Level reports must precede Multi and Bottom Level reports")
                            doAxios = false
                            break
                        }

                        if (report.hierarchy.toLowerCase() === 'multi level' && bottomLevelReports) {
                            alert("Multi Level reports must precede Bottom Level reports")
                            doAxios = false
                            break
                        }
                    }

                    console.log(report)
                    reportsListWithOrder = reportsListWithOrder.concat({
                        packageId: null,
                        packageName: this.state.packageName,
                        reportName: report.text,
                        contentType: report.contentType,
                        reportOrder: reportOrderCount,
                        reportingGroup: report.reportingGroup,
                        reportDate: report.reportDate,
                        orientation: report.orientation,
                        paramString: report.paramString,
                        hierarchy: report.hierarchy,
                    })
                    reportOrderCount++;
                }//)

                var thisFileName = this.state.singleFileName
                // if (!this.state.singleFileCheckbox) {
                //     thisFileName = ''
                // }

                if (!thisFileName) {
                    thisFileName = ''
                }
                newPackage = {
                    packageName: this.state.packageName,
                    packageDescription: this.state.packageDesc,
                    reportingGroup: this.state.selectedAccount.value,
                    reportingGroupCode: this.state.selectedAccount.value,
                    frequency: this.state.frequency,
                    saveAsSinglePdf: this.state.singleFileCheckbox,
                    fileName: thisFileName,
                    advancedMode: this.state.selectedMode === 'individual',
                }

                payload = { newPackage: newPackage, detail: reportsListWithOrder, company: this.props.user.company, originalName: this.state.packageName, template: true }
            }


        }
        else if (this.props.type === 'edittemplate') {
            if (this.state.packageName === "" && this.state.packageName) {
                alert("Template Name must not be blank")
                doAxios = false
            }
            else if (this.props.reportList.length < 1) {
                alert("Template must contain one or more reports")
                doAxios = false
            }
            else {
                reportsListWithOrder = [];
                reportOrderCount = 1

                var packageName
                if (this.state.editPackageName) {
                    packageName = this.state.packageName
                }
                else {
                    packageName = this.props.package[0].Key
                }

                var packageDesc
                if (this.state.editPackageDesc) {
                    packageDesc = this.state.packageDesc
                }
                else {
                    packageDesc = this.props.package[0].Description
                }

                var reportingGroup
                var reportingGroupCode
                if (this.state.editEntity) {
                    reportingGroup = this.state.selectedAccount.value
                    reportingGroupCode = this.state.selectedAccount.value
                }
                else {
                    reportingGroup = this.props.package[0].Entity
                    reportingGroupCode = this.props.package[0].Entity.replace('@', '')
                }

                console.log(reportsListWithOrder)

                

                console.log(reportsListWithOrder)
                console.log(this.state.reportsList.length)
                for (let report of this.props.reportList) {
                    //this.props.reportList.forEach(report => {
                    console.log(this.state.reportsList.length)
                    console.log(reportsListWithOrder)
                    // var hierarchy = null


                    // if (this.state.advancedMode){
                    //     hierarchy = report.hierarchy
                    // }

                    console.log(this.props.package[0].advancedMode)

                    // if (this.props.package[0].advancedMode === false){
                    //     report.hierarchy = ''
                    // }

                    console.log(report.hierarchy)

                    if (report.hierarchy) {
                        if (report.hierarchy.toLowerCase() === 'top level') { topLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'multi level') { multiLevelReports = true }
                        if (report.hierarchy.toLowerCase() === 'bottom level') { bottomLevelReports = true }

                        console.log(report.hierarchy.toLowerCase() + '  ' + multiLevelReports + '  ' + bottomLevelReports)
                        if (report.hierarchy.toLowerCase() === 'top level' && (multiLevelReports || bottomLevelReports)) {
                            alert("Top Level reports must precede Multi and Bottom Level reports")
                            doAxios = false
                            break
                        }

                        if (report.hierarchy.toLowerCase() === 'multi level' && bottomLevelReports) {
                            alert("Multi Level reports must precede Bottom Level reports")
                            doAxios = false
                            break
                        }
                    }


                    console.log(report)
                    reportsListWithOrder = reportsListWithOrder.concat({
                        packageId: null,
                        packageName: packageName,
                        reportName: report.text,
                        contentType: report.contentType,
                        reportOrder: reportOrderCount,
                        reportingGroup: report.reportingGroup,
                        reportDate: report.reportDate,
                        orientation: report.orientation,
                        paramString: report.paramString,
                        hierarchy: report.hierarchy,
                    })
                    reportOrderCount++;
                }//)

                // if (!packageSaveAsSinglePdf) {
                //     packageFileName = ''
                // }

                if (!packageFileName) {
                    packageFileName = ''
                }

                console.log(reportsListWithOrder)

                newPackage = {
                    packageName: packageName,
                    packageDescription: packageDesc,
                    reportingGroup: reportingGroup,
                    reportingGroupCode: reportingGroupCode,
                    frequency: null,
                    saveAsSinglePdf: null,
                    fileName: null,
                    advancedMode: null,
                }
                console.log(newPackage)


                console.log(reportsListWithOrder)
                payload = { newPackage: newPackage, detail: reportsListWithOrder, company: this.props.user.company, originalName: this.props.package[0].Key, template: true }
                console.log(payload)
            }

        }
        if (this.props.type === 'createtemplatepackage') {
            if (this.state.packageName === "") {
                alert("Template Package Name must not be blank")
                doAxios = false
            }
            else if (this.state.reportsList.length < 1) {
                alert("Template Package must contain one or more templates")
                doAxios = false
            }
            else {
                reportsListWithOrder = [];
                reportOrderCount = 1
                for (let report of this.state.reportsList) {
                    //this.state.reportsList.forEach(report => {

                    console.log(report)
                    reportsListWithOrder = reportsListWithOrder.concat({
                        packageId: null,
                        packageName: this.state.packageName,
                        reportName: report.text,
                        reportOrder: reportOrderCount,
                        reportingGroup: report.reportingGroup,
                    })
                    reportOrderCount++;
                }//)

                var thisFileName = this.state.singleFileName
                // if (!this.state.singleFileCheckbox) {
                //     thisFileName = ''
                // }

                if (!thisFileName) {
                    thisFileName = ''
                }
                newPackage = {
                    packageName: this.state.packageName,
                    packageDescription: this.state.packageDesc,
                    reportingGroup: this.state.selectedAccount.value,
                    reportingGroupCode: this.state.selectedAccount.value,
                    frequency: this.state.frequency,
                    saveAsSinglePdf: this.state.singleFileCheckbox,
                    fileName: thisFileName,
                }

                payload = { newPackage: newPackage, detail: reportsListWithOrder, company: this.props.user.company, originalName: this.state.packageName, templatePackage: true }
            }


        }
        else if (this.props.type === 'edittemplatepackage') {
            if (this.state.packageName === "" && this.state.packageName) {
                alert("Template Package Name must not be blank")
                doAxios = false
            }
            else if (this.state.reportsList.length < 1 && this.props.reportList.length < 1) {
                alert("Template Package must contain one or more templates")
                doAxios = false
            }
            else {
                reportsListWithOrder = [];
                reportOrderCount = 1

                var packageName
                if (this.state.editPackageName) {
                    packageName = this.state.packageName
                }
                else {
                    packageName = this.props.package[0].Key
                }

                var packageDesc
                if (this.state.editPackageDesc) {
                    packageDesc = this.state.packageDesc
                }
                else {
                    packageDesc = this.props.package[0].Description
                }

                var reportingGroup
                var reportingGroupCode
                if (this.state.editEntity) {
                    reportingGroup = this.state.selectedAccount.value
                    reportingGroupCode = this.state.selectedAccount.value
                }
                else {
                    reportingGroup = this.props.package[0].Entity
                    reportingGroupCode = this.props.package[0].Entity.replace('@', '')
                }

                var packageFreq
                if (this.state.editFrequency) {
                    packageFreq = this.state.frequency
                }
                else {
                    packageFreq = this.props.package[0].Frequency
                }


                var packageSaveAsSinglePdf
                if (this.state.editSingleFile) {
                    packageSaveAsSinglePdf = this.state.singleFileCheckbox
                }
                else {
                    packageSaveAsSinglePdf = this.props.package[0].SaveAsSinglePdf
                }

                var packageFileName
                if (this.state.editSingleFile) {
                    packageFileName = this.state.singleFileName
                }
                else {
                    packageFileName = this.props.package[0].FileName
                }
                
                if (this.state.reportsList.length === 0){
                    for (let report of this.props.reportList) {
                        //this.state.reportsList.forEach(report => {
    
                        console.log(report)
                        reportsListWithOrder = reportsListWithOrder.concat({
                            packageId: null,
                            packageName: this.state.packageName,
                            reportName: report.text,
                            reportOrder: reportOrderCount,
                            reportingGroup: report.reportingGroup,
                        })
                        reportOrderCount++;
                    }
                }
                else{
                    for (let report of this.state.reportsList) {
                        //this.state.reportsList.forEach(report => {
    
                        console.log(report)
                        reportsListWithOrder = reportsListWithOrder.concat({
                            packageId: null,
                            packageName: this.state.packageName,
                            reportName: report.text,
                            reportOrder: reportOrderCount,
                            reportingGroup: report.reportingGroup,
                        })
                        reportOrderCount++;
                    }
                }


                if (!packageFileName) {
                    packageFileName = ''
                }

                console.log(reportsListWithOrder)

                newPackage = {
                    packageName: packageName,
                    packageDescription: packageDesc,
                    reportingGroup: reportingGroup,
                    reportingGroupCode: reportingGroupCode,
                    frequency: packageFreq,
                    saveAsSinglePdf: packageSaveAsSinglePdf,
                    fileName: packageFileName,
                }
                console.log(newPackage)


                console.log(reportsListWithOrder)
                payload = { newPackage: newPackage, detail: reportsListWithOrder, company: this.props.user.company, originalName: this.props.package[0].Key, templatePackage: true }
                console.log(payload)
            }

        }

        if (doAxios) {
            axios
                .post("api/ssrsSavePackage", payload, { headers })
                .then((response) => {
                    if (response.data.code === 200) {
                        if (this.props.type === 'create' || this.props.type === 'createtemplatepackage') {
                            alert("Package has been saved.")
                        }
                        else if (this.props.type === 'edit' || this.props.type === 'edittemplate' || this.props.type === 'edittemplatepackage') {
                            alert("Changes have been saved")
                        }
                        else if (this.props.type === 'createtemplate') {
                            alert("Template has been saved.")
                        }
                        callback(true)
                    }
                    else {
                        alert('An error has occured while saving Package.');
                        callback(false)
                    }
                })
                .catch((e) => {
                    console.error(e);
                    alert('An error has occured while saving Package.');
                    callback(false)
                });
        }
        else {
            callback(false)
        }



    }

    submitForm = () => {
        var tempReport
        var tempTemplate
        var entityList = ''
        var validate = true
        if (this.state.SSRSParam_PortfolioTest) {//delete this if block later


            this.state.SSRSParam_PortfolioTest.forEach(entity => {
                entityList = entityList.concat(entity.value + '`')
            })

            entityList = entityList.slice(0, -1);//trims last character which is a comma
        }

        if (this.state.SSRSParam_PortfolioList) {


            this.state.SSRSParam_PortfolioList.forEach(entity => {
                entityList = entityList.concat(entity.value + '`')
            })

            entityList = entityList.slice(0, -1);//trims last character which is a comma
        }

        if (this.props.type === 'createtemplatepackage' || this.props.type === 'edittemplatepackage'){
            console.log(this.props)
            console.log(this.state)
            if (this.props.type === 'createtemplatepackage') {

                var reportingGroup = this.state.selectedMode === 'group'


                tempTemplate = {
                    value: this.state.reportIdCount,
                    text: this.state.template.name,
                    //reportingGroup: this.state.selectedAccount,
                    reportingGroup: this.state.templateGroup[0].value,
                }


                this.setState({ reportsList: this.state.reportsList.concat(tempTemplate), reportIdCount: this.state.reportIdCount + 1, templateGroup: [] }, () => {
                    console.log(this.state.reportsList);

                });
            }
            if (this.props.type === 'edittemplatepackage') {

                var reportingGroup = this.state.selectedMode === 'group'


                tempTemplate = {
                    value: this.state.reportIdCount,
                    text: this.state.template.name,
                    //reportingGroup: this.state.selectedAccount,
                    reportingGroup: this.state.templateGroup[0].value,
                }

                if (this.state.reportsList.length === 0){
                    this.setState({ reportsList: this.props.reportList.concat(tempTemplate), reportIdCount: this.state.reportIdCount + 1, templateGroup: [] }, () => {
                        console.log(this.state.reportsList);
    
                    });  
                }
                else{
                    this.setState({ reportsList: this.state.reportsList.concat(tempTemplate), reportIdCount: this.state.reportIdCount + 1, templateGroup: [] }, () => {
                        console.log(this.state.reportsList);
    
                    });
                }
            }
        }
        else if (this.state.contentType === 'Report') {
            const { ...inputFields } = this.state
            Object.entries(inputFields).map(([key, value]) => {
                if (key.includes("SSRSParam_") && key.includes("_Validate")) {
                    this.setState({ [key]: true })
                }
            })

            validate = this.validate();
            if (validate) {
                var splitKey;
                var paramString = '';

                var singleDateObject;
                var fromDateObject;
                var toDateObject;

                // if (this.state.selectedMode === 'group'){
                //     paramString += "GroupBehavior" + "`" + this.state.packageGroupPortfolio
                // }

                var count = 0
                Object.entries(inputFields).map(([key, value]) => {
                    if (key.includes("SSRSParam_") && value !== null && !key.includes("_Data") && !key.includes("_DataOriginal") && !key.includes("_Validate")) {
                        splitKey = key.split('_')

                        if (count > 0) {
                            paramString += "|"
                        }


                        if ((splitKey[1] === "PortfolioTest" || splitKey[1] === "PortfolioList") && this.state.selectedMode !== 'group') {

                            //paramString += splitKey[1] + "," + value[0].value
                            paramString += splitKey[1] + "`" + entityList

                        }
                        // else if (splitKey[1] === "singleDate" && this.state.singleDateGenericDateToggle) {
                        //     this.state.genericDates.forEach(genericDate => {
                        //         if (genericDate.label === value.value) {
                        //             singleDateObject = this.getDate(genericDate.value, true)
                        //             paramString += splitKey[1] + "," + format(singleDateObject, "MM-dd-yyyy")
                        //         }
                        //     })
                        // }
                        // else if (splitKey[1] === "fromDate" && this.state.fromDateGenericDateToggle) {
                        //     this.state.genericDates.forEach(genericDate => {
                        //         if (genericDate.label === value.value) {
                        //             fromDateObject = this.getDate(genericDate.value, true)
                        //             paramString += splitKey[1] + "," + format(fromDateObject, "MM-dd-yyyy")
                        //         }
                        //     })
                        // }
                        // else if (splitKey[1] === "toDate" && this.state.toDateGenericDateToggle) {
                        //     this.state.genericDates.forEach(genericDate => {
                        //         if (genericDate.label === value.value) {
                        //             toDateObject = this.getDate(genericDate.value, true)
                        //             paramString += splitKey[1] + "," + format(toDateObject, "MM-dd-yyyy")
                        //         }
                        //     })
                        // }
                        else if (value.label) {//checks if this value is from a dropdown list
                            paramString += splitKey[1] + "`" + value.value
                            //paramString = paramString.concat({paramName: splitKey[1], paramValue: value.value})
                        }
                        else if (value.constructor.name === "Date") {
                            //console.log(key + " is date")
                            paramString += splitKey[1] + "`" + format(value, "MM-dd-yyyy")
                            //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(value, "MM-dd-yyyy")})
                        }
                        else {
                            paramString += splitKey[1] + "`" + value
                            //paramString = paramString.concat({paramName: splitKey[1], paramValue: value})
                        }
                        count++;

                        if (this.state.selectedMode === 'group') {
                            paramString = paramString.replace("PortfolioList`|", "")
                            //asdf
                            // var indexOfPortList = -1
                            // indexOfPortList = paramString.findIndex((param) => {
                            //     return param.paramName === 'PortfolioList';
                            // });
        
                            // if (indexOfPortList >= 0) {
                            //     paramString.splice(indexOfPortList, 1)
                            // }
                        }

                        if (this.props.type === 'edit' && this.props.package[0].advancedMode === false) {
                            paramString = paramString.replace("PortfolioList`|", "")
                            // indexOfPortList = paramString.findIndex((param) => {
                            //     return param.paramName === 'PortfolioList';
                            // });
        
                            // if (indexOfPortList >= 0) {
                            //     paramString.splice(indexOfPortList, 1)
                            // }
                        }
                    }
                })

                var layout = null
                var layoutFull = null
                if (this.state.SSRSParam_Layout !== null && this.state.SSRSParam_Layout) {
                    layout = this.state.SSRSParam_Layout.value
                    if (layout === "l") {
                        layoutFull = "Landscape"
                    }
                    else {
                        layoutFull = "Portrait"
                    }
                }
                else {
                    layoutFull = "Landscape"
                }


                var prettyDate;
                if (this.state.SSRSParam_fromDate !== null && this.state.SSRSParam_fromDate) {
                    if (this.state.fromDateGenericDateToggle && this.state.toDateGenericDateToggle)
                        prettyDate = this.state.SSRSParam_fromDate.value + " - " + this.state.SSRSParam_toDate.value
                    else if (this.state.fromDateGenericDateToggle && !this.state.toDateGenericDateToggle)
                        prettyDate = this.state.SSRSParam_fromDate.value + " - " + this.formatDate(this.state.SSRSParam_toDate)
                    else if (!this.state.fromDateGenericDateToggle && this.state.toDateGenericDateToggle)
                        prettyDate = this.formatDate(this.state.SSRSParam_fromDate) + " - " + this.state.SSRSParam_toDate.value
                    else
                        prettyDate = this.formatDate(this.state.SSRSParam_fromDate) + " - " + this.formatDate(this.state.SSRSParam_toDate)
                }
                else if (this.state.SSRSParam_singleDate !== null && this.state.SSRSParam_singleDate) {
                    if (this.state.singleDateGenericDateToggle)
                        prettyDate = this.state.SSRSParam_singleDate.value
                    else
                        prettyDate = this.formatDate(this.state.SSRSParam_singleDate)
                }

                if (this.props.type === 'create') {
                    var hierarchy = null
                    // if (this.state.selectedMode === 'individual') {
                    //     hierarchy = this.state.hierarchy
                    // }
                    hierarchy = this.state.hierarchy

                    var contentType = "SSRS Report"
                    if (this.state.selectedMode === 'group') {
                        contentType = "Advanced Group Report"
                    }

                    var reportingGroup = this.state.selectedMode === 'group' ? this.state.packageGroupPortfolio : entityList
                    console.log('hhhghreyuhrguheurhg')
                    console.log(reportingGroup)
                    reportingGroup = (hierarchy === 'Multi Level' && this.state.selectedMode === 'group') ? reportingGroup : reportingGroup

                    if (hierarchy === 'Multi Level' && this.state.selectedMode === 'group'){
                        this.setState({mutliAdded: this.state.packageGroupPortfolio})
                    }

                    //reportingGroup = (this.state.selectedMode === 'group' && )

                    tempReport = {
                        value: this.state.reportIdCount,
                        text: this.state.report.name,
                        folder: this.state.report.folder,
                        contentType: contentType,
                        //reportingGroup: this.state.selectedAccount,
                        reportingGroup: reportingGroup,
                        reportDate: prettyDate,
                        orientation: layoutFull,
                        paramString: paramString,
                        hierarchy: hierarchy
                    }


                    this.setState({ reportsList: this.state.reportsList.concat(tempReport), reportIdCount: this.state.reportIdCount + 1 , showReport: !this.state.showReport, showReportText: "Add Content"}, () => {
                        console.log(this.state.reportsList);

                    });
                }
                else if (this.props.type === 'edit') {

                    var hierarchy = null
                    // if (this.state.selectedMode === 'individual' || this.props.package[0].advancedMode) {
                    //     hierarchy = this.state.hierarchy
                    // }
                    hierarchy = this.state.hierarchy

                    var contentType = "SSRS Report"
                    if (this.state.selectedMode === 'group' || this.props.package[0].advancedMode === false) {
                        contentType = "Advanced Group Report"
                    }

                    var reportingGroup = this.props.package[0].advancedMode === false ? this.state.packageGroupPortfolio : entityList
                    reportingGroup = (hierarchy === 'Multi Level' && this.props.package[0].advancedMode === false) ? reportingGroup : reportingGroup

                    if (hierarchy === 'Multi Level' && this.state.selectedMode === 'group'){
                        this.setState({mutliAdded: this.state.packageGroupPortfolio})
                    }

                    tempReport = {
                        value: this.props.reportList.length + 1,
                        text: this.state.report.name,
                        folder: this.state.report.folder,
                        contentType: contentType,
                        //reportingGroup: this.state.selectedAccount,
                        reportingGroup: reportingGroup,
                        reportDate: prettyDate,
                        orientation: layoutFull,
                        paramString: paramString,
                        hierarchy: hierarchy
                    }


                    this.props.onReportListChange(this.props.reportList.concat(tempReport))
                    this.setState({ reportIdCount: this.props.reportList.length + 1, showReport: !this.state.showReport, showReportText: "Add Content" })
                }
                else if (this.props.type === 'createtemplate') {
                    var hierarchy = null
                    // if (this.state.selectedMode === 'individual') {
                    //     hierarchy = this.state.hierarchy
                    // }
                    hierarchy = this.state.hierarchy
    
                    var contentType = "SSRS Report"
                    if (this.state.selectedMode === 'group') {
                        contentType = "Advanced Group Report"
                    }
    
                    var reportingGroup = this.state.selectedMode === 'group' ? this.state.packageGroupPortfolio : entityList
                    console.log('hhhghreyuhrguheurhg')
                    console.log(reportingGroup)
                    reportingGroup = (hierarchy === 'Multi Level' && this.state.selectedMode === 'group') ? reportingGroup : reportingGroup
    
                    if (hierarchy === 'Multi Level' && this.state.selectedMode === 'group'){
                        this.setState({mutliAdded: this.state.packageGroupPortfolio})
                    }
    
                    //reportingGroup = (this.state.selectedMode === 'group' && )
    
                    tempReport = {
                        value: this.state.reportIdCount,
                        text: this.state.report.name,
                        folder: this.state.report.folder,
                        contentType: contentType,
                        //reportingGroup: this.state.selectedAccount,
                        reportingGroup: reportingGroup,
                        reportDate: prettyDate,
                        orientation: layoutFull,
                        paramString: paramString,
                        hierarchy: hierarchy
                    }
    
    
                    this.setState({ reportsList: this.state.reportsList.concat(tempReport), reportIdCount: this.state.reportIdCount + 1, showReport: !this.state.showReport, showReportText: "Add Content"  }, () => {
                        console.log(this.state.reportsList);
    
                    });
                }
                else if (this.props.type === 'createtemplatepackage') {
                    var hierarchy = null
                    // if (this.state.selectedMode === 'individual') {
                    //     hierarchy = this.state.hierarchy
                    // }
                    hierarchy = this.state.hierarchy
    
                    var contentType = "SSRS Report"
                    if (this.state.selectedMode === 'group') {
                        contentType = "Advanced Group Report"
                    }
    
                    var reportingGroup = this.state.selectedMode === 'group' ? this.state.packageGroupPortfolio : entityList
                    console.log('hhhghreyuhrguheurhg')
                    console.log(reportingGroup)
                    reportingGroup = (hierarchy === 'Multi Level' && this.state.selectedMode === 'group') ? reportingGroup : reportingGroup
    
                    if (hierarchy === 'Multi Level' && this.state.selectedMode === 'group'){
                        this.setState({mutliAdded: this.state.packageGroupPortfolio})
                    }
    
                    //reportingGroup = (this.state.selectedMode === 'group' && )
    
                    tempReport = {
                        value: this.state.reportIdCount,
                        text: this.state.report.name,
                        folder: this.state.report.folder,
                        contentType: contentType,
                        //reportingGroup: this.state.selectedAccount,
                        reportingGroup: reportingGroup,
                        reportDate: prettyDate,
                        orientation: layoutFull,
                        paramString: paramString,
                        hierarchy: hierarchy
                    }
    
    
                    this.setState({ reportsList: this.state.reportsList.concat(tempReport), reportIdCount: this.state.reportIdCount + 1, showReport: !this.state.showReport, showReportText: "Add Content"  }, () => {
                        console.log(this.state.reportsList);
    
                    });
                }

                else if (this.props.type === 'edittemplatepackage') {
                    var hierarchy = null
                    // if (this.state.selectedMode === 'individual') {
                    //     hierarchy = this.state.hierarchy
                    // }
                    hierarchy = this.state.hierarchy
    
                    var contentType = "SSRS Report"
                    if (this.state.selectedMode === 'group') {
                        contentType = "Advanced Group Report"
                    }
    
                    var reportingGroup = this.state.selectedMode === 'group' ? this.state.packageGroupPortfolio : entityList
                    console.log('hhhghreyuhrguheurhg')
                    console.log(reportingGroup)
                    reportingGroup = (hierarchy === 'Multi Level' && this.state.selectedMode === 'group') ? reportingGroup : reportingGroup
    
                    if (hierarchy === 'Multi Level' && this.state.selectedMode === 'group'){
                        this.setState({mutliAdded: this.state.packageGroupPortfolio})
                    }
    
                    //reportingGroup = (this.state.selectedMode === 'group' && )
    
                    tempReport = {
                        value: this.state.reportIdCount,
                        text: this.state.report.name,
                        folder: this.state.report.folder,
                        contentType: contentType,
                        //reportingGroup: this.state.selectedAccount,
                        reportingGroup: reportingGroup,
                        reportDate: prettyDate,
                        orientation: layoutFull,
                        paramString: paramString,
                        hierarchy: hierarchy
                    }
    
    
                    this.setState({ reportsList: this.state.reportsList.concat(tempReport), reportIdCount: this.state.reportIdCount + 1,showReport: !this.state.showReport, showReportText: "Add Content"  }, () => {
                        console.log(this.state.reportsList);
    
                    });
                }

                else if (this.props.type === 'edittemplate') {

                    var hierarchy = null
                    // if (this.state.selectedMode === 'individual' || this.props.package[0].advancedMode) {
                    //     hierarchy = this.state.hierarchy
                    // }
                    hierarchy = this.state.hierarchy

                    var contentType = "SSRS Report"
                    if (this.state.selectedMode === 'group' || this.props.package[0].advancedMode === false) {
                        contentType = "Advanced Group Report"
                    }

                    var reportingGroup = this.state.selectedMode === 'group'  ? this.state.packageGroupPortfolio : entityList
                    reportingGroup = (hierarchy === 'Multi Level' && this.props.package[0].advancedMode === false) ? reportingGroup : reportingGroup

                    if (hierarchy === 'Multi Level' && this.state.selectedMode === 'group'){
                        this.setState({mutliAdded: this.state.packageGroupPortfolio})
                    }

                    tempReport = {
                        value: this.props.reportList.length + 1,
                        text: this.state.report.name,
                        folder: this.state.report.folder,
                        contentType: contentType,
                        //reportingGroup: this.state.selectedAccount,
                        reportingGroup: reportingGroup,
                        reportDate: prettyDate,
                        orientation: layoutFull,
                        paramString: paramString,
                        hierarchy: hierarchy
                    }


                    this.props.onReportListChange(this.props.reportList.concat(tempReport))
                    this.setState({ reportIdCount: this.props.reportList.length + 1, showReport: !this.state.showReport, showReportText: "Add Content"  })
                }


            }
            


        }
        else if (this.state.contentType === 'PDF') {
            if (this.props.type === 'create') {

                var hierarchy = null
                // if (this.state.selectedMode === 'individual') {
                //     hierarchy = this.state.hierarchy
                // }
                hierarchy = this.state.hierarchy

                var contentType = "PDF"
                if (this.state.selectedMode === 'group') {
                    contentType = "Advanced Group PDF"
                }

                tempReport = {
                    value: this.state.reportIdCount,
                    text: this.state.selectedPdf.name,
                    contentType: contentType,
                    reportingGroup: '',
                    reportDate: '',
                    orientation: '',
                    paramString: null,
                    hierarchy: hierarchy
                }

                this.setState({ reportsList: this.state.reportsList.concat(tempReport), reportIdCount: this.state.reportIdCount + 1 });
            }
            else if (this.props.type === 'edit') {
                var hierarchy = null
                // if (this.state.selectedMode === 'individual' || this.props.package[0].advancedMode) {
                //     hierarchy = this.state.hierarchy
                // }
                hierarchy = this.state.hierarchy

                var contentType = "PDF"
                if (this.state.selectedMode === 'group' || this.props.package[0].advancedMode === false) {
                    contentType = "Advanced Group PDF"
                }

                tempReport = {
                    value: this.props.reportList.length + 1,
                    text: this.state.selectedPdf.name,
                    contentType: contentType,
                    reportingGroup: '',
                    reportDate: '',
                    orientation: '',
                    paramString: null,
                    hierarchy: hierarchy
                }

                this.props.onReportListChange(this.props.reportList.concat(tempReport))
                this.setState({ reportIdCount: this.props.reportList.length + 1 })
            }
            else if (this.props.type === 'createtemplate') {

                var hierarchy = null
                // if (this.state.selectedMode === 'individual') {
                //     hierarchy = this.state.hierarchy
                // }
                hierarchy = this.state.hierarchy

                var contentType = "PDF"
                if (this.state.selectedMode === 'group') {
                    contentType = "Advanced Group PDF"
                }

                tempReport = {
                    value: this.state.reportIdCount,
                    text: this.state.selectedPdf.name,
                    contentType: contentType,
                    reportingGroup: '',
                    reportDate: '',
                    orientation: '',
                    paramString: null,
                    hierarchy: hierarchy
                }

                this.setState({ reportsList: this.state.reportsList.concat(tempReport), reportIdCount: this.state.reportIdCount + 1 });
            }
            else if (this.props.type === 'edittemplate') {
                var hierarchy = null
                // if (this.state.selectedMode === 'individual' || this.props.package[0].advancedMode) {
                //     hierarchy = this.state.hierarchy
                // }
                hierarchy = this.state.hierarchy

                var contentType = "PDF"
                if (this.state.selectedMode === 'group' || this.props.package[0].advancedMode === false) {
                    contentType = "Advanced Group PDF"
                }

                tempReport = {
                    value: this.props.reportList.length + 1,
                    text: this.state.selectedPdf.name,
                    contentType: contentType,
                    reportingGroup: '',
                    reportDate: '',
                    orientation: '',
                    paramString: null,
                    hierarchy: hierarchy
                }

                this.props.onReportListChange(this.props.reportList.concat(tempReport))
                this.setState({ reportIdCount: this.props.reportList.length + 1 })
            }

        }

        if (validate && (this.props.type !== 'createtemplatepackage' || this.props.type !== 'edittemplatepackage')){
            const { ...stateObject } = this.state
        this.setState({ runReportButtonFlag: false })

        Object.entries(stateObject).map(([key, value]) => {
            if (key.includes("SSRSParam_")) {
                this.setState({ [key]: null })
            }
        })

        this.setState({
            parameterList: [],
            layout: null, layoutFlag: false,
            pdfFile: null, pdfFlag: false, pdfScale: 1.25,
            report: { name: "Select Report", key: null, adjustableLayout: null, folder: null },
            paramTrackingCount: null
        })
        }
        else {
            this.setState({template: {name: ''}, })
        }
        


    }

    _handleChange = e => {
        const t = "SSRSParam_".concat([e.target.props.name])
        this.validate(t)

        this.setState({
            [t]: e.target.value
        })
    }

    // _filterChange = (event) => {
    //     this.setState({
    //         accounts: this.filterData(event.filter, this.state.accountsForFilter)
    //     });
    //     console.log(event)
    // }

    _filterChange = (event) => {
        var dataStateToBeFiltered
        var dataStateContainingOriginalValues

        if (event.target.name === "accountList") {
            if (this.state.selectedMode === 'group' || (this.props.package && this.props.package.length > 0 && !this.props.package[0].advancedMode)) {
                dataStateToBeFiltered = "groups"
                dataStateContainingOriginalValues = this.state.groupsForFilter                
            }
            else{
                dataStateToBeFiltered = "accounts"
                dataStateContainingOriginalValues = this.state.accountsForFilter
            }
        }
        else if (event.target.name === "accountListTemplate") {
            dataStateToBeFiltered = "templateEntities"
            dataStateContainingOriginalValues = this.state.templateEntitiesForFilter
        }
        else {
            console.log(event)
            dataStateToBeFiltered = "SSRSParam_" + event.target.name + "_Data"
            dataStateContainingOriginalValues = this.state["SSRSParam_" + event.target.name + "_DataOriginal"]
        }


        if (event.target.name === "PortfolioList") {
            event.filter.field = "label"
        }

        if (event.target.name === 'PortfolioListTemplate'){
            dataStateToBeFiltered = "templateEntities"
            dataStateContainingOriginalValues = this.state.templateEntitiesForFilter  
        }

        this.setState({
            [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
        });
    }

    _filterChangeGenericDate = (event) => {
        console.log(event)
        event.filter.field = "value"
        const dataStateToBeFiltered = "genericDates_" + event.target.name + "_Data"
        const dataStateContainingOriginalValues = this.state["genericDates_" + event.target.name + "_DataOriginal"]

        this.setState({
            [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
        });
    }

    filterData(filter, originalData) {
        //const data = allData.slice();
        return filterBy(originalData, filter);
    }


    changePdfPage = (pageNumber, direction) => {
        var newPage = pageNumber;
        if (direction === 'prev') {
            if (pageNumber === 1) {//if currently viewing the first page...
                newPage = this.state.numPages//...show the last page
            }
            else {
                newPage = pageNumber - 1
            }
        }
        if (direction === 'next') {
            if (pageNumber === this.state.numPages) {//if current page is equal to the amount of pages...
                newPage = 1//...wrap around to first page
            }
            else {
                newPage = pageNumber + 1
            }

        }
        this.setState({ pageNumber: newPage });
    }


    onReportChangeHandler = (e) => {

        const { ...stateObject } = this.state
        this.setState({ runReportButtonFlag: false })

        Object.entries(stateObject).map(([key, value]) => {
            if (key.includes("SSRSParam_")) {
                this.setState({ [key]: null })
            }
        })

        this.setState({
            parameterList: [],
            layout: null, layoutFlag: false,
            pdfFile: null, pdfFlag: false, pdfScale: 1.25,
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
        })
        //this.setState({ fromDateFlag: false })
        //this.setState({ toDateFlag: false })
        //this.setState({ singleDateFlag: false })
        //this.setState({ pdfFile: "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"})

        //wait for setState to finish before calling getReportParameters
        this.setState({ report: e.target.value }, () => {
            this.getReportParameters()
        });
    }

    onTemplateChangeHandler = (e) => {

        
        //this.setState({ fromDateFlag: false })
        //this.setState({ toDateFlag: false })
        //this.setState({ singleDateFlag: false })
        //this.setState({ pdfFile: "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"})

        //wait for setState to finish before calling getReportParameters
        this.setState({ template: e.target.value })
    }

    getReportList() {
        var reports = [];
        var reportNames = [];
        var splitName;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetReportsAll`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                var folder
                response.data.data.forEach(reportInResponse => {
                    folder = reportInResponse.path.split('/')[1]
                    if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
                        splitName = reportInResponse.name.split('_')
                        if (!reportNames.includes(splitName[0])) {
                            reportNames.push(splitName[0]);
                            reports.push({ name: splitName[0], key: reportInResponse.key, adjustableLayout: true, folder: folder  })
                        }
                    }
                    else {
                        reports.push({ name: reportInResponse.name, key: reportInResponse.key, folder: folder })
                    }


                })
                this.setState({ reports: reports })

            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getPdfList() {
        var pdfList = [];
        var firstRecord = true;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetPdfContent`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(PdfInResponse => {
                    pdfList.push({ name: PdfInResponse.title, key: PdfInResponse.title })

                    if (firstRecord) {
                        this.setState({ selectedPdf: pdfList[0] })
                    }

                })
                this.setState({ pdfList: pdfList })

            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getTemplateList() {
        var reports = [];
        var reportNames = [];
        var splitName;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetTemplatesForForm`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                // var folder
                // response.data.data.forEach(reportInResponse => {
                //     folder = reportInResponse.path.split('/')[1]
                //     if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
                //         splitName = reportInResponse.name.split('_')
                //         if (!reportNames.includes(splitName[0])) {
                //             reportNames.push(splitName[0]);
                //             reports.push({ name: splitName[0], key: reportInResponse.key, adjustableLayout: true, folder: folder  })
                //         }
                //     }
                //     else {
                //         reports.push({ name: reportInResponse.name, key: reportInResponse.key, folder: folder })
                //     }


                // })
                this.setState({ templates: response.data.data })

            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getGenericDates() {
        var dates = []

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetAllGenericDates`, {
            method: 'GET',
            params: { database: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                response.data.data.forEach(dateInResponse => {
                    dates.push({ label: dateInResponse.label, value: dateInResponse.value })
                })
                this.setState({ genericDates: dates })
            })
            .catch(err => {
                console.error(err);
            })

        const genericDates = [
            { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' },
            { value: 'bblq', label: 'bblq - Beginning Business Day Last Quarter' },
            { value: 'bbly', label: 'bbly - Beginning Business Day Last Year' },
            { value: 'bbpm', label: 'bbpm - Beginning Business Day Previous Month' },
            { value: 'bbpq', label: 'bbpq - Beginning Business Day Previous Quarter' },
            { value: 'bbpy', label: 'bbpy - Beginning Business Day Previous Year' },
            { value: 'bbtm', label: 'bbtm - Beginning Business Day This Month' },
            { value: 'bbtq', label: 'bbtq - Beginning Business Day This Quarter' },
            { value: 'bbty', label: 'bbty - Beginning Business Day This Year' },
            { value: 'bbnm', label: 'bbnm - Beginning Business Day Next Month' },
            { value: 'bbnq', label: 'bbnq - Beginning Business Day Next Quarter' },
            { value: 'bbny', label: 'bbny - Beginning Business Day Next Year' },
            { value: 'bdlm', label: 'bdlm - Beginning Day Last Month' },
            { value: 'bdlq', label: 'bdlq - Beginning Day Last Quarter' },
            { value: 'bdly', label: 'bdly - Beginning Day Last Year' },
            { value: 'bdpm', label: 'bdpm - Beginning Day Previous Month' },
            { value: 'bdpq', label: 'bdpq - Beginning Day Previous Quarter' },
            { value: 'bdpy', label: 'bdpy - Beginning Day Previous Year' },
            { value: 'bdtm', label: 'bdtm - Beginning Day This Month' },
            { value: 'bdtq', label: 'bdtq - Beginning Day This Quarter' },
            { value: 'bdty', label: 'bdty - Beginning Day This Year' },
            { value: 'bdnm', label: 'bdnm - Beginning Day Next Month' },
            { value: 'bdnq', label: 'bdnq - Beginning Day Next Quarter' },
            { value: 'bdny', label: 'bdny - Beginning Day Next Year' },
            { value: 'eblm', label: 'eblm - Ending Business Day Last Month' },
            { value: 'eblq', label: 'eblq - Ending Business Day Last Quarter' },
            { value: 'ebly', label: 'ebly - Ending Business Day Last Year' },
            { value: 'ebpm', label: 'ebpm - Ending Business Day Previous Month' },
            { value: 'ebpq', label: 'ebpq - Ending Business Day Previous Quarter' },
            { value: 'ebpy', label: 'ebpy - Ending Business Day Previous Year' },
            { value: 'ebtm', label: 'ebtm - Ending Business Day This Month' },
            { value: 'ebtq', label: 'ebtq - Ending Business Day This Quarter' },
            { value: 'ebty', label: 'ebty - Ending Business Day This Year' },
            { value: 'ebnm', label: 'ebnm - Ending Business Day Next Month' },
            { value: 'ebnq', label: 'ebnq - Ending Business Day Next Quarter' },
            { value: 'ebny', label: 'ebny - Ending Business Day Next Year' },
            { value: 'edlm', label: 'edlm - Ending Day Last Month' },
            { value: 'edlq', label: 'edlq - Ending Day Last Quarter' },
            { value: 'edly', label: 'edly - Ending Day Last Year' },
            { value: 'edpm', label: 'edpm - Ending Day Previous Month' },
            { value: 'edpq', label: 'edpq - Ending Day Previous Quarter' },
            { value: 'edpy', label: 'edpy - Ending Day Previous Year' },
            { value: 'edtm', label: 'edtm - Ending Day This Month' },
            { value: 'edtq', label: 'edtq - Ending Day This Quarter' },
            { value: 'edty', label: 'edty - Ending Day This Year' },
            { value: 'ednm', label: 'ednm - Ending Day Next Month' },
            { value: 'ednq', label: 'ednq - Ending Day Next Quarter' },
            { value: 'edny', label: 'edny - Ending Day Next Year' },
            { value: 'yest', label: 'yest - Yesterday' },
            { value: 'last', label: 'last - Last Business Day' },
            { value: 'prev', label: 'prev - Next To Last Business Day' }
        ]

        this.setState({
            genericDates_singleDate_Data: genericDates,
            genericDates_fromDate_Data: genericDates,
            genericDates_toDate_Data: genericDates,
            genericDates_singleDate_DataOriginal: genericDates,
            genericDates_fromDate_DataOriginal: genericDates,
            genericDates_toDate_DataOriginal: genericDates,
        })

    }

    getAccountList() {
        var entities = [];
        var groupEntities = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        this.setState({accountLoading: true})

        axios(`api/ssrsGetEntities`, {
            method: 'GET',
            params: { database: this.props.user.company },
            headers: headers
        })
            .then(response => {
                entities = response.data.data
                var splitLine
                var code
                var name
                var type
                var formattedLine
                var newEntities = []
                entities.forEach(line => {
                    splitLine = line.label.split('|')
                    code = this.getSpacedLine(splitLine[0], 12)
                    name = this.getSpacedLine(splitLine[1], 32)
                    type = this.getSpacedLine(splitLine[2], 8)

                    formattedLine = code + " | " + name + " | " + type
                    newEntities.push({ value: line.value, label: formattedLine })
                })
                entities = newEntities
                this.setState({ accounts: entities, accountsForFilter: entities })


                axios(`api/ssrsGetEntities`, {
                    method: 'GET',
                    params: { database: this.props.user.company, newGroups: true },
                    headers: headers
                })
                    .then(response2 => {
                        groupEntities = response2.data.data
                        var splitLine2
                        var code2
                        var name2
                        var type2
                        var formattedLine2
                        var newEntities2 = []
                        groupEntities.forEach(line => {
                            splitLine2 = line.label.split('|')
                            code2 = this.getSpacedLine(splitLine2[0], 12)
                            name2 = this.getSpacedLine(splitLine2[1], 32)
                            type2 = this.getSpacedLine(splitLine2[2], 8)

                            formattedLine2 = code2 + " | " + name2 + " | " + type2
                            newEntities2.push({ value: line.value, label: formattedLine2 })
                        })
                        groupEntities = newEntities2
                        var templateEntities = entities
                        templateEntities = templateEntities.concat(groupEntities)
                        this.setState({ templateEntities: templateEntities, templateEntitiesForFilter: templateEntities, groups: groupEntities, groupsForFilter: groupEntities, accountLoading: false })
                    })
                    .catch(err => {
                        console.error(err);
                        this.setState({accountLoading: false})
                    })
            })
            .catch(err => {
                console.error(err);
                this.setState({accountLoading: false})
            })


    }

    getBlob = (fileName, outputFormat) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var responseType = null
        if (outputFormat === 'pdf') {
            responseType = 'application/pdf'
        }
        if (outputFormat === 'xlsx') {
            responseType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }



        axios(`api/ssrsGetPdfBlob?company=${this.props.user.company}&fileName=` + fileName, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: headers
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    { type: responseType });
                const fileURL = URL.createObjectURL(file);//Build a URL from the file
                if (outputFormat === 'pdf') {
                    this.setState({
                        pdfFile: fileURL, pdfFlag: true, pdfScale: 1.25
                    });
                }
                if (outputFormat === 'xlsx') {
                    //window.open(fileURL + ".xlsx")
                    this.saveExcelFile(file, fileName)
                    this.setState({ generateExcelButtonFlag: true })
                    //window.location.assign(fileURL)
                }
            })
            .catch(error => {
                console.error(error);
            });

        this.setState({ runReportButtonFlag: true, status: "", loaderSpinnerFlag: false })
    }

    saveExcelFile = (blob, filename) => {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }


    checkBlob = (fileName, callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetPdfBlob?company=${this.props.user.company}&fileName=` + fileName, {
            method: 'GET',
            //responseType: 'blob', //Force to receive data in a Blob Format
            headers: headers
        })
            .then(response => {

                if (response.data === "no data") {
                    callback(false)
                }
                else {
                    callback(true)
                }
            })
            .catch(error => {
                console.error(error);
            });

    }

    getValidValues = (report, parameter, callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetParameterValidValuesQuery`, {
            method: 'GET',
            params: { report: report, parameter: parameter, company: this.props.user.company },
            //responseType: 'blob', //Force to receive data in a Blob Format
            headers: headers
        })
            .then(response => {

                if (response.data === "no data") {
                    callback(false)
                }
                else {
                    callback(response.data)
                }
            })
            .catch(error => {
                console.error(error);
            });

    }

    getDefaultValue = (report, parameter, callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetParameterDefaultValueQuery`, {
            method: 'GET',
            params: { report: report, parameter: parameter, company: this.props.user.company },
            //responseType: 'blob', //Force to receive data in a Blob Format
            headers: headers
        })
            .then(response => {

                if (response.data === "no data") {
                    callback(false)
                }
                else {
                    callback(response.data)
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    getDate(sqlDate, isUTC) {
        var d;
        if (parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")) {
            d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")
        }
        else {
            d = parseDate(sqlDate, "MM/dd/yyyy HH:mm:ss")
        }

        if (isUTC) {
            var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
            return utc;
        }

        else
            return d;
    }

    formatDate(date) {
        var d;
        var ds;
        if (parseDate(date, "yyyy-MM-ddTHH:mm:ss.SSSXXX")) {
            d = parseDate(date, "yyyy-MM-ddTHH:mm:ss.SSSXXX")
            ds = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear()
            console.log(d.getMonth() + 1 + " " + d.getDate() + " " + d.getFullYear())
        }
        else {
            d = parseDate(date, "MM/dd/yyyy HH:mm:ss")
            ds = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear()
            console.log(d.getMonth() + 1 + " " + d.getDate() + " " + d.getFullYear())
        }
        return ds;
    }

    getButtonStatus() {
        if (!this.state.runReportButtonFlag || this.state.paramTrackingCount !== 0) {
            return true
        }
        else {
            return false
        }

    }

    validate(input) {
        var flag = true;
        var isBlank = false;
        var isIncorrectDate = false;

        const { parameterList } = this.state
        if (input === undefined) {//check every parameter, e.g. for when user clicks Run Report
            parameterList.map(form => {
                if (form.allowBlank.toLowerCase() === "false" && (this.state["SSRSParam_" + form.name] === "" || this.state["SSRSParam_" + form.name] === null)) {

                    this.setState({ ["SSRSParam_" + form.name + "_Validate"]: false })
                    flag = false
                    isBlank = true

                }
                if (this.state.selectedMode !== 'group' && this.props.type !== 'edittemplate' && (form.name === 'PortfolioList' && (this.state["SSRSParam_" + form.name] === "" || this.state["SSRSParam_" + form.name] === null || this.state["SSRSParam_" + form.name].length === 0))) {

                    this.setState({ ["SSRSParam_" + form.name + "_Validate"]: false })
                    flag = false
                    isBlank = true

                }
                if (form.name.toLowerCase() === "fromdate" && (!this.state.singleDateGenericDateToggle && !this.state.fromDateGenericDateToggle && !this.state.toDateGenericDateToggle)) {
                    if (this.getDate(this.state.SSRSParam_fromDate, false).getTime() > this.getDate(this.state.SSRSParam_toDate, false).getTime()) {
                        this.setState({ SSRSParam_fromDate_Validate: false, SSRSParam_toDate_Validate: false })
                        flag = false
                        isIncorrectDate = true
                    }


                }
            })

        }
        else {
            if (this.state[input + "_Validate"] === false) {
                this.setState({ [input + "_Validate"]: true })

                if (input.toLowerCase().includes("date")) {
                    const { ...inputFields } = this.state
                    Object.entries(inputFields).map(([key, value]) => {
                        if (key.toLowerCase().includes("date_") && key.includes("_Validate") && value !== null) {
                            this.setState({ [key]: true })
                        }
                    })
                }

            }
        }

        if (flag === false) {
            var errorString = ''
            if (isBlank) {
                errorString = errorString.concat('Highlighted fields must not be blank.\r\n')
            }
            if (isIncorrectDate) {
                errorString = errorString.concat('Begin Date must precede End Date.\r\n')
            }
            alert(errorString);
        }

        return flag;

    }

    getReportDefaults() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetReportDefaults`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
        })
            .then(response => {
                this.setState({ defaultList: response.data.data })
            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getThisDefaultValue(reportName, paramName) {
        var tempDefaultValue = null

        if (paramName === 'ReportTitle'
            || paramName === 'Layout'
            || paramName === 'Disclaimer'
            || paramName === 'FeeMethod'
            || paramName === 'Periods'
            || paramName === 'Period1'
            || paramName === 'Period2'
            || paramName === 'ReportTitle1'
            || paramName === 'ReportTitle2'
            || paramName === 'ReportTitle3'
            || paramName === 'ReportTitle4'
            || paramName.toLowerCase() === 'equityassetclass'
            || paramName.toLowerCase() === 'securityclassification'
            || paramName.toLowerCase() === 'includeai'
            || paramName.toLowerCase() === 'companyname'
            || paramName.toLowerCase() === 'includeportfoliocode'
            || paramName.toLowerCase() === 'bonddescription'
            || paramName.toLowerCase() === 'includesp'
            || paramName.toLowerCase() === 'includemoody'
            || paramName.toLowerCase() === 'includefitch'
            || paramName.toLowerCase() === 'showcash'
            || paramName.toLowerCase() === 'accountnumber'
            || paramName.toLowerCase() === 'showauthorization'
            || paramName.toLowerCase() === 'rounding'
            || paramName.toLowerCase() === 'billfor') {
            this.state.defaultList.forEach(row => {
                if (reportName === row.reportName) {
                    if (paramName === 'ReportTitle') {
                        tempDefaultValue = row.reportTitle
                    }
                    if (paramName === 'Layout') {
                        tempDefaultValue = row.layout
                    }
                    if (paramName === 'Disclaimer') {
                        tempDefaultValue = row.disclaimer
                    }
                    if (paramName === 'FeeMethod') {
                        tempDefaultValue = row.feeMethod
                    }
                    if (paramName === 'Periods' || paramName === 'Period1') {
                        tempDefaultValue = row.period1
                    }
                    if (paramName === 'Period2') {
                        tempDefaultValue = row.period2
                    }
                    if (paramName === 'ReportTitle1') {
                        tempDefaultValue = row.reportTitle1
                    }
                    if (paramName === 'ReportTitle2') {
                        tempDefaultValue = row.reportTitle2
                    }
                    if (paramName === 'ReportTitle3') {
                        tempDefaultValue = row.reportTitle3
                    }
                    if (paramName === 'ReportTitle4') {
                        tempDefaultValue = row.reportTitle4
                    }
                    if (paramName.toLowerCase() === 'equityassetclass') {
                        tempDefaultValue = row.equityAssetClass
                    }
                    if (paramName.toLowerCase() === 'securityclassification') {
                        tempDefaultValue = row.securityClassification
                    }
                    if (paramName.toLowerCase() === 'includeai') {
                        tempDefaultValue = row.includeAI
                    }
                    if (paramName.toLowerCase() === 'companyname') {
                        tempDefaultValue = row.companyName
                    }
                    if (paramName.toLowerCase() === 'includeportfoliocode') {
                        tempDefaultValue = row.includePortfolioCode
                    }
                    if (paramName.toLowerCase() === 'bonddescription') {
                        tempDefaultValue = row.bondDescription
                    }
                    if (paramName.toLowerCase() === 'includesp') {
                        tempDefaultValue = row.includeSP
                    }
                    if (paramName.toLowerCase() === 'includemoody') {
                        tempDefaultValue = row.includeMoody
                    }
                    if (paramName.toLowerCase() === 'includefitch') {
                        tempDefaultValue = row.includeFitch
                    }
                    if (paramName.toLowerCase() === 'showcash') {
                        tempDefaultValue = row.showCash
                    }
                    if (paramName.toLowerCase() === 'showauthorization') {
                        tempDefaultValue = row.ShowAuthorization
                    }
                    if (paramName.toLowerCase() === 'accountnumber') {
                        tempDefaultValue = row.AccountNumber
                    }
                    if (paramName.toLowerCase() === 'rounding') {
                        tempDefaultValue = row.Rounding
                    }
                    if (paramName.toLowerCase() === 'billfor') {
                        tempDefaultValue = row.BillFor
                    }

                }
            })
        }

        return tempDefaultValue
    }

    getParameterDefaults = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var report = this.state.report.name;
        var parameterList = []
        parameterList = parameterList.concat(this.state.parameterList)
        var originalParameterList = JSON.parse(JSON.stringify(parameterList)) 
        axios(`api/ssrsGetParameterDefaults`, {
          method: 'GET',
          params: { report: report, includeGlobals: true, company: this.props.user.company },
          headers: headers
        })
          .then(response => {
    
            if (response.data.code === 200) {
              parameterList.forEach(param => {
                response.data.data.forEach(defaultParam => {
                  if (param.name === defaultParam.paramName) {
                    if (param.type === "Dropdown"){
                      const newDefault = param.values.find(item => item.value === defaultParam.value)
                      param.defaultValue = newDefault
                      this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                    }
                    else if (param.type === "DateTime"){
                        if (defaultParam.value.length === 4) {//if generic date
                            const newDefault = this.state["genericDates_" + defaultParam.paramName + "_Data"].find(item => item.value === defaultParam.value)
                            param.defaultValue = newDefault
                            this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault, [defaultParam.paramName + "GenericDateToggle"]: true })
                        }
                        else{
                            const newDefault = this.getDate(defaultParam.value, true)
                            param.defaultValue = newDefault
                            this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                        }
                      }
                    else{
                      param.defaultValue = defaultParam.value
                      this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: defaultParam.value })
                    }
                    param.defaultSet = true
                  }
                })
              })
              console.log(parameterList)
              this.setState({ parameterList: parameterList, originalParameterList: originalParameterList, reportParametersFlag: true, runReportButtonFlag: true })
            }
            else {
              alert("Error getting report defaults")
            }
          })
          .catch(error => {
            console.error(error);
          });
      }


    getReportParameters() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var report = this.state.report.name;
        var parameters = [];
        var defaultParameters = [];
        this.checkParamTracking();
        //var adjustedDefaultValue = null

        if (this.state.report.adjustableLayout === true) {
            report = report + "_Landscape"
        }

        axios(`api/ssrsGetParameterDefaults`, {
            method: 'GET',
            params: { report: report, includeGlobals: true, company: this.props.user.company },
            headers: headers
          })
            .then(defaultParameterResponse => {
      
              if (defaultParameterResponse.data.code === 200) {
                defaultParameters = defaultParameterResponse.data.data
                console.log(defaultParameters)

                axios(`api/ssrsGetReportParameters?company=${this.props.user.company}&report=` + report, {
                    method: 'GET',
                    headers: headers
                })
                    .then(response => {
        
                        this.setState({ paramTrackingCount: 0, reportParameters: response.data.data })
                        //begin loop through response
                        //response.data.data.map((individualParameter) => {
                        response.data.data.forEach(individualParameter => {
                            if (individualParameter.ParamName !== "database") {
                                const t = "SSRSParam_".concat([individualParameter.ParamName])
        
                                var adjustedDefaultValue = null //this.getThisDefaultValue(this.state.report.name, individualParameter.ParamName)
        
                                if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                                    parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: this.state.accounts[0], values: this.state.accounts, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                    parameters.sort(this.compareParamOrder)
                                    this.setState({
                                        [t]: [], [t.concat("_Data")]: this.state.accounts, [t.concat("_DataOriginal")]: this.state.accounts, [t.concat("_Validate")]: true
                                    })
                                }
                                else {
                                    if (individualParameter.ParamDataType === "String" && individualParameter.ValidValues !== "") {
                                        var values = []
                                        var XMLParser = require('react-xml-parser');
                                        var xml = new XMLParser().parseFromString(individualParameter.ValidValues);
                                        xml.getElementsByTagName('ValidValue').forEach(value => {
                                            values.push({ value: value.children[0].value, label: value.children[1].value })
                                        })
        
                                        if (individualParameter.DefaultValues !== null) {
                                            const defaultValue = values.find(item => item.value === individualParameter.DefaultValues);
                                            adjustedDefaultValue = values.find(item => item.value === adjustedDefaultValue);
        
                                            //if (defaultValue){adjustedDefaultValue = {value: adjustedDefaultValue, label: defaultValue.label}}
        
                                            parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                            parameters.sort(this.compareParamOrder)
                                            this.setState({
                                                [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                                            })
                                        }
                                        else {
                                            parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || values[0], values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                            parameters.sort(this.compareParamOrder)
                                            this.setState({
                                                [t]: adjustedDefaultValue || values[0], [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                                            })
                                        }
                                    }
                                    else if (individualParameter.DynamicValidValues === "True") {
                                        this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                                        this.getValidValues(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                                            if (callback !== false) {
                                                var lowerValues = []
                                                callback.forEach(obj => {
                                                    var key, keys = Object.keys(obj);
                                                    var n = keys.length;
                                                    var newobj = {}
                                                    while (n--) {
                                                        key = keys[n];
                                                        newobj[key.toLowerCase()] = obj[key];
                                                    }
                                                    lowerValues.push(newobj)
                                                })
        
                                                if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                                                    var splitLine
                                                    var code
                                                    var name
                                                    var type
                                                    var formattedLine
                                                    var newLowerValues = []
                                                    lowerValues.forEach(line => {
                                                        splitLine = line.label.split('|')
                                                        code = this.getSpacedLine(splitLine[0], 12)
                                                        name = this.getSpacedLine(splitLine[1], 32)
                                                        type = this.getSpacedLine(splitLine[2], 8)
        
                                                        formattedLine = code + " | " + name + " | " + type
                                                        newLowerValues.push({ value: line.value, label: formattedLine })
                                                    })
                                                    lowerValues = newLowerValues
        
                                                }
        
        
                                                if (individualParameter.DefaultValues !== null || individualParameter.ParamName === "equityAssetClass") {
                                                    const defaultValue = lowerValues.find(item => item.value === individualParameter.DefaultValues);
                                                    adjustedDefaultValue = lowerValues.find(item => item.value === adjustedDefaultValue);
                                                    parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                                    parameters.sort(this.compareParamOrder)
                                                    this.setState({
                                                        [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                                                    })
                                                }
                                                else if (individualParameter.DefaultValues === null && individualParameter.DynamicDefaultValue === "True") {
                                                    this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                                                    this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                                                        if (callback !== "false") {
                                                            const lowerDefaultValue = []
                                                            callback.forEach(obj => {
                                                                var key, keys = Object.keys(obj);
                                                                var n = keys.length;
                                                                var newobj = {}
                                                                while (n--) {
                                                                    key = keys[n];
                                                                    newobj[key.toLowerCase()] = obj[key];
                                                                }
                                                                lowerDefaultValue.push(newobj)
                                                            })
                                                            const defaultValue = lowerValues.find(item => item.value === lowerDefaultValue[0].value);
                                                            adjustedDefaultValue = null //this.getThisDefaultValue(this.state.report.name, individualParameter.ParamName)
                                                            adjustedDefaultValue = lowerValues.find(item => item.label === adjustedDefaultValue);
        
                                                            if (individualParameter.ParamName === 'Disclaimer'){
                                                                lowerValues.forEach(v => {
                                                                  v.value = v.label
                                                                })
                                                                if (adjustedDefaultValue)
                                                                    adjustedDefaultValue.value = adjustedDefaultValue.label
                                                            }
                                                            parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                                            parameters.sort(this.compareParamOrder)
                                                            this.setState({
                                                                [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                                                            })
                                                        }
                                                        this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                                                    })
        
                                                }
                                                else {
                                                    parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: lowerValues[0], values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                                    parameters.sort(this.compareParamOrder)
                                                    if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                                                        this.setState({
                                                            [t]: [], [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            [t]: '', [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                                                        })
                                                    }
                                                }
        
                                            }
                                            this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                                        })
        
                                        //parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                                        //this.setState({
                                        //  [t]: individualParameter.DefaultValues
                                        //})
        
                                    }
                                    else if (individualParameter.DynamicDefaultValue === "True" && individualParameter.ParamDataType === "DateTime") {
                                        this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                                        this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                                            if (callback !== false) {
                                                const lowerDefaultValue = []
                                                callback.forEach(obj => {
                                                    var key, keys = Object.keys(obj);
                                                    var n = keys.length;
                                                    var newobj = {}
                                                    while (n--) {
                                                        key = keys[n];
                                                        newobj[key.toLowerCase()] = obj[key];
                                                    }
                                                    lowerDefaultValue.push(newobj)
                                                })
                                                var defaultDate = this.getDate(lowerDefaultValue[0].value, true)
                                                this.setState({ [individualParameter.ParamName.concat('_OriginalDefault')]: defaultDate })
                                                parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: defaultDate, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                                parameters.sort(this.compareParamOrder)
                                                this.setState({
                                                    [t]: defaultDate, [t.concat("_Validate")]: true
                                                })
        
        
                                            }
                                            this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                                        })
        
                                        //parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                                        //this.setState({
                                        //  [t]: individualParameter.DefaultValues
                                        //})
                                    }
                                    else {
                                        parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || individualParameter.DefaultValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                        parameters.sort(this.compareParamOrder)
                                        if (individualParameter.DefaultValues !== null) {
                                            this.setState({
                                                [t]: adjustedDefaultValue || individualParameter.DefaultValues, [t.concat("_Validate")]: true
                                            })
                                        }
                                        else {
                                            this.setState({
                                                [t]: '', [t.concat("_Validate")]: true
                                            })
                                        }
                                    }
                                }
        
                            }
        
                        })
                        //end loop through response
        
                    })
                    .catch(err => {
                        console.error(err);
                    })
                //return reports;
        
                this.setState({ parameterList: parameters }, () => {
                    //this.setState({ reportParametersFlag: true, runReportButtonFlag: true })
                });
                if (this.state.report.adjustableLayout === true) {
                    //this.setState({ reportParametersFlag: true })
                }

      
              }
              else {
                alert("Error getting report defaults")
              }
            })
            .catch(error => {
              console.error(error);
            });
            
        //axios
        //  .post('api/ssrsGetReportParameters', payload, { headers })
        

    }

    checkParamTracking() {
        var checkTimer = setInterval(
          function () {
            //test = this.checkBlob(fileNameIn)
            if (this.state.paramTrackingCount === 0){
              this.getParameterDefaults()
              clearInterval(checkTimer)
            }
    
          }
            .bind(this),
          2000
        )
      }
    

    getSpacedLine(line, maxLength) {
        var newLine = line
        if (line.length > maxLength) {
            newLine = line.substring(0, maxLength - 3) + '...'
        }
        else if (line.length < maxLength) {
            for (var i = line.length; i < maxLength; i++) {
                newLine = newLine + " "
            }
        }
        return newLine
    }

    compareParamOrder(a, b) {
        if (parseInt(a.sortOrder) < parseInt(b.sortOrder)) {
            return -1;
        }
        if (parseInt(a.sortOrder) > parseInt(b.sortOrder)) {
            return 1;
        }
        return 0;
    }

    compareReportOrder(a, b) {
        if (parseInt(a.value) < parseInt(b.value)) {
            return -1;
        }
        if (parseInt(a.value) > parseInt(b.value)) {
            return 1;
        }
        return 0;
    }

    determineIndividualOrGroup(reportList) {

        if (reportList){
            for (let report of reportList){
                if (report.contentType && report.contentType.toLowerCase().includes('advance')){
                    return "group"
                }
            }
        }

        return 'individual'
        
    }


    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    clearState = (e) => {
        this.setState({
            username: this.props.user,
            runReportButtonFlag: false,

            layout: null,
            report: { name: "Select Report", key: null, adjustableLayout: null },
            account: { account: "", displayName: "" },
            numPages: null,
            pageNumber: 1,
            pdfFile: null,
            pdfFlag: false,
            pdfScale: 1.25,
            status: "",
            loaderSpinnerFlag: false,
            checkPdfBlobTimerId: null,//used in componentWillUnmount cleanup
            generateExcelButtonFlag: true,
            reportParameters: [],
            reportParametersFlag: false,
            parameterList: [],
            packageName: null,
            packageDesc: '',
            frequency: null,
            showReport: false,
            showReportText: "Add Content",
            selectedAccount: { value: '', label: '' },
            reportsList: [],
            reportIdCount: 1,
            editPackageName: false,
            editPackageDesc: false,
            editEntity: false,
            editFrequency: false,
            editSingleFile: false,
            editAdvanced: false,
            selectedReport: null,
            contentType: "Report",
            selectedPdf: null,
            singleFileCheckbox: false,
            singleFileName: '',
            advancedMode: false,
            hierarchy: "Top Level",
            mutliAdded: '',
        })

        const { ...stateObject } = this.state

        Object.entries(stateObject).map(([key, value]) => {
            if (key.includes("SSRSParam_")) {
                this.setState({ [key]: null })
            }
        })

        this.props.closeRefresh()
    }

    getBaseItemStyle = (isActive) => ({
        fontSize: '16px',
        textAlign: 'left',
        outline: 'none',
        cursor: 'move',
        border: '1px solid',
        width: '300px',
        borderColor: isActive ? '#27aceb' : 'black',
        background: 'white',
        marginBottom: '5px',
        marginLeft: '5px'
    });

    sortItemUI = (props) => {
        const { isActive, style, attributes, dataItem, forwardRef } = props;
        const classNames = ['col-xs-6 col-sm-3'];

        return (
            <div ref={forwardRef}
                {...attributes}
                style={{
                    ...this.getBaseItemStyle(isActive),
                    ...style
                }}
                className={classNames.join(' ')}
            >
                {dataItem.text}
            </div>
        )
    }

    emptyUI = (props) => {
        return (<div></div>)

    }

    onDragOver = (e) => {
        this.setState({ reportsList: e.newState });
    }

    onNavigate = (e) => {
        this.setState({ reportsList: e.newState });

    }

    onDragStart = (e) => {
        //this.setState({ reportsList: e.newState });
        //console.log(e)
    }

    onDragEnd = (e) => {
        //this.setState({ reportsList: e.newState });
        //console.log(e)
    }

    _itemRender = (li, itemProps) => {
        const itemChildren = <span>{itemProps.dataItem.label}</span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    _handleChangePortfolio = e => {
        const t = "SSRSParam_".concat([e.target.props.name])
        //this.validate(t)
        var alreadyInListAsConsolidated = false //will be true if you are adding a group and the group is already in the list as consolidated.
        if (e.target.value.length > this.state[t].length && this.state[t].length > 0) {//checks if adding an entity, rather than deleting
            var entityToAdd = e.target.value[e.target.value.length - 1]
            if (entityToAdd.value.includes('@')) {
                this.state[t].forEach(entity => {
                    if (entityToAdd.value === entity.value.replace('+', '')) {
                        alreadyInListAsConsolidated = true;
                    }
                })
            }
        }
        if (!alreadyInListAsConsolidated) {
            this.setState({
                [t]: e.target.value
            })
        }
    }

    _handleChangeTemplateGroup = e => {
        this.setState({
            templateGroup: e.target.value
        })
    }


    render() {
        const frequencyDropdown = ["Ad-Hoc","Daily", "Monthly", "Quarterly", "Annually"]
        const contentTypeDropdown = ["Report", "PDF"]

        //const packageGroupPortfolioDropdown = ["Run for individual members", "Run on household (Consolidated)", "Run on household (Unconsolidated)"]
        const packageGroupPortfolioDropdown = ["Run as is", "Run Consolidated", "Run Unconsolidated"]

        //const packageGroupPdfDropdown = ["Print once", "Print for each household"]

        var fileNameLabelColor = ''
        if (!this.state.singleFileCheckbox) {
            fileNameLabelColor = '#d7d7d7'
        }

        var modeLabelColor = ''
        if (this.state.reportsList.length > 0) {
            modeLabelColor = '#d7d7d7'
        }

        const hierarchyDropdown = ["Top Level", "Multi Level", "Bottom Level"]


        const { parameterList } = this.state;

        if (this.props.type === 'create') {
            return (
                <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                    {close => (
                        <div className="modal">
                            <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />



                            <div className="header" align="left">
                                Create Package
                            </div>
                            <div className="content">
                            <br />

                            <label>Package Name: </label><Input style={{ marginLeft: "3px" }} value={this.state.packageName} type="text" name="packageName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageName')} />
                            <br />
                            <label>Description: </label><Input value={this.state.packageDesc} type="text" name="packageDesc" style={{ width: "650px", marginLeft: "25px" }} autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageDesc')} />
                            <br />
                            <label>Frequency: </label><DropDownList style={{ marginLeft: "14px" }} data={frequencyDropdown} value={this.state.frequency} onChange={(e) => this.onChangeHandler(e, 'frequency')} />
                            <br /><br />
                            <RadioGroup name="mode" selectedValue={this.state.selectedMode} onChange={(e) => this.setState({ selectedMode: e, singleFileCheckbox: false })}>
                                <label style={{ color: modeLabelColor }}><Radio value="individual" disabled={this.state.reportsList.length > 0} />Individual Member Mode</label>
                                <br/>
                                <label style={{ color: modeLabelColor }}><Radio value="group" disabled={this.state.reportsList.length > 0} />Group Mode</label>
                            </RadioGroup>
                            <label>Reporting Entity: </label>
                            <ComboBox
                                name="accountList"
                                data={this.state.selectedMode === 'individual' ? this.state.accounts : this.state.groups}
                                textField="label"
                                dataItemKey="value"
                                filterable={true}
                                header={<span>Code          |Name                              </span>}
                                style={{ width: "400px" }}
                                popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                onFilterChange={this._filterChange}
                                value={this.state.selectedAccount}
                                onChange={(e) => this.onChangeHandler(e, 'selectedAccount')}
                            />
                            {/* <label>Advanced Groups: </label><Switch style={{ marginLeft: "14px" }} value={this.state.packageGroupCheckbox} disabled={!this.state.packageGroupCheckboxEnable || this.state.advancedMode} checked={this.state.packageGroupCheckbox} onChange={(e) => this.onChangeHandlerPackageGroup(e)} /> */}
                            <br />
                            {this.state.selectedMode === 'group' && <><label>Output as Single File: </label><Switch style={{ marginLeft: "14px" }} value={this.state.singleFileCheckbox} onChange={(e) => this.onChangeHandlerSingleFile(e)} /></>}
                            {/* <br/><label style={{ color: fileNameLabelColor }}> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                            {/* <br/><label> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                            <br/><label> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Name of File:": "Name of File:"} </label>

                            {/*<Input style={{ marginLeft: "3px" }} disabled={!this.state.singleFileCheckbox} value={this.state.singleFileName} type="text" name="packageFileName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'singleFileName')} />*/}
                            <ComboBox
                                name="householdList"
                                data={this.state.householdList}
                                //textField="label"
                                //dataItemKey="value"
                                filterable={true}
                                //header={<span>Code          |Name                              </span>}
                                style={{ marginLeft: "3px", width: "200px" }}
                                //popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                allowCustom={true}
                                onFilterChange={(e) => this.setState({ householdList: this.filterData(e.filter, this.state.householdListForFilter) })}
                                //disabled={!this.state.singleFileCheckbox}
                                value={this.state.singleFileName}
                                onChange={(e) => this.onChangeHandler(e, 'singleFileName')}
                            />
                            <br /><br />
                            {/* <label>Advanced Mode: </label><Switch style={{ marginLeft: "14px" }} disabled={this.state.reportsList.length > 0 || this.state.packageGroupCheckbox} value={this.state.advancedMode} checked={this.state.advancedMode} onChange={(e) => this.onChangeHandler(e, 'advancedMode')} />
                            <br /> */}
                            <br />
                            <DropDownList data={contentTypeDropdown} disabled={this.state.showReport} value={this.state.contentType} onChange={(e) => this.onChangeHandler(e, 'contentType')}></DropDownList><Button style={{ marginLeft: "5px" }} onClick={(e) => { this.setState({ showReport: !this.state.showReport }); if (this.state.showReportText !== "Cancel") { this.setState({ showReportText: "Cancel" }) } else { this.setState({ showReportText: "Add Content" }) } }} >{this.state.showReportText}</Button>
                            <br />


                            {this.state.showReport && this.state.contentType === 'Report' && <div>
                                <br />
                                <div id="reportSelect" style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                    <label>Report: </label><DropDownList data={this.state.reports} loading={this.state.accountLoading} disabled={this.state.accountLoading} textField="name" dataItemKey="key" style={{ width: '300px' }} value={this.state.report} onChange={(e) => this.onReportChangeHandler(e)} />
                                    {/* {this.state.selectedMode === 'individual' && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                    <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                    <br></br>
                                    <div style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                        {this.state.reportParametersFlag && this.state.paramTrackingCount === 0 &&
                                            <Grid fluid>
                                                <Row>
                                                    {/* {this.state.selectedMode === 'group' && this.state.hierarchy !== 'Multi Level' && */}
                                                    {this.state.selectedMode === 'group' &&
                                                        <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                            <br></br>
                                                            <label>Group Entity Behavior: </label>
                                                            <br></br>
                                                            <DropDownList
                                                                disabled={this.state.mutliAdded && this.state.hierarchy === 'Multi Level'}
                                                                data={packageGroupPortfolioDropdown}
                                                                value={this.state.packageGroupPortfolio}
                                                                onChange={(e) => this.onChangeHandler(e, 'packageGroupPortfolio')}>
                                                            </DropDownList>
                                                        </Col>
                                                    }
                                                    {parameterList.map((form) => {
                                                        const t = "SSRSParam_".concat([form.name])
                                                        if (form.type === "String") {
                                                            return (
                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputTextField
                                                                        name={form.name}
                                                                        input_type={form.type}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        value={this.state[t]}
                                                                        defaultValue={form.defaultValue}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>
                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'singleDate') {
                                                            return (
                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button alt={"hello"} onClick={(e) => this.onGenericToggleChangeHandler(true, 'singleDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_singleDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'singleDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'fromDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'fromDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_fromDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'fromDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'toDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'toDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_toDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'toDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "Boolean") {
                                                            var defaultChecked = ''
                                                            if (form.defaultValue.toLowerCase() === 'true') {
                                                                defaultChecked = true
                                                            }
                                                            else if (form.defaultValue.toLowerCase() === 'false') {
                                                                defaultChecked = false
                                                            }
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputBoolean
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        value={this.state[t]}
                                                                        defaultChecked={defaultChecked}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "Dropdown" && (form.name === "PortfolioTest" || form.name === "PortfolioList") && this.state.selectedMode !== 'group') {
                                                            return (
                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputPortfolioDropdown
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        data={this.state[t.concat("_Data")]}
                                                                        value={this.state[t]}
                                                                        key={form.label}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChangePortfolio={this._handleChangePortfolio}
                                                                        _itemRender={this._itemRender}
                                                                        defaultValue={this.state.selectedAccount}
                                                                    />
                                                                    <Button onClick={(e) => this.consolidateGroups()} >Consolidate</Button>
                                                                </Col>
                                                            )
                                                        }
                                                        else if (form.type === "Dropdown" && (form.name !== "PortfolioTest" && form.name !== "PortfolioList")) {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputDropdown
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        data={this.state[t.concat("_Data")]}
                                                                        value={this.state[t]}
                                                                        key={form.label}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Grid>
                                        }
                                    </div>
                                    <br></br>


                                    <Button onClick={(e) => { this.submitForm() }} disabled={this.getButtonStatus()}>Add Report</Button>
                                    <br></br><br></br>
                                </div>




                            </div >
                            }

                            {this.state.showReport && this.state.contentType === 'PDF' && <div>
                                <br />
                                <label>PDF: </label><DropDownList data={this.state.pdfList} textField="name" dataItemKey="key" value={this.state.selectedPdf} onChange={(e) => this.onChangeHandler(e, 'selectedPdf')} />
                                {/* {this.state.selectedMode === 'group' &&
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>Group Entity Behavior: </label>
                                        <br></br>
                                        <DropDownList
                                            data={packageGroupPdfDropdown}
                                            value={this.state.packageGroupPdf}
                                            onChange={(e) => this.onChangeHandler(e, 'packageGroupPdf')}>
                                        </DropDownList>
                                    </Col>
                                } */}
                                {/* {this.state.selectedMode === 'individual' && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                <br /><br />
                                <Button onClick={(e) => { this.submitForm(); this.setState({ showReport: !this.state.showReport, showReportText: "Add Content" }) }} disabled={!this.state.selectedPdf}>Add PDF</Button>
                            </div>
                            }

                            <br />
                            {this.state.reportsList.length > 0 &&
                                <div>
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('up')} icon='sort-asc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('down')} icon='sort-desc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.deleteReportFromPackage()} icon='delete' >Delete</Button>
                                    <div className="scroll-box">
                                        <div className="scroll-box__wrapper">
                                            <div className="scroll-box__container" role="list">
                                                <RadioGroup name="reports" selectedValue={this.state.selectedReport} onChange={(e) => this.onRadioChangeHandler(e)}>
                                                    {this.state.reportsList.map(item => {
                                                        var labelText

                                                        labelText = item.text

                                                        if (item.reportDate !== '') {
                                                            labelText = labelText + " | " + item.reportDate
                                                        }

                                                        if (item.hierarchy) {
                                                            labelText = labelText + " | " + item.hierarchy
                                                        }

                                                        return (<div><Radio id={item.text} value={item} /><label htmlFor={item.text} onChange={(e) => this.onChangeHandler(e, 'selectedReport')}>{labelText}</label><br /></div>)
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="actions">              
                                      
                              <Button 
                                className="upload"  
                                onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })}>
                                    Save Package
                              </Button>
                            </div>

                            {/* <div style={{ display: "flex" }}>
                                <Button onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Package</Button>
                            </div> */}



                        </div>
                    )
                    }
                </Popup >

            );
        }
        else if (this.props.type === 'edit') {
            return (
                <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                    {close => (
                        <div className="modal">
                            <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />



                            <div className="header" align="left">
                                Edit Package
                            </div>
                            <div className="content">
                            <br />

                            <label>Package Name: </label>
                            {!this.state.editPackageName &&
                                <Input style={{ marginLeft: "3px" }} disabled={true} value={this.props.package[0].Key} ></Input>
                            }
                            {!this.state.editPackageName &&
                                <Button onClick={(e) => { this.setState({ packageName: this.props.package[0].Key, editPackageName: true }) }} icon="edit"></Button>
                            }
                            {this.state.editPackageName &&
                                <Input style={{ marginLeft: "3px" }} value={this.state.packageName} type="text" name="packageName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageName')} />
                            }
                            <br />
                            <label>Description: </label>
                            {!this.state.editPackageDesc &&
                                <Input style={{ width: "650px", marginLeft: "25px" }} disabled={true} value={this.props.package[0].Description} ></Input>
                            }
                            {!this.state.editPackageDesc &&
                                <Button onClick={(e) => { this.setState({ packageDesc: this.props.package[0].Description, editPackageDesc: true }) }} icon="edit"></Button>
                            }
                            {this.state.editPackageDesc &&
                                <Input style={{ width: "650px", marginLeft: "25px" }} value={this.state.packageDesc} type="text" name="packageDesc" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageDesc')} />
                            }
                            <br />
                            <label>Frequency: </label>
                            {!this.state.editFrequency &&
                                <Input style={{ marginLeft: "28px" }} disabled={true} value={this.props.package[0].Frequency} ></Input>
                            }
                            {!this.state.editFrequency &&
                                <Button onClick={(e) => { this.setState({ frequency: this.props.package[0].Frequency, editFrequency: true }) }} icon="edit"></Button>
                            }
                            {this.state.editFrequency &&
                                <DropDownList style={{ marginLeft: "14px" }} data={frequencyDropdown} value={this.state.frequency} onChange={(e) => this.onChangeHandler(e, 'frequency')} />
                            }
                            <br/><br/>
                            <RadioGroup name="mode" selectedValue={this.determineIndividualOrGroup(this.props.reportList)} onChange={(e) => this.setState({ selectedMode: e, singleFileCheckbox: false })}>
                                <label style={{ color: modeLabelColor }}><Radio value="individual" disabled={true} />Individual Member Mode</label>
                                <br/>
                                <label style={{ color: modeLabelColor }}><Radio value="group" disabled={true} />Group Mode</label>
                            </RadioGroup>
                            <br/>
                            <label>Reporting Entity: </label>
                            {!this.state.editEntity &&
                                <Input style={{ width: "400px" }} disabled={true} value={this.props.package[0].Entity} ></Input>
                            }
                            {!this.state.editEntity &&
                                <Button onClick={(e) => { this.setState({ selectedAccount: { value: '', label: '' }, editEntity: true }) }} icon="edit"></Button>
                            }
                            {this.state.editEntity &&
                                <ComboBox
                                    name="accountList"
                                    data={(this.props.package[0].advancedMode) ? this.state.accounts : this.state.groups}
                                    textField="label"
                                    dataItemKey="value"
                                    filterable={true}
                                    header={<span>Code          |Name                              </span>}
                                    popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                    style={{ width: "400px" }}
                                    onFilterChange={this._filterChange}
                                    value={this.state.selectedAccount}
                                    onChange={(e) => this.onChangeHandler(e, 'selectedAccount')}
                                />
                            }
                            <br />


                            
                            
                            {/* <label>Output as Single File: </label> */}
                            {!this.state.editSingleFile &&
                                <>
                                    {(this.state.selectedMode === 'group' || this.props.package[0].advancedMode === false || this.determineIndividualOrGroup(this.props.reportList) === 'group') && <><label>Output as Single File: </label><Switch disabled={true}  style={{ marginLeft: "14px" }} checked={this.props.package[0].SaveAsSinglePdf} value={this.props.package[0].SaveAsSinglePdf} /></>}
                                    {(this.state.selectedMode === 'group' || this.props.package[0].advancedMode === false || this.determineIndividualOrGroup(this.props.reportList) === 'group') && <><Button onClick={(e) => { this.setState({ singleFileCheckbox: this.props.package[0].SaveAsSinglePdf, singleFileName: this.props.package[0].FileName, editSingleFile: true }) }} icon="edit"></Button></>}
                                    {/* <br/><label style={{ color: fileNameLabelColor }}> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                                    {/* <br/><label> {!this.props.package[0].SaveAsSinglePdf && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Append to Filename": "Name of File:"} </label> */}
                                    <br/><label> {!this.props.package[0].SaveAsSinglePdf && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Name of File:": "Name of File:"} </label>
                                    <Input style={{ marginLeft: "3px" }} disabled={true} value={this.props.package[0].FileName} type="text" name="packageFileName" autoComplete="off" />
                                    <Button onClick={(e) => { this.setState({ singleFileCheckbox: this.props.package[0].SaveAsSinglePdf, singleFileName: this.props.package[0].FileName, editSingleFile: true }) }} icon="edit"></Button>

                                    {/* <Switch style={{ marginLeft: "14px" }} disabled={true} defaultChecked={this.props.package[0].SaveAsSinglePdf} value={this.props.package[0].SaveAsSinglePdf} />
                                    <label style={{ color: fileNameLabelColor }}> Name of File: </label><Input style={{ marginLeft: "3px" }} disabled={true} value={this.props.package[0].FileName} type="text" name="packageFileName" autoComplete="off" />
                                    <Button onClick={(e) => { this.setState({ singleFileCheckbox: this.props.package[0].SaveAsSinglePdf, singleFileName: this.props.package[0].FileName, editSingleFile: true }) }} icon="edit"></Button> */}
                                </>
                            }
                            {this.state.editSingleFile &&
                                <>
                                    {/* <Switch style={{ marginLeft: "14px" }} defaultChecked={this.state.singleFileCheckbox} value={this.state.singleFileCheckbox} onChange={(e) => this.onChangeHandlerSingleFile(e)} />
                                    <label style={{ color: fileNameLabelColor }}> Name of File: </label> */}
                                    {(this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') && <><label>Output as Single File: </label><Switch style={{ marginLeft: "14px" }} checked={this.state.singleFileCheckbox} value={this.state.singleFileCheckbox} onChange={(e) => this.onChangeHandlerSingleFile(e)} /></>}
                                    {/* <br/><label style={{ color: fileNameLabelColor }}> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                                    {/* <br/><label> {!this.state.singleFileCheckbox && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Append to Filename": "Name of File:"} </label> */}
                                    <br/><label> {!this.state.singleFileCheckbox && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Name of File:": "Name of File:"} </label>
                                    {/*<Input style={{ marginLeft: "3px" }} type="text" name="packageFileName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'singleFileName')} />*/}
                                    <ComboBox
                                        name="householdList"
                                        data={this.state.householdList}
                                        //textField="label"
                                        //dataItemKey="value"
                                        filterable={true}
                                        //header={<span>Code          |Name                              </span>}
                                        style={{ marginLeft: "3px", width: "200px" }}
                                        //popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                        allowCustom={true}
                                        onFilterChange={(e) => this.setState({ householdList: this.filterData(e.filter, this.state.householdListForFilter) })}
                                        //disabled={!this.state.singleFileCheckbox}
                                        value={this.state.singleFileName}
                                        onChange={(e) => this.onChangeHandler(e, 'singleFileName')}
                                    />
                                </>
                            }
                            
                            {/* <label>Advanced Mode: </label>
                            {!this.state.editAdvanced &&
                                <Switch style={{ marginLeft: "14px" }} disabled={true} defaultChecked={this.props.package[0].advancedMode} value={this.props.package[0].advancedMode} />
                            }
                            {!this.state.editAdvanced &&
                                <Button onClick={(e) => { this.setState({ advancedMode: this.props.package[0].advancedMode, editAdvanced: true }) }} icon="edit"></Button>
                            }
                            {this.state.editAdvanced &&
                                <Switch style={{ marginLeft: "14px" }} disabled={this.props.reportList.length > 0} defaultChecked={this.state.advancedMode} value={this.state.advancedMode} onChange={(e) => this.onChangeHandler(e, 'advancedMode')} />
                            } */}


                            <br />
                            <br />
                            <DropDownList data={contentTypeDropdown} disabled={this.state.showReport} value={this.state.contentType} onChange={(e) => this.onChangeHandler(e, 'contentType')}></DropDownList><Button style={{ marginLeft: "5px" }} onClick={(e) => { this.setState({ showReport: !this.state.showReport }); if (this.state.showReportText !== "Cancel") { this.setState({ showReportText: "Cancel" }) } else { this.setState({ showReportText: "Add Content" }) } }} >{this.state.showReportText}</Button>
                            <br />


                            {this.state.showReport && this.state.contentType === "Report" && <div>
                                <br />
                                <div id="reportSelect" style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                    <label>Report: </label><DropDownList data={this.state.reports} textField="name" dataItemKey="key" style={{ width: '300px' }} value={this.state.report} onChange={(e) => this.onReportChangeHandler(e)} />
                                    {/* {(this.props.package[0].advancedMode) && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                    <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                    <br></br>
                                    <div style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                        {this.state.reportParametersFlag && this.state.paramTrackingCount === 0 &&
                                            <Grid fluid>
                                                <Row>
                                                    {/* {this.props.package[0].advancedMode === false && this.state.hierarchy !== 'Multi Level' && */}
                                                    {this.props.package[0].advancedMode === false &&
                                                        <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                            <br></br>
                                                            <label>Group Entity Behavior: </label>
                                                            <br></br>
                                                            <DropDownList
                                                                disabled={this.state.mutliAdded && this.state.hierarchy === 'Multi Level'}
                                                                data={packageGroupPortfolioDropdown}
                                                                value={this.state.packageGroupPortfolio}
                                                                onChange={(e) => this.onChangeHandler(e, 'packageGroupPortfolio')}>
                                                            </DropDownList>
                                                        </Col>
                                                    }
                                                    {parameterList.map(form => {
                                                        const t = "SSRSParam_".concat([form.name])
                                                        if (form.type === "String") {
                                                            return (
                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputTextField
                                                                        name={form.name}
                                                                        input_type={form.type}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        value={this.state[t]}
                                                                        defaultValue={form.defaultValue}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>
                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'singleDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'singleDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_singleDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'singleDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'fromDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99}>
                                                                    <br></br>
                                                                    {!this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'fromDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_fromDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'fromDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'toDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'toDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_toDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'toDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "Boolean") {
                                                            var defaultChecked = ''
                                                            if (form.defaultValue.toLowerCase() === 'true') {
                                                                defaultChecked = true
                                                            }
                                                            else if (form.defaultValue.toLowerCase() === 'false') {
                                                                defaultChecked = false
                                                            }
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputBoolean
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        value={this.state[t]}
                                                                        defaultChecked={defaultChecked}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "Dropdown" && (form.name === "PortfolioTest" || form.name === "PortfolioList") && this.props.package[0].advancedMode === true) {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputPortfolioDropdown
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        data={this.state[t.concat("_Data")]}
                                                                        value={this.state[t]}
                                                                        key={form.label}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChangePortfolio={this._handleChangePortfolio}
                                                                        _itemRender={this._itemRender}
                                                                        defaultValue={this.state.selectedAccount}
                                                                    />
                                                                    <Button onClick={(e) => this.consolidateGroups()} >Consolidate</Button>
                                                                </Col>

                                                            )
                                                        }
                                                        else if (form.type === "Dropdown" && (form.name !== "PortfolioTest" && form.name !== "PortfolioList")) {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputDropdown
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        data={this.state[t.concat("_Data")]}
                                                                        value={this.state[t]}
                                                                        key={form.label}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Grid>
                                        }
                                    </div>
                                    <br></br>


                                    <Button onClick={(e) => { this.submitForm()}} disabled={this.getButtonStatus()}>Add Report</Button>
                                    <br></br><br></br>
                                </div>
                            </div >
                            }

                            {this.state.showReport && this.state.contentType === 'PDF' && <div>
                                <br />
                                <label>PDF: </label><DropDownList data={this.state.pdfList} textField="name" dataItemKey="key" value={this.state.selectedPdf} onChange={(e) => this.onChangeHandler(e, 'selectedPdf')} />
                                {/* {this.props.package[0].advancedMode === false &&
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>Group Entity Behavior: </label>
                                        <br></br>
                                        <DropDownList
                                            data={packageGroupPdfDropdown}
                                            value={this.state.packageGroupPdf}
                                            onChange={(e) => this.onChangeHandler(e, 'packageGroupPdf')}>
                                        </DropDownList>
                                    </Col>
                                } */}
                                {/* {(this.props.package[0].advancedMode) && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                <br /><br />
                                <Button onClick={(e) => { this.submitForm(); this.setState({ showReport: !this.state.showReport, showReportText: "Add Content" }) }} disabled={!this.state.selectedPdf}>Add PDF</Button>
                            </div>
                            }

                            <br />
                            {this.props.reportList.length > 0 &&
                                <div>
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('up')} icon='sort-asc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('down')} icon='sort-desc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.deleteReportFromPackage()} icon='delete' >Delete</Button>
                                    <div className="scroll-box">
                                        <div className="scroll-box__wrapper">
                                            <div className="scroll-box__container" role="list">
                                                <RadioGroup name="reports" selectedValue={this.state.selectedReport} onChange={(e) => this.onRadioChangeHandler(e)}>
                                                    {this.props.reportList.map((item, id) => {
                                                        var labelText

                                                        labelText = item.text

                                                        if (item.reportDate !== '') {
                                                            labelText = labelText + " | " + item.reportDate
                                                        }

                                                        if (item.hierarchy && item.hierarchy !== 'undefined' && item.hierarchy !== 'null') {
                                                            labelText = labelText + " | " + item.hierarchy
                                                        }

                                                        return (<div><Radio id={id} value={item} /><label htmlFor={id} onChange={(e) => this.onChangeHandler(e, 'selectedReport')}>{labelText}</label><br /></div>)
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="actions">                     
                              <Button 
                                className="upload"  
                                onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })}>
                                    Save Changes
                              </Button>
                            </div>
                            {/* <div style={{ display: "flex" }}>
                                <Button onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Changes</Button>
                            </div> */}



                        </div>
                    )}
                </Popup>

            );
        }
        else if (this.props.type === 'createtemplate') {
            return (
                <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                    {close => (
                        <div className="modal">
                            <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />



                            <div className="header" align="left">
                                Create Template
                            </div>
                            <br />
                            <div className="content">
                            <label>Template Name: </label><Input style={{ marginLeft: "3px" }} value={this.state.packageName} type="text" name="packageName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageName')} />
                            <br />
                            <label>Description: </label><Input value={this.state.packageDesc} type="text" name="packageDesc" style={{ width: "650px", marginLeft: "25px" }} autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageDesc')} />
                            <br /><br />
                            <label>Reporting Entity: </label>
                            <ComboBox
                                name="accountListTemplate"
                                data={this.state.templateEntities}
                                textField="label"
                                dataItemKey="value"
                                filterable={true}
                                header={<span>Code          |Name                              </span>}
                                style={{ width: "400px" }}
                                popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                onFilterChange={this._filterChange}
                                value={this.state.selectedAccount}
                                onChange={(e) => this.onChangeHandler(e, 'selectedAccount')}
                            />
                            <br /><br />
                            {/* <label>Advanced Mode: </label><Switch style={{ marginLeft: "14px" }} disabled={this.state.reportsList.length > 0 || this.state.packageGroupCheckbox} value={this.state.advancedMode} checked={this.state.advancedMode} onChange={(e) => this.onChangeHandler(e, 'advancedMode')} />
                            <br /> */}
                            <br />
                            <DropDownList data={contentTypeDropdown} disabled={this.state.showReport} value={this.state.contentType} onChange={(e) => this.onChangeHandler(e, 'contentType')}></DropDownList><Button style={{ marginLeft: "5px" }} onClick={(e) => { this.setState({ showReport: !this.state.showReport }); if (this.state.showReportText !== "Cancel") { this.setState({ showReportText: "Cancel" }) } else { this.setState({ showReportText: "Add Content" }) } }} >{this.state.showReportText}</Button>
                            <br />


                            {this.state.showReport && this.state.contentType === 'Report' && <div>
                                <br />
                                <div id="reportSelect" style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                    <label>Report: </label><DropDownList data={this.state.reports} loading={this.state.accountLoading} disabled={this.state.accountLoading} textField="name" dataItemKey="key" style={{ width: '300px' }} value={this.state.report} onChange={(e) => this.onReportChangeHandler(e)} />
                                    {/* {this.state.selectedMode === 'individual' && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                    <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                    <br></br>
                                    <div style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                        {this.state.reportParametersFlag && this.state.paramTrackingCount === 0 &&
                                            <Grid fluid>
                                                <Row>
                                                    {/* {this.state.selectedMode === 'group' && this.state.hierarchy !== 'Multi Level' && */}
                                                    {1 === 1 &&
                                                        <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                            <br></br>
                                                            <label>Group Entity Behavior: </label>
                                                            <br></br>
                                                            <DropDownList
                                                                disabled={this.state.mutliAdded && this.state.hierarchy === 'Multi Level'}
                                                                data={packageGroupPortfolioDropdown}
                                                                value={this.state.packageGroupPortfolio}
                                                                onChange={(e) => this.onChangeHandler(e, 'packageGroupPortfolio')}>
                                                            </DropDownList>
                                                        </Col>
                                                    }
                                                    {parameterList.map((form) => {
                                                        const t = "SSRSParam_".concat([form.name])
                                                        if (form.type === "String") {
                                                            return (
                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputTextField
                                                                        name={form.name}
                                                                        input_type={form.type}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        value={this.state[t]}
                                                                        defaultValue={form.defaultValue}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>
                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'singleDate') {
                                                            return (
                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button alt={"hello"} onClick={(e) => this.onGenericToggleChangeHandler(true, 'singleDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_singleDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'singleDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'fromDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'fromDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_fromDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'fromDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'toDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'toDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_toDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'toDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "Boolean") {
                                                            var defaultChecked = ''
                                                            if (form.defaultValue.toLowerCase() === 'true') {
                                                                defaultChecked = true
                                                            }
                                                            else if (form.defaultValue.toLowerCase() === 'false') {
                                                                defaultChecked = false
                                                            }
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputBoolean
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        value={this.state[t]}
                                                                        defaultChecked={defaultChecked}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                        // if (form.type === "Dropdown" && (form.name === "PortfolioTest" || form.name === "PortfolioList") && this.state.selectedMode !== 'group') {
                                                        //     return (
                                                        //         <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                        //             <br></br>
                                                        //             <InputPortfolioDropdown
                                                        //                 name={form.name}
                                                        //                 label={form.label}
                                                        //                 data={this.state[t.concat("_Data")]}
                                                        //                 value={this.state[t]}
                                                        //                 key={form.label}
                                                        //                 valid={this.state[t.concat("_Validate")]}
                                                        //                 _filterChange={this._filterChange}
                                                        //                 _handleChangePortfolio={this._handleChangePortfolio}
                                                        //                 _itemRender={this._itemRender}
                                                        //                 defaultValue={this.state.selectedAccount}
                                                        //             />
                                                        //             <Button onClick={(e) => this.consolidateGroups()} >Consolidate</Button>
                                                        //         </Col>
                                                        //     )
                                                        // }
                                                        if (form.type === "Dropdown" && (form.name !== "PortfolioTest" && form.name !== "PortfolioList")) {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputDropdown
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        data={this.state[t.concat("_Data")]}
                                                                        value={this.state[t]}
                                                                        key={form.label}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Grid>
                                        }
                                    </div>
                                    <br></br>


                                    <Button onClick={(e) => { this.submitForm()}} disabled={this.getButtonStatus()}>Add Report</Button>
                                    <br></br><br></br>
                                </div>




                            </div >
                            }

                            {this.state.showReport && this.state.contentType === 'PDF' && <div>
                                <br />
                                <label>PDF: </label><DropDownList data={this.state.pdfList} textField="name" dataItemKey="key" value={this.state.selectedPdf} onChange={(e) => this.onChangeHandler(e, 'selectedPdf')} />
                                {/* {this.state.selectedMode === 'group' &&
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>Group Entity Behavior: </label>
                                        <br></br>
                                        <DropDownList
                                            data={packageGroupPdfDropdown}
                                            value={this.state.packageGroupPdf}
                                            onChange={(e) => this.onChangeHandler(e, 'packageGroupPdf')}>
                                        </DropDownList>
                                    </Col>
                                } */}
                                {/* {this.state.selectedMode === 'individual' && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                <br /><br />
                                <Button onClick={(e) => { this.submitForm(); this.setState({ showReport: !this.state.showReport, showReportText: "Add Content" }) }} disabled={!this.state.selectedPdf}>Add PDF</Button>
                            </div>
                            }

                            <br />
                            {this.state.reportsList.length > 0 &&
                                <div>
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('up')} icon='sort-asc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('down')} icon='sort-desc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.deleteReportFromPackage()} icon='delete' >Delete</Button>
                                    <div className="scroll-box">
                                        <div className="scroll-box__wrapper">
                                            <div className="scroll-box__container" role="list">
                                                <RadioGroup name="reports" selectedValue={this.state.selectedReport} onChange={(e) => this.onRadioChangeHandler(e)}>
                                                    {this.state.reportsList.map(item => {
                                                        var labelText

                                                        labelText = item.text

                                                        if (item.reportDate !== '') {
                                                            labelText = labelText + " | " + item.reportDate
                                                        }

                                                        if (item.hierarchy) {
                                                            labelText = labelText + " | " + item.hierarchy
                                                        }

                                                        return (<div><Radio id={item.text} value={item} /><label htmlFor={item.text} onChange={(e) => this.onChangeHandler(e, 'selectedReport')}>{labelText}</label><br /></div>)
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="actions">                     
                              <Button 
                                className="upload"  
                                onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })}>
                                    Save Template
                              </Button>
                            </div>
                            {/* <div style={{ display: "flex" }}>
                                <Button onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Template</Button>
                            </div> */}



                        </div>
                    )
                    }
                </Popup >

            );
        }
        else if (this.props.type === 'edittemplate') {
            return (
                <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                    {close => (
                        <div className="modal">
                            <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />



                            <div className="header" align="left">
                                Edit Template
                            </div>
                            <div className="content">
                            <br />

                            <label>Template Name: </label>
                            {!this.state.editPackageName &&
                                <Input style={{ marginLeft: "3px" }} disabled={true} value={this.props.package[0].Key} ></Input>
                            }
                            {!this.state.editPackageName &&
                                <Button onClick={(e) => { this.setState({ packageName: this.props.package[0].Key, editPackageName: true }) }} icon="edit"></Button>
                            }
                            {this.state.editPackageName &&
                                <Input style={{ marginLeft: "3px" }} value={this.state.packageName} type="text" name="packageName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageName')} />
                            }
                            <br />
                            <label>Description: </label>
                            {!this.state.editPackageDesc &&
                                <Input style={{ width: "650px", marginLeft: "25px" }} disabled={true} value={this.props.package[0].Description} ></Input>
                            }
                            {!this.state.editPackageDesc &&
                                <Button onClick={(e) => { this.setState({ packageDesc: this.props.package[0].Description, editPackageDesc: true }) }} icon="edit"></Button>
                            }
                            {this.state.editPackageDesc &&
                                <Input style={{ width: "650px", marginLeft: "25px" }} value={this.state.packageDesc} type="text" name="packageDesc" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageDesc')} />
                            }
                            <br/>
                            <label>Reporting Entity: </label>
                            {!this.state.editEntity &&
                                <Input style={{ width: "400px" }} disabled={true} value={this.props.package[0].Entity} ></Input>
                            }
                            {!this.state.editEntity &&
                                <Button onClick={(e) => { this.setState({ selectedAccount: { value: '', label: '' }, editEntity: true }) }} icon="edit"></Button>
                            }
                            {this.state.editEntity &&
                                <ComboBox
                                    name="accountListTemplate"
                                    data={this.state.templateEntities}
                                    textField="label"
                                    dataItemKey="value"
                                    filterable={true}
                                    header={<span>Code          |Name                              </span>}
                                    popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                    style={{ width: "400px" }}
                                    onFilterChange={this._filterChange}
                                    value={this.state.selectedAccount}
                                    onChange={(e) => this.onChangeHandler(e, 'selectedAccount')}
                                />
                            }
                            <br />



                            <br />
                            <br />
                            <DropDownList data={contentTypeDropdown} disabled={this.state.showReport} value={this.state.contentType} onChange={(e) => this.onChangeHandler(e, 'contentType')}></DropDownList><Button style={{ marginLeft: "5px" }} onClick={(e) => { this.setState({ showReport: !this.state.showReport }); if (this.state.showReportText !== "Cancel") { this.setState({ showReportText: "Cancel" }) } else { this.setState({ showReportText: "Add Content" }) } }} >{this.state.showReportText}</Button>
                            <br />


                            {this.state.showReport && this.state.contentType === "Report" && <div>
                                <br />
                                <div id="reportSelect" style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                    <label>Report: </label><DropDownList data={this.state.reports} textField="name" dataItemKey="key" style={{ width: '300px' }} value={this.state.report} onChange={(e) => this.onReportChangeHandler(e)} />
                                    {/* {(this.props.package[0].advancedMode) && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                    <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                    <br></br>
                                    <div style={{ backgroundColor: "white", borderRadius: '3px' }}>
                                        {this.state.reportParametersFlag && this.state.paramTrackingCount === 0 &&
                                            <Grid fluid>
                                                <Row>
                                                    {/* {this.props.package[0].advancedMode === false && this.state.hierarchy !== 'Multi Level' && */}
                                                        <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                            <br></br>
                                                            <label>Group Entity Behavior: </label>
                                                            <br></br>
                                                            <DropDownList
                                                                disabled={this.state.mutliAdded && this.state.hierarchy === 'Multi Level'}
                                                                data={packageGroupPortfolioDropdown}
                                                                value={this.state.packageGroupPortfolio}
                                                                onChange={(e) => this.onChangeHandler(e, 'packageGroupPortfolio')}>
                                                            </DropDownList>
                                                        </Col>
                                                    {parameterList.map(form => {
                                                        const t = "SSRSParam_".concat([form.name])
                                                        if (form.type === "String") {
                                                            return (
                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputTextField
                                                                        name={form.name}
                                                                        input_type={form.type}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        defaultValue={form.defaultValue}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>
                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'singleDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'singleDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.singleDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_singleDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'singleDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'fromDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99}>
                                                                    <br></br>
                                                                    {!this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'fromDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.fromDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_fromDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'fromDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "DateTime" && form.name === 'toDate') {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    {!this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDate
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                key={form.label}
                                                                                value={this.state[t]}
                                                                                defaultValue={form.defaultValue}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'toDateGenericDateToggle')} icon="list-unordered"></Button>
                                                                        </div>
                                                                    }
                                                                    {this.state.toDateGenericDateToggle &&
                                                                        <div>
                                                                            <InputDropdown
                                                                                name={form.name}
                                                                                label={form.label}
                                                                                data={this.state.genericDates_toDate_Data}
                                                                                value={this.state[t]}
                                                                                key={form.label}
                                                                                valid={this.state[t.concat("_Validate")]}
                                                                                _filterChange={this._filterChangeGenericDate}
                                                                                _handleChange={this._handleChange}
                                                                            />
                                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'toDateGenericDateToggle')} icon="calendar"></Button>
                                                                        </div>
                                                                    }
                                                                    <br></br>
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "Boolean") {
                                                            var defaultChecked = ''
                                                            if (form.defaultValue.toLowerCase() === 'true') {
                                                                defaultChecked = true
                                                            }
                                                            else if (form.defaultValue.toLowerCase() === 'false') {
                                                                defaultChecked = false
                                                            }
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputBoolean
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        key={form.label}
                                                                        value={this.state[t]}
                                                                        defaultChecked={defaultChecked}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                        if (form.type === "Dropdown" && (form.name === "PortfolioTest" || form.name === "PortfolioList") && this.props.package[0].advancedMode === true) {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputPortfolioDropdown
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        data={this.state[t.concat("_Data")]}
                                                                        value={this.state[t]}
                                                                        key={form.label}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChangePortfolio={this._handleChangePortfolio}
                                                                        _itemRender={this._itemRender}
                                                                        defaultValue={this.state.selectedAccount}
                                                                    />
                                                                    <Button onClick={(e) => this.consolidateGroups()} >Consolidate</Button>
                                                                </Col>

                                                            )
                                                        }
                                                        else if (form.type === "Dropdown" && (form.name !== "PortfolioTest" && form.name !== "PortfolioList")) {
                                                            return (

                                                                <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                                                    <br></br>
                                                                    <InputDropdown
                                                                        name={form.name}
                                                                        label={form.label}
                                                                        data={this.state[t.concat("_Data")]}
                                                                        value={this.state[t]}
                                                                        key={form.label}
                                                                        valid={this.state[t.concat("_Validate")]}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChange={this._handleChange}
                                                                    />
                                                                </Col>

                                                            )
                                                        }
                                                    })}
                                                </Row>
                                            </Grid>
                                        }
                                    </div>
                                    <br></br>


                                    <Button onClick={(e) => { this.submitForm()}} disabled={this.getButtonStatus()}>Add Report</Button>
                                    <br></br><br></br>
                                </div>
                            </div >
                            }

                            {this.state.showReport && this.state.contentType === 'PDF' && <div>
                                <br />
                                <label>PDF: </label><DropDownList data={this.state.pdfList} textField="name" dataItemKey="key" value={this.state.selectedPdf} onChange={(e) => this.onChangeHandler(e, 'selectedPdf')} />
                                {/* {this.props.package[0].advancedMode === false &&
                                    <Col xs={99} sm={99} md={99} lg={99} xl={99} >
                                        <br></br>
                                        <label>Group Entity Behavior: </label>
                                        <br></br>
                                        <DropDownList
                                            data={packageGroupPdfDropdown}
                                            value={this.state.packageGroupPdf}
                                            onChange={(e) => this.onChangeHandler(e, 'packageGroupPdf')}>
                                        </DropDownList>
                                    </Col>
                                } */}
                                {/* {(this.props.package[0].advancedMode) && <><label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} /></>} */}
                                <label style={{ paddingLeft: "10px" }}>Hierarchy: </label><DropDownList data={hierarchyDropdown} style={{ width: '300px' }} value={this.state.hierarchy} onChange={(e) => this.onChangeHandler(e, 'hierarchy')} />
                                <br /><br />
                                <Button onClick={(e) => { this.submitForm(); this.setState({ showReport: !this.state.showReport, showReportText: "Add Content" }) }} disabled={!this.state.selectedPdf}>Add PDF</Button>
                            </div>
                            }

                            <br />
                            {this.props.reportList.length > 0 &&
                                <div>
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('up')} icon='sort-asc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('down')} icon='sort-desc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.deleteReportFromPackage()} icon='delete' >Delete</Button>
                                    <div className="scroll-box">
                                        <div className="scroll-box__wrapper">
                                            <div className="scroll-box__container" role="list">
                                                <RadioGroup name="reports" selectedValue={this.state.selectedReport} onChange={(e) => this.onRadioChangeHandler(e)}>
                                                    {this.props.reportList.map((item, id) => {
                                                        var labelText

                                                        labelText = item.text

                                                        if (item.reportDate !== '') {
                                                            labelText = labelText + " | " + item.reportDate
                                                        }

                                                        if (item.hierarchy && item.hierarchy !== 'undefined' && item.hierarchy !== 'null') {
                                                            labelText = labelText + " | " + item.hierarchy
                                                        }

                                                        return (<div><Radio id={id} value={item} /><label htmlFor={id} onChange={(e) => this.onChangeHandler(e, 'selectedReport')}>{labelText}</label><br /></div>)
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="actions">                     
                              <Button 
                                className="upload"  
                                onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })}>
                                    Save Changes
                              </Button>
                            </div>
                            {/* <div style={{ display: "flex" }}>
                                <Button onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Changes</Button>
                            </div> */}



                        </div>
                    )}
                </Popup>

            );
        }
        else if (this.props.type === 'createtemplatepackage') {
            return (
                <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                    {close => (
                        <div className="modal">
                            <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />



                            <div className="header" align="left">
                                Create Package
                            </div>
                            <div className="content">
                            <br />

                            <label>Package Name: </label><Input style={{ marginLeft: "3px" }} value={this.state.packageName} type="text" name="packageName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageName')} />
                            <br />
                            <label>Description: </label><Input value={this.state.packageDesc} type="text" name="packageDesc" style={{ width: "650px", marginLeft: "25px" }} autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageDesc')} />
                            <br />
                            <label>Frequency: </label><DropDownList style={{ marginLeft: "14px" }} data={frequencyDropdown} value={this.state.frequency} onChange={(e) => this.onChangeHandler(e, 'frequency')} />
                            <br /><br />
                            {/* <RadioGroup name="mode" selectedValue={this.state.selectedMode} onChange={(e) => this.setState({ selectedMode: e, singleFileCheckbox: false })}>
                                <label style={{ color: modeLabelColor }}><Radio value="individual" disabled={this.state.reportsList.length > 0} />Individual Member Mode</label>
                                <br/>
                                <label style={{ color: modeLabelColor }}><Radio value="group" disabled={this.state.reportsList.length > 0} />Group Mode</label>
                            </RadioGroup> */}
                            <label>Reporting Entity: </label>
                            <ComboBox
                                name="accountListTemplate"
                                data={this.state.templateEntities}
                                textField="label"
                                dataItemKey="value"
                                filterable={true}
                                header={<span>Code          |Name                              </span>}
                                style={{ width: "400px" }}
                                popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                onFilterChange={this._filterChange}
                                value={this.state.selectedAccount}
                                onChange={(e) => this.onChangeHandler(e, 'selectedAccount')}
                            />
                            {/* <label>Advanced Groups: </label><Switch style={{ marginLeft: "14px" }} value={this.state.packageGroupCheckbox} disabled={!this.state.packageGroupCheckboxEnable || this.state.advancedMode} checked={this.state.packageGroupCheckbox} onChange={(e) => this.onChangeHandlerPackageGroup(e)} /> */}
                            <br />
                            <label>Output as Single File: </label><Switch style={{ marginLeft: "14px" }} value={this.state.singleFileCheckbox} onChange={(e) => this.onChangeHandlerSingleFile(e)} />
                            {/* <br/><label style={{ color: fileNameLabelColor }}> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                            {/* <br/><label> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                            <br/><label> {"Name of File:"} </label>

                            {/*<Input style={{ marginLeft: "3px" }} disabled={!this.state.singleFileCheckbox} value={this.state.singleFileName} type="text" name="packageFileName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'singleFileName')} />*/}
                            <ComboBox
                                name="householdList"
                                data={this.state.householdList}
                                //textField="label"
                                //dataItemKey="value"
                                filterable={true}
                                //header={<span>Code          |Name                              </span>}
                                style={{ marginLeft: "3px", width: "200px" }}
                                //popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                allowCustom={true}
                                onFilterChange={(e) => this.setState({ householdList: this.filterData(e.filter, this.state.householdListForFilter) })}
                                //disabled={!this.state.singleFileCheckbox}
                                value={this.state.singleFileName}
                                onChange={(e) => this.onChangeHandler(e, 'singleFileName')}
                            />
                            <br /><br />
                            {/* <label>Advanced Mode: </label><Switch style={{ marginLeft: "14px" }} disabled={this.state.reportsList.length > 0 || this.state.packageGroupCheckbox} value={this.state.advancedMode} checked={this.state.advancedMode} onChange={(e) => this.onChangeHandler(e, 'advancedMode')} />
                            <br /> */}

                                <><label>Template: </label><DropDownList data={this.state.templates} loading={this.state.accountLoading} disabled={this.state.accountLoading} textField="name" dataItemKey="name" style={{ width: '300px' }} value={this.state.template} onChange={(e) => this.onTemplateChangeHandler(e)} />
                                <br />
                                {this.state.template.name && <>       <br/><InputPortfolioDropdown
                                                                        name={'PortfolioListTemplate'}
                                                                        label={'Portfolios'}
                                                                        data={this.state.templateEntities}
                                                                        value={this.state.templateGroup}
                                                                        key={'Portfolios'}
                                                                        // valid={this.state.SSRSParam_PortfolioList_Validate}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChangePortfolio={this._handleChangeTemplateGroup}
                                                                        _itemRender={this._itemRender}
                                                                        // defaultValue={this.state.selectedAccount}
                                                                    />
                                                                    {/* <Button onClick={(e) => this.consolidateGroups()}>Consolidate</Button> */}
                                </>}
                                <br/>
                                <br/>
                                <Button onClick={(e) => { this.submitForm() }} disabled={!this.state.template.name}>Add Template</Button>
                                <br/>
                                </>


                            <br />
                            {this.state.reportsList.length > 0 &&
                                <div>
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('up')} icon='sort-asc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('down')} icon='sort-desc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.deleteReportFromPackage()} icon='delete' >Delete</Button>
                                    <div className="scroll-box">
                                        <div className="scroll-box__wrapper">
                                            <div className="scroll-box__container" role="list">
                                                <RadioGroup name="reports" selectedValue={this.state.selectedReport} onChange={(e) => this.onRadioChangeHandler(e)}>
                                                    {this.state.reportsList.map(item => {
                                                        var labelText

                                                        labelText = item.text + ' | ' + item.reportingGroup

                                                        return (<div><Radio id={item.text} value={item} /><label htmlFor={item.text} onChange={(e) => this.onChangeHandler(e, 'selectedReport')}>{labelText}</label><br /></div>)
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            </div>
                            <div className="actions">                     
                              <Button 
                                className="upload"  
                                onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })}>
                                    Save Package
                              </Button>
                            </div>
                            {/* <div style={{ display: "flex" }}>
                                <Button onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Package</Button>
                            </div> */}



                        </div>
                    )
                    }
                </Popup >

            );
        }
        else if (this.props.type === 'edittemplatepackage') {
            return (
                <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                    {close => (
                        <div className="modal">
                            <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />



                            <div className="header" align="left">
                                Edit Package
                            </div>
                            <div className="content">
                            <br />

                            <label>Package Name: </label>
                            {!this.state.editPackageName &&
                                <Input style={{ marginLeft: "3px" }} disabled={true} value={this.props.package[0].Key} ></Input>
                            }
                            {!this.state.editPackageName &&
                                <Button onClick={(e) => { this.setState({ packageName: this.props.package[0].Key, editPackageName: true }) }} icon="edit"></Button>
                            }
                            {this.state.editPackageName &&
                                <Input style={{ marginLeft: "3px" }} value={this.state.packageName} type="text" name="packageName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageName')} />
                            }
                            <br />
                            <label>Description: </label>
                            {!this.state.editPackageDesc &&
                                <Input style={{ width: "650px", marginLeft: "25px" }} disabled={true} value={this.props.package[0].Description} ></Input>
                            }
                            {!this.state.editPackageDesc &&
                                <Button onClick={(e) => { this.setState({ packageDesc: this.props.package[0].Description, editPackageDesc: true }) }} icon="edit"></Button>
                            }
                            {this.state.editPackageDesc &&
                                <Input style={{ width: "650px", marginLeft: "25px" }} value={this.state.packageDesc} type="text" name="packageDesc" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'packageDesc')} />
                            }
                            <br />
                            <label>Frequency: </label>
                            {!this.state.editFrequency &&
                                <Input style={{ marginLeft: "28px" }} disabled={true} value={this.props.package[0].Frequency} ></Input>
                            }
                            {!this.state.editFrequency &&
                                <Button onClick={(e) => { this.setState({ frequency: this.props.package[0].Frequency, editFrequency: true }) }} icon="edit"></Button>
                            }
                            {this.state.editFrequency &&
                                <DropDownList style={{ marginLeft: "14px" }} data={frequencyDropdown} value={this.state.frequency} onChange={(e) => this.onChangeHandler(e, 'frequency')} />
                            }
                            <br/>
                            <label>Reporting Entity: </label>
                            {!this.state.editEntity &&
                                <Input style={{ width: "400px" }} disabled={true} value={this.props.package[0].Entity} ></Input>
                            }
                            {!this.state.editEntity &&
                                <Button onClick={(e) => { this.setState({ selectedAccount: { value: '', label: '' }, editEntity: true }) }} icon="edit"></Button>
                            }
                            {this.state.editEntity &&
                                <ComboBox
                                    name="accountListTemplate"
                                    data={this.state.templateEntities}
                                    textField="label"
                                    dataItemKey="value"
                                    filterable={true}
                                    header={<span>Code          |Name                              </span>}
                                    popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                    style={{ width: "400px" }}
                                    onFilterChange={this._filterChange}
                                    value={this.state.selectedAccount}
                                    onChange={(e) => this.onChangeHandler(e, 'selectedAccount')}
                                />
                            }
                            <br />


                            
                            <br /><br />
                            {/* <label>Output as Single File: </label> */}
                            {!this.state.editSingleFile &&
                                <>
                                    {<><label>Output as Single File: </label><Switch disabled={true}  style={{ marginLeft: "14px" }} checked={this.props.package[0].SaveAsSinglePdf} value={this.props.package[0].SaveAsSinglePdf} /></>}
                                    <Button onClick={(e) => { this.setState({ singleFileCheckbox: this.props.package[0].SaveAsSinglePdf, singleFileName: this.props.package[0].FileName, editSingleFile: true }) }} icon="edit"></Button>
                                    {/* <br/><label style={{ color: fileNameLabelColor }}> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                                    {/* <br/><label> {!this.props.package[0].SaveAsSinglePdf && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Append to Filename": "Name of File:"} </label> */}
                                    <br/><label> {!this.props.package[0].SaveAsSinglePdf && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Name of File:": "Name of File:"} </label>
                                    <Input style={{ marginLeft: "3px" }} disabled={true} value={this.props.package[0].FileName} type="text" name="packageFileName" autoComplete="off" />
                                    <Button onClick={(e) => { this.setState({ singleFileCheckbox: this.props.package[0].SaveAsSinglePdf, singleFileName: this.props.package[0].FileName, editSingleFile: true }) }} icon="edit"></Button>

                                    {/* <Switch style={{ marginLeft: "14px" }} disabled={true} defaultChecked={this.props.package[0].SaveAsSinglePdf} value={this.props.package[0].SaveAsSinglePdf} />
                                    <label style={{ color: fileNameLabelColor }}> Name of File: </label><Input style={{ marginLeft: "3px" }} disabled={true} value={this.props.package[0].FileName} type="text" name="packageFileName" autoComplete="off" />
                                    <Button onClick={(e) => { this.setState({ singleFileCheckbox: this.props.package[0].SaveAsSinglePdf, singleFileName: this.props.package[0].FileName, editSingleFile: true }) }} icon="edit"></Button> */}
                                </>
                            }
                            {this.state.editSingleFile &&
                                <>
                                    {/* <Switch style={{ marginLeft: "14px" }} defaultChecked={this.state.singleFileCheckbox} value={this.state.singleFileCheckbox} onChange={(e) => this.onChangeHandlerSingleFile(e)} />
                                    <label style={{ color: fileNameLabelColor }}> Name of File: </label> */}
                                    {(this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') && <><label>Output as Single File: </label><Switch style={{ marginLeft: "14px" }} checked={this.state.singleFileCheckbox} value={this.state.singleFileCheckbox} onChange={(e) => this.onChangeHandlerSingleFile(e)} /></>}
                                    {/* <br/><label style={{ color: fileNameLabelColor }}> {!this.state.singleFileCheckbox && this.state.selectedMode === 'group' ? "Append to Filename": "Name of File:"} </label> */}
                                    {/* <br/><label> {!this.state.singleFileCheckbox && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Append to Filename": "Name of File:"} </label> */}
                                    <br/><label> {!this.state.singleFileCheckbox && (this.state.selectedMode === 'group' || !this.props.package[0].advancedMode || this.determineIndividualOrGroup(this.props.reportList) === 'group') ? "Name of File:": "Name of File:"} </label>
                                    {/*<Input style={{ marginLeft: "3px" }} type="text" name="packageFileName" autoComplete="off" onChange={(e) => this.onChangeHandler(e, 'singleFileName')} />*/}
                                    <ComboBox
                                        name="householdList"
                                        data={this.state.householdList}
                                        //textField="label"
                                        //dataItemKey="value"
                                        filterable={true}
                                        //header={<span>Code          |Name                              </span>}
                                        style={{ marginLeft: "3px", width: "200px" }}
                                        //popupSettings={{ width: "420px", className: "ssrsportfolio" }}
                                        allowCustom={true}
                                        onFilterChange={(e) => this.setState({ householdList: this.filterData(e.filter, this.state.householdListForFilter) })}
                                        //disabled={!this.state.singleFileCheckbox}
                                        value={this.state.singleFileName}
                                        onChange={(e) => this.onChangeHandler(e, 'singleFileName')}
                                    />
                                </>
                            }
                            <br /><br />
                            {/* <label>Advanced Mode: </label>
                            {!this.state.editAdvanced &&
                                <Switch style={{ marginLeft: "14px" }} disabled={true} defaultChecked={this.props.package[0].advancedMode} value={this.props.package[0].advancedMode} />
                            }
                            {!this.state.editAdvanced &&
                                <Button onClick={(e) => { this.setState({ advancedMode: this.props.package[0].advancedMode, editAdvanced: true }) }} icon="edit"></Button>
                            }
                            {this.state.editAdvanced &&
                                <Switch style={{ marginLeft: "14px" }} disabled={this.props.reportList.length > 0} defaultChecked={this.state.advancedMode} value={this.state.advancedMode} onChange={(e) => this.onChangeHandler(e, 'advancedMode')} />
                            } */}

<br /><br />
                            {/* <label>Advanced Mode: </label><Switch style={{ marginLeft: "14px" }} disabled={this.state.reportsList.length > 0 || this.state.packageGroupCheckbox} value={this.state.advancedMode} checked={this.state.advancedMode} onChange={(e) => this.onChangeHandler(e, 'advancedMode')} />
                            <br /> */}

                                <><label>Template: </label><DropDownList data={this.state.templates} loading={this.state.accountLoading} disabled={this.state.accountLoading} textField="name" dataItemKey="name" style={{ width: '300px' }} value={this.state.template} onChange={(e) => this.onTemplateChangeHandler(e)} />
                                <br />
                                {this.state.template.name && <>       <br/><InputPortfolioDropdown
                                                                        name={'PortfolioListTemplate'}
                                                                        label={'Portfolios'}
                                                                        data={this.state.templateEntities}
                                                                        value={this.state.templateGroup}
                                                                        key={'Portfolios'}
                                                                        // valid={this.state.SSRSParam_PortfolioList_Validate}
                                                                        _filterChange={this._filterChange}
                                                                        _handleChangePortfolio={this._handleChangeTemplateGroup}
                                                                        _itemRender={this._itemRender}
                                                                        // defaultValue={this.state.selectedAccount}
                                                                    />
                                                                    {/* <Button onClick={(e) => this.consolidateGroups()}>Consolidate</Button> */}
                                </>}
                                <br/>
                                <br/>
                                <Button onClick={(e) => { this.submitForm() }} disabled={!this.state.template.name}>Add Template</Button>
                                <br/>
                                </>


                            <br />
                            {this.state.reportsList.length === 0 &&
                                <div>
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('up')} icon='sort-asc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('down')} icon='sort-desc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.deleteReportFromPackage()} icon='delete' >Delete</Button>
                                    <div className="scroll-box">
                                        <div className="scroll-box__wrapper">
                                            <div className="scroll-box__container" role="list">
                                                <RadioGroup name="reports" selectedValue={this.state.selectedReport} onChange={(e) => this.onRadioChangeHandler(e)}>
                                                    {this.props.reportList.map((item, id) => {
                                                        var labelText
                                                        console.log(item)

                                                        labelText = item.text + ' | ' + item.reportingGroup

                                                        return (<div><Radio id={id} value={item} /><label htmlFor={id} onChange={(e) => this.onChangeHandler(e, 'selectedReport')}>{labelText}</label><br /></div>)
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.reportsList.length > 0 &&
                                <div>
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('up')} icon='sort-asc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.changeReportOrder('down')} icon='sort-desc-sm' />
                                    <Button disabled={!this.state.selectedReport} onClick={(e) => this.deleteReportFromPackage()} icon='delete' >Delete</Button>
                                    <div className="scroll-box">
                                        <div className="scroll-box__wrapper">
                                            <div className="scroll-box__container" role="list">
                                                <RadioGroup name="reports" selectedValue={this.state.selectedReport} onChange={(e) => this.onRadioChangeHandler(e)}>
                                                    {this.state.reportsList.map((item, id) => {
                                                        var labelText
                                                        console.log(item)

                                                        labelText = item.text + ' | ' + item.reportingGroup

                                                        return (<div><Radio id={id} value={item} /><label htmlFor={id} onChange={(e) => this.onChangeHandler(e, 'selectedReport')}>{labelText}</label><br /></div>)
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            </div>
                            <div className="actions">                     
                              <Button 
                                className="upload"  
                                onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })}>
                                    Save Changes
                              </Button>
                            </div>
                            {/* <div style={{ display: "flex" }}>
                                <Button onClick={(e) => this.savePackage((callback) => { if (callback) { close(); this.clearState(); } })} style={{ marginLeft: "auto" }}>Save Changes</Button>
                            </div> */}



                        </div>
                    )}
                </Popup>

            );
        }

    };

}

export default PackagerForm;