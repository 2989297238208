import React from "react";
import { ComboBox } from '@progress/kendo-react-dropdowns';

const InputDropdown = ({ name, label, data, value, valid, defaultSet, _filterChange, _handleChange, }) =>
    (
        <div>
            {defaultSet && <strong>{label}: </strong>}
            {!defaultSet && <label>{label}: </label>}
            <br></br>
            <ComboBox
                name={name}
                data={data}
                textField="label"
                //textField={"entityCode     |Name of Account or Group truncated if it's too l...|Account"}
                dataItemKey="value"
                //defaultValue={defaultValue}
                filterable={true}
                //popupSettings={{width: "500px", className: "test"}}
                onFilterChange={_filterChange}
                value={value}
                valid={valid}
                onChange={_handleChange}
            />
            <br></br>
        </div>
    );
export default InputDropdown;