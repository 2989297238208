import React from "react";
import { DatePicker } from '@progress/kendo-react-dateinputs';

const InputDate = ({ name, label, defaultValue, valid, defaultSet, value, _handleChange }) =>

    (
        <div>
            {defaultSet && <strong>{label}: </strong>}
            {!defaultSet && <label>{label}: </label>}
            <br></br>
            <DatePicker
                defaultValue={new Date(defaultValue)}
                name={name}
                valid={valid}
                value={new Date(value)}
                format={"MM/dd/yyyy"}
                onChange={_handleChange}
            />
        </div>
    );
export default InputDate;