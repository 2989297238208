import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PositionMapping from './components/PositionMapping';
import AccountMapping from './components/AccountMapping';
import AccountAnnotation from './components/AccountAnnotation';
import PerformanceMapping from './components/PerformanceMapping';
import TranMapping from './components/TranMapping';
import BenchMapping from './components/BenchMapping';
import IrrMapping from './components/IrrMapping';
import RGainMapping from './components/RGainMapping';
import HouseholdMapping from './components/HouseholdMapping';
import HouseholdUsers from './components/HouseholdUsers';
import DemographicMapping from './components/DemographicMapping';
import SecurityClassificationLookupMapping from './components/SecurityClassificationLookupMapping';
import SecurityClassificationMembersMapping from './components/SecurityClassificationMembersMapping';
import SecurityTypeMapping from './components/SecurityTypeMapping';
import SecurityMasterMapping from './components/SecurityMasterMapping';
import UnmappedUsers from "./components/UnmappedUsers";
import axios from 'axios';


class DataMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabSelected:0,
      hasUnmappedUsers: false
    }
  }

  handleSelect = (e) => {
    this.setState({ tabSelected: e.selected });
  }

  componentDidMount(){
    this.getUnmappedUserCount();
  }

  getUnmappedUserCount() {

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/unmappedUserCount`, {
      method: 'GET',
      params: { company: this.props.user.company, },
      headers: headers
    })
      .then(response => {
        if (response.data.code === 200) {
          if (response.data.count > 0){
            this.setState({ hasUnmappedUsers: true })
          }
        }

      })
      .catch(err => {
        console.log(err)
      });
  }

  render() {
    return(
        <div>
            <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                <TabStripTab title='Unmapped Accounts'>
                    <AccountMapping user={this.props.user} idToken={this.props.idToken} previewUnmapped={this.props.previewUnmapped}/>
                </TabStripTab>
                {this.state.hasUnmappedUsers && 
                  <TabStripTab title='Unmapped Users'>
                    <UnmappedUsers user={this.props.user} idToken={this.props.idToken} compSettings={this.props.compSettings}/>
                  </TabStripTab>  
                }
                <TabStripTab title='Household Accounts'>
                    <HouseholdMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title='Household Users'>
                    <HouseholdUsers user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title='Account Annotation'>
                    <AccountAnnotation user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Position Mapping">
                    <PositionMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Performance Mapping">
                    <PerformanceMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="IRR Mapping">
                    <IrrMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Transaction Mapping">
                  <TranMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Benchmark Mapping">
                  <BenchMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Realized Gains Mapping">
                  <RGainMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Demographics Mapping">
                  <DemographicMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Security Classification Lookup Mapping">
                  <SecurityClassificationLookupMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Security Classification Members Mapping">
                  <SecurityClassificationMembersMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Security Type Mapping">
                  <SecurityTypeMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title="Security Master Mapping">
                  <SecurityMasterMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>

            </TabStrip>
        </div>
    );
  }

}

export default DataMapping;