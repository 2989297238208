import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';

export const DropDownFilterCell = props => {
    let hasValue = value => Boolean(value && value !== props.defaultItem);

    const onChange = event => {
        let value = (event.target.value === "" || event.target.value == null) ? '(All)' : event.target.value;
        hasValue = hasValue(value);
        props.onChange({
            value: hasValue ? value : '',
            operator: hasValue ? 'eq' : '',
            syntheticEvent: event.syntheticEvent
        });
    };

    const onClearButtonClick = event => {
        event.preventDefault();
        props.onChange({
            value: '',
            operator: '',
            syntheticEvent: event
        });
    };

    let retStr = '';
    if (props.fieldType === 'activeInactive' && props.value) retStr = 'Active';
    if (props.fieldType === 'activeInactive' && !props.value) retStr = 'Inactive';
    if (props.fieldType === 'yesNo' && props.value) retStr = 'Yes';
    if (props.fieldType === 'yesNo' && !props.value) retStr = 'No';

    return <div className="k-filtercell">
        <DropDownList data={props.data} onChange={onChange} value={props.value === '' || props.value == null ? '(All)' : retStr} />
        <Button style={{marginLeft: '4px'}} title="Clear" disabled={!hasValue(props.value !== '' && props.value != null)} onClick={onClearButtonClick} icon="filter-clear" />
    </div>;
};