import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import ClientServiceTeamMapping from "./components/ClientServiceTeamMapping";
import RelationshipAssociateMapping from "./components/RelationshipAssociateMapping";


class CustomMapping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabSelected: 0,
        }


    }

    handleSelect = (e) => {
        this.setState({ tabSelected: e.selected });;
    }


    render() {
        return (
            <div className="customMenu">
                <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                    <TabStripTab title={'Household/Client Service Team Mapping'}>
                        <ClientServiceTeamMapping idToken={this.props.idToken} user={this.props.user} />
                    </TabStripTab>
                    <TabStripTab title={'Relationship Associate Mapping'}>
                        <RelationshipAssociateMapping idToken={this.props.idToken} user={this.props.user} />
                    </TabStripTab>
                </TabStrip>

            </div>

        )
    }

}

export default CustomMapping;