import React, { Component } from "react";
import FixedIncomeAccountHoldings from '../components/FixedIncomeAccountHoldings';

class FixedIncomeHoldingsDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }


  render() {
    return(
        <div className='fiHoldingsDash'>
            <FixedIncomeAccountHoldings user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.props.styleSettings} portfolio={this.props.portfolio} household={this.props.user.defaultAcct.household} idToken={this.props.idToken} />
        </div>
    );
  }

}

export default FixedIncomeHoldingsDashboard;