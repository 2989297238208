import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import posFields from './positionFields.json';

class PositionMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:{household_Id:'',
                    portfolio_Id:'',
                    portfolioName:'',
                    strategy:'',
                    securitySymbol:'',
                    securityName:'',
                    securityType:'',
                    assetAllocation:'',
                    sector:'',
                    industry:'',
                    quantity:'',
                    acqDate:'',
                    unitCost:'',
                    appMarketPrice:'',
                    marketValue:'',
                    totalCost:'',
                    unrealized:'',
                    yield:'',
                    pctAssets:'',
                    accruedInterest:'',
                    assetClassCode:'',
                    securityTypeCode:'',
                    sectorCode:'',
                    industryCode:'',
                    lOrS:'',
                    userDef1:'',
                    userDef2:'',
                    userDef3:'',
                    userDef4:'',
                    userDef5:'',
                    estAnnIncome:'',
                    ExtendedField1:'',
                    ExtendedField2:'',
                    ExtendedField3:'',
                    ExtendedField4:'',
                    ExtendedField5:'',
                    InterestDividendRate:'',
                    CouponFrequency:'',
                    NextPaymentDate:'',
                    SPRating:'',
                    MoodyRating:'',
                    State:'',
                    CallDate1:'',
                    CallPrice1:'',
                    PutDate1:'',
                    PutPrice1:'',
                    CallDate2:'',
                    CallPrice2:'',
                    PutDate2:'',
                    PutPrice2:'',
                    CallDate3:'',
                    CallPrice3:'',
                    PutDate3:'',
                    PutPrice3:'',
                    MaturityDate:'',
                    YieldToMaturity:'',
                    Duration:'',
                    BondStatus:'',
                    FirstCoupon:'',
                    LastCoupon:'',
                    IssueDate:'',
                    AccrualCalendar:'',
                    PaysOnMonthEnd:'',
                    ZeroCoupon:'',
                    EntityType:'',
                    ValF:'',
                    IsBond:'',
                    IsCash:'',
                    IsCurr:'',
                    IsVRS:'',
                    IsMBS:'',
                    CouponDelay:'',
                    PoolNumber:'',
                    YieldToWorstOnPrice:'',
                    YieldOnCost:'',
                    YieldToNextCallOnPrice:'',
                    YieldToPutOnPrice:'',
                    DurationToWorstOnPrice:'',
                    DurationToNextCallOnPrice:'',
                    YieldToWorstOnCost:'',
                    UnitAdjCost:'',
                    FitchRating:'',
                    Convexity:'',
                    AverageLife:'',
                    YieldToBest:'',
                    DurationToBest:'',
                    TotalAdjCost:''
                }
    }

    this.getMappings = this.getMappings.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount(){
      this.getMappings();
  }

  onInputChange(e, attr){
    let mappings = {...this.state.mappings};
    mappings[attr] = e.target.value.toUpperCase();
    this.setState({mappings});
  }

  uploadMappings = (e)=>{

    if(this.state.mappings.portfolio_Id===null || this.state.mappings.portfolio_Id==='')
        alert("Account/Portfolio ID is required.");
    else{
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload ={
            company:this.props.user.company,
            mappings:this.state.mappings
        }
        axios.post('api/positionMappings', payload, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Position Mappings have been updated.");
            this.getMappings();
        }
        else
            alert("An error occured when trying to update mappings");
        }).catch(err=>{
        alert("An error occured when trying to update mappings");
        this.getMappings();
        });
    }
  }

  getMappings(){
    var emptyMappings ={household_Id:'',
                        portfolio_Id:'',
                        portfolioName:'',
                        strategy:'',
                        securitySymbol:'',
                        securityName:'',
                        securityType:'',
                        assetAllocation:'',
                        sector:'',
                        industry:'',
                        quantity:'',
                        acqDate:'',
                        unitCost:'',
                        appMarketPrice:'',
                        marketValue:'',
                        totalCost:'',
                        unrealized:'',
                        yield:'',
                        pctAssets:'',
                        accruedInterest:'',
                        assetClassCode:'',
                        securityTypeCode:'',
                        sectorCode:'',
                        industryCode:'',
                        lOrS:'',
                        userDef1:'',
                        userDef2:'',
                        userDef3:'',
                        userDef4:'',
                        userDef5:'',
                        estAnnIncome:'',
                        ExtendedField1:'',
                        ExtendedField2:'',
                        ExtendedField3:'',
                        ExtendedField4:'',
                        ExtendedField5:'',
                        InterestDividendRate:'',
                        CouponFrequency:'',
                        NextPaymentDate:'',
                        SPRating:'',
                        MoodyRating:'',
                        State:'',
                        CallDate1:'',
                        CallPrice1:'',
                        PutDate1:'',
                        PutPrice1:'',
                        CallDate2:'',
                        CallPrice2:'',
                        PutDate2:'',
                        PutPrice2:'',
                        CallDate3:'',
                        CallPrice3:'',
                        PutDate3:'',
                        PutPrice3:'',
                        MaturityDate:'',
                        YieldToMaturity:'',
                        Duration:'',
                        BondStatus:'',
                        FirstCoupon:'',
                        LastCoupon:'',
                        IssueDate:'',
                        AccrualCalendar:'',
                        PaysOnMonthEnd:'',
                        ZeroCoupon:'',
                        EntityType:'',
                        ValF:'',
                        IsBond:'',
                        IsCash:'',
                        IsCurr:'',
                        IsVRS:'',
                        IsMBS:'',
                        CouponDelay:'',
                        PoolNumber:'',
                        YieldToWorstOnPrice:'',
                        YieldOnCost:'',
                        YieldToNextCallOnPrice:'',
                        YieldToPutOnPrice:'',
                        DurationToWorstOnPrice:'',
                        DurationToNextCallOnPrice:'',
                        YieldToWorstOnCost:'',
                        UnitAdjCost:'',
                        FitchRating:'',
                        Convexity:'',
                        AverageLife:'',
                        YieldToBest:'',
                        DurationToBest:'',
                        TotalAdjCost:''
    }
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/positionMappings?company='+this.props.user.company, {headers}).then(response=>{
      if(response.data.mappings.length===0)
          this.setState({mappings:emptyMappings});
      else
        this.setState({mappings:response.data.mappings[0]});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:emptyMappings})
    });
  }


  render() {
    var data = posFields;
    return(
        <div>
            <table className='posMapTable'>
                <tbody>
                    <tr>
                        <td className='heading'>Field Name <hr/></td>
                        <td align='right' className='heading'>Column Letter in Excel <hr/></td>
                    </tr>
                    {data.map(field => (
                    <tr key={field.id}>
                        <td><h4>{field.name}</h4><div>{field.desc}</div></td>
                        <td align='right'><input type='text' value={this.state.mappings[field.dbField]} onChange={e=>this.onInputChange(e,field.dbField)}/></td>                  
                    </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td align='right'><Button onClick={(e)=>this.uploadMappings(e)}>Apply Changes</Button></td>
                    </tr> 
                </tbody>
            </table>
        </div>
    );
  }

}

export default PositionMapping;