import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import GridLoading from '../../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import {parseDate} from '@telerik/kendo-intl';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

class AdvisorPerfHist extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data:[],
      result:[],
      dataState:{group:[{field:"client", aggregates:[]}]},
      aggregates:[],
      perfType:'net',
      returnType:'irr',
      dates:[],
      selectedDate:'',
      loading:false
    }

    this.getData = this.getData.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.getDate = this.getDate.bind(this);
    this.getPerfDates = this.getPerfDates.bind(this);
  }

  componentDidMount(){
      this.getData();
      this.getPerfDates();
  }

  getPerfDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      advisor:this.props.user.user,
      perfType:this.state.perfType
    }
    axios.post('api/perfDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getDate(sqlDate, isUTC){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if(d){
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getData(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var irr = '';
    if(this.state.returnType==='irr')
      irr = '&irr=true';

    if (this.state.selectedDate==='')
    {
      this.getPerfDates();
    }
    

    axios.get('api/advisorPerfHist?company='+this.props.user.company+'&date='+this.state.selectedDate+'&perfType='+this.state.perfType+irr, {headers}).then(response=>{
      if(response.data.code===200){
        for(let i=0; i<response.data.data.length; i++){
          response.data.data[i].perfStDate = this.getDate(response.data.data[i].perfStDate, true);
        }
        this.setState({data:response.data.data, result:process(response.data.data, this.state.dataState), loading:false});
      }
      else{
        this.setState({loading:false});
        alert("Could not data for advisor's accounts.");
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

  changeDataState = (e)=>{
    const groups = e.data.group;
    if(groups){
      groups.map(group=>group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.data, e.data);
    this.setState({dataState:e.data, result:newData});
  }

  cellRender(tdElement, cellProps) {
    if(cellProps.rowType==='data' && cellProps.field!=='value' && (cellProps.dataItem[cellProps.field]===undefined || cellProps.dataItem[cellProps.field]===null)){
        return(
            <td style={{textAlign:'right'}}>-</td>
        );
    }
    else if(tdElement!==null){
      var style={};
        if(cellProps.field!=='client' && cellProps.field!=='displayName'){
            
            style = {textAlign:'right'};
            return(
                <td {...tdElement.props} style={style}/>
            );
        }
        else if(cellProps.field==='displayName'){
            if(cellProps.dataItem.indexName)
              style = {textAlign:'right', fontStyle:'italic'};
            else
              style = {fontWeight:'bold'}

            return(
                <td {...tdElement.props} style={style}/>
            );
        }
    
    }

    return tdElement;
  }

  headerCellRender(thElement, cellProps) {
    if(thElement!==null){
      var style={};
      if(cellProps.field!=='client' && cellProps.field!=='displayName')
        style = {textAlign:'right'};

      return(
        <th {...thElement.props} style={style}/>
      );
    }

    return thElement;

  }

  _export;

  export = () =>{
    this._export.save();
  }

  render() {
    return(
        <div className='advisorPerfHist'>
          <ExcelExport data={this.state.data} fileName={'AdvisorPerfHist_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
            <ExcelExportColumn field="client" title="Client"/>
            <ExcelExportColumn field="displayName" title="Account"/>
            <ExcelExportColumn field="perfStDate" title="Start Date"/>
            <ExcelExportColumn field="twrMTD" title="MTD"/>
            <ExcelExportColumn field="returnsQTD" title="QTD"/>
            <ExcelExportColumn field="returnsPrevQtr" title="Prev Qtr"/>
            <ExcelExportColumn field="returns3Month" title="3 Months"/>
            <ExcelExportColumn field="returns6Month" title="6 Months"/>
            <ExcelExportColumn field="twrYTD" title="YTD"/>
            <ExcelExportColumn field="returnsPrevYear" title="Prev Year"/>
            <ExcelExportColumn field="returnsYear" title="1 Year"/>
            <ExcelExportColumn field="returns3Year" title="3 Years"/>
            <ExcelExportColumn field="returns5Year" title="5 Years"/>
            <ExcelExportColumn field="returns7Year" title="7 Years"/>
            <ExcelExportColumn field="returns10Year" title="10 Years"/>
            <ExcelExportColumn field="returns15Year" title="15 Years"/>
            <ExcelExportColumn field="returns20Year" title="20 Years"/>
            <ExcelExportColumn field="returns25Year" title="25 Years"/>
            <ExcelExportColumn field="twrITD" title="ITD"/>
          </ExcelExport>
          <Grid data={this.state.result}
            className='noGroupGridLines'
            cellRender = {this.cellRender}
            headerCellRender = {this.headerCellRender}
            onExpandChange={this.expandChange}
            expandField="expanded"
            groupable={true}
            resizable={true}
            reorderable={true}
            onDataStateChange={this.changeDataState}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              <label>Type: </label><select onChange={(e)=>this.setState({perfType:e.target.value},()=>this.getData())} value={this.state.perfType} style={{marginRight:'10px'}}>
                <option value='net'>Net</option>
                <option value='gross'>Gross</option>
              </select>
              <label>Returns: </label><select onChange={(e)=>this.setState({returnType:e.target.value}, ()=>this.getData())} value={this.state.returnType} style={{marginRight:'10px'}}>
                <option value='irr'>IRR</option>
                <option value='twr'>TWR</option>
              </select>
              <label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value}, ()=>this.getData())}>
              {this.state.dates.map((date, i)=>(
              <option value={date.fDate}>{date.fDate}</option>
              ))}
              </select>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
            </GridToolbar>
            <GridColumn headerClassName='gridHeader' field="client" title="Client"/>
            <GridColumn headerClassName='gridHeader' field="displayName" title="Account" format="{0:n2}"/>
              {this.state.data.filter(x=>(x.perfStDate!==undefined)).length>0 && this.props.styleSettings.showPerfStart && <GridColumn headerClassName='gridHeader' field="perfStDate" title="Start Date" format="{0:d}"/>}
              {this.state.data.filter(x=>(x.twrMTD!==undefined)).length>0 && this.props.styleSettings.showPerfMTD && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="twrMTD" title="MTD" />}
              {this.state.data.filter(x=>(x.returnsQTD!==undefined)).length>0 && this.props.styleSettings.showPerfQTD && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returnsQTD" title="QTD" />}
              {this.state.data.filter(x=>(x.returnsPrevQtr!==undefined)).length>0 && this.props.styleSettings.showPerfPrevQtr && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returnsPrevQtr" title="Prev Qtr" />}
              {this.state.data.filter(x=>(x.returns3Month!==undefined)).length>0 && this.props.styleSettings.showPerf3Month && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns3Month" title="3 Month" />}
              {this.state.data.filter(x=>(x.returns6Month!==undefined)).length>0 && this.props.styleSettings.showPerf6Month && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns6Month" title="6 Month" />}
              {this.state.data.filter(x=>(x.twrYTD!==undefined)).length>0 && this.props.styleSettings.showPerfYTD && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="twrYTD" title="YTD" />}
              {this.state.data.filter(x=>(x.returnsPrevYear!==undefined)).length>0 && this.props.styleSettings.showPerfPrevYear && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returnsPrevYear" title="Prev Year" />}
              {this.state.data.filter(x=>(x.returnsYear!==undefined)).length>0 && this.props.styleSettings.showPerfYear && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returnsYear" title="1 Year" />}
              {this.state.data.filter(x=>(x.returns3Year!==undefined)).length>0 && this.props.styleSettings.showPerf3Year && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns3Year" title="3 Years" />}
              {this.state.data.filter(x=>(x.returns5Year!==undefined)).length>0 && this.props.styleSettings.showPerf5Year && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns5Year" title="5 Years" />}
              {this.state.data.filter(x=>(x.returns7Year!==undefined)).length>0 && this.props.styleSettings.showPerf7Year && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns7Year" title="7 Years" />}
              {this.state.data.filter(x=>(x.returns10Year!==undefined)).length>0 && this.props.styleSettings.showPerf10Year && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns10Year" title="10 Years" />}
              {this.state.data.filter(x=>(x.returns15Year!==undefined)).length>0 && this.props.styleSettings.showPerf15Year && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns15year" title="15 Years" />}
              {this.state.data.filter(x=>(x.returns20Year!==undefined)).length>0 && this.props.styleSettings.showPerf20Year && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns20Year" title="20 Years" />}
              {this.state.data.filter(x=>(x.returns25Year!==undefined)).length>0 && this.props.styleSettings.showPerf25Year && <GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="returns25Year" title="25 Years" />}
              {this.state.data.filter(x=>(x.twrITD!==undefined)).length>0 && this.props.styleSettings.showPerfITD &&<GridColumn format="{0:#.##'%'}" headerClassName='gridHeader' field="twrITD" title="ITD" />}
          </Grid>
          {this.state.loading && <GridLoading />}
        </div>
    );
  }

}

export default AdvisorPerfHist;