import React, { Component } from "react";
import { Switch } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import RolePermissionsPopUp from "../../UserList/components/RolePermissionsPopUp";

class ViewsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  isDefaultChecked(view) {
    if (view.page === 'docTab6' && this.props.approved === false) {
      return false;
    }
    if (this.props.views.findIndex(v2 => v2.page === view.page && v2.active) !== -1) {
      return true;
    }
    if (this.props.views.findIndex(v2 => v2 === view.page) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  isOverride = (view) => {
    return (this.props.views.findIndex(v2 =>
      v2.page === view.page &&
      (v2.originalActive !== v2.active ||
        v2.isOverride)
    ) > -1) ? true : false;
  }

  render() {
    var data = this.props.data;

    return (
      <table className="viewTable">
        <tbody>
          {data.map((view, i) => (
            <tr key={i} style={{ background: 'none' }}>
              <td style={{ background: 'none' }}><label key={view.name}>{view.name}</label></td>
              <td style={{ background: 'none' }}>
                <Switch
                  /*disabled={this.props.company !== 'cssi' && view.isGlobal}*/
                  onChange={(e) => this.props.onChange(view, e)}
                  defaultChecked={this.isDefaultChecked(view)} />
                {this.props.company !== 'cssi' && view.isGlobal && this.props.isGlobal ? (this.isOverride(view) ? "  (Override)" : " (CSSI)") : ""}
              </td>
              <td style={{ background: 'none' }}>
                <RolePermissionsPopUp
                  permissions={view.permissions}
                  title={view.name}
                  button={<Button className='docButton'>View Permissions ({view.permissions != null ? view.permissions.length : 0})</Button>}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    );
  }
}

export default ViewsList;